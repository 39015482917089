import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        reactivos: [],
    },

    getters: {
        getReactivos: state => {
            return state.reactivos
        },
    },

    mutations: {
        SET_REACTIVOS(state, reactivos) {
            state.reactivos = reactivos
        },
    },

    actions: {
        fetchReactivos: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/reactivos')
                .then(response => {
                    commit('SET_REACTIVOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
