<template>
    <v-dialog v-model="active" width="480" persistent>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Historial de movimientos</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-virtual-scroll v-if="movimientoItem.length > 0" height="480" :items="movimientoItem" item-height="88">
                                <template v-slot:default="{ item, index }">
                                    <v-list-item :key="index" three-line link>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
                                            <v-list-item-title>{{ getTipoMovimiento(item.fk_idcatTipoMovimiento) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.observaciones }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-avatar color="secondary">
                                                <span class="white--text">{{ item.cantidad }}</span>
                                            </v-avatar>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                            <div v-else class="text-subtitle-1">No hay movimientos registrados del item seleccionado.</div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex'
    import _ from 'lodash'

    export default {
        props: {
            active: {
                type: Boolean,
            },
        },

        data() {
            return {
                selectedTipoLista: 1,
                tipoListaMovimientos: [
                    { id: 1, title: 'Todos' },
                    { id: 2, title: 'Cargadores' },
                    { id: 3, title: 'Municiones' },
                ],
            }
        },

        computed: {
            ...mapGetters('movimientoItem', {
                movimientoItem: 'getMovimientoItem',
            }),

            filteredMovimientoMuniciones() {
                return this.movimientoMunicion.filter(movimiento => {
                    return movimiento.fk_idCatCalibre == this.calibre.idCalibre
                })
            },

            filteredListaMovimientos() {
                switch (this.selectedTipoLista) {
                    case 1:
                        return _.sortBy(this.filteredMovimientoMuniciones.concat(this.movimientoCargador), function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    case 2:
                        return _.sortBy(this.movimientoCargador, function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    case 3:
                        return _.sortBy(this.filteredMovimientoMuniciones, function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    default:
                        break
                }
            },
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },

            getTipoMovimiento(id_tipo_movimiento) {
                if (id_tipo_movimiento == 1) {
                    return 'Entrada'
                } else return 'Salida'
            },
        },
    }
</script>
