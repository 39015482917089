import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposInstancia: [],
    },

    getters: {
        getTiposInstancia: state => {
            return state.tiposInstancia
        },
    },

    mutations: {
        SET_TIPOS_INSTANCIA(state, tiposInstancia) {
            state.tiposInstancia = tiposInstancia
        },
    },

    actions: {
        fetchTiposInstancia: async function({ commit }) {
            await axios
                .get('/EonCloud/common/instancias')
                .then(response => {
                    commit('SET_TIPOS_INSTANCIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
