import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        colonias: [],
    },

    getters: {
        getColonias: state => {
            return state.colonias
        },
    },

    mutations: {
        SET_COLONIAS(state, colonias) {
            state.colonias = colonias
        },
    },

    actions: {
        fetchColonias: async function({ commit }) {
            await axios
                .get('/EonCloud/colonias')
                .then(response => {
                    commit('SET_COLONIAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
