import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        motivoFaltaAdministrativa: [],
    },

    getters: {
        getMotivoFaltaAdministrativa: state => {
            return state.motivoFaltaAdministrativa
        },
    },

    mutations: {
        SET_MOTIVO_FALTA_ADMINISTRATIVA(state, motivoFaltaAdministrativa) {
            state.motivoFaltaAdministrativa = motivoFaltaAdministrativa
        },
    },

    actions: {
        fetchMotivoFaltaAdministrativa: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/motivoFaltaAdmin')
                .then(response => {
                    commit('SET_MOTIVO_FALTA_ADMINISTRATIVA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
