import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        reactivoValor: [],
    },

    getters: {
        getReactivoValor: state => {
            return state.reactivoValor
        },
    },

    mutations: {
        SET_REACTIVO_VALOR(state, reactivoValor) {
            state.reactivoValor = reactivoValor
        },
    },

    actions: {
        fetchReactivoValor: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/reactivoValor')
                .then(response => {
                    commit('SET_REACTIVO_VALOR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
