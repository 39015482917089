import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {},

    getters: {
        value: state => {
            return state.value
        },
    },

    mutations: {
        updateValue(state, payload) {
            state.value = payload
        },
    },

    actions: {
        saveAmmunitionAssignment: async function({ commit }, municionPersonal) {
            try {
                await axios.post('/PoliciaCuadrantes/Armeria/armas/' + municionPersonal.idArma + '/municiones', municionPersonal)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
