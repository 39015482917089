import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusMunicion: [],
    },

    getters: {
        getEstatusMunicion: state => {
            return state.estatusMunicion
        },
    },

    mutations: {
        SET_ESTATUS_MUNICION: (state, estatusMunicion) => {
            state.estatusMunicion = estatusMunicion
        },
    },

    actions: {
        fetchEstatusMunicion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/estatusMunicion')
                .then(response => {
                    commit('SET_ESTATUS_MUNICION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
