import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        subareas: [],
    },

    getters: {
        getSubareas: state => {
            return state.subareas
        },
    },

    mutations: {
        SET_SUBAREAS(state, subareas) {
            state.subareas = subareas
        },
    },

    actions: {
        fetchSubareas: async function({ commit }) {
            await axios
                .get('/EonCloud/common/subareas')
                .then(response => {
                    commit('SET_SUBAREAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
