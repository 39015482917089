<template>
    <v-dialog v-model="active" width="480" persistent>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Cambiar estatus de arma</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="arma">
                <v-select
                    v-model="arma.fk_idEstatus"
                    label="Estatus"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                    item-color="secondary"
                    item-value="id"
                    item-text="title"
                    :items="estatusArma"
                ></v-select>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" :disabled="loading" :loading="loading" @click="triggerUpdateEstatusArma()">Guardar cambios</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            arma: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('estatusArma', {
                estatusArma: 'getEstatusArma',
            }),
        },

        methods: {
            ...mapActions('arma', ['fetchArmas', 'updateEstatusArma']),

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },

            async triggerUpdateEstatusArma() {
                this.loading = true
                await this.updateEstatusArma({
                    idArma: this.arma.idArma,
                    fk_idEstatus: this.arma.fk_idEstatus,
                })

                await this.fetchArmas()
                this.closeDialog()
            },
        },
    }
</script>
