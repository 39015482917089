<template>
    <v-container>
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title v-bind="cardTitleProps">Sugerencia de curso</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-avatar size="80" color="secondary">
                                        <v-icon size="48" color="primary">mdi-account</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="8" align="left">
                                    <div class="black--text text-subtitle-1">{{ getFullName(personal) }}</div>
                                    <div class="black--text text-caption text-secondary">{{ personal.CUIP }}</div>
                                    <div class="black--text text-caption text-secondary">
                                        {{ personal.CatPuestoDependencia.catPuesto.puesto }}
                                    </div>
                                    <div class="black--text text-caption text-secondary">Operativo</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="sugerencia.fk_idCurso"
                                        filled
                                        rounded
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Curso sugerido"
                                        :items="cursos"
                                        item-value="id"
                                        item-text="subcategoria"
                                    ></v-select>
                                    <v-text-field
                                        v-model="sugerencia.otroCurso"
                                        label="Nuevo curso sugerido"
                                        rounded
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        no-resize
                                        rows="4"
                                        color="secondary"
                                        background-color="primary"
                                        label="Comentario"
                                        v-model="sugerencia.comentario"
                                        rounded
                                        filled
                                        dense
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions v-bind="cardActionsProps">
                        <v-btn v-bind="secondaryButtonProps">Cancelar</v-btn>
                        <v-btn v-bind="primaryButtonProps" v-on:click="triggerSaveSugerenciaCurso()" :disabled="loading" :loading="loading"
                            >Enviar sugerencia</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, secondaryButtonProps } from '../../components/props/props'

    export default {
        props: {
            personal: {
                type: Object,
            },
        },

        async mounted() {
            await this.fetchCursos()
        },

        data() {
            return {
                cardTitleProps,
                cardProps,
                cardActionsProps,
                primaryButtonProps,
                secondaryButtonProps,

                loading: false,
                cursoElegido: null,

                sugerencia: {
                    fk_idPersonal: 0,
                    fk_idCurso: 0,
                    otroCurso: '',
                    comentario: '',
                },
            }
        },

        computed: {
            ...mapGetters('curso', {
                cursos: 'getCursos',
            }),
        },

        methods: {
            ...mapActions('curso', ['fetchCursos']),
            ...mapActions('sugerenciaCursos', ['saveSugerenciaCurso']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            triggerSaveSugerenciaCurso: async function() {
                this.loading = true
                this.sugerencia.fk_idPersonal = this.personal.idPersonal
                await this.saveSugerenciaCurso(this.sugerencia)
                this.loading = false
                this.SET_SNACKBAR_MESSAGE('Se ha enviado la sugerencia de curso.')
                this.$router.push('/desarrollo_profesional/personal')
            },
        },
    }
</script>
