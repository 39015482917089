import anime from 'animejs/lib/anime.es.js'

export function textAnimation() {
    var textWrapper = document.querySelector('.armeria')
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>")

    anime
        .timeline({ loop: true })
        .add({
            targets: '.armeria .letter',
            translateX: [48, 0],
            translateZ: 0,
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1200,
            delay: (el, i) => 500 + 30 * i,
        })
        .add({
            targets: '.armeria .letter',
            translateX: [0, -30],
            opacity: [1, 0],
            easing: 'easeInExpo',
            duration: 1100,
            delay: (el, i) => 100 + 30 * i,
        })
}

export function animateCard() {
    anime
        .timeline({ loop: true })
        .add({
            targets: '.card',
            boxShadow: 'none',
            easing: 'easeInOutQuint',
            duration: 1000,
        })
        .add({
            targets: '.card',
            opacity: 1,
            easing: 'easeInOutQuint',
            delay: 1000,
            duration: 300,
        })
}

export function loaderText() {
    anime
        .timeline({ loop: true })
        .add({
            targets: '.loader',
            opacity: 0.68,
            easing: 'easeInOutQuint',
            duration: 700,
        })
        .add({
            targets: '.loader',
            opacity: 1,
            easing: 'easeInOutQuint',
            duration: 700,
        })
}
