import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        indicadorEvaluacion: [],
    },

    getters: {
        getIndicadorEvaluacion: state => {
            return state.indicadorEvaluacion
        },
    },

    mutations: {
        SET_INDICADOR_EVALUACION(state, indicadorEvaluacion) {
            state.indicadorEvaluacion = indicadorEvaluacion
        },
    },

    actions: {
        fetchIndicadorEvaluacion: async function({ commit }, idEvaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/' + idEvaluacion + '/indicadorEvaluacion')
                .then(response => {
                    commit('SET_INDICADOR_EVALUACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
