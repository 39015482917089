<template>
    <v-dialog persistent width="90%" v-model="disciplinaAdministrativaDialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" class="mx-2" v-on="on" v-bind="attrs">Disciplina Administrativa</v-btn>
        </template>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación de disciplina administrativa</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="disciplinaAdministrativa">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Instancia que informa"
                                                    :value="disciplinaAdministrativa.instanciaInforma"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Periodicidad"
                                                    :value="disciplinaAdministrativa.CatPeriodicidad.title"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaAdministrativa.fechaInicial"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaAdministrativa.fechaFin"
                                                    label="Fecha fin"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaAdministrativa.fechaAplicacion"
                                                    label="Fecha de aplicación"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="2">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Tipos de incidencia</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-text-field
                                        v-for="(incidencia, index) in disciplinaAdministrativa.incidencias"
                                        v-model.number="incidencia.cantidad"
                                        type="number"
                                        :key="index"
                                        :label="getIncidenciaTitle(incidencia.fk_idCatIncidencia)"
                                        rounded
                                        filled
                                        color="secondary"
                                        background-color="primary"
                                        min="0"
                                    ></v-text-field>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Actas administrativas</div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="secondary"
                                        icon
                                        @click="
                                            disciplinaAdministrativa.actasAdministrativas.push({
                                                cantidad: 0,
                                                fecha: null,
                                                motivo: '',
                                                descripcion: '',
                                                estatus: true,
                                            })
                                        "
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(acta, index) in disciplinaAdministrativa.actasAdministrativas" :key="index">
                                                <v-card color="primary" class="card">
                                                    <v-card-title>
                                                        <v-text-field
                                                            label="Cantidad"
                                                            v-model.number="acta.cantidad"
                                                            type="number"
                                                            min="0"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            hide-details
                                                        ></v-text-field>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" icon @click="disciplinaAdministrativa.actasAdministrativas.splice(index, 1)"
                                                            ><v-icon>mdi-minus</v-icon></v-btn
                                                        >
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-text-field
                                                            label="Motivo"
                                                            v-model="acta.motivo"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                        <v-menu
                                                            v-model="menuFechaActa[index]"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="acta.fecha"
                                                                    label="Fecha"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                    :rules="required"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="acta.fecha" @input="menuFechaActa[index] = false"></v-date-picker>
                                                        </v-menu>
                                                        <v-text-field
                                                            label="Descripción"
                                                            v-model="acta.descripcion"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Estímulos</div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="secondary"
                                        icon
                                        @click="
                                            disciplinaAdministrativa.estimulos.push({
                                                estimulo: '',
                                                fecha: null,
                                                motivo: '',
                                                descripcion: '',
                                                estatus: true,
                                            })
                                        "
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(estimulo, index) in disciplinaAdministrativa.estimulos" :key="index">
                                                <v-card color="primary" class="card">
                                                    <v-card-title>
                                                        <v-text-field
                                                            label="Estímulo"
                                                            v-model="disciplinaAdministrativa.estimulos[index].estimulo"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            hide-details
                                                        ></v-text-field>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" icon @click="disciplinaAdministrativa.estimulos.splice(index, 1)"
                                                            ><v-icon>mdi-minus</v-icon></v-btn
                                                        >
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-text-field
                                                            label="Motivo"
                                                            v-model="disciplinaAdministrativa.estimulos[index].motivo"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                        <v-menu
                                                            v-model="menuFechaEstimulo[index]"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="estimulo.fecha"
                                                                    label="Fecha"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                    :rules="required"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="estimulo.fecha" @input="menuFechaEstimulo[index] = false"></v-date-picker>
                                                        </v-menu>
                                                        <v-text-field
                                                            label="Descripción"
                                                            v-model="disciplinaAdministrativa.estimulos[index].descripcion"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Reconocimientos</div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="secondary"
                                        icon
                                        @click="
                                            disciplinaAdministrativa.reconocimientos.push({
                                                reconocimiento: '',
                                                fecha: null,
                                                motivo: '',
                                                descripcion: '',
                                                estatus: true,
                                            })
                                        "
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(reconocimiento, index) in disciplinaAdministrativa.reconocimientos" :key="index">
                                                <v-card color="primary" class="card">
                                                    <v-card-title>
                                                        <v-text-field
                                                            label="Reconocimiento"
                                                            v-model="disciplinaAdministrativa.reconocimientos[index].reconocimiento"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            hide-details
                                                        ></v-text-field>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="error" icon @click="disciplinaAdministrativa.reconocimientos.splice(index, 1)"
                                                            ><v-icon>mdi-minus</v-icon></v-btn
                                                        >
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <v-text-field
                                                            label="Motivo"
                                                            v-model="disciplinaAdministrativa.reconocimientos[index].motivo"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                        <v-menu
                                                            v-model="menuFechaReconocimiento[index]"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="reconocimiento.fecha"
                                                                    label="Fecha"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                    :rules="required"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="reconocimiento.fecha"
                                                                @input="menuFechaReconocimiento[index] = false"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                        <v-text-field
                                                            label="Descripción"
                                                            v-model="disciplinaAdministrativa.reconocimientos[index].descripcion"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-text-field>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveDisciplinaAdministrativa()" :loading="loading" :disabled="!valid">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            idPersonal: {
                type: Number,
            },

            disciplinaAdministrativa: {
                type: Object,
            },
        },

        data() {
            return {
                disciplinaAdministrativaDialog: false,
                loading: false,

                menuFechaActa: [],
                menuFechaEstimulo: [],
                menuFechaReconocimiento: [],

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('incidencia', {
                incidencias: 'getIncidencia',
            }),

            valid: function() {
                return true
            },
        },

        methods: {
            ...mapActions('disciplinaAdministrativa', ['updateDisciplinaAdministrativa', 'fetchDisciplinaAdministrativa', 'updateEstatus']),

            getIncidenciaTitle(id_incidencia) {
                switch (id_incidencia) {
                    case 1:
                        return 'Faltas'
                    case 2:
                        return 'Amonestaciones'
                    case 3:
                        return 'Incapacidades'
                    case 4:
                        return 'Permisos'
                    case 5:
                        return 'Arresto'

                    default:
                        break
                }
            },

            closeDialog() {
                this.loading = false
                this.disciplinaAdministrativaDialog = false
            },

            async triggerSaveDisciplinaAdministrativa() {
                this.loading = true

                this.disciplinaAdministrativa.incidencias.forEach(incidencia => {
                    incidencia.estatus = true
                })
                await this.updateDisciplinaAdministrativa(this.disciplinaAdministrativa)
                await this.updateEstatus(this.disciplinaAdministrativa)
                await this.fetchDisciplinaAdministrativa(this.idPersonal)

                this.closeDialog()
            },
        },
    }
</script>
