import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        descripcion: [],
    },

    getters: {
        getDescripcion: state => {
            return state.descripcion
        },
    },

    mutations: {
        SET_DESCRIPCION(state, descripcion) {
            state.descripcion = descripcion
        },
    },

    actions: {
        fetchDescripcion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/descripcion')
                .then(response => {
                    commit('SET_DESCRIPCION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
