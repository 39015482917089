<template>
    <v-container v-if="preaspirante">
        <v-row>
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card class="card" color="#f2fff8">
                    <v-card-title class="font-weight-bold justify-center">Agendar cita a aspirante</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 50" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form v-model="fechaRegistroForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-date-picker
                                            v-model="evaluacionMedica.fechaRegistro"
                                            locale="es"
                                            color="#34A871"
                                            :max="getToday()"
                                            no-title
                                            full-width
                                            :rules="nameRules"
                                        ></v-date-picker>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-else>
                            <v-form v-model="agendarCitaForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(preaspirante)"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Aspirante"
                                            :rules="nameRules"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="selectedTipoCita"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="tipoCita"
                                            item-value="id"
                                            item-text="title"
                                            :rules="nameRules"
                                            item-color="secondary"
                                        >
                                            <template v-slot:label>Tipo de evaluación <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" align="center">
                                        <div class="text-subtitle-2 font-weight-bold">Fecha de evaluación</div>
                                        <v-date-picker
                                            v-model="evaluacionMedica.fechaAplicacion"
                                            locale="es"
                                            color="#34A871"
                                            no-title
                                            full-width
                                            :rules="nameRules"
                                        ></v-date-picker>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        class="white--text"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import { circularProgressProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
            await this.fetchDictamenPersonal(this.id)
        },

        data() {
            return {
                circularProgressProps,

                loading: false,
                fechaRegistroForm: false,
                agendarCitaForm: false,
                activeStep: 1,
                selectedTipoCita: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],

                evaluacionMedica: {
                    fk_idPersonal: this.id,
                    fisica: 0,
                    medica: 0,
                    clinica: 0,
                    resultado: 0,
                    fechaAplicacion: null,
                    observaciones: '',
                    fechaRegistro: null,
                },

                tipoCita: [
                    { id: 1, title: 'Evaluación psicológica' },
                    { id: 2, title: 'Evaluación de acondicionamiento físico' },
                    { id: 3, title: 'Evaluación médica-clínica' },
                    { id: 4, title: 'Registro para personal de seguridad' },
                ],
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenes: 'getDictamenPersonal',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de registro'
                    case 2:
                        return 'Agendar cita'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.evaluacionMedica.fechaRegistro) {
                            return true
                        } else return false
                    case 2:
                        if (this.agendarCitaForm && this.evaluacionMedica.fechaAplicacion) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 2) {
                    return 'Siguiente paso'
                } else {
                    return 'Agendar cita'
                }
            },
        },

        methods: {
            ...mapActions('evaluacionMedica', ['saveEvaluacionMedica']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal', 'saveDictamenPersonal']),

            triggerSaveTrainer: async function() {
                this.loading = true

                await this.saveCita(this.capacitador)

                this.loading = false
            },

            getFullName: function(personal) {
                return personal.nombre + ' ' + personal.segundoNombre + ' ' + personal.apaterno + ' ' + personal.amaterno
            },

            getToday: function() {
                return moment().format('YYYY-MM-DD')
            },

            activeStepButtonHandler: async function() {
                if (this.activeStep < 2) {
                    this.activeStep++
                } else {
                    this.loading = true

                    if (this.selectedTipoCita != 4) {
                        await this.saveEvaluacionMedica({
                            fk_idPersonal: this.id,
                            fisica: 0,
                            medica: 0,
                            clinica: 0,
                            resultado: 0,
                            fechaAplicacion: moment(this.evaluacionMedica.fechaAplicacion).format('DD/MM/YYYY'),
                            observaciones: '',
                            fechaRegistro: moment(this.evaluacionMedica.fechaRegistro).format('DD/MM/YYYY'),
                        })
                    } else {
                        await this.saveDictamenPersonal({
                            fk_idPersonal: this.id,
                            resultadoConsultaPrevia: '',
                            oficio: '',
                            afis: '',
                            suic: '',
                            rnsp: '',
                        })
                    }

                    this.loading = false
                    this.$router.push('/profesionalizacion/preaspirantes')
                }
            },
        },
    }
</script>
