var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card",attrs:{"color":"primary"}},[_c('v-card-title',[_vm._v("Asignación de items a personal")]),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fechaSolicitud,"append-icon":"mdi-calendar","readonly":"","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Fecha de solicitud "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaSolicitud),callback:function ($$v) {_vm.menuFechaSolicitud=$$v},expression:"menuFechaSolicitud"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuFechaSolicitud = false}},model:{value:(_vm.asignacionEquipamiento.fechaSolicitud),callback:function ($$v) {_vm.$set(_vm.asignacionEquipamiento, "fechaSolicitud", $$v)},expression:"asignacionEquipamiento.fechaSolicitud"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fechaAsignacion,"append-icon":"mdi-calendar","readonly":"","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Fecha de asignación "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaAsignacion),callback:function ($$v) {_vm.menuFechaAsignacion=$$v},expression:"menuFechaAsignacion"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuFechaAsignacion = false}},model:{value:(_vm.asignacionEquipamiento.fechaAsignacion),callback:function ($$v) {_vm.$set(_vm.asignacionEquipamiento, "fechaAsignacion", $$v)},expression:"asignacionEquipamiento.fechaAsignacion"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Número de folio de resguardo","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary"},model:{value:(_vm.asignacionEquipamiento.folioResguardo),callback:function ($$v) {_vm.$set(_vm.asignacionEquipamiento, "folioResguardo", $$v)},expression:"asignacionEquipamiento.folioResguardo"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"label":"Evidencia","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary"},model:{value:(_vm.asignacionEquipamiento.file),callback:function ($$v) {_vm.$set(_vm.asignacionEquipamiento, "file", $$v)},expression:"asignacionEquipamiento.file"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"label":"Items","items":_vm.filteredEquipos,"item-text":"CatItem.item","background-color":"primary","color":"secondary","rounded":"","filled":"","dense":"","clearable":false,"multiple":"","hide-selected":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.CatItem.item)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.numero)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Vigencia: "+_vm._s(item.aniosVigencia)+" ")])],1)]}}]),model:{value:(_vm.selectedEquipo),callback:function ($$v) {_vm.selectedEquipo=$$v},expression:"selectedEquipo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"label":"Observaciones","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary","no-resize":"","rows":"4"},model:{value:(_vm.asignacionEquipamiento.observaciones),callback:function ($$v) {_vm.$set(_vm.asignacionEquipamiento, "observaciones", $$v)},expression:"asignacionEquipamiento.observaciones"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.selectedEquipo,"hide-default-footer":"","disable-pagination":""}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"secondary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.triggerSaveAsignacion()}}},[_vm._v(" Registrar asignación de items")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }