import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        usuario: [],
    },

    getters: {
        getReferencias: state => {
            return state.usuario
        },
    },

    mutations: {
        SET_REFERENCIAS(state, usuario) {
            state.usuario = usuario
        },
    },

    actions: {
        fetchReferenciasByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/usuario')
                .then(response => {
                    commit('SET_REFERENCIAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveUsuario: async function({ commit }, usuario) {
            await axios
                .post('/PoliciaCuadrantes/personal/' + usuario.fk_idPersonal + '/user', usuario)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateUsuario: async function(context, usuario) {
            await axios
                .put('/PoliciaCuadrantes/personal/' + usuario.idPersonal + '/usuario/' + usuario.idUsuario, usuario)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Usuario actualizado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
