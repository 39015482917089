import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposItem: [],
    },

    getters: {
        getTiposItem: state => {
            return state.tiposItem
        },
    },

    mutations: {
        SET_TIPOS_ITEM(state, tiposItem) {
            state.tiposItem = tiposItem
        },
    },

    actions: {
        fetchTiposItem: async function(context) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/tipoItem')
                .then(async response => {
                    context.commit('SET_TIPOS_ITEM', response.data.data)
                    context.dispatch('item/clearItems', {}, { root: true })
                    response.data.data.forEach(tipoItem => {
                        context.dispatch('item/fetchItemsByType', { id: tipoItem.id }, { root: true })
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
