import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        movimientoMunicion: [],
        movimientoMunicionDisponibles: 0,
        movimientoMunicionAsignadas: 0,
    },

    getters: {
        getMovimientoMunicion: state => {
            return state.movimientoMunicion
        },

        getMovimientoMunicionDisponibles: state => {
            return state.movimientoMunicionDisponibles
        },

        getMovimientoMunicionAsignadas: state => {
            return state.movimientoMunicionAsignadas
        },
    },

    mutations: {
        SET_MOVIMIENTO_MUNICION(state, movimientoMunicion) {
            state.movimientoMunicion = movimientoMunicion
        },

        SET_MOVIMIENTO_MUNICION_DISPONIBLES(state, movimientoMunicionDisponibles) {
            state.movimientoMunicionDisponibles = movimientoMunicionDisponibles
        },

        SET_MOVIMIENTO_MUNICION_ASIGNADAS(state, movimientoMunicionAsignadas) {
            state.movimientoMunicionAsignadas = movimientoMunicionAsignadas
        },
    },

    actions: {
        fetchMovimientoMunicion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/movimientosMuniciones')
                .then(response => {
                    commit('SET_MOVIMIENTO_MUNICION', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchMovimientoMunicionDisponibles: async function({ commit }, idCalibre) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Calibre/' + idCalibre + '/movimientosMuniciones/disponibles')
                .then(response => {
                    commit('SET_MOVIMIENTO_MUNICION_DISPONIBLES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchMovimientoMunicionAsignadas: async function({ commit }, idCalibre) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Calibre/' + idCalibre + '/movimientosMuniciones/asignadas')
                .then(response => {
                    commit('SET_MOVIMIENTO_MUNICION_ASIGNADAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMovimientoMunicion: async function({ commit }, movimientoMunicion) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/movimientosMuniciones', movimientoMunicion)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateMovimientoMunicion: async function({ commit }, movimientoMunicion) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/movimientosMuniciones/' + movimientoMunicion.id, movimientoMunicion)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatusMovimientoMunicion: async function({ commit }, movimientoMunicion) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/movimientosMuniciones/' + movimientoMunicion.id + '/estatus', {
                    fk_idCatEstatusMunicion: movimientoMunicion.fk_idCatEstatusMunicion,
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
