<template>
    <v-container v-if="personal">
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Actualiza estatus de personal</v-card-title>
                    <v-card-text align="center">
                        <v-container>
                            <v-row align="center">
                                <v-col cols="6">
                                    <v-text-field
                                        :value="personal.CatEstatus.title"
                                        rounded
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Estatus actual"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        background-color="primary"
                                        color="secondary"
                                        rounded
                                        filled
                                        dense
                                        item-value="id"
                                        item-text="title"
                                        v-model="selectedEstatus"
                                        :rules="required"
                                        :items="filteredEstatus"
                                    >
                                        <template v-slot:label>Nuevo estatus <span class="red--text">*</span> </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center ">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn
                            color="secondary"
                            class="primary--text mb-4"
                            :loading="loading"
                            v-on:click="triggerUpdateEstatus()"
                            :disabled="!selectedEstatus || loading"
                            >Editar estatus</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'
    import { circularProgressProps } from '../../components/props/props'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchEstatusPersonal()
        },

        data() {
            return {
                loading: false,
                circularProgressProps,

                activeStep: 1,
                asignaSedePersonalForm: false,
                asignaPuestoPersonalForm: false,
                selectedSede: null,
                selectedSubsede: null,
                selectedPuesto: null,
                selectedEstatus: null,
                selectedDependencia: null,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('estatusPersonal', {
                estatusPersonal: 'getEstatusPersonal',
            }),

            filteredEstatus() {
                return this.estatusPersonal.filter(estatus => {
                    return (
                        estatus.id == 1 ||
                        estatus.id == 2 ||
                        estatus.id == 3 ||
                        estatus.id == 4 ||
                        estatus.id == 10 ||
                        estatus.id == 11 ||
                        estatus.id == 12 ||
                        estatus.id == 14 ||
                        estatus.id == 15 ||
                        estatus.id == 16
                    )
                })
            },
        },

        methods: {
            ...mapActions('personal', ['updateEstatusPersonal', 'updatePuestoPersonal', 'fetchPersonal']),
            ...mapActions('puesto', ['fetchPuestosByDependencia']),
            ...mapActions('estatusPersonal', ['fetchEstatusPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            triggerUpdateEstatus: async function() {
                this.loading = true

                this.personal.fk_idEstatus = this.selectedEstatus

                await this.updateEstatusPersonal(this.personal)
                await this.fetchPersonal()

                this.loading = false
                this.$router.push('/recursos_humanos/personal')
            },
        },
    }
</script>
