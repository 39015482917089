import { axios, fileAxios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        faltasAdministrativas: [],
    },

    getters: {
        getFaltasAdministrativas: state => {
            return state.faltasAdministrativas
        },
    },

    mutations: {
        SET_FALTAS_ADMINISTRATIVAS(state, faltasAdministrativas) {
            state.faltasAdministrativas = faltasAdministrativas
        },
    },

    actions: {
        fetchFaltasAdministrativasByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/faltasAdministrativas')
                .then(response => {
                    commit('SET_FALTAS_ADMINISTRATIVAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveFaltaAdministrativa: async function(context, faltaAdministrativa) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + faltaAdministrativa.idPersonal + '/faltasAdministrativas', faltaAdministrativa)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', faltaAdministrativa.file)
                    formData.append('idFalta', response.data.data[0].ID)
                    context.dispatch('uploadFaltaAdministrativa', formData)
                })
                .catch(error => console.log(error))
        },

        updateFaltaAdministrativa: async function({ commit }, faltaAdministrativa) {
            await axios
                .put(
                    '/EonCloud/EstadoFuerza/personal/' +
                        faltaAdministrativa.idPersonal +
                        '/faltasAdministrativas/' +
                        faltaAdministrativa.idFaltaAdministrativa,
                    faltaAdministrativa
                )
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        uploadFaltaAdministrativa: async function(context, formData) {
            await fileAxios
                .post('/SetAdjuntoFaltaAdministrativa/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Falta administrativa guardado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
