import { axios } from '../../../plugins/axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        sanciones: [],
    },

    getters: {
        getSanciones: state => {
            return state.sanciones
        },
    },

    mutations: {
        SET_SANCIONES(state, sanciones) {
            state.sanciones = sanciones
        },
    },

    actions: {
        fetchSanciones: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/sancionasuntosinternos')
                .then(response => {
                    commit('SET_SANCIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveSancion: async function(context, sancion) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + sancion.fk_idPersonal + '/sancionasuntosinternos', sancion)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Sancion registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateSancion: async function(context, sancion) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + sancion.fk_idPersonal + '/sancionasuntosinternos/' + sancion.idSancion, sancion)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Sancion editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateVigenciaSancion: async function(context, sancion) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + sancion.fk_idPersonal + '/sancionasuntosinternos/' + sancion.idSancion + '/vigencia', {
                    vigencia: sancion.vigencia,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Vigencia de sancion editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
