<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Referencias y familiares</div>
            <v-spacer></v-spacer>
            <reporte-referencias-component :referencias="referencias"></reporte-referencias-component>
            <v-tabs v-model="selectedTab" color="secondary" fixed-tabs>
                <v-tab class="font-weight-bold">Referencias</v-tab>
                <v-tab class="font-weight-bold">Familiares</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="selectedTab" class="transparent">
                <v-tab-item key="1">
                    <v-container>
                        <v-row v-if="referencias.length > 0">
                            <v-col cols="4" v-for="referencia in referencias" :key="referencia.id">
                                <v-card color="primary" class="card">
                                    <v-card-title class="justify-center">
                                        <div class="text-subtitle-1 font-weight-bold">
                                            {{ referencia.Nombre }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Tipo de referencia
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">
                                                        {{ referencia.CatTipoReferencia.title }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Tiempo de conocerse
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">{{ referencia.tiempoConocerse }} años</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Ocupación
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">{{ referencia.CatOcupacion.title }}</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Teléfono
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">{{ referencia.telefono }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <v-icon color="primary darken-3" size="256">mdi-account-multiple</v-icon>
                                <div class="text-h6">Este elemento no ha registrado referencias</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" align="center">
                                <v-btn
                                    v-if="perfil !== 'ADMINISTRADOR' && perfil !== 'Policia'"
                                    color="secondary"
                                    :to="{ name: getReferenciaRouteName, params: { id: personal.idPersonal } }"
                                    >Agregar referencia</v-btn
                                >
                                <agrega-referencia-dialog-component :id="personal.idPersonal" v-else></agrega-referencia-dialog-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                    <v-container>
                        <v-row v-if="familiares.length > 0">
                            <v-col cols="4" v-for="familiar in familiares" :key="familiar.id">
                                <v-card color="primary" class="card">
                                    <v-card-title class="justify-center d-block" align="center">
                                        <div class="text-subtitle-1 font-weight-bold">{{ getFullName(familiar) }}</div>
                                        <div class="text-subtitle-2">{{ familiar.CatParentesco.title }}</div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Fecha de nacimiento
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">
                                                        {{ familiar.fechaNacimiento }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Edad
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">{{ getAge(familiar.fechaNacimiento) }}</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2">
                                                        Sexo
                                                    </div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2 font-weight-bold">{{ familiar.CatSexo.title }}</div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <v-icon color="primary darken-3" size="256">mdi-account-multiple</v-icon>
                                <div class="text-h6">Este elemento no cuenta con familaires registrados</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" align="center">
                                <v-btn
                                    v-if="perfil !== 'ADMINISTRADOR' && perfil !== 'Policia'"
                                    color="secondary"
                                    :to="{ name: getFamiliarRouteName, params: { id: personal.idPersonal } }"
                                    >Agregar familiar</v-btn
                                >
                                <agrega-familiar-dialog-component :id="personal.idPersonal" v-else></agrega-familiar-dialog-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'
    import AgregaFamiliarDialogComponent from '../../views/policia/AgregaFamiliarDialogComponent.vue'
    import AgregaReferenciaDialogComponent from '../../views/policia/AgregaReferenciaDialogComponent.vue'
    import ReporteReferenciasComponent from './ReporteReferenciasComponent.vue'

    export default {
        components: {
            ReporteReferenciasComponent,
            AgregaFamiliarDialogComponent,
            AgregaReferenciaDialogComponent,
        },

        props: {
            personal: {
                type: Object,
            },

            referencias: {
                type: Array,
                default: () => {
                    return []
                },
            },

            familiares: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },

        data() {
            return {
                selectedTab: null,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            getReferenciaRouteName: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return 'agregaReferencia'
                } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    {
                        return 'agregaReferenciaSite'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    {
                        return 'recursos_humanos.agrega_referencia_personal'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                    {
                        return 'enlace_c3.agrega_referencia'
                    }
                }
            },

            getFamiliarRouteName: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return 'profesionalizacion.agrega_familiar'
                } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    {
                        return 'registro_personal.agrega_familiar'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    {
                        return 'recursos_humanos.agrega_familiar'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                    {
                        return 'enlace_c3.agrega_familiar'
                    }
                }
            },
        },
    }
</script>
