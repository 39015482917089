import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        sugerenciaCursos: [],
    },

    getters: {
        getSugerenciaCursos: state => {
            return state.sugerenciaCursos
        },
    },

    mutations: {
        SET_SUGERENCIA_CURSOS(state, sugerenciaCursos) {
            state.sugerenciaCursos = sugerenciaCursos
        },
    },

    actions: {
        fetchSugerenciaCursos: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/sugerenciaCursos')
                .then(response => {
                    commit('SET_SUGERENCIA_CURSOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveSugerenciaCurso: async function(context, sugerenciaCurso) {
            await axios
                .post('/PoliciaCuadrantes/Profesionalizacion/personal/' + sugerenciaCurso.fk_idPersonal + '/sugerenciaCursos', sugerenciaCurso)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
