import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        grupoSanguineo: [],
    },

    getters: {
        getGrupoSanguineo: state => {
            return state.grupoSanguineo
        },
    },

    mutations: {
        SET_GRUPO_SANGUINEO(state, grupoSanguineo) {
            state.grupoSanguineo = grupoSanguineo
        },
    },

    actions: {
        fetchGrupoSanguineo: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/C3/catGrupoSanguineo')
                .then(response => {
                    commit('SET_GRUPO_SANGUINEO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
