import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        movimientoCargador: [],
        movimientoCargadorDisponibles: 0,
    },

    getters: {
        getMovimientoCargador: state => {
            return state.movimientoCargador
        },

        getMovimientoCargadorDisponibles: state => {
            return state.movimientoCargadorDisponibles
        },
    },

    mutations: {
        SET_MOVIMIENTO_CARGADOR(state, movimientoCargador) {
            state.movimientoCargador = movimientoCargador
        },

        SET_MOVIMIENTO_CARGADOR_DISPONIBLES(state, movimientoCargadorDisponibles) {
            state.movimientoCargadorDisponibles = movimientoCargadorDisponibles
        },
    },

    actions: {
        fetchMovimientoCargador: async function({ commit }, idCalibre) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Calibre/' + idCalibre + '/movimientosCargador')
                .then(response => {
                    commit('SET_MOVIMIENTO_CARGADOR', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchMovimientoCargadorDisponibles: async function({ commit }, idCalibre) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Calibre/' + idCalibre + '/movimientosCargador/disponibles')
                .then(response => {
                    commit('SET_MOVIMIENTO_CARGADOR_DISPONIBLES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMovimientoCargador: async function({ commit }, movimientoCargador) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/movimientosCargador', movimientoCargador)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateMovimientoCargador: async function({ commit }, movimientoCargador) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/movimientosCargador/' + movimientoCargador.id, movimientoCargador)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
