<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personalFotoCuip"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <div class="text-h5">Evaluación de desempeño</div>
                <v-stepper vertical non-linear v-model="activeStep">
                    <v-stepper-step step="1">Información general</v-stepper-step>
                    <v-stepper-content step="1">
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-card class="rounded-sm" elevation="4">
                                        <v-card-title>
                                            <div class="text-subtitle-1 font-weight-bold">
                                                Información general
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-form v-model="informacionGeneralForm">
                                                    <v-row>
                                                        <v-col>
                                                            <v-autocomplete
                                                                v-model="informacionGeneral.fk_idPersonalEvaluador"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="evaluadores"
                                                                item-value="idPersonal"
                                                                item-text="CUIP"
                                                                item-color="secondary"
                                                                :rules="required"
                                                                hint="Presione la tecla Enter para realizar la búsqueda"
                                                                persistent-hint
                                                                @keyup.enter="fetchEvaluadores($event.target.value)"
                                                            >
                                                                <template v-slot:label>Personal evaluador <span class="red--text">*</span> </template>
                                                                <template v-slot:item="data">
                                                                    <v-list-item-avatar>
                                                                        <v-img v-if="data.item.foto !== ''" :src="data.item.foto"></v-img>
                                                                        <v-icon v-else>mdi-account</v-icon>
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ data.item.CUIP }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ getFullName(data.item) }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="informacionGeneral.fk_idPeriodicidad"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="periodicidad"
                                                                item-value="id"
                                                                item-text="title"
                                                                item-color="secondary"
                                                                :rules="required"
                                                                @change="setFechaInicial($event)"
                                                            >
                                                                <template v-slot:label>Periodicidad <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-menu
                                                                v-model="menuFechaInicio"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        :value="fechaInicio"
                                                                        append-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        rounded
                                                                        filled
                                                                        dense
                                                                        color="secondary"
                                                                        background-color="primary"
                                                                        :rules="required"
                                                                    >
                                                                        <template v-slot:label>Fecha inicio <span class="red--text">*</span> </template>
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="informacionGeneral.fechaInicial"
                                                                    @input="menuFechaInicio = false"
                                                                ></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                :value="fechaFin"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                            >
                                                                <template v-slot:label>Fecha fin <span class="red--text">*</span> </template>
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-menu
                                                                v-model="menuFechaAplicacion"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        :value="fechaAplicacion"
                                                                        append-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        rounded
                                                                        filled
                                                                        dense
                                                                        color="secondary"
                                                                        background-color="primary"
                                                                        :rules="required"
                                                                    >
                                                                        <template v-slot:label
                                                                            >Fecha de aplicación <span class="red--text">*</span>
                                                                        </template>
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="informacionGeneral.fechaAplicacion"
                                                                    :min="fechaInicioPermitida"
                                                                    :max="fechaFinalPermitida"
                                                                    @input="menuFechaAplicacion = false"
                                                                ></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" align="center">
                                                            <v-btn
                                                                color="secondary"
                                                                @click="generaEvaluaciones()"
                                                                :disabled="loading || !informacionGeneralForm"
                                                                >Guardar información</v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-step step="2">Llenado de evaluaciones</v-stepper-step>
                    <v-stepper-content step="2">
                        <v-container>
                            <v-row>
                                <v-col v-if="disciplinaAdministrativa.length > 0">
                                    <v-card color="secondary" v-if="disciplinaAdministrativa.length > 0">
                                        <v-card-title>Disciplina administrativa</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ disciplinaAdministrativa[disciplinaAdministrativa.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <evaluacion-disciplina-administrativa-dialog-component
                                                :id-personal="id"
                                                :disciplina-administrativa="disciplinaAdministrativa[disciplinaAdministrativa.length - 1]"
                                            ></evaluacion-disciplina-administrativa-dialog-component>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col v-if="disciplinaOperativa.length > 0">
                                    <v-card color="secondary">
                                        <v-card-title>Disciplina operativa</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ disciplinaOperativa[disciplinaOperativa.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <evaluacion-disciplina-operativa-dialog-component
                                                :id-personal="id"
                                                :procedimientos="filteredProcedimientos"
                                                :disciplina-operativa="disciplinaOperativa[disciplinaOperativa.length - 1]"
                                            ></evaluacion-disciplina-operativa-dialog-component>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .v-data-table {
        overflow-x: auto !important;
    }
</style>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import EvaluacionDisciplinaAdministrativaDialogComponent from '../../components/desarrollo_policial/EvaluacionDisciplinaAdministrativaDialogComponent.vue'
    import EvaluacionDisciplinaOperativaDialogComponent from '../../components/desarrollo_policial/EvaluacionDisciplinaOperativaDialogComponent.vue'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        components: {
            EvaluacionDisciplinaAdministrativaDialogComponent,
            EvaluacionDisciplinaOperativaDialogComponent,
            PersonalInfoCardComponent,
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchEstatusProcedimiento()
            await this.fetchProcedimiento()
            await this.fetchReactivoPrincipio()
            await this.fetchTipoEvaluacion()
            await this.fetchIncidencia()
            // await this.fetchRespetoPrincipios(this.id)
            // await this.fetchDisciplinaAdministrativa(this.id)
            // await this.fetchDisciplinaOperativa(this.id)
            // await this.fetchProductividad(this.id)
            // await this.fetchRespetoPrincipiosValores(this.id)
            // await this.fetchRendimientoProfesionalismo(this.id)
            // await this.fetchDesempeñoInstitucional(this.id)
            await this.fetchFaltasAdministrativasByPersonal(this.id)
            await this.fetchTipoFaltaAdministrativa()
            await this.fetchProcedimientos(this.id)
        },

        data() {
            return {
                selectedTipoEvaluacion: null,
                loadingEvaluadores: false,

                activeStep: 1,
                loading: false,
                informacionGeneralForm: false,

                menuFechaInicio: false,
                menuFechaFin: false,
                menuFechaAplicacion: false,

                informacionGeneral: {
                    fk_idPersonalEvaluador: null,
                    fk_idPeriodicidad: null,
                    fechaInicial: null,
                    fechaFin: null,
                    fechaAplicacion: null,
                },

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('tipoEvaluacion', {
                tipoEvaluacion: 'getTipoEvaluacion',
            }),

            ...mapGetters('periodicidad', {
                periodicidad: 'getPeriodicidad',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonal',
                personalFotoCuip: 'getPersonalFotoCuip',
                evaluadores: 'getEvaluadores',
            }),

            ...mapGetters('respetoPrincipios', {
                respetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('disciplinaAdministrativa', {
                disciplinaAdministrativa: 'getDisciplinaAdministrativa',
            }),

            ...mapGetters('disciplinaOperativa', {
                disciplinaOperativa: 'getDisciplinaOperativa',
            }),

            ...mapGetters('productividad', {
                productividad: 'getProductividad',
            }),

            ...mapGetters('reactivosEvaluacion', {
                reactivosDesempenioInstitucional: 'getReactivosDesempenioInstitucional',
                reactivosRendimientoProfesionalismo: 'getReactivosRendimientoProfesionalismo',
            }),

            ...mapGetters('reactivoPrincipio', {
                reactivosRespetoPrincipios: 'getReactivoPrincipio',
            }),

            ...mapGetters('faltasAdministrativas', {
                faltasAdministrativas: 'getFaltasAdministrativas',
            }),

            ...mapGetters('procedimientos', {
                listaProcedimientos: 'getProcedimientos',
            }),

            filteredProcedimientos() {
                if (this.informacionGeneral.fechaAplicacion) {
                    return this.listaProcedimientos.filter(procedimiento => {
                        var x = moment(procedimiento.fecha, 'DD/MM/YYYY').format('YYYY-MM-DD')
                        return moment(x).isBetween(
                            moment(this.informacionGeneral.fechaAplicacion, 'YYYY-MM-DD').subtract(3, 'year'),
                            moment(this.informacionGeneral.fechaAplicacion, 'YYYY-MM-DD')
                        )
                    })
                } else return []
            },

            filteredFaltas() {
                if (this.informacionGeneral.fechaAplicacion) {
                    return this.faltasAdministrativas.filter(falta => {
                        var x = moment(falta.fechaDesde, 'DD/MM/YYYY').format('YYYY-MM-DD')
                        return moment(x).isBetween(
                            moment(this.informacionGeneral.fechaAplicacion, 'YYYY-MM-DD').subtract(3, 'year'),
                            moment(this.informacionGeneral.fechaAplicacion, 'YYYY-MM-DD')
                        )
                    })
                } else return []
            },

            fechaInicio: function() {
                return this.informacionGeneral.fechaInicial ? moment(this.informacionGeneral.fechaInicial).format('DD/MM/YYYY') : ''
            },

            fechaFin: function() {
                return this.informacionGeneral.fechaInicial
                    ? moment(this.informacionGeneral.fechaInicial)
                          .add(3, 'months')
                          .subtract(1, 'day')
                          .format('DD/MM/YYYY')
                    : ''
            },

            fechaAplicacion: function() {
                return this.informacionGeneral.fechaAplicacion ? moment(this.informacionGeneral.fechaAplicacion).format('DD/MM/YYYY') : ''
            },

            fechaInicioPermitida: function() {
                return this.fechaInicio != '' ? moment(this.fechaInicio, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
            },

            fechaFinalPermitida: function() {
                return this.fechaInicioPermitida
                    ? moment(this.fechaInicioPermitida)
                          .add(3, 'months')
                          .subtract(1, 'day')
                          .format('YYYY-MM-DD')
                    : ''
            },
        },

        methods: {
            ...mapActions('estatusProcedimiento', ['fetchEstatusProcedimiento']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),
            ...mapActions('procedimiento', ['fetchProcedimiento']),
            ...mapActions('reactivoPrincipio', ['fetchReactivoPrincipio']),

            ...mapActions('tipoEvaluacion', ['fetchTipoEvaluacion']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),

            ...mapActions('actosRelevantesEvaluacion', ['fetchActosRelevantesEvaluacion']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios', 'saveRespetoPrincipios']),
            ...mapActions('disciplinaAdministrativa', ['fetchDisciplinaAdministrativa', 'saveDisciplinaAdministrativa']),
            ...mapActions('disciplinaOperativa', ['fetchDisciplinaOperativa', 'saveDisciplinaOperativa']),
            ...mapActions('productividad', ['fetchProductividad', 'saveProductividad']),

            ...mapActions('incidencia', ['fetchIncidencia']),

            ...mapActions('faltasAdministrativas', ['fetchFaltasAdministrativasByPersonal']),
            ...mapActions('tipoFaltaAdministrativa', ['fetchTipoFaltaAdministrativa']),

            ...mapActions('procedimientos', ['fetchProcedimientos']),

            ...mapActions('personal', ['fetchEvaluadores']),

            async generaEvaluaciones() {
                this.loading = true

                await this.saveRespetoPrincipios({
                    puntajeTotal: 0,
                    calificacion: '0.0',
                    observaciones: '',
                    fechaAplicacion: moment(this.informacionGeneral.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fk_idPersonal: this.id,
                    fk_idResultado: 1,
                    fk_idPersonalEvaluador: this.informacionGeneral.fk_idPersonalEvaluador,
                    fk_idPeriodicidad: this.informacionGeneral.fk_idPeriodicidad,
                    frecuenciasMedicion: [],
                    fk_idCatEstatusEvaluacion: 1,
                })

                await this.saveDisciplinaOperativa({
                    fk_idPersonal: this.id,
                    fk_idPersonalEvaluador: this.informacionGeneral.fk_idPersonalEvaluador,
                    fk_idPeriodicidad: this.informacionGeneral.fk_idPeriodicidad,
                    instanciaInforma: '',
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fechaAplicacion: moment(this.informacionGeneral.fechaAplicacion).format('DD/MM/YYYY'),
                    procedimientos: [],
                    fk_idCatEstatusEvaluacion: 1,
                })

                await this.saveDisciplinaAdministrativa({
                    fk_idPersonal: this.id,
                    fk_idPersonalEvaluador: this.informacionGeneral.fk_idPersonalEvaluador,
                    fk_idPeriodicidad: this.informacionGeneral.fk_idPeriodicidad,
                    instanciaInforma: 'RECURSOS HUMANOS',
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fechaAplicacion: this.fechaAplicacion,
                    fk_idCatEstatusEvaluacion: 1,
                    incidencias: [
                        {
                            cantidad: 0,
                            fk_idCatIncidencia: 1,
                        },
                        {
                            cantidad: 0,
                            fk_idCatIncidencia: 2,
                        },
                        {
                            cantidad: this.getCantidadFaltas(6),
                            fk_idCatIncidencia: 3,
                        },
                        {
                            cantidad: this.getCantidadFaltas(3),
                            fk_idCatIncidencia: 4,
                        },
                        {
                            cantidad: this.getCantidadFaltas(2),
                            fk_idCatIncidencia: 5,
                        },
                    ],
                    reconocimientos: [],
                    estimulos: [],
                    actasAdministrativas: [],
                })

                await this.saveProductividad({
                    fk_idPersonal: this.id,
                    puntajeTotal: 0,
                    calificacion: 0,
                    fechaAplicacion: moment(this.productividad.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fkidPersonalEvaluador: this.informacionGeneral.fk_idPersonalEvaluador,
                    fkidResultado: 1,
                    fk_idCatEstatusEvaluacion: 1,
                    niveles: [],
                    factores: [],
                })

                await this.fetchDisciplinaAdministrativa(this.id)
                await this.fetchDisciplinaOperativa(this.id)

                this.disciplinaOperativa[this.disciplinaOperativa.length - 1].procedimientos = this.filteredProcedimientos

                this.activeStep++

                this.loading = false
            },

            setFechaInicial(event) {
                if (event == 1) {
                    this.informacionGeneral.fechaInicial = '2021-01-01'
                } else if (event == 2) {
                    this.informacionGeneral.fechaInicial = '2021-04-01'
                } else if (event == 3) {
                    this.informacionGeneral.fechaInicial = '2021-07-01'
                } else if (event == 4) {
                    this.informacionGeneral.fechaInicial = '2021-10-01'
                }
            },

            getCantidadFaltas: function(idFalta) {
                return this.filteredFaltas.filter(falta => {
                    return falta.fk_idTipoFalta == idFalta
                }).length
            },
        },
    }
</script>
