<template>
    <v-dialog v-model="registroArmaDialog" width="80%" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="secondary" rounded>Registrar arma nueva</v-btn>
        </template>
        <v-card class="rounded-sm" color="primary">
            <v-card-title>
                <div class="text-h6">Registro de arma nueva</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="armaForm">
                        <v-row>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-subtitle-1">Tipo y clase</div>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="selectedTipo"
                                            :items="tipoArma"
                                            item-value="id"
                                            item-text="title"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                        >
                                            <template v-slot:label>Tipo de arma <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="arma.fk_idClase"
                                            :items="filteredClaseArma"
                                            item-value="id"
                                            item-text="clase"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Clase de arma <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-subtitle-1">Marca y modelo</div>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            label="Marca"
                                            v-model="selectedMarca"
                                            :items="marcaArma"
                                            item-value="id"
                                            item-text="title"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            @change="fetchModeloArmas($event)"
                                        >
                                            <template v-slot:label>Marca <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            label="Modelo"
                                            v-model="arma.fk_idModelo"
                                            :items="modeloArma"
                                            item-value="id"
                                            item-text="modelo"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Modelo <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <v-select
                                            label="Calibre"
                                            v-model="arma.fk_idCalibre"
                                            :items="calibre"
                                            item-value="id"
                                            item-text="title"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Calibre <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Número económico"
                                            v-model.number="arma.noEconomico"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            min="0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Cantidad de cargadores"
                                            v-model.number="arma.cantidadCargadores"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            min="0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Folio"
                                            v-model="arma.folio"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Matrícula"
                                            v-model="arma.matricula"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            label="Folio C"
                                            v-model="arma.folioC"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Folio D"
                                            v-model="arma.folioD"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Armero"
                                            v-model="arma.armeros"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            v-model="arma.fk_idCatProcedenciaArma"
                                            :items="procedencia"
                                            item-value="id"
                                            item-text="title"
                                            item-color="secondary"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Procedencia <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="arma.fechaAltaLoc"
                                            v-mask="'##/##/####'"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :rules="dateRules"
                                        >
                                            <template v-slot:label>Fecha de alta en LOC <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="arma.fechaCompra"
                                            v-mask="'##/##/####'"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :rules="dateRules"
                                        >
                                            <template v-slot:label>Fecha de compra <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field
                                            v-model="arma.puestaDisposición"
                                            v-mask="'##/##/####'"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :rules="dateRules"
                                        >
                                            <template v-slot:label>Fecha de puesta a disposición <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="triggerSaveArma()" color="secondary" :loading="loading" :disabled="!armaForm || loading">Registrar arma</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                registroArmaDialog: false,
                loading: false,
                armaForm: false,

                arma: {
                    serie: '',
                    folio: '',
                    matricula: '',
                    noEconomico: 0,
                    folioC: '',
                    folioD: '',
                    loc: '',
                    fechaAltaLoc: '',
                    fk_idSubsede: null,
                    armeros: '',
                    fk_idModelo: null,
                    fk_idClase: null,
                    fk_idCalibre: null,
                    fk_idEstatus: null,
                    fk_idCatProcedenciaArma: null,
                    cantidadCargadores: 0,
                    fechaCompra: null,
                    puestaDisposición: null,
                },

                selectedTipo: null,
                selectedMarca: null,

                required: [v => !!v || 'Este campo es requerido.'],

                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('tipoArma', {
                tipoArma: 'getTipoArmas',
            }),

            ...mapGetters('claseArma', {
                claseArmaCorta: 'getClasesArmaCorta',
                claseArmaLarga: 'getClasesArmaLarga',
            }),

            ...mapGetters('marcaArma', {
                marcaArma: 'getMarcaArma',
            }),

            ...mapGetters('marcaArma', {
                marcaArma: 'getMarcaArma',
            }),

            ...mapGetters('modeloArma', {
                modeloArma: 'getModelosArmas',
            }),

            ...mapGetters('calibre', {
                calibre: 'getCalibres',
            }),

            ...mapGetters('estatusArma', {
                estatus: 'getEstatusArma',
            }),

            ...mapGetters('procedencia', {
                procedencia: 'getProcedencia',
            }),

            ...mapGetters('subsede', {
                subsede: 'getSubsedes',
            }),

            filteredClaseArma: function() {
                if (this.selectedTipo) {
                    if (this.selectedTipo == 1) {
                        return this.claseArmaLarga
                    } else return this.claseArmaCorta
                } else return []
            },
        },

        methods: {
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('arma', ['saveArma', 'fetchArmas']),

            closeDialog() {
                this.registroArmaDialog = false

                this.arma = {
                    serie: '',
                    folio: '',
                    matricula: '',
                    noEconomico: 0,
                    folioC: '',
                    folioD: '',
                    loc: '',
                    fechaAltaLoc: '',
                    fk_idSubsede: null,
                    armeros: '',
                    fk_idModelo: null,
                    fk_idClase: null,
                    fk_idCalibre: null,
                    fk_idEstatus: null,
                    fk_idCatProcedenciaArma: null,
                    cantidadCargadores: 0,
                }

                this.selectedTipo = null
                this.selectedMarca = null
                this.loading = false
                this.$refs.form.resetValidation()
            },

            async triggerSaveArma() {
                this.loading = true
                await this.saveArma(this.arma)
                await this.fetchArmas()
                this.closeDialog()
            },
        },
    }
</script>
