import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        convocatoriaPersonal: [],
    },

    getters: {
        getConvocatoriaPersonal: state => {
            return state.convocatoriaPersonal
        },
    },

    mutations: {
        SET_CONVOCATORIA_PERSONAL(state, convocatoriaPersonal) {
            state.convocatoriaPersonal = convocatoriaPersonal
        },
    },

    actions: {
        fetchConvocatoriaPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/convocatoriaPersonal')
                .then(response => {
                    commit('SET_CONVOCATORIA_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveConvocatoriaPersonal: async function(context, convocatoriaPersonal) {
            try {
                await Promise.all(
                    convocatoriaPersonal.selectedPersonal.map(async personal => {
                        await axios
                            .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + personal + '/convocatoriaPersonal', {
                                fk_idConvocatoria: convocatoriaPersonal.fk_idConvocatoria,
                            })
                            .then(response => {
                                context.commit('SET_SNACKBAR_MESSAGE', 'Personal asignado a convocatoria correctamente.', {
                                    root: true,
                                })
                            })
                            .catch(error => console.log(error))
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },

        updateConvocatoriaPersonal: async function(context, convocatoriaPersonal) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        convocatoriaPersonal.fk_idPersonal +
                        '/convocatoriaPersonal/' +
                        convocatoriaPersonal.idConvocatoria,
                    convocatoriaPersonal
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Condecoración editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
