import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        actosRelevantes: [],
    },

    getters: {
        getActosRelevantes: state => {
            return state.actosRelevantes
        },
    },

    mutations: {
        SET_ACTOS_RELEVANTES(state, actosRelevantes) {
            state.actosRelevantes = actosRelevantes
        },
    },

    actions: {
        fetchActosRelevantes: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/actosrelevantes')
                .then(response => {
                    commit('SET_ACTOS_RELEVANTES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
