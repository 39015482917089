<template>
    <v-main class="primary">
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading"></loader-component>
            <router-view v-else></router-view>
        </transition>
    </v-main>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import LoaderComponent from '../../components/LoaderComponent.vue'

    export default {
        components: {
            LoaderComponent,
        },

        async created() {
            this.ENABLE_LOADER()
            this.$vuetify.theme.themes.light.primary = '#fff2f8'
            this.$vuetify.theme.themes.light.secondary = '#9E2120'
            this.$vuetify.theme.themes.light.accent = '#F58634'
            this.$vuetify.theme.themes.light.anchor = '#34A871'
            await this.fetchTipoAmbito()
            await this.fetchAspirantes()
            await this.fetchPreaspirantes()
            await this.fetchAreas()
            await this.fetchTipoPuesto()
            await this.fetchTipoFuncion()
            await this.fetchRamas()
            await this.fetchDependencias()
            await this.fetchAlertas()
            await this.fetchEstadoCivil()
            await this.fetchTipoNacionalidad()
            await this.fetchSexo()
            await this.fetchGrupoSanguineo()
            await this.fetchEstados()
            await this.fetchMunicipios()

            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
            }),
        },

        methods: {
            ...mapActions('personal', ['fetchPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes', 'fetchAspirantes']),
            ...mapActions('tipoAmbito', ['fetchTipoAmbito']),
            ...mapActions('pendientesConsultaPrevia', ['fetchPendientesConsultaPrevia']),
            ...mapActions('areas', ['fetchAreas']),
            ...mapActions('rama', ['fetchRamas']),
            ...mapActions('tipoPuesto', ['fetchTipoPuesto']),
            ...mapActions('puesto', ['fetchPuestos']),
            ...mapActions('tipoFuncion', ['fetchTipoFuncion']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('dependencia', ['fetchDependencias']),
            ...mapActions('alerta', ['fetchAlertas']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('sexo', ['fetchSexo']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),

            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),
        },
    }
</script>
