import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        convocatoria: [],
    },

    getters: {
        getConvocatoria: state => {
            return state.convocatoria
        },
    },

    mutations: {
        SET_CONVOCATORIA(state, convocatoria) {
            state.convocatoria = convocatoria
        },
    },

    actions: {
        fetchConvocatoria: async function({ commit }, idTipoConvocatoria) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/tipo/' + idTipoConvocatoria + '/convocatoria')
                .then(response => {
                    commit('SET_CONVOCATORIA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveConvocatoria: async function(context, convocatoria) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/convocatoria', convocatoria)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Convocatoria registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateConvocatoria: async function(context, convocatoria) {
            await axios
                .put('/PoliciaCuadrantes/DesarrolloPolicial/convocatoria/' + convocatoria.idConvocatoria, convocatoria)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Convocatoria actualizada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
