<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Catálogo de convocatorias</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="end" align="center">
                                <v-col cols="2">
                                    <registro-convocatoria-dialog-component></registro-convocatoria-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaConvocatoria"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar convocatoria"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table class="primary" :headers="headers" :items="convocatorias" :search="buscaConvocatoria" calculate-widths>
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn icon color="secondary" @click="openEditarConvocatoriaDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                            <v-btn icon color="secondary" @click="openAsignaPersonalDialog(item)"
                                                ><v-icon>mdi-account-multiple-plus</v-icon></v-btn
                                            >
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <editar-convocatoria-dialog-component
            :active="editConvocatoriaDialog"
            :convocatoria="selectedConvocatoria"
            @close="editConvocatoriaDialog = false"
        ></editar-convocatoria-dialog-component>
        <asigna-personal-dialog-component
            :active="asignaPersonalDialog"
            :convocatoria="selectedConvocatoria"
            @close="asignaPersonalDialog = false"
        ></asigna-personal-dialog-component>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    import AsignaPersonalDialogComponent from '../../components/desarrollo_policial/convocatorias/AsignaPersonalDialogComponent.vue'
    import EditarConvocatoriaDialogComponent from '../../components/desarrollo_policial/convocatorias/EditarConvocatoriaDialogComponent.vue'
    import RegistroConvocatoriaDialogComponent from '../../components/desarrollo_policial/convocatorias/RegistroConvocatoriaDialogComponent.vue'

    export default {
        components: {
            RegistroConvocatoriaDialogComponent,
            EditarConvocatoriaDialogComponent,
            AsignaPersonalDialogComponent,
        },

        data() {
            return {
                buscaConvocatoria: '',

                headers: [
                    { text: 'Nombre', value: 'convocatoria', width: '20%' },
                    { text: 'Dirigida a', value: 'dirigidaA', width: '20%' },
                    { text: 'Lugar de recepción de documentos', value: 'lugarRecepcionDocumentos', width: '20%' },
                    { text: 'Fecha de publicación', value: 'fechaHoraPublicacionConvocatoria', width: '10%' },
                    { text: 'Fecha de inicio', value: 'fechaHoraInicioRegistro', width: '10%' },
                    { text: 'Fecha de cierre', value: 'fechaHoraCierreRegistro', width: '10%' },
                    { text: 'Opciones', value: 'opciones', width: '10%' },
                ],

                editConvocatoriaDialog: false,
                asignaPersonalDialog: false,
                selectedConvocatoria: null,
            }
        },

        computed: {
            ...mapGetters('convocatoria', {
                convocatorias: 'getConvocatoria',
            }),
        },

        methods: {
            openEditarConvocatoriaDialog(convocatoria) {
                this.selectedConvocatoria = convocatoria
                this.editConvocatoriaDialog = true
            },

            openAsignaPersonalDialog(convocatoria) {
                this.selectedConvocatoria = convocatoria
                this.asignaPersonalDialog = true
            },
        },
    }
</script>
