import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposEntrega: [],
    },

    getters: {
        getTiposEntrega: state => {
            return state.tiposEntrega
        },
    },

    mutations: {
        SET_TIPOS_ENTREGA: (state, tiposEntrega) => {
            state.tiposEntrega = tiposEntrega
        },
    },

    actions: {
        fetchTiposEntrega: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoEntrega')
                .then(response => {
                    commit('SET_TIPOS_ENTREGA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
