import Vue from 'vue'
import VueParticles from 'vue-particles'
import App from './App.vue'
import router from './router'
import store from './store'
import device from 'vue-device-detector'
import '@babel/polyfill'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
import moment from 'moment'
import VueMask from 'v-mask'

Vue.config.productionTip = false

Vue.use(VueParticles)
Vue.use(device)
Vue.use(VueApexCharts)
Vue.use(VueMask)

Vue.component('apexchart', VueApexCharts)
Vue.component('kinesis-container', KinesisContainer)
Vue.component('kinesis-element', KinesisElement)

Vue.mixin({
    methods: {
        formatDate: function(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            } else return null
        },
        getDateText(fecha) {
            return moment(fecha, 'DD/MM/YYYY').isValid()
                ? moment(fecha, 'DD/MM/YYYY').format('DD') +
                      ' de ' +
                      moment(fecha, 'DD/MM/YYYY').format('MMMM') +
                      ' del ' +
                      moment(fecha, 'DD/MM/YYYY').format('YYYY')
                : ''
        },
        getToday: function() {
            return moment().format('YYYY-MM-DD')
        },
        getFullName(person) {
            if (person.segundoNombre == '') {
                return person.apaterno + ' ' + person.amaterno + ' ' + person.nombre
            } else {
                return person.apaterno + ' ' + person.amaterno + ' ' + person.nombre + person.segundoNombre
            }
        },
        getAge(birthdayDate) {
            return moment().diff(moment(birthdayDate, 'DD/MM/YYYY'), 'years') ? moment().diff(moment(birthdayDate, 'DD/MM/YYYY'), 'years') + ' años' : 'N/D'
        },
        logout() {
            localStorage.removeItem('nombre')
            localStorage.removeItem('token')
            localStorage.removeItem('perfil')
            localStorage.removeItem('idPersonal')
        },
        download: function(url) {
            window.open(url, '_blank')
        },
        getDocumentType: function(name) {
            if (name.substr(name.length - 3).toLowerCase() == 'jpg' || name.substr(name.length - 3).toLowerCase() == 'png') {
                return 'img'
            } else if (name.substr(name.length - 3).toLowerCase() == 'pdf') {
                return 'pdf'
            } else {
                return '0'
            }
        },

        tieneC3Vigente(fecha_vigencia) {
            if (fecha_vigencia !== '') {
                return moment(fecha_vigencia, 'DD/MM/YYYY').diff(moment(), 'months', true) > 7 ? true : false
            } else return false
        },
    },
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
