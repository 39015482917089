<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="card" color="primary">
            <v-card-title>
                <div class="text-h6">Lista de sanciones</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-virtual-scroll height="480" item-height="96" :items="sanciones">
                    <template v-slot:default="{ item }">
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.motivo }}</v-list-item-title>
                                <v-list-item-subtitle>No. oficio: {{ item.oficio }}</v-list-item-subtitle>
                                <!-- <v-list-item-subtitle>Registrada por: {{ getFullName(item.PersonalAutorizo) }}</v-list-item-subtitle> -->
                                <v-list-item-subtitle>Descripción: {{ item.descripcion }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-switch
                                    v-model="item.vigente"
                                    @change="triggerUpdateVigencia($event, item)"
                                    color="secondary"
                                    :disabled="loading"
                                    :loading="loading"
                                ></v-switch>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            personal: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('sanciones', {
                sanciones: 'getSanciones',
            }),
        },

        methods: {
            ...mapActions('sanciones', ['updateVigenciaSancion']),

            closeDialog() {
                this.$emit('close')
            },

            async triggerUpdateVigencia(event, sancion) {
                this.loading = true
                await this.updateVigenciaSancion({
                    fk_idPersonal: this.personal.idPersonal,
                    idSancion: sancion.idSancionAI,
                    vigencia: event,
                })
                this.loading = false
            },
        },
    }
</script>
