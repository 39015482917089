import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        alertas: [],
    },

    getters: {
        getAlertas: state => {
            return state.alertas
        },
    },

    mutations: {
        SET_ALERTAS(state, alertas) {
            state.alertas = alertas
        },
    },

    actions: {
        fetchAlertas: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/alerta')
                .then(response => {
                    commit('SET_ALERTAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
