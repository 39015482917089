<template>
    <v-alert :type="getAlertType(vigenciaCup)" dense :color="vigenciaCup === '' ? 'blue-grey' : ''">
        {{ getAlertText(vigenciaCup) }}
    </v-alert>
</template>
<script>
    import moment from 'moment'

    export default {
        props: {
            vigenciaCup: {
                type: String,
            },
        },

        methods: {
            getAlertType(fecha_vigencia_cup) {
                if (fecha_vigencia_cup !== '') {
                    return moment(fecha_vigencia_cup, 'DD/MM/YYYY').diff(moment(), 'months', true) > 7 ? 'success' : 'error'
                } else return 'info'
            },

            getAlertText(fecha_vigencia_cup) {
                if (fecha_vigencia_cup !== '') {
                    return 'CUP: ' + this.getDateText(fecha_vigencia_cup)
                } else return 'CUP: Fecha no registrada'
            },
        },
    }
</script>
