<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Movimiento de entrada</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="movimientoForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model.number="movimiento.cantidad"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            min="0"
                                        >
                                            <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="movimiento.observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="4"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn
                            color="secondary"
                            class="primary--text mb-4"
                            :loading="loading"
                            v-on:click="triggerMovimiento()"
                            :disabled="movimiento.cantidad == 0 || loading"
                            >Guardar movimiento</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        async mounted() {
            await this.fetchTipoMovimiento()
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                movimientoForm: false,
                loading: false,
                movimiento: {
                    fk_idCatItem: this.id,
                    fk_idcatTipoMovimiento: 1,
                    cantidad: 0,
                    observaciones: '',
                },

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('tipoMovimientoEquipamiento', {
                tipoMovimiento: 'getTipoMovimiento',
            }),
        },

        methods: {
            ...mapActions('movimientoEquipamiento', ['saveMovimientoEquipamiento']),
            ...mapActions('tipoMovimientoEquipamiento', ['fetchTipoMovimiento']),
            ...mapActions('equipo', ['fetchEquipo']),

            triggerMovimiento: async function() {
                this.loading = true
                await this.saveMovimientoEquipamiento(this.movimiento)
                await this.fetchEquipo()
                this.loading = false
                this.$router.go(-1)
            },
        },
    }
</script>
