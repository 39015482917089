import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cargadores: [],
        cargadoresArma: [],
    },

    getters: {
        getCargadores: state => {
            return state.cargadores
        },

        GETcargadoresArma: state => {
            return state.cargadoresArma
        },
    },

    mutations: {
        SET_CARGADORES(state, cargadores) {
            state.cargadores = cargadores
        },

        SET_CARGADORES_ARMA(state, cargadoresArma) {
            state.cargadoresArma = cargadoresArma
        },
    },

    actions: {
        fetchCargadores: async function({ commit }, idCalibre) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Calibre/' + idCalibre + '/cargadores')
                .then(response => {
                    commit('SET_CARGADORES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchCargadoresByArma: async function({ commit }, idArma) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Arma/' + idArma + '/cargadores')
                .then(response => {
                    commit('SET_CARGADORES_ARMA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCargador: async function(context, cargador) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/cargadores', cargador)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Registro de cargador realizado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateCargador: async function(context, cargador) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/cargadores/' + cargador.idCargador, cargador)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Cargador editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
