import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        regiones: [],
    },

    getters: {
        getRegiones: state => {
            return state.regiones
        },
    },

    mutations: {
        SET_REGIONES(state, regiones) {
            state.regiones = regiones
        },
    },

    actions: {
        fetchRegiones: async function({ commit }) {
            await axios
                .get('/EonCloud/common/regiones')
                .then(response => {
                    commit('SET_REGIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchRegionesByRama: async function({ commit }, rama) {
            await axios
                .get('/EonCloud/common/ramas/' + rama.id + '/regiones')
                .then(response => {
                    commit('SET_REGIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
