import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        frecuenciaProductividad: [],
    },

    getters: {
        getFrecuenciaProductividad: state => {
            return state.frecuenciaProductividad
        },
    },

    mutations: {
        SET_FRECUENCIA_PRODUCTIVIDAD(state, frecuenciaProductividad) {
            state.frecuenciaProductividad = frecuenciaProductividad
        },
    },

    actions: {
        fetchFrecuenciaProductividad: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/frecuenciasproductividad')
                .then(response => {
                    commit('SET_FRECUENCIA_PRODUCTIVIDAD', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
