<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Media filiación</div>
            <template v-if="mediaFiliacion.length > 0">
                <v-tabs v-model="selectedTab" color="secondary">
                    <v-tab class="font-weight-bold">Complexión</v-tab>
                    <v-tab class="font-weight-bold">Piel</v-tab>
                    <v-tab class="font-weight-bold">Cara</v-tab>
                    <v-tab class="font-weight-bold">Cabello</v-tab>
                    <v-tab class="font-weight-bold">Frente</v-tab>
                    <v-tab class="font-weight-bold">Cejas</v-tab>
                    <v-tab class="font-weight-bold">Ojos</v-tab>
                    <v-tab class="font-weight-bold">Nariz</v-tab>
                    <v-tab class="font-weight-bold">Boca</v-tab>
                    <v-tab class="font-weight-bold">Labios</v-tab>
                    <v-tab class="font-weight-bold">Mentón</v-tab>
                    <v-tab class="font-weight-bold">Oreja derecha</v-tab>
                </v-tabs>
            </template>
            <template v-else>
                <v-container>
                    <v-row>
                        <v-col cols="12" align="center">
                            <div class="text-h6">Este elemento no cuenta con media filiación registrada</div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-card-title>
        <v-card-text>
            <template v-if="mediaFiliacion.length > 0">
                <v-tabs-items v-model="selectedTab" class="transparent">
                    <v-tab-item key="1">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Complexión</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Complexión"
                                                :value="getMediaFiliacionByRasgo(6)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Estatura"
                                                :value="personal.estatura"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Peso"
                                                :value="personal.peso"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-h6 font-weight-bold">Señas particulares</div>
                                </v-col>
                                <v-col cols="4" v-for="(seña, index) in seniasParticulares" :key="index">
                                    <v-card color="primary" class="card">
                                        <v-card-title class="justify-center">
                                            <div class="text-h6 font-weight-bold">
                                                {{ seña.CatTipo.title }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-caption">Descripción</div>
                                                        <div class="text-subtitle-2">{{ seña.descripcion }}</div>
                                                    </v-col>
                                                    <v-col align="center">
                                                        <div class="text-caption">Región</div>
                                                        <div class="text-subtitle-2">{{ seña.CatRegion.title }}</div>
                                                    </v-col>
                                                    <v-col align="center">
                                                        <div class="text-caption">Lado</div>
                                                        <div class="text-subtitle-2">{{ seña.CatLado.title }}</div>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col align="center">
                                                        <div class="text-caption">Cantidad</div>
                                                        <div class="text-subtitle-2">{{ seña.CatCantidad.title }}</div>
                                                    </v-col>
                                                    <v-col align="center">
                                                        <div class="text-caption">Vista</div>
                                                        <div class="text-subtitle-2">{{ seña.CatVista.title }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="2">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Piel</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Piel"
                                                :value="getMediaFiliacionByRasgo(10)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="3">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Cara</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Cara"
                                                :value="getMediaFiliacionByRasgo(5)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="4">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Cabello</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Cantidad"
                                                :value="getMediaFiliacionByRasgo(14)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Color"
                                                :value="getMediaFiliacionByRasgo(16)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Forma"
                                                :value="getMediaFiliacionByRasgo(4)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Calvicie"
                                                :value="getMediaFiliacionByRasgo(23)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Implantación"
                                                :value="getMediaFiliacionByRasgo(24)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="5">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Frente</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Altura"
                                                :value="getMediaFiliacionByRasgo(17)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Inclinación"
                                                :value="getMediaFiliacionByRasgo(25)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Ancho"
                                                :value="getMediaFiliacionByRasgo(26)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="6">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Cejas</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Dirección"
                                                :value="getMediaFiliacionByRasgo(15)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Implantación"
                                                :value="getMediaFiliacionByRasgo(27)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Forma"
                                                :value="getMediaFiliacionByRasgo(15)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Tamaño"
                                                :value="getMediaFiliacionByRasgo(28)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="7">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Ojos</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Color"
                                                :value="getMediaFiliacionByRasgo(7)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Forma"
                                                :value="getMediaFiliacionByRasgo(20)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Tamaño"
                                                :value="getMediaFiliacionByRasgo(29)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="8">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Nariz</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Raíz (Profundidad)"
                                                :value="getMediaFiliacionByRasgo(9)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Dorso"
                                                :value="getMediaFiliacionByRasgo(30)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Ancho"
                                                :value="getMediaFiliacionByRasgo(31)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Base"
                                                :value="getMediaFiliacionByRasgo(32)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Altura"
                                                :value="getMediaFiliacionByRasgo(33)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="9">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Boca</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Tamaño"
                                                :value="getMediaFiliacionByRasgo(13)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Comisuras"
                                                :value="getMediaFiliacionByRasgo(34)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="10">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Labios</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Espesor"
                                                :value="getMediaFiliacionByRasgo(35)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Altura naso-labial"
                                                :value="getMediaFiliacionByRasgo(36)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Prominencia"
                                                :value="getMediaFiliacionByRasgo(37)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="11">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Mentón</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Espesor"
                                                :value="getMediaFiliacionByRasgo(38)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Altura naso-labial"
                                                :value="getMediaFiliacionByRasgo(39)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Prominencia"
                                                :value="getMediaFiliacionByRasgo(40)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="12">
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Forma</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Forma"
                                                :value="getMediaFiliacionByRasgo(41)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Hélix</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Original"
                                                :value="getMediaFiliacionByRasgo(42)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Superior"
                                                :value="getMediaFiliacionByRasgo(43)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Posterior"
                                                :value="getMediaFiliacionByRasgo(44)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Adherencia"
                                                :value="getMediaFiliacionByRasgo(45)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Lóbulo</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Contorno"
                                                :value="getMediaFiliacionByRasgo(46)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Adherencia"
                                                :value="getMediaFiliacionByRasgo(47)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Particularidad"
                                                :value="getMediaFiliacionByRasgo(48)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                            <v-text-field
                                                label="Dimensión"
                                                :value="getMediaFiliacionByRasgo(49)"
                                                rounded
                                                readonly
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            mediaFiliacion: {
                type: Array,
                default: [],
            },

            seniasParticulares: {
                type: Array,
            },

            personal: {
                type: Object,
            },
        },

        data() {
            return {
                selectedTab: null,
            }
        },

        methods: {
            getMediaFiliacionByRasgo: function(id_rasgo) {
                var filiacion = this.mediaFiliacion.filter(filiacion => {
                    return filiacion.Cat_mediafiliacion.fk_idRasgo == id_rasgo && filiacion.estatus == true
                })

                if (filiacion.length > 0) {
                    return filiacion[0].Cat_mediafiliacion.nombre
                } else {
                    return 'NO ASIGNADO'
                }
            },
        },
    }
</script>
