import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        indicadores: [],
    },

    getters: {
        getIndicadores: state => {
            return state.indicadores
        },
    },

    mutations: {
        SET_INDICADORES(state, indicadores) {
            state.indicadores = indicadores
        },
    },

    actions: {
        fetchIndicadores: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/indicadores')
                .then(response => {
                    commit('SET_INDICADORES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
