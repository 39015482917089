<template>
    <v-container>
        <v-row class="mt-8" align="center">
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <trainer-form-component></trainer-form-component>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import TrainerFormComponent from '../../components/TrainerFormComponent'

    export default {
        components: {
            TrainerFormComponent,
        },
    }
</script>
