import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        courseTypes: [],
    },
    getters: {
        getCourseTypes: state => {
            return state.courseTypes
        },
    },

    mutations: {
        SET_COURSE_TYPES(state, courseTypes) {
            state.courseTypes = courseTypes
        },
    },

    actions: {
        fetchCourseTypes: async function(context) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/tipoCursos')
                .then(response => {
                    context.commit('SET_COURSE_TYPES', response.data.data)
                    context.state.courseTypes.forEach(courseType => {
                        context.dispatch('categoriaCurso/fetchCategoriasCursos', courseType, { root: true })
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
