<template>
    <transition name="fade" mode="out-in">
        <!-- <loader-component v-if="loading" key="1"></loader-component> -->
        <!-- <v-app v-else> -->
        <v-app>
            <navigation-drawer-component></navigation-drawer-component>
            <app-bar-component></app-bar-component>
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
            <v-snackbar v-model="snackbar.active" color="eon">
                {{ snackbar.message }}
            </v-snackbar>
        </v-app>
    </transition>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import AppBarComponent from './components/AppBarComponent.vue'
    import LoaderComponent from './components/LoaderComponent'
    import NavigationDrawerComponent from './components/NavigationDrawerComponent.vue'

    export default {
        name: 'App',

        components: { LoaderComponent, NavigationDrawerComponent, AppBarComponent },

        async mounted() {
            if (this.$route.matched.some(({ path }) => path === '/armeria')) {
                this.$vuetify.theme.themes.light.primary = '#f4f2ff'
                this.$vuetify.theme.themes.light.secondary = '#2F1BA8'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#2F1BA8'
            } else if (this.$route.matched.some(({ path }) => path === '/equipamiento')) {
                this.$vuetify.theme.themes.light.primary = '#FFF8F2'
                this.$vuetify.theme.themes.light.secondary = '#A85113'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#A85113'
            } else if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                this.$vuetify.theme.themes.light.primary = '#f2fff8'
                this.$vuetify.theme.themes.light.secondary = '#34A871'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#34A871'
            } else if (this.$route.matched.some(({ path }) => path === '/desarrollo_profesional')) {
                this.$vuetify.theme.themes.light.primary = '#fffbf2'
                this.$vuetify.theme.themes.light.secondary = '#F5B940'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#F5B940'
            } else if (this.$route.matched.some(({ path }) => path === '/c3')) {
                this.$vuetify.theme.themes.light.primary = '#f2fff8'
                this.$vuetify.theme.themes.light.secondary = '#34A871'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#34A871'
            } else if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                this.$vuetify.theme.themes.light.primary = '#fffbf2'
                this.$vuetify.theme.themes.light.secondary = '#F5B940'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#F5B940'
            } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                this.$vuetify.theme.themes.light.primary = '#fff4f2'
                this.$vuetify.theme.themes.light.secondary = '#F54127'
                this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#34A871'
            } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                this.$vuetify.theme.themes.light.primary = '#fff2f8'
                this.$vuetify.theme.themes.light.secondary = '#9E2120'
                // this.$vuetify.theme.themes.light.accent = '#F58634'
                this.$vuetify.theme.themes.light.anchor = '#34A871'
            }

            // this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
                snackbar: 'getSnackbar',
                token: 'getToken',
            }),
        },

        methods: {
            ...mapActions('capacitador', ['fetchCapacitadores']),
            ...mapActions('aula', ['fetchAulas']),
            ...mapActions('curso', ['fetchCursos']),
            ...mapActions('cursoProgramado', ['fetchCursosProgramados']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignados']),
            ...mapActions('categoriaCurso', ['fetchCategoriasCursos']),
            ...mapActions('estatusCurso', ['fetchEstatusCurso']),
            ...mapActions('estatusCursoPersonal', ['fetchEstatusCursoPersonal']),
            ...mapActions('estatusAula', ['fetchClassroomStatus']),
            ...mapActions('tipoCurso', ['fetchCourseTypes']),

            ...mapActions('estatusEstudios', ['fetchEstatusEstudios']),
            ...mapActions('calibre', ['fetchCalibres']),
            ...mapActions('tipoArma', ['fetchTiposArma']),
            ...mapActions('marcaArma', ['fetchMarcaArma']),
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('estatusArma', ['fetchEstatusArma']),
            ...mapActions('arma', ['fetchArmas']),
            ...mapActions('tipoAsignacionArma', ['fetchTipoAsignacion']),
            ...mapActions('tipoEntregaArma', ['fetchTiposEntrega']),
            ...mapActions('licenciaOficialColectiva', ['fetchLocs']),

            ...mapActions('personal', ['fetchPersonal']),

            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios']),
            ...mapActions('principio', ['fetchPrincipios']),
            ...mapActions('reactivo', ['fetchReactivos']),
            ...mapActions('frecuenciaRespetoPrincipio', ['fetchFrecuenciaRespetoPrincipio']),
            ...mapActions('periodicidad', ['fetchPeriodicidad']),
            ...mapActions('resultado', ['fetchResultados']),
            ...mapActions('productividad', ['fetchProductividad']),
            ...mapActions('funcion', ['fetchFunciones']),
            ...mapActions('indicador', ['fetchIndicadores']),
            ...mapActions('frecuenciaProductividad', ['fetchFrecuenciaProductividad']),
            ...mapActions('actoRelevante', ['fetchActosRelevantes']),

            ...mapActions('tipoItem', ['fetchTiposItem']),

            ...mapActions('item', ['fetchItemsByType']),

            ...mapActions('preaspirante', ['fetchPreaspirantes']),

            ...mapActions('candidato', ['fetchCandidatos']),
            ...mapActions('categoria', ['fetchCategorias']),

            ...mapActions('sede', ['fetchSedes']),
            ...mapActions('subsede', ['fetchSubsedes']),
            ...mapActions('puesto', ['fetchPuestos']),
            ...mapActions('dependencia', ['fetchDependencias']),

            ...mapActions('estudio', ['fetchTipoEstudios']),

            ...mapActions('perfil', ['fetchPerfiles']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('estado', ['fetchEstados']),

            ...mapActions('estatusPersonal', ['fetchEstatusPersonal']),
            ...mapActions('tipoDocumento', ['fetchTipoDocumento']),

            ...mapActions('preaspirante', ['fetchAspirantes']),

            ...mapActions('rama', ['fetchRamas']),
            ...mapActions('region', ['fetchRegiones']),
            ...mapMutations(['DISABLE_LOADER']),
        },
    }
</script>
