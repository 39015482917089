import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        bitacoraEntregaRecepcion: [],
    },

    getters: {
        getBitacoraEntregaRecepcion: state => {
            return state.bitacoraEntregaRecepcion
        },
    },

    mutations: {
        SET_BITACORA_ENTREGA_RECEPCION: (state, bitacoraEntregaRecepcion) => {
            state.bitacoraEntregaRecepcion = bitacoraEntregaRecepcion
        },
    },

    actions: {
        fetchBitacoraEntregaRecepcion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/bitacoraER')
                .then(response => {
                    commit('SET_BITACORA_ENTREGA_RECEPCION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
