<template>
    <v-container v-if="preaspirante">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="#f2fff8">
                    <v-card-title class="font-weight-bold justify-center">Archivar personal</v-card-title>
                    <v-card-text>
                        <div class="text-body-1" align="center">
                            ¿Desea archivar al elemento {{ getFullName(preaspirante) }}? Al realizar este paso, no se mostrará más en el catálogo de
                            preaspirantes.
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="px-8" color="error" to="/profesionalizacion/preaspirantes">Cancelar</v-btn>
                        <v-btn color="#34A871" class="primary--text px-8" v-on:click="archivar()">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),
        },

        methods: {
            ...mapActions('personal', ['updatePersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),

            archivar: async function() {
                this.preaspirante.fk_idEstatus = 9
                await this.updatePersonal(this.preaspirante)
                await this.fetchPreaspirantes()
                this.$router.push('/profesionalizacion/preaspirantes')
            },
        },
    }
</script>
