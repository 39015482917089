var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"align":"center","fill-height":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card",attrs:{"color":"primary"}},[_c('v-card-title',[_vm._v("Personal registrado en LOC")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Filtrar por puesto","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary","items":_vm.puestos,"item-value":"id","item-text":"catPuesto.puesto","item-color":"secondary","clearable":""},model:{value:(_vm.filtroPuesto),callback:function ($$v) {_vm.filtroPuesto=$$v},expression:"filtroPuesto"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Filtrar por tipo de funcion","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary","items":_vm.tipoPuesto,"item-value":"id","item-text":"title","item-color":"secondary","clearable":""},model:{value:(_vm.filtroFuncion),callback:function ($$v) {_vm.filtroFuncion=$$v},expression:"filtroFuncion"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rounded":"","filled":"","dense":"","hide-details":"","background-color":"primary","color":"secondary","label":"Buscar personal","append-icon":"mdi-magnify"},model:{value:(_vm.buscaPersonal),callback:function ($$v) {_vm.buscaPersonal=$$v},expression:"buscaPersonal"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.headers,"items":_vm.filteredPersonal,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center pt-4"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":item.foto}})],1),_c('div',[_c('div',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getFullName(item))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.CUIP)+" ")]),(item.CatPuestoDependencia)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.CatPuestoDependencia.catPuesto.puesto)+" ")]):_vm._e()])],1)]}},{key:"item.vigenciaEvaluacionC3",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.tieneC3Vigente(item.vigenciaEvaluacionC3)
                                                    ? 'success--text font-weight-bold'
                                                    : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(item.vigenciaEvaluacionC3)+" ")])]}},{key:"item.opciones",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","rounded":"","small":""},on:{"click":function($event){return _vm.triggerSavePersonalLoc(item.idPersonal)}}},[_vm._v("Registrar en LOC")])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":999,"align":"center","color":"transparent","opacity":"1"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }