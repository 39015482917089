<template>
    <v-main class="primary">
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading"></loader-component>
            <router-view v-else></router-view>
        </transition>
    </v-main>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import LoaderComponent from '../../components/LoaderComponent.vue'

    export default {
        components: {
            LoaderComponent,
        },

        async created() {
            this.ENABLE_LOADER()
            this.$vuetify.theme.themes.light.primary = '#f4f2ff'
            this.$vuetify.theme.themes.light.secondary = '#2F1BA8'
            this.$vuetify.theme.themes.light.accent = '#F58634'
            this.$vuetify.theme.themes.light.anchor = '#34A871'
            await this.fetchCalibres()
            await this.fetchTiposArma()
            await this.fetchMarcaArma()
            await this.fetchEstatusArma()
            await this.fetchArmas()
            await this.fetchTipoAsignacion()
            await this.fetchTiposEntrega()
            await this.fetchEstatusMunicion()
            await this.fetchProcedencia()
            await this.fetchTipoItem()
            await this.fetchMovimientoMunicion()
            await this.fetchMovimientoArma()
            await this.fetchTipoMovimiento()
            await this.fetchClasesArmaCorta()
            await this.fetchClasesArmaLarga()
            await this.fetchSubsedes()
            await this.fetchBitacoraEntregaRecepcion()
            await this.fetchMotivoUso()
            await this.fetchLocs()
            await this.fetchPuestosByDependencia(2)
            await this.fetchTipoPuesto()
            await this.fetchTipoFuncion()
            await this.fetchCursos()
            await this.fetchAccesorios()
            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
            }),
        },

        methods: {
            ...mapActions('personal', ['fetchPersonal']),

            ...mapActions('calibre', ['fetchCalibres']),
            ...mapActions('tipoArma', ['fetchTiposArma']),
            ...mapActions('marcaArma', ['fetchMarcaArma']),
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('estatusArma', ['fetchEstatusArma']),
            ...mapActions('arma', ['fetchArmas']),
            ...mapActions('tipoAsignacionArma', ['fetchTipoAsignacion']),
            ...mapActions('tipoEntregaArma', ['fetchTiposEntrega']),
            ...mapActions('estatusMunicion', ['fetchEstatusMunicion']),
            ...mapActions('itemArmeria', ['fetchItems']),
            ...mapActions('procedencia', ['fetchProcedencia']),
            ...mapActions('tipoItemArmeria', ['fetchTipoItem']),
            ...mapActions('movimientoMunicion', ['fetchMovimientoMunicion']),
            ...mapActions('movimientoArma', ['fetchMovimientoArma']),
            ...mapActions('movimientoItem', ['fetchMovimientoItem']),
            ...mapActions('inventario', ['fetchInventario']),
            ...mapActions('tipoMovimientoEquipamiento', ['fetchTipoMovimiento']),
            ...mapActions('claseArma', ['fetchClasesArmaCorta', 'fetchClasesArmaLarga']),
            ...mapActions('subsede', ['fetchSubsedes']),
            ...mapActions('bitacoraEntregaRecepcion', ['fetchBitacoraEntregaRecepcion']),
            ...mapActions('motivoUso', ['fetchMotivoUso']),
            ...mapActions('licenciaOficialColectiva', ['fetchLocs']),
            ...mapActions('puesto', ['fetchPuestosByDependencia']),
            ...mapActions('tipoPuesto', ['fetchTipoPuesto']),
            ...mapActions('tipoFuncion', ['fetchTipoFuncion']),
            ...mapActions('curso', ['fetchCursos']),
            ...mapActions('accesorio', ['fetchAccesorios']),

            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),
        },
    }
</script>
