import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoPuesto: [],
    },

    getters: {
        getTipoPuesto: state => {
            return state.tipoPuesto
        },
    },

    mutations: {
        SET_TIPO_PUESTO(state, tipoPuesto) {
            state.tipoPuesto = tipoPuesto
        },
    },

    actions: {
        fetchTipoPuesto: async function({ commit }) {
            await axios
                .get('/EonCloud/common/tiposPuesto')
                .then(response => {
                    commit('SET_TIPO_PUESTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
