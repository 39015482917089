<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card color="primary">
            <v-card-title>
                <div class="text-h6">Asignar personal a promoción</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="personalSearchField"
                    rounded
                    filled
                    background-color="primary"
                    color="secondary"
                    placeholder="Buscar personal"
                ></v-text-field>
                <v-virtual-scroll :items="filteredPersonal" item-height="64" height="300">
                    <template v-slot:default="{ item }">
                        <v-list-item :key="item.idPersonal">
                            <template v-slot:default="{ active }">
                                <v-list-item-avatar>
                                    <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                    <v-icon size="48" v-else>mdi-account</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                        <div class="text-subtitle-1" v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                        <div class="text-subtitle-1" v-else>CUIP no asignado</div>
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-checkbox v-model="selectedPersonal" :value="item.idPersonal" color="secondary"></v-checkbox>
                                </v-list-item-action>
                            </template>
                        </v-list-item>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="loading || selectedPersonal.length == 0" @click="triggerAsignaPersonal()"
                    >Asignar personal</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            convocatoria: {
                type: Object,
            },
        },

        data() {
            return {
                selectedPersonal: [],
                personalSearchField: '',
                loading: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            filteredPersonal: function() {
                return this.personal.filter(personal => {
                    return (
                        personal.nombre.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.segundoNombre.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.apaterno.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.amaterno.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0
                    )
                })
            },
        },

        methods: {
            ...mapActions('convocatoriaPersonal', ['saveConvocatoriaPersonal']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
                this.selectedPersonal = []
            },

            async triggerAsignaPersonal() {
                this.loading = true
                await this.saveConvocatoriaPersonal({
                    selectedPersonal: this.selectedPersonal,
                    fk_idConvocatoria: this.convocatoria.idConvocatoria,
                })

                this.closeDialog()
            },
        },
    }
</script>
