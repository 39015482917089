<template>
    <v-container>
        <v-row>
            <v-col v-bind="cardFormColsProps">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">Nuevo item</v-card-title>
                    <v-card-text>
                        <v-form v-model="itemForm">
                            <v-select
                                background-color="primary"
                                color="secondary"
                                rounded
                                filled
                                dense
                                :items="tiposItem"
                                item-value="id"
                                item-text="title"
                                v-model="tipoItem"
                                :rules="required"
                            >
                                <template v-slot:label>Tipo de item <span class="red--text">*</span> </template>
                            </v-select>
                            <v-text-field v-model="itemName" v-bind="textInputProps" :rules="required">
                                <template v-slot:label>Nombre del item <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-bind="cardActionsProps">
                        <v-btn v-bind="secondaryButtonProps" to="/equipamiento/items">Cancelar</v-btn>
                        <v-btn v-bind="primaryButtonProps" :disabled="!itemForm || loading" v-on:click="triggerSaveItem()">Registrar item</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import {
        cardActionsProps,
        cardFormColsProps,
        cardProps,
        cardTitleProps,
        primaryButtonProps,
        secondaryButtonProps,
        textInputProps,
    } from '../../components/props/props'

    import { mapActions, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                cardActionsProps,
                primaryButtonProps,
                secondaryButtonProps,
                textInputProps,
                tipoItem: null,

                itemName: '',
                loading: false,
                itemForm: false,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('tipoItem', {
                tiposItem: 'getTiposItem',
            }),
        },

        methods: {
            ...mapActions('item', ['saveItem']),
            ...mapActions('tipoItem', ['fetchTiposItem']),

            triggerSaveItem: async function() {
                this.loading = true

                await this.saveItem({
                    item: this.itemName,
                    fk_idCatTipoItem: this.tipoItem,
                })

                await this.fetchTiposItem()

                this.$router.replace('/equipamiento/items')
                this.loading = false
            },
        },
    }
</script>
