<template>
    <v-dialog v-model="active" width="90%">
        <v-card class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación anual</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="evaluacionAnual">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title>
                                    <div class="text-h6 font-weight-bold">Información general</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluado"
                                                    :value="getFullName(evaluacionAnual.Personal)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluado"
                                                    :value="evaluacionAnual.periodoEvaluacion"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Fecha"
                                                    :value="evaluacionAnual.fecha"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Tipo de evaluación"
                                                    :value="evaluacionAnual.CatTipoEvaluacion.title"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container v-if="evaluacionAnual.cumplimientoFunciones.length > 0">
                    <v-row>
                        <v-col cols="12">
                            <div class="text-h6 font-weight-bold secondary--text">Cumplimiento de sus funciones</div>
                        </v-col>
                    </v-row>
                    <v-row v-for="(descripcion, index) in descripcionCriterio" :key="index" align="center">
                        <v-col cols="8">
                            <div class="text-subtitle-2 font-weight-bold">{{ descripcion.CatCriterioEvaluacion.title }}</div>
                            <div class="text-subtitle-1">{{ descripcion.CatDescripcion.title }}</div>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                label="Calificación"
                                v-model="evaluacionAnual.cumplimientoFunciones[index].calificacion"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                :rules="required"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="4">
                            <v-text-field
                                label="Promedio de cumplimiento de sus funciones"
                                :value="getPromedio"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-h6 font-weight-bold secondary--text">Evaluación de capacidades</div>
                        </v-col>
                        <v-col cols="4">
                            <v-card class="card" color="primary">
                                <v-card-title>Valores</v-card-title>
                                <v-card-text v-if="evaluacionAnual.evaluacionValores.length > 0">
                                    <v-container>
                                        <v-row v-for="(valor, index) in valoresCapacidades" :key="index">
                                            <v-col cols="8">{{ valor.title }}</v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Calificación"
                                                    v-model="evaluacionAnual.evaluacionValores[index].calificacion"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Promedio"
                                                    :value="getPromedioValores"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card class="card" color="primary">
                                <v-card-title>Principios constitucionales</v-card-title>
                                <v-card-text v-if="evaluacionAnual.evaluacionPrincipios.length > 0">
                                    <v-container>
                                        <v-row v-for="(principio, index) in principiosConstitucionales" :key="index">
                                            <v-col cols="8">{{ principio.title }}</v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Calificación"
                                                    v-model="evaluacionAnual.evaluacionPrincipios[index].calificacion"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Promedio"
                                                    :value="getPromedioPrincipios"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card class="card" color="primary">
                                <v-card-title>Habilidades</v-card-title>
                                <v-card-text v-if="evaluacionAnual.evaluacionHabilidades.length > 0">
                                    <v-container>
                                        <v-row v-for="(habilidad, index) in habilidades" :key="index">
                                            <v-col cols="8">{{ habilidad.title }}</v-col>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Calificación"
                                                    v-model="evaluacionAnual.evaluacionHabilidades[index].calificacion"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="4">
                                                <v-text-field
                                                    label="Promedio"
                                                    :value="getPromedioHabilidades"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-textarea
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                label="Observaciones"
                                v-model="evaluacionAnual.observaciones"
                                no-resize
                                rows="4"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                label="Puntaje total"
                                :value="getPuntajeTotal"
                                no-resize
                                rows="4"
                            ></v-text-field>
                            <v-text-field
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                label="Calificación"
                                :value="(getPuntajeTotal / 4).toFixed(2)"
                                no-resize
                                rows="4"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="triggerUpdateEvaluacion()" :disabled="loading" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            evaluacionAnual: {
                type: Object,
            },
        },

        data() {
            return {
                required: [v => !!v || 'Este campo es requerido.'],
                loading: false,
            }
        },

        computed: {
            ...mapGetters('descripcionCriterio', {
                descripcionCriterio: 'getDescripcionCriterio',
            }),

            ...mapGetters('habilidades', {
                habilidades: 'getHabilidades',
            }),

            ...mapGetters('principiosConstitucionales', {
                principiosConstitucionales: 'getPrincipiosConstitucionales',
            }),

            ...mapGetters('valoresCapacidades', {
                valoresCapacidades: 'getValoresCapacidades',
            }),

            getPromedio: function() {
                if (this.evaluacionAnual.cumplimientoFunciones.length > 0) {
                    var acumulado = 0
                    var countNoAplica = 0
                    this.evaluacionAnual.cumplimientoFunciones.forEach(funcion => {
                        if (!isNaN(funcion.calificacion)) {
                            switch (funcion.calificacion) {
                                case '7':
                                    acumulado += 7
                                    break
                                case '8':
                                    acumulado += 8
                                    break
                                case '9':
                                    acumulado += 9
                                    break
                                case '10':
                                    acumulado += 10
                                    break
                                default:
                                    break
                            }
                        } else {
                            countNoAplica++
                        }
                    })
                    return (acumulado / (this.evaluacionAnual.cumplimientoFunciones.length - countNoAplica)).toFixed(1)
                }
            },

            getPromedioValores: function() {
                if (this.evaluacionAnual.evaluacionValores.length > 0) {
                    var acumulado = 0
                    var countNoAplica = 0
                    this.evaluacionAnual.evaluacionValores.forEach(funcion => {
                        if (!isNaN(funcion.calificacion)) {
                            switch (funcion.calificacion) {
                                case '7':
                                    acumulado += 7
                                    break
                                case '8':
                                    acumulado += 8
                                    break
                                case '9':
                                    acumulado += 9
                                    break
                                case '10':
                                    acumulado += 10
                                    break
                                default:
                                    break
                            }
                        } else {
                            countNoAplica++
                        }
                    })
                    return (acumulado / (this.evaluacionAnual.evaluacionValores.length - countNoAplica)).toFixed(1)
                }
            },

            getPromedioPrincipios: function() {
                if (this.evaluacionAnual.evaluacionPrincipios.length > 0) {
                    var acumulado = 0
                    this.evaluacionAnual.evaluacionPrincipios.forEach(funcion => {
                        switch (funcion.calificacion) {
                            case '7':
                                acumulado += 7
                                break
                            case '8':
                                acumulado += 8
                                break
                            case '9':
                                acumulado += 9
                                break
                            case '10':
                                acumulado += 10
                                break
                            default:
                                break
                        }
                    })
                    return (acumulado / this.evaluacionAnual.evaluacionPrincipios.length).toFixed(1)
                }
            },

            getPromedioHabilidades: function() {
                if (this.evaluacionAnual.evaluacionHabilidades.length > 0) {
                    var acumulado = 0
                    this.evaluacionAnual.evaluacionHabilidades.forEach(funcion => {
                        switch (funcion.calificacion) {
                            case '7':
                                acumulado += 7
                                break
                            case '8':
                                acumulado += 8
                                break
                            case '9':
                                acumulado += 9
                                break
                            case '10':
                                acumulado += 10
                                break
                            default:
                                break
                        }
                    })
                    return (acumulado / this.evaluacionAnual.evaluacionHabilidades.length).toFixed(1)
                }
            },

            getPuntajeTotal: function() {
                return +this.getPromedio + +this.getPromedioValores + +this.getPromedioPrincipios + +this.getPromedioHabilidades
            },
        },

        methods: {
            ...mapActions('evaluacionAnual', ['updateEvaluacionAnual', 'updateEstatus']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            async triggerUpdateEvaluacion() {
                this.loading = true
                this.evaluacionAnual.promedio = this.getPromedio
                this.evaluacionAnual.promedioHabilidades = this.getPromedioHabilidades
                this.evaluacionAnual.promedioPrincipiosConstitucionales = this.getPromedioPrincipios
                this.evaluacionAnual.promedioValores = this.getPromedioValores
                this.evaluacionAnual.puntajeTotal = this.getPuntajeTotal.toString()
                this.evaluacionAnual.calificacion = (this.getPuntajeTotal / 4).toFixed(2)
                this.evaluacionAnual.fk_idResultado = this.getResultado()
                await this.updateEvaluacionAnual(this.evaluacionAnual)
                await this.updateEstatus(this.evaluacionAnual)
                await this.fetchEvaluacionesById(this.evaluacionAnual.fk_idPersonalEvaluador)
                this.loading = false
                this.closeDialog()
            },

            closeDialog() {
                this.$emit('close')
            },

            getResultado() {
                if (this.getPuntajeTotal / 4 <= 10) {
                    return 1
                } else if (this.getPuntajeTotal / 4 <= 9) {
                    return 2
                } else if (this.getPuntajeTotal / 4 <= 8) {
                    return 3
                } else if (this.getPuntajeTotal / 4 <= 7) {
                    return 4
                }
            },
        },
    }
</script>
