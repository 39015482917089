import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        colores: [],
    },

    getters: {
        getColores: state => {
            return state.colores
        },
    },

    mutations: {
        SET_COLORES(state, colores) {
            state.colores = colores
        },
    },

    actions: {
        fetchColores: async function({ commit }) {
            await axios
                .get('/EonCloud/colores')
                .then(response => {
                    commit('SET_COLORES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
