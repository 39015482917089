import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        movimientoItem: [],
    },

    getters: {
        getMovimientoItem: state => {
            return state.movimientoItem
        },
    },

    mutations: {
        SET_MOVIMIENTO_ITEM(state, movimientoItem) {
            state.movimientoItem = movimientoItem
        },
    },

    actions: {
        fetchMovimientoItem: async function({ commit }, idItem) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/item/' + idItem + '/movimientos')
                .then(response => {
                    commit('SET_MOVIMIENTO_ITEM', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMovimientoItem: async function(context, movimientoItem) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/movimientos', movimientoItem)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Movimiento de item registrado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateMovimientoItem: async function(context, movimientoItem) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/movimientos/' + movimientoItem.id, movimientoItem)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Movimiento de item editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
