import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusEvaluacion: [],
    },

    getters: {
        getEstatusEvaluacion: state => {
            return state.estatusEvaluacion
        },
    },

    mutations: {
        SET_ESTATUS_EVALUACION(state, estatusEvaluacion) {
            state.estatusEvaluacion = estatusEvaluacion
        },
    },

    actions: {
        fetchEstatusEvaluacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/estatusEvaluacion')
                .then(response => {
                    commit('SET_ESTATUS_EVALUACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
