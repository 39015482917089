import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        incidencia: [],
    },

    getters: {
        getIncidencia: state => {
            return state.incidencia
        },
    },

    mutations: {
        SET_INCIDENCIA(state, incidencia) {
            state.incidencia = incidencia
        },
    },

    actions: {
        fetchIncidencia: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/incidencia')
                .then(response => {
                    commit('SET_INCIDENCIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
