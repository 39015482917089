<template>
    <v-card class="card" color="primary">
        <v-card-title>Respeto a los principios y valores</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" align="center">
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 3" color="success">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 1" color="error">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                    </v-col>
                    <v-col align="center">
                        <div class="text-caption">Puntaje total</div>
                        <div class="text-body-2">{{ evaluacion.puntajeTotal }}</div>
                    </v-col>
                    <v-col align="center">
                        <div class="text-caption">Calificación</div>
                        <div class="text-body-2">{{ evaluacion.calificacion }}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-caption">Resultado</div>
                        <div class="text-body-2">{{ evaluacion.CatResultado.title }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn rounded color="secondary" @click="triggerDescargaEvaluacion()" :loading="loading" :disabled="loading">Descargar evaluacion</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { saveAs } from 'file-saver'
    import axios from 'axios'
    import { publicPath } from '../../../../../vue.config'

    const ExcelJS = require('exceljs')
    let buffer = null
    let workbook = new ExcelJS.Workbook()
    let worksheet = null
    let cell = null

    export default {
        props: {
            evaluacion: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            async triggerDescargaEvaluacion() {
                this.loading = true

                await this.loadEvaluacion()
                this.loading = false
            },

            async loadEvaluacion() {
                await axios(publicPath + 'evaluacion_respeto_principios_valores.xlsx', { responseType: 'arraybuffer' })
                    .catch(function(err) {
                        /* error in getting data */
                        console.log('Error in getting data: ' + err)
                    })
                    .then(async function(res) {
                        /* parse the data when it is received */
                        return res.data
                    })
                    .catch(function(err) {
                        /* error in parsing */
                        console.log('Error in parsin: ' + err)
                    })
                    .then(async data => {
                        await workbook.xlsx.load(data)

                        worksheet = workbook.getWorksheet(1)

                        cell = worksheet.getCell('D7')
                        cell.value = this.getFullName(this.evaluacion.Personal)
                        cell = worksheet.getCell('K7')
                        cell.value = this.evaluacion.Personal.curp
                        cell = worksheet.getCell('N7')
                        cell.value = this.evaluacion.Personal.rfc
                        cell = worksheet.getCell('F9')
                        cell.value = this.evaluacion.institucionSeguridadAspira
                        cell = worksheet.getCell('L9')
                        cell.value = this.evaluacion.aspiranteAlPuesto
                        cell = worksheet.getCell('F11')
                        cell.value = this.evaluacion.CatMunicipio.catEstado.title
                        cell = worksheet.getCell('L11')
                        cell.value = this.evaluacion.CatMunicipio.nombre
                        cell = worksheet.getCell('N13')
                        cell.value = this.evaluacion.fechaAplicacion

                        let index_reactivos = 20
                        this.evaluacion.frecuenciasMedicion.forEach(frecuencia => {
                            switch (frecuencia.fk_idCatFrecuencia) {
                                case 1:
                                    cell = worksheet.getCell('L' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 2:
                                    cell = worksheet.getCell('M' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 3:
                                    cell = worksheet.getCell('N' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 4:
                                    cell = worksheet.getCell('O' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 5:
                                    cell = worksheet.getCell('P' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                default:
                                    break
                            }
                            index_reactivos += 1
                        })

                        cell = worksheet.getCell('D38')
                        cell.value = this.evaluacion.observaciones
                        cell = worksheet.getCell('E42')
                        cell.value = this.getFullName(this.evaluacion.PersonalEvaluador)
                        cell = worksheet.getCell('E43')
                        cell.value = this.evaluacion.PersonalEvaluador.CatPuestoDependencia.catPuesto.puesto

                        worksheet.removeConditionalFormatting()
                        buffer = await workbook.xlsx.writeBuffer()

                        this.downloadEvaluacion()
                    })
            },

            downloadEvaluacion() {
                var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, 'evaluacion_respeto_principios_valores.xlsx')
            },
        },
    }
</script>
