import { axios } from '../../../plugins/axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        procedimientos: [],
    },

    getters: {
        getProcedimientos: state => {
            return state.procedimientos
        },
    },

    mutations: {
        SET_PROCEDIMIENTOS(state, procedimientos) {
            state.procedimientos = procedimientos
        },
    },

    actions: {
        fetchProcedimientos: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/procedimientoCHJ')
                .then(response => {
                    commit('SET_PROCEDIMIENTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveProcedimiento: async function(context, procedimiento) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + procedimiento.fk_idPersonal + '/procedimientoCHJ', procedimiento)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Procedimiento registrado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateProcedimiento: async function(context, procedimiento) {
            await axios
                .put(
                    '/PoliciaCuadrantes/EstadoFuerza/personal/' + procedimiento.fk_idPersonal + '/procedimientoCHJ/' + procedimiento.idSancion,
                    procedimiento
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'imiento editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
