import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusPersonal: [],
    },

    getters: {
        getEstatusPersonal: state => {
            return state.estatusPersonal
        },
    },

    mutations: {
        SET_ESTATUS_PERSONAL: (state, estatusPersonal) => {
            state.estatusPersonal = estatusPersonal
        },
    },

    actions: {
        fetchEstatusPersonal: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/estatusPersonal')
                .then(response => {
                    commit('SET_ESTATUS_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
