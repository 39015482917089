import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cursosAsignados: [],
        cursosAsignadosPersonal: [],
    },

    getters: {
        getCursosAsignados: state => {
            return state.cursosAsignados
        },

        getCursosAsignadosPersonal: state => {
            return state.cursosAsignadosPersonal
        },
    },

    mutations: {
        SET_CURSOS_ASIGNADOS(state, cursosAsignados) {
            state.cursosAsignados = cursosAsignados
        },

        SET_CURSOS_ASIGNADOS_PERSONAL(state, cursosAsignadosPersonal) {
            state.cursosAsignadosPersonal = cursosAsignadosPersonal
        },
    },

    actions: {
        fetchCursosAsignados: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/asignacionCursos')
                .then(response => {
                    commit('SET_CURSOS_ASIGNADOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchCursosAsignadosByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/personal/' + idPersonal + '/asignacionCursos')
                .then(response => {
                    commit('SET_CURSOS_ASIGNADOS_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCursoAsignado: async function(context, listaPersonal) {
            try {
                await Promise.all(
                    listaPersonal.map(async personal => {
                        await axios
                            .post('/PoliciaCuadrantes/Profesionalizacion/asignacionCursos', personal)
                            .then(response => {
                                context.commit('SET_SNACKBAR_MESSAGE', 'El personal se asignó al curso correctamente.', {
                                    root: true,
                                })
                            })
                            .catch(error => console.log(error))
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },

        updateCursoAsignado: async function(context, cursosPersonal) {
            try {
                await Promise.all(
                    cursosPersonal.map(async cursoPersonal => {
                        await axios
                            .put('/PoliciaCuadrantes/Profesionalizacion/asignacionCursos/' + cursoPersonal.id, cursoPersonal)
                            .then(response => {
                                if (cursoPersonal.file) {
                                    var formData = new FormData()
                                    formData.append('file', cursoPersonal.file)
                                    formData.append('idCursoPersonal', cursoPersonal.id)
                                    console.log(formData)
                                    context.dispatch('uploadDiplomaPersonal', formData)
                                }
                            })
                            .catch(error => console.log(error))
                    })
                )
            } catch (error) {
                console.log(error)
            }
        },

        uploadDiplomaPersonal: async function({ commit }, formData) {
            await fileAxios
                .post('/SetDiplomaPersonal/uploadFiles', formData)
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
