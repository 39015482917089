import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        subcategorias: [],
    },

    getters: {
        getSubcategorias: state => {
            return state.subcategorias
        },
    },

    mutations: {
        SET_SUBCATEGORIAS(state, subcategorias) {
            state.subcategorias = subcategorias
        },
    },

    actions: {
        fetchSubcategoriasByCategoria: async function({ commit }, categoria) {
            await axios
                .get('/EonCloud/categorias/' + categoria.id + '/subcategorias')
                .then(response => {
                    commit('SET_SUBCATEGORIAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
