<template>
    <v-app-bar v-if="$route.name == 'Portal'" fixed height="64" color="white" app>
        <v-container>
            <v-row align="center">
                <v-col cols="2">
                    <v-img src="@/assets/img/eon.png" height="48" contain></v-img>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6" align="right">
                    <template v-if="token.length == 0">
                        <v-btn text class="mx-2" to="/login">Iniciar sesion</v-btn>
                        <v-btn v-bind="primaryButtonProps" class="mx-2" color="secondary" to="/register">Registrarse</v-btn>
                    </template>
                    <template v-else>
                        <v-btn class="mx-2" color="secondary" v-on:click="handlerEntrar()">Entrar</v-btn>
                        <v-btn text class="mx-2" v-on:click="triggerLogout()">Cerrar sesión</v-btn>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
    <v-app-bar flat :color="getTopBarColor" v-else-if="showTopBar" app>
        <v-container fluid>
            <v-row align="center" justify="space-between">
                <v-col>
                    <v-card color="transparent">
                        <v-container>
                            <v-row align="center">
                                <v-col cols="12" align="center">
                                    <v-breadcrumbs>
                                        <div class="text-h6 font-weight-black">
                                            {{ this.$route.matched[0].name }}
                                        </div>
                                    </v-breadcrumbs>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
                <v-col cols="3">
                    <v-card class="card" color="primary">
                        <v-container>
                            <v-row align="center">
                                <v-col cols="1" class="py-1">
                                    <v-icon color="secondary" large>mdi-account-circle</v-icon>
                                </v-col>
                                <v-col cols="9" class="py-1 pl-5">
                                    <div class="text-subtitle-2 font-weight-bold text--primary text-truncate">{{ nombre }}</div>
                                    <div class="text-caption font-weight-bold text--secondary">{{ perfil }}</div>
                                </v-col>
                                <v-col cols="2" class="py-1">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on">
                                                <v-icon color="secondary">mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <reset-password-dialog-component></reset-password-dialog-component>
                                            <v-list-item v-on:click="triggerLogout()">
                                                Cerrar sesión
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex'
    import { primaryButtonProps } from './props/props'
    import ResetPasswordDialogComponent from './ResetPasswordDialogComponent.vue'

    export default {
        components: {
            ResetPasswordDialogComponent,
        },

        data() {
            return {
                primaryButtonProps,
            }
        },

        computed: {
            ...mapGetters({
                token: 'getToken',
                nombre: 'getNombre',
                loading: 'getLoader',
                perfil: 'getPerfil',
            }),
            showTopBar: function() {
                if (this.loading || this.token.length == 0 || this.$route.name == '404' || this.$route.name == 'Login') {
                    return false
                } else return true
            },

            getTopBarColor: function() {
                if (this.$route.name == 'Launcher') {
                    return 'white'
                } else return 'primary'
            },

            items: function() {
                var items = []
                this.$route.matched.forEach(route => {
                    items.push({
                        text: route.name,
                    })
                })
                console.log(items)
                return items
            },
        },

        methods: {
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            getName: function(name) {
                return name.split(' ', 1)[0]
            },

            triggerLogout: function() {
                this.SET_SNACKBAR_MESSAGE('Cerrando sesión...')
                this.logout()
                window.location.reload()
                // this.$router.replace('/')
            },

            handlerEntrar() {
                switch (this.perfil) {
                    case 'Armeria':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/armeria/dashboard')
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'Equipamiento':
                        // this.DISABLE_LOADER()
                        window.location.replace('equipamiento/personal')
                        break
                    case 'Profesionalización':
                        // this.DISABLE_LOADER()
                        // this.$router.replace('/profesionalizacion/dashboard')
                        window.location.replace('profesionalizacion/dashboard')
                        break
                    case 'Desarrollo Profesional':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/desarrollo_profesional/dashboard')
                        window.location.replace('desarrollo_profesional/personal')
                        break
                    case 'Personal/Site':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/personal/dashboard')
                        window.location.replace('personal/dashboard')
                        break
                    case 'Recursos Humanos':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/recursos_humanos/dashboard')
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'Enlace C3':
                        window.location.replace('enlace_c3/dashboard')
                        break
                    case 'ADMINISTRADOR':
                        window.location.replace('launcher')
                        break
                    case 'Administrador IPH+':
                        window.location.replace('launcher')
                        break
                    case 'Asuntos Internos':
                        window.location.replace('asuntos_internos/personal')
                        break
                    case 'Consejo de Honor y Justicia':
                        window.location.replace('asuntos_internos/personal')
                        break
                    case 'Policia':
                        window.location.replace('policia/' + this.idPersonal)
                        break
                }
            },
        },
    }
</script>
