<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <v-container>
                <v-row align="center">
                    <v-col cols="8">
                        <div class="text-h5 font-weight-bold">Datos generales</div>
                    </v-col>
                    <v-col cols="4" align="right">
                        <v-btn rounded color="secondary" :disabled="!personalForm" v-on:click="triggerUpdatePersonal()"
                            >Guardar cambios<v-icon small class="ml-4">mdi-pencil</v-icon></v-btn
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-tabs v-model="selectedTab" color="secondary" fixed-tabs>
                            <v-tab class="font-weight-bold"
                                >Información <br />principal
                                <v-icon
                                    color="error"
                                    class="ml-2"
                                    v-if="!nameForm || !fechaNacimientoForm || !estadoCivilForm || !sexoForm || !grupoSanguineoForm"
                                    >mdi-alert-circle-outline</v-icon
                                ></v-tab
                            >
                            <v-tab class="font-weight-bold"
                                >Nacionalidad <v-icon color="error" class="ml-2" v-if="!nacionalidadForm">mdi-alert-circle-outline</v-icon></v-tab
                            >
                            <v-tab class="font-weight-bold"
                                >Documentación <br />oficial
                                <v-icon color="error" class="ml-2" v-if="!curpForm || !ineForm || !smnForm">mdi-alert-circle-outline</v-icon></v-tab
                            >
                            <v-tab class="font-weight-bold"
                                >Información <br />personal <v-icon color="error" class="ml-2" v-if="!contactoForm">mdi-alert-circle-outline</v-icon></v-tab
                            >
                            <v-tab class="font-weight-bold"
                                >Domicilio <v-icon color="error" class="ml-2" v-if="!direccionForm">mdi-alert-circle-outline</v-icon></v-tab
                            >
                            <v-tab class="font-weight-bold">Estudios</v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="selectedTab" class="transparent">
                <v-tab-item key="1" eager>
                    <v-container>
                        <v-row>
                            <v-col lg="8">
                                <div class="text-h6 font-weight-bold">Nombre del personal</div>
                            </v-col>
                            <v-col lg="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="nameForm" ref="name">
                                            <v-text-field
                                                v-model="personal.apaterno"
                                                flat
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.amaterno"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.nombre"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.segundoNombre"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Segundo nombre"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col lg="8">
                                <div class="text-h6 font-weight-bold">Fecha de nacimiento</div>
                            </v-col>
                            <v-col lg="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="fechaNacimientoForm" ref="nacimiento">
                                            <v-text-field
                                                v-model="personal.fechaNacimiento"
                                                v-mask="'##/##/####'"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="dateRules"
                                                hint="Formato de fecha: DD/MM/YYYY"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Fecha de nacimiento <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getAge(personal.fechaNacimiento)"
                                            rounded
                                            filled
                                            dense
                                            label="Edad"
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col lg="8">
                                <div class="text-h6 font-weight-bold">Estado civil</div>
                            </v-col>
                            <v-col lg="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="estadoCivilForm" ref="civil">
                                            <v-select
                                                v-model="personal.fk_idEstadoCivil"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="estadoCivil"
                                                item-value="id"
                                                item-text="title"
                                                item-color="secondary"
                                                :rules="requiredSelect"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Estado civíl <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col lg="8">
                                <div class="text-h6 font-weight-bold">Sexo</div>
                            </v-col>
                            <v-col lg="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="sexoForm" ref="sexo">
                                            <v-select
                                                v-model="personal.fk_idSexo"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="sexo"
                                                item-value="id"
                                                item-text="title"
                                                item-color="secondary"
                                                :rules="requiredSelect"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Sexo <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col lg="8">
                                <div class="text-h6 font-weight-bold">Grupo sanguíneo</div>
                            </v-col>
                            <v-col lg="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="grupoSanguineoForm" ref="sanguineo">
                                            <v-select
                                                v-model="personal.fk_idCatGrupoSanguineo"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="grupoSanguineo"
                                                item-value="id"
                                                item-text="title"
                                                item-color="secondary"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Grupo sanguíneo <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="2" eager>
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Nacionalidad</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="nacionalidadForm" ref="nacionalidad">
                                            <v-text-field
                                                v-model="personal.nacionalidad"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Nacionalidad <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-select
                                                v-model="personal.fk_idTipoNacionalidad"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="tipoNacionalidad"
                                                item-value="id"
                                                item-text="title"
                                                item-color="secondary"
                                                :rules="requiredSelect"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Tipo de nacionalidad <span class="red--text">*</span> </template>
                                            </v-select>
                                            <v-autocomplete
                                                v-model="personal.fk_idMunicipioNacimiento"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="municipios"
                                                item-value="id"
                                                item-text="nombre"
                                                item-color="secondary"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Municipio donde nació <span class="red--text">*</span> </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="3" eager>
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Clave única de Registro de Población</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="curpForm" ref="curp">
                                            <v-text-field
                                                v-model="personal.curp"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="curpRules"
                                                maxlength="18"
                                                counter="18"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>CURP <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Cartilla militar</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="smnForm" ref="smn">
                                            <v-text-field
                                                v-model="personal.cartillaMilitar"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="personal.fk_idSexo == 1 ? required : []"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label
                                                    >Cartilla militar <span class="red--text" v-if="personal.fk_idSexo == 1">*</span>
                                                </template>
                                            </v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Clave de elector (INE)</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-form v-model="ineForm" ref="ine">
                                            <v-text-field
                                                v-model="personal.claveIne"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="ineRules"
                                                maxlength="18"
                                                counter="18"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Clave de credencial de elector (INE) <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Número de Seguro Social</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.nss"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Número de seguro social"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Pasaporte</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.pasaporte"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Pasaporte"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.vigenciaPasaporte"
                                            label="Vigencia de pasaporte"
                                            v-mask="'##/##/####'"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="vigenciaRules"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            :disabled="personal.pasaporte === ''"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">VISA</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.visa"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Visa"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.vigenciaVisa"
                                            label="Vigencia de visa"
                                            v-mask="'##/##/####'"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="vigenciaRules"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            :disabled="personal.visa === ''"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Número de Registro Federal de Contribuyentes</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.rfc"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="RFC"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Licencia para conducir</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.licenciaConducir"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Licencia para conducir"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.vigenciaLicencia"
                                            label="Vigencia de licencia"
                                            v-mask="'##/##/####'"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="vigenciaRules"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                            :disabled="personal.licenciaConducir === ''"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" v-if="personal.fk_idEstatus == 1">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8" v-if="personal.fk_idEstatus == 1">
                                <div class="text-h6 font-weight-bold">CUIP</div>
                            </v-col>
                            <v-col cols="4" v-if="personal.fk_idEstatus == 1">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.CUIP"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="CUIP"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" v-if="personal.fk_idEstatus == 1">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8" v-if="personal.fk_idEstatus == 1">
                                <div class="text-h6 font-weight-bold">CUP</div>
                            </v-col>
                            <v-col cols="4" v-if="personal.fk_idEstatus == 1">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.CUP"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="CUP"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" v-if="personal.fk_idEstatus == 1">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col
                                cols="8"
                                v-if="
                                    personal.fk_idEstatus == 1 &&
                                        perfil !== 'Enlace C3' &&
                                        perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'
                                "
                            >
                                <div class="text-h6 font-weight-bold">NCP</div>
                            </v-col>
                            <v-col
                                cols="4"
                                v-if="
                                    personal.fk_idEstatus == 1 &&
                                        perfil !== 'Enlace C3' &&
                                        perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'
                                "
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.ncp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="NCP"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                cols="12"
                                v-if="
                                    personal.fk_idEstatus == 1 &&
                                        perfil !== 'Enlace C3' &&
                                        perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'
                                "
                            >
                                <v-divider></v-divider>
                            </v-col>
                            <v-col
                                cols="8"
                                v-if="
                                    personal.fk_idEstatus == 1 &&
                                        perfil !== 'Enlace C3' &&
                                        perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'
                                "
                            >
                                <div class="text-h6 font-weight-bold">Número de servicio médico interno</div>
                            </v-col>
                            <v-col
                                cols="4"
                                v-if="
                                    personal.fk_idEstatus == 1 &&
                                        perfil !== 'Enlace C3' &&
                                        perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                        perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'
                                "
                            >
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.nsmi"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Número de servicio médico interno"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="4" eager>
                    <v-container>
                        <v-form v-model="contactoForm" ref="contacto">
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Correo electrónico</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="personal.Contacto.correoElectronico"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="emailRules"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Correo electrónico <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Teléfonos</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="personal.Contacto.telefonoCelular"
                                                v-mask="'###-###-##-##'"
                                                type="tel"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Telefono celular"
                                                maxlength="13"
                                                :rules="personal.Contacto.telefonoCelular.length > 0 ? phoneRules : []"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Teléfono celular <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.Contacto.telefonoFijo"
                                                v-mask="'###-###-##-##'"
                                                type="tel"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Telefono fijo"
                                                maxlength="13"
                                                :rules="personal.Contacto.telefonoFijo.length > 0 ? phoneRules : []"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="5" eager>
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Lugar donde radica actualmente</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="personal.fk_idMunicipioRadica"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :items="municipios"
                                            item-value="id"
                                            item-text="nombre"
                                            item-color="secondary"
                                            :readonly="perfil === 'ADMINISTRADOR' && true"
                                        >
                                            <template v-slot:label>Municipio donde radica <span class="red--text">*</span> </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="7">
                                <div class="text-h6 font-weight-bold">Dirección</div>
                                <v-card color="transparent" height="480" v-if="$route.matched.some(({ path }) => path === '/enlace_c3')">
                                    <v-card-text style="height:480px">
                                        <MglMap
                                            :accessToken="accessToken"
                                            :mapStyle="mapStyle"
                                            :center="center"
                                            :zoom="16"
                                            :bearing="-17.6"
                                            :antialias="true"
                                            v-on:dblclick="updateUbicacion($event)"
                                            :doubleClickZoom="false"
                                            @load="mapLoaded"
                                        >
                                            <MglMarker
                                                :coordinates="[personal.Contacto.Direccion.lgt, personal.Contacto.Direccion.lat]"
                                                color="unidad.color"
                                                :key="personal.idPersonal"
                                            />
                                            <MglGeocoderControl
                                                :accessToken="accessToken"
                                                :input="defaultInput"
                                                @results="handleSearch"
                                                placeholder="Buscar lugar o dirección"
                                            />
                                        </MglMap>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-form v-model="direccionForm" ref="direccion">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="personal.Contacto.Direccion.fk_idMunicipio"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="municipios"
                                                item-value="id"
                                                item-text="nombre"
                                                item-color="secondary"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Municipio donde vive <span class="red--text">*</span> </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.calle"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="requiredText"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Calle <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.entrecalles"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.entrecalles2"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle 2"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6"
                                            ><v-text-field
                                                v-model="personal.Contacto.Direccion.ext"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="requiredText"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Número exterior <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.int"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Número interior"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.colonia"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="requiredText"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Colonia <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.cp"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="requiredText"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            >
                                                <template v-slot:label>Código Postal <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="personal.Contacto.Direccion.referencias"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Referencias"
                                                :readonly="perfil === 'ADMINISTRADOR' && true"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="6" eager>
                    <v-container>
                        <v-row v-if="estudios.length > 0">
                            <v-col cols="4" v-for="estudio in estudios" :key="estudio.id">
                                <v-card class="card" color="primary">
                                    <v-card-title class="justify-center" align="center">
                                        <div class="text-subtitle-1 font-weight-black ">
                                            {{ estudio.institucion }}
                                        </div>
                                    </v-card-title>
                                    <v-card-text align="center">
                                        <v-container>
                                            <v-row align="center">
                                                <v-col cols="6" align="left">
                                                    <div class="subtitle-2 font-weight-bold">Generación</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-1">{{ estudio.generacion }}</div>
                                                </v-col>
                                                <v-col cols="6" align="left">
                                                    <div class="subtitle-2 font-weight-bold">Estatus</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="subtitle-1">{{ estudio.CatEstatusEstudio.title }}</div>
                                                </v-col>
                                                <v-col cols="6" align="left">
                                                    <div class="subtitle-2 font-weight-bold">Comprobante</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="subtitle-1">{{ estudio.ComprobanteEstudio.title }}</div>
                                                </v-col>
                                                <v-col cols="6" align="left">
                                                    <div class="subtitle-2 font-weight-bold">Grado</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="subtitle-1">{{ estudio.CatEstudios.title }}</div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <div class="text-caption font-weight-bold">Observaciones</div>
                                                    <div v-if="estudio.observaciones.length > 0" class="text-body-1">{{ estudio.observaciones }}</div>
                                                    <div v-else class="text-body-1">Ninguna</div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <v-icon color="primary darken-3" size="256">mdi-folder-alert</v-icon>
                                <div class="text-h6">Este elemento no cuenta con comprobantes de estudio registrados.</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" align="center">
                                <v-btn
                                    v-if="perfil !== 'ADMINISTRADOR' && perfil !== 'Policia'"
                                    color="secondary"
                                    :to="{ name: getRouteName, params: { id: personal.idPersonal } }"
                                    >Agregar estudio</v-btn
                                >
                                <agrega-estudio-dialog-component :id="personal.idPersonal" v-if="perfil == 'Policia'"></agrega-estudio-dialog-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
    import MapComponent from '../../components/MapComponent'
    import Mapbox from 'mapbox-gl'
    import { MglMap, MglMarker } from 'vue-mapbox'
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'
    import MglGeocoderControl from 'vue-mapbox-geocoder'
    import AgregaEstudioDialogComponent from '../../views/policia/AgregaEstudioDialogComponent.vue'

    let mapRef = {}

    export default {
        components: {
            MapComponent,
            MglMap,
            MglMarker,
            MglGeocoderControl,
            AgregaEstudioDialogComponent,
        },

        props: {
            personal: {
                type: Object,
            },

            estudios: {
                type: Array,
            },
        },

        created() {
            this.mapbox = Mapbox

            if (!this.personal.Contacto.Direccion) {
                this.personal.Contacto.Direccion = {
                    calle: '',
                    entrecalles: '',
                    entrecalles2: '',
                    ext: '',
                    int: '',
                    colonia: '',
                    cp: '',
                    referencias: '',
                    fk_idMunicipio: 0,
                    lat: 0,
                    lgt: 0,
                }
            }
        },

        data() {
            return {
                mapbox: null,
                selectedTab: null,

                accessToken: 'pk.eyJ1IjoiZ3VzdG1nIiwiYSI6ImNramtlb2liazRwbDgzM255ajJleWd2cWUifQ.IXSXU4vuLx_zyN5c8lcvYg', // your access token. Needed if you using Mapbox maps
                mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
                defaultInput: '',

                date: null,

                required: [v => !!v || 'Este campo es obligatorio.'],
                requiredSelect: [v => v != 0 || 'Este campo es obligatorio.'],
                requiredText: [v => v.length > 0 || 'Este campo es obligatorio.'],

                nameForm: false,
                fechaNacimientoForm: false,
                estadoCivilForm: false,
                sexoForm: false,
                grupoSanguineoForm: false,

                nacionalidadForm: false,

                ineForm: false,
                smnForm: false,
                curpForm: false,

                contactoForm: false,
                municipioRadicaForm: false,
                direccionForm: false,

                estadoNace: null,
                municipioNace: null,

                estadoRadica: null,
                municipioRadica: null,

                estadoVive: null,
                municipioVive: null,

                emailRules: [v => !!v || 'Este campo es requerido.', v => /.+@.+\..+/.test(v) || 'Ingrese un correo electrónico válido.'],
                phoneRules: [v => !!v || 'Este campo es requerido.', v => v.length == 13 || 'Ingrese su número telefónico a 10 dígitos.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],

                vigenciaRules: [
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],

                curpRules: [v => !!v || 'Este campo es requerido.', v => v.length == 18 || 'Ingrese CURP a 18 dígitos.'],

                ineRules: [v => !!v || 'Este campo es requerido.', v => v.length == 18 || 'Ingrese INE a 18 dígitos.'],
            }
        },

        watch: {
            selectedTab(newValue) {
                switch (newValue) {
                    case 0:
                        this.$refs.name.validate()
                        this.$refs.nacimiento.validate()
                        this.$refs.civil.validate()
                        this.$refs.sexo.validate()
                        this.$refs.sanguineo.validate()
                        break
                    case 1:
                        this.$refs.nacionalidad.validate()
                        break
                    case 2:
                        this.$refs.curp.validate()
                        this.$refs.ine.validate()
                        this.$refs.smn.validate()
                        break
                    case 3:
                        this.$refs.contacto.validate()
                        break
                    case 4:
                        this.$refs.direccion.validate()
                        break
                    default:
                        break
                }
            },
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters('estadoCivil', {
                estadoCivil: 'getEstadoCivil',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('grupoSanguineo', {
                grupoSanguineo: 'getGrupoSanguineo',
            }),

            center: function() {
                if (this.personal) {
                    if (this.personal.Contacto.Direccion.lat != 0) {
                        return [this.personal.Contacto.Direccion.lgt, this.personal.Contacto.Direccion.lat]
                    }
                }
                return [-98.2460645, 19.3165631]
            },

            filteredMunicipiosEstadoNacimiento: function() {
                if (this.personal.CatMunicipioNacimiento) {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.personal.CatMunicipioNacimiento.catEstado.id
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.estadoNace
                    })
                }
            },

            filteredMunicipiosEstadoRadica: function() {
                if (this.personal.CatMunicipioRadica) {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.personal.CatMunicipioRadica.fk_idEstado
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.estadoRadica
                    })
                }
            },

            filteredMunicipiosEstadoVive: function() {
                if (this.personal.Contacto.Direccion.fk_idMunicipio != 0) {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.personal.Contacto.Direccion.CatMunicipio.fk_idEstado
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.fk_idEstado == this.estadoVive
                    })
                }
            },

            getRouteName: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return 'agregaEstudioPersonal'
                } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    {
                        return 'registro_personal.agrega_estudios'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    {
                        return 'agregaEstudioPersonalRH'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                    {
                        return 'agregaEstudioPersonalC3'
                    }
                }
            },

            personalForm() {
                if (
                    !this.nameForm ||
                    !this.fechaNacimientoForm ||
                    !this.estadoCivilForm ||
                    !this.sexoForm ||
                    !this.grupoSanguineoForm ||
                    !this.nacionalidadForm ||
                    !this.curpForm ||
                    !this.ineForm ||
                    !this.smnForm ||
                    !this.contactoForm ||
                    !this.direccionForm
                ) {
                    return false
                } else return true
            },
        },

        methods: {
            // ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            // ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            // ...mapActions('sexo', ['fetchSexo']),
            // ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('personal', ['updatePersonal']),

            triggerUpdatePersonal: async function() {
                if (this.personal.fk_idMunicipioNacimiento == 0) {
                    this.personal.fk_idMunicipioNacimiento = this.municipioNace
                }
                if (this.personal.fk_idMunicipioRadica == 0) {
                    this.personal.fk_idMunicipioRadica = this.municipioRadica
                }
                if (this.personal.Contacto.Direccion.fk_idMunicipio == 0) {
                    this.personal.Contacto.Direccion.fk_idMunicipio = this.municipioVive
                }

                await this.updatePersonal(this.personal)
            },

            updateUbicacion: function(e) {
                this.personal.Contacto.Direccion.lat = e.mapboxEvent.lngLat.lat
                this.personal.Contacto.Direccion.lgt = e.mapboxEvent.lngLat.lng
            },

            handleSearch(event) {
                // console.log(event)
            },

            mapLoaded(event) {
                mapRef = event.map
                mapRef.resize()
            },
        },
    }
</script>
