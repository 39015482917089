import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        reimpresionCredencial: [],
    },

    getters: {
        getReimpresionCredencial: state => {
            return state.reimpresionCredencial
        },
    },

    mutations: {
        SET_REIMPRESION_CREDENCIAL(state, reimpresionCredencial) {
            state.reimpresionCredencial = reimpresionCredencial
        },
    },

    actions: {
        fetchReimpresionCredencialByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/reimpresionCredencial')
                .then(response => {
                    commit('SET_REIMPRESION_CREDENCIAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveReimpresionCredencial: async function({ commit }, reimpresionCredencial) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + reimpresionCredencial.idPersonal + '/reimpresionCredencial', reimpresionCredencial)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateReimpresionCredencial: async function({ commit }, reimpresionCredencial) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/reimpresionCredencial/' + reimpresionCredencial.id, reimpresionCredencial)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
