<template>
    <v-container fluid>
        <v-row>
            <v-col cols="8">
                <v-card class="card" color="primary" height="100%">
                    <v-card-title>
                        <div class="text-h6 font-weight-bold">Calendario de eventos</div>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="space-between">
                                <v-col cols="4">
                                    <v-btn text @click="$refs.calendar.prev()">
                                        <v-icon>mdi-arrow-left</v-icon>
                                        Mes anterior
                                    </v-btn>
                                </v-col>
                                <v-col cols="4" align="right">
                                    <v-btn text @click="$refs.calendar.next()">
                                        Mes siguiente
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-calendar
                            ref="calendar"
                            v-model="calendario"
                            color="secondary"
                            event-color="secondary"
                            :events="calendarPendientesConsultaPrevia"
                            @click:event="mostrarEvento($event)"
                        ></v-calendar>
                        <v-menu v-model="selectedOpen" :close-on-content-click="false" :close-on-click="false" :activator="selectedElement" offset-x>
                            <v-card class="card rounded-0" color="grey lighten-4" min-width="350px" flat>
                                <v-card-title>{{ selectedEvent.name }}</v-card-title>
                                <v-card-text>
                                    <div class="text-subtitle-2 font-weight-bold">Impartido por: {{ selectedEvent.capacitador }}</div>
                                    <div class="text-subtitle-2 font-weight-bold">Aula: {{ selectedEvent.aula }}</div>
                                    <div class="text-subtitle-2 font-weight-bold">Fecha de finalización: {{ formatDate(selectedEvent.end) }}</div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn color="secondary" @click="closeMenu()">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold">Pendientes de consulta previa</v-card-title>
                    <v-card-text>
                        <div v-if="pendientesConsultaPrevia.length == 0" class="text-body-1">No hay información para mostrar.</div>
                        <template v-else>
                            <v-virtual-scroll height="360" item-height="64" :items="pendientesConsultaPrevia">
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item.idPersonal" two-line>
                                        <v-list-item-avatar>
                                            <v-avatar color="secondary" size="80">
                                                <v-icon color="white">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="text-body-1 font-weight-bold">
                                                    {{ getFullName(item) }}
                                                </div>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div class="text-body-2">CURP: {{ item.curp }}</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters } from 'vuex'
    import validator from 'validator'

    export default {
        data() {
            return {
                calendario: '',

                selectedOpen: false,
                selectedEvent: {},
                selectedElement: null,
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirantes: 'getPreaspirantes',
            }),

            ...mapGetters('pendientesConsultaPrevia', {
                pendientesConsultaPrevia: 'getPendientesConsultaPrevia',
            }),

            calendarPendientesConsultaPrevia: function() {
                var programacion = []

                // this.cursosProgramados.forEach(cursoProgramado => {
                //     if (
                //         validator.isDate(moment(cursoProgramado.fechaInicio).format('YYYY-MM-DD'), { format: 'YYYY-MM-DD' }) &&
                //         validator.isDate(moment(cursoProgramado.fechaFin).format('YYYY-MM-DD'), { format: 'YYYY-MM-DD' })
                //     ) {
                //         programacion.push({
                //             name: 'Inicio de curso: ' + cursoProgramado.Subcategoria.subcategoria,
                //             aula: cursoProgramado.Aula.aula,
                //             capacitador: this.getFullName(cursoProgramado.Capacitador),
                //             start: moment(cursoProgramado.fechaInicio).format('YYYY-MM-DD'),
                //             end: moment(cursoProgramado.fechaFin).format('YYYY-MM-DD'),
                //         })
                //     }
                // })

                return programacion
            },
        },

        methods: {
            mostrarEvento(event) {
                this.selectedEvent = event.event
                this.selectedOpen = true
            },

            closeMenu() {
                this.selectedOpen = false
            },
        },
    }
</script>
