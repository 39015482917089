import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        armas: [],
    },

    getters: {
        getArmas: state => {
            return state.armas
        },
    },

    mutations: {
        SET_ARMAS: (state, armas) => {
            state.armas = armas
        },
    },

    actions: {
        fetchArmas: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/armas/')
                .then(response => {
                    commit('SET_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveArma: async function({ commit }, arma) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/armas/', arma)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateArma: async function({ commit }, arma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/armas/' + arma.idArma, arma)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateEstatusArma: async function(context, arma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/armas/' + arma.idArma + '/estatus', {
                    fk_idEstatus: arma.fk_idEstatus,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Estatus de arma cambiado correctamente.', { root: true })
                })
                .catch(error => console.log(error))
        },
    },
}
