<template>
    <v-container v-if="candidato">
        <v-row>
            <v-col cols="8" align="right" offset-md="4">
                <v-spacer></v-spacer>
                <v-btn rounded color="secondary" class="mx-2" v-on:click="triggerUpdatePersonal()">Guardar cambios</v-btn>
            </v-col>
            <v-col cols="4" xl="3">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card" color="secondary">
                            <v-card-title class="font-weight-bold primary--text ml-4">Expediente</v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row align="center">
                                        <v-col cols="4">
                                            <v-avatar color="primary" size="96" rounded>
                                                <v-icon color="secondary" size="64">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="8">
                                            <div class="text-subtitle-1 primary--text font-weight-bold">{{ candidato.apaterno }}</div>
                                            <div class="text-subtitle-2 primary--text ">
                                                {{ candidato.amaterno + ' ' + candidato.nombre + ' ' + candidato.segundoNombre }}
                                            </div>
                                            <v-chip class="secondary--text my-2 font-weight-bold" color="primary" small>
                                                {{ candidato.CatEstatus.title }}
                                            </v-chip>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="card" color="secondary">
                            <v-card-text>
                                <v-virtual-scroll height="480" item-height="48" :items="menu">
                                    <template v-slot:default="{ item }">
                                        <v-list-item :key="item" link v-on:click="selectMenu(item)">
                                            <v-list-item-content>
                                                <v-list-item-title class="primary--text">
                                                    {{ item }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-virtual-scroll>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="9">
                <v-card class="card" color="primary" v-if="selectedMenu == 'Información candidato '">
                    <v-card-title class="font-weight-bold text-h5 justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col lg="8">
                                    <div class="text-h6 font-weight-bold">Nombre del candidato</div>
                                </v-col>
                                <v-col lg="4">
                                    <v-form v-model="nombreForm" ref="form">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="candidato.apaterno"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Apellido paterno"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="candidato.amaterno"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Apellido materno"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="candidato.nombre"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Nombre"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="candidato.segundoNombre"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Segundo nombre"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col lg="8">
                                    <div class="text-h6 font-weight-bold">Fecha de nacimiento</div>
                                </v-col>
                                <v-col lg="4">
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field
                                                v-model="candidato.fechaNacimiento"
                                                rounded
                                                filled
                                                dense
                                                label="Fecha de nacimiento *"
                                                background-color="primary"
                                                color="secondary"
                                                :rules="nameRules"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" align="center">
                                            <v-menu
                                                ref="menu"
                                                v-model="menuNacimiento"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                min-width="auto"
                                                offset-y
                                                left
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn fab icon color="secondary" class="mt-2" elevation="4" small v-on="on" v-bind="attrs"
                                                        ><v-icon>mdi-calendar</v-icon></v-btn
                                                    >
                                                </template>
                                                <v-date-picker
                                                    no-title
                                                    ref="picker"
                                                    v-model="date"
                                                    :max="new Date().toISOString().substr(0, 10)"
                                                    min="1950-01-01"
                                                    @change="save"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col lg="8">
                                    <div class="text-h6 font-weight-bold">Estado civil</div>
                                </v-col>
                                <v-col lg="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select
                                                v-model="candidato.fk_idEstadoCivil"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Estado civil *"
                                                :items="estadoCivil"
                                                item-value="id"
                                                item-text="title"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col lg="8">
                                    <div class="text-h6 font-weight-bold">Sexo</div>
                                </v-col>
                                <v-col lg="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select
                                                v-model="candidato.fk_idSexo"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Sexo *"
                                                :items="sexo"
                                                item-value="id"
                                                item-text="title"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Nacionalidad'">
                    <v-card-title class="font-weight-bold text-h5 justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Nacionalidad</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-form v-model="nacionalidadForm" ref="form">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="candidato.nacionalidad"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Nacionalidad"
                                                ></v-text-field>
                                                <v-select
                                                    v-model="candidato.fk_idTipoNacionalidad"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Tipo de nacionalidad *"
                                                    :items="tipoNacionalidad"
                                                    item-value="id"
                                                    item-text="title"
                                                ></v-select>
                                                <div v-if="candidato.CatMunicipioNacimiento">
                                                    <v-select
                                                        v-model="candidato.CatMunicipioNacimiento.fk_idEstado"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Estado donde nació *"
                                                        :items="estados"
                                                        item-value="id"
                                                        item-text="title"
                                                        :rules="nameRules"
                                                    ></v-select>
                                                    <v-select
                                                        v-model="candidato.fk_idMunicipioNacimiento"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Municipio donde nació *"
                                                        :items="filteredMunicipioNace"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        :rules="nameRules"
                                                    ></v-select>
                                                </div>
                                                <div v-else>
                                                    <v-select
                                                        v-model="estadoNace"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Estado donde nació *"
                                                        :items="estados"
                                                        item-value="id"
                                                        item-text="title"
                                                        :rules="nameRules"
                                                    ></v-select>
                                                    <v-select
                                                        v-model="municipioNace"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Municipio donde nació *"
                                                        :items="filteredMunicipioNace"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        :rules="nameRules"
                                                    ></v-select>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Documentación oficial'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Clave única de Registro de Población</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.curp"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="CURP"
                                                maxlength="18"
                                                counter="18"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Clave de elector (INE)</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.claveIne"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Clave INE"
                                                maxlength="18"
                                                counter="18"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Número de Seguro Social</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.nss"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="NSS"
                                                maxlength="11"
                                                counter="11"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Pasaporte</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.pasaporte"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Pasaporte"
                                                maxlength="9"
                                                counter="9"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Número de Registro Federal de Contribuyentes</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.rfc"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="RFC"
                                                maxlength="13"
                                                counter="13"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-h6 font-weight-bold">Licencia para conducir</div>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.licenciaConducir"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Licencia para conducir"
                                                maxlength="9"
                                                counter="9"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="candidato.vigenciaLicencia"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Vigencia de licencia para conducir"
                                                maxlength="4"
                                                counter="4"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Información de contacto'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="contactoForm" ref="form">
                                <v-row>
                                    <v-col cols="8">
                                        <div class="text-h6 font-weight-bold">Correo electrónico</div>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="candidato.Contacto.correoElectronico"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Correo electrónico"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="text-h6 font-weight-bold">Teléfonos</div>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="candidato.Contacto.telefonoCelular"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Telefono celular"
                                                    maxlength="10"
                                                    counter="10"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="candidato.Contacto.telefonoFijo"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Telefono fijo"
                                                    maxlength="10"
                                                    counter="10"
                                                    :rules="nameRules"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Ubicación'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Lugar donde radica actualmente</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <div v-if="candidato.CatMunicipioRadica">
                                            <v-select
                                                v-model="candidato.CatMunicipioRadica.fk_idEstado"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Estado donde radica *"
                                                :items="estados"
                                                item-value="id"
                                                item-text="title"
                                                :rules="nameRules"
                                            ></v-select>
                                            <v-select
                                                v-model="candidato.fk_idMunicipioRadica"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Municipio donde radica *"
                                                :items="filteredMunicipioRadica"
                                                item-value="id"
                                                item-text="nombre"
                                                :rules="nameRules"
                                            ></v-select>
                                        </div>
                                        <div v-else>
                                            <v-select
                                                v-model="estadoRadica"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Estado donde radica *"
                                                :items="estados"
                                                item-value="id"
                                                item-text="title"
                                                :rules="nameRules"
                                            ></v-select>
                                            <v-select
                                                v-model="municipioRadica"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Municipio donde radica *"
                                                :items="filteredMunicipioRadica"
                                                item-value="id"
                                                item-text="nombre"
                                                :rules="nameRules"
                                            ></v-select>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                            </v-col>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Dirección</div>
                            </v-col>
                            <v-col cols="4">
                                <v-form v-model="ubicacionForm" ref="form">
                                    <v-row v-if="candidato.Contacto.Direccion">
                                        <v-col cols="12">
                                            <div>
                                                <v-select
                                                    v-model="candidato.Contacto.Direccion.CatMunicipio.fk_idEstado"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Estado donde nació *"
                                                    :items="estados"
                                                    item-value="id"
                                                    item-text="title"
                                                    :rules="nameRules"
                                                ></v-select>
                                                <v-select
                                                    v-model="candidato.Contacto.Direccion.fk_idMunicipio"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Municipio donde nació *"
                                                    :items="filteredMunicipioDireccion"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    :rules="nameRules"
                                                ></v-select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.calle"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Calle"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.entrecalles"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.entrecalles2"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle 2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6"
                                            ><v-text-field
                                                v-model="candidato.Contacto.Direccion.ext"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Número exterior"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.int"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Número interior"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.colonia"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Colonia"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.cp"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="CP"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="candidato.Contacto.Direccion.referencias"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Referencias"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12">
                                            <div>
                                                <v-select
                                                    v-model="estadoDireccion"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Estado donde vive *"
                                                    :items="estados"
                                                    item-value="id"
                                                    item-text="title"
                                                ></v-select>
                                                <v-select
                                                    v-model="municipioDireccion"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Municipio donde vive *"
                                                    :items="filteredMunicipioDireccion"
                                                    item-value="id"
                                                    item-text="nombre"
                                                ></v-select>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="calle"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Calle"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="entrecalles"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="entrecalles2"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Entre calle 2"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6"
                                            ><v-text-field
                                                v-model="ext"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Número exterior"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="int"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Número interior"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field
                                                v-model="colonia"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Colonia"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="cp"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="CP"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="referencias"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Referencias"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Estudios'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="estudiosPersonal.length > 0">
                                <v-col cols="4" v-for="estudio in estudiosPersonal" :key="estudio.id">
                                    <v-card class="card" color="primary">
                                        <v-card-title class="justify-center" align="center">
                                            <div class="text-subtitle-1 font-weight-black ">
                                                {{ estudio.institucion }}
                                            </div>
                                        </v-card-title>
                                        <v-card-text align="center">
                                            <v-container>
                                                <v-row align="center">
                                                    <v-col cols="6" align="left">
                                                        <div class="subtitle-2 font-weight-bold">Generación</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-subtitle-1">{{ estudio.generacion }}</div>
                                                    </v-col>
                                                    <v-col cols="6" align="left">
                                                        <div class="subtitle-2 font-weight-bold">Estatus</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="subtitle-1">{{ estudio.CatEstatusEstudio.title }}</div>
                                                    </v-col>
                                                    <v-col cols="6" align="left">
                                                        <div class="subtitle-2 font-weight-bold">Comprobante</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="subtitle-1">{{ estudio.ComprobanteEstudio.title }}</div>
                                                    </v-col>
                                                    <v-col cols="6" align="left">
                                                        <div class="subtitle-2 font-weight-bold">Grado</div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="subtitle-1">{{ estudio.CatEstudios.title }}</div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="text-caption font-weight-bold">Observaciones</div>
                                                        <div v-if="estudio.observaciones.length > 0" class="text-body-1">{{ estudio.observaciones }}</div>
                                                        <div v-else class="text-body-1">Ninguna</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" align="center">
                                    <v-icon color="primary darken-3" size="256">mdi-folder-alert</v-icon>
                                    <div class="text-h6">Este elemento no ha registrado comprobantes de estudio.</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-btn color="secondary" :to="{ name: 'agregaEstudioPersonal', params: { id: id } }">Agregar estudio</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Documentos'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="documentos.length > 0">
                                <v-col cols="4" v-for="documento in documentos" :key="documento.id">
                                    <v-card color="primary" class="card" v-if="documento.fk_idEstatus == 1">
                                        <v-card-title class="font-weight-bold justify-center">{{ documento.TipoDocumento.title }}</v-card-title>
                                        <v-card-text align="center">
                                            <v-icon size="80" color="warning">mdi-timer-sand</v-icon>
                                            <div class="text-subtitle-2 font-weight-bold">Este documento está pendiente por validarse</div>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                            <v-btn v-if="documento.fk_idEstatus == 1" v-on:click="triggerUpdateEstatusDocumento(documento)">Validar</v-btn>
                                            <v-btn color="secondary" v-on:click="download(documento.URL)">Descargar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    <v-card color="primary" class="card" v-else>
                                        <v-card-title class="font-weight-bold justify-center">{{ documento.TipoDocumento.title }}</v-card-title>
                                        <v-card-text align="center">
                                            <v-icon size="80" color="success">mdi-check</v-icon>
                                            <div class="text-subtitle-2 font-weight-bold">Este documento ha sido validado</div>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                            <v-btn color="secondary" v-on:click="download(documento.URL)">Descargar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" align="center">
                                    <v-icon color="primary darken-3" size="256">mdi-folder-alert</v-icon>
                                    <div class="text-h6">Este elemento no ha registrado documentos.</div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-btn color="secondary" :to="{ name: 'agregaDocumentoPersonal', params: { id: id } }">Agregar documento</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Evaluaciones médicas'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="evaluacionesMedicas.length > 0">
                                <v-col cols="4" v-for="evaluacionMedica in evaluacionesMedicas" :key="evaluacionMedica.id">
                                    <v-card color="primary" class="card">
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            :value="evaluacionMedica.fechaAplicacion"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Fecha de aplicación"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="text-caption">Resultados</div>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div class="text-subtitle-2 font-weight-bold">Médica</div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-icon v-if="evaluacionMedica.medica == 1" color="success">mdi-check</v-icon>
                                                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div class="text-subtitle-2 font-weight-bold">Física</div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-icon v-if="evaluacionMedica.clinica == 1" color="success">mdi-check</v-icon>
                                                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div class="text-subtitle-2 font-weight-bold">Clínica</div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-icon v-if="evaluacionMedica.fisica == 1" color="success">mdi-check</v-icon>
                                                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-divider></v-divider>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <div class="text-subtitle-2 font-weight-bold">Resultado</div>
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-icon v-if="evaluacionMedica.resultado == 1" color="success">mdi-check</v-icon>
                                                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" algin="center">
                                    <div class="text--secondary text-h6">Este candidato no cuenta con evaluaciones médicas registradas</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card class="card" color="primary" v-if="selectedMenu == 'Dictamenes'">
                    <v-card-title class="font-weight-bold justify-center">{{ selectedMenu }}</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="dictamenPersonal.length > 0">
                                <v-col cols="4" offset-md="4">
                                    <v-text-field
                                        :value="dictamenPersonal[0].fecha"
                                        rounded
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Fecha de registro"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="10" offset-md="1">
                                    {{ dictamenPersonal[0].dictamen }}
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" align="center">
                                    <div class="text-h6">No se ha hecho el dictamen de candidato</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .selectable {
        cursor: pointer;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import _ from 'lodash'

    export default {
        async mounted() {
            await this.fetchEstudiosByPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            await this.fetchEvaluacionMedicaByPersonal(this.id)
            await this.fetchDictamenPersonal(this.id)
            this.SET_CANDIDATO_PARA_VALIDACION(this.id)
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                selectedMenu: 'Información candidato ',

                nameRules: [v => !!v || 'Este campo es requerido.'],

                nombreForm: false,
                nacionalidadForm: false,
                contactoForm: false,
                ubicacionForm: false,

                estadoNace: null,
                municipioNace: null,
                estadoRadica: null,
                municipioRadica: null,
                estadoDireccion: null,
                municipioDireccion: null,
                saveChanges: false,

                menuNacimiento: false,
                date: null,

                calle: '',
                entrecalles: '',
                entrecalles2: '',
                ext: '',
                int: '',
                colonia: '',
                cp: '',
                referencias: '',
            }
        },

        watch: {
            menuNacimiento(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },

            candidato: {
                deep: true,
                handler: function(after, before) {
                    if (_.isEqual(after, before)) {
                        this.saveChanges = true
                    }
                },
            },
        },

        computed: {
            ...mapGetters('candidato', {
                candidato: 'getCandidatoParaValidacion',
            }),

            ...mapGetters('estudio', {
                estudios: 'getEstudios',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicas: 'getEvaluacionMedica',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenPersonal: 'getDictamenPersonal',
            }),

            ...mapGetters('cursoAsignado', {
                cursos: 'getCursosAsignados',
            }),

            ...mapGetters('productividad', {
                evaluacionesProductividad: 'getProductividad',
            }),

            ...mapGetters('respetoPrincipios', {
                evaluacionesRespetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('estadoCivil', {
                estadoCivil: 'getEstadoCivil',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            menu: function() {
                if (this.candidato.fk_idEstatus < 8) {
                    return [
                        'Información candidato ',
                        'Nacionalidad',
                        'Documentación oficial',
                        'Información de contacto',
                        'Ubicación',
                        'Estudios',
                        'Documentos',
                        'Evaluaciones médicas',
                        'Dictamenes',
                    ]
                } else {
                    return [
                        'Información candidato ',
                        'Nacionalidad',
                        'Documentación oficial',
                        'Información de contacto',
                        'Ubicación',
                        'Estudios',
                        'Documentos',
                    ]
                }
            },

            estudiosPersonal: function() {
                if (this.estudios) {
                    return this.estudios.filter(estudio => {
                        return estudio.fk_idPersonal == this.id
                    })
                } else return []
            },

            cursosPersonal: function() {
                if (this.cursos) {
                    return this.cursos.filter(cursoAsignado => {
                        return cursoAsignado.fk_idPersonal == this.idPersonal
                    })
                } else return []
            },

            evaluacionesPersonalProductividad: function() {
                if (this.evaluacionesProductividad) {
                    return this.evaluacionesProductividad.filter(evaluacion => {
                        evaluacion.fk_idPersonal == this.idPersonal
                    })
                } else return []
            },

            evaluacionesPersonalRespetoPrincipios: function() {
                if (this.evaluacionesRespetoPrincipios) {
                    return this.evaluacionesRespetoPrincipios.filter(evaluacion => {
                        evaluacion.fk_idPersonal == this.idPersonal
                    })
                } else return []
            },

            filteredMunicipioNace: function() {
                if (this.candidato.CatMunicipioNacimiento) {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.candidato.CatMunicipioNacimiento.fk_idEstado
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.estadoNace
                    })
                }
            },

            filteredMunicipioRadica: function() {
                if (this.candidato.CatMunicipioRadica) {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.candidato.CatMunicipioRadica.fk_idEstado
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.estadoRadica
                    })
                }
            },

            filteredMunicipioDireccion: function() {
                if (this.candidato.Contacto.Direccion) {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.candidato.Contacto.Direccion.CatMunicipio.fk_idEstado
                    })
                } else {
                    return this.municipios.filter(municipio => {
                        return municipio.catEstado.id == this.estadoDireccion
                    })
                }
            },
        },

        methods: {
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal']),
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal']),
            ...mapActions('estatusDocumento', ['updateEstatusDocumento']),
            ...mapMutations('candidato', ['SET_CANDIDATO_PARA_VALIDACION']),
            ...mapActions('candidato', ['updateCandidato']),

            selectMenu: function(menu) {
                this.selectedMenu = menu
            },

            triggerUpdateEstatusDocumento: async function(documento) {
                await this.updateEstatusDocumento({
                    idPersonal: documento.fk_idPersonal,
                    idDocumentoPersonal: documento.iddocumentosPersonal,
                })
                await this.fetchDocumentoByPersonal(this.id)
            },

            download: function(url) {
                window.open(url, '_blank')
            },

            triggerUpdatePersonal: async function() {
                var x = this.candidato

                if (this.municipioNace) {
                    x.fk_idMunicipioNacimiento = this.municipioNace
                }
                if (this.municipioRadica) {
                    x.fk_idMunicipioRadica = this.municipioRadica
                }
                if (this.municipioDireccion) {
                    x.Contacto.Direccion = {
                        CatMunicipio: {
                            fk_idEstado: this.estadoDireccion,
                        },
                        calle: this.calle,
                        colonia: this.colonia,
                        cp: this.cp,
                        entrecalles: this.entrecalles,
                        entrecalles2: this.entrecalles2,
                        ext: this.ext,
                        fk_idMunicipio: this.municipioDireccion,
                        int: this.int,
                    }
                }
                await this.updateCandidato(x)
            },

            save(date) {
                this.$refs.menu.save(date)
                this.candidato.fechaNacimiento = moment(date).format('DD/MM/YYYY')
            },
        },
    }
</script>
