<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Inventario de accesorios</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="2">
                                    <v-select
                                        label="Tipo de accesorio"
                                        v-model="selectedTipoItem"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :items="tipoItem"
                                        item-color="secondary"
                                        item-text="title"
                                        item-value="id"
                                        hide-details
                                        @change="fetchItems($event)"
                                    ></v-select>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2" align="right">
                                    <registro-item-dialog-component></registro-item-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaItem"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar accesorio"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table class="primary" :headers="headers" :items="items" :search="buscaItem" hide-default-footer calculate-widths>
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn rounded small color="secondary" class="mx-2" @click="openEditarItemDialog(item)">Editar accesorio</v-btn>
                                            <v-btn rounded small color="secondary" class="mx-2" @click="openHistorialMovimientosDialog(item)"
                                                >Ver movimientos</v-btn
                                            >
                                            <registrar-movimiento-item-dialog-component :id-item="item.id"></registrar-movimiento-item-dialog-component>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <editar-item-dialog-component :active="editarItemDialog" :item="selectedItem" @close="editarItemDialog = false"></editar-item-dialog-component>
        <historial-movimientos-item-dialog-component
            :active="historialMovimientosItemDialog"
            @close="historialMovimientosItemDialog = false"
        ></historial-movimientos-item-dialog-component>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import EditarItemDialogComponent from '../../components/armeria/EditarItemDialogComponent.vue'
    import HistorialMovimientosItemDialogComponent from '../../components/armeria/HistorialMovimientosItemDialogComponent.vue'
    import RegistrarMovimientoItemDialogComponent from '../../components/armeria/RegistrarMovimientoItemDialogComponent.vue'
    import RegistroItemDialogComponent from '../../components/armeria/RegistroItemDialogComponent.vue'

    export default {
        components: {
            RegistroItemDialogComponent,
            RegistrarMovimientoItemDialogComponent,
            EditarItemDialogComponent,
            HistorialMovimientosItemDialogComponent,
        },

        data() {
            return {
                headers: [
                    { text: 'Accesorio', value: 'item', width: '30%' },
                    { text: 'Opciones', value: 'opciones', align: 'center', width: '70%' },
                ],

                buscaItem: '',
                selectedTipoItem: null,

                historialMovimientosItemDialog: false,
                // listaCargadoresDialog: false,

                editarItemDialog: false,
                selectedItem: null,
            }
        },

        computed: {
            ...mapGetters('tipoItemArmeria', {
                tipoItem: 'getTipoItem',
            }),

            ...mapGetters('itemArmeria', {
                items: 'getItems',
            }),
        },

        methods: {
            ...mapActions('itemArmeria', ['fetchItems']),
            ...mapActions('movimientoItem', ['fetchMovimientoItem']),

            async openHistorialMovimientosDialog(item) {
                this.selectedItem = item
                await this.fetchMovimientoItem(item.id)

                this.historialMovimientosItemDialog = true
            },

            async openListaCargadoresDialog(calibre) {
                this.selectedCalibre = calibre
                await this.fetchCargadores(calibre.idCalibre)

                this.listaCargadoresDialog = true
            },

            openEditarItemDialog: function(item) {
                this.selectedItem = item
                this.editarItemDialog = true
            },

            getStockMunicionesByCalibre(id_calibre) {
                var stock = 0

                var filteredMuniciones = this.movimientoMunicion.filter(movimiento => {
                    return movimiento.fk_idCatCalibre == id_calibre
                })

                filteredMuniciones.forEach(movimiento => {
                    if (movimiento.fk_idcatTipoMovimiento == 1) {
                        stock += movimiento.cantidad
                    } else {
                        stock -= movimiento.cantidad
                    }
                })

                return stock
            },
        },
    }
</script>
