import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        value: 'my value',
    },

    getters: {
        value: state => {
            return state.value
        },
    },

    mutations: {
        updateValue(state, payload) {
            state.value = payload
        },
    },

    actions: {
        saveEntregaRecepcionArma: async function({ commit }, entregaRecepcionArma) {
            try {
                await axios.post('/PoliciaCuadrantes/Armeria/personal/' + entregaRecepcionArma.idPersonal + '/bitacoraER', entregaRecepcionArma)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
