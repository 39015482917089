<template>
    <v-dialog persistent v-model="active" width="480">
        <template v-slot:activator="{ on, attrs }">
            <v-btn rounded small class="mx-2" color="secondary" v-on="on" v-bind="attrs">Registrar entrada de inventario</v-btn>
        </template>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Movimiento de entrada de accesorio</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-form v-model="entradaItemForm" ref="form">
                    <v-text-field
                        v-model="movimientoItem.fechaCompra"
                        v-mask="'##/##/####'"
                        hint="Formato de fecha: DD/MM/YYYY"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        :rules="dateRules"
                    >
                        <template v-slot:label>Fecha de compra <span class="red--text">*</span> </template>
                    </v-text-field>
                    <v-text-field
                        v-model="movimientoItem.puestaDisposición"
                        v-mask="'##/##/####'"
                        hint="Formato de fecha: DD/MM/YYYY"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        :rules="dateRules"
                    >
                        <template v-slot:label>Fecha de puesta a disposición <span class="red--text">*</span> </template>
                    </v-text-field>
                    <v-text-field
                        v-model.number="movimientoItem.cantidad"
                        type="number"
                        color="secondary"
                        background-color="primary"
                        rounded
                        filled
                        dense
                        min="0"
                    >
                        <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                    </v-text-field>
                    <v-textarea
                        label="Observaciones"
                        v-model="movimientoItem.observaciones"
                        rounded
                        filled
                        dense
                        no-resize
                        rows="3"
                        color="secondary"
                        background-color="primary"
                    ></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :disabled="loading || !entradaItemForm" :loading="loading" @click="triggerSaveMovimientoItem()"
                    >Registrar entrada</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions } from 'vuex'

    export default {
        props: {
            idItem: {
                type: Number,
            },
        },

        data() {
            return {
                active: false,
                entradaItemForm: false,
                loading: false,

                movimientoItem: {
                    fk_idCatItem: null,
                    fk_idcatTipoMovimiento: 1,
                    cantidad: 0,
                    observaciones: '',
                    fechaCompra: null,
                    puestaDisposición: null,
                },

                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        methods: {
            ...mapActions('movimientoItem', ['saveMovimientoItem', 'fetchMovimientoItem']),

            closeDialog() {
                this.$refs.form.resetValidation()
                this.active = false
                this.movimientoItem = {
                    fk_idCatItem: null,
                    fk_idcatTipoMovimiento: 1,
                    cantidad: 0,
                    observaciones: '',
                }
                this.loading = false
            },

            async triggerSaveMovimientoItem() {
                this.loading = true
                this.movimientoItem.fk_idCatItem = this.idItem
                await this.saveMovimientoItem(this.movimientoItem)
                await this.fetchMovimientoItem()

                this.closeDialog()
            },
        },
    }
</script>
