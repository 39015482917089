import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        evaluacionAnual: [],
        evaluacionAnualSingle: [],
    },

    getters: {
        getEvaluacionAnual: state => {
            return state.evaluacionAnual
        },

        getEvaluacionAnualSingle: state => {
            return state.evaluacionAnualSingle[0]
        },
    },

    mutations: {
        SET_EVALUACION_ANUAL(state, evaluacionAnual) {
            state.evaluacionAnual = evaluacionAnual
        },

        SET_EVALUACION_ANUAL_SINGLE(state, evaluacionAnualSingle) {
            state.evaluacionAnualSingle = evaluacionAnualSingle
        },
    },

    actions: {
        fetchEvaluacionAnualById: async function({ commit }, evaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacion.fkidPersonal + '/evaluacionAnual/' + evaluacion.idEvaluacionAnual)
                .then(response => {
                    commit('SET_EVALUACION_ANUAL_SINGLE', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchEvaluacionAnual: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/evaluacionAnual')
                .then(response => {
                    commit('SET_EVALUACION_ANUAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchEvaluacionAnualByTipo: async function({ commit }, evaluacionAnual) {
            await axios
                .get(
                    '/PoliciaCuadrantes/DesarrolloPolicial/tipoEvaluacion/' +
                        evaluacionAnual.fk_idCatTipoEvaluacion +
                        '/personal/' +
                        evaluacionAnual.idPersonal +
                        '/evaluacionAnual'
                )
                .then(response => {
                    commit('SET_EVALUACION_ANUAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveEvaluacionAnual: async function(context, evaluacionAnual) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacionAnual.fk_idPersonal + '/evaluacionAnual', evaluacionAnual)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación anual registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEvaluacionAnual: async function(context, evaluacionAnual) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacionAnual.fk_idPersonal + '/evaluacionAnual/' + evaluacionAnual.id,
                    evaluacionAnual
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación anual editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatus: async function(context, evaluacionAnual) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacionAnual.fk_idPersonal + '/evaluacionAnual/' + evaluacionAnual.id + '/estatus',
                    { fk_idCatEstatusEvaluacion: 3 }
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación anual editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
