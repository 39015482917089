<template>
    <v-container>
        <v-row align="center">
            <v-col cols="2">
                <v-select
                    label="Marca"
                    v-model="selectedMarca"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                    :items="marcas"
                    item-value="id"
                    item-text="title"
                    item-color="secondary"
                    hide-details
                    @change="triggerFetchModeloArmas($event)"
                ></v-select>
            </v-col>
            <v-col cols="2">
                <v-select
                    label="Modelo"
                    v-model="selectedModelo"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                    :items="modeloArma"
                    item-value="id"
                    item-text="modelo"
                    item-color="secondary"
                    hide-details
                    @change="triggerConsultaMovimientos($event)"
                ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col align="right">
                <registrar-movimiento-arma-dialog-component></registrar-movimiento-arma-dialog-component>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary" :loading="loading">
                    <v-card-title>
                        <div class="text-h6">Movimientos de arma</div>
                        <v-spacer></v-spacer>
                        <v-alert color="secondary" class="white--text" v-if="!loading"> Disponibles: {{ getDisponibles }} </v-alert>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headers" class="transparent" :items="filteredMovimientos">
                            <template v-slot:item.fk_idcatTipoMovimiento="{ item }">
                                <div v-if="item.fk_idcatTipoMovimiento == 1" class="text-body-2">Entrada</div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import RegistrarMovimientoArmaDialogComponent from '../../components/armeria/RegistrarMovimientoArmaDialogComponent.vue'

    export default {
        components: {
            RegistrarMovimientoArmaDialogComponent,
        },

        async mounted() {
            await this.fetchMovimientoArma()
        },

        data() {
            return {
                loading: false,
                selectedMarca: null,
                selectedModelo: null,

                headers: [
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Tipo de movimiento', value: 'fk_idcatTipoMovimiento' },
                    { text: 'Modelo', value: 'CatModelo.modelo' },
                    { text: 'Cantidad', value: 'cantidad' },
                    { text: 'Observaciones', value: 'observaciones' },
                ],
            }
        },

        computed: {
            ...mapGetters('marcaArma', {
                marcas: 'getMarcaArma',
            }),

            ...mapGetters('modeloArma', {
                modeloArma: 'getModelosArmas',
            }),

            ...mapGetters('movimientoArma', {
                movimientoArma: 'getMovimientoArma',
                movimientoArmaDisponibles: 'getMovimientoArmaDisponibles',
            }),

            filteredMovimientos: function() {
                return this.movimientoArma.filter(movimiento => {
                    return movimiento.fk_idCatModelo == this.selectedModelo
                })
            },

            getDisponibles: function() {
                if (this.loading || !this.selectedModelo) {
                    return 0
                } else return this.movimientoArmaDisponibles[0].disponibles
            },
        },

        methods: {
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('movimientoArma', ['fetchMovimientoArmaDisponibles', 'fetchMovimientoArma']),

            async triggerConsultaMovimientos() {
                this.loading = true
                await this.fetchMovimientoArmaDisponibles(this.selectedModelo)
                this.loading = false
            },

            async triggerFetchModeloArmas(event) {
                this.loading = true
                this.selectedModelo = null
                await this.fetchModeloArmas(event)
                this.loading = false
            },
        },
    }
</script>
