<template>
    <v-main>
        <v-container fluid>
            <v-row align="center" class="launcher" v-if="perfil == 'Administrador IPH+'">
                <v-col cols="12" align="center">
                    <div class="text-h6 font-weight-black">Aplicaciones operativas</div>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card elevation="24" color="gradient">
                        <v-card-title class="d-block justify-center" align="center">
                            <div class="font-weight-bold text-h5 py-4 text-armory">DPRS (PPL)</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="newWindow('https://eonproduccion.net:44354')">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card elevation="24" color="gradient">
                        <v-card-title class="d-block justify-center" align="center">
                            <div class="font-weight-bold text-h5 py-4 text-armory">Tablero de control</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="newWindow('https://eonproduccion.net:9091/bi')">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-card elevation="24" color="gradient">
                        <v-card-title class="d-block justify-center" align="center">
                            <div class="font-weight-bold text-h5 py-4 text-armory">IPH+</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="newWindow('http://eonproduccion.net/iph.html')">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" align="center">
                    <div class="text-h6 font-weight-black">Aplicaciones administrativas</div>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-armeria">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80" color="#DADAF2">mdi-ammunition</v-icon>
                            <div class="font-weight-bold text-h6 py-4 text-armory">Armería</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(1)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-equipamiento">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-briefcase</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Equipamiento</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(2)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-prof">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-school</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Profesionalización</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(3)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-dpol">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-account-star</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Desarrollo policial</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(4)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-consejo">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-file-certificate</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Consejo de honor y justicia</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-enlace">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80" color="#DADAF2">mdi-file-certificate</v-icon>
                            <div class="font-weight-bold text-h6 py-4 white--text">Enlace C3</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(6)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color="gradient-rh">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-file-certificate</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Recursos Humanos</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(7)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="3">
                    <v-card elevation="24" color=" gradient-personal">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-file-certificate</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Registro de personal</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(8)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" lg="3">
                    <v-card elevation="24">
                        <v-card-title class="d-block justify-center" align="center">
                            <v-icon size="80">mdi-magnify</v-icon>
                            <div class="font-weight-bold text-h6 py-4">Consulta de personal</div>
                        </v-card-title>
                        <v-card-actions class="justify-center">
                            <v-btn rounded class="mb-4 px-8 button" v-on:click="setTheme(9)">Entrar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<style scoped>
    .text-armory {
        color: #dadaf2 !important;
    }
    .gradient {
        background: rgb(0, 0, 0) !important;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(18, 18, 18, 1) 47%, rgba(38, 38, 38, 1) 100%) !important;
    }

    .gradient-armeria {
        background: rgb(52, 36, 147) !important;
        background: linear-gradient(0deg, rgba(52, 36, 147, 1) 0%, rgba(70, 48, 207, 1) 39%, rgba(47, 27, 168, 1) 100%) !important;
    }

    .gradient-equipamiento {
        background: rgb(255, 182, 35) !important;
        background: linear-gradient(0deg, rgb(194, 136, 22) 0%, rgb(241, 190, 87) 39%, rgb(214, 150, 21) 100%) !important;
    }

    .gradient-prof {
        background: rgb(52, 168, 113) !important;
        background: linear-gradient(0deg, rgba(52, 168, 113, 1) 0%, rgba(71, 224, 152, 1) 39%, rgba(52, 168, 113, 1) 100%) !important;
    }

    .gradient-dpol {
        background: rgb(245, 185, 64) !important;
        background: linear-gradient(0deg, rgb(206, 155, 55) 0%, rgb(255, 201, 92) 39%, rgb(211, 160, 58) 100%) !important;
    }

    .gradient-consejo {
        background: rgb(61, 61, 61);
        background: linear-gradient(0deg, rgb(61, 61, 61) 0%, rgb(102, 102, 102) 39%, rgb(61, 61, 61) 100%);
    }

    .gradient-enlace {
        background: rgb(245, 185, 64) !important;
        background: linear-gradient(0deg, rgb(206, 155, 55) 0%, rgb(255, 201, 92) 39%, rgb(211, 160, 58) 100%) !important;
    }

    .gradient-rh {
        background: rgb(245, 65, 39) !important;
        background: linear-gradient(0deg, rgb(197, 51, 29) 0%, rgb(247, 78, 52) 39%, rgb(192, 48, 26) 100%) !important;
    }

    .gradient-personal {
        background: rgb(135, 25, 19);
        background: linear-gradient(0deg, rgb(124, 24, 21) 0%, rgb(223, 48, 39) 39%, rgb(121, 30, 25) 100%);
    }

    .button {
        background: rgb(255, 255, 255) !important;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(240, 240, 240, 1) 39%, rgba(249, 248, 248, 1) 100%) !important;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        mounted() {
            if (this.perfil == 'Candidato') {
                this.$router.push({ path: '/candidato/informacion_personal/' + this.idPersonal, params: { id: this.idPersonal } })
            }
            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters({
                idPersonal: 'getIdPersonal',
            }),

            ...mapGetters('tipoItem', {
                tiposItem: 'getTiposItem',
            }),
        },

        methods: {
            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),

            async setTheme(theme_id) {
                switch (theme_id) {
                    case 1:
                        this.$vuetify.theme.themes.light.primary = '#f4f2ff'
                        this.$vuetify.theme.themes.light.secondary = '#2F1BA8'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#2F1BA8'
                        this.$router.push('/armeria/inventario_armas')
                        break
                    case 2:
                        this.ENABLE_LOADER()
                        await this.fetchTiposItem()
                        this.$vuetify.theme.themes.light.primary = '#FFF8F2'
                        this.$vuetify.theme.themes.light.secondary = '#A85113'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#A85113'
                        this.$router.push('/equipamiento/personal')
                        this.DISABLE_LOADER()
                        break
                    case 3:
                        this.$vuetify.theme.themes.light.primary = '#f2fff8'
                        this.$vuetify.theme.themes.light.secondary = '#34A871'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#34A871'
                        this.$router.push('/profesionalizacion/dashboard')
                        break
                    case 4:
                        this.$vuetify.theme.themes.light.primary = '#fffbf2'
                        this.$vuetify.theme.themes.light.secondary = '#BD7E00'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#BD7E00'
                        this.$router.push('/desarrollo_profesional/personal')
                        break
                    case 5:
                        this.$vuetify.theme.themes.light.primary = '#f9f2ff'
                        this.$vuetify.theme.themes.light.secondary = '#7D3EAB'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#7D3EAB'
                        break
                    case 6:
                        this.$vuetify.theme.themes.light.primary = '#FFF7F2'
                        this.$vuetify.theme.themes.light.secondary = '#4A4A4A'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#4A4A4A'
                        this.$router.push('/enlace_c3/dashboard')
                        break
                    case 7:
                        this.$vuetify.theme.themes.light.primary = '#fff4f2'
                        this.$vuetify.theme.themes.light.secondary = '#F54127'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#F54127'
                        this.$router.push('/recursos_humanos/dashboard')
                        break
                    case 8:
                        this.$vuetify.theme.themes.light.primary = '#fff3f2'
                        this.$vuetify.theme.themes.light.secondary = '#871913'
                        this.$vuetify.theme.themes.light.accent = '#F58634'
                        this.$vuetify.theme.themes.light.anchor = '#871913'
                        this.$router.push('/personal/dashboard')
                        break
                    case 9:
                        this.$router.push('/consulta_personal/personal')
                        break
                }
            },

            newWindow(url) {
                window.open(url, '_blank', 'location=yes,height=720,width=1020,scrollbars=yes,status=yes')
            },
        },
    }
</script>
