import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoItem: [],
    },

    getters: {
        getTipoItem: state => {
            return state.tipoItem
        },
    },

    mutations: {
        SET_TIPO_ITEM: (state, tipoItem) => {
            state.tipoItem = tipoItem
        },
    },

    actions: {
        fetchTipoItem: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoItem')
                .then(response => {
                    commit('SET_TIPO_ITEM', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
