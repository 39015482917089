<template>
    <v-container v-if="preaspirante">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Validar perfil de preaspirante</v-card-title>
                    <v-card-text>
                        <div class="text-body-1" align="center">
                            ¿Desea validar el perfil del preaspirante {{ getFullName(preaspirante) }}? Al realizar este paso, pasará a formar parte de la
                            lista de enlace C3.
                        </div>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <div class="text-subtitle-2">Requisitos</div>
                                    <v-list color="primary">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Dictamen realizado en Personal/Site </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="dictamenPersonal.length > 0">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title> Evaluación médica aprobada </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="evaluacionMedicaAprobada > 0">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-subtitle-2">Lista de documentos cargados</div>
                                    <v-list color="primary">
                                        <v-list-item v-for="(documento, index) in documentos" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ documento.TipoDocumento.title }} </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="dictamenPersonal.length > 0">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-btn
                                        color="secondary"
                                        :to="{
                                            name: 'documentosPreaspirante',
                                            params: { id: preaspirante.idPersonal },
                                        }"
                                        >Ver expediente de preaspirante</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn v-bind="secondaryButtonProps" to="/profesionalizacion/preaspirantes">Cancelar</v-btn>
                        <v-btn v-bind="primaryButtonProps" v-on:click="validaCandidato()" :loading="loading" :disabled="loading">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { primaryButtonProps, secondaryButtonProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.fetchEvaluacionMedicaByPersonal(this.id)
            await this.fetchDictamenPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
        },

        data() {
            return {
                primaryButtonProps,
                secondaryButtonProps,

                loading: false,
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),

            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicas: 'getEvaluacionMedica',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenPersonal: 'getDictamenPersonal',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            evaluacionMedicaAprobada: function() {
                var aprobadas = []

                aprobadas = this.evaluacionesMedicas.filter(evaluacion => {
                    return evaluacion.resultado == true
                })

                return aprobadas.length
            },

            valid: function() {
                if (this.evaluacionMedicaAprobada > 0 && this.dictamenPersonal.length > 0) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapActions('personal', ['updateEstatusPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes', 'fetchAspirantes']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal']),
            ...mapActions('documento', ['fetchDocumentoByPersonal', 'updateEstatusDocumentoPersonal']),

            validaCandidato: async function() {
                this.loading = true
                this.documentos.forEach(documento => {
                    this.updateEstatusDocumentoPersonal(documento)
                })

                await this.updateEstatusPersonal({
                    idPersonal: this.id,
                    fk_idEstatus: 5,
                })

                await this.fetchPreaspirantes()
                await this.fetchAspirantes()
                this.$router.push('/profesionalizacion/preaspirantes')
                this.loading = false
            },
        },
    }
</script>
