import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        procedencia: [],
    },

    getters: {
        getProcedencia: state => {
            return state.procedencia
        },
    },

    mutations: {
        SET_PROCEDENCIA: (state, procedencia) => {
            state.procedencia = procedencia
        },
    },

    actions: {
        fetchProcedencia: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/procedencias')
                .then(response => {
                    commit('SET_PROCEDENCIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
