import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        criterioEvaluacion: [],
    },

    getters: {
        getCriterioEvaluacion: state => {
            return state.criterioEvaluacion
        },
    },

    mutations: {
        SET_CRITERIO_EVALUACION(state, criterioEvaluacion) {
            state.criterioEvaluacion = criterioEvaluacion
        },
    },

    actions: {
        fetchCriterioEvaluacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/criterioEvaluacion')
                .then(response => {
                    commit('SET_CRITERIO_EVALUACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
