import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        regionSeniaParticular: [],
    },

    getters: {
        getRegionSeniaParticular: state => {
            return state.regionSeniaParticular
        },
    },

    mutations: {
        SET_REGION_SENIA_PARTICULAR(state, regionSeniaParticular) {
            state.regionSeniaParticular = regionSeniaParticular
        },
    },

    actions: {
        fetchRegionSeniaParticular: async function({ commit }) {
            await axios
                .get('/EonCloud/common/regionSeniaParticular')
                .then(response => {
                    commit('SET_REGION_SENIA_PARTICULAR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
