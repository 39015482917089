<template>
    <v-card class="card" color="primary">
        <v-card-title class="font-weight-bold justify-center">Nueva referencia</v-card-title>
        <v-card-text>
            <v-form v-model="referenciaForm" ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="referencia.nombre"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                :rules="nameRules"
                                validate-on-blur
                            >
                                <template v-slot:label>Nombre completo <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="maskedPhone"
                                v-mask="'###-###-##-##'"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                label="Teléfono"
                                maxlength="13"
                                :rules="nameRules"
                            >
                                <template v-slot:label>Teléfono <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model.number="referencia.tiempoConocerse"
                                type="number"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                :rules="nameRules"
                                min="0"
                                validate-on-blur
                            >
                                <template v-slot:label>Años de conocerse <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="referencia.fk_idCatTipoReferencia"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                :rules="nameRules"
                                :items="tipoReferencia"
                                item-value="id"
                                item-text="title"
                                validate-on-blur
                                item-color="secondary"
                            >
                                <template v-slot:label>Tipo de referencia <span class="red--text">*</span> </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="referencia.fk_idCatOcupacion"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                :rules="nameRules"
                                :items="ocupaciones"
                                item-value="id"
                                item-text="title"
                                item-color="secondary"
                            >
                                <template v-slot:label>Ocupación <span class="red--text">*</span> </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="referencia.Direccion.fk_idMunicipio"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                label="Municipio donde vive"
                                :items="municipios"
                                item-value="id"
                                item-text="nombre"
                                item-color="secondary"
                            >
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="referencia.Direccion.calle"
                                rounded
                                filled
                                dense
                                label="Calle"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="referencia.Direccion.entrecalles"
                                rounded
                                filled
                                dense
                                label="Entre calle"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="referencia.Direccion.entrecalles2"
                                rounded
                                filled
                                dense
                                label="Entre calle 2"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="referencia.Direccion.ext"
                                rounded
                                filled
                                dense
                                label="Número exterior"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="referencia.Direccion.int"
                                rounded
                                filled
                                dense
                                label="Número interior"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="referencia.Direccion.colonia"
                                rounded
                                filled
                                dense
                                label="Colonia"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="referencia.Direccion.cp"
                                rounded
                                filled
                                dense
                                label="Código postal"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="referencia.Direccion.referencias"
                                rounded
                                filled
                                dense
                                label="Referencias de la dirección"
                                background-color="primary"
                                color="secondary"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn color="error" text class="px-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
            <v-btn color="secondary" class="px-8" :disabled="!referenciaForm || loading" :loading="loading" v-on:click="triggerSaveReferencia()"
                >Guardar</v-btn
            >
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        async mounted() {
            await this.fetchOcupaciones()
            await this.fetchTipoReferencia()
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                referenciaForm: false,
                loading: false,

                referencia: {
                    idPersonal: this.id,
                    nombre: '',
                    telefono: '',
                    tiempoConocerse: 0,
                    fk_idCatTipoReferencia: null,
                    fk_idCatOcupacion: null,
                    vigente: true,
                    Direccion: {
                        fk_idMunicipio: null,
                        calle: '',
                        colonia: '',
                        localidad: '',
                        ext: '',
                        int: '',
                        entrecalles: '',
                        entrecalles2: '',
                        referencias: '',
                        lat: '0',
                        lgt: '0',
                        cp: '',
                    },
                },

                maskedPhone: '',

                nameRules: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('tipoReferencia', {
                tipoReferencia: 'getTipoReferencia',
            }),
            ...mapGetters('ocupaciones', {
                ocupaciones: 'getOcupaciones',
            }),
            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),
        },

        methods: {
            ...mapActions('tipoReferencia', ['fetchTipoReferencia']),
            ...mapActions('ocupaciones', ['fetchOcupaciones']),
            ...mapActions('referencias', ['saveReferencia', 'fetchReferenciasByPersonal']),

            triggerSaveReferencia: async function() {
                this.loading = true

                this.referencia.telefono = this.maskedPhone.replace(/-/g, '')
                await this.saveReferencia(this.referencia)

                this.resetForm()
                this.fetchReferenciasByPersonal(this.id)
                this.loading = false
                // this.$router.go(-1)
            },

            resetForm() {
                this.referencia = {
                    idPersonal: this.id,
                    nombre: '',
                    telefono: '',
                    tiempoConocerse: 0,
                    fk_idCatTipoReferencia: null,
                    fk_idCatOcupacion: null,
                    vigente: true,
                    Direccion: {
                        fk_idMunicipio: null,
                        calle: '',
                        colonia: '',
                        localidad: '',
                        ext: '',
                        int: '',
                        entrecalles: '',
                        entrecalles2: '',
                        referencias: '',
                        lat: '0',
                        lgt: '0',
                        cp: '',
                    },
                }
                this.maskedPhone = ''
                this.$refs.form.resetValidation()
            },
        },
    }
</script>
