<template>
    <v-card class="card" color="primary" height="100%">
        <v-card-title class="font-weight-bold justify-center">Línea de tiempo de elemento</v-card-title>
        <v-card-text>
            <v-timeline v-if="permutaciones.length > 0">
                <v-virtual-scroll :items="permutaciones" item-height="240" height="670">
                    <template v-slot:default="{ item }">
                        <v-timeline-item :key="item.idPermutacion" :left="item.idPermutacion % 2 == 0">
                            <template v-slot:opposite>
                                <span class="font-weight-black secondary--text">{{ item.fecha }}</span>
                            </template>
                            <v-card class="secondary" color="primary">
                                <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold">{{
                                    item.TipoPermutacion.title
                                }}</v-card-title>
                                <v-card-text>
                                    <div class="text-body-2 primary--text">
                                        {{ item.descripcion }}
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </template>
                </v-virtual-scroll>
            </v-timeline>
            <template v-else>
                <v-container>
                    <v-row>
                        <v-col cols="12" align="center">
                            <div class="text-h6">Este elemento no cuenta con cambios de puesto ni adscripción registrados.</div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            permutaciones: {
                type: Array,
            },
        },
    }
</script>
