<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Catálogo de promociones</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="end" align="center">
                                <v-col cols="2">
                                    <registro-promocion-dialog-component></registro-promocion-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaPromocion"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar promocion"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-data-table class="primary" :headers="headers" :items="promociones" :search="buscaPromocion" calculate-widths>
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn icon color="secondary" @click="openEditarPromocionDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <editar-promocion-dialog-component
            :active="editPromocionDialog"
            :promocion="selectedPromocion"
            @close="editPromocionDialog = false"
        ></editar-promocion-dialog-component>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    import AsignaPersonalDialogComponent from '../../components/desarrollo_policial/promociones/AsignaPersonalDialogComponent.vue'
    import EditarPromocionDialogComponent from '../../components/desarrollo_policial/promociones/EditarPromocionDialogComponent.vue'
    import RegistroPromocionDialogComponent from '../../components/desarrollo_policial/promociones/RegistroPromocionDialogComponent.vue'

    export default {
        components: {
            RegistroPromocionDialogComponent,
            EditarPromocionDialogComponent,
            AsignaPersonalDialogComponent,
        },

        data() {
            return {
                buscaPromocion: '',

                headers: [
                    { text: 'Puesto', value: 'puesto', width: '10%' },
                    { text: 'Descripción', value: 'descripcion', width: '20%' },
                    { text: 'Requisitos', value: 'requisitos', width: '15%' },
                    { text: 'Funciones', value: 'funciones', width: '10%' },
                    { text: 'Responsabilidades', value: 'responsabilidades', width: '15%' },
                    { text: 'Sueldo', value: 'sueldo', width: '10%' },
                    { text: 'Fecha de publicación', value: 'fechaPublicacion', width: '10%' },
                    { text: 'Opciones', value: 'opciones', width: '15%' },
                ],

                editPromocionDialog: false,
                asignaPersonalDialog: false,
                selectedPromocion: null,
            }
        },

        computed: {
            ...mapGetters('promociones', {
                promociones: 'getPromociones',
            }),
        },

        methods: {
            openEditarPromocionDialog(promocion) {
                this.selectedPromocion = promocion
                this.editPromocionDialog = true
            },

            openAsignaPersonalDialog(promocion) {
                this.selectedPromocion = promocion
                this.asignaPersonalDialog = true
            },
        },
    }
</script>
