<template>
    <v-container v-if="personal">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Resultados C3</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <div class="text-subtitle-1 font-weight-bold">Vigencia de evaluaciones de C3 <span class="red--text">*</span></div>
                                    <v-date-picker v-model="fechaElegida" locale="es" color="secondary" no-title full-width :rules="required"></v-date-picker>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        v-model="resultadoElegido"
                                        dense
                                        filled
                                        rounded
                                        color="secondary"
                                        background-color="primary"
                                        :items="resultados"
                                        item-value="title"
                                        item-text="title"
                                        item-color="secondary"
                                        :rules="required"
                                    >
                                        <template v-slot:label>Resultado <span class="red--text">*</span> </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn
                            color="secondary"
                            class="primary--text mb-4 mx-8"
                            :loading="loading"
                            :disabled="!resultadoElegido || !fechaElegida || loading"
                            v-on:click="triggerAsignacionTurno()"
                            >Guardar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        data() {
            return {
                loading: false,
                fechaElegida: null,
                resultadoElegido: null,

                resultados: [
                    { id: 1, title: 'Aprobado' },
                    { id: 2, title: 'No aprobado' },
                    { id: 3, title: 'No cubre perfil' },
                    { id: 3, title: 'Sí cubre perfil' },
                ],

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        methods: {
            ...mapActions('personal', ['updatePersonal', 'updatePuestoPersonal', 'fetchPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            triggerAsignacionTurno: async function() {
                this.loading = true
                this.personal.observacionesC3 = this.resultadoElegido
                this.personal.examenC3Mes = moment(this.fechaElegida).format('MMMM')
                this.personal.vigenciaAnio = parseInt(moment(this.fechaElegida).year())
                await this.updatePersonal(this.personal)

                this.loading = false
                this.$router.push('/enlace_c3/personal')
            },
        },
    }
</script>
