import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        locs: [],
        loc: null,
    },

    getters: {
        getLocs: state => {
            return state.locs
        },

        getLocToUpdate: state => {
            return state.loc
        },
    },

    mutations: {
        SET_LOCS(state, locs) {
            state.locs = locs
        },

        SET_LOC_TO_UPDATE(state, idPersonal) {
            var preaspiranteParaValidacion = state.locs.filter(preaspirante => {
                return preaspirante.id == idPersonal
            })

            var preaspirante = _.cloneDeep(preaspiranteParaValidacion[0])

            state.loc = preaspirante
        },
    },

    actions: {
        fetchLocs: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/loc')
                .then(response => {
                    commit('SET_LOCS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveLoc: async function(context, loc) {
            try {
                await axios.post('/PoliciaCuadrantes/Armeria/loc/', loc.loc).then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Licencia Oficial Colectiva registrada correctamente.', { root: true })
                })
            } catch (error) {
                console.log(error)
            }
        },

        updateLoc: async function({ commit }, loc) {
            try {
                await axios.put('/PoliciaCuadrantes/Armeria/personal/' + loc.idPersonal + '/loc/', loc)
            } catch (error) {
                console.log(error)
            }
        },

        saveArmaLoc: async function({ commit }, armaLoc) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/armas/' + armaLoc.arma + '/armasLoc/', {
                    fk_idLoc: armaLoc.loc,
                })
                .then(response => console.log(response))
                .catch(error => console.log(error))
        },
    },
}
