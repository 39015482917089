<template>
    <v-main style="height:100vh;overflow-y:hidden;">
        <div id="particles-js"></div>
        <v-navigation-drawer fixed right width="480" class="px-16" color="primary">
            <kinesis-container>
                <v-container>
                    <kinesis-element :strength="15" type="depth">
                        <v-row style="height:80vh" align="center">
                            <v-col cols="12">
                                <v-form v-model="loginForm">
                                    <v-card class="card" color="secondary">
                                        <v-card-title class="d-block" align="center">
                                            <img src="@/assets/img/policia.png" width="140" class="logo mb-8" />
                                            <div class="font-weight-bold text-h6 justify-center primary--text">
                                                Inicio de sesión
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-form v-model="loginForm">
                                                <v-text-field
                                                    v-model="name"
                                                    rounded
                                                    filled
                                                    dense
                                                    label="Usuario"
                                                    background-color="primary"
                                                    color="secondary"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="password"
                                                    rounded
                                                    filled
                                                    dense
                                                    label="Contraseña"
                                                    type="password"
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="passwordRules"
                                                ></v-text-field>
                                            </v-form>
                                        </v-card-text>
                                        <v-card-actions class="justify-center">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="8" offset-md="2" align="center">
                                                        <v-btn
                                                            color="primary"
                                                            class="mb-4 secondary--text"
                                                            v-on:click="triggerLogin()"
                                                            block
                                                            :disabled="!loginForm || loading"
                                                            >Iniciar sesión</v-btn
                                                        >
                                                        <a class="text-decoration-none secondary--text text-subtitle-2">¿Ha olvidado su contraseña?</a>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-form>
                            </v-col>
                        </v-row>
                    </kinesis-element>
                </v-container>
            </kinesis-container>
            <template v-slot:append>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-btn text to="/">
                                <v-icon color="secondary" small>mdi-arrow-left</v-icon>
                                Volver al portal
                            </v-btn>
                        </v-col>
                        <v-col cols="12" align="center">
                            <kinesis-container>
                                <kinesis-element :strength="15" type="depth">
                                    <v-img src="@/assets/img/eon.png" max-width="240"></v-img>
                                </kinesis-element>
                            </kinesis-container>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-navigation-drawer>
        <div class="white--text armeria font-weight-black">ESTADO DE FUERZA</div>
        <login-perfil-dialog-component :active="activeModal" @close="activeModal = false"></login-perfil-dialog-component>
    </v-main>
</template>
<style scoped>
    #particles-js {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--v-secondary-base);
    }
    img {
        -webkit-filter: drop-shadow(5px 5px 5px #666666);
        filter: drop-shadow(5px 5px 5px #666666);
    }
    .armeria {
        position: fixed;
        bottom: 0;
        left: 48px;
        font-size: 96px;
        opacity: 0.78;
        text-shadow: 4px 4px 8px black;
    }
    .card {
        -webkit-box-shadow: 0px 48px 24px -32px rgba(0, 0, 60, 0.61) !important;
        box-shadow: -1px 50px 48px -30px rgba(0, 0, 60, 0.61) !important;
    }
</style>
<script>
    import 'particles.js'
    import { textAnimation } from '../plugins/anime'
    import sha1 from 'sha1'
    import { mapMutations, mapActions, mapGetters } from 'vuex'
    import LoginPerfilDialogComponent from '../components/LoginPerfilDialogComponent.vue'

    export default {
        components: {
            LoginPerfilDialogComponent,
        },

        mounted() {
            this.initParticles()
            textAnimation()

            // if (this.token.length > 0) {
            //     this.$router.replace('launcher')
            // }
        },

        data() {
            return {
                loginForm: false,
                loading: false,
                name: '',
                password: '',
                emailRules: [v => !!v || 'Este campo es requerido.', v => /.+@.+\..+/.test(v) || 'Ingrese un correo electrónico válido.'],
                passwordRules: [v => !!v || 'Este campo es requerido.'],

                activeModal: false,
            }
        },

        computed: {
            ...mapGetters({
                token: 'getToken',
                perfil: 'getPerfil',
                idPersonal: 'getIdPersonal',
            }),
        },

        watch: {
            async token(newToken, oldToken) {
                if (newToken.length > 0) {
                    console.log(typeof this.perfil)
                    if (typeof this.perfil === 'string') {
                        switch (this.perfil) {
                            case 'Armeria':
                                // this.DISABLE_LOADER()
                                // this.$router.push('/armeria/dashboard')
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'Equipamiento':
                                // this.DISABLE_LOADER()
                                window.location.replace('equipamiento/personal')
                                break
                            case 'Profesionalización':
                                // this.DISABLE_LOADER()
                                // this.$router.replace('/profesionalizacion/dashboard')
                                window.location.replace('profesionalizacion/dashboard')
                                break
                            case 'Desarrollo Profesional':
                                // this.DISABLE_LOADER()
                                // this.$router.push('/desarrollo_profesional/dashboard')
                                window.location.replace('desarrollo_profesional/personal')
                                break
                            case 'Personal/Site':
                                // this.DISABLE_LOADER()
                                // this.$router.push('/personal/dashboard')
                                window.location.replace('personal/dashboard')
                                break
                            case 'Recursos Humanos':
                                // this.DISABLE_LOADER()
                                // this.$router.push('/recursos_humanos/dashboard')
                                window.location.replace('recursos_humanos/dashboard')
                                break
                            case 'Enlace C3':
                                window.location.replace('enlace_c3/dashboard')
                                break
                            case 'ADMINISTRADOR':
                                window.location.replace('launcher')
                                break
                            case 'Administrador IPH+':
                                window.location.replace('launcher')
                                break
                            case 'Asuntos Internos':
                                window.location.replace('asuntos_internos/personal')
                                break
                            case 'Consejo de Honor y Justicia':
                                window.location.replace('asuntos_internos/personal')
                                break
                            case 'Policia':
                                window.location.replace('policia/' + this.idPersonal)
                                break

                            // * Nuevos perfiles
                            case 'IFCSP_PROFESIONALIZACION':
                                window.location.replace('profesionalizacion/dashboard')
                                break
                            case 'IFCSP_RECLUTAMIENTO':
                                window.location.replace('profesionalizacion/dashboard')
                                break
                            case 'IFCSP_ADMINISTRADOR':
                                window.location.replace('profesionalizacion/dashboard')
                                break
                            case 'REP_ADMINISTRADOR':
                                window.location.replace('personal/dashboard')
                                break
                            case 'REP_REGISTRO':
                                window.location.replace('personal/dashboard')
                                break
                            case 'REP_CREDENCIALIZACION':
                                window.location.replace('personal/dashboard')
                                break
                            case 'REP_BIOMETRIA':
                                window.location.replace('personal/dashboard')
                                break
                            case 'ENLACE C3_CAPTURA DE RESULTADOS':
                                window.location.replace('enlace_c3/dashboard')
                                break
                            case 'ENLACE C3_CAPTURA DE EXPEDIENTE':
                                window.location.replace('enlace_c3/dashboard')
                                break
                            case 'ENLACE C3_ADMINISTRADOR':
                                window.location.replace('enlace_c3/dashboard')
                                break
                            case 'RH_ADMINISTRADOR':
                                window.location.replace('recursos_humanos/dashboard')
                                break
                            case 'RH_TRAMITES ADMINISTRATIVOS':
                                window.location.replace('recursos_humanos/dashboard')
                                break
                            case 'RH_CAMBIO DE ESTATUS/ADSCRIPCION':
                                window.location.replace('recursos_humanos/dashboard')
                                break
                            case 'RH_REGISTRO DE PERSONAL':
                                window.location.replace('recursos_humanos/dashboard')
                                break
                            case 'ARMERIA_PASE DE LISTA':
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'ARMERIA_LOC':
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'ARMERIA_PERSONAL LOC':
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'ARMERIA_CATALOGOS E INVENTARIO ARMAS':
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS':
                                window.location.replace('armeria/inventario_armas')
                                break
                            case 'EQUIPAMIENTO_ADMINISTRADOR':
                                window.location.replace('equipamiento/personal')
                                break
                            case 'DP_ADMINISTRADOR':
                                window.location.replace('desarrollo_profesional/personal')
                                break
                            case 'DP_CONTROL DE EVALUACIONES':
                                window.location.replace('desarrollo_profesional/personal')
                                break
                            case 'DP_CONDECORACIONES Y CONVOCATORIAS':
                                window.location.replace('desarrollo_profesional/personal')
                                break
                        }
                    } else {
                        this.activeModal = true
                    }
                }
            },
        },

        methods: {
            ...mapActions(['login']),

            ...mapMutations(['SET_SNACKBAR_MESSAGE', 'SET_AUTH', 'DISABLE_LOADER', 'ENABLE_LOADER']),
            ...mapActions('personal', ['fetchPersonal']),

            ...mapActions('capacitador', ['fetchCapacitadores']),
            ...mapActions('aula', ['fetchAulas']),
            ...mapActions('curso', ['fetchCursos']),
            ...mapActions('cursoProgramado', ['fetchCursosProgramados']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignados']),
            ...mapActions('categoriaCurso', ['fetchCategoriasCursos']),
            ...mapActions('estatusCurso', ['fetchEstatusCurso']),
            ...mapActions('estatusCursoPersonal', ['fetchEstatusCursoPersonal']),
            ...mapActions('estatusAula', ['fetchClassroomStatus']),
            ...mapActions('tipoCurso', ['fetchCourseTypes']),

            ...mapActions('estatusEstudios', ['fetchEstatusEstudios']),
            ...mapActions('calibre', ['fetchCalibres']),
            ...mapActions('tipoArma', ['fetchTiposArma']),
            ...mapActions('marcaArma', ['fetchMarcaArma']),
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('estatusArma', ['fetchEstatusArma']),
            ...mapActions('arma', ['fetchArmas']),
            ...mapActions('tipoAsignacionArma', ['fetchTipoAsignacion']),
            ...mapActions('tipoEntregaArma', ['fetchTiposEntrega']),
            ...mapActions('licenciaOficialColectiva', ['fetchLocs']),

            ...mapActions('personal', ['fetchPersonal']),

            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios']),
            ...mapActions('principio', ['fetchPrincipios']),
            ...mapActions('reactivo', ['fetchReactivos']),
            ...mapActions('frecuenciaRespetoPrincipio', ['fetchFrecuenciaRespetoPrincipio']),
            ...mapActions('periodicidad', ['fetchPeriodicidad']),
            ...mapActions('resultado', ['fetchResultados']),
            ...mapActions('productividad', ['fetchProductividad']),
            ...mapActions('funcion', ['fetchFunciones']),
            ...mapActions('indicador', ['fetchIndicadores']),
            ...mapActions('frecuenciaProductividad', ['fetchFrecuenciaProductividad']),
            ...mapActions('actoRelevante', ['fetchActosRelevantes']),

            ...mapActions('tipoItem', ['fetchTiposItem']),

            ...mapActions('item', ['fetchItemsByType']),

            ...mapActions('preaspirante', ['fetchPreaspirantes']),

            ...mapActions('candidato', ['fetchCandidatos']),
            ...mapActions('categoria', ['fetchCategorias']),

            ...mapActions('sede', ['fetchSedes']),
            ...mapActions('subsede', ['fetchSubsedes']),
            ...mapActions('puesto', ['fetchPuestos']),
            ...mapActions('dependencia', ['fetchDependencias']),

            ...mapActions('estudio', ['fetchTipoEstudios']),

            ...mapActions('perfil', ['fetchPerfiles']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('sexo', ['fetchSexo']),

            ...mapActions('estatusPersonal', ['fetchEstatusPersonal']),
            ...mapActions('tipoDocumento', ['fetchTipoDocumento']),

            ...mapActions('preaspirante', ['fetchAspirantes']),

            ...mapActions('rama', ['fetchRamas']),
            ...mapActions('region', ['fetchRegiones']),
            ...mapMutations(['ENABLE_LOADER', 'DISABLE_LOADER']),

            initParticles() {
                window.particlesJS('particles-js', {
                    particles: {
                        number: {
                            value: 80,
                            density: {
                                enable: true,
                                value_area: 800,
                            },
                        },
                        color: {
                            value: '#ffffff',
                        },
                        shape: {
                            type: 'circle',
                            stroke: {
                                width: 0,
                                color: '#000000',
                            },
                            polygon: {
                                nb_sides: 5,
                            },
                            image: {
                                src: 'img/github.svg',
                                width: 100,
                                height: 100,
                            },
                        },
                        opacity: {
                            value: 0.5,
                            random: false,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false,
                            },
                        },
                        size: {
                            value: 3,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 40,
                                size_min: 0.1,
                                sync: false,
                            },
                        },
                        line_linked: {
                            enable: true,
                            distance: 150,
                            color: '#ffffff',
                            opacity: 0.4,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 6,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200,
                            },
                        },
                    },
                    interactivity: {
                        detect_on: 'canvas',
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'repulse',
                            },
                            onclick: {
                                enable: true,
                                mode: 'push',
                            },
                            resize: true,
                        },
                        modes: {
                            grab: {
                                distance: 400,
                                line_linked: {
                                    opacity: 1,
                                },
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                            push: {
                                particles_nb: 4,
                            },
                            remove: {
                                particles_nb: 2,
                            },
                        },
                    },
                    retina_detect: true,
                })
            },

            triggerLogin: async function() {
                this.loading = true
                await this.login({
                    loginField: this.name,
                    passwordField: sha1(this.password).toUpperCase(),
                })

                this.loading = false
            },
        },
    }
</script>
