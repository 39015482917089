<template>
    <v-dialog v-model="active" width="360" persistent>
        <v-card color="primary" class="rounded-sm" v-if="cargador">
            <v-card-title>
                <div class="text-h6">Editar número económico</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    label="Número económico"
                    v-model.number="cargador.noEconomico"
                    type="number"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                ></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="triggerUpdateCargador()" color="secondary" rounded :loading="loading" :disabled="loading">Guardar cambios</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            cargador: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            ...mapActions('cargadores', ['updateCargador']),

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },

            async triggerUpdateCargador() {
                this.loading = true
                await this.updateCargador(this.cargador)
                this.closeDialog()
            },
        },
    }
</script>
