<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Familiares</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row v-if="familiares.length > 0">
                    <v-col cols="4" v-for="familiar in familiares" :key="familiar.id">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(familiar) }}</div>
                                <div class="text-subtitle-2">{{ familiar.CatParentesco.title }}</div>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <div class="text-subtitle-2">
                                                Fecha de nacimiento
                                            </div>
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            <div class="text-subtitle-2 font-weight-bold">
                                                {{ familiar.fechaNacimiento }}
                                            </div>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="text-subtitle-2">
                                                Edad
                                            </div>
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            <div class="text-subtitle-2 font-weight-bold">{{ getAge(familiar.fechaNacimiento) }}</div>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="text-subtitle-2">
                                                Sexo
                                            </div>
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            <div class="text-subtitle-2 font-weight-bold">{{ familiar.CatSexo.title }}</div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" align="center">
                        <v-icon color="primary darken-3" size="256">mdi-account-multiple</v-icon>
                        <div class="text-h6">Este elemento no cuenta con familaires registrados</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center">
                        <v-btn color="secondary" :to="{ name: getRouteName, params: { id: personal.idPersonal } }">Agregar familiar</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            personal: {
                type: Object,
            },

            familiares: {
                type: Array,
            },
        },

        computed: {
            getRouteName: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return 'agregaReferencia'
                } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    {
                        return 'agregaReferenciaSite'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    {
                        return 'recursos_humanos.agrega_familiar'
                    }
                }
            },
        },
    }
</script>
