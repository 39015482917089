import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoReferencia: [],
    },

    getters: {
        getTipoReferencia: state => {
            return state.tipoReferencia
        },
    },

    mutations: {
        SET_TIPO_REFERENCIA(state, tipoReferencia) {
            state.tipoReferencia = tipoReferencia
        },
    },

    actions: {
        fetchTipoReferencia: async function({ commit }) {
            await axios
                .get('/EonCloud/EstadoFuerza/tipoReferecias')
                .then(response => {
                    commit('SET_TIPO_REFERENCIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
