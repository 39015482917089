<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset="4">
                <agrega-referencia-form-component :id="id"></agrega-referencia-form-component>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import AgregaReferenciaFormComponent from '../../components/AgregaReferenciaFormComponent.vue'

    export default {
        components: {
            AgregaReferenciaFormComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },
    }
</script>
