import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        promociones: [],
    },

    getters: {
        getPromociones: state => {
            return state.promociones
        },
    },

    mutations: {
        SET_PROMOCIONES(state, promociones) {
            state.promociones = promociones
        },
    },

    actions: {
        fetchPromociones: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/promocion')
                .then(response => {
                    commit('SET_PROMOCIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        savePromocion: async function(context, promocion) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/promocion', promocion)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Promocion registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updatePromocion: async function(context, promocion) {
            await axios
                .put('/PoliciaCuadrantes/DesarrolloPolicial/promocion/' + promocion.idPromocion, promocion)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Promocion actualizada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
