<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="card" color="primary">
            <v-card-title>
                <div class="text-h6">Turnar aspirante</div>
            </v-card-title>
            <v-card-text>
                <v-radio-group v-model="opcionAspirante">
                    <v-radio value="1" label="Asignar como cadete" color="secondary"></v-radio>
                    <v-radio value="2" label="Descartar aspirante" color="error"></v-radio>
                </v-radio-group>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="error" @click="closeDialog()">Cancelar</v-btn>
                <v-btn color="secondary" @click="triggerActualizaAspirante" :loading="loading" :disabled="loading">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            aspirante: {
                type: Object,
            },
        },

        data() {
            return {
                opcionAspirante: '1',
                loading: false,
            }
        },

        methods: {
            ...mapActions('personal', ['updateEstatusPersonal', 'updatePuestoPersonal']),
            ...mapActions('preaspirante', ['fetchAspirantes']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
            },

            async triggerActualizaAspirante() {
                this.loading = true

                if (this.opcionAspirante == '1') {
                    // *Se actualiza su puesto inicial a BECADO
                    await this.updatePuestoPersonal({
                        idPersonal: this.aspirante.idPersonal,
                        fk_idPuestoDependencia: 28,
                    })
                    await this.updateEstatusPersonal({
                        idPersonal: this.aspirante.idPersonal,
                        fk_idEstatus: 1,
                    })
                } else {
                    await this.updateEstatusPersonal({
                        idPersonal: this.aspirante.idPersonal,
                        fk_idEstatus: 6,
                    })
                }

                await this.fetchAspirantes()

                this.closeDialog()
            },
        },
    }
</script>
