<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Faltas administrativas</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row v-if="faltas.length > 0">
                    <v-col cols="4" v-for="falta in faltas" :key="falta.id">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-h6 font-weight-bold">{{ falta.CatTipoFalta.title }}</div>
                                <div class="text-subtitle-2">Registrada el {{ falta.fecha }}</div>
                            </v-card-title>
                            <v-card-text align="center">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center" v-if="falta.PersonalEmisor">
                                            <div class="text-subtitle-2">Falta levantada por</div>
                                            <div class="text-subtitle-2 font-weight-bold">
                                                {{ getFullName(falta.PersonalEmisor) }}
                                            </div>
                                            <div class="text-subtitle-2 font-weight-bold">
                                                {{ falta.PersonalEmisor.CUIP }}
                                            </div>
                                        </v-col>
                                        <template v-if="falta.fk_idTipoFalta == 1">
                                            <v-col cols="6" align="left">
                                                <div class="text-subtitle-2">Fecha de sanción</div>
                                            </v-col>
                                            <v-col cols="6" align="right">
                                                <div class="text-subtitle-2 font-weight-bold">
                                                    {{ falta.fechaDesde }}
                                                </div>
                                            </v-col>
                                        </template>
                                        <template v-else>
                                            <v-col cols="6" align="left">
                                                <div class="text-subtitle-2">Desde</div>
                                            </v-col>
                                            <v-col cols="6" align="right">
                                                <div class="text-subtitle-2 font-weight-bold">
                                                    {{ falta.fechaDesde }}
                                                </div>
                                            </v-col>
                                            <v-col cols="6" align="left">
                                                <div class="text-subtitle-2">Hasta</div>
                                            </v-col>
                                            <v-col cols="6" align="right">
                                                <div class="text-subtitle-2 font-weight-bold">
                                                    {{ falta.fechaHasta }}
                                                </div>
                                            </v-col>
                                        </template>
                                        <v-col cols="12" align="center">
                                            <div class="text-subtitle-2">Motivo</div>
                                            <div class="text-subtitle-2 font-weight-bold">
                                                {{ falta.motivo }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-img :src="falta.URL" contain v-if="getDocumentType(falta.URL) == 'img'">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0" align="center" justify="center">
                                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                                <embed
                                    v-else-if="getDocumentType(falta.URL) == 'pdf'"
                                    width="100%"
                                    class="pdf"
                                    name="plugin"
                                    :src="falta.URL + '#toolbar=0&navpanes=0&scrollbar=0'"
                                    type="application/pdf"
                                />
                            </v-card-text>
                            <v-card-actions class="justify-center">
                                <v-btn color="secondary" v-on:click="download(falta.URL)">Descargar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" align="center">
                        <div class="text-h6">Este elemento no cuenta con faltas administrativas</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            faltas: {
                type: Array,
            },
        },
    }
</script>
