import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        descripcionCriterio: [],
    },

    getters: {
        getDescripcionCriterio: state => {
            return state.descripcionCriterio
        },
    },

    mutations: {
        SET_DESCRIPCION_CRITERIO(state, descripcionCriterio) {
            state.descripcionCriterio = descripcionCriterio
        },
    },

    actions: {
        fetchDescripcionCriterio: async function({ commit }, idTipoEvaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/' + idTipoEvaluacion + '/descripcionCriterio')
                .then(response => {
                    commit('SET_DESCRIPCION_CRITERIO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
