<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="5">
                <agrega-documento-form-component :id="id"></agrega-documento-form-component>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import AgregaDocumentoFormComponent from '../../components/AgregaDocumentoFormComponent.vue'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            AgregaDocumentoFormComponent,
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchTipoDocumento()
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        methods: {
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapActions('tipoDocumento', ['fetchTipoDocumento']),
        },
    }
</script>
