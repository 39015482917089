import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        motivoUso: [],
    },

    getters: {
        getMotivoUso: state => {
            return state.motivoUso
        },
    },

    mutations: {
        SET_MOTIVO_USO: (state, motivoUso) => {
            state.motivoUso = motivoUso
        },
    },

    actions: {
        fetchMotivoUso: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/motivoUso')
                .then(response => {
                    commit('SET_MOTIVO_USO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
