<template>
    <v-container>
        <v-row>
            <v-col cols="7" align="right">
                <v-btn class="secondary card py-y mt-2 px-8" rounded elevation="8" to="/profesionalizacion/registro_capacitador"
                    >Nuevo capacitador <v-icon small class="ml-2">mdi-plus</v-icon></v-btn
                >
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="buscaCapacitador"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar capacitador por nombre..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row v-if="capacitadores.length > 0">
            <v-col cols="12" v-if="buscaCapacitador.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredCapacitadores.length }} resultados encontrados con la búsqueda "{{ buscaCapacitador }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoCapacitadores, index) in groupedCapacitadores" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoCapacitadores.inicial }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoCapacitadores.capacitadores.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="capacitador in grupoCapacitadores.capacitadores" :key="capacitador.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-cast-education</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ capacitador.apaterno }}</div>
                                                        <div class="text-subtitle-1 primary--text ">
                                                            {{ capacitador.amaterno + ' ' + capacitador.nombre + ' ' + capacitador.segundoNombre }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container> </v-container>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    :to="{ name: 'updateTrainer', params: { id: capacitador.id } }"
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Editar capacitador</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row v-else>
            <v-col cols="12" align="center">
                <div class="text-h4">No hay capacitadores registrados</div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                buscaCapacitador: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('capacitador', {
                capacitadores: 'getCapacitadores',
            }),

            filteredCapacitadores: function() {
                return this.capacitadores.filter(capacitador => {
                    return (
                        capacitador.nombre.toLowerCase().indexOf(this.buscaCapacitador.toLowerCase()) >= 0 ||
                        capacitador.segundoNombre.toLowerCase().indexOf(this.buscaCapacitador.toLowerCase()) >= 0 ||
                        capacitador.apaterno.toLowerCase().indexOf(this.buscaCapacitador.toLowerCase()) >= 0 ||
                        capacitador.amaterno.toLowerCase().indexOf(this.buscaCapacitador.toLowerCase()) >= 0
                    )
                })
            },

            groupedCapacitadores: function() {
                var result = _(this.filteredCapacitadores)
                    .groupBy(capacitador => capacitador.apaterno[0].toUpperCase())
                    .map((capacitadores, inicial) => ({ inicial, capacitadores }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.inicial < b.inicial) {
                            return -1
                        }
                        if (a.inicial > b.inicial) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.inicial > b.inicial) {
                            return -1
                        }
                        if (a.inicial < b.inicial) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },
    }
</script>
