<template>
    <v-container>
        <v-row>
            <v-col lg="4">
                <v-card class="card" color="secondary">
                    <v-card-title v-bind="cardTitleProps" class="black--text d-block" align="center">
                        <div class="black--text mb-4">Evaluación de productividad</div>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4">
                                    <v-avatar rounded size="80" color="primary">
                                        <v-icon size="48" color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="8" align="left">
                                    <div class="black--text text-subtitle-1">{{ getFullName(evaluacionRespetoPrincipios.Personal) }}</div>
                                    <div class="black--text text-caption text-secondary">{{ evaluacionRespetoPrincipios.Personal.CUIP }}</div>
                                    <div class="black--text text-caption text-secondary">
                                        {{ evaluacionRespetoPrincipios.Personal.CatPuestoDependencia.catPuesto.puesto }}
                                    </div>
                                    <div class="black--text text-caption text-secondary">Operativo</div>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-icon color="primary" class="mr-4">mdi-calendar</v-icon>
                                    <div class="text-overline black--text d-inline">{{ evaluacionRespetoPrincipios.fechaInicial }}</div>
                                    <div class="text-body-2 d-inline mx-4">-</div>
                                    <div class="text-overline black--text d-inline ">{{ evaluacionRespetoPrincipios.fechaFin }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-list color="secondary">
                            <v-list-item-group v-model="principioElegido">
                                <v-list-item :key="principio.id" v-for="principio in principios">
                                    <v-list-item-content>
                                        <v-list-item-title class="black--text">{{ principio.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="8" v-if="evaluacionRespetoPrincipios.frecuenciasMedicion.length > 0">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">Indicadores de productividad</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="reactivosForm">
                                <v-row>
                                    <v-col cols="12" v-for="reactivo in filterReactivosByPrincipio" :key="reactivo.id">
                                        <v-row>
                                            <v-col cols="8">
                                                <div class="text subtitle-1 black--text">{{ reactivo.reactivo }}</div>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    label="Frecuencia productividad"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    :items="frecuenciaRespetoPrincipios"
                                                    item-value="id"
                                                    item-text="title"
                                                    :value="getFrecuencia(reactivo.id)"
                                                    @input="setFrecuencia(reactivo.id, $event)"
                                                    :rules="required"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, textInputProps, circularProgressProps } from '../../components/props/props'
    import moment from 'moment'

    export default {
        async mounted() {
            this.setFrecuenciasMedicion()
        },

        props: {
            evaluacionRespetoPrincipios: {
                type: Object,
            },
        },

        data() {
            return {
                cardProps,
                cardTitleProps,
                textInputProps,
                circularProgressProps,
                cardActionsProps,
                primaryButtonProps,

                activeStep: 1,
                loading: false,
                principioElegido: 0,
                reactivosForm: false,

                fechaAplicacionForm: false,
                periodoEvaluacionForm: false,

                periodoEvaluacion: [null, null],

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('principio', {
                principios: 'getPrincipios',
            }),

            ...mapGetters('reactivo', {
                reactivos: 'getReactivos',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuenciaRespetoPrincipios: 'getFrecuenciaRespetoPrincipio',
            }),
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de aplicación'
                    case 2:
                        return 'Período de evaluación'
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 4) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            fechaInicio: function() {
                if (this.periodoEvaluacion[0]) {
                    if (moment(this.periodoEvaluacion[1]).isBefore(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.periodoEvaluacion[1]) {
                    if (moment(this.periodoEvaluacion[1]).isAfter(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            filterReactivosByPrincipio: function() {
                return this.reactivos.filter(reactivo => {
                    return reactivo.fk_idCatPrincipio == this.principioElegido + 1
                })
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.fechaAplicacionForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.periodoEvaluacionForm) {
                            return true
                        } else {
                            return false
                        }
                }
            },
        },

        methods: {
            ...mapActions('respetoPrincipios', ['saveRespetoPrincipios']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            getToday: function() {
                return moment().format('YYYY-MM-DD')
            },

            getFrecuencia: function(id_reactivo) {
                const index = this.evaluacionRespetoPrincipios.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idCatReactivo == id_reactivo)
                return this.evaluacionRespetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia
            },

            setFrecuencia: function(id_reactivo, e) {
                const index = this.evaluacionRespetoPrincipios.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idCatReactivo == id_reactivo)
                this.evaluacionRespetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia = e
            },

            setFrecuenciasMedicion: function() {
                this.reactivos.forEach(reactivo => {
                    this.evaluacionRespetoPrincipios.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idCatReactivo: reactivo.id,
                    })
                })
            },

            triggerSaveEvaluacionRespetoPrincipios: async function() {
                this.loading = true
                setTimeout(() => this.activeStep++, 2000)

                await this.saveRespetoPrincipios({
                    puntajeTotal: 100,
                    calificacion: '100',
                    observaciones: this.evaluacionRespetoPrincipios.observaciones,
                    fechaAplicacion: moment(this.evaluacionRespetoPrincipios.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fk_idPersonal: this.id,
                    fk_idResultado: 2,
                    fk_idPersonalEvaluador: 510,
                    fk_idPeriodicidad: 1,
                    frecuenciasMedicion: this.evaluacionRespetoPrincipios.frecuenciasMedicion,
                })

                this.$router.replace('/desarrollo_profesional/personal')
            },
        },
    }
</script>
