<template>
    <v-alert :type="getAlertType(vigenciaC3)" dense :color="vigenciaC3 === '' ? 'blue-grey' : ''">
        {{ getAlertText(vigenciaC3) }}
    </v-alert>
</template>
<script>
    import moment from 'moment'

    export default {
        props: {
            vigenciaC3: {
                type: String,
            },
        },

        methods: {
            getAlertType(fecha_vigencia) {
                if (fecha_vigencia !== '') {
                    return moment(fecha_vigencia, 'DD/MM/YYYY').diff(moment(), 'months', true) > 7 ? 'success' : 'error'
                } else return 'info'
            },

            getAlertText(fecha_vigencia) {
                if (fecha_vigencia !== '') {
                    return 'Evaluación C3: ' + this.getDateText(fecha_vigencia)
                } else return 'Evaluación C3: Fecha no registrada'
            },
        },
    }
</script>
