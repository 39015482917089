<template>
    <v-container>
        <v-row>
            <v-col cols="10" offset="1">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Solicitudes de cursos</v-card-title>
                    <v-card-text>
                        <v-list color="primary" v-if="sugerenciaCursos.length > 0">
                            <v-list-item v-for="sugerencia in sugerenciaCursos" :key="sugerencia.idSugerenciaCurso">
                                <v-list-item-avatar>
                                    <v-icon color="secondary">mdi-comment-quote</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="font-weight-bold">SUGERENCIA DE CURSO</v-list-item-subtitle>
                                    <v-list-item-title
                                        >Se sugiere el curso de {{ sugerencia.Curso.subcategoria }} al elemento {{ getFullName(sugerencia.Person) }} con CUIP
                                        {{ sugerencia.Person.CUIP }}</v-list-item-title
                                    >
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <div v-else class="text-h6 justify-center" align="center">No hay solicitudes de cursos a personal registradas</div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters('sugerenciaCursos', {
                sugerenciaCursos: 'getSugerenciaCursos',
            }),
        },
    }
</script>
