<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-card color="primary" class="card">
                    <v-card-title>Asignación de items a personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col>
                                    <v-menu
                                        v-model="menuFechaSolicitud"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="fechaSolicitud"
                                                append-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                rounded
                                                filled
                                                dense
                                                color="secondary"
                                                background-color="primary"
                                            >
                                                <template v-slot:label>Fecha de solicitud <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="asignacionEquipamiento.fechaSolicitud" @input="menuFechaSolicitud = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                        v-model="menuFechaAsignacion"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="fechaAsignacion"
                                                append-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                rounded
                                                filled
                                                dense
                                                color="secondary"
                                                background-color="primary"
                                            >
                                                <template v-slot:label>Fecha de asignación <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="asignacionEquipamiento.fechaAsignacion" @input="menuFechaAsignacion = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        label="Número de folio de resguardo"
                                        v-model="asignacionEquipamiento.folioResguardo"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-file-input
                                        label="Evidencia"
                                        v-model="asignacionEquipamiento.file"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-autocomplete
                                                v-model="selectedEquipo"
                                                label="Items"
                                                :items="filteredEquipos"
                                                item-text="CatItem.item"
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :clearable="false"
                                                multiple
                                                hide-selected
                                                return-object
                                            >
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold">
                                                            {{ item.CatItem.item }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            {{ item.numero }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle> Vigencia: {{ item.aniosVigencia }} </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-textarea
                                                label="Observaciones"
                                                v-model="asignacionEquipamiento.observaciones"
                                                rounded
                                                filled
                                                dense
                                                color="secondary"
                                                background-color="primary"
                                                no-resize
                                                rows="4"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headers" :items="selectedEquipo" class="transparent" hide-default-footer disable-pagination>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" @click="triggerSaveAsignacion()" :loading="loading" :disabled="loading">
                            Registrar asignación de items</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                loading: false,
                menuFechaSolicitud: false,
                menuFechaAsignacion: false,
                menuFechaFabricacion: [],
                menuAniosVigencia: [],

                selectedTipoItem: null,

                asignacionEquipamiento: {
                    fk_idPersonal: null,
                    fechaSolicitud: '',
                    fechaAsignacion: '',
                    observaciones: '',
                    evidencia: '',
                    folioResguardo: '',
                    equipoAsignado: [],
                    file: null,
                },

                headers: [
                    { text: 'Item', value: 'CatItem.item' },
                    { text: 'Número', value: 'numero' },
                    { text: 'Fecha de fabricación', value: 'fechaFabricacion' },
                    { text: 'Vigencia', value: 'aniosVigencia' },
                ],

                selectedEquipo: [],
            }
        },

        computed: {
            ...mapGetters('tipoItem', {
                tipoItem: 'getTiposItem',
            }),

            ...mapGetters('asignacionEquipo', {
                asignacionesEquipo: 'getAsignacionesEquipo',
            }),

            ...mapGetters('equipo', {
                equipos: 'getEquipos',
            }),

            filteredEquipos() {
                return this.equipos.filter(equipo => {
                    return equipo.fk_idCatEstatus == 2
                })
            },

            fechaSolicitud: function() {
                return this.asignacionEquipamiento.fechaSolicitud ? moment(this.asignacionEquipamiento.fechaSolicitud).format('DD/MM/YYYY') : ''
            },

            fechaAsignacion: function() {
                return this.asignacionEquipamiento.fechaAsignacion ? moment(this.asignacionEquipamiento.fechaAsignacion).format('DD/MM/YYYY') : ''
            },
        },

        methods: {
            ...mapActions('asignacionEquipo', ['saveAsignacionEquipo']),

            async triggerSaveAsignacion() {
                this.loading = true

                this.selectedEquipo.forEach(equipo => {
                    this.asignacionEquipamiento.equipoAsignado.push({
                        fk_idEquipo: equipo.idEquipamiento,
                    })
                })

                await this.saveAsignacionEquipo({
                    fk_idPersonal: this.id,
                    fechaSolicitud: this.fechaSolicitud,
                    fechaAsignacion: this.fechaAsignacion,
                    observaciones: this.asignacionEquipamiento.observaciones,
                    evidencia: this.asignacionEquipamiento.file ? this.asignacionEquipamiento.file.name : '',
                    folioResguardo: this.asignacionEquipamiento.folioResguardo,
                    equipoAsignado: this.asignacionEquipamiento.equipoAsignado,
                    file: this.asignacionEquipamiento.file,
                })

                this.loading = false
                this.$router.push('/equipamiento/personal')
            },
        },
    }
</script>
