<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="7">
                <v-text-field
                    v-model="buscaPersonal"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar personal por nombre, edad, municipio o estado..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaPersonal.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredPersonal.length }} resultados encontrados con la búsqueda "{{ buscaPersonal }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoPersonal, index) in groupedPersonal" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoPersonal.inicial }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoPersonal.personal.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="personal in grupoPersonal.personal" :key="personal.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80" v-if="personal.foto.length == 0">
                                                            <v-icon x-large color="secondary">mdi-account</v-icon>
                                                        </v-avatar>
                                                        <v-img v-else :src="personal.foto" max-height="80" max-width="80"></v-img>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 black--text font-weight-bold">{{ personal.apaterno }}</div>
                                                        <div class="text-subtitle-1 black--text ">
                                                            {{ personal.amaterno + ' ' + personal.nombre + ' ' + personal.segundoNombre }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-subtitle-1 black--text ">CUIP: {{ personal.CUIP }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-caption black--text">
                                                            Evaluación C3 vigente hasta
                                                        </div>
                                                        <div
                                                            v-if="personal.examenC3Mes.length > 0 && personal.vigenciaAnio != 0"
                                                            class="text-subtitle-1 black--text"
                                                        >
                                                            {{ personal.examenC3Mes }} del {{ personal.vigenciaAnio }}
                                                        </div>
                                                        <div v-else class="text-subtitle-1 black--text">
                                                            Información no registrada
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="right">
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="
                                                                perfil === 'ENLACE C3_ADMINISTRADOR' ||
                                                                    perfil === 'ENLACE C3_CAPTURA DE RESULTADOS' ||
                                                                    perfil === 'Enlace C3'
                                                            "
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    @click="openResultadoDialog(personal)"
                                                                    ><v-icon>mdi-account-details</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Registro de resultados C3</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{
                                                                        path: 'documentos_personal/' + personal.idPersonal,
                                                                        params: { id: personal.idPersonal },
                                                                    }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-file-account</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Ver expediente</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <!-- <validacion-asuntos-internos-dialog-component
            :active="asuntosInternosDialog"
            :id-personal="selectedPersonal"
            @close="asuntosInternosDialog = false"
        ></validacion-asuntos-internos-dialog-component> -->
        <registro-resultados-aspirante-dialog-component
            :active="resultadosDialog"
            :personal="selectedPersonal"
            @close="resultadosDialog = false"
        ></registro-resultados-aspirante-dialog-component>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import _ from 'lodash'
    import ValidacionAsuntosInternosDialogComponent from '../../components/enlace/ValidacionAsuntosInternosDialogComponent.vue'
    import MarcaActivoDialogComponent from '../../components/enlace/MarcaActivoDialogComponent.vue'
    import RegistroResultadosAspiranteDialogComponent from '../../components/enlace/RegistroResultadosAspiranteDialogComponent'

    export default {
        components: {
            ValidacionAsuntosInternosDialogComponent,
            MarcaActivoDialogComponent,
            RegistroResultadosAspiranteDialogComponent,
        },

        data() {
            return {
                buscaPersonal: '',
                ordenAlfabetico: 0,
                asuntosInternosDialog: false,

                selectedPersonal: null,
                marcaActivoDialog: false,

                resultadosDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters('preaspirante', {
                personal: 'getAspirantes',
            }),

            filteredPersonal: function() {
                return this.personal
                    .filter(personal => {
                        return (
                            this.getFullName(personal)
                                .toLowerCase()
                                .indexOf(this.buscaPersonal.toLowerCase()) >= 0 ||
                            personal.rfc.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0 ||
                            personal.curp.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0
                        )
                    })
                    .filter(personal => {
                        return personal.CUIP !== ''
                    })
            },

            groupedPersonal: function() {
                var result = _(this.filteredPersonal)
                    .groupBy(personal => personal.apaterno[0].toUpperCase())
                    .map((personal, inicial) => ({ inicial, personal }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.inicial < b.inicial) {
                            return -1
                        }
                        if (a.inicial > b.inicial) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.inicial > b.inicial) {
                            return -1
                        }
                        if (a.inicial < b.inicial) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },

        methods: {
            openAsuntosInternosDialog(personal) {
                this.selectedPersonal = personal.idPersonal
                this.asuntosInternosDialog = true
            },

            openMarcaActivoDialog(personal) {
                this.selectedPersonal = personal.idPersonal
                this.marcaActivoDialog = true
            },

            openResultadoDialog(personal) {
                this.selectedPersonal = personal
                this.resultadosDialog = true
            },
        },
    }
</script>
