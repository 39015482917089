<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Inventario de municiones y cargadores</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="end" align="center">
                                <v-col cols="2">
                                    <registrar-movimiento-dialog-component></registrar-movimiento-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaMunicion"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar por calibre"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        class="primary"
                                        :headers="headers"
                                        :items="municiones"
                                        :search="buscaMunicion"
                                        hide-default-footer
                                        calculate-widths
                                    >
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn rounded small color="secondary" class="mx-2" @click="openMovimientosDialog(item)">Ver movimientos</v-btn>
                                            <v-btn rounded small color="secondary" class="mx-2" @click="openListaCargadoresDialog(item)">
                                                Ver lista de cargadores</v-btn
                                            >
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <historial-movimientos-dialog-component
            :active="historialMovimientosDialog"
            :calibre="selectedCalibre"
            @close="historialMovimientosDialog = false"
        ></historial-movimientos-dialog-component>
        <lista-cargadores-dialog-component :active="listaCargadoresDialog" @close="listaCargadoresDialog = false"></lista-cargadores-dialog-component>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import HistorialMovimientosDialogComponent from '../../components/armeria/HistorialMovimientosDialogComponent.vue'
    import ListaCargadoresDialogComponent from '../../components/armeria/ListaCargadoresDialogComponent.vue'
    import RegistrarMovimientoDialogComponent from '../../components/armeria/RegistrarMovimientoDialogComponent.vue'

    export default {
        components: {
            RegistrarMovimientoDialogComponent,
            HistorialMovimientosDialogComponent,
            ListaCargadoresDialogComponent,
        },

        data() {
            return {
                headers: [
                    { text: 'Calibre', value: 'calibre', width: '10%' },
                    { text: 'Opciones', value: 'opciones', align: 'center', width: '10%' },
                ],

                buscaMunicion: '',

                historialMovimientosDialog: false,
                listaCargadoresDialog: false,

                movimientosCargador: [],
                movimientosMunicion: [],

                selectedCalibre: null,
            }
        },

        computed: {
            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),

            ...mapGetters('movimientoMunicion', {
                movimientoMunicion: 'getMovimientoMunicion',
            }),

            municiones: function() {
                var municiones = []
                this.calibres.forEach(calibre => {
                    municiones.push({
                        idCalibre: calibre.id,
                        calibre: calibre.title,
                        stock_municiones: this.getStockMunicionesByCalibre(calibre.id),
                        stock_cargadores: 0,
                    })
                })
                return municiones
            },
        },

        methods: {
            ...mapActions('movimientoMunicion', ['fetchMovimientoMunicion', 'fetchMovimientoMunicionDisponibles']),
            ...mapActions('movimientoCargador', ['fetchMovimientoCargador', 'fetchMovimientoCargadorDisponibles']),
            ...mapActions('cargadores', ['fetchCargadores']),

            async openMovimientosDialog(calibre) {
                this.selectedCalibre = calibre
                await this.fetchMovimientoCargador(calibre.idCalibre)
                await this.fetchMovimientoCargadorDisponibles(calibre.idCalibre)
                await this.fetchMovimientoMunicion()
                await this.fetchMovimientoMunicionDisponibles(calibre.idCalibre)

                this.historialMovimientosDialog = true
            },

            async openListaCargadoresDialog(calibre) {
                this.selectedCalibre = calibre
                await this.fetchCargadores(calibre.idCalibre)

                this.listaCargadoresDialog = true
            },

            getStockMunicionesByCalibre(id_calibre) {
                var stock = 0

                var filteredMuniciones = this.movimientoMunicion.filter(movimiento => {
                    return movimiento.fk_idCatCalibre == id_calibre
                })

                filteredMuniciones.forEach(movimiento => {
                    if (movimiento.fk_idcatTipoMovimiento == 1) {
                        stock += movimiento.cantidad
                    } else {
                        stock -= movimiento.cantidad
                    }
                })

                return stock
            },
        },
    }
</script>
