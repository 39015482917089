<template>
    <v-dialog persistent width="90%" v-model="active">
        <v-card class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación de productividad</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="productividad">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-select
                                                    label="Tipo de evaluación"
                                                    :items="filteredTipoEvaluacion"
                                                    v-model="selectedTipoEvaluacion"
                                                    @change="fetchProductividadData($event)"
                                                    item-value="id"
                                                    item-text="title"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="Personal evaluador"
                                                    :value="getFullName(productividad.PersonalEvaluador)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="Personal evaluador"
                                                    :value="getFullName(productividad.Personal)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="productividad.fechaInicial"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="productividad.fechaFin"
                                                    label="Fecha fin"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="productividad.fechaAplicacion"
                                                    label="Fecha aplicación"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Indicadores</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container v-if="productividad.niveles.length > 0">
                                        <v-row>
                                            <v-col cols="12" v-for="(indicador, index) in indicadores" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        <div class="text-subtitle-2 font-weight-bold">
                                                            {{ indicador.CatFuncion.title }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-subtitle-2">{{ indicador.CatIndicador.title }}</div>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                            :disabled="productividad.niveles[index].fk_idCatFrecuencia == 6"
                                                            v-model="productividad.niveles[index].realizados"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            label="Realizados"
                                                            :rules="required"
                                                            validate-on-blur
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            label="Frecuencia productividad"
                                                            background-color="primary"
                                                            color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            :items="frecuenciaProductividad"
                                                            item-value="id"
                                                            item-text="title"
                                                            item-color="secondary"
                                                            :value="productividad.niveles[index].fk_idCatFrecuencia"
                                                            @input="setFrecuencia(indicador.id, $event)"
                                                            :rules="required"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Factores de mérito</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container v-if="productividad.factores.length > 0">
                                        <v-row>
                                            <v-col cols="12" v-for="(factor, index) in actosRelevantes" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="4">
                                                        {{ factor.CatActosRelevantes.title }}
                                                    </v-col>
                                                    <v-col cols="2">
                                                        <v-text-field
                                                            v-model.number="productividad.factores[index].cantidad"
                                                            type="number"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            label="Cantidad"
                                                            validate-on-blur
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                            v-model="productividad.factores[index].descripcion"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                            label="Descripción"
                                                            validate-on-blur
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Observaciones</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea
                                                    label="Observaciones"
                                                    v-model="productividad.observaciones"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    no-resize
                                                    rows="3"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="5">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Resultados</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Resultado</div>
                                                        <div class="text-subtitle-1">{{ getResultado }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Puntaje total</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Calificación</div>
                                                        <div class="text-subtitle-1">{{ getCalificacion }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveEvaluacionProductividad()" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            productividad: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
                required: [v => !!v || 'Este campo es requerido.'],

                selectedTipoEvaluacion: null,
            }
        },

        watch: {
            indicadores: {
                handler(newVal) {
                    this.setNiveles()
                },
                deep: true,
            },

            actosRelevantes: {
                handler(newVal) {
                    this.setFactoresMerito()
                },
                deep: true,
            },
        },

        computed: {
            ...mapGetters('funcion', {
                funciones: 'getFunciones',
            }),

            ...mapGetters('indicadorEvaluacion', {
                indicadores: 'getIndicadorEvaluacion',
            }),

            ...mapGetters('actosRelevantesEvaluacion', {
                actosRelevantes: 'getActosRelevantesEvaluacion',
            }),

            ...mapGetters('frecuenciaProductividad', {
                frecuenciaProductividad: 'getFrecuenciaProductividad',
            }),

            ...mapGetters('factorMerito', {
                factoresMerito: 'getFactoresMerito',
            }),

            ...mapGetters('resultado', {
                resultado: 'getResultado',
            }),

            ...mapGetters('tipoEvaluacion', {
                tipoEvaluacion: 'getTipoEvaluacion',
            }),

            filteredTipoEvaluacion: function() {
                return this.tipoEvaluacion.filter(tipoEvaluacion => {
                    return tipoEvaluacion.id == 1 || tipoEvaluacion.id == 3
                })
            },

            getResultado: function() {
                if (this.getCalificacion >= 9.5) {
                    return 'EXCELENTE'
                } else if (this.getCalificacion >= 8) {
                    return 'SOBRESALIENTE'
                } else if (this.getCalificacion >= 7) {
                    return 'SATISFACTORIO'
                } else if (this.getCalificacion >= 6) {
                    return 'INSUFICIENTE'
                } else if (this.getCalificacion < 6) {
                    return 'NO SATISFACTORIO'
                }
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0
                this.productividad.niveles.forEach(reactivo => {
                    switch (reactivo.fk_idCatFrecuencia) {
                        case 1:
                            puntajeTotal = puntajeTotal + 4
                            break
                        case 2:
                            puntajeTotal = puntajeTotal + 3
                            break
                        case 3:
                            puntajeTotal = puntajeTotal + 2
                            break
                        case 4:
                            puntajeTotal = puntajeTotal + 1

                            break
                        case 5:
                            puntajeTotal = puntajeTotal + 0
                            break
                    }
                })

                return puntajeTotal
            },

            getCalificacion: function() {
                var calificacion = 0

                var reactivos_aplican = this.productividad.niveles.filter(reactivo => {
                    return reactivo.fk_idCatFrecuencia != 6
                }).length

                calificacion = (this.getPuntajeTotal * 100) / (reactivos_aplican * 4)

                if (+calificacion.toFixed(2) >= 100) {
                    return 10
                } else if (+calificacion.toFixed(2) >= 91) {
                    return 9.5
                } else if (+calificacion.toFixed(2) >= 83) {
                    return 9
                } else if (+calificacion.toFixed(2) >= 75) {
                    return 8.5
                } else if (+calificacion.toFixed(2) >= 67) {
                    return 8
                } else if (+calificacion.toFixed(2) >= 59) {
                    return 7.5
                } else if (+calificacion.toFixed(2) >= 50) {
                    return 7
                } else if (+calificacion.toFixed(2) >= 37) {
                    return 6.5
                } else if (+calificacion.toFixed(2) >= 25) {
                    return 6
                } else if (+calificacion.toFixed(2) >= 22) {
                    return 5.5
                } else if (+calificacion.toFixed(2) >= 20) {
                    return 5
                } else if (+calificacion.toFixed(2) >= 18) {
                    return 4.5
                } else if (+calificacion.toFixed(2) >= 16) {
                    return 4
                } else if (+calificacion.toFixed(2) >= 14) {
                    return 3.5
                } else if (+calificacion.toFixed(2) >= 12) {
                    return 3
                } else if (+calificacion.toFixed(2) >= 10) {
                    return 2.5
                } else if (+calificacion.toFixed(2) >= 8) {
                    return 2
                } else if (+calificacion.toFixed(2) >= 6) {
                    return 1.5
                } else if (+calificacion.toFixed(2) >= 4) {
                    return 1
                } else if (+calificacion.toFixed(2) >= 2) {
                    return 0.5
                } else return 0
            },
        },

        methods: {
            ...mapActions('productividad', ['updateProductividad', 'updateEstatusProductividad']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),
            ...mapActions('actosRelevantesEvaluacion', ['fetchActosRelevantesEvaluacion']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            setNiveles: function() {
                this.productividad.niveles = []
                this.indicadores.forEach(indicador => {
                    this.productividad.niveles.push({
                        realizados: null,
                        fk_idCatFrecuencia: null,
                        fk_idIndicadorEvaluacion: indicador.id,
                        estatus: true,
                    })
                })
            },

            setFactoresMerito: function() {
                this.productividad.factores = []
                this.actosRelevantes.forEach(factor => {
                    this.productividad.factores.push({
                        fk_idActoRelevanteEvaluacion: factor.id,
                        cantidad: null,
                        descripcion: '',
                        estatus: true,
                    })
                })
            },

            triggerSaveEvaluacionProductividad: async function() {
                this.loading = true

                this.productividad.puntajeTotal = this.getPuntajeTotal
                this.productividad.calificacion = +parseFloat(this.getCalificacion).toFixed(2)
                this.productividad.fkidResultado = 1
                await this.updateProductividad(this.productividad)
                await this.updateEstatusProductividad(this.productividad)
                await this.fetchEvaluacionesById(this.productividad.fkidPersonalEvaluador)

                this.closeDialog()
            },

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },

            setRealizados: function(id_indicador, e) {
                const index = this.productividad.niveles.findIndex(indicador => indicador.fk_idIndicadorEvaluacion == id_indicador)
                this.productividad.niveles[index].realizados = e
            },

            setFrecuencia: function(id_indicador, e) {
                const index = this.productividad.niveles.findIndex(indicador => indicador.fk_idIndicadorEvaluacion == id_indicador)
                this.productividad.niveles[index].fk_idCatFrecuencia = e
            },

            async fetchProductividadData(event) {
                await this.fetchIndicadorEvaluacion(event)
                await this.fetchActosRelevantesEvaluacion(event)
            },
        },
    }
</script>
