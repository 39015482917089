import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cursos: [],
        cursoToUpdate: null,
    },

    getters: {
        getCursos: state => {
            return state.cursos
        },

        getCursoToUpdate: state => {
            return state.cursoToUpdate
        },
    },

    mutations: {
        SET_CURSOS(state, cursos) {
            state.cursos = cursos
        },

        SET_CURSO_TO_UPDATE(state, cursoId) {
            var cursoToUpdate = state.cursos.filter(curso => {
                return curso.id == cursoId
            })

            var curso = _.cloneDeep(cursoToUpdate[0])

            state.cursoToUpdate = curso
        },
    },

    actions: {
        fetchCursos: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/curso')
                .then(response => {
                    commit('SET_CURSOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCurso: async function(context, curso) {
            console.log('Salvando curso')
            await axios
                .post('/PoliciaCuadrantes/Profesionalizacion/curso', curso)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', curso.file)
                    formData.append('idSubcategoria', response.data.data[0].ID)
                    context.dispatch('uploadCurso', formData)
                })
                .catch(error => console.log(error))
        },

        updateCurso: async function(context, curso) {
            await axios
                .put('/PoliciaCuadrantes/Profesionalizacion/curso/' + curso.id, curso)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', curso.file)
                    formData.append('idSubcategoria', curso.id)
                    console.log(formData)
                    context.dispatch('uploadCurso', formData)
                })
                .catch(error => console.log(error))
        },

        uploadCurso: async function({ commit }, formData) {
            await fileAxios
                .post('/Curso/uploadFiles', formData)
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
