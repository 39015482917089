import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoFuncion: [],
    },

    getters: {
        getTipoFuncion: state => {
            return state.tipoFuncion
        },
    },

    mutations: {
        SET_TIPO_FUNCION(state, tipoFuncion) {
            state.tipoFuncion = tipoFuncion
        },
    },

    actions: {
        fetchTipoFuncion: async function({ commit }) {
            await axios
                .get('/EonCloud/common/tiposFuncion')
                .then(response => {
                    commit('SET_TIPO_FUNCION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
