import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        subsedes: [],
    },

    getters: {
        getSubsedes: state => {
            return state.subsedes
        },
    },

    mutations: {
        SET_SUBSEDES(state, subsedes) {
            state.subsedes = subsedes
        },
    },

    actions: {
        fetchSubsedes: async function({ commit }) {
            await axios
                .get('/EonCloud/common/subsedes')
                .then(response => {
                    commit('SET_SUBSEDES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchSubsedesBySede: async function({ commit }, sede) {
            await axios
                .get('/EonCloud/common/sedes/' + sede.id + '/subsedes')
                .then(response => {
                    commit('SET_SUBSEDES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
