<template>
    <v-dialog v-model="active" width="480" persistent v-if="calibre">
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Historial de movimientos</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="6" align="center">
                            <v-card color="secondary" class="card">
                                <v-card-title class="justify-center">
                                    <div v-if="municionesDisponibles.length > 0" class="text-h6 primary--text">
                                        {{ municionesDisponibles[0].disponibles }}
                                    </div>
                                    <div v-else class="text-h6 primary--text">0</div>
                                    <div class="text-subtitle-2 primary--text">Municiones disponibles</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align="center">
                            <v-card color="secondary" class="card">
                                <v-card-title class="justify-center">
                                    <div v-if="cargadoresDisponibles.length > 0" class="text-h6 primary--text">
                                        {{ cargadoresDisponibles[0].disponibles }}
                                    </div>
                                    <div v-else class="text-h6 primary--text">0</div>
                                    <div class="text-subtitle-2 primary--text">Cargadores disponibles</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                label="Mostrar movimientos de"
                                v-model="selectedTipoLista"
                                :items="tipoListaMovimientos"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                item-color="secondary"
                                item-value="id"
                                item-text="title"
                            ></v-select>
                            <v-virtual-scroll height="480" :items="filteredListaMovimientos" item-height="88">
                                <template v-slot:default="{ item, index }">
                                    <v-list-item :key="index" three-line link>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
                                            <v-list-item-title>{{ getTipoMovimiento(item.fk_idcatTipoMovimiento) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.observaciones }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-avatar color="secondary">
                                                <span class="white--text">{{ item.cantidad }}</span>
                                            </v-avatar>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex'
    import _ from 'lodash'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            calibre: {
                type: Object,
            },
        },

        data() {
            return {
                selectedTipoLista: 1,
                tipoListaMovimientos: [
                    { id: 1, title: 'Todos' },
                    { id: 2, title: 'Cargadores' },
                    { id: 3, title: 'Municiones' },
                ],
            }
        },

        computed: {
            ...mapGetters('movimientoMunicion', {
                movimientoMunicion: 'getMovimientoMunicion',
                municionesDisponibles: 'getMovimientoMunicionDisponibles',
            }),

            ...mapGetters('movimientoCargador', {
                movimientoCargador: 'getMovimientoCargador',
                cargadoresDisponibles: 'getMovimientoCargadorDisponibles',
            }),

            filteredMovimientoMuniciones() {
                return this.movimientoMunicion.filter(movimiento => {
                    return movimiento.fk_idCatCalibre == this.calibre.idCalibre
                })
            },

            filteredListaMovimientos() {
                switch (this.selectedTipoLista) {
                    case 1:
                        return _.sortBy(this.filteredMovimientoMuniciones.concat(this.movimientoCargador), function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    case 2:
                        return _.sortBy(this.movimientoCargador, function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    case 3:
                        return _.sortBy(this.filteredMovimientoMuniciones, function(movimiento) {
                            return new Date(movimiento.fecha)
                        }).reverse()
                        break
                    default:
                        break
                }
            },
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },

            getTipoMovimiento(id_tipo_movimiento) {
                if (id_tipo_movimiento == 1) {
                    return 'Entrada'
                } else return 'Salida'
            },
        },
    }
</script>
