<template>
    <v-container v-if="aulaToUpdate">
        <v-row>
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card color="primary" class="card">
                    <v-card-title class="font-weight-bold justify-center">Registro de aula</v-card-title>
                    <v-card-text>
                        <v-form v-model="classroomForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="aulaToUpdate.aula"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Nombre del aula"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Nombre del aula <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-text-field
                                            v-model="aulaToUpdate.ubicacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Ubicación <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model.number="aulaToUpdate.capacidad"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Capacidad"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Capacidad <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="classroomStatus"
                                            item-value="id"
                                            item-text="title"
                                            v-model="aulaToUpdate.fkidEstatusAula"
                                        >
                                            <template v-slot:label>Estatus del aula <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" class="mb-8" :disabled="!classroomForm" v-on:click="triggerSaveClassroom()">Editar aula</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_AULA_TO_UPDATE(this.id)
        },

        data() {
            return {
                classroomForm: false,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('estatusAula', {
                classroomStatus: 'getClassroomStatus',
            }),

            ...mapGetters('aula', {
                aulaToUpdate: 'getAulaToUpdate',
            }),
        },

        methods: {
            ...mapActions('aula', ['updateAula', 'fetchAulas']),
            ...mapMutations('aula', ['SET_AULA_TO_UPDATE']),

            triggerSaveClassroom: async function() {
                this.loading = true

                await this.updateAula(this.aulaToUpdate)
                await this.fetchAulas()
                this.$router.replace('/profesionalizacion/aulas')

                this.loading = false
            },
        },
    }
</script>
