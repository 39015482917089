import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoAmbito: [],
    },

    getters: {
        getTipoAmbito: state => {
            return state.tipoAmbito
        },
    },

    mutations: {
        SET_TIPO_AMBITO(state, tipoAmbito) {
            state.tipoAmbito = tipoAmbito
        },
    },

    actions: {
        fetchTipoAmbito: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/tipoAmbito')
                .then(response => {
                    commit('SET_TIPO_AMBITO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
