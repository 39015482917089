import { axios } from '../../../plugins/axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        items: [],
        itemsPrestamo: [],
        itemToUpdate: null,
    },

    getters: {
        getItems: state => {
            return state.items
        },

        getItemsPrestamo: state => {
            return state.itemsPrestamo
        },

        getItemToUpdate: state => {
            return state.itemToUpdate
        },
    },

    mutations: {
        SET_ITEMS(state, items) {
            items.forEach(item => {
                state.items.push(item)
            })
        },

        SET_ITEMS_PRESTAMO(state, itemsPrestamo) {
            state.itemsPrestamo = itemsPrestamo
        },

        SET_ITEM_TO_UPDATE(state, itemId) {
            var itemToUpdate = state.items.filter(item => {
                return item.id == itemId
            })

            var item = _.cloneDeep(itemToUpdate[0])

            state.itemToUpdate = item
        },

        CLEAR_ITEMS(state) {
            state.items = []
        },
    },

    actions: {
        fetchItemsByType: async function({ commit }, tipoItem) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/tipoItem/' + tipoItem.id + '/items')
                .then(response => {
                    commit('SET_ITEMS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchItemsById: async function({ commit }, id) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/tipoItem/' + id + '/items')
                .then(response => {
                    commit('SET_ITEMS_PRESTAMO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveItem: async function({ commit }, item) {
            await axios
                .post('/PoliciaCuadrantes/Equipamiento/items', item)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateItem: async function({ commit }, item) {
            await axios
                .put('/PoliciaCuadrantes/Equipamiento/items/' + item.id, item)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        clearItems: function({ commit }) {
            commit('CLEAR_ITEMS')
        },
    },
}
