<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-text-field
                    v-model="buscaPersonal"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar personal..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col cols="12" lg="12">
                <v-slide-group show-arrows>
                    <v-slide-item v-for="personal in filteredPersonal" :key="personal.idPersonal">
                        <v-card class="my-12 mx-8 card" color="secondary" elevation="8" width="360">
                            <v-card-title class="d-block" align="center">
                                <v-badge bottom right overlap offset-x="25" offset-y="25" color="blue">
                                    <v-avatar size="120" color="primary">
                                        <v-icon size="80" color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                </v-badge>
                                <div class="primary--text mt-4">{{ getFullName(personal) }}</div>
                            </v-card-title>
                            <v-card-text class="my-4">
                                <v-container>
                                    <v-row align="center">
                                        <v-col cols="4">
                                            <div class="text-subtitle-1 primary--text">Sede:</div>
                                        </v-col>
                                        <v-col cols="8" align="right">
                                            <div v-if="personal.CatSubSede" class="text-body-1 font-weight-bold primary--text">
                                                {{ personal.CatSubSede.CatSede.sede }}
                                            </div>
                                            <div v-else class="text-body-1 font-weight-bold primary--text">NA</div>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col cols="4">
                                            <div class="text-subtitle-1 primary--text">Subsede:</div>
                                        </v-col>
                                        <v-col cols="8" align="right">
                                            <div v-if="personal.CatSubSede" class="text-body-1 font-weight-bold primary--text">
                                                {{ personal.CatSubSede.nombre }}
                                            </div>
                                            <div v-else class="text-body-1 font-weight-bold primary--text">NA</div></div>
                                        </v-col>
                                        <v-divider></v-divider>
                                        <v-col cols="4">
                                            <div class="text-subtitle-1 primary--text">Puesto:</div>
                                        </v-col>
                                        <v-col cols="8" align="right">
                                            <div v-if="personal.CatPuestoDependencia" class="text-body-1 font-weight-bold primary--text">{{ personal.CatPuestoDependencia.catPuesto.puesto }}</div>
                                            <div v-else class="text-body-1 font-weight-bold primary--text">NA</div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-8">
                                <v-btn
                                    class="primary secondary--text mx-2"
                                    elevation="8"
                                    :to="{ name: 'actualizaAspiranteCadete', params: { personal: personal } }"
                                    icon
                                    ><v-icon>mdi-police-badge</v-icon></v-btn
                                >
                                <v-btn class="primary secondary--text mx-2" elevation="8" v-on:click="activeStep = 3" icon>
                                    <v-icon>mdi-file-account</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-slide-item>
                </v-slide-group>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                buscaPersonal: '',
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            filteredPersonal: function() {
                return this.personal.filter(personal => {
                    return (
                        personal.nombre.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0 ||
                        personal.segundoNombre.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0 ||
                        personal.apaterno.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0 ||
                        personal.amaterno.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0
                    )
                })
            },
        },

        methods: {
            getFullName: function(personal) {
                return personal.nombre + ' ' + personal.segundoNombre + ' ' + personal.apaterno + ' ' + personal.amaterno
            },
        },
    }
</script>
