<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="7">
                <v-text-field
                    v-model="buscaPreaspirante"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar preaspirante por nombre o CURP"
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaPreaspirante.length > 0">
                <div class="text-h6 font-weight-bold">
                    {{ filteredPreaspirantes.length }} resultados encontrados con la búsqueda "{{ buscaPreaspirante }}"
                </div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoPreaspirantes, index) in groupedPreaspirantes" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoPreaspirantes.inicial }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoPreaspirantes.preaspirantes.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="preaspirante in grupoPreaspirantes.preaspirantes" :key="preaspirante.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-account</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ preaspirante.apaterno }}</div>
                                                        <div class="text-subtitle-1 primary--text ">
                                                            {{ preaspirante.amaterno + ' ' + preaspirante.nombre + ' ' + preaspirante.segundoNombre }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-sheet color="transparent" height="120"></v-sheet>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="right">
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="perfil === 'REP_BIOMETRIA' || perfil === 'REP_ADMINISTRADOR' || perfil === 'Personal/Site'"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'capturaFoto', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-camera</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Captura de fotografía</span>
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="perfil === 'REP_BIOMETRIA' || perfil === 'REP_ADMINISTRADOR' || perfil === 'Personal/Site'"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'capturaVoz', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-microphone</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Captura de voz</span>
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="perfil === 'REP_BIOMETRIA' || perfil === 'REP_ADMINISTRADOR' || perfil === 'Personal/Site'"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'capturaHuella', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-fingerprint</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Captura de huellas</span>
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="perfil === 'REP_REGISTRO' || perfil === 'REP_ADMINISTRADOR' || perfil === 'Personal/Site'"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'generaDictamen', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-file-plus</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Registro de resultado en consulta previa</span>
                                                        </v-tooltip>
                                                        <v-tooltip
                                                            bottom
                                                            color="black"
                                                            v-if="perfil === 'REP_REGISTRO' || perfil === 'REP_ADMINISTRADOR' || perfil === 'Personal/Site'"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'registroFotoCuip', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-card-text</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Registro de CUIP</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'documentosPreaspiranteSite', params: { id: preaspirante.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-file-account</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Ver expediente</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import _ from 'lodash'

    export default {
        data() {
            return {
                buscaPreaspirante: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirantes: 'getPreaspirantes',
            }),

            ...mapGetters({
                perfil: 'getPerfil',
            }),

            filteredPreaspirantes: function() {
                return this.preaspirantes.filter(preaspirante => {
                    return (
                        preaspirante.nombre.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0 ||
                        preaspirante.segundoNombre.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0 ||
                        preaspirante.apaterno.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0 ||
                        preaspirante.amaterno.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0 ||
                        this.getAge(preaspirante.fechaNacimiento) == this.buscaPreaspirante
                        // (preaspirante.CatMunicipioRadica && preaspirante.CatMunicipioRadica.nombre.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0) ||
                        // (preaspirante.CatMunicipioRadica &&
                        //     preaspirante.CatMunicipioRadica.CatEstado.title.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0)
                        // (preaspirante.CatMunicipioNacimiento &&
                        //     preaspirante.CatMunicipioNacimiento.nombre.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0) ||
                        // (preaspirante.CatMunicipioNacimiento &&
                        //     preaspirante.CatMunicipioNacimiento.CatEstado.title.toLowerCase().indexOf(this.buscaPreaspirante.toLowerCase()) >= 0)
                    )
                })
            },

            groupedPreaspirantes: function() {
                var result = _(this.filteredPreaspirantes)
                    .groupBy(preaspirante => preaspirante.apaterno[0].toUpperCase())
                    .map((preaspirantes, inicial) => ({ inicial, preaspirantes }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.inicial < b.inicial) {
                            return -1
                        }
                        if (a.inicial > b.inicial) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.inicial > b.inicial) {
                            return -1
                        }
                        if (a.inicial < b.inicial) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },

        methods: {
            getFullName: function(preaspirante) {
                return preaspirante.nombre + ' ' + preaspirante.segundoNombre + ' ' + preaspirante.apaterno + ' ' + preaspirante.amaterno
            },
        },
    }
</script>
