import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        vistaSeniaParticular: [],
    },

    getters: {
        getVistaSeniaParticular: state => {
            return state.vistaSeniaParticular
        },
    },

    mutations: {
        SET_VISTA_SENIA_PARTICULAR(state, vistaSeniaParticular) {
            state.vistaSeniaParticular = vistaSeniaParticular
        },
    },

    actions: {
        fetchVistaSeniaParticular: async function({ commit }) {
            await axios
                .get('/EonCloud/common/vistaSeniaParticular')
                .then(response => {
                    commit('SET_VISTA_SENIA_PARTICULAR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
