<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title>
                        <div class="text-h6 font-weight-bold">Gráfica de vigencias de elementos activos</div>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <apexchart ref="apex" height="100%" type="bar" :options="options2" :series="series2"></apexchart>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold">Aspirantes listos para turnar a C3</v-card-title>
                    <v-card-text>
                        <div v-if="aspirantesNoTurnados.length == 0" class="text-body-1">No hay información para mostrar.</div>
                        <template v-else>
                            <v-virtual-scroll height="480" item-height="64" :items="aspirantesNoTurnados">
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item.idPersonal" two-line>
                                        <v-list-item-avatar>
                                            <v-avatar color="secondary" size="80">
                                                <v-icon color="white">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="text-body-1 font-weight-bold">
                                                    {{ getFullName(item) }}
                                                </div>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div class="text-body-2">{{ getAge(item.fechaNacimiento) }} años</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon small @click="openTurnaAspiranteDialog(item)"><v-icon color="secondary">mdi-update</v-icon></v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold">Aspirantes en espera de resultados</v-card-title>
                    <v-card-text>
                        <div v-if="aspirantesTurnados.length == 0" class="text-body-1">No hay información para mostrar.</div>
                        <template v-else>
                            <v-virtual-scroll height="480" item-height="120" :items="aspirantesTurnados">
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item.idPersonal" three-line>
                                        <v-list-item-avatar>
                                            <v-avatar color="secondary" size="80">
                                                <v-icon color="white">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="text-body-1 font-weight-bold">
                                                    {{ getFullName(item) }}
                                                </div>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div class="text-body-2">{{ getAge(item.fechaNacimiento) }} años</div>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <div class="text-body-2">Resultado C3: {{ item.observacionesC3 }}</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon small @click="openResultadoDialog(item)"><v-icon color="secondary">mdi-file-edit</v-icon></v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold">Recordatorio mensual de vigencias</v-card-title>
                    <v-card-text>
                        <div v-if="personalVigencia.length == 0" class="text-body-1">No hay información para mostrar.</div>
                        <template v-else>
                            <v-virtual-scroll height="480" item-height="120" :items="personalVigencia">
                                <template v-slot:default="{ item }">
                                    <v-list-item :key="item.idPersonal" three-line>
                                        <v-list-item-avatar>
                                            <v-img v-if="item.foto != ''" :src="item.foto" contain></v-img>
                                            <v-avatar color="secondary" size="80" v-else>
                                                <v-icon color="white">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="text-body-1 font-weight-bold">
                                                    {{ getFullName(item) }}
                                                </div>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <div class="text-body-2">{{ item.CatPuestoDependencia.catPuesto.puesto }}</div>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <div class="text-caption">{{ item.CatArea.title }}</div>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                <div class="text-caption">Vigencia C3 hasta: {{ item.examenC3Mes }} del {{ item.vigenciaAnio }}</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-virtual-scroll>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <turna-aspirante-dialog-component
            :active="turnaAspiranteDialog"
            :aspirante="selectedAspirante"
            @close="turnaAspiranteDialog = false"
        ></turna-aspirante-dialog-component>
        <registro-resultados-aspirante-dialog-component
            :active="resultadosDialog"
            :personal="selectedPersonal"
            @close="resultadosDialog = false"
        ></registro-resultados-aspirante-dialog-component>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters } from 'vuex'
    import validator from 'validator'
    import ActualizaAspiranteDialogComponent from '../../components/profesionalizacion/ActualizaAspiranteDialogComponent.vue'
    import TurnaAspiranteDialogComponent from '../../components/enlace/TurnaAspiranteDialogComponent.vue'
    import RegistroResultadosAspiranteDialogComponent from '../../components/enlace/RegistroResultadosAspiranteDialogComponent'

    export default {
        components: {
            ActualizaAspiranteDialogComponent,
            TurnaAspiranteDialogComponent,
            RegistroResultadosAspiranteDialogComponent,
        },

        async mounted() {
            this.setData()
        },

        data() {
            return {
                calendario: '',

                selectedOpen: false,
                selectedEvent: {},
                selectedElement: null,

                selectedAspirante: null,
                selectedPersonal: null,
                turnaAspiranteDialog: false,
                resultadosDialog: false,

                options2: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {},
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: [
                            'Enero',
                            'Febrero',
                            'Marzo',
                            'Abril',
                            'Mayo',
                            'Junio',
                            'Julio',
                            'Agosto',
                            'Septiembre',
                            'Octubre',
                            'Noviembre',
                            'Diciembre',
                        ],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                series2: [
                    {
                        type: 'column',
                        data: [],
                        name: 'Cantidad de elementos',
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirantes: 'getPreaspirantes',
                aspirantes: 'getAspirantes',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            aspirantesNoTurnados() {
                return this.aspirantes.filter(aspirante => {
                    return aspirante.observacionesC3 === ''
                })
            },

            aspirantesTurnados() {
                return this.aspirantes.filter(aspirante => {
                    return aspirante.observacionesC3 === 'NO REGISTRADO'
                })
            },

            personalVigencia() {
                return this.personal.filter(personal => {
                    return this.getVigenciaProgramada(personal.examenC3Mes, personal.vigenciaAnio) == moment().format('YYYY-MM')
                })
            },
        },

        methods: {
            mostrarEvento(event) {
                this.selectedEvent = event.event
                this.selectedOpen = true
            },

            closeMenu() {
                this.selectedOpen = false
            },

            openTurnaAspiranteDialog(aspirante) {
                this.selectedAspirante = aspirante
                this.turnaAspiranteDialog = true
            },

            openResultadoDialog(personal) {
                this.selectedPersonal = personal
                this.resultadosDialog = true
            },

            getVigenciaProgramada(mes, anio) {
                var mesC3 = moment()
                    .month(mes)
                    .locale('es')
                    .format('M')

                var fecha = anio.toString() + '-' + mesC3.toString()

                return moment(fecha)
                    .subtract(7, 'months')
                    .format('YYYY-MM')
            },

            setData() {
                this.$refs.apex.updateSeries([{ type: 'column', data: this.getVigenciaMensual(), name: 'Cantidad de elementos' }])
            },

            getVigenciaMensual() {
                var vigencia = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                this.personal.forEach(personal => {
                    if (personal.examenC3Mes != '') {
                        switch (
                            moment()
                                .month(personal.examenC3Mes)
                                .locale('es')
                                .format('M')
                        ) {
                            case '1':
                                vigencia[0]++
                                break
                            case '2':
                                vigencia[1]++
                                break
                            case '3':
                                vigencia[2]++
                                break
                            case '4':
                                vigencia[3]++
                                break
                            case '5':
                                vigencia[4]++
                                break
                            case '6':
                                vigencia[5]++
                                break
                            case '7':
                                vigencia[6]++
                                break
                            case '8':
                                vigencia[7]++
                                break
                            case '9':
                                vigencia[8]++
                                break
                            case '10':
                                vigencia[9]++
                                break
                            case '11':
                                vigencia[10]++
                                break
                            case '12':
                                vigencia[11]++
                                break
                            default:
                                break
                        }
                    }
                })

                return vigencia
            },
        },
    }
</script>
