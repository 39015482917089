<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="rounded-sm" color="primary">
            <v-card-title>
                <div class="text-h6">Validación de asuntos internos</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-autocomplete
                    label="Personal que autoriza"
                    v-model="asuntosInternos.fk_idPersonalAutoriza"
                    :items="personal"
                    item-value="idPersonal"
                    item-text="CUIP"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                ></v-autocomplete>
                <v-checkbox label="Autorizó para realización de evaluaciones" v-model="asuntosInternos.autorizo" color="secondary"></v-checkbox>
                <v-text-field
                    label="Número de oficio"
                    v-model="asuntosInternos.oficio"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                ></v-text-field>
                <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="asuntosInternos.fechaConsulta"
                            label="Fecha"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="asuntosInternos.fechaConsulta" @input="menuFecha = false"></v-date-picker>
                </v-menu>
                <v-textarea label="Observaciones" rounded filled dense color="secondary" background-color="primary" no-resize rows="4"></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="loading" @click="saveAsuntos()">Registrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            idPersonal: {
                type: Number,
            },
        },

        data() {
            return {
                menuFecha: false,
                loading: false,

                asuntosInternos: {
                    fk_idPersonal: this.idPersonal,
                    fk_idPersonalAutoriza: null,
                    observaciones: '',
                    autorizo: true,
                    fechaConsulta: null,
                    oficio: '',
                },
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            fecha() {
                return this.asuntosInternos.fecha ? moment(this.asuntosInternos.fecha).format('DD/MM/YYYY') : ''
            },
        },

        methods: {
            ...mapActions('asuntosInternos', ['saveAsuntosInternos']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
            },

            async saveAsuntos() {
                this.loading = true
                await this.saveAsuntosInternos({
                    fk_idPersonal: this.idPersonal,
                    fk_idPersonalAutoriza: this.asuntosInternos.fk_idPersonalAutoriza,
                    observaciones: this.asuntosInternos.observaciones,
                    autorizo: this.asuntosInternos.autorizo,
                    fechaConsulta: moment(this.asuntosInternos.fechaConsulta).format('DD/MM/YYYY'),
                    oficio: this.asuntosInternos.oficio,
                })

                this.closeDialog()
            },
        },
    }
</script>
