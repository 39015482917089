<template>
    <v-container v-if="productividad.length > 0">
        <v-row>
            <v-col lg="4">
                <v-row>
                    <v-col cols="12">
                        <v-card class="card" color="secondary">
                            <v-card-title v-bind="cardTitleProps" class="black--text d-block" align="center">
                                <div class="black--text mb-4">Evaluación de productividad</div>
                                <v-container>
                                    <v-row align="center">
                                        <v-col cols="4">
                                            <v-avatar rounded size="80" color="primary">
                                                <v-icon size="48" color="secondary">mdi-account</v-icon>
                                            </v-avatar>
                                        </v-col>
                                        <v-col cols="8" align="left">
                                            <div class="black--text text-subtitle-1">{{ getFullName(evaluacionProductividad.Personal) }}</div>
                                            <div class="black--text text-caption text-secondary">{{ evaluacionProductividad.Personal.CUIP }}</div>
                                            <div class="black--text text-caption text-secondary">
                                                {{ evaluacionProductividad.Personal.CatPuestoDependencia.catPuesto.puesto }}
                                            </div>
                                            <div class="black--text text-caption text-secondary">Operativo</div>
                                        </v-col>
                                        <v-col cols="12" align="center">
                                            <v-icon color="primary" class="mr-4">mdi-calendar</v-icon>
                                            <div class="text-overline black--text d-inline">{{ evaluacionProductividad.fechaInicial }}</div>
                                            <div class="text-body-2 d-inline mx-4">-</div>
                                            <div class="text-overline black--text d-inline ">{{ evaluacionProductividad.fechaFin }}</div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-title>
                            <v-card-text>
                                <v-list color="secondary">
                                    <v-list-item-group v-model="funcionElegida">
                                        <v-list-item :key="funcion.id" v-for="funcion in funciones">
                                            <v-list-item-content>
                                                <v-list-item-title class="black--text">{{ funcion.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                <v-list-item v-on:click="funcionElegida = 4">
                                    <v-list-item-content>
                                        <v-list-item-title class="black--text">Factores de mérito</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card class="card" color="primary">
                            <apexchart height="100%" type="bar" :options="personalInscritoOptions" :series="personalInscritoSeries"></apexchart>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="8" v-if="evaluacionProductividad.niveles.length > 0 && evaluacionProductividad.factores.length > 0">
                <v-card v-bind="cardProps" v-if="funcionElegida < 4">
                    <v-card-title v-bind="cardTitleProps">Indicadores de productividad</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" v-for="(indicador, index) in filterIndicadoresByFuncion" :key="indicador.id">
                                    <v-row>
                                        <v-col cols="5">
                                            <div class="text subtitle-1 black--text">{{ indicador.indicador }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                :value="evaluacionProductividad.niveles[index].realizados"
                                                v-bind="textInputProps"
                                                label="Realizados"
                                                validate-on-blur
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                :value="evaluacionProductividad.niveles[index].CatFrecuencia.title"
                                                v-bind="textInputProps"
                                                label="Frecuencia de productividad"
                                                validate-on-blur
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
                <v-card v-bind="cardProps" v-else>
                    <v-card-title v-bind="cardTitleProps">Factores de mérito</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" v-for="(actoRelevante, index) in actosRelevantes" :key="actoRelevante.id">
                                    <v-row>
                                        <v-col cols="3">
                                            <div class="text subtitle-1 black--text">{{ actoRelevante.title }}</div>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                :value="evaluacionProductividad.factores[index].cantidad"
                                                v-bind="textInputProps"
                                                label="Cantidad"
                                                :rules="required"
                                                validate-on-blur
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                :value="evaluacionProductividad.factores[index].descripcion"
                                                v-bind="textInputProps"
                                                label="Descripción"
                                                :rules="required"
                                                validate-on-blur
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, textInputProps, circularProgressProps } from '../../components/props/props'
    import moment from 'moment'

    export default {
        async mounted() {
            await this.setNiveles()
            await this.setFactoresMerito()
        },

        props: {
            evaluacionProductividad: {
                type: Object,
            },
        },

        data() {
            return {
                cardTitleProps,
                cardActionsProps,
                primaryButtonProps,
                cardProps,
                textInputProps,
                circularProgressProps,

                loading: false,
                funcionElegida: 0,
                activeStep: 1,

                personalInscritoOptions: {
                    chart: {
                        id: 'vuechart-example',
                    },

                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: ['Excelente', 'Sobresaliente', 'Satisfactorio', 'Insuficiente', 'No satisfactorio', 'No aplica'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#F5B940',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },
                personalInscritoSeries: [
                    {
                        type: 'column',
                        name: 'Total de inscritos',
                        data: [19, 5, 7, 3, 0, 2],
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('productividad', {
                productividad: 'getProductividad',
            }),
            ...mapGetters('funcion', {
                funciones: 'getFunciones',
            }),
            ...mapGetters('indicador', {
                indicadores: 'getIndicadores',
            }),
            ...mapGetters('actoRelevante', {
                actosRelevantes: 'getActosRelevantes',
            }),
            ...mapGetters('factorMerito', {
                factoresMerito: 'getFactoresMerito',
            }),
            ...mapGetters('frecuenciaProductividad', {
                frecuenciaProductividad: 'getFrecuenciaProductividad',
            }),

            filterIndicadoresByFuncion: function() {
                return this.indicadores.filter(indicador => {
                    return indicador.fk_idCatFuncion == this.funcionElegida + 1
                })
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de aplicación'
                    case 2:
                        return 'Período de evaluación'
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 4) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            fechaInicio: function() {
                if (this.periodoEvaluacion[0]) {
                    if (moment(this.periodoEvaluacion[1]).isBefore(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.periodoEvaluacion[1]) {
                    if (moment(this.periodoEvaluacion[1]).isAfter(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.fechaAplicacionForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.periodoEvaluacionForm) {
                            return true
                        } else {
                            return false
                        }
                }
            },
        },

        methods: {
            ...mapActions('productividad', ['fetchProductividad']),

            getRealizados: function(id_indicador) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                return this.productividadTest.niveles[index].realizados
            },

            setRealizados: function(id_indicador, e) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                if (isNaN(e)) {
                    this.productividadTest.niveles[index].realizados = null
                } else {
                    this.productividadTest.niveles[index].realizados = parseFloat(e)
                }
            },

            getFrecuencia: function(id_indicador) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                return this.productividadTest.niveles[index].fk_idCatFrecuencia
            },

            setFrecuencia: function(id_indicador, e) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                this.productividadTest.niveles[index].fk_idCatFrecuencia = e
            },

            getCantidadActos: function(id_acto_relevante) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                return this.productividadTest.factores[index].cantidad
            },

            setCantidadActos: function(id_acto_relevante, e) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                this.productividadTest.factores[index].cantidad = parseFloat(e)
            },

            getDescripcionActos: function(id_acto_relevante) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                return this.productividadTest.factores[index].descripcion
            },

            setDescripcionActos: function(id_acto_relevante, e) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                this.productividadTest.factores[index].descripcion = e
            },

            setNiveles: function() {
                this.indicadores.forEach(indicador => {
                    this.productividadTest.niveles.push({
                        realizados: null,
                        fk_idCatFrecuencia: null,
                        fk_idCatIndicador: indicador.id,
                    })
                })
            },

            setFactoresMerito: function() {
                this.actosRelevantes.forEach(factor => {
                    this.productividadTest.factores.push({
                        fk_idActorRelevante: factor.id,
                        cantidad: null,
                        descripcion: '',
                    })
                })
            },

            triggerSaveEvaluacionProductividad: async function() {
                this.loading = true
                setTimeout(() => this.activeStep++, 2000)

                await this.saveProductividad({
                    idPersonal: this.personal.idPersonal,
                    puntajeTotal: 60,
                    calificacion: '8',
                    fechaAplicacion: moment(this.productividadTest.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fkidPersonalEvaluador: 1,
                    fkidResultado: 1,
                    niveles: this.productividadTest.niveles,
                    factores: this.productividadTest.factores,
                })
            },
        },
    }
</script>
