import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        clasesArma: [],
        clasesArmaCorta: [],
        clasesArmaLarga: [],
    },
    getters: {
        getClasesArma: state => {
            return state.clasesArma
        },

        getClasesArmaCorta: state => {
            return state.clasesArmaCorta
        },

        getClasesArmaLarga: state => {
            return state.clasesArmaLarga
        },
    },
    mutations: {
        SET_CLASES_ARMA: (state, clasesArma) => {
            state.clasesArma = clasesArma
        },

        SET_CLASES_ARMA_CORTA: (state, clasesArmaCorta) => {
            state.clasesArmaCorta = clasesArmaCorta
        },

        SET_CLASES_ARMA_LARGA: (state, clasesArmaLarga) => {
            state.clasesArmaLarga = clasesArmaLarga
        },
    },
    actions: {
        fetchClasesArma: async function({ commit }, tipoArma) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoArma/' + tipoArma + '/claseArma')
                .then(response => {
                    commit('SET_CLASES_ARMA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchClasesArmaCorta: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoArma/2/claseArma')
                .then(response => {
                    commit('SET_CLASES_ARMA_CORTA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchClasesArmaLarga: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoArma/1/claseArma')
                .then(response => {
                    commit('SET_CLASES_ARMA_LARGA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
