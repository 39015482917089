<template>
    <v-container class="transparent">
        <v-row>
            <v-col cols="4">
                <div class="glass-card">
                    <v-card class="transparent" elevation="16">
                        <v-card-title class="font-weight-bold justify-center">LOC's registradas por mes</v-card-title>
                        <v-card-text>
                            <apexchart width="100%" type="area" :options="options" :series="series"></apexchart>
                        </v-card-text>
                        <v-card-actions class="pa-4">
                            <v-spacer></v-spacer>
                            <v-btn icon class="secondary--text" to="/chartExample" outlined><v-icon>mdi-cube-outline</v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="4">
                <v-row>
                    <v-col cols="12">
                        <div class="glass-card">
                            <v-card class="transparent" elevation="8">
                                <v-card-title class="font-weight-bold justify-center">Estatus general de armas</v-card-title>
                                <v-card-text>
                                    <apexchart height="100%" type="bar" :options="options2" :series="series2"></apexchart>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="glass-card">
                            <v-card class="transparent">
                                <v-card-title class="font-weight-bold justify-center">Gráfica 3</v-card-title>
                                <v-card-text>
                                    <apexchart width="100%" type="donut" :options="options3" :series="series3"></apexchart>
                                </v-card-text>
                                <v-card-actions class="pa-4 justify-center">
                                    <v-btn class="secondary primary--text elevation-8">Ver detalles</v-btn>
                                </v-card-actions>
                            </v-card>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="glass-card" style="height:100%">
                            <v-card class="transparent" height="100%">
                                <v-card-title class="font-weight-bold justify-center">Personal activo</v-card-title>
                                <v-card-text>
                                    <apexchart width="100%" type="radialBar" :options="options4" :series="series4"></apexchart>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <div class="glass-card" style="height:100%">
                    <v-card color="transparent" height="100%">
                        <v-card-title class="font-weight-bold justify-center">Ubicación de elementos</v-card-title>
                        <v-card-text style="height:80%">
                            <map-component></map-component>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="6">
                <v-card color="primary" class="card" height="100%">
                    <v-card-title class="font-weight-bold justify-center">Últimos movimientos</v-card-title>
                    <v-card-text>
                        <v-list color="primary" class="inset-card">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-ammunition</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 45 min</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>Se asignaron 50 municiones al elemento Gustavo Mitre</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-book</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 2 hrs</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>Se asignaron 50 municiones al elemento Gustavo Mitre</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-pistol</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 10 hrs</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>Se asignaron 50 municiones al elemento Gustavo Mitre</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-ammunition</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 1 día</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>Se asignaron 50 municiones al elemento Gustavo Mitre</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-row>
                    <v-col cols="6">
                        <v-card color="primary" class="card">
                            <v-card-title class="font-weight-bold justify-center">Personal con más armas</v-card-title>
                            <v-card-text>
                                <v-list-item class="dashboard-list">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>John Doe</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card color="primary" class="card">
                            <v-card-title class="font-weight-bold justify-center">Ubicación con más elementos activos</v-card-title>
                            <v-card-text>
                                <v-list-item class="dashboard-list">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-map-marker</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>Ubicación A</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn text>Ver en mapa</v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card color="primary" class="card">
                            <v-card-title class="font-weight-bold justify-center">Municiones disponibles</v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="3" align="center">
                                            <div class="text-caption">ARMAS CORTAS</div>
                                            <v-card class="inset-card">
                                                <v-card-text align="center">
                                                    <v-avatar>
                                                        <v-icon>mdi-pistol</v-icon>
                                                    </v-avatar>
                                                    <v-avatar rounded>
                                                        <div class="text-h6 font-weight-bold">62</div>
                                                    </v-avatar>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="3" align="center">
                                            <div class="text-caption">ARMAS LARGAS</div>
                                            <v-card class="inset-card">
                                                <v-card-text align="center">
                                                    <v-avatar>
                                                        <v-icon>mdi-pistol</v-icon>
                                                    </v-avatar>
                                                    <v-avatar rounded>
                                                        <div class="text-h6 font-weight-bold">62</div>
                                                    </v-avatar>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="3" align="center">
                                            <div class="text-caption">MUNICION X</div>
                                            <v-card class="inset-card">
                                                <v-card-text align="center">
                                                    <v-avatar>
                                                        <v-icon>mdi-ammunition</v-icon>
                                                    </v-avatar>
                                                    <v-avatar rounded>
                                                        <div class="text-h6 font-weight-bold">62</div>
                                                    </v-avatar>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="3" align="center">
                                            <div class="text-caption">MUNICION Y</div>
                                            <v-card class="inset-card">
                                                <v-card-text align="center">
                                                    <v-avatar>
                                                        <v-icon>mdi-ammunition</v-icon>
                                                    </v-avatar>
                                                    <v-avatar rounded>
                                                        <div class="text-h6 font-weight-bold">62</div>
                                                    </v-avatar>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    // import LoaderComponent from '../components/LoaderComponent'
    import MapComponent from '../../components/MapComponent'

    export default {
        components: {
            // LoaderComponent,
            MapComponent,
        },

        data() {
            return {
                items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                options: {
                    chart: {
                        id: 'vuechart-example',
                    },

                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: ['Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic', 'Ene'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },
                series: [
                    {
                        type: 'line',
                        name: 'Licencias registradas',
                        data: [40, 80, 45, 64, 74, 20, 57, 32],
                    },
                    {
                        type: 'column',
                        name: 'Total de armas',
                        data: [30, 40, 45, 50, 49, 60, 70, 91],
                    },
                ],

                options2: {
                    chart: {
                        type: 'bar',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: ['Activas', 'Inactivas'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                series2: [
                    {
                        type: 'column',
                        data: [460, 80],
                    },
                ],

                options3: {
                    chart: {
                        type: 'donut',
                    },
                    labels: ['Armas', 'Municiones'],
                    stroke: {
                        lineCap: 'round',
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                series3: [70, 10],

                options4: {
                    series: [67],
                    colors: ['#20E647'],
                    plotOptions: {
                        radialBar: {
                            startAngle: -60,
                            endAngle: 60,
                            track: {
                                background: '#333',
                                startAngle: -60,
                                endAngle: 60,
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    fontSize: '30px',
                                    show: true,
                                },
                            },
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: 'horizontal',
                            gradientToColors: ['#87D4F9'],
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: 'round',
                    },
                    labels: ['Progress'],

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                series4: [62],
            }
        },
    }
</script>
