import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        ramas: [],
    },

    getters: {
        getRamas: state => {
            return state.ramas
        },
    },

    mutations: {
        SET_RAMAS(state, ramas) {
            state.ramas = ramas
        },
    },

    actions: {
        fetchRamas: async function({ commit }) {
            await axios
                .get('/EonCloud/common/ramas')
                .then(response => {
                    commit('SET_RAMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchRamasByDependencia: async function({ commit }, dependencia) {
            await axios
                .get('/EonCloud/common/dependencias/' + dependencia + '/ramas')
                .then(response => {
                    commit('SET_RAMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
