import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        condecoraciones: [],
    },

    getters: {
        getCondecoraciones: state => {
            return state.condecoraciones
        },
    },

    mutations: {
        SET_CONDECORACIONES(state, condecoraciones) {
            state.condecoraciones = condecoraciones
        },
    },

    actions: {
        fetchCondecoraciones: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/condecoraciones')
                .then(response => {
                    commit('SET_CONDECORACIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
