<template>
    <v-dialog persistent width="90%" v-model="active">
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación de desempeño institucional</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="desempenioInstitucional">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Personal evaluador"
                                                    :value="getFullName(desempenioInstitucional.PersonalEvaluador)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Municipio"
                                                    :value="desempenioInstitucional.CatMunicipio.nombre"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="desempenioInstitucional.fechaInicial"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="desempenioInstitucional.fechaFin"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="desempenioInstitucional.fechaAplicacion"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Institución de seguridad pública a la que aspira"
                                                    v-model="desempenioInstitucional.institucionSeguridadAspira"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Aspirante al puesto de"
                                                    v-model="desempenioInstitucional.aspiranteAlPuesto"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Reactivos</div>
                                </v-card-title>
                                <v-card-text v-if="desempenioInstitucional.frecuenciasMedicion.length > 0">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(reactivo, index) in reactivos" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        {{ index + 1 }}
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <div class="text-subtitle-2">{{ reactivo.CatReactivo.title }}</div>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            label="Frecuencia de medición"
                                                            v-model="desempenioInstitucional.frecuenciasMedicion[index].fk_idCatFrecuencia"
                                                            rounded
                                                            filled
                                                            dense
                                                            :items="frecuencia"
                                                            item-value="id"
                                                            item-text="title"
                                                            item-color="secondary"
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Observaciones</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea
                                                    label="Observaciones"
                                                    v-model="desempenioInstitucional.observaciones"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    no-resize
                                                    rows="3"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="5">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Resultados</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Resultado</div>
                                                        <div class="text-subtitle-1">{{ getResultado }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Puntaje total</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Calificación</div>
                                                        <div class="text-subtitle-1">{{ getCalificacion }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveEvaluacionDesempeñoInstitucional()" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            idPersonal: {
                type: Number,
            },

            active: {
                type: Boolean,
            },

            desempenioInstitucional: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,

                menuFechaAplicacion: false,
                menuFechaInicio: false,
                menuFechaFin: false,
                menuFecha: [],
            }
        },

        computed: {
            ...mapGetters('periodicidad', {
                periodicidad: 'getPeriodicidad',
            }),

            ...mapGetters('reactivosEvaluacion', {
                reactivos: 'getReactivosDesempenioInstitucional',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuencia: 'getFrecuenciaRespetoPrincipio',
            }),

            ...mapGetters('resultado', {
                resultado: 'getResultado',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            getResultado: function() {
                // TODO: Ligar resultado con objeto a actualizar, ya que siempre lo deja con resultado Excelente
                if (this.getPuntajeTotal <= 26) {
                    this.desempenioInstitucional.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal > 26 && this.getPuntajeTotal < 31) {
                    this.desempenioInstitucional.fk_idResultado = 4
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal > 30 && this.getPuntajeTotal < 36) {
                    this.desempenioInstitucional.fk_idResultado = 3
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal > 35 && this.getPuntajeTotal < 40) {
                    this.desempenioInstitucional.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal > 39) {
                    this.desempenioInstitucional.fk_idResultado = 1
                    return 'Excelente'
                }
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0
                if (this.desempenioInstitucional) {
                    this.desempenioInstitucional.frecuenciasMedicion.forEach(reactivo => {
                        switch (reactivo.fk_idCatFrecuencia) {
                            case 1:
                                puntajeTotal = puntajeTotal + 4
                                break
                            case 2:
                                puntajeTotal = puntajeTotal + 3
                                break
                            case 3:
                                puntajeTotal = puntajeTotal + 2
                                break
                            case 4:
                                puntajeTotal = puntajeTotal + 1

                                break
                            case 5:
                                puntajeTotal = puntajeTotal + 0
                                break
                            default:
                                break
                        }
                    })
                }

                return puntajeTotal
            },

            getCalificacion: function() {
                if (this.getPuntajeTotal == 0) {
                    return '0'
                } else if (this.getPuntajeTotal > 1 && this.getPuntajeTotal < 27) {
                    return '5'
                } else if (this.getPuntajeTotal > 26 && this.getPuntajeTotal < 31) {
                    return '6'
                } else if (this.getPuntajeTotal > 30 && this.getPuntajeTotal < 36) {
                    return '7'
                } else if (this.getPuntajeTotal > 35 && this.getPuntajeTotal < 40) {
                    return '8'
                } else if (this.getPuntajeTotal > 39 && this.getPuntajeTotal < 43) {
                    return '9'
                } else if (this.getPuntajeTotal > 42) {
                    return '10'
                }
            },
        },

        methods: {
            ...mapActions('desempeñoInstitucional', ['updateDesempeñoInstitucional', 'updateEstatus']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            triggerSaveEvaluacionDesempeñoInstitucional: async function() {
                this.loading = true

                this.desempenioInstitucional.puntajeTotal = this.getPuntajeTotal
                this.desempenioInstitucional.calificacion = this.getCalificacion
                await this.updateDesempeñoInstitucional(this.desempenioInstitucional)
                await this.updateEstatus(this.desempenioInstitucional)
                await this.fetchEvaluacionesById(this.desempenioInstitucional.fk_idPersonalEvaluador)

                this.closeDialog()
            },

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },
        },
    }
</script>
