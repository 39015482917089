import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estadoCivil: [],
    },
    getters: {
        getEstadoCivil: state => {
            return state.estadoCivil
        },
    },
    mutations: {
        SET_ESTADO_CIVIL(state, estadoCivil) {
            state.estadoCivil = estadoCivil
        },
    },
    actions: {
        fetchEstadoCivil: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/estadoCivil')
                .then(response => {
                    commit('SET_ESTADO_CIVIL', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
