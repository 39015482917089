<template>
    <v-dialog persistent width="90%" v-model="active">
        <v-card class="rounded-sm">
            <v-card-title>
                <div class="text-h6 font-weight-bold">Evaluación de respeto a los principios</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="respetoPrincipios">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="Personal evaluador"
                                                    :value="getFullName(respetoPrincipios.PersonalEvaluador)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    label="Personal evaluador"
                                                    :value="getFullName(respetoPrincipios.Personal)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Trimestre"
                                                    :value="respetoPrincipios.CatPeriodicidad.title"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="respetoPrincipios.fechaInicial"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="respetoPrincipios.fechaFin"
                                                    label="Fecha fin"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="respetoPrincipios.fechaAplicacion"
                                                    label="Fecha aplicación"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Reactivos</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container v-if="respetoPrincipios.frecuenciasMedicion.length > 0">
                                        <v-row>
                                            <v-col cols="12" v-for="(reactivo, index) in reactivos" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        {{ index + 1 }}
                                                    </v-col>
                                                    <v-col cols="7">
                                                        <div class="text-subtitle-2 font-weight-black">{{ reactivo.CatPrincipio.title }}</div>
                                                        <div class="text-subtitle-2">{{ reactivo.CatReactivo.title }}</div>
                                                    </v-col>
                                                    <v-col cols="3">
                                                        <v-select
                                                            label="Elija una frecuencia de medición"
                                                            :value="respetoPrincipios.frecuenciasMedicion[index]"
                                                            @input="setFrecuencia(reactivo.id, $event)"
                                                            solo
                                                            :items="frecuencia"
                                                            item-value="id"
                                                            item-text="title"
                                                            item-color="secondary"
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col cols="1">
                                                        <v-chip
                                                            small
                                                            label
                                                            color="success"
                                                            v-if="respetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia"
                                                            >Elegido</v-chip
                                                        >
                                                        <v-chip small label color="error" v-else>Pendiente</v-chip>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Observaciones</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea
                                                    label="Observaciones"
                                                    v-model="respetoPrincipios.observaciones"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    no-resize
                                                    rows="3"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="5">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Resultados</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Resultado</div>
                                                        <div class="text-subtitle-1">{{ getResultado }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Puntaje total</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Calificación</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal / 10 }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveEvaluacionRespetoPrincipios()" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            respetoPrincipios: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('reactivoPrincipio', {
                reactivos: 'getReactivoPrincipio',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuencia: 'getFrecuenciaRespetoPrincipio',
            }),

            ...mapGetters('resultado', {
                resultado: 'getResultado',
            }),

            getResultado: function() {
                if (this.getPuntajeTotal < 31) {
                    this.respetoPrincipios.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal < 63) {
                    this.respetoPrincipios.fk_idResultado = 4
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal < 76) {
                    this.respetoPrincipios.fk_idResultado = 3
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal < 88) {
                    this.respetoPrincipios.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal <= 100) {
                    this.respetoPrincipios.fk_idResultado = 1
                    return 'Excelente'
                }
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0
                this.respetoPrincipios.frecuenciasMedicion.forEach(reactivo => {
                    switch (reactivo.fk_idCatFrecuencia) {
                        case 1:
                            puntajeTotal = puntajeTotal + 4
                            break
                        case 2:
                            puntajeTotal = puntajeTotal + 3
                            break
                        case 3:
                            puntajeTotal = puntajeTotal + 2
                            break
                        case 4:
                            puntajeTotal = puntajeTotal + 1

                            break
                        case 5:
                            puntajeTotal = puntajeTotal + 0
                            break
                        default:
                            break
                    }
                })

                return puntajeTotal
            },

            valid: function() {
                if (this.respetoPrincipios.frecuenciasMedicion.some(reactivo => reactivo.fk_idCatFrecuencia == null)) {
                    return false
                } else return true
            },
        },

        methods: {
            ...mapActions('respetoPrincipios', ['updateRespetoPrincipios', 'updateEstatusRespetoPrincipios']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            triggerSaveEvaluacionRespetoPrincipios: async function() {
                this.loading = true

                this.respetoPrincipios.puntajeTotal = this.getPuntajeTotal
                this.respetoPrincipios.calificacion = (this.getPuntajeTotal / 10).toString()
                this.respetoPrincipios.observaciones = this.respetoPrincipios.observaciones
                await this.updateRespetoPrincipios(this.respetoPrincipios)
                await this.updateEstatusRespetoPrincipios(this.respetoPrincipios)

                await this.fetchEvaluacionesById(this.respetoPrincipios.fk_idPersonalEvaluador)

                this.closeDialog()
            },

            closeDialog() {
                this.loading = false

                this.$emit('close')
            },

            setFrecuencia: function(id_reactivo, e) {
                const index = this.respetoPrincipios.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idReactivoPrincipio == id_reactivo)
                this.respetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia = e
            },
        },
    }
</script>
