import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        permutaciones: [],
    },

    getters: {
        getPermutaciones: state => {
            return state.permutaciones
        },
    },

    mutations: {
        SET_PERMUTACIONES(state, permutaciones) {
            state.permutaciones = permutaciones
        },
    },

    actions: {
        fetchPermutacionesByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/permutaciones')
                .then(response => {
                    commit('SET_PERMUTACIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
