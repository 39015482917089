import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        items: [],
    },

    getters: {
        getItems: state => {
            return state.items
        },
    },

    mutations: {
        SET_ITEMS: (state, items) => {
            state.items = items
        },
    },

    actions: {
        fetchItems: async function({ commit }, idTipoItem) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoItem/' + idTipoItem + '/items')
                .then(response => {
                    commit('SET_ITEMS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveItem: async function(context, item) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/items/', item)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Item registrado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateItem: async function(context, item) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/items/' + item.id, item)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Item editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
