import moment from 'moment'
import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        disciplinaOperativa: [],
    },

    getters: {
        getDisciplinaOperativa: state => {
            return state.disciplinaOperativa
        },
    },

    mutations: {
        SET_DISCIPLINA_OPERATIVA(state, disciplinaOperativa) {
            state.disciplinaOperativa = disciplinaOperativa
        },
    },

    actions: {
        fetchDisciplinaOperativa: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/disciplinaOperativa')
                .then(response => {
                    commit('SET_DISCIPLINA_OPERATIVA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveDisciplinaOperativa: async function({ commit }, disciplinaOperativa) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + disciplinaOperativa.fk_idPersonal + '/disciplinaOperativa', disciplinaOperativa)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateDisciplinaOperativa: async function(context, disciplinaOperativa) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + disciplinaOperativa.fk_idPersonal + '/disciplinaOperativa/' + disciplinaOperativa.id,
                    disciplinaOperativa
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación de disciplina operativa guardada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatus: async function(context, disciplinaOperativa) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        disciplinaOperativa.fk_idPersonal +
                        '/disciplinaOperativa/' +
                        disciplinaOperativa.id +
                        '/estatus',
                    { fk_idCatEstatusEvaluacion: 3 }
                )
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
