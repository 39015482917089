import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        condecoracionPersonal: [],
    },

    getters: {
        getCondecoracionPersonal: state => {
            return state.condecoracionPersonal
        },
    },

    mutations: {
        SET_CONDECORACION_PERSONAL(state, condecoracionPersonal) {
            state.condecoracionPersonal = condecoracionPersonal
        },
    },

    actions: {
        fetchCondecoracionPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/condecoracionPersonal')
                .then(response => {
                    commit('SET_CONDECORACION_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCondecoracionPersonal: async function(context, condecoracionPersonal) {
            await axios
                .post(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + condecoracionPersonal.fk_idPersonal + '/condecoracionPersonal',
                    condecoracionPersonal
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Condecoración registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateCondecoracionPersonal: async function(context, condecoracionPersonal) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        condecoracionPersonal.fk_idPersonal +
                        '/condecoracionPersonal/' +
                        condecoracionPersonal.idCondecoracion,
                    condecoracionPersonal
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Condecoración editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
