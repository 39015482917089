import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        seguimientos: [],
    },

    getters: {
        getSeguimientos: state => {
            return state.seguimientos
        },
    },

    mutations: {
        SET_SEGUIMIENTOS(state, seguimientos) {
            state.seguimientos = seguimientos
        },
    },

    actions: {
        fetchSeguimientos: async function({ commit }) {
            await axios
                .get('/EonCloud/seguimientos')
                .then(response => {
                    commit('SET_SEGUIMIENTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
