import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        procedimiento: [],
    },

    getters: {
        getProcedimiento: state => {
            return state.procedimiento.sort(function(a, b) {
                return a.id - b.id
            })
        },
    },

    mutations: {
        SET_PROCEDIMIENTO(state, procedimiento) {
            state.procedimiento = procedimiento
        },
    },

    actions: {
        fetchProcedimiento: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/procedimiento')
                .then(response => {
                    commit('SET_PROCEDIMIENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
