<template>
    <div>
        <v-btn rounded color="secondary" class="mb-2" @click="generateReport()">Descargar referencias</v-btn>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Referencias"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            :html-to-pdf-options="options"
        >
            <section slot="pdf-content">
                <section class="pdf-item" v-for="tipoReferencia in tipoReferencias" :key="tipoReferencia.id">
                    <v-container class="font">
                        <v-row>
                            <v-col cols="12" v-if="filteredReferenciasByTipo(tipoReferencia.id).length > 0">
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-h5">TIPO DE REFERENCIA(S): {{ tipoReferencia.title }}</div>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                v-for="referencia in filteredReferenciasByTipo(tipoReferencia.id)"
                                                :key="referencia.idReferencia"
                                                class="bordered"
                                            >
                                                <div>Nombre: {{ referencia.Nombre }}</div>
                                                <div>Teléfono: {{ referencia.telefono }}</div>
                                                <div>Ocupación: {{ referencia.CatOcupacion.title }}</div>
                                                <div>Años de conocerse: {{ referencia.tiempoConocerse }}</div>
                                                <br />
                                                <div>DIRECCIÓN</div>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="3">Calle: </v-col>
                                                            <v-col v-if="referencia.Direccion.calle !== ''">{{ referencia.Direccion.calle }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Entre calle: </v-col>
                                                            <v-col v-if="referencia.Direccion.entrecalles !== ''">{{
                                                                referencia.Direccion.entrecalles
                                                            }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Entre calle 2: </v-col>
                                                            <v-col v-if="referencia.Direccion.entrecalles2 !== ''">{{
                                                                referencia.Direccion.entrecalles2
                                                            }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Número exterior: </v-col>
                                                            <v-col v-if="referencia.Direccion.ext !== ''">{{ referencia.Direccion.ext }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Número interior: </v-col>
                                                            <v-col v-if="referencia.Direccion.int !== ''">{{ referencia.Direccion.int }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Colonia: </v-col>
                                                            <v-col v-if="referencia.Direccion.colonia !== ''">{{ referencia.Direccion.colonia }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Código postal: </v-col>
                                                            <v-col v-if="referencia.Direccion.cp !== ''">{{ referencia.Direccion.cp }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Municipio: </v-col>
                                                            <v-col v-if="referencia.Direccion.CatMunicipio">{{
                                                                referencia.Direccion.CatMunicipio.nombre
                                                            }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="3">Estado: </v-col>
                                                            <v-col v-if="referencia.Direccion.CatMunicipio">{{
                                                                referencia.Direccion.CatMunicipio.catEstado.title
                                                            }}</v-col>
                                                            <v-col v-else>DATO NO REGISTRADO</v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </section>
            </section>
        </vue-html2pdf>
    </div>
</template>
<style scoped>
    .bordered {
        border: 1px solid black;
    }
    .font {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
    }
</style>
<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            VueHtml2pdf,
        },

        props: {
            referencias: {
                type: Array,
            },
        },

        data() {
            return {
                options: {},
            }
        },

        computed: {
            ...mapGetters('tipoReferencia', {
                tipoReferencias: 'getTipoReferencia',
            }),
        },

        methods: {
            generateReport() {
                this.$refs.html2Pdf.generatePdf()
            },

            filteredReferenciasByTipo(id_tipo_referencia) {
                return this.referencias.filter(referencia => {
                    return referencia.fk_idCatTipoReferencia == id_tipo_referencia
                })
            },
        },
    }
</script>
