<template>
    <v-main class="primary">
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading"></loader-component>
            <router-view v-else></router-view>
        </transition>
    </v-main>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import LoaderComponent from '../../components/LoaderComponent.vue'

    export default {
        components: {
            LoaderComponent,
        },

        async created() {
            this.ENABLE_LOADER()
            this.$vuetify.theme.themes.light.primary = '#f2fff8'
            this.$vuetify.theme.themes.light.secondary = '#34A871'
            this.$vuetify.theme.themes.light.accent = '#F58634'
            this.$vuetify.theme.themes.light.anchor = '#34A871'
            // await this.fetchPersonal()
            await this.fetchPreaspirantes()
            await this.fetchCandidatos()
            await this.fetchAspirantes()

            await this.fetchCursos()
            await this.fetchCapacitadores()
            await this.fetchAulas()
            await this.fetchCursosProgramados()
            await this.fetchCursosAsignados()
            await this.fetchEstatusCurso()
            await this.fetchEstatusCursoPersonal()
            await this.fetchClassroomStatus()
            await this.fetchCourseTypes()
            await this.fetchSugerenciaCursos()

            await this.fetchMunicipios()
            await this.fetchEstados()
            await this.fetchEstadoCivil()
            await this.fetchTipoNacionalidad()
            await this.fetchSexo()
            await this.fetchGrupoSanguineo()

            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
            }),
        },

        methods: {
            ...mapActions('capacitador', ['fetchCapacitadores']),
            ...mapActions('aula', ['fetchAulas']),
            ...mapActions('curso', ['fetchCursos']),
            ...mapActions('cursoProgramado', ['fetchCursosProgramados']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignados']),
            ...mapActions('categoriaCurso', ['fetchCategoriasCursos']),
            ...mapActions('estatusCurso', ['fetchEstatusCurso']),
            ...mapActions('estatusCursoPersonal', ['fetchEstatusCursoPersonal']),
            ...mapActions('estatusAula', ['fetchClassroomStatus']),
            ...mapActions('tipoCurso', ['fetchCourseTypes']),
            ...mapActions('personal', ['fetchPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes', 'fetchAspirantes']),
            ...mapActions('candidato', ['fetchCandidatos']),
            ...mapActions('sugerenciaCursos', ['fetchSugerenciaCursos']),

            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('sexo', ['fetchSexo']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),
        },
    }
</script>
