import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        respetoPrincipios: [],
        respetoPrincipiosSingle: null,
    },

    getters: {
        getRespetoPrincipios: state => {
            return state.respetoPrincipios
        },

        getRespetoPrincipiosSingle: state => {
            return state.respetoPrincipiosSingle
        },
    },

    mutations: {
        SET_RESPETO_PRINCIPIOS(state, respetoPrincipios) {
            state.respetoPrincipios = respetoPrincipios
        },

        SET_RESPETO_PRINCIPIOS_SINGLE(state, respetoPrincipiosSingle) {
            state.respetoPrincipiosSingle = respetoPrincipiosSingle[0]
        },
    },

    actions: {
        fetchRespetoPrincipiosById: async function({ commit }, evaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacion.fkidPersonal + '/respetoprincipios/' + evaluacion.idRespetoPrincipios)
                .then(response => {
                    commit('SET_RESPETO_PRINCIPIOS_SINGLE', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchRespetoPrincipios: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/respetoprincipios')
                .then(response => {
                    commit('SET_RESPETO_PRINCIPIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveRespetoPrincipios: async function({ commit }, respetoPrincipios) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + respetoPrincipios.fk_idPersonal + '/respetoprincipios', respetoPrincipios)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateRespetoPrincipios: async function(context, respetoPrincipios) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + respetoPrincipios.fk_idPersonal + '/respetoprincipios/' + respetoPrincipios.id,
                    respetoPrincipios
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatusRespetoPrincipios: async function(context, respetoPrincipios) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        respetoPrincipios.fk_idPersonal +
                        '/respetoprincipios/' +
                        respetoPrincipios.id +
                        '/estatus',
                    { fk_idCatEstatusEvaluacion: 3 }
                )
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
