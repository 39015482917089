<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Registro de LOC</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 20" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <v-form v-model="validSecondStepForm">
                                            <v-row>
                                                <v-col cols="7">
                                                    <v-text-field
                                                        label="Número de licencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        v-model="loc.noLicencia"
                                                        :rules="required"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-text-field
                                                        label="Año de vigencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        maxlength="4"
                                                        rounded
                                                        filled
                                                        dense
                                                        v-model="loc.vigencia"
                                                        :rules="required"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-date-picker v-model="loc.fechaAlta" locale="es" color="secondary" no-title full-width></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="R.M."
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.rm"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="Z.M."
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.zm"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Instancia"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.nombreInstancia"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Titular de la licencia"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.titular"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Representante legal"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.representanteLegal"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Dirección"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    v-model="loc.direccion"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                activeStep: 1,
                loading: false,
                cuip: '',
                successfulCuipSearch: null,
                selectedPersonal: null,
                locRegistrationDate: '',
                validSecondStepForm: false,
                searchWeapon: '',
                armasElegidas: [],

                loc: {
                    direccion: 'Av. Siempre Viva #123',
                    noLicencia: '',
                    nombreInstancia: 'Seguridad Pública',
                    representanteLegal: 'Esteban Rogelio',
                    rm: 'VI REG',
                    titular: 'Tadeo Rosas',
                    vigencia: '',
                    zm: '23/a Z.M.',
                    fechaAlta: null,
                    fechaTramite: null,
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Número de licencia'
                    case 2:
                        return 'Fecha de alta'
                    case 3:
                        return 'Información de instancia'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.validSecondStepForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.loc.fechaAlta != '' && this.loc.fechaAlta) {
                            return true
                        } else {
                            return false
                        }
                    case 3:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar loc'
                }
            },
        },

        methods: {
            ...mapActions('licenciaOficialColectiva', ['saveLoc']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.loc.fechaAlta = moment(this.loc.fechaAlta).format('DD/MM/YYYY')
                    this.loc.fechaTramite = this.loc.fechaAlta

                    await this.saveLoc({
                        loc: this.loc,
                    })

                    this.$router.push('/armeria/loc_personal')
                }
            },
        },
    }
</script>
