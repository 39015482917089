<template>
    <v-container>
        <v-row>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(asignacion, index) in asignacionesEquipo" :key="asignacion.id">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ asignacion.fechaAsignacion }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ asignacion.equipoAsignado.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="item in asignacion.equipoAsignado" :key="item.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-briefcase</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ item.CatItem.item }}</div>
                                                        <div class="text-subtitle-1 primary--text">{{ item.numero }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="8">
                                                        <div class="text-subtitle-1 primary--text">
                                                            Fecha de fabricación:
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <div class="text-subtitle-1 font-weight-bold primary--text">
                                                            {{ item.fechaFabricacion }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <div class="text-subtitle-1 primary--text">
                                                            Vigencia de equipo hasta:
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <div class="text-subtitle-1 font-weight-bold primary--text">
                                                            {{ item.aniosVigencia }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    import { cardProps, cardFormColsProps, cardTitleProps, textInputProps } from '../../components/props/props'
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.fetchAsignacionEquipoByPersonal(this.id)
        },

        data() {
            return {
                cardProps,
                cardTitleProps,
                cardFormColsProps,
                textInputProps,

                searchAsignacion: '',
                buscaItem: '',
                selectedDate: 0,
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('asignacionEquipo', {
                asignacionesEquipo: 'getAsignacionesEquipo',
            }),
        },

        methods: {
            ...mapActions('asignacionEquipo', ['fetchAsignacionEquipoByPersonal']),
        },
    }
</script>
