<template>
    <v-container fill-height>
        <v-row v-if="resultadosPersonal.length == 0" justify="center">
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Buscar personal</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="busquedaPersonal.nombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.segundoNombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Segundo nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.apaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido paterno"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.amaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido materno"
                        ></v-text-field>
                        <v-text-field v-model="busquedaPersonal.curp" color="secondary" background-color="primary" rounded filled label="CURP"></v-text-field>
                        <v-text-field v-model="busquedaPersonal.CUIP" color="secondary" background-color="primary" rounded filled label="CUIP"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-container class="py-0">
                            <v-row justify="center">
                                <v-col cols="8" align="center">
                                    <v-btn
                                        rounded
                                        block
                                        color="secondary"
                                        @click="triggerSearchPersonal()"
                                        :disabled="loading || emptyForm"
                                        :loading="loading"
                                        >Realizar búsqueda</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-row>
                    <v-col cols="4">
                        <v-btn color="secondary" rounded @click="RESET_BUSQUEDA_PERSONAL()">Realizar nueva búsqueda</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                        <v-text-field
                            label="Filtrar elementos"
                            v-model="filtroPersonal"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4" xl="3" v-for="personal in filteredPersonal" :key="personal.id">
                <v-card class="card" color="secondary" elevation="8">
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-avatar rounded color="primary" size="80" v-if="personal.foto.length == 0">
                                        <v-icon x-large color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                    <v-img v-else :src="personal.foto" max-height="80" max-width="80"></v-img>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <div class="text-h6 primary--text font-weight-bold">{{ personal.apaterno }}</div>
                                    <div class="text-subtitle-1 primary--text ">
                                        {{ personal.amaterno + ' ' + personal.nombre + ' ' + personal.segundoNombre }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <div class="text-caption primary--text">Radica en</div>
                                    <div class="text-overline primary--text " v-if="personal.CatMunicipioRadica">
                                        {{ personal.CatMunicipioRadica.nombre + ', ' + personal.CatMunicipioRadica.catEstado.title }}
                                    </div>
                                    <div class="text-overline text--secondary" v-else>
                                        No definido
                                    </div>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <div class="text-caption primary--text">Nació en</div>
                                    <div class="text-overline primary--text " v-if="personal.CatMunicipioNacimiento">
                                        {{ personal.CatMunicipioNacimiento.nombre + ', ' + personal.CatMunicipioNacimiento.catEstado.title }}
                                    </div>
                                    <div class="text-overline text--secondary" v-else>No definido</div>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <div class="text-caption primary--text">Edad</div>
                                    <div class="text-overline primary--text " v-if="personal.fechaNacimiento != ''">
                                        {{ getAge(personal.fechaNacimiento) + ' años' }}
                                    </div>
                                    <div class="text-overline text--secondary" v-else>
                                        No definido
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="secondary darken-1">
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <v-tooltip bottom color="black">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                v-on:click="downloadCartaNoAdeudo(personal.CUIP)"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon>mdi-file-download</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Descargar carta de no adeudo</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="black">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                v-on:click="download(personal.CUIP)"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon>mdi-download</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Descargar resguardo de equipamiento</span>
                                    </v-tooltip>
                                    <!-- <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'verEquipamiento', params: { id: personal.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-briefcase</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Ver equipamiento</span>
                                                        </v-tooltip> -->
                                    <v-tooltip bottom color="black">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{ name: 'asignaEquipamiento', params: { id: personal.idPersonal } }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon>mdi-briefcase-plus</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Asignar equipamiento</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {
        data() {
            return {
                loading: false,
                filtroPersonal: '',
            }
        },

        computed: {
            ...mapGetters('personal', {
                resultadosPersonal: 'getPersonal',
                busquedaPersonal: 'getBusquedaPersonal',
            }),

            ...mapGetters('tipoAmbito', {
                tipoAmbito: 'getTipoAmbito',
            }),

            ...mapGetters('dependencia', {
                dependencias: 'getDependencias',
            }),

            filteredPersonal: function() {
                return this.resultadosPersonal.filter(personal => {
                    return (
                        this.getFullName(personal)
                            .toLowerCase()
                            .indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.CUIP.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.rfc.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.curp.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0
                    )
                })
            },

            emptyForm() {
                if (
                    this.busquedaPersonal.nombre === '' &&
                    this.busquedaPersonal.segundoNombre === '' &&
                    this.busquedaPersonal.apaterno === '' &&
                    this.busquedaPersonal.amaterno === '' &&
                    this.busquedaPersonal.curp === '' &&
                    this.busquedaPersonal.CUIP === ''
                ) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            async triggerSearchPersonal() {
                this.loading = true

                await this.searchPersonal(this.busquedaPersonal)
                if (this.resultadosPersonal.length == 0) {
                    this.SET_SNACKBAR_MESSAGE('Búsqueda sin resultados.')
                }

                this.loading = false
            },

            download: function(id) {
                window.open(
                    'http://ssctlaxcala.eonproduccion.net/tableros/report.php?r=59&u1=' + id + '&m=1&h=5254dbdeb51eb0435529a6af6dbace7362bdf4db',
                    '_blank'
                )
            },

            downloadCartaNoAdeudo: function(cuip) {
                window.open(
                    'http://ssctlaxcala.eonproduccion.net/tableros/report.php?r=68&u1=' + cuip + '&m=1&h=9ae897093104fc9734a959dfb66811f15f7cc110&export=pdf',
                    '_blank'
                )
            },
        },
    }
</script>
