<template>
    <v-dialog v-model="active" width="480" persistent>
        <v-card color="primary" class="rounded-sm" v-if="equipo">
            <v-card-title>
                <div class="text-h6">Editar equipo</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field label="Número" v-model="equipo.numero" rounded filled dense color="secondary" background-color="primary">
                            </v-text-field>
                            <v-menu
                                v-model="menuFechaFabricacion"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="fechaFabricacion"
                                        label="Fecha de fabricación"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="equipo.fechaFabricacion" @input="menuFechaFabricacion = false"></v-date-picker>
                            </v-menu>
                            <v-menu
                                v-model="menuAniosVigencia"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :value="aniosVigencia"
                                        label="Fecha de vigencia"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="equipo.aniosVigencia" @input="menuAniosVigencia = false"></v-date-picker>
                            </v-menu>
                            <v-select
                                v-model="equipo.fk_idCatitem"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                :items="items"
                                item-value="id"
                                item-text="item"
                            >
                                <template v-slot:label>Tipo de item <span class="red--text">*</span> </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="loading" @click="triggerUpdateEquipo()">Editar equipo</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            equipo: {
                type: Object,
            },
        },

        data() {
            return {
                menuFechaFabricacion: false,
                menuAniosVigencia: false,
                loading: false,
            }
        },

        computed: {
            ...mapGetters('item', {
                items: 'getItems',
            }),

            fechaFabricacion: function() {
                return this.equipo.fechaFabricacion ? moment(this.equipo.fechaFabricacion).format('DD/MM/YYYY') : ''
            },

            aniosVigencia: function() {
                return this.equipo.aniosVigencia ? moment(this.equipo.aniosVigencia).format('DD/MM/YYYY') : ''
            },
        },

        methods: {
            ...mapActions('equipo', ['fetchEquipo', 'updateEquipo']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
            },

            async triggerUpdateEquipo() {
                this.loading = true
                await this.updateEquipo(this.equipo)
                await this.fetchEquipo()

                this.closeDialog()
            },
        },
    }
</script>
