import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cursosProgramados: [],
        cursoProgramado: null,
    },

    getters: {
        getCursosProgramados: state => {
            return state.cursosProgramados
        },

        getCursoProgramado: state => {
            return state.cursoProgramado
        },
    },

    mutations: {
        SET_CURSOS_PROGRAMADOS(state, cursosProgramados) {
            state.cursosProgramados = cursosProgramados
        },

        SET_CURSO_PROGRAMADO(state, cursoProgramadoId) {
            var cursoProgramado = state.cursosProgramados.filter(curso => {
                return curso.id == cursoProgramadoId
            })

            var curso = _.cloneDeep(cursoProgramado[0])

            state.cursoProgramado = curso
        },
    },

    actions: {
        fetchCursosProgramados: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/programarcurso')
                .then(response => {
                    commit('SET_CURSOS_PROGRAMADOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCursoProgramado: async function({ commit }, cursoProgramado) {
            console.log(cursoProgramado)
            await axios
                .post('/PoliciaCuadrantes/Profesionalizacion/programarCurso', cursoProgramado)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateCursoProgramado: async function({ commit }, cursoProgramado) {
            await axios
                .put('/PoliciaCuadrantes/Profesionalizacion/programarCurso/' + cursoProgramado.id, cursoProgramado)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
