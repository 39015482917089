import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusCursoPersonal: [],
    },
    getters: {
        getEstatusCursoPersonal: state => {
            return state.estatusCursoPersonal
        },
    },

    mutations: {
        SET_ESTATUS_CURSO_PERSONAL(state, estatusCursoPersonal) {
            state.estatusCursoPersonal = estatusCursoPersonal
        },
    },

    actions: {
        fetchEstatusCursoPersonal: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/estatus')
                .then(response => {
                    commit('SET_ESTATUS_CURSO_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
