import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        evaluacionMedica: [],
    },

    getters: {
        getEvaluacionMedica: state => {
            return state.evaluacionMedica
        },
    },

    mutations: {
        SET_EVALUACION_MEDICA(state, evaluacionMedica) {
            state.evaluacionMedica = evaluacionMedica
        },
    },

    actions: {
        fetchEvaluacionMedicaByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/evaluacionMedica')
                .then(response => {
                    commit('SET_EVALUACION_MEDICA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveEvaluacionMedica: async function(context, evaluacionMedica) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + evaluacionMedica.fk_idPersonal + '/evaluacionMedica', evaluacionMedica)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación médica agendada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEvaluacionMedica: async function(context, evaluacionMedica) {
            await axios
                .put(
                    '/EonCloud/EstadoFuerza/personal/' + evaluacionMedica.fk_idPersonal + '/evaluacionMedica/' + evaluacionMedica.idEvaluacionMedica,
                    evaluacionMedica
                )
                .then(response => {
                    console.log(response.data.data)
                    var formData = new FormData()
                    formData.append('file', evaluacionMedica.file)
                    formData.append('idEvaluacionMedica', evaluacionMedica.idEvaluacionMedica)
                    console.log(formData)
                    context.dispatch('uploadEvaluacionMedica', formData)
                })
                .catch(error => console.log(error))
        },

        uploadEvaluacionMedica: async function(context, formData) {
            await fileAxios
                .post('/EvaluacionMedica/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación médica registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
