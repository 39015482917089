<template>
    <v-container v-if="candidato">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Validar perfil de candidato</v-card-title>
                    <v-card-text>
                        <div class="text-body-1" align="center">
                            ¿Desea validar el perfil del candidato {{ getFullName(candidato) }}? Al realizar este paso, pasará a formar parte de la lista de
                            preaspirantes.
                        </div>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-list color="primary">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title> Información personal </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="informacionPersonalCompleta">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title> Comprobantes de estudio </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="estudiosPersonal.length > 1">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Documentos válidos </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="success" v-if="documentosValidos == 10">mdi-check</v-icon>
                                                <v-icon v-else color="error">mdi-cancel</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-btn
                                        color="secondary"
                                        :to="{
                                            name: 'documentosCandidato',
                                            params: { id: candidato.idPersonal },
                                        }"
                                        >Ver expediente de candidato</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn v-bind="secondaryButtonProps" to="/profesionalizacion/candidatos">Cancelar</v-btn>
                        <v-btn v-bind="primaryButtonProps" v-on:click="validaCandidato()" :disabled="!candidatoValido">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { primaryButtonProps, secondaryButtonProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.fetchEstudiosByPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            this.SET_CANDIDATO_PARA_VALIDACION(this.id)
        },

        data() {
            return {
                primaryButtonProps,
                secondaryButtonProps,
            }
        },

        computed: {
            ...mapGetters('candidato', {
                candidato: 'getCandidatoParaValidacion',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            ...mapGetters('estudio', {
                estudios: 'getEstudios',
            }),

            ...mapGetters('tipoDocumento', {
                tipoDocumento: 'getTipoDocumento',
            }),

            estudiosPersonal: function() {
                if (this.estudios) {
                    return this.estudios.filter(estudio => {
                        return estudio.fk_idPersonal == this.id
                    })
                } else return []
            },

            documentosValidos: function() {
                var documentosValidos = []

                documentosValidos = this.documentos.filter(documento => {
                    if (
                        (documento.fk_idTipoDocumento == 4 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 6 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 17 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 15 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 14 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 10 && documento.Estatus.id == 2) ||
                        (documento.fk_idTipoDocumento == 5 && documento.Estatus.id == 2)
                    )
                        return documento
                })
                return documentosValidos.length
            },

            candidatoValido: function() {
                if (this.documentosValidos == 6 && this.estudiosPersonal.length > 1) {
                    return true
                } else return false
            },

            informacionPersonalCompleta: function() {
                if (
                    this.candidato.nombre != '' &&
                    this.candidato.apaterno != '' &&
                    this.candidato.amaterno != '' &&
                    this.candidato.fechaNacimiento != '' &&
                    this.candidato.fk_idMunicipioNacimiento != 0 &&
                    this.candidato.fk_idMunicipioRadica != 0
                ) {
                    if (this.candidato.Contacto) {
                        if (
                            this.candidato.Contacto.Direccion &&
                            this.candidato.Contacto.correoElectronico != '' &&
                            this.candidato.Contacto.telefonoCelular != '' &&
                            this.candidato.Contacto.telefonoFijo != ''
                        ) {
                            return true
                        }
                    } else {
                        return false
                    }
                } else return false
            },
        },

        methods: {
            ...mapActions('personal', ['updatePersonal']),
            ...mapActions('candidato', ['fetchCandidatos', 'getCandidatoById', 'updateCandidato']),
            ...mapActions('preaspirante', ['fetchPreaspirantes']),
            ...mapMutations('candidato', ['SET_CANDIDATO_PARA_VALIDACION']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal']),

            validaCandidato: async function() {
                this.candidato.fk_idEstatus = 7
                await this.updateCandidato(this.candidato)
                await this.fetchCandidatos()
                await this.fetchPreaspirantes()
                this.$router.push('/profesionalizacion/candidatos')
            },
        },
    }
</script>
