export default {
    namespaced: true,

    state: {
        selectedMenu: 1,
    },

    getters: {
        getSelectedMenu: state => {
            return state.selectedMenu
        },
    },

    mutations: {
        SET_SELECTED_MENU(state, selectedMenu) {
            state.selectedMenu = selectedMenu
        },
    },
}
