<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col v-bind="cardFormColsProps">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">
                        Registro de personal
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 10" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col cols="12" v-if="activeStep == 1" key="1">
                                        <v-alert type="info" color="secondary">
                                            Antes de continuar, es necesario verificar que su CURP no esté registrada en nuestro sistema.
                                        </v-alert>
                                        <v-text-field
                                            v-model="personal.curp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            counter="18"
                                            maxlength="18"
                                            :rules="curpRules"
                                        >
                                            <template v-slot:label>CURP <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="nameForm">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field v-model="personal.nombre" v-bind="textInputProps" :rules="nameRules">
                                                        <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.segundoNombre"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Segundo nombre"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.apaterno"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.amaterno"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 3" key="3">
                                        <v-form v-model="personalInfoForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.fechaNacimiento"
                                                        v-mask="'##/##/####'"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="dateRules"
                                                        hint="Formato de fecha: DD/MM/YYYY"
                                                    >
                                                        <template v-slot:label>Fecha de nacimiento <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                    <v-text-field
                                                        :value="getAge(personal.fechaNacimiento)"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Edad"
                                                        background-color="primary"
                                                        color="secondary"
                                                        readonly
                                                    ></v-text-field>
                                                    <v-select
                                                        v-model="personal.fk_idEstadoCivil"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :items="estadoCivil"
                                                        item-value="id"
                                                        item-text="title"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Estado civíl <span class="red--text">*</span> </template>
                                                    </v-select>
                                                    <v-select
                                                        v-model="personal.fk_idSexo"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :items="sexo"
                                                        item-value="id"
                                                        item-text="title"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Sexo <span class="red--text">*</span> </template>
                                                    </v-select>
                                                    <v-select
                                                        v-model="personal.fk_idCatGrupoSanguineo"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Grupo sanguíneo"
                                                        :items="grupoSanguineo"
                                                        item-value="id"
                                                        item-text="title"
                                                    >
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 4" key="4">
                                        <v-form v-model="contactInfoForm">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.telefonoCelular"
                                                        v-mask="'###-###-##-##'"
                                                        type="tel"
                                                        rounded
                                                        filled
                                                        dense
                                                        maxlength="13"
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="phoneRules"
                                                    >
                                                        <template v-slot:label>Teléfono celular <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.telefonoFijo"
                                                        v-mask="'###-###-##-##'"
                                                        type="tel"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Teléfono fijo"
                                                        maxlength="13"
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="personal.Contacto.telefonoFijo.length > 0 ? phoneRules : []"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.Contacto.correoElectronico"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="emailRules"
                                                    >
                                                        <template v-slot:label>Correo electrónico <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 5" key="5">
                                        <v-form v-model="nacionalidadForm">
                                            <v-text-field
                                                v-model="personal.nacionalidad"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Nacionalidad <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-select
                                                v-model="personal.fk_idTipoNacionalidad"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="tipoNacionalidad"
                                                item-value="id"
                                                item-text="title"
                                                item-color="secondary"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Tipo de nacionalidad <span class="red--text">*</span> </template>
                                            </v-select>
                                            <v-autocomplete
                                                v-model="personal.fk_idMunicipioNacimiento"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :items="municipios"
                                                item-value="id"
                                                item-text="nombre"
                                                item-color="secondary"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Municipio donde nació <span class="red--text">*</span> </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 6" key="6">
                                        <v-form v-model="documentacionOficialForm">
                                            <v-text-field
                                                v-model="personal.cartillaMilitar"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                counter="10"
                                                maxlength="10"
                                                :rules="personal.fk_idSexo == 1 ? smnRules : []"
                                            >
                                                <template v-slot:label
                                                    >Cartilla militar <span class="red--text" v-if="personal.fk_idSexo == 1">*</span>
                                                </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.claveIne"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                counter="18"
                                                maxlength="18"
                                                :rules="curpRules"
                                            >
                                                <template v-slot:label>Clave de credencial de elector (INE) <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-text-field
                                                v-model="personal.nss"
                                                rounded
                                                filled
                                                dense
                                                label="Número de seguro social"
                                                background-color="primary"
                                                color="secondary"
                                                counter="11"
                                                maxlength="11"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model="personal.rfc"
                                                rounded
                                                filled
                                                dense
                                                label="RFC"
                                                background-color="primary"
                                                color="secondary"
                                                maxlength="13"
                                            ></v-text-field>
                                            <v-container fluid class="mx-0 px-0">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <v-text-field
                                                            v-model="personal.pasaporte"
                                                            rounded
                                                            filled
                                                            dense
                                                            label="Pasaporte"
                                                            background-color="primary"
                                                            color="secondary"
                                                            counter="9"
                                                            maxlength="9"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-menu
                                                            v-model="menuPasaporte"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="personal.vigenciaPasaporte"
                                                                    label="Vigencia de pasaporte"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="personal.vigenciaPasaporte"
                                                                @input="menuPasaporte = false"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-container fluid class="mx-0 px-0">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <v-text-field
                                                            v-model="personal.visa"
                                                            rounded
                                                            filled
                                                            dense
                                                            label="Visa"
                                                            background-color="primary"
                                                            color="secondary"
                                                            maxlength="16"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-menu
                                                            v-model="menuVisa"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="personal.vigenciaVisa"
                                                                    label="Vigencia de Visa"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="personal.vigenciaVisa" @input="menuVisa = false"></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                            <v-container fluid class="mx-0 px-0">
                                                <v-row>
                                                    <v-col cols="8">
                                                        <v-text-field
                                                            v-model="personal.licenciaConducir"
                                                            rounded
                                                            filled
                                                            dense
                                                            label="Número de licencia de conducir"
                                                            background-color="primary"
                                                            color="secondary"
                                                            counter="9"
                                                            maxlength="9"
                                                            :rules="licenciaRules"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-menu
                                                            v-model="menuVigenciaLicencia"
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    :value="personal.vigenciaLicencia"
                                                                    label="Vigencia"
                                                                    append-icon="mdi-calendar"
                                                                    readonly
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="personal.vigenciaLicencia"
                                                                @input="menuVigenciaLicencia = false"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 7" key="7">
                                        <v-form v-model="ubicacionForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete
                                                        v-model="personal.fk_idMunicipioRadica"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :items="municipios"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        item-color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Municipio donde radica <span class="red--text">*</span> </template>
                                                        <template v-slot:item="{ item }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                                <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 8" key="8">
                                        <v-form v-model="direccionForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete
                                                        v-model="personal.Contacto.Direccion.fk_idMunicipio"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :items="municipios"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        item-color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Municipio donde vive <span class="red--text">*</span> </template>
                                                        <template v-slot:item="{ item }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                                <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.calle"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Calle <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.entrecalles"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Entre calle"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.entrecalles2"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Entre calle 2"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.ext"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Número exterior <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.int"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Número interior"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.colonia"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Colonia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Colonia <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.cp"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Código postal"
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                        maxlength="5"
                                                    >
                                                        <template v-slot:label>Código Postal <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.Contacto.Direccion.referencias"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Referencias"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 9" key="9">
                                        <v-form v-model="estudiosForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="estudio.institucion"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Escuela o institución <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        v-model="estudio.generacionInicio"
                                                        maxlength="4"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="yearRules"
                                                    >
                                                        <template v-slot:label>Año de inicio <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        v-model="estudio.generacionFin"
                                                        maxlength="4"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="yearRules"
                                                    >
                                                        <template v-slot:label>Año de finalización <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-select
                                                        v-model="estudio.fk_idCatEstatusEstudio"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="estatusEstudios"
                                                        item-value="id"
                                                        item-text="title"
                                                        item-color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Estatus de estudios <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-textarea
                                                        v-model="estudio.observaciones"
                                                        no-resize
                                                        rows="4"
                                                        color="secondary"
                                                        background-color="primary"
                                                        label="Observaciones"
                                                        rounded
                                                        filled
                                                        dense
                                                    ></v-textarea>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select
                                                        v-model="estudio.fk_idCatEstudio"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="tipoEstudios"
                                                        item-value="id"
                                                        item-text="title"
                                                        item-color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Tipo de estudios <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select
                                                        v-model="estudio.fk_idComprobanteEstudio"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="comprobanteEstudios"
                                                        item-value="id"
                                                        item-text="title"
                                                        item-color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Tipo de comprobante de estudios <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col>
                                                    <v-btn text v-if="estudios.length != 0" block color="secondary" v-on:click="activeStep++">Cancelar</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 10" key="10" align="center">
                                        <div class="text-subtitle-2 font-weight-bold">Lista de estudios</div>
                                        <v-list color="primary" three-line align="left">
                                            <v-list-item v-for="(estudio, index) in estudios" :key="index">
                                                <v-list-item-avatar color="secondary">
                                                    <v-icon color="primary">mdi-school</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>
                                                        <div class="text-caption">
                                                            {{ getNombreTipoEstudios(estudio.fk_idCatEstudio) }}
                                                        </div>
                                                    </v-list-item-subtitle>
                                                    <v-list-item-title>
                                                        <div class="font-weight-bold">
                                                            {{ estudio.institucion }}
                                                        </div>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ estudio.generacionInicio + ' - ' + estudio.generacionFin }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn class="button" icon>
                                                        <v-icon color="error" v-on:click="removeEstudio(index)">mdi-minus</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="8" offset="2">
                                                    <v-btn block outlined color="secondary" class="px-8" v-on:click="activeStep--">Agregar estudios</v-btn>
                                                </v-col>
                                                <v-col cols="8" offset="2">
                                                    <v-btn
                                                        block
                                                        color="secondary"
                                                        v-on:click="activeStepButtonHandler"
                                                        :loading="loading"
                                                        :disabled="!validActiveStep || loading"
                                                        >{{ activeStepButtonLabel }}</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep < 10">
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../../components/props/props'

    export default {
        async mounted() {
            await this.fetchEstadoCivil()
            await this.fetchTipoNacionalidad()
            await this.fetchEstados()
            await this.fetchMunicipios()
            await this.fetchTipoEstudios()
            await this.fetchComprobantesEstudios()
            await this.fetchEstatusEstudios()
            await this.fetchSexo()

            await this.fetchGrupoSanguineo()
        },

        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,

                activeStep: 1,
                nameForm: false,
                personalInfoForm: false,
                contactInfoForm: false,
                nacionalidadForm: false,
                documentacionOficialForm: false,
                ubicacionForm: false,
                direccionForm: false,
                estudiosForm: false,
                documentosForm: false,
                loading: false,
                snackbar: false,
                menuVigenciaLicencia: false,
                menuPasaporte: false,
                menuVisa: false,

                estadoNace: null,
                estadoRadica: null,
                estadoVive: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                phoneRules: [v => !!v || 'Este campo es requerido.', v => v.length == 13 || 'Ingrese su número telefónico a 10 dígitos.'],
                smnRules: [v => !!v || 'Este campo es requerido.'],
                emailRules: [v => !!v || 'Este campo es requerido.', v => /.+@.+\..+/.test(v) || 'Ingrese un correo electrónico válido.'],
                curpRules: [v => !!v || 'Este campo es requerido', v => v.length == 0 || v.length == 18 || 'Por favor, verifique este campo.'],
                nssRules: [v => v.length == 0 || v.length == 11 || 'Por favor, complete los 11 caracteres.'],
                licenciaRules: [v => v.length == 0 || v.length == 9 || 'Por favor, complete los 9 caracteres.'],
                vigenciaRules: [v => v.length == 0 || v.length == 4 || 'Por favor, ingrese el año a 4 digitos.'],
                yearRules: [v => /^[0-9]*$/.test(v) || 'Por favor, sólo ingrese números.', v => v.length == 4 || 'Por favor, ingrese el año a 4 dígitos.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],

                menu: false,

                personal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    fechaNacimiento: '',
                    nacionalidad: '',
                    curp: '',
                    nss: '',
                    nsmi: '',
                    pasaporte: '',
                    rfc: '',
                    claveIne: '',
                    licenciaConducir: '',
                    vigenciaLicencia: null,
                    vigenciaPasaporte: null,
                    vigenciaVisa: null,
                    foto: '',
                    fk_idTipoNacionalidad: 0,
                    fk_idEstadoCivil: 0,
                    fk_idEstatus: 1,
                    fk_idMunicipioNacimiento: 0,
                    fk_idMunicipioRadica: 0,
                    fk_idPuestoDependencia: 28,
                    fk_idSubsede: 0,
                    fk_idSexo: 0,
                    Contacto: {
                        telefonoCelular: '',
                        telefonoFijo: '',
                        correoElectronico: '',
                        Direccion: {
                            fk_idMunicipio: 0,
                            calle: '',
                            colonia: '',
                            ext: '',
                            int: '',
                            entrecalles: '',
                            entrecalles2: '',
                            referencias: '',
                            lat: '',
                            lgt: '',
                            cp: '',
                        },
                    },
                    cartillaMilitar: '',
                    fk_idCatGrupoSanguineo: 0,
                    aptoSNPSP: true,
                    fk_idCatArea: 19,
                    capturo: '',
                    fechaCaptura: '',
                    ncp: '',
                    smn: '',
                    escolaridad: '',
                    rangoCategoria: '',
                    funciones: '',
                    categorizacion: '',
                    sueldoBase: '',
                    jerarquiaPuesto: '',
                    criticidadPuestoOperativo: '',
                    categoriaPuestoCnca: '',
                    funcional: '',
                    unidadInvestigacion: '',
                    unidadOperaciones: '',
                    unidadAnalisisTactico: '',
                    fechaIngresoEdoFza: '',
                    estatusSnsp: '',
                    estarLn: '',
                    fechaBaja: '',
                    motivoBaja: '',
                    observaciones: '',
                    examenC3Mes: '',
                    vigenciaAnio: 0,
                    observacionesC3: '',
                    visa: '',
                },

                estudio: {
                    institucion: '',
                    generacionInicio: '',
                    generacionFin: '',
                    generacion: '',
                    fk_idCatEstatusEstudio: null,
                    observaciones: '',
                    fk_idCatEstudio: null,
                    fk_idComprobanteEstudio: null,
                    fk_idPersonal: null,
                },

                estudios: [],
            }
        },

        computed: {
            ...mapGetters('estadoCivil', {
                estadoCivil: 'getEstadoCivil',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('comprobanteEstudios', {
                comprobanteEstudios: 'getComprobantesEstudios',
            }),

            ...mapGetters('estatusEstudios', {
                estatusEstudios: 'getEstatusEstudios',
            }),

            ...mapGetters('estudio', {
                tipoEstudios: 'getTipoEstudios',
            }),

            ...mapGetters('tipoDocumento', {
                tipoDocumento: 'getTipoDocumento',
            }),

            ...mapGetters('estatusEstudios', {
                estatusEstudios: 'getEstatusEstudios',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('validaCurp', {
                validaCurp: 'getValidaCurp',
            }),

            ...mapGetters('grupoSanguineo', {
                grupoSanguineo: 'getGrupoSanguineo',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Verificación de CURP'
                    case 2:
                        return 'Nombre del personal'
                    case 3:
                        return 'Información personal'
                    case 4:
                        return 'Información de contacto'
                    case 5:
                        return 'Nacionalidad'
                    case 6:
                        return 'Documentación oficial'
                    case 7:
                        return 'Ubicación'
                    case 8:
                        return 'Dirección'
                    case 9:
                        return 'Estudios'
                    case 10:
                        return 'Estudios'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.personal.curp.length == 18) {
                            return true
                        } else return false
                    case 2:
                        if (this.nameForm) {
                            return true
                        } else return false
                    case 3:
                        if (this.personalInfoForm) {
                            return true
                        } else return false
                    case 4:
                        if (this.contactInfoForm) {
                            return true
                        } else return false
                    case 5:
                        if (this.nacionalidadForm) {
                            return true
                        } else return false
                    case 6:
                        if (this.documentacionOficialForm) {
                            return true
                        } else return false
                    case 7:
                        if (this.ubicacionForm) {
                            return true
                        } else return false
                    case 8:
                        if (this.direccionForm) {
                            return true
                        } else return false
                    case 9:
                        if (this.estudiosForm) {
                            return true
                        } else return false
                    default:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep == 1) {
                    return 'Verificar CURP'
                } else if (this.activeStep < 9) {
                    return 'Siguiente paso'
                } else if (this.activeStep == 9) {
                    return 'Agregar estudios '
                } else {
                    return 'Registrar personal'
                }
            },

            filteredMunicipiosEstadoNacimiento: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoNace
                })
            },

            filteredMunicipiosEstadoRadica: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoRadica
                })
            },

            filteredMunicipiosEstadoVive: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoVive
                })
            },
        },

        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },

        methods: {
            ...mapActions('personal', ['savePersonal', 'fetchPersonal']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('estudio', ['saveEstudiosPersonal', 'fetchTipoEstudios']),
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('tipoDocumento', ['fetchTipoDocumento']),
            ...mapActions('documento', ['saveDocumentoPersonal', 'fetchDocumentoByPersonal']),
            ...mapActions('estatusEstudios', ['fetchEstatusEstudios']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('validaCurp', ['fetchValidaCurp']),
            ...mapActions('sexo', ['fetchSexo']),

            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.loading = true
                    await this.fetchValidaCurp(this.personal.curp)
                    if (this.validaCurp == true) {
                        this.activeStep++
                    } else {
                        this.SET_SNACKBAR_MESSAGE('La CURP ingresada ya está registrada.')
                    }
                    this.loading = false
                } else if (this.activeStep < 9) {
                    this.activeStep++
                } else if (this.activeStep == 9) {
                    this.estudios.push(this.estudio)
                    this.estudio = {
                        institucion: '',
                        generacionInicio: '',
                        generacionFin: '',
                        generacion: '',
                        fk_idCatEstatusEstudio: null,
                        observaciones: '',
                        fk_idCatEstudio: null,
                        fk_idComprobanteEstudio: null,
                    }
                    this.activeStep++
                } else {
                    this.loading = true

                    // this.personal.fechaNacimiento = moment(this.personal.fechaNacimiento, 'DD/MM/YYYY').format('DD/MM/YYYY')
                    this.personal.vigenciaLicencia = this.personal.vigenciaLicencia ? moment(this.personal.vigenciaLicencia).format('DD/MM/YYYY') : null

                    this.estudios.forEach(estudio => {
                        estudio.generacion = estudio.generacionInicio + '-' + estudio.generacionFin
                    })

                    await this.savePersonal({
                        data: this.personal,
                        estudios: this.estudios,
                    })

                    this.$router.replace('/recursos_humanos/personal')

                    this.loading = false
                }
            },

            saveBirthdayDate: function(date) {
                this.$refs.menu.save(date)
            },

            getNombreTipoEstudios: function(id) {
                var tipoEstudio = this.tipoEstudios.filter(tipoEstudio => {
                    return tipoEstudio.id == id
                })

                return tipoEstudio[0].title
            },

            removeEstudio: function(index) {
                this.estudios.splice(index, 1)

                if (this.estudios.length == 0) {
                    this.activeStep = 9
                }
            },
        },
    }
</script>
