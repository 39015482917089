import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        capacitadores: [],
        capacitadorToUpdate: null,
    },

    getters: {
        getCapacitadores: state => {
            return state.capacitadores.filter(capacitador => {
                return capacitador.apaterno != ''
            })
        },

        getCapacitadorToUpdate: state => {
            return state.capacitadorToUpdate
        },
    },

    mutations: {
        SET_TRAINERS(state, capacitadores) {
            state.capacitadores = capacitadores
        },

        SET_CAPACITADOR_TO_UPDATE(state, capacitadorId) {
            var capacitadorToUpdate = state.capacitadores.filter(capacitador => {
                return capacitador.id == capacitadorId
            })

            var capacitador = _.cloneDeep(capacitadorToUpdate[0])

            state.capacitadorToUpdate = capacitador
        },
    },

    actions: {
        fetchCapacitadores: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/capacitadores')
                .then(response => {
                    commit('SET_TRAINERS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCapacitador: async function(context, capacitador) {
            await axios
                .post('/PoliciaCuadrantes/Profesionalizacion/capacitadores', capacitador)
                .then(response => {
                    console.log(response.data.data)
                    var formData = new FormData()
                    formData.append('file', capacitador.file)
                    formData.append('idCapacitador', response.data.data[0].ID)
                    console.log(formData)
                    context.dispatch('uploadDocumento', formData)
                })
                .catch(error => console.log(error))
        },

        updateCapacitador: async function(context, capacitador) {
            await axios
                .put('/PoliciaCuadrantes/Profesionalizacion/capacitadores/' + capacitador.capacitador.id, capacitador.capacitador)
                .then(response => {
                    if (capacitador.file) {
                        var formData = new FormData()
                        formData.append('file', capacitador.file)
                        formData.append('idCapacitador', capacitador.capacitador.id)
                        console.log(formData)
                        context.dispatch('uploadDocumento', formData)
                    } else console.log('No actualiza documento')
                })
                .catch(error => console.log(error))
        },

        uploadDocumento: async function(context, formData) {
            await fileAxios
                .post('/SetCertificadoCapacitador/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'El capacitador ha sido registrado correctamente', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
