<template>
    <v-dialog width="480" v-model="active" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" rounded v-on="on" v-bind="attrs">Nuevo accesorio</v-btn>
        </template>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Nuevo accesorio</div>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="closeDialog()" icon><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-form v-model="itemForm" ref="form">
                    <v-select
                        label="Tipo de accesorio"
                        v-model="item.fk_idCatTipoItem"
                        :items="tipoItem"
                        item-color="secondary"
                        item-value="id"
                        item-text="title"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        :rules="required"
                    >
                        <template v-slot:label>Tipo de accesorio <span class="red--text">*</span> </template>
                    </v-select>
                    <v-text-field
                        label="Nombre del accesorio"
                        v-model="item.item"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        :rules="required"
                    >
                        <template v-slot:label>Nombre del accesorio <span class="red--text">*</span> </template>
                    </v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn @click="triggerSaveItem()" color="secondary" :loading="loading" :disabled="loading || !itemForm">Registrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data() {
            return {
                active: false,
                loading: false,
                itemForm: false,

                item: {
                    item: '',
                    fk_idCatTipoItem: null,
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('tipoItemArmeria', {
                tipoItem: 'getTipoItem',
            }),
        },

        methods: {
            ...mapActions('itemArmeria', ['saveItem', 'fetchItems']),

            closeDialog() {
                this.$refs.form.resetValidation()
                this.active = false
                this.item = {
                    item: '',
                    fk_idCatTipoItem: null,
                }
                this.loading = false
            },

            async triggerSaveItem() {
                this.loading = true
                await this.saveItem(this.item)
                await this.fetchItems(this.item.fk_idCatTipoItem)

                this.closeDialog()
            },
        },
    }
</script>
