<template>
    <v-container>
        <v-row>
            <v-col cols="2">
                <v-card class="card pa-2" color="primary" dense height="64">
                    <v-container>
                        <v-row align="center">
                            <v-col cols="6">
                                <div class="text-subtitle-2 font-weight-bold">Asistieron</div>
                            </v-col>
                            <v-col cols="6" align="right">
                                <div class="d-inline text-subtitle-1 font-weight-black">{{ totalAsistidos }}</div>
                                <div class="d-inline text-subtitle-1 mx-2">de</div>
                                <div class="d-inline text-subtitle-1 font-weight-black">{{ personal.length }}</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="card pa-2" color="primary" dense height="64">
                    <v-container>
                        <v-row align="center">
                            <v-col cols="8">
                                <div class="text-subtitle-2 font-weight-bold">Promedio general</div>
                            </v-col>
                            <v-col cols="4" align="right">
                                <div class="text-subtitle-1 font-weight-black">{{ promedioGeneral }}</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="3" align="right">
                <v-btn class="secondary card mt-2 px-8" rounded elevation="8" v-on:click="save()" :disabled="loading" :loading="loading">
                    Guardar evaluación <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="buscaPersonal"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar personal por nombre, edad, municipio o estado..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaPersonal.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredPersonal.length }} resultados encontrados con la búsqueda "{{ buscaPersonal }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoPersonal, index) in groupedPersonal" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoPersonal.inicial }}
                            </div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="personal in grupoPersonal.cursoPersonal" :key="personal.Personal.idPersonal">
                                    <v-card class="card" color="secondary" elevation="12">
                                        <v-card-title class="mb-0 pb-0">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-img :src="personal.Personal.foto" v-if="personal.Personal.foto !== ''" width="80"></v-img>
                                                        <v-icon size="80" v-else>mdi-account</v-icon>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ personal.Personal.apaterno }}</div>
                                                        <div class="text-subtitle-1 primary--text ">
                                                            {{
                                                                personal.Personal.amaterno +
                                                                    ' ' +
                                                                    personal.Personal.nombre +
                                                                    ' ' +
                                                                    personal.Personal.segundoNombre
                                                            }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text class="pb-0">
                                            <v-container>
                                                <v-row align="center">
                                                    <v-col cols="6" offset-md="3" align="center">
                                                        <v-switch v-model="personal.asistencia" label="Asistió al curso" inset></v-switch>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="personal.calificacion"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Calificación"
                                                            :disabled="!personal.asistencia"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model="personal.calificacionEvaluacion"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Calificación de evaluador"
                                                            :disabled="!personal.asistencia"
                                                        ></v-text-field>
                                                        <v-file-input
                                                            v-model="personal.file"
                                                            label="Evidencia .jpg"
                                                            rounded
                                                            filled
                                                            background-color="primary"
                                                            color="secondary"
                                                            dense
                                                            prepend-inner-icon="mdi-file"
                                                            prepend-icon=""
                                                            :disabled="!personal.asistencia"
                                                        ></v-file-input>
                                                        <v-textarea
                                                            v-model="personal.comentario"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Comentario"
                                                            no-resize
                                                            rows="2"
                                                        ></v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import { textInputProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                textInputProps,

                buscaPersonal: '',
                ordenAlfabetico: 0,

                loading: false,
            }
        },

        computed: {
            ...mapGetters('cursoAsignado', {
                personalCurso: 'getCursosAsignados',
            }),

            personal: function() {
                return this.personalCurso.filter(cursoPersonal => {
                    return cursoPersonal.fk_idProgramacionCurso == this.id
                })
            },

            filteredPersonal: function() {
                return this.personal.filter(cursoPersonal => {
                    return cursoPersonal
                })
            },

            totalAsistidos: function() {
                var total = 0
                this.personal.forEach(cursoPersonal => {
                    if (cursoPersonal.asistencia == true) {
                        total++
                    }
                })
                return total
            },

            promedioGeneral: function() {
                var promedioGeneral = 0

                this.personal.forEach(cursoPersonal => {
                    if (cursoPersonal.calificacion.length > 0) {
                        promedioGeneral += parseFloat(cursoPersonal.calificacion)
                    }
                })

                return promedioGeneral / this.totalAsistidos
            },

            groupedPersonal: function() {
                var result = _(this.filteredPersonal)
                    .groupBy(cursoPersonal => cursoPersonal.Personal.apaterno[0].toUpperCase())
                    .map((cursoPersonal, inicial) => ({ inicial, cursoPersonal }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.inicial < b.inicial) {
                            return -1
                        }
                        if (a.inicial > b.inicial) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.inicial > b.inicial) {
                            return -1
                        }
                        if (a.inicial < b.inicial) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },

        methods: {
            ...mapActions('cursoAsignado', ['updateCursoAsignado']),

            save: async function() {
                this.loading = true

                this.personal.forEach(cursoPersonal => {
                    if (cursoPersonal.file) {
                        cursoPersonal.evidencia = cursoPersonal.file.name
                    }
                })
                console.log(this.personal)
                await this.updateCursoAsignado(this.personal)

                this.$router.push('/profesionalizacion/cursos_programados')
                this.loading = false
            },
        },
    }
</script>
