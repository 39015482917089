<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Campos registro personal</div>
            <v-tabs v-model="selectedTab" color="secondary" fixed-tabs>
                <v-tab class="font-weight-bold">Captura</v-tab>
                <v-tab class="font-weight-bold">Rangos</v-tab>
                <v-tab class="font-weight-bold">Puesto</v-tab>
                <v-tab class="font-weight-bold">Funciones</v-tab>
                <v-tab class="font-weight-bold" v-if="perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE'">Información C3</v-tab>
                <v-tab class="font-weight-bold"
                    >Información<br />
                    de baja</v-tab
                >
                <v-tab class="font-weight-bold">Observaciones</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="selectedTab" class="transparent">
                <v-tab-item key="1">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Acerca de la captura de datos</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Información capturada por"
                                            v-model="personal.capturo"
                                            rounded
                                            readonly
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                        ></v-text-field>
                                        <v-text-field
                                            label="Fecha de captura"
                                            v-model="personal.fechaCaptura"
                                            rounded
                                            readonly
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Rangos y categorías</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.rangoCategoria"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Rango o categoría"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.categorizacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categorización"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.jerarquiaPuesto"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Jerarquía de puesto"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.categoriaPuestoCnca"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categoría de puesto CNCA"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="3">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Acerca del puesto</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.fechaIngresoEdoFza"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Fecha de ingreso a Estado de Fuerza"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.sueldoBase"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Sueldo base"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.criticidadPuestoOperativo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Criticidad de puesto operativo"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.funcional"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funcional"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.estatusSnsp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Estatus SNSP"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.estarLn"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Debe estar en LN"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="4">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Funciones</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="personal.funciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funciones"
                                            no-resize
                                            rows="6"
                                            readonly
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="5">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Información C3</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.examenC3Mes"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Exámen C3 mes"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.vigenciaAnio"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Vigencia año"
                                            readonly
                                        ></v-text-field>
                                        <v-textarea
                                            v-model="personal.observacionesC3"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones C3"
                                            no-resize
                                            rows="6"
                                            readonly
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="6">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Información de baja</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.fechaBaja"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Fecha de baja"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.motivoBaja"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Motivo de baja"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="7">
                    <v-container>
                        <v-row>
                            <v-col cols="8">
                                <div class="text-h6 font-weight-bold">Observaciones</div>
                            </v-col>
                            <v-col cols="4">
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="personal.observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="6"
                                            readonly
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            personal: {
                type: Object,
            },
        },

        data() {
            return {
                selectedTab: null,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),
        },
    }
</script>
