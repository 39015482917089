import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusDocumento: [],
    },

    getters: {
        getEstatusDocumento: state => {
            return state.estatusDocumento
        },
    },

    mutations: {
        SET_ESTATUS_DOCUMENTO(state, estatusDocumento) {
            state.estatusDocumento = estatusDocumento
        },
    },

    actions: {
        updateEstatusDocumento: async function({ commit }, documentoPersonal) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + documentoPersonal.idPersonal + '/documento/' + documentoPersonal.idDocumentoPersonal + '/estatus', {
                    fk_idEstatus: 2,
                })
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
