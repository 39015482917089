<template>
    <v-container>
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Asignación de CUP</v-card-title>
                    <v-card-text>
                        <v-text-field
                            :value="getFullName(personal)"
                            rounded
                            filled
                            dense
                            background-color="primary"
                            color="secondary"
                            label="Nombre del elemento"
                            :rules="nameRules"
                            readonly
                        >
                        </v-text-field>
                        <v-text-field
                            :value="personal.CUIP"
                            rounded
                            filled
                            dense
                            background-color="primary"
                            color="secondary"
                            label="CUIP"
                            :rules="nameRules"
                            readonly
                        ></v-text-field>
                        <v-text-field v-model="cup" rounded filled dense background-color="primary" color="secondary" label="CUP" :rules="nameRules">
                            <template v-slot:label>Ingrese folio CUP <span class="red--text">*</span> </template>
                        </v-text-field>
                        <v-file-input v-model="file" rounded filled background-color="primary" color="secondary" dense :rules="nameRules">
                            <template v-slot:label>Adjuntar documento CUP <span class="red--text">*</span> </template>
                        </v-file-input>
                        <v-text-field
                            v-model="fechaVigenciaC3"
                            v-mask="'##/##/####'"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                            :rules="dateRules"
                        >
                            <template v-slot:label>Fecha de vigencia de evaluaciones C3 <span class="red--text">*</span> </template>
                        </v-text-field>
                        <v-text-field
                            v-model="fechaVigenciaFormacionInicial"
                            v-mask="'##/##/####'"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                            :rules="dateRules"
                        >
                            <template v-slot:label>Fecha de vigencia de formación inicial <span class="red--text">*</span> </template>
                        </v-text-field>
                        <v-text-field
                            v-model="fechaVigenciaCompetenciasBasicas"
                            v-mask="'##/##/####'"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                            :rules="dateRules"
                        >
                            <template v-slot:label>Fecha de vigencia de competencias básicas <span class="red--text">*</span> </template>
                        </v-text-field>
                        <v-text-field
                            v-model="fechaVigenciaCup"
                            v-mask="'##/##/####'"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                            :rules="dateRules"
                        >
                            <template v-slot:label>Fecha de vigencia de CUP <span class="red--text">*</span> </template>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn v-bind="secondaryButtonProps" to="/desarrollo_profesional/personal">Cancelar</v-btn>
                        <v-btn v-bind="primaryButtonProps" v-on:click="asignaCup()" :loading="loading" :disabled="loading">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapActions } from 'vuex'
    import { primaryButtonProps, secondaryButtonProps } from '../../components/props/props'

    export default {
        props: {
            personal: {
                type: Object,
            },
        },

        data() {
            return {
                primaryButtonProps,
                secondaryButtonProps,
                loading: false,
                cup: '',
                file: null,
                menuVigenciaC3: false,
                fechaVigenciaC3: null,

                menuVigenciaCup: false,
                fechaVigenciaCup: null,

                menuVigenciaCompetenciasBasicas: false,
                fechaVigenciaCompetenciasBasicas: null,

                menuVigenciaFormacionInicial: false,
                fechaVigenciaFormacionInicial: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        methods: {
            ...mapActions('personal', ['updateCupPersonal', 'fetchPersonal', 'updatePersonal', 'uploadCup']),

            asignaCup: async function() {
                this.loading = true
                this.personal.cup = this.cup
                await this.updateCupPersonal(this.personal)

                var formData = new FormData()
                formData.append('file', this.file)
                formData.append('idPersonal', this.personal.idPersonal)
                await this.uploadCup(formData)

                this.personal.vigenciaEvaluacionC3 = this.fechaVigenciaC3
                this.personal.vigenciaFormacionInicial = this.fechaVigenciaFormacionInicial
                this.personal.vigenciaCompetenciasBasicas = this.fechaVigenciaCompetenciasBasicas
                this.personal.vigenciaCup = this.fechaVigenciaCup

                await this.updatePersonal(this.personal)

                this.$router.push('/desarrollo_profesional/personal')
                this.loading = false
            },
        },
    }
</script>
