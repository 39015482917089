import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        valoresCapacidades: [],
    },

    getters: {
        getValoresCapacidades: state => {
            return state.valoresCapacidades
        },
    },
    mutations: {
        SET_VALORES_CAPACIDADES(state, valoresCapacidades) {
            state.valoresCapacidades = valoresCapacidades
        },
    },
    actions: {
        fetchValoresCapacidades: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/valoresCapacidades')
                .then(response => {
                    commit('SET_VALORES_CAPACIDADES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
