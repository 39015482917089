import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        turnos: [],
    },

    getters: {
        getTurnos: state => {
            return state.turnos
        },
    },

    mutations: {
        SET_TURNOS(state, turnos) {
            state.turnos = turnos
        },
    },

    actions: {
        fetchTurnos: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/turno')
                .then(response => {
                    commit('SET_TURNOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
