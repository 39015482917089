import Vue from 'vue'
import Vuex from 'vuex'
import { axios } from '../plugins/axios'
import moment from 'moment'
import arma from './modules/armeria/arma'
import armaPersonal from './modules/armeria/armaPersonal'
import calibre from './modules/armeria/calibre'
import claseArma from './modules/armeria/claseArma'
import entregaRecepcionArma from './modules/armeria/entregaRecepcionArma'
import estatusArma from './modules/armeria/estatusArma'
import licenciaOficialColectiva from './modules/armeria/licenciaOficialColectiva'
import marcaArma from './modules/armeria/marcaArma'
import modeloArma from './modules/armeria/modeloArma'
import municionPersonal from './modules/armeria/municionPersonal'
import municionArma from './modules/armeria/municionArma'
import tipoArma from './modules/armeria/tipoArma'
import tipoAsignacionArma from './modules/armeria/tipoAsignacionArma'
import tipoEntregaArma from './modules/armeria/tipoEntregaArma'

import asignacionEquipo from './modules/equipamiento/asignacionEquipo'
import aula from './modules/profesionalizacion/aula'
import candidato from './modules/candidato'
import capacitador from './modules/profesionalizacion/capacitador'
import categoria from './modules/categoria'
import categoriaCurso from './modules/profesionalizacion/categoriaCurso'
import colonia from './modules/colonia'
import color from './modules/color'
import comprobanteEstudios from './modules/comprobanteEstudios'
import cuip from './modules/cuip'
import curso from './modules/profesionalizacion/curso'
import cursoProgramado from './modules/profesionalizacion/cursoProgramado'
import cursoAsignado from './modules/profesionalizacion/cursoAsignado'
import dependencia from './modules/dependencia'
import dictamenPersonal from './modules/dictamenPersonal'
import documento from './modules/documento'
import estado from './modules/estado'
import estadoCivil from './modules/estadoCivil'
import estatusAula from './modules/profesionalizacion/estatusAula'
import estatusCurso from './modules/profesionalizacion/estatusCurso'
import estatusCursoPersonal from './modules/profesionalizacion/estatusCursoPersonal'
import estatusDocumento from './modules/estatusDocumento'
import estatusEstudios from './modules/estatusEstudios'
import estudio from './modules/estudio'
import evaluacionMedica from './modules/profesionalizacion/evaluacionMedica'
import factorMerito from './modules/factorMerito'
import icono from './modules/icono'
import item from './modules/equipamiento/item'
import marcas from './modules/marcas'
import mediaFiliacion from './modules/mediaFiliacion'
import modelo from './modules/modelo'
import municipio from './modules/municipio'
import personal from './modules/personal'
import preaspirante from './modules/preaspirante'
import puesto from './modules/puesto'
import rama from './modules/rama'
import rasgo from './modules/rasgo'
import region from './modules/region'
import sede from './modules/sede'
import seguimiento from './modules/seguimiento'
import subcategoria from './modules/subcategoria'
import subsede from './modules/subsede'
import tipoCurso from './modules/profesionalizacion/tipoCurso'
import tipoDocumento from './modules/tipoDocumento'
import tipoInstancia from './modules/tipoInstancia'
import tipoItem from './modules/equipamiento/tipoItem'
import tipoMultimedia from './modules/tipoMultimedia'
import tipoNacionalidad from './modules/tipoNacionalidad'
import tipoRegion from './modules/tipoRegion'
import tipoSede from './modules/tipoSede'
import biometrico from './modules/biometrico'
import sexo from './modules/sexo'
import estatusPersonal from './modules/estatusPersonal'
import expediente from '../components/expediente/expediente'
import referencias from './modules/referencias'
import ocupaciones from './modules/ocupaciones'
import tipoReferencia from './modules/tipoReferencia'
import grupoSanguineo from './modules/grupoSanguineo'
import validaCurp from './modules/validaCurp'
import faltasAdministrativas from './modules/faltasAdministrativas'
import reimpresionCredencial from './modules/reimpresionCredencial'
import areas from './modules/areas'
import turnos from './modules/turnos'
import tipoPuesto from './modules/tipoPuesto'
import movimientoEquipamiento from './modules/equipamiento/movimientoEquipamiento'
import tipoMovimientoEquipamiento from './modules/equipamiento/tipoMovimientoEquipamiento'
import tipoFaltaAdministrativa from './modules/tipoFaltaAdmnistrativa'
import motivoFaltaAdministrativa from './modules/motivoFaltaAdministrativa'
import enrolamiento from './modules/registro_personal/enrolamiento'
import tipoFuncion from './modules/tipoFuncion'
import familiares from './modules/familiares'
import parentesco from './modules/parentesco'
import permutacion from './modules/permutacion'
import solicitudes from './modules/solicitudes'
import municionCurso from './modules/armeria/municionCurso'
import asignacionItem from './modules/armeria/asignacionItem'
import estatusMunicion from './modules/armeria/estatusMunicion'
import itemArmeria from './modules/armeria/itemArmeria'
import movimientoCargador from './modules/armeria/movimientoCargador'
import movimientoItem from './modules/armeria/movimientoItem'
import procedencia from './modules/armeria/procedencia'
import tipoItemArmeria from './modules/armeria/tipoItemArmeria'
import movimientoMunicion from './modules/armeria/movimientoMunicion'
import movimientoArma from './modules/armeria/movimientoArma'
import inventario from './modules/armeria/inventario'
import cargadores from './modules/armeria/cargadores'
import cargadorPersonal from './modules/armeria/cargadorPersonal'
import tipoAmbito from './modules/tipoAmbito'
import sugerenciaCursos from './modules/profesionalizacion/sugerenciaCursos'

import actoRelevante from './modules/desarrollo_policial/actoRelevante'
import actosRelevantesEvaluacion from './modules/desarrollo_policial/actosRelevantesEvaluacion'
import desempeñoInstitucional from './modules/desarrollo_policial/desempeñoInstitucional'
import disciplinaAdministrativa from './modules/desarrollo_policial/disciplinaAdministrativa'
import disciplinaOperativa from './modules/desarrollo_policial/disciplinaOperativa'
import estatusProcedimiento from './modules/desarrollo_policial/estatusProcedimiento'
import frecuenciaProductividad from './modules/desarrollo_policial/frecuenciaProductividad'
import frecuenciaRespetoPrincipio from './modules/desarrollo_policial/frecuenciaRespetoPrincipio'
import funcion from './modules/desarrollo_policial/funcion'
import incidencia from './modules/desarrollo_policial/incidencia'
import indicador from './modules/desarrollo_policial/indicador'
import indicadorEvaluacion from './modules/desarrollo_policial/indicadorEvaluacion'
import periodicidad from './modules/desarrollo_policial/periodicidad'
import principio from './modules/desarrollo_policial/principio'
import procedimiento from './modules/desarrollo_policial/procedimiento'
import productividad from './modules/desarrollo_policial/productividad'
import promociones from './modules/desarrollo_policial/promociones'
import reactivo from './modules/desarrollo_policial/reactivo'
import reactivoPrincipio from './modules/desarrollo_policial/reactivoPrincipio'
import reactivosEvaluacion from './modules/desarrollo_policial/reactivosEvaluacion'
import reactivoValor from './modules/desarrollo_policial/reactivoValor'
import recompensas from './modules/desarrollo_policial/recompensas'
import rendimientoProfesionalismo from './modules/desarrollo_policial/rendimientoProfesionalismo'
import respetoPrincipios from './modules/desarrollo_policial/respetoPrincipios'
import respetoPrincipiosValores from './modules/desarrollo_policial/respetoPrincipiosValores'
import resultado from './modules/desarrollo_policial/resultado'
import tipoEvaluacion from './modules/desarrollo_policial/tipoEvaluacion'
import tipoRecompensa from './modules/desarrollo_policial/tipoRecompensa'
import pendientesConsultaPrevia from './modules/registro_personal/pendientesConsultaPrevia'
import bitacoraEntregaRecepcion from './modules/armeria/bitacoraEntregaRecepcion'
import motivoUso from './modules/armeria/motivoUso'
import armaLoc from './modules/armeria/armaLoc'
import equipo from './modules/equipamiento/equipo'
import estatusEquipamiento from './modules/equipamiento/estatusEquipamiento'
import asuntosInternos from './modules/asuntosInternos'

import tipoSeniaParticular from './modules/registro_personal/tipoSeniaParticular'
import ladoSeniaParticular from './modules/registro_personal/ladoSeniaParticular'
import regionSeniaParticular from './modules/registro_personal/regionSeniaParticular'
import vistaSeniaParticular from './modules/registro_personal/vistaSeniaParticular'
import cantidadSeniaParticular from './modules/registro_personal/cantidadSeniaParticular'
import usuario from './modules/usuario'
import evaluacionAnual from './modules/desarrollo_policial/evaluacionAnual'
import condecoracionPersonal from './modules/desarrollo_policial/condecoracionPersonal'
import convocatoriaPersonal from './modules/desarrollo_policial/convocatoriaPersonal'
import capacitacion from './modules/desarrollo_policial/capacitacion'
import convocatoria from './modules/desarrollo_policial/convocatoria'
import evaluacionesPersonal from './modules/desarrollo_policial/evaluacionesPersonal'
import descripcionCriterio from './modules/desarrollo_policial/descripcionCriterio'
import descripcion from './modules/desarrollo_policial/descripcion'
import criterioEvaluacion from './modules/desarrollo_policial/criterioEvaluacion'
import principiosConstitucionales from './modules/desarrollo_policial/principiosConstitucionales'
import habilidades from './modules/desarrollo_policial/habilidades'
import valoresCapacidades from './modules/desarrollo_policial/valoresCapacidades'
import tipoConvocatoria from './modules/desarrollo_policial/tipoConvocatoria'
import condecoraciones from './modules/desarrollo_policial/condecoraciones'
import sanciones from './modules/asuntos_internos/sanciones'
import procedimientos from './modules/consejo_honor/procedimientos'
import cadenaResultados from './modules/desarrollo_policial/cadenaResultados'
import estatusEvaluacion from './modules/desarrollo_policial/estatusEvaluacion'
import accesorio from './modules/armeria/accesorio'
import personalLoc from './modules/armeria/personalLoc'
import subareas from './modules/subareas'
import alerta from './modules/registro_personal/alerta'

Vue.use(Vuex)
moment.locale('es')

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        perfil: localStorage.getItem('perfil') || '',
        nombre: localStorage.getItem('nombre') || '',
        idPersonal: localStorage.getItem('idPersonal') || '',
        id: localStorage.getItem('id') || '',
        loader: true,
        snackbar: {
            active: false,
            message: '',
        },
    },

    getters: {
        getLoader: state => {
            return state.loader
        },

        getSnackbar: state => {
            return state.snackbar
        },

        getToken: state => {
            return state.token
        },

        getPerfil: state => {
            return state.perfil
        },

        getNombre: state => {
            return state.nombre
        },

        getIdPersonal: state => {
            return state.idPersonal
        },

        getId: state => {
            return state.id
        },
    },

    mutations: {
        DISABLE_LOADER(state) {
            state.loader = false
        },

        ENABLE_LOADER(state) {
            state.loader = true
        },

        SET_SNACKBAR_MESSAGE(state, message) {
            state.snackbar = {
                active: true,
                message: message,
            }
        },

        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_PERFIL(state, perfil) {
            state.perfil = perfil
        },

        SET_NOMBRE(state, nombre) {
            state.nombre = nombre
        },

        SET_ID_PERSONAL(state, idPersonal) {
            state.idPersonal = idPersonal
        },

        SET_ID(state, id) {
            state.id = id
        },
    },

    actions: {
        login: async function({ commit }, user) {
            await axios
                .post('/PoliciaCuadrantes/login', user)
                .then(res => {
                    if (res.data.data.length == 1) {
                        if (res.data.data[0].token.length > 0) {
                            var token = res.data.data[0].token
                            var perfil = res.data.data[0].extraInfo.perfil
                            var nombre = res.data.data[0].fullName
                            var idPersonal = res.data.data[0].fk_idPersonal
                            var id = res.data.data[0].id
                            localStorage.setItem('token', token)
                            localStorage.setItem('perfil', perfil)
                            localStorage.setItem('nombre', nombre)
                            localStorage.setItem('idPersonal', idPersonal)
                            localStorage.setItem('id', id)
                            commit('SET_TOKEN', token)
                            commit('SET_PERFIL', perfil)
                            commit('SET_NOMBRE', nombre)
                            commit('SET_ID_PERSONAL', idPersonal)
                            commit('SET_ID', id)
                            commit('SET_SNACKBAR_MESSAGE', 'Inicio de sesión realizado. Redirigiendo...')
                        } else {
                            commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.')
                        }
                    } else {
                        if (res.data.data[0].token.length > 0) {
                            var token = res.data.data[0].token
                            var nombre = res.data.data[0].fullName
                            var idPersonal = res.data.data[0].fk_idPersonal
                            var id = res.data.data[0].id
                            localStorage.setItem('token', token)
                            localStorage.setItem('nombre', nombre)
                            localStorage.setItem('idPersonal', idPersonal)
                            localStorage.setItem('id', id)
                            commit('SET_TOKEN', token)
                            commit('SET_PERFIL', [res.data.data[0].extraInfo.perfil, res.data.data[1].extraInfo.perfil])
                            commit('SET_NOMBRE', nombre)
                            commit('SET_ID_PERSONAL', idPersonal)
                            commit('SET_ID', id)
                            // commit('SET_SNACKBAR_MESSAGE', 'Inicio de sesión realizado. Redirigiendo...')
                        } else {
                            commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.')
                        }
                    }
                })
                .catch(error => commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el inicio de sesión. Por favor, verifique sus credenciales.'))
        },

        passwordReset: async function({ commit }, user) {
            await axios
                .put('/PoliciaCuadrantes/passwords', user)
                .then(res => {
                    commit('SET_SNACKBAR_MESSAGE', 'Su contraseña ha sido actualizada.')
                })
                .catch(error => commit('SET_SNACKBAR_MESSAGE', 'No pudo realizarse el cambio de contraseña. Por favor, intente nuevamente.'))
        },
    },

    modules: {
        arma,
        armaPersonal,
        calibre,
        claseArma,
        entregaRecepcionArma,
        estatusArma,
        licenciaOficialColectiva,
        marcaArma,
        modeloArma,
        municionPersonal,
        tipoArma,
        tipoAsignacionArma,
        tipoEntregaArma,
        actoRelevante,
        asignacionEquipo,
        aula,
        candidato,
        capacitador,
        categoria,
        categoriaCurso,
        colonia,
        color,
        comprobanteEstudios,
        cuip,
        curso,
        cursoProgramado,
        cursoAsignado,
        dependencia,
        dictamenPersonal,
        documento,
        estado,
        estadoCivil,
        estatusAula,
        estatusCurso,
        estatusCursoPersonal,
        estatusDocumento,
        estatusEstudios,
        estudio,
        evaluacionMedica,
        factorMerito,
        frecuenciaProductividad,
        frecuenciaRespetoPrincipio,
        funcion,
        icono,
        item,
        marcas,
        mediaFiliacion,
        modelo,
        municipio,
        periodicidad,
        personal,
        preaspirante,
        principio,
        productividad,
        puesto,
        rama,
        rasgo,
        reactivo,
        region,
        respetoPrincipios,
        resultado,
        sede,
        seguimiento,
        subcategoria,
        subsede,
        tipoCurso,
        tipoDocumento,
        tipoInstancia,
        tipoItem,
        tipoMultimedia,
        tipoNacionalidad,
        tipoRegion,
        tipoSede,
        indicador,
        biometrico,
        sexo,
        estatusPersonal,

        expediente,
        referencias,
        ocupaciones,
        tipoReferencia,
        grupoSanguineo,
        validaCurp,
        faltasAdministrativas,
        reimpresionCredencial,
        areas,
        turnos,
        tipoPuesto,
        movimientoEquipamiento,
        tipoMovimientoEquipamiento,
        tipoFaltaAdministrativa,
        motivoFaltaAdministrativa,
        enrolamiento,
        tipoFuncion,
        familiares,
        parentesco,
        permutacion,
        solicitudes,
        municionArma,
        municionCurso,
        tipoEvaluacion,
        reactivoValor,
        reactivosEvaluacion,
        reactivoPrincipio,
        procedimiento,
        indicadorEvaluacion,
        incidencia,
        estatusProcedimiento,
        estatusMunicion,
        asignacionItem,
        itemArmeria,
        movimientoCargador,
        movimientoItem,
        procedencia,
        tipoItemArmeria,
        movimientoMunicion,
        movimientoArma,
        inventario,
        cargadores,
        cargadorPersonal,
        tipoAmbito,
        sugerenciaCursos,
        tipoRecompensa,
        actosRelevantesEvaluacion,
        desempeñoInstitucional,
        disciplinaAdministrativa,
        disciplinaOperativa,
        promociones,
        recompensas,
        rendimientoProfesionalismo,
        respetoPrincipiosValores,
        pendientesConsultaPrevia,
        bitacoraEntregaRecepcion,
        motivoUso,
        armaLoc,
        equipo,
        estatusEquipamiento,
        asuntosInternos,
        tipoSeniaParticular,
        ladoSeniaParticular,
        regionSeniaParticular,
        vistaSeniaParticular,
        cantidadSeniaParticular,
        usuario,
        condecoracionPersonal,
        evaluacionAnual,
        convocatoriaPersonal,
        convocatoria,
        capacitacion,
        evaluacionesPersonal,
        descripcionCriterio,
        descripcion,
        criterioEvaluacion,
        principiosConstitucionales,
        valoresCapacidades,
        habilidades,
        tipoConvocatoria,
        condecoraciones,
        sanciones,
        procedimientos,
        cadenaResultados,
        estatusEvaluacion,
        accesorio,
        personalLoc,
        subareas,
        alerta,
    },
})
