<template>
    <v-main>
        <v-container>
            <v-row>
                <v-col cols="12" align="center">
                    <v-card class="card" color="primary">
                        <v-card-text align="center">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-avatar size="180">
                                            <v-img src="@/assets/img/tadeo_rosas.png"></v-img>
                                        </v-avatar>
                                        <div class="text-h6 font-weight-bold text--primary">Tadeo Rosas</div>
                                        <div class="text-subtitle-1 font-weight-bold text--primary">Comandante Operativo</div>
                                        <div class="text-subtitle-2 text--primary my-2">
                                            General encargado de la operación y el proyecto Baner.
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/rJnzrNJB0Y0"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen
                                        ></iframe>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="4" xl="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title class="font-weight-bold justify-center">
                                    Archivos de interés
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-btn color="secondary" block>Convocatoria A (pdf)</v-btn>
                                            </v-col>

                                            <v-col cols="12">
                                                <v-btn color="secondary" block>Convocatoria B (pdf)</v-btn>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-btn color="secondary" block>Convocatoria C (pdf)</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-text>
                                    <v-slide-group center-active :show-arrows="true">
                                        <v-slide-item v-for="imagen in imagenes" :key="imagen.id" v-slot="{ active, toggle }">
                                            <v-img src="@/assets/img/cartel1.jpg" class="ma-4" rounded @click="toggle"></v-img>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col lg="5" xl="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title class="font-weight-bold">Lo más reciente</v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-virtual-scroll :items="feed" height="720" item-height="64">
                                                    <template v-slot:default="{ item }">
                                                        <v-list-item :key="item.id">
                                                            <v-list-item-avatar>
                                                                <v-avatar color="secondary">
                                                                    <v-icon color="primary">{{ item.icon }}</v-icon>
                                                                </v-avatar>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                                                            </v-list-item-content>
                                                            <v-list-item-action>
                                                                <v-btn text class="secondary--text">Ver</v-btn>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </template>
                                                </v-virtual-scroll>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col lg="3" xl="4">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title class="font-weight-bold justify-center">Fechas de interés</v-card-title>
                                <v-card-text align="center">
                                    <v-date-picker locale="es" color="secondary" no-title full-width></v-date-picker>
                                    <div class="text-h6 font-weight-bold">Acontecimientos</div>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" align="left">
                                                <v-virtual-scroll :items="acontecimientos" height="240" item-height="64">
                                                    <template v-slot:default="{ item }">
                                                        <v-list-item :key="item.id">
                                                            <v-list-item-avatar>
                                                                <v-avatar color="secondary">
                                                                    <div class="text-subtitle-2 primary--text">{{ item.dia }}</div>
                                                                </v-avatar>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-virtual-scroll>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <kinesis-container>
                                <kinesis-element :strength="25" type="depth">
                                    <v-img src="@/assets/img/eon.jpeg" contain></v-img>
                                </kinesis-element>
                            </kinesis-container>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
    export default {
        data() {
            return {
                feed: [
                    { id: 1, titulo: 'Fechas de asignación de tabletas a elementos', icon: 'mdi-newspaper' },
                    { id: 2, titulo: 'Rutinas para sobresalir en evaluación médica', icon: 'mdi-lightbulb-on' },
                    { id: 3, titulo: 'Fechas de asignación de tabletas a elementos', icon: 'mdi-newspaper' },
                    { id: 4, titulo: 'Rutinas para sobresalir en evaluación médica', icon: 'mdi-lightbulb-on' },
                    { id: 5, titulo: 'Fechas de asignación de tabletas a elementos', icon: 'mdi-newspaper' },
                    { id: 6, titulo: 'Rutinas para sobresalir en evaluación médica', icon: 'mdi-lightbulb-on' },
                    { id: 7, titulo: 'Fechas de asignación de tabletas a elementos', icon: 'mdi-newspaper' },
                    { id: 8, titulo: 'Rutinas para sobresalir en evaluación médica', icon: 'mdi-lightbulb-on' },
                    { id: 9, titulo: 'Fechas de asignación de tabletas a elementos', icon: 'mdi-newspaper' },
                    { id: 10, titulo: 'Rutinas para sobresalir en evaluación médica', icon: 'mdi-lightbulb-on' },
                ],

                acontecimientos: [
                    { id: 1, titulo: 'Asignación de tabletas a elementos', dia: '02' },
                    { id: 2, titulo: 'Rutinas para sobresalir en evaluación médica', dia: '24' },
                    { id: 3, titulo: 'Publicación de convocatoria para candidatos', dia: '28' },
                ],

                imagenes: [
                    { id: 1, titulo: 'Fechas de asignación de tabletas a elementos', src: '@/assets/img/cartel1.jpg' },
                    { id: 2, titulo: 'Rutinas para sobresalir en evaluación médica', src: '@/assets/img/cartel2.jpg' },
                    { id: 3, titulo: 'Fechas de asignación de tabletas a elementos', src: '@/assets/img/cartel3.jpg' },
                    { id: 4, titulo: 'Rutinas para sobresalir en evaluación médica', src: '@/assets/img/cartel1.jpg' },
                    { id: 5, titulo: 'Fechas de asignación de tabletas a elementos', src: '@/assets/img/cartel2.jpg' },
                    { id: 6, titulo: 'Rutinas para sobresalir en evaluación médica', src: '@/assets/img/cartel3.jpg' },
                ],

                pickerDate: null,
            }
        },
    }
</script>
