<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Turnar preaspirante a C3</v-card-title>
                    <v-card-text align="center">
                        <div class="text-subtitle-1">¿Desea turnar al preaspirante a C3?</div>
                        <div class="text-subtitle-1">Documentación solicitada:</div>
                        <v-checkbox color="secondary" label="Curriculum"></v-checkbox>
                        <v-checkbox color="secondary" label="Acta de nacimiento"></v-checkbox>
                        <v-checkbox color="secondary" label="INE"></v-checkbox>
                        <v-checkbox color="secondary" label="CURP"></v-checkbox>
                        <v-checkbox color="secondary" label="Comprobante de domicilio no mayor a dos meses"></v-checkbox>
                        <v-checkbox color="secondary" label="Comprobante de último grado de estudios"></v-checkbox>
                        <v-checkbox color="secondary" label="Cartilla de servicio militar liberada y original"></v-checkbox>
                        <v-checkbox color="secondary" label="Últimos 2 recibos de nómina"></v-checkbox>
                        <v-checkbox color="secondary" label="Buró de crédito completo"></v-checkbox>
                        <v-checkbox color="secondary" label="Croquis de domicilio"></v-checkbox>
                        <v-checkbox
                            color="secondary"
                            label="12 referencias (3 vecinales, 3 personales no familiares, 3 compañeros de trabajo, 3 jefes inmediatos)"
                        ></v-checkbox>
                        <div color="secondary" class="text-subtitle-1">En caso de que aplique:</div>
                        <v-checkbox color="secondary" label="Estados de cuentas bancarias"></v-checkbox>
                        <v-checkbox color="secondary" label="Estados de cuenta departamentales"></v-checkbox>
                        <v-checkbox color="secondary" label="Copia de última declaraciónm patrimonial"></v-checkbox>
                        <v-checkbox color="secondary" label="Acta de matrimonio o en su caso sentencia de divorcio"></v-checkbox>
                        <v-checkbox color="secondary" label="Factura y tarjeta de circulación de vehículos"></v-checkbox>
                        <v-checkbox color="secondary" label="Copia de comprobantes de bienes muebles e inmuebles"></v-checkbox>
                        <v-checkbox color="secondary" label="Procedimientos administrativos, penales, averigüaciones previas, sanciones, etc."></v-checkbox>
                        <v-checkbox color="secondary" label="Constancia de rfc"></v-checkbox>
                        <v-checkbox color="secondary" label="Copia de los documentos referentes a negocios"></v-checkbox>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn text class="error--text px-8" :disabled="loading" to="/enlace_c3/preaspirante">Cancelar</v-btn>
                        <v-btn color="secondary" class="primary--text px-8" :disabled="loading" v-on:click="triggerTurnaC3()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),
        },

        methods: {
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),
            ...mapActions('solicitudes', ['saveSolicitud']),
            ...mapActions('personal', ['updateEstatusPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes', 'fetchAspirantes']),

            triggerTurnaC3: async function() {
                this.loading = true
                await this.updateEstatusPersonal({
                    idPersonal: this.id,
                    fk_idEstatus: 5,
                })

                await this.fetchPreaspirantes()
                await this.fetchAspirantes()
                this.$router.go(-1)
            },
        },
    }
</script>
