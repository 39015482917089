import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        sedes: [],
    },

    getters: {
        getSedes: state => {
            return state.sedes
        },
    },

    mutations: {
        SET_SEDES(state, sedes) {
            state.sedes = sedes
        },
    },

    actions: {
        fetchSedes: async function({ commit }) {
            await axios
                .get('/EonCloud/common/sedes')
                .then(response => {
                    commit('SET_SEDES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchSedesByRegion: async function({ commit }, region) {
            await axios
                .get('/EonCloud/common/regiones/' + region.id + '/sedes')
                .then(response => {
                    commit('SET_SEDES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
