<template>
    <v-dialog persistent width="90%" v-model="active">
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación de respeto a los principios y valores</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="respetoPrincipiosValores">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluado"
                                                    :value="getFullName(respetoPrincipiosValores.Personal)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluador"
                                                    :value="getFullName(respetoPrincipiosValores.PersonalEvaluador)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Municipio"
                                                    :value="respetoPrincipiosValores.CatMunicipio.nombre"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Fecha inicial"
                                                    :value="respetoPrincipiosValores.fechaInicial"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Fecha fin"
                                                    :value="respetoPrincipiosValores.fechaFin"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Fecha de aplicación"
                                                    :value="respetoPrincipiosValores.fechaAplicacion"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Institución de seguridad pública a la que aspira"
                                                    v-model="respetoPrincipiosValores.institucionSeguridadAspira"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Aspirante al puesto de"
                                                    v-model="respetoPrincipiosValores.aspiranteAlPuesto"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Reactivos</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(reactivo, index) in reactivos" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        {{ index + 1 }}
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <div class="text-subtitle-2">{{ reactivo.CatReactivo.title }}</div>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            label="Frecuencia de medición"
                                                            :value="respetoPrincipiosValores.frecuenciasMedicion[index]"
                                                            @input="setFrecuencia(reactivo.id, $event)"
                                                            rounded
                                                            filled
                                                            dense
                                                            :items="frecuencia"
                                                            item-value="id"
                                                            item-text="title"
                                                            item-color="secondary"
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Observaciones</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea
                                                    label="Observaciones"
                                                    v-model="respetoPrincipiosValores.observaciones"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    no-resize
                                                    rows="3"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="5">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Resultados</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Resultado</div>
                                                        <div class="text-subtitle-1">{{ getResultado }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Puntaje total</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Calificación</div>
                                                        <div class="text-subtitle-1">{{ getCalificacion }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveEvaluacionRespetoPrincipios()" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            idPersonal: {
                type: Number,
            },

            active: {
                type: Boolean,
            },

            respetoPrincipiosValores: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('periodicidad', {
                periodicidad: 'getPeriodicidad',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuencia: 'getFrecuenciaRespetoPrincipio',
            }),

            ...mapGetters('resultado', {
                resultado: 'getResultado',
            }),

            ...mapGetters('reactivoValor', {
                reactivos: 'getReactivoValor',
            }),

            getResultado: function() {
                // TODO: Ligar resultado con objeto a actualizar, ya que siempre lo deja con resultado Excelente

                if (this.getPuntajeTotal <= 38) {
                    this.respetoPrincipiosValores.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal > 38 && this.getPuntajeTotal < 45) {
                    this.respetoPrincipiosValores.fk_idResultado = 4
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal > 44 && this.getPuntajeTotal < 52) {
                    this.respetoPrincipiosValores.fk_idResultado = 3
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal > 51 && this.getPuntajeTotal < 58) {
                    this.respetoPrincipiosValores.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal > 57) {
                    this.respetoPrincipiosValores.fk_idResultado = 1
                    return 'Excelente'
                }
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0
                this.respetoPrincipiosValores.frecuenciasMedicion.forEach(reactivo => {
                    switch (reactivo.fk_idCatFrecuencia) {
                        case 1:
                            puntajeTotal = puntajeTotal + 4
                            break
                        case 2:
                            puntajeTotal = puntajeTotal + 3
                            break
                        case 3:
                            puntajeTotal = puntajeTotal + 2
                            break
                        case 4:
                            puntajeTotal = puntajeTotal + 1

                            break
                        case 5:
                            puntajeTotal = puntajeTotal + 0
                            break
                    }
                })

                return puntajeTotal
            },

            getCalificacion: function() {
                if (this.getPuntajeTotal == 0) {
                    return '0'
                } else if (this.getPuntajeTotal > 1 && this.getPuntajeTotal < 39) {
                    return '5'
                } else if (this.getPuntajeTotal > 38 && this.getPuntajeTotal < 45) {
                    return '6'
                } else if (this.getPuntajeTotal > 44 && this.getPuntajeTotal < 52) {
                    return '7'
                } else if (this.getPuntajeTotal > 51 && this.getPuntajeTotal < 58) {
                    return '8'
                } else if (this.getPuntajeTotal > 57 && this.getPuntajeTotal < 62) {
                    return '9'
                } else if (this.getPuntajeTotal > 61) {
                    return '10'
                }
            },
        },

        methods: {
            ...mapActions('respetoPrincipiosValores', ['updateRespetoPrincipiosValores', 'updateEstatus']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            triggerSaveEvaluacionRespetoPrincipios: async function() {
                this.loading = true

                this.respetoPrincipiosValores.puntajeTotal = this.getPuntajeTotal
                this.respetoPrincipiosValores.calificacion = this.getCalificacion

                await this.updateRespetoPrincipiosValores(this.respetoPrincipiosValores)
                await this.updateEstatus(this.respetoPrincipiosValores)
                await this.fetchEvaluacionesById(this.respetoPrincipiosValores.fk_idPersonalEvaluador)

                this.closeDialog()
            },

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },

            fecha: function(index) {
                return this.respetoPrincipiosValores.procedimientos[index].fecha
                    ? moment(this.respetoPrincipiosValores.procedimientos[index].fecha).format('DD/MM/YYYY')
                    : ''
            },

            setFrecuencia: function(id_reactivo, e) {
                const index = this.respetoPrincipiosValores.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idReactivoValor == id_reactivo)
                this.respetoPrincipiosValores.frecuenciasMedicion[index].fk_idCatFrecuencia = e
            },
        },
    }
</script>
