<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title>
                        <div class="text-h6 font-weight-bold">Estadística de elementos</div>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="text-subtitle-1 font-weight-bold">Elementos por turno</div>
                                            <apexchart width="100%" type="donut" :options="optionsTurno" :series="seriesTurno"></apexchart>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="text-subtitle-1 font-weight-bold">Elementos por tipo de función</div>
                                            <apexchart width="100%" type="donut" :options="optionsTipoFuncion" :series="seriesTipoFuncion"></apexchart>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="text-subtitle-1 font-weight-bold">Elementos por estatus</div>
                                            <apexchart width="100%" type="donut" :options="optionsEstatus" :series="seriesEstatus"></apexchart>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-subtitle-1 font-weight-bold">Elementos por puesto</div>
                                    <apexchart ref="apex" width="100%" type="bar" :options="optionsPuesto" :series="seriesPuesto"></apexchart>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters } from 'vuex'
    import validator from 'validator'

    export default {
        async mounted() {
            this.setBarData()
        },

        data() {
            return {
                calendario: '',

                selectedOpen: false,
                selectedEvent: {},
                selectedElement: null,

                optionsEstatus: {
                    chart: {
                        type: 'donut',
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                    ],
                    labels: [
                        'Activos',
                        'Acta administrativa',
                        'Baja definitivia',
                        'Franco / Vacaciones',
                        'L.S.G.S.',
                        'Suspendido',
                        'Incapacidad',
                        'Gravidez',
                        'Permiso por maternidad',
                        'L.C.G.S.',
                    ],
                },

                optionsTurno: {
                    chart: {
                        type: 'donut',
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                    ],
                    labels: ['24 Hrs.', 'Turno A', 'Turno B', 'Sin turno asignado'],
                },

                optionsTipoFuncion: {
                    chart: {
                        type: 'donut',
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200,
                                },
                                legend: {
                                    position: 'bottom',
                                },
                            },
                        },
                    ],
                    labels: ['Administrativa', 'Operativa', 'Operativa con funciones administrativas', 'Sin asignar'],
                },

                optionsPuesto: {
                    chart: {
                        id: 'vuechart-example',
                    },
                    xaxis: {
                        name: 'Puesto',
                        categories: [],
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true,
                        },
                        series: {
                            dataLabels: {
                                enabled: true,
                                formatter: function() {
                                    if (this.y > 0) return this.y
                                },
                            },
                        },
                    },
                },

                seriesPuesto: [
                    {
                        name: 'Puesto',
                        data: [],
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('puesto', {
                puestos: 'getPuestos',
            }),

            seriesEstatus() {
                var seriesEstatus = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

                this.personal.forEach(personal => {
                    switch (personal.fk_idEstatus) {
                        case 1:
                            seriesEstatus[0]++
                            break
                        case 2:
                            seriesEstatus[1]++
                            break
                        case 3:
                            seriesEstatus[2]++
                            break
                        case 4:
                            seriesEstatus[3]++
                            break
                        case 10:
                            seriesEstatus[4]++
                            break
                        case 11:
                            seriesEstatus[5]++
                            break
                        case 12:
                            seriesEstatus[6]++
                            break
                        case 14:
                            seriesEstatus[7]++
                            break
                        case 15:
                            seriesEstatus[8]++
                            break
                        case 16:
                            seriesEstatus[9]++
                            break

                        default:
                            break
                    }
                })

                return seriesEstatus
            },

            seriesTurno() {
                var seriesTurno = [0, 0, 0, 0]

                this.personal.forEach(personal => {
                    switch (personal.fk_idCatTurno) {
                        case 1:
                            seriesTurno[0]++
                            break
                        case 2:
                            seriesTurno[1]++
                            break
                        case 3:
                            seriesTurno[2]++
                            break
                        default:
                            seriesTurno[3]++
                            break
                    }
                })

                return seriesTurno
            },

            seriesTipoFuncion() {
                var seriesTipoFuncion = [0, 0, 0, 0]

                this.personal.forEach(personal => {
                    switch (personal.fk_idCatTipoFuncion) {
                        case 1:
                            seriesTipoFuncion[0]++
                            break
                        case 2:
                            seriesTipoFuncion[1]++
                            break
                        case 3:
                            seriesTipoFuncion[2]++
                            break
                        default:
                            seriesTipoFuncion[3]++
                            break
                    }
                })

                return seriesTipoFuncion
            },
        },

        methods: {
            setBarData: function() {
                var listaPuestos = []
                var cantidadPorPuesto = []

                this.puestos.forEach(puesto => {
                    if (this.personal.some(personal => personal.fk_idPuestoDependencia == puesto.fk_idPuesto)) {
                        cantidadPorPuesto.push(
                            this.personal.filter(personal => {
                                return personal.fk_idPuestoDependencia == puesto.fk_idPuesto
                            }).length
                        )
                        listaPuestos.push(puesto.catPuesto.puesto)
                    }
                })

                this.optionsPuesto = {
                    ...this.optionsPuesto,
                    ...{
                        xaxis: {
                            categories: listaPuestos,
                        },
                    },
                }

                this.$refs.apex.updateSeries([{ name: 'Cantidad de elementos', data: cantidadPorPuesto }])
            },
        },
    }
</script>
