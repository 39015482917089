<template>
    <v-card class="card" color="primary">
        <v-card-title>Productividad</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" align="center">
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 3" color="success">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 1" color="error">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="center">
                        <div class="text-caption">Puntaje total</div>
                        <div class="text-body-2">{{ evaluacion.puntajeTotal }}</div>
                    </v-col>
                    <v-col align="center">
                        <div class="text-caption">Calificacón</div>
                        <div class="text-body-2">{{ evaluacion.calificacion }}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-caption">Resultado</div>
                        <div class="text-body-2">{{ evaluacion.CatResultado.title }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn rounded color="secondary" @click="triggerDescargaEvaluacionProductividad()" :loading="loading" :disabled="loading"
                >Descargar evaluacion</v-btn
            >
        </v-card-actions>
    </v-card>
</template>
<script>
    import { saveAs } from 'file-saver'
    import axios from 'axios'
    import { publicPath } from '../../../../../vue.config'

    const ExcelJS = require('exceljs')
    let buffer = null
    let workbook = new ExcelJS.Workbook()
    let worksheet = null
    let cell = null

    export default {
        props: {
            evaluacion: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            async triggerDescargaEvaluacionProductividad() {
                this.loading = true

                await this.loadEvaluacion()
                this.loading = false
            },

            async loadEvaluacion() {
                await axios(publicPath + 'evaluacion_productividad.xlsx', { responseType: 'arraybuffer' })
                    // await axios('/evaluacion_productividad.xlsx', { responseType: 'arraybuffer' })
                    .catch(function(err) {
                        /* error in getting data */
                        console.log('Error in getting data: ' + err)
                    })
                    .then(async function(res) {
                        /* parse the data when it is received */
                        return res.data
                    })
                    .catch(function(err) {
                        /* error in parsing */
                        console.log('Error in parsin: ' + err)
                    })
                    .then(async data => {
                        await workbook.xlsx.load(data)

                        worksheet = workbook.getWorksheet(1)

                        cell = worksheet.getCell('D7')
                        cell.value = this.getFullName(this.evaluacion.Personal)
                        cell = worksheet.getCell('D9')
                        cell.value = this.evaluacion.Personal.CatPuestoDependencia ? this.evaluacion.Personal.CatPuestoDependencia.catPuesto.puesto : ''
                        cell = worksheet.getCell('D11')
                        cell.value = this.evaluacion.Personal.CatArea ? this.evaluacion.Personal.CatArea.nombre : ''
                        cell = worksheet.getCell('D13')
                        cell.value = this.evaluacion.Personal.CatPuestoDependencia ? this.evaluacion.Personal.CatPuestoDependencia.CatTipoPuesto.title : ''
                        cell = worksheet.getCell('H7')
                        cell.value = this.evaluacion.Personal.curp
                        cell = worksheet.getCell('H9')
                        cell.value = this.evaluacion.Personal.CUIP
                        cell = worksheet.getCell('H11')
                        cell.value = 'TLAXCALA'
                        cell = worksheet.getCell('K11')
                        cell.value = this.evaluacion.fechaAplicacion
                        cell = worksheet.getCell('I13')
                        cell.value = this.evaluacion.fechaInicial + ' - ' + this.evaluacion.fechaFin

                        let index_reactivos = 18
                        this.evaluacion.niveles.forEach(nivel => {
                            cell = worksheet.getCell('E' + index_reactivos)
                            cell.value = nivel.realizados

                            switch (nivel.fk_idCatFrecuencia) {
                                case 1:
                                    cell = worksheet.getCell('F' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 2:
                                    cell = worksheet.getCell('G' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 3:
                                    cell = worksheet.getCell('H' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 4:
                                    cell = worksheet.getCell('I' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 5:
                                    cell = worksheet.getCell('J' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                case 6:
                                    cell = worksheet.getCell('K' + index_reactivos)
                                    cell.value = 'X'
                                    break
                                default:
                                    break
                            }
                            index_reactivos += 1
                        })

                        let index_factores = 62

                        this.evaluacion.factores.forEach(factor => {
                            cell = worksheet.getCell('G' + index_factores)
                            cell.value = factor.cantidad
                            cell = worksheet.getCell('H' + index_factores)
                            cell.value = factor.descripcion
                            index_factores += 1
                        })

                        cell = worksheet.getCell('G73')
                        cell.value = this.getFullName(this.evaluacion.PersonalEvaluador)
                        cell = worksheet.getCell('G74')
                        cell.value = this.evaluacion.PersonalEvaluador.CatPuestoDependencia.catPuesto.puesto

                        worksheet.removeConditionalFormatting()
                        buffer = await workbook.xlsx.writeBuffer()

                        this.downloadEvaluacion()
                    })
            },

            downloadEvaluacion() {
                var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, 'evaluacion_productividad.xlsx')
            },
        },
    }
</script>
