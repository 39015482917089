import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoAsignacion: [],
    },

    getters: {
        getTipoAsignacion: state => {
            return state.tipoAsignacion
        },
    },

    mutations: {
        SET_TIPO_ASIGNACION: (state, tipoAsignacion) => {
            state.tipoAsignacion = tipoAsignacion
        },
    },

    actions: {
        fetchTipoAsignacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoAsignacion/')
                .then(response => {
                    commit('SET_TIPO_ASIGNACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
