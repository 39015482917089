import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        familiares: [],
    },

    getters: {
        getFamiliares: state => {
            return state.familiares
        },
    },

    mutations: {
        SET_FAMILIARES(state, familiares) {
            state.familiares = familiares
        },
    },

    actions: {
        fetchFamiliaresByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/EonCloud/EstadoFuerza/personal/' + idPersonal + '/familiares')
                .then(response => {
                    commit('SET_FAMILIARES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveFamiliar: async function(context, familiar) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + familiar.idPersonal + '/familiares', familiar)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Familiar agregado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateFamiliar: async function({ commit }, familiar) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + idPersonal + '/familiares/' + familiar.idFamiliar, familiar)
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
