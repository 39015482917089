<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="7">
                <v-text-field
                    v-model="buscaCurso"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar curso por nombre..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaCurso.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredCursos.length }} resultados encontrados con la búsqueda "{{ buscaCurso }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoCursos, index) in groupedCursos" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoCursos.tipo }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoCursos.cursos.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="curso in grupoCursos.cursos" :key="curso.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-calendar</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ curso.Subcategoria.subcategoria }}</div>
                                                        <div class="text-caption primary--text font-weight-bold">
                                                            {{ curso.Subcategoria.CatCategoria.categoria }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container></v-container>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    :to="{ name: 'asignacionCurso', params: { id: curso.id } }"
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-account-multiple-plus</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Asignar personal a curso</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    :to="{ name: 'evaluarCurso', params: { id: curso.id } }"
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-text-box-check</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Evaluar curso</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                buscaCurso: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('cursoProgramado', {
                cursosProgramados: 'getCursosProgramados',
            }),

            filteredCursos: function() {
                return this.cursosProgramados.filter(curso => {
                    return curso.Subcategoria.subcategoria.toLowerCase().indexOf(this.buscaCurso.toLowerCase()) >= 0
                })
            },

            groupedCursos: function() {
                var result = _(this.filteredCursos)
                    .groupBy(curso => curso.Subcategoria.CatCategoria.CatTipoCurso.title)
                    .map((cursos, tipo) => ({ tipo, cursos }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.tipo < b.tipo) {
                            return -1
                        }
                        if (a.tipo > b.tipo) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.tipo > b.tipo) {
                            return -1
                        }
                        if (a.tipo < b.tipo) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },
    }
</script>
