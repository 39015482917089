import { axios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        comprobantesEstudios: [],
    },

    getters: {
        getComprobantesEstudios: state => {
            return state.comprobantesEstudios
        },
    },

    mutations: {
        SET_COMPROBANTES_ESTUDIOS(state, comprobantesEstudios) {
            state.comprobantesEstudios = comprobantesEstudios
        },
    },

    actions: {
        fetchComprobantesEstudios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/comprobanteEstudios')
                .then(response => {
                    commit('SET_COMPROBANTES_ESTUDIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
