<template>
    <v-dialog v-model="active" fullscreen>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-on="on" v-bind="attrs">Agregar referencia</v-btn>
        </template>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-btn text @click="active = false">
                                <v-icon>mdi-arrow-left</v-icon>
                                Volver
                            </v-btn>
                        </v-col>
                        <v-col cols="8">
                            <personal-info-card-component :personal="personal"></personal-info-card-component>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" offset="4">
                    <agrega-referencia-form-component :id="id"></agrega-referencia-form-component>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay color="primary" opacity="1" z-index="-1"></v-overlay>
    </v-dialog>
</template>
<script>
    import AgregaReferenciaFormComponent from '../../components/AgregaReferenciaFormComponent.vue'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        components: {
            AgregaReferenciaFormComponent,
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        data() {
            return {
                active: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        methods: {
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
        },
    }
</script>
