import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cadenaResultados: [],
        cadenaResultadosDesempeñoAcademico: [],
    },

    getters: {
        getCadenaResultados: state => {
            return state.cadenaResultados
        },

        getCadenaResultadosDesempeñoAcademico: state => {
            return state.cadenaResultadosDesempeñoAcademico
        },
    },

    mutations: {
        SET_CADENA_RESULTADOS(state, cadenaResultados) {
            state.cadenaResultados = cadenaResultados
        },

        SET_CADENA_RESULTADOS_DESEMPEÑO_ACADEMICO(state, cadenaResultadosDesempeñoAcademico) {
            state.cadenaResultadosDesempeñoAcademico = cadenaResultadosDesempeñoAcademico
        },
    },

    actions: {
        fetchCadenaResultados: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/cadenaResultados')
                .then(response => {
                    commit('SET_CADENA_RESULTADOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchCadenaResultadosDesempeñoAcademico: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/cadenaResultadosDesempenioAcademico')
                .then(response => {
                    commit('SET_CADENA_RESULTADOS_DESEMPEÑO_ACADEMICO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
