<template>
    <v-container v-if="cursoProgramado">
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="card" color="#f2fff8">
                    <v-card-title class="font-weight-bold justify-center">Asignación de personal a curso</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-card color="secondary">
                                        <v-card-title class="justify-center d-block" align="center">
                                            <div class="text-subtitle-1 font-weight-bold primary--text">{{ cursoProgramado.Subcategoria.subcategoria }}</div>
                                            <div class="text-subtitle-2 primary--text">{{ cursoProgramado.Subcategoria.CatCategoria.categoria }}</div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="6">
                                                        <div class="text-body-1 primary--text">Capacitador</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-body-1 primary--text font-weight-bold">
                                                            {{ getFullName(cursoProgramado.Capacitador) }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-body-1 primary--text">Aula</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-body-1 primary--text font-weight-bold">
                                                            {{ cursoProgramado.Aula.aula }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-body-1 primary--text">Elementos asignados</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-body-1 primary--text font-weight-bold">
                                                            {{ cantidadInscritos }} de {{ cursoProgramado.Aula.capacidad }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="8">
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.nombre"
                                                placeholder="Nombre"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.segundoNombre"
                                                placeholder="Segundo nombre"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.apaterno"
                                                placeholder="Apellido paterno"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.amaterno"
                                                placeholder="Apellido materno"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.curp"
                                                placeholder="CURP"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="busquedaPersonal.CUIP"
                                                placeholder="CUIP"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                :disabled="loadingBusqueda"
                                                hide-details
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="4">
                                            <v-btn
                                                rounded
                                                block
                                                large
                                                color="secondary"
                                                @click="triggerSearchPersonal()"
                                                :disabled="loadingBusqueda || loading"
                                                :loading="loadingBusqueda"
                                            >
                                                Buscar <v-icon class="ml-2">mdi-magnify</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-virtual-scroll :items="filteredPersonal" item-height="148" height="440">
                                        <template v-slot:default="{ item }">
                                            <v-list-item :key="item.idPersonal">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-avatar size="80">
                                                        <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                                        <v-icon size="48" v-else>mdi-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <div v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                                            <div v-else>CUIP no asignado</div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle> Estatus: {{ item.CatEstatus.title }} </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Vigencia Formación Inicial:
                                                            {{ item.vigenciaFormacionInicial === '' ? 'No registrado' : item.vigenciaFormacionInicial }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Vigencia Competencias Básicas:
                                                            {{ item.vigenciaCompetenciasBasicas === '' ? 'No registrado' : item.vigenciaCompetenciasBasicas }}
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Vigencia C3:
                                                            {{ item.vigenciaEvaluacionC3 === '' ? 'No registrado' : item.vigenciaEvaluacionC3 }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-checkbox v-model="selectedPersonal" :value="item.idPersonal" color="#34A871"></v-checkbox>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn
                            color="secondary"
                            class="primary--text"
                            :loading="loading"
                            v-on:click="triggerAsignaCursoPersonal()"
                            :disabled="loading || selectedPersonal.length == 0"
                            >Asignar personal seleccionado</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_CURSO_PROGRAMADO(this.id)
        },

        data() {
            return {
                loading: false,
                loadingBusqueda: false,
                activeStep: 2,
                courseAssignmentForm: false,
                fechaSolicitud: null,
                fechaAsignación: null,
                observaciones: '',
                evidencia: '',
                selectedPersonal: [],
                personalSearchField: '',

                busquedaPersonal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: '',
                },
            }
        },

        computed: {
            filteredPersonal: function() {
                return this.filteredPersonalDisponible.filter(personal => {
                    return (
                        personal.nombre.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.segundoNombre.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.apaterno.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0 ||
                        personal.amaterno.toLowerCase().indexOf(this.personalSearchField.toLowerCase()) >= 0
                    )
                })
            },

            filteredPersonalDisponible: function() {
                if (this.personal.length > 0) {
                    return this.personal.filter(personal => {
                        if (
                            !this.cursosAsignados.some(
                                cursoPersonal => cursoPersonal.fk_idProgramacionCurso == this.id && cursoPersonal.fk_idPersonal == personal.idPersonal
                            )
                        ) {
                            return personal
                        }
                    })
                } else return []
            },

            cantidadInscritos: function() {
                return this.cursosAsignados.filter(cursoAsignado => {
                    return cursoAsignado.fk_idProgramacionCurso == this.id
                }).length
            },

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },

            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('cursoProgramado', {
                cursoProgramado: 'getCursoProgramado',
            }),

            ...mapGetters('cursoAsignado', {
                cursosAsignados: 'getCursosAsignados',
            }),
        },

        methods: {
            ...mapActions('cursoAsignado', ['saveCursoAsignado', 'fetchCursosAsignados']),
            ...mapMutations('cursoProgramado', ['SET_CURSO_PROGRAMADO']),
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            triggerAsignaCursoPersonal: async function() {
                this.loading = true

                var listaPersonal = []

                this.selectedPersonal.forEach(personal => {
                    listaPersonal.push({
                        fk_idProgramacionCurso: this.id,
                        fk_idPersonal: personal,
                        comentario: '',
                    })
                })
                await this.saveCursoAsignado(listaPersonal)
                await this.fetchCursosAsignados()
                this.loading = false

                this.RESET_BUSQUEDA_PERSONAL()
                this.busquedaPersonal.CUIP = ''
            },

            buscaPersonal() {
                return new Promise(async () => {
                    setTimeout(await this.searchPersonal(this.busquedaPersonal), 200)
                    this.loadingBusqueda = false
                })
            },

            triggerSearchPersonal() {
                this.loadingBusqueda = true
                if (
                    this.busquedaPersonal.nombre !== '' ||
                    this.busquedaPersonal.segundoNombre !== '' ||
                    this.busquedaPersonal.apaterno !== '' ||
                    this.busquedaPersonal.amaterno !== '' ||
                    this.busquedaPersonal.curp !== '' ||
                    this.busquedaPersonal.CUIP !== ''
                ) {
                    this.buscaPersonal()
                } else {
                    this.loadingBusqueda = false
                    this.RESET_BUSQUEDA_PERSONAL()
                }
            },
        },
    }
</script>
