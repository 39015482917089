import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        puestos: [],
    },

    getters: {
        getPuestos: state => {
            return state.puestos
        },
    },

    mutations: {
        SET_PUESTOS(state, puestos) {
            state.puestos = puestos
        },
    },

    actions: {
        fetchPuestos: async function({ commit }) {
            await axios
                .get('/EonCloud/common/puestos')
                .then(response => {
                    commit('SET_PUESTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchPuestosByDependencia: async function({ commit }, dependencia) {
            await axios
                .get('/EonCloud/common/dependencias/' + dependencia + '/puestos')
                .then(response => {
                    commit('SET_PUESTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
