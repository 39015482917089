import { axios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        tipoNacionalidad: [],
    },
    getters: {
        getTipoNacionalidad: state => {
            return state.tipoNacionalidad
        },
    },
    mutations: {
        SET_TIPO_NACIONALIDAD(state, tipoNacionalidad) {
            state.tipoNacionalidad = tipoNacionalidad
        },
    },
    actions: {
        fetchTipoNacionalidad: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/tipoNacionalidad')
                .then(response => {
                    commit('SET_TIPO_NACIONALIDAD', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
