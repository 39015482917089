<template>
    <v-dialog width="480" v-model="active" persistent>
        <v-card>
            <v-card-title>
                <div class="text-h6">Consulta de arma en loc</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="armaLoc.length == 0">
                <div class=" text-subtitle-1">
                    El arma no se encuentra registrada en la Licencia Oficial Colectiva.
                </div>
                <v-btn color="secondary" @click="triggerAltaArmaLoc()">Dar de alta Arma en Loc</v-btn>
            </v-card-text>
            <v-card-text v-else>
                <div class="success--text text-subtitle-1">
                    El arma se encuentra registrada en la Licencia Oficial Colectiva.
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            arma: {
                type: Object,
            },
        },

        computed: {
            ...mapGetters('armaLoc', {
                armaLoc: 'getArmaLoc',
            }),
        },

        methods: {
            ...mapActions('armaLoc', ['saveArmaLoc']),

            async triggerAltaArmaLoc() {
                this.loading = true
                await this.saveArmaLoc({
                    idArma: this.arma.idArma,
                    fk_idLoc: 1,
                })

                this.closeDialog()
            },

            closeDialog() {
                this.$emit('close')
            },
        },
    }
</script>
