import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estados: [],
    },

    getters: {
        getEstados: state => {
            return state.estados
        },
    },

    mutations: {
        SET_ESTADOS(state, estados) {
            state.estados = estados
        },
    },

    actions: {
        fetchEstados: async function({ commit }) {
            await axios
                .get('/EonCloud/estados')
                .then(response => {
                    commit('SET_ESTADOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
