import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        respetoPrincipiosValores: [],
        respetoPrincipiosValoresPersonal: null,
    },

    getters: {
        getRespetoPrincipiosValores: state => {
            return state.respetoPrincipiosValores
        },

        getRespetoPrincipiosValoresPersonal: state => {
            return state.respetoPrincipiosValoresPersonal
        },
    },

    mutations: {
        SET_RESPETO_PRINCIPIOS_VALORES(state, respetoPrincipiosValores) {
            state.respetoPrincipiosValores = respetoPrincipiosValores
        },

        SET_RESPETO_PRINCIPIOS_VALORES_PERSONAL(state, respetoPrincipiosValoresPersonal) {
            state.respetoPrincipiosValoresPersonal = respetoPrincipiosValoresPersonal[0]
        },
    },

    actions: {
        fetchRespetoPrincipiosValores: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/respetoprincipiosvalores')
                .then(response => {
                    commit('SET_RESPETO_PRINCIPIOS_VALORES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchRespetoPrincipiosValoresById: async function({ commit }, evaluacion) {
            await axios
                .get(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        evaluacion.fkidPersonal +
                        '/respetoprincipiosvalores/' +
                        evaluacion.idRespetoPrincipiosValores
                )
                .then(response => {
                    commit('SET_RESPETO_PRINCIPIOS_VALORES_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveRespetoPrincipiosValores: async function({ commit }, respetoPrincipiosValores) {
            await axios
                .post(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + respetoPrincipiosValores.fk_idPersonal + '/respetoprincipiosvalores',
                    respetoPrincipiosValores
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateRespetoPrincipiosValores: async function({ commit }, respetoPrincipiosValores) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        respetoPrincipiosValores.fk_idPersonal +
                        '/respetoPrincipiosValores/' +
                        respetoPrincipiosValores.id,
                    respetoPrincipiosValores
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatus: async function({ commit }, respetoPrincipiosValores) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        respetoPrincipiosValores.fk_idPersonal +
                        '/respetoPrincipiosValores/' +
                        respetoPrincipiosValores.id +
                        '/estatus',
                    {
                        fk_idCatEstatusEvaluacion: 3,
                    }
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
