<template>
    <v-dialog v-model="active" width="50%" persistent>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Editar arma</div>
                <v-spacer></v-spacer>
                <v-btn color="error" icon @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="editArma">
                <v-container>
                    <v-form v-model="armaForm" ref="form">
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="editArma.CatClase.fk_idTipo"
                                    v-on:change="editArma.fk_idClase = null"
                                    :items="tipoArma"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="title"
                                    item-value="id"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Tipo de arma <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    v-model="editArma.fk_idClase"
                                    :items="getClaseArma"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="clase"
                                    item-value="id"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Clase <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                    label="Marca"
                                    v-model="editArma.CatModelo.fk_idMarca"
                                    v-on:change="triggerFetchModeloArmas($event)"
                                    :items="marca"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="title"
                                    item-value="id"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Marca <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                    v-model="editArma.fk_idModelo"
                                    :items="modeloArma"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="modelo"
                                    item-value="id"
                                    item-color="secondary"
                                    :disabled="loadingModelos"
                                    :rules="required"
                                >
                                    <template v-slot:label>Modelo <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                    v-model="editArma.fk_idCalibre"
                                    :items="calibres"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="title"
                                    item-value="id"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Calibre <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                    v-model="editArma.fk_idCatProcedenciaArma"
                                    :items="procedencia"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-text="title"
                                    item-value="id"
                                    item-color="secondary"
                                >
                                    <template v-slot:label>Procedencia <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Matrícula"
                                    v-model="editArma.matricula"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Número económico"
                                    v-model.number="editArma.noEconomico"
                                    type="number"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Cantidad de cargadores"
                                    v-model.number="editArma.cantidadCargadores"
                                    type="number"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    min="0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Folio C"
                                    v-model="editArma.folioC"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Folio D"
                                    v-model="editArma.folioD"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Armero"
                                    v-model="editArma.armeros"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" @click="triggerUpdateArma()" :loading="loading" :disabled="loading || !armaForm">Guardar cambios</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import _ from 'lodash'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            arma: {
                type: Object,
            },
        },

        data() {
            return {
                editArma: null,
                armaForm: true,
                loading: false,
                loadingModelos: false,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('modeloArma', {
                modeloArma: 'getModelosArmas',
            }),

            ...mapGetters('claseArma', {
                clasesArmaCorta: 'getClasesArmaCorta',
                clasesArmaLarga: 'getClasesArmaLarga',
            }),

            ...mapGetters('tipoArma', {
                tipoArma: 'getTipoArmas',
            }),

            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),

            ...mapGetters('procedencia', {
                procedencia: 'getProcedencia',
            }),

            ...mapGetters('marcaArma', {
                marca: 'getMarcaArma',
            }),

            getClaseArma: function() {
                if (this.editArma) {
                    if (this.editArma.CatClase) {
                        if (this.editArma.CatClase.fk_idTipo == 2) {
                            return this.clasesArmaCorta
                        } else return this.clasesArmaLarga
                    } else return []
                }
            },
        },

        watch: {
            arma: {
                deep: true,
                handler(newArma) {
                    this.editArma = _.cloneDeep(newArma)

                    if (!this.editArma.CatClase) {
                        this.editArma.CatClase = {
                            fk_idTipo: null,
                        }
                    }
                },
            },
        },

        methods: {
            ...mapActions('claseArma', ['fetchClasesArma']),
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('arma', ['updateArma', 'fetchArmas']),

            closeDialog() {
                this.loading = false
                this.$emit('close')
                this.$refs.form.resetValidation()
            },

            async triggerFetchModeloArmas(event) {
                this.loadingModelos = true
                this.editArma.fk_idModelo = null
                await this.fetchModeloArmas(event)
                this.loadingModelos = false
            },

            async triggerUpdateArma() {
                this.loading = true
                this.editArma.fechaAltaLoc = '01/01/1999'
                await this.updateArma(this.editArma)
                await this.fetchArmas()

                this.closeDialog()
            },
        },
    }
</script>
