import { axios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        estatusEstudios: [],
    },
    getters: {
        getEstatusEstudios: state => {
            return state.estatusEstudios
        },
    },
    mutations: {
        SET_ESTATUS_ESTUDIOS(state, estatusEstudios) {
            state.estatusEstudios = estatusEstudios
        },
    },
    actions: {
        fetchEstatusEstudios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/estatusEstudios')
                .then(response => {
                    commit('SET_ESTATUS_ESTUDIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
