import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposSede: [],
    },

    getters: {
        getTiposSede: state => {
            return state.tiposSede
        },
    },

    mutations: {
        SET_TIPOS_SEDE(state, tiposSede) {
            state.tiposSede = tiposSede
        },
    },

    actions: {
        fetchTiposSede: async function({ commit }) {
            await axios
                .get('/EonCloud/common/tiposedes')
                .then(response => {
                    commit('SET_TIPOS_SEDE', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
