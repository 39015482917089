<template>
    <v-container v-if="personal">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de área de adscripción</v-card-title>
                    <v-card-text>
                        <v-container class="mb-4">
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 10" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.capturo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Capturó"
                                        ></v-text-field>
                                        <v-date-picker v-model="personal.fechaCaptura" locale="es" color="secondary" no-title full-width></v-date-picker>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.ncp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="NCP"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.smn"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="SMN"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 3">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.rangoCategoria"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Rango o categoría"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.categorizacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categorización"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.jerarquiaPuesto"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Jerarquía de puesto"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.categoriaPuestoCnca"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categoría de puesto CNCA"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 4">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-subtitle-2">Fecha ingreso edo fuerza</div>
                                        <v-date-picker
                                            v-model="personal.fechaIngresoEdoFza"
                                            locale="es"
                                            color="secondary"
                                            no-title
                                            full-width
                                        ></v-date-picker>
                                        <v-text-field
                                            v-model="personal.sueldoBase"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Sueldo base"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.criticidadPuestoOperativo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Criticidad de puesto operativo"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.funcional"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funcional"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.estatusSnsp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Estatus SNSP"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.estarLn"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Debe estar en LN"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.vigenciaAnio"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Vigencia año"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 5">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="personal.funciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funciones"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 6">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.unidadInvestigacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad de investigación"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.unidadOperaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad operaciones"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="personal.unidadAnalisisTactico"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad de análisis táctico"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 7">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.examenC3Mes"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Exámen C3 mes"
                                        ></v-text-field>
                                        <v-textarea
                                            v-model="personal.observacionesC3"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones C3"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 8">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-date-picker v-model="personal.fechaBaja" locale="es" color="secondary" no-title full-width></v-date-picker>
                                        <v-text-field
                                            v-model="personal.motivoBaja"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Motivo de baja"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 9">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="personal.observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import { circularProgressProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        data() {
            return {
                circularProgressProps,

                loading: false,
                activeStep: 1,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Acerca de la captura'
                    case 2:
                        return 'Información de documentación'
                    case 3:
                        return 'Rangos y categorías'
                    case 4:
                        return 'Acerca del puesto'
                    case 5:
                        return 'Funciones'
                    case 6:
                        return 'Unidades'
                    case 7:
                        return 'Información de C3'
                    case 8:
                        return 'Información de baja'
                    case 9:
                        return 'Observaciones'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    default:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 9) {
                    return 'Siguiente paso'
                } else {
                    return 'Guardar registro'
                }
            },
        },

        methods: {
            ...mapActions('personal', ['updatePersonal', 'fetchPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 9) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.personal.capturo = this.capturo
                    if (this.fechaCaptura) {
                        this.personal.fechaCaptura = moment(this.fechaCaptura).format('DD/MM/YYYY')
                    }
                    this.personal.ncp = this.ncp
                    this.personal.smn = this.smn
                    this.personal.escolaridad = this.escolaridad
                    this.personal.rangoCategoria = this.rangoCategoria
                    this.personal.funciones = this.funciones
                    this.personal.categorizacion = this.categorizacion
                    this.personal.sueldoBase = this.sueldoBase
                    this.personal.jerarquiaPuesto = this.jerarquiaPuesto
                    this.personal.criticidadPuestoOperativo = this.criticidadPuestoOperativo
                    this.personal.categoriaPuestoCnca = this.categoriaPuestoCnca
                    this.personal.funcional = this.funcional
                    this.personal.unidadInvestigacion = this.unidadInvestigacion
                    this.personal.unidadOperaciones = this.unidadOperaciones
                    this.personal.unidadAnalisisTactico = this.unidadAnalisisTactico
                    if (this.fechaIngresoEdoFza) {
                        this.personal.fechaIngresoEdoFza = moment(this.fechaIngresoEdoFza).format('DD/MM/YYYY')
                    }
                    this.personal.estatusSnsp = this.estatusSnsp
                    this.personal.estarLn = this.estarLn
                    if (this.fechaBaja) {
                        this.personal.fechaBaja = moment(this.fechaBaja).format('DD/MM/YYYY')
                    }
                    this.personal.motivoBaja = this.motivoBaja
                    this.personal.observaciones = this.observaciones
                    this.personal.examenC3Mes = this.examenC3Mes
                    this.personal.vigenciaAnio = this.vigenciaAnio
                    this.personal.observacionesC3 = this.observacionesC3

                    await this.updatePersonal(this.personal)
                    this.SET_SNACKBAR_MESSAGE('Los campos de registro personal han sido actualizados.')
                    this.$router.push('/recursos_humanos/personal')
                }
            },
        },
    }
</script>
