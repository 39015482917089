import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        periodicidad: [],
    },

    getters: {
        getPeriodicidad: state => {
            return state.periodicidad
        },
    },
    mutations: {
        SET_PERIODICIDAD(state, periodicidad) {
            state.periodicidad = periodicidad
        },
    },
    actions: {
        fetchPeriodicidad: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/periodicidad')
                .then(response => {
                    commit('SET_PERIODICIDAD', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
