<template>
    <v-card class="card" color="primary" v-if="personal">
        <v-card-title>
            <div class="text-h6 font-weight-bold">Información del elemento</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row align="center">
                    <v-col cols="2">
                        <v-img v-if="personal.foto != ''" :src="personal.foto" width="120"></v-img>
                        <v-avatar v-else color="secondary" rounded size="80">
                            <v-icon size="64" color="white">mdi-account</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="10">
                                <div class="text-caption">Nombre</div>
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(personal) }}</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="text-caption">Edad</div>
                                <div class="text-subtitle-1 font-weight-bold">{{ getAge(personal.fechaNacimiento) }} años</div>
                            </v-col>
                            <v-col>
                                <div class="text-caption">Fecha de nacimiento</div>
                                <div class="text-subtitle-1 font-weight-bold">{{ personal.fechaNacimiento }}</div>
                            </v-col>
                            <v-col>
                                <div class="text-caption">Sexo</div>
                                <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatSexo">{{ personal.CatSexo.title }}</div>
                                <div class="text-subtitle-1 font-weight-bold" v-else>No registrado</div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="text-caption">Puesto</div>
                                <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatPuestoDependencia">
                                    {{ personal.CatPuestoDependencia.catPuesto.puesto }}
                                </div>
                            </v-col>
                            <v-col>
                                <div class="text-caption">Dependencia</div>
                                <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatPuestoDependencia">
                                    {{ personal.CatPuestoDependencia.catDependencia.nombre }}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="text-caption">Adscripción</div>
                                <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatArea">
                                    {{ personal.CatArea.nombre }}
                                </div>
                            </v-col>
                            <v-col>
                                <div class="text-caption">Subárea</div>
                                <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatSubArea">
                                    {{ personal.CatSubArea.nombre }}
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        props: {
            personal: {
                type: Object,
            },
        },
    }
</script>
