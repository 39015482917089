import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusCurso: [],
    },

    getters: {
        getEstatusCurso: state => {
            return state.estatusCurso
        },
    },

    mutations: {
        SET_ESTATUS_CURSO(state, estatusCurso) {
            state.estatusCurso = estatusCurso
        },
    },

    actions: {
        fetchEstatusCurso: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/estatusCurso')
                .then(response => {
                    commit('SET_ESTATUS_CURSO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
