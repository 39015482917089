import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        accesorios: [],
    },

    getters: {
        getAccesorios: state => {
            return state.accesorios
        },
    },

    mutations: {
        SET_ACCESORIOS: (state, accesorios) => {
            state.accesorios = accesorios
        },
    },

    actions: {
        fetchAccesorios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/accesorios')
                .then(response => {
                    commit('SET_ACCESORIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveAccesorio: async function(context, accesorio) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/accesorios/', accesorio)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Accesorio registrado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateAccesorio: async function(context, accesorio) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/accesorios/' + accesorio.id, accesorio)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Accesorio editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
