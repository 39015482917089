<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Asignación de munición</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">
                                        PASO {{ activeStep }}
                                        <div v-if="activeStep == 3" class="d-inline">(Opcional)</div>
                                    </div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <v-select
                                            v-model="municionCurso.fk_idCurso"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="cursos"
                                            item-value="id"
                                            item-text="subcategoria"
                                        >
                                            <template v-slot:label>Seleccione un curso <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-date-picker
                                            v-model="municionCurso.fechaAsignado"
                                            locale="es"
                                            color="secondary"
                                            :max="getMaxDay"
                                            no-title
                                            full-width
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="8" class="py-0">
                                                <v-select
                                                    v-model="municionCurso.fk_idCatCalibre"
                                                    solo
                                                    filled
                                                    rounded
                                                    color="secondary"
                                                    background-color="primary"
                                                    :items="calibres"
                                                    item-value="id"
                                                    item-text="title"
                                                    item-color="secondary"
                                                >
                                                    <template v-slot:label>Seleccione calibre <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4" class="py-0">
                                                <v-text-field
                                                    v-model.number="municionCurso.cantidad"
                                                    min="0"
                                                    background-color="primary"
                                                    color="secondary"
                                                    type="number"
                                                    rounded
                                                    filled
                                                    dense
                                                >
                                                    <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                activeStep: 1,
                loading: false,

                required: [v => !!v || 'Este campo es requerido.'],

                municionCurso: {
                    fk_idCurso: null,
                    fk_idCatCalibre: null,
                    cantidad: 0,
                    fechaAsignado: null,
                },
            }
        },

        computed: {
            ...mapGetters('curso', {
                cursos: 'getCursos',
            }),

            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Elegir Curso'
                    case 2:
                        return 'Fecha de asignación'
                    case 3:
                        return 'Información de asignación'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.municionCurso.fk_idCurso) {
                            return true
                        } else return false
                    case 2:
                        if (this.municionCurso.fechaAsignado) {
                            return true
                        } else return false
                    case 3:
                        if (this.municionCurso.cantidad > 0 && this.municionCurso.fk_idCatCalibre) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Asignar municiones'
                }
            },
        },

        methods: {
            ...mapActions('municionCurso', ['saveMunicionCurso']),
            ...mapActions('movimientoMunicion', ['fetchMovimientoMunicion']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.municionCurso.fechaAsignado = moment(this.municionCurso.fechaAsignado).format('DD/MM/YYYY')
                    await this.saveMunicionCurso(this.municionCurso)
                    await this.fetchMovimientoMunicion()

                    this.loading = false

                    this.$router.go(-1)
                }
            },
        },
    }
</script>
