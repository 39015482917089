<template>
    <v-container v-if="personal" fluid>
        <v-row>
            <v-col cols="4">
                <v-btn text class="mx-2" v-on:click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon> Volver a catálogo de personal</v-btn>
            </v-col>
            <v-col cols="8" align="right">
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    readonly
                    color="secondary"
                    class="mx-2"
                    v-on:click="
                        download(
                            'http://ssctlaxcala.eonproduccion.net/CICG_SEG/ESTADO_FUERZA/Files/Documentos/downloadzip.php?idPersonal=' + personal.idPersonal
                        )
                    "
                    >Descargar archivos</v-btn
                >
                <v-btn v-on:click="download(personal.foto)" rounded color="secondary" class="mx-2" v-if="personal.foto.length > 0"
                    >Descargar fotografía</v-btn
                >
            </v-col>
            <v-col cols="3">
                <v-row>
                    <v-col cols="12">
                        <personal-card-component :personal="personal" :enrolamiento="enrolamiento[0]"></personal-card-component>
                    </v-col>
                    <v-col cols="12">
                        <menu-list-component :loading="loadingPersonalData" :estatus="personal.fk_idEstatus"></menu-list-component>
                    </v-col>
                </v-row>
            </v-col>
            <template v-if="loadingPersonalData == false">
                <v-col cols="9">
                    <datos-generales-component v-if="selectedMenu == 1" :personal="personal" :estudios="estudiosPersonal"></datos-generales-component>
                    <referencias-component
                        v-if="selectedMenu == 2"
                        :personal="personal"
                        :referencias="referencias"
                        :familiares="familiares"
                    ></referencias-component>
                    <documentos-component v-if="selectedMenu == 3" :documentos="documentos" :id="personal.idPersonal"></documentos-component>
                    <desarrollo-elemento-component
                        v-if="selectedMenu == 4"
                        :evaluaciones="evaluacionesMedicas"
                        :dictamenes="dictamenes"
                        :biometricos="biometricosPersonal"
                        :cursos="cursos"
                        :evaluaciones-productividad="evaluacionesPersonalProductividad"
                        :armas="armasPersonal"
                        :equipamiento="equipamientoPersonal"
                        :estatus="personal.fk_idEstatus"
                    ></desarrollo-elemento-component>
                    <puesto-dependencia-component v-if="selectedMenu == 5" :personal="personal"></puesto-dependencia-component>
                    <area-adscripcion-component v-if="selectedMenu == 6" :personal="personal"></area-adscripcion-component>
                    <faltas-administrativas-component v-if="selectedMenu == 8" :faltas="faltasAdministrativas"></faltas-administrativas-component>
                    <linea-tiempo-component v-if="selectedMenu == 7" :permutaciones="permutaciones"></linea-tiempo-component>
                    <media-filiacion-component
                        v-if="selectedMenu == 9"
                        :media-filiacion="personal.mediafiliacion"
                        :senias-particulares="personal.seniasParticulares"
                    ></media-filiacion-component>
                    <carrera-policial-component
                        v-if="selectedMenu == 11"
                        :convocatorias="convocatorias"
                        :condecoraciones="condecoraciones"
                        :personal="personal"
                    ></carrera-policial-component>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="9">
                    <v-skeleton-loader type="image" height="100%"></v-skeleton-loader>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<style scoped>
    .selectable {
        cursor: pointer;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import _ from 'lodash'
    import PersonalCardComponent from '../../components/expediente/PersonalCardComponent.vue'
    import MenuListComponent from '../../components/expediente/MenuListComponent.vue'
    import DatosGeneralesComponent from '../../components/expediente/DatosGeneralesComponent.vue'
    import DocumentosComponent from '../../components/expediente/DocumentosComponent.vue'
    import DesarrolloElementoComponent from '../../components/expediente/DesarrolloElemento.vue'
    import AreaAdscripcionComponent from '../../components/expediente/AreaAdscripcionComponent.vue'
    import ReferenciasComponent from '../../components/expediente/ReferenciasComponent.vue'
    import PuestoDependenciaComponent from '../../components/expediente/PuestoDependenciaComponent'
    import FaltasAdministrativasComponent from '../../components/expediente/FaltasAdministrativasComponent.vue'
    import FamiliaresComponent from '../../components/expediente/FamiliaresComponent.vue'
    import LineaTiempoComponent from '../../components/expediente/LineaTiempoComponent.vue'
    import MediaFiliacionComponent from '../../components/expediente/MediaFiliacionComponent.vue'
    import CarreraPolicialComponent from '../../components/expediente/CarreraPolicialComponent.vue'

    export default {
        components: {
            PersonalCardComponent,
            MenuListComponent,
            DatosGeneralesComponent,
            DocumentosComponent,
            DesarrolloElementoComponent,
            AreaAdscripcionComponent,
            ReferenciasComponent,
            PuestoDependenciaComponent,
            FaltasAdministrativasComponent,
            FamiliaresComponent,
            LineaTiempoComponent,
            MediaFiliacionComponent,
            CarreraPolicialComponent,
        },

        async mounted() {
            await this.SET_SELECTED_MENU(1)
            this.loadingPersonalData = true
            await this.fetchPersonalById(this.id)
            await this.fetchEstudiosByPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            await this.fetchEvaluacionMedicaByPersonal(this.id)
            await this.fetchDictamenPersonal(this.id)
            await this.fetchArmasPersonal(this.id)
            await this.fetchBiometricosPersonal(this.id)
            await this.fetchAsignacionEquipoByPersonal(this.id)
            await this.fetchCursosAsignadosByPersonal(this.id)
            await this.fetchReferenciasByPersonal(this.id)
            await this.fetchFaltasAdministrativasByPersonal(this.id)
            await this.fetchFamiliaresByPersonal(this.id)
            await this.fetchPermutacionesByPersonal(this.id)
            await this.fetchConvocatoriaPersonal(this.id)
            await this.fetchCondecoracionPersonal(this.id)
            await this.fetchCadenaResultados(this.id)
            await this.fetchCadenaResultadosDesempeñoAcademico(this.id)
            await this.fetchProductividad(this.id)
            await this.fetchRespetoPrincipios(this.id)
            await this.fetchDisciplinaOperativa(this.id)
            await this.fetchDisciplinaAdministrativa(this.id)
            await this.fetchEnrolamientoByPersonal(this.id)
            await this.fetchRespetoPrincipiosValores(this.id)
            await this.fetchDesempeñoInstitucional(this.id)
            await this.fetchRendimientoProfesionalismo(this.id)
            await this.fetchEvaluacionAnual(this.id)

            this.loadingPersonalData = false
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                nameRules: [v => !!v || 'Este campo es requerido.'],
                loadingPersonalData: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalSingle',
            }),

            ...mapGetters('estudio', {
                estudios: 'getEstudios',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            ...mapGetters('biometrico', {
                biometricosPersonal: 'getBiometricosPersonal',
            }),

            ...mapGetters('asignacionEquipo', {
                equipamientoPersonal: 'getAsignacionesEquipo',
            }),

            ...mapGetters('cursoAsignado', {
                cursos: 'getCursosAsignadosPersonal',
            }),

            ...mapGetters('productividad', {
                evaluacionesProductividad: 'getProductividad',
            }),

            ...mapGetters('respetoPrincipios', {
                evaluacionesRespetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('expediente', {
                selectedMenu: 'getSelectedMenu',
            }),

            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicas: 'getEvaluacionMedica',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenes: 'getDictamenPersonal',
            }),

            ...mapGetters('referencias', {
                referencias: 'getReferencias',
            }),

            ...mapGetters('permutacion', {
                permutaciones: 'getPermutaciones',
            }),

            ...mapGetters('faltasAdministrativas', {
                faltasAdministrativas: 'getFaltasAdministrativas',
            }),

            ...mapGetters('familiares', {
                familiares: 'getFamiliares',
            }),

            ...mapGetters('convocatoriaPersonal', {
                convocatorias: 'getConvocatoriaPersonal',
            }),

            ...mapGetters('condecoracionPersonal', {
                condecoraciones: 'getCondecoracionPersonal',
            }),

            ...mapGetters('enrolamiento', {
                enrolamiento: 'getEnrolamientos',
            }),

            menu: function() {
                return []
            },

            estudiosPersonal: function() {
                if (this.estudios) {
                    return this.estudios.filter(estudio => {
                        return estudio.fk_idPersonal == this.id
                    })
                } else return []
            },

            cursosPersonal: function() {
                if (this.cursos) {
                    return this.cursos.filter(cursoAsignado => {
                        return cursoAsignado.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalProductividad: function() {
                if (this.evaluacionesProductividad) {
                    return this.evaluacionesProductividad.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalRespetoPrincipios: function() {
                if (this.evaluacionesRespetoPrincipios) {
                    return this.evaluacionesRespetoPrincipios.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },
        },

        methods: {
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal']),
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal']),
            ...mapActions('estatusDocumento', ['updateEstatusDocumento']),
            ...mapActions('personal', ['fetchPersonalById']),
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),
            ...mapActions('biometrico', ['fetchBiometricosPersonal']),
            ...mapActions('asignacionEquipo', ['fetchAsignacionEquipoByPersonal']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignadosByPersonal']),
            ...mapActions('productividad', ['fetchProductividad']),
            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios']),
            ...mapActions('referencias', ['fetchReferenciasByPersonal']),
            ...mapActions('faltasAdministrativas', ['fetchFaltasAdministrativasByPersonal']),
            ...mapActions('familiares', ['fetchFamiliaresByPersonal']),
            ...mapActions('permutacion', ['fetchPermutacionesByPersonal']),
            ...mapActions('enrolamiento', ['fetchEnrolamientoByPersonal']),
            ...mapActions('convocatoriaPersonal', ['fetchConvocatoriaPersonal']),
            ...mapActions('condecoracionPersonal', ['fetchCondecoracionPersonal']),
            ...mapActions('cadenaResultados', ['fetchCadenaResultados', 'fetchCadenaResultadosDesempeñoAcademico']),
            ...mapMutations('expediente', ['SET_SELECTED_MENU']),

            ...mapActions('disciplinaOperativa', ['fetchDisciplinaOperativa']),
            ...mapActions('disciplinaAdministrativa', ['fetchDisciplinaAdministrativa']),

            ...mapActions('respetoPrincipiosValores', ['fetchRespetoPrincipiosValores']),
            ...mapActions('desempeñoInstitucional', ['fetchDesempeñoInstitucional']),
            ...mapActions('rendimientoProfesionalismo', ['fetchRendimientoProfesionalismo']),
            ...mapActions('evaluacionAnual', ['fetchEvaluacionAnual']),

            selectMenu: function(menu) {
                this.selectedMenu = menu
            },

            download: function(url) {
                window.open(url, '_blank')
            },
        },
    }
</script>
