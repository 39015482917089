<template>
    <v-dialog v-model="active" width="480" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn large rounded color="secondary" v-on="on" v-bind="attrs"><v-icon class="mr-2">mdi-plus</v-icon> Registrar entrada</v-btn>
        </template>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Registrar entrada de inventario</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form v-model="movimientoForm" ref="form">
                        <v-row>
                            <v-col cols="12" align="center">
                                <div class="text-subtitle-2 font-weight-bold mb-2">¿De qué elemento realizará el movimiento?</div>
                                <v-btn-toggle v-model="elementoMovimiento" color="secondary" mandatory rounded>
                                    <v-btn>Cargador</v-btn>
                                    <v-btn>Munición</v-btn>
                                </v-btn-toggle>
                                <v-divider class="my-4"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="movimiento.fechaCompra"
                                    v-mask="'##/##/####'"
                                    hint="Formato de fecha: DD/MM/YYYY"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="dateRules"
                                >
                                    <template v-slot:label>Fecha de compra <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-text-field
                                    v-model="movimiento.puestaDisposición"
                                    v-mask="'##/##/####'"
                                    hint="Formato de fecha: DD/MM/YYYY"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="dateRules"
                                >
                                    <template v-slot:label>Fecha de puesta a disposición <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-select
                                    v-model="movimiento.fk_idCatCalibre"
                                    :items="calibres"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    item-value="id"
                                    item-text="title"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Calibre <span class="red--text">*</span> </template>
                                </v-select>
                                <v-text-field
                                    v-model.number="movimiento.cantidad"
                                    type="number"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    min="0"
                                    :rules="cantidadRules"
                                >
                                    <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-textarea
                                    label="Observaciones"
                                    v-model="movimiento.observaciones"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="3"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" :loading="loading" :disabled="loading || !movimientoForm" v-on:click="triggerSaveMovimiento()"
                    >Guardar movimiento</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        data() {
            return {
                active: false,
                loading: false,
                movimientoForm: false,

                elementoMovimiento: 0,
                movimiento: {
                    fk_idCatCalibre: null,
                    fk_idcatTipoMovimiento: 1,
                    cantidad: 0,
                    observaciones: '',
                    fechaCompra: null,
                    fechaPuestaDispocision: null,
                },

                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],

                required: [v => !!v || 'Este campo es requerido'],
                cantidadRules: [v => v > 0 || 'La cantidad no puede ser cero.'],
            }
        },

        computed: {
            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),

            ...mapGetters('tipoMovimientoEquipamiento', {
                tipoMovimiento: 'getTipoMovimiento',
            }),
        },

        methods: {
            ...mapActions('movimientoMunicion', ['saveMovimientoMunicion', 'fetchMovimientoMunicion']),
            ...mapActions('movimientoCargador', ['saveMovimientoCargador', 'fetchMovimientoCargador']),

            closeDialog() {
                this.loading = false
                this.$refs.form.resetValidation()
                this.movimiento = {
                    fk_idCatCalibre: null,
                    fk_idcatTipoMovimiento: 1,
                    cantidad: 0,
                    observaciones: '',
                }
                this.active = false
            },

            async triggerSaveMovimiento() {
                this.loading = true

                if (this.elementoMovimiento == 0) {
                    await this.saveMovimientoCargador(this.movimiento)
                } else {
                    await this.saveMovimientoMunicion(this.movimiento)
                    await this.fetchMovimientoMunicion()
                }

                this.closeDialog()
            },
        },
    }
</script>
