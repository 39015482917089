import axios from 'axios'

export default {
    namespaced: true,

    actions: {
        saveSolicitud: async function({ commit }, solicitud) {
            await axios
                .post('http://server1.eonproduccion.net:31070/v2017001/API/PoliciaCuadrantes/C3/solicitudes', solicitud)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
