<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Inventario de armas</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="2">
                                    <v-select
                                        label="Marca"
                                        rounded
                                        filled
                                        dense
                                        v-model="selectedMarca"
                                        @change="fetchModeloArmas($event)"
                                        :items="marcas"
                                        item-value="id"
                                        item-text="title"
                                        item-color="secondary"
                                        color="secondary"
                                        background-color="primary"
                                    ></v-select>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="2">
                                    <registrar-movimiento-arma-dialog-component></registrar-movimiento-arma-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaArma"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar arma"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table
                                        class="primary"
                                        :headers="headers"
                                        :items="modeloArmas"
                                        :search="buscaArma"
                                        :loading="loading"
                                        calculate-widths
                                    >
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn rounded small color="secondary" @click="openEstatusArmaDialog(item)">Ver movimientos</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <historial-movimientos-arma-dialog-component
            :active="historialMovimientosArmaDialog"
            :id-modelo="selectedModelo"
            v-on:close="historialMovimientosArmaDialog = false"
        ></historial-movimientos-arma-dialog-component>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import HistorialMovimientosArmaDialogComponent from '../../components/armeria/HistorialMovimientosArmaDialogComponent.vue'
    import RegistrarMovimientoArmaDialogComponent from '../../components/armeria/RegistrarMovimientoArmaDialogComponent.vue'

    export default {
        components: {
            RegistrarMovimientoArmaDialogComponent,
            HistorialMovimientosArmaDialogComponent,
        },

        data() {
            return {
                headers: [
                    { text: 'Modelo', value: 'modelo', width: '10%' },

                    { text: 'Opciones', value: 'opciones', align: 'center', width: '10%', sortable: false },
                ],

                selectedMarca: null,
                selectedModelo: null,
                buscaArma: '',
                loading: false,

                historialMovimientosArmaDialog: false,
            }
        },

        computed: {
            ...mapGetters('marcaArma', {
                marcas: 'getMarcaArma',
            }),

            ...mapGetters('modeloArma', {
                modeloArmas: 'getModelosArmas',
            }),

            ...mapGetters('movimientoArma', {
                movimientoArmas: 'getMovimientoArma',
            }),
        },

        methods: {
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('movimientoArma', ['fetchMovimientoArma', 'fetchMovimientoArmaDisponibles']),

            async openEstatusArmaDialog(modelo) {
                this.selectedModelo = modelo
                await this.fetchMovimientoArma(modelo.id)
                await this.fetchMovimientoArmaDisponibles(modelo.id)
                this.historialMovimientosArmaDialog = true
            },
        },
    }
</script>
