<template>
    <v-container v-if="personal" fluid>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold">Registro de media filiación</v-card-title>
                    <v-card-text>
                        <v-container v-if="activeStep == 1" fluid>
                            <v-form v-model="mediaFiliacionForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[0].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(6)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Complexión <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[1].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(10)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Piel <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[2].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(5)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Cara <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Cabello</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[3].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(14)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Cantidad de cabello <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    label="Color de cabello"
                                                    v-model="mediaFiliacionPersonal[4].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(16)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Color de cabello <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[5].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(4)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Forma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[6].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(23)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Calvicie <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[7].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(24)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Implantación <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Frente</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[8].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(17)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Altura <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[9].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(25)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Inclinación <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[10].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(26)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Ancho <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Cejas</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[11].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(15)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Dirección <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[12].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(27)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Implantación <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[13].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(15)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Forma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[14].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(28)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Tamaño <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Ojos</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[15].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(7)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Color <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[16].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(20)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Forma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[17].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(29)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Tamaño <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Nariz</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[18].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(9)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Raíz (profundidad) <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[19].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(30)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Dorso <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[20].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(31)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Ancho <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[21].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(32)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Base <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[22].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(33)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Altura <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Boca</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[23].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(13)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Tamaño <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[24].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(34)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Comisuras <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Labios</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[25].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(35)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Espesor <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[26].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(36)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Altura naso-labial <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[27].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(37)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Prominencia <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Mentón</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[28].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(38)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Tipo <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[29].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(39)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Forma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[30].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(40)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                    validate-on-blur
                                                >
                                                    <template v-slot:label>Inclinación <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-h6">Oreja derecha</div>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-select
                                                    v-model="mediaFiliacionPersonal[31].fk_idMediafiliacion"
                                                    :items="getFiliacionByRasgo(41)"
                                                    item-value="id"
                                                    item-text="nombre"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Forma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="text-subtitle-1">Hélix</div>
                                                <v-row>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[32].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(42)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Original <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[33].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(43)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Superior <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[34].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(44)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Posterior <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[35].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(45)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Adherencia <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="text-subtitle-1">Lóbulo</div>
                                                <v-row>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[36].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(46)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Contorno <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[37].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(47)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Adherencia <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[38].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(48)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Particularidad <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select
                                                            v-model="mediaFiliacionPersonal[39].fk_idMediafiliacion"
                                                            :items="getFiliacionByRasgo(49)"
                                                            item-value="id"
                                                            item-text="nombre"
                                                            item-color="secondary"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Dimensión <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="3">
                                                <v-select
                                                    v-model="personal.fk_idCatGrupoSanguineo"
                                                    :items="grupoSanguineo"
                                                    item-value="id"
                                                    item-text="title"
                                                    item-color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Tipo de sangre <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="personal.estatura"
                                                    type="number"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Estatura (cm) <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field
                                                    v-model="personal.peso"
                                                    type="number"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Peso (Kg) <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="1">
                                                <v-checkbox v-model="personal.lentes" label="Usa lentes" color="secondary"> </v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-h6">Señas particulares</div>
                                    </v-col>
                                    <v-col cols="12" align="center">
                                        <v-btn @click="addSeniaParticular" color="secondary">Agregar</v-btn>
                                    </v-col>
                                    <v-col cols="12" v-for="(señaParticular, index) in seniasParticulares" :key="index">
                                        <v-card class="card" color="primary">
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row align="center">
                                                        <v-col cols="1">
                                                            <v-avatar color="secondary" size="48" class="white--text">
                                                                {{ index + 1 }}
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="señaParticular.fk_idCatTipo"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="tipoSenia"
                                                                item-value="id"
                                                                item-text="title"
                                                                hide-details
                                                                :rules="required"
                                                            >
                                                                <template v-slot:label>Tipo <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="señaParticular.fk_idCatLado"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="ladoSenia"
                                                                item-value="id"
                                                                item-text="title"
                                                                hide-details
                                                            >
                                                                <template v-slot:label>Lado <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="señaParticular.fk_idCatRegion"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="regionSenia"
                                                                item-value="id"
                                                                item-text="title"
                                                                hide-details
                                                            >
                                                                <template v-slot:label>Región <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="señaParticular.fk_idCatVista"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="vistaSenia"
                                                                item-value="id"
                                                                item-text="title"
                                                                hide-details
                                                            >
                                                                <template v-slot:label>Vista <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-model="señaParticular.fk_idCatCantidad"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="cantidadSenia"
                                                                item-value="id"
                                                                item-text="title"
                                                                hide-details
                                                            >
                                                                <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                label="Descripción"
                                                                v-model="señaParticular.descripcion"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                hide-details
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="1">
                                                            <v-btn text color="error" @click="removeSeniaParticular(index)">Quitar</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="loading || !mediaFiliacionForm"
                                        >Registrar</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    import { circularProgressProps } from '../../components/props/circularProgress'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchMediasFiliaciones()
            await this.fetchRasgos()
            await this.fetchTipoSeniaParticular()
            await this.fetchLadoSeniaParticular()
            await this.fetchRegionSeniaParticular()
            await this.fetchVistaSeniaParticular()
            await this.fetchCantidadSeniaParticular()
        },

        data() {
            return {
                circularProgressProps,

                mediaFiliacionPersonal: [
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                    {
                        fecha: moment().format('DD/MM/YYYY'),
                        estatus: true,
                        fk_idMediafiliacion: null,
                    },
                ],

                seniasParticulares: [],

                cuerpoForm: false,
                cabelloForm: false,
                rostroForm: false,
                ojosForm: false,
                bocaForm: false,
                vozForm: false,
                mediaFiliacionForm: false,

                activeStep: 1,
                loading: false,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('mediaFiliacion', {
                mediaFiliacion: 'getMediasFiliaciones',
            }),
            ...mapGetters('rasgo', {
                rasgos: 'getRasgos',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('grupoSanguineo', {
                grupoSanguineo: 'getGrupoSanguineo',
            }),

            ...mapGetters('tipoSeniaParticular', {
                tipoSenia: 'getTipoSeniaParticular',
            }),

            ...mapGetters('ladoSeniaParticular', {
                ladoSenia: 'getLadoSeniaParticular',
            }),

            ...mapGetters('regionSeniaParticular', {
                regionSenia: 'getRegionSeniaParticular',
            }),

            ...mapGetters('vistaSeniaParticular', {
                vistaSenia: 'getVistaSeniaParticular',
            }),

            ...mapGetters('cantidadSeniaParticular', {
                cantidadSenia: 'getCantidadSeniaParticular',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Cuerpo'
                    case 2:
                        return 'Cabello'
                    case 3:
                        return 'Rostro'
                    case 4:
                        return 'Ojos'
                    case 5:
                        return 'Boca'
                    case 6:
                        return 'Voz'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.cuerpoForm) {
                            return true
                        } else return false
                    case 2:
                        if (this.cabelloForm) {
                            return true
                        } else return false
                    case 3:
                        if (this.rostroForm) {
                            return true
                        } else return false
                    case 4:
                        if (this.ojosForm) {
                            return true
                        } else return false
                    case 5:
                        if (this.bocaForm) {
                            return true
                        } else return false
                    case 6:
                        if (this.vozForm) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 6) {
                    return 'Siguiente paso'
                } else {
                    return 'Guardar registro'
                }
            },
        },

        methods: {
            ...mapActions('rasgo', ['fetchRasgos']),
            ...mapActions('mediaFiliacion', ['fetchMediasFiliaciones']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapActions('personal', ['updatePersonal']),

            ...mapActions('tipoSeniaParticular', ['fetchTipoSeniaParticular']),
            ...mapActions('ladoSeniaParticular', ['fetchLadoSeniaParticular']),
            ...mapActions('regionSeniaParticular', ['fetchRegionSeniaParticular']),
            ...mapActions('vistaSeniaParticular', ['fetchVistaSeniaParticular']),
            ...mapActions('cantidadSeniaParticular', ['fetchCantidadSeniaParticular']),

            getFiliacionByRasgo: function(id_rasgo) {
                return this.mediaFiliacion.filter(filiacion => {
                    return filiacion.fk_idRasgo == id_rasgo
                })
            },

            activeStepButtonHandler: async function() {
                this.loading = true

                this.mediaFiliacionPersonal.forEach(filiacion => {
                    filiacion.idPersonalMediafiliacion = 0
                    this.personal.mediafiliacion.push(filiacion)
                })

                this.seniasParticulares.forEach(seña => {
                    this.personal.seniasParticulares.push(seña)
                })

                await this.updatePersonal(this.personal)
                this.$router.push('/personal/personal')
            },

            existsFiliacionInPersonal: function(fk_idMediafiliacion) {
                if (this.personal.mediafiliacion.some(filiacionPersonal => filiacionPersonal.fk_idMediafiliacion == fk_idMediafiliacion)) {
                    console.log('Mediafiliacion existe')
                    return true
                } else {
                    console.log('Mediafiliacion NO existe')
                    return false
                }
            },

            disableFiliacionInPersonal: function(fk_idMediaFiliacion) {
                this.personal.mediafiliacion.forEach(filiacionPersonal => {
                    if (filiacionPersonal.fk_idMediafiliacion == fk_idMediaFiliacion) {
                        filiacionPersonal.estatus = false
                    }
                })
            },

            addSeniaParticular() {
                this.seniasParticulares.push({
                    fecha: null,
                    estatus: true,
                    fk_idCatTipo: null,
                    fk_idCatLado: null,
                    fk_idCatRegion: null,
                    fk_idCatVista: null,
                    fk_idCatCantidad: null,
                    descripcion: '',
                    idPersonalSeniasParticulares: 0,
                })
            },

            removeSeniaParticular(index) {
                this.seniasParticulares.splice(index, 1)
            },

            async reset() {
                this.personal.mediafiliacion.forEach(media_filiacion => {
                    media_filiacion.estatus = false
                })
                await this.updatePersonal(this.personal)
            },
        },
    }
</script>
