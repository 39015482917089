import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        productividad: [],
        productividadSingle: [],
    },

    getters: {
        getProductividad: state => {
            return state.productividad
        },

        getProductividadSingle: state => {
            return state.productividadSingle[0]
        },
    },

    mutations: {
        SET_PRODUCTIVIDAD(state, productividad) {
            state.productividad = productividad
        },

        SET_PRODUCTIVIDAD_SINGLE(state, productividadSingle) {
            state.productividadSingle = productividadSingle
        },
    },

    actions: {
        fetchProductividadById: async function({ commit }, evaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + evaluacion.fkidPersonal + '/productividad/' + evaluacion.idProductividad)
                .then(response => {
                    commit('SET_PRODUCTIVIDAD_SINGLE', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchProductividad: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/productividad')
                .then(response => {
                    commit('SET_PRODUCTIVIDAD', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveProductividad: async function({ commit }, productividad) {
            console.log(productividad)
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + productividad.fk_idPersonal + '/productividad', productividad)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateProductividad: async function(context, productividad) {
            await axios
                .put('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + productividad.fk_idPersonal + '/productividad/' + productividad.id, productividad)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación registrada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatusProductividad: async function(context, productividad) {
            await axios
                .put('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + productividad.fk_idPersonal + '/productividad/' + productividad.id + '/estatus', {
                    fk_idCatEstatusEvaluacion: 3,
                })
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
