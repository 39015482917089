<template>
    <v-card class="card" color="primary">
        <v-card-title class="font-weight-bold justify-center">Registro de documento</v-card-title>
        <v-card-text>
            <v-form v-model="documentForm" ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input v-model="file" rounded filled background-color="primary" color="secondary" dense :rules="nameRules">
                                <template v-slot:label>Adjuntar documento <span class="red--text">*</span> </template>
                            </v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="tipoDocumentoElegido"
                                label="Tipo de documento"
                                background-color="primary"
                                color="secondary"
                                rounded
                                filled
                                dense
                                :items="tipoDocumento"
                                item-value="id"
                                item-text="title"
                                :rules="nameRules"
                            >
                                <template v-slot:label>Tipo de documento <span class="red--text">*</span> </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                no-resize
                                rows="3"
                                color="secondary"
                                background-color="primary"
                                label="Observaciones"
                                v-model="observaciones"
                                rounded
                                filled
                                dense
                            ></v-textarea>
                            <v-checkbox
                                v-model="bajoProtesta"
                                label="Declaro bajo protesta de decir verdad que la documentación proporcionada es auténtica. De no ser así, asumo plenamente las responsabilidades que fijen las leyes."
                                color="secondary"
                                :rules="nameRules"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="justify-center pb-8">
            <v-btn color="secondary" class="primary--text px-8" :disabled="!documentForm || loading" v-on:click="triggerSaveDocument()">
                Registrar documento
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                documentForm: false,
                loading: false,
                observaciones: '',
                fecha: null,
                nombre: '',
                file: null,
                fileBajoProtesta: null,
                bajoProtesta: false,
                // estatusDocumentoElegido: null,
                tipoDocumentoElegido: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('tipoDocumento', {
                tipoDocumento: 'getTipoDocumento',
            }),

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },
        },

        methods: {
            ...mapActions('documento', ['saveDocumentoPersonal', 'fetchDocumentoByPersonal']),

            triggerSaveDocument: async function() {
                this.loading = true
                await this.saveDocumentoPersonal({
                    fk_idPersonal: this.id,
                    fk_idTipoDocumento: this.tipoDocumentoElegido,
                    observaciones: this.observaciones,
                    nombre: this.file.name,
                    file: this.file,
                })

                this.resetForm()
                await this.fetchDocumentoByPersonal(this.id)
                this.loading = false

                // this.$router.go(-1)
            },

            resetForm() {
                this.observaciones = ''
                this.fecha = null
                this.nombre = ''
                this.file = null
                this.fileBajoProtesta = null
                this.bajoProtesta = false
                this.tipoDocumentoElegido = null

                this.$refs.form.resetValidation()
            },
        },
    }
</script>
