<template>
    <v-dialog v-model="active" fullscreen>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-on="on" v-bind="attrs">Agregar estudios</v-btn>
        </template>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="2">
                            <v-btn text @click="active = false">
                                <v-icon>mdi-arrow-left</v-icon>
                                Volver
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" offset-md="4">
                    <v-card class="card" color="primary">
                        <v-card-title class="font-weight-bold justify-center">Registro de estudios</v-card-title>
                        <v-card-text>
                            <v-form v-model="studiesForm">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="institucion"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Institución o escuela <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="generacionInicio"
                                                maxlength="4"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Año de inicio"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Año de inicio <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                v-model="generacionFin"
                                                maxlength="4"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Año de finalización"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Año de finalización <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :items="estatusEstudios"
                                                item-value="id"
                                                item-text="title"
                                                v-model="estatusEstudiosElegido"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Estatus de estudios <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                no-resize
                                                rows="4"
                                                color="secondary"
                                                background-color="primary"
                                                label="Observaciones (Opcional)"
                                                v-model="observaciones"
                                                rounded
                                                filled
                                                dense
                                            ></v-textarea>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :items="tipoEstudios"
                                                item-value="id"
                                                item-text="title"
                                                v-model="tipoEstudioElegido"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Tipo de estudios <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :items="comprobanteEstudios"
                                                item-value="id"
                                                item-text="title"
                                                v-model="comprobanteEstudioElegido"
                                                :rules="nameRules"
                                            >
                                                <template v-slot:label>Tipo de comprobante de estudios <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-center pb-8">
                            <v-btn color="secondary" class="primary--text px-8" :disabled="!studiesForm || loading" v-on:click="triggerSaveStudies()">
                                Registrar estudios
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay color="primary" opacity="1" z-index="-1"></v-overlay>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchComprobantesEstudios()
            await this.fetchEstatusEstudios()
            await this.fetchTipoEstudios()
        },

        data() {
            return {
                studiesForm: false,
                loading: false,
                estatusEstudio: null,
                institucion: '',
                generacionInicio: '',
                generacionFin: '',
                observaciones: '',
                comprobanteEstudioElegido: null,
                tipoEstudioElegido: null,
                estatusEstudiosElegido: null,
                nameRules: [v => !!v || 'Este campo es requerido.'],

                active: false,
            }
        },

        computed: {
            ...mapGetters('comprobanteEstudios', {
                comprobanteEstudios: 'getComprobantesEstudios',
            }),
            ...mapGetters('estatusEstudios', {
                estatusEstudios: 'getEstatusEstudios',
            }),

            ...mapGetters('estudio', {
                tipoEstudios: 'getTipoEstudios',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        methods: {
            ...mapActions('estudio', ['saveEstudiosPersonal']),
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal', 'fetchTipoEstudios']),
            ...mapActions('estatusEstudios', ['fetchEstatusEstudios']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            triggerSaveStudies: async function() {
                this.loading = true

                await this.saveEstudiosPersonal({
                    fk_idPersonal: this.id,
                    institucion: this.institucion,
                    generacion: this.generacionInicio + '-' + this.generacionFin,
                    observaciones: this.observaciones,
                    fk_idCatEstatusEstudio: this.estatusEstudiosElegido,
                    fk_idCatEstudio: this.tipoEstudioElegido,
                    fk_idComprobanteEstudio: this.comprobanteEstudioElegido,
                })

                this.loading = false
                await this.fetchEstudiosByPersonal(this.id)
                this.active = false
            },
        },
    }
</script>
