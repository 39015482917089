var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card",attrs:{"color":"primary","loading":_vm.loading}},[_c('v-card-title',[_vm._v("Tabla de personal")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar personal por nombre o CUIP","rounded":"","filled":"","dense":"","color":"secondary","background-color":"primary","append-icon":"mdi-magnify"},model:{value:(_vm.searchPersonal),callback:function ($$v) {_vm.searchPersonal=$$v},expression:"searchPersonal"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.filteredPersonal,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex align-center pt-4"},[_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":item.foto}})],1),_c('div',[_c('div',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getFullName(item))+" ")]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.CUIP)+" ")]),(item.CatPuestoDependencia)?_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.CatPuestoDependencia.catPuesto.puesto)+" ")]):_vm._e()])],1)]}},{key:"item.susceptible",fn:function(ref){
var item = ref.item;
return [(_vm.susceptibleCup(item))?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")])]}},{key:"item.vigenciaEvaluacionC3",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.tieneC3Vigente(item.vigenciaEvaluacionC3)
                                                    ? 'success--text font-weight-bold'
                                                    : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(item.vigenciaEvaluacionC3)+" ")])]}},{key:"item.vigenciaFormacionInicial",fn:function(ref){
                                                    var item = ref.item;
return [_c('div',{class:_vm.tieneC3Vigente(item.vigenciaFormacionInicial)
                                                    ? 'success--text font-weight-bold'
                                                    : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(item.vigenciaFormacionInicial)+" ")])]}},{key:"item.vigenciaCompetenciasBasicas",fn:function(ref){
                                                    var item = ref.item;
return [_c('div',{class:_vm.tieneC3Vigente(item.vigenciaCompetenciasBasicas)
                                                    ? 'success--text font-weight-bold'
                                                    : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(item.vigenciaCompetenciasBasicas)+" ")])]}},{key:"item.vigenciaCup",fn:function(ref){
                                                    var item = ref.item;
return [_c('div',{class:_vm.tieneC3Vigente(item.vigenciaCup) ? 'success--text font-weight-bold' : 'error--text font-weight-bold'},[_vm._v(" "+_vm._s(item.vigenciaCup)+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }