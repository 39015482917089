import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusEquipamiento: [],
    },

    getters: {
        getEstatusEquipamiento: state => {
            return state.estatusEquipamiento
        },
    },

    mutations: {
        SET_ESTATUS_EQUIPAMIENTO: (state, estatusEquipamiento) => {
            state.estatusEquipamiento = estatusEquipamiento
        },
    },

    actions: {
        fetchEstatusEquipamiento: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/estatus')
                .then(response => {
                    commit('SET_ESTATUS_EQUIPAMIENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
