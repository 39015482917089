<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Carrera policial</div>
            <v-tabs v-model="selectedTab" color="secondary" fixed-tabs>
                <v-tab class="font-weight-bold">Convocatorias</v-tab>
                <v-tab class="font-weight-bold">Condecoraciones</v-tab>
                <v-tab class="font-weight-bold">Evaluaciones de desempeño</v-tab>
                <v-tab class="font-weight-bold">Evaluaciones de desempeño académico</v-tab>
                <v-tab class="font-weight-bold">Evaluaciones anuales</v-tab>
                <v-tab class="font-weight-bold">CUP</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="selectedTab" class="transparent">
                <v-tab-item key="1">
                    <v-container>
                        <v-row v-if="convocatorias.length > 0">
                            <v-col cols="6" v-for="convocatoria in convocatorias" :key="convocatoria.idConvocatoria">
                                <v-card color="primary" class="card">
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" align="center">
                                                    <div class="text-h6 font-weight-bold">{{ convocatoria.Convocatoria.convocatoria }}</div>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        :value="convocatoria.fechaRegistro"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de registro"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        :value="convocatoria.Convocatoria.fechaHoraPublicacionConvocatoria"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de publicación de convocatoria"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="convocatoria.Convocatoria.plazasVacantes"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Plazas vacantes"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        :value="convocatoria.Convocatoria.lugarRecepcionDocumentos"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Lugar de recepción de documentos"
                                                    ></v-text-field>
                                                    <v-textarea
                                                        label="Convocatoria dirigida a"
                                                        :value="convocatoria.Convocatoria.dirigidaA"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                        readonly
                                                        no-resize
                                                        rows="4"
                                                    ></v-textarea>
                                                    <v-textarea
                                                        label="Documentación solicitada"
                                                        :value="convocatoria.Convocatoria.documentacion"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                        readonly
                                                        no-resize
                                                        rows="4"
                                                    ></v-textarea>
                                                    <v-textarea
                                                        label="Requisitos"
                                                        :value="convocatoria.Convocatoria.requisitos"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                        readonly
                                                        no-resize
                                                        rows="4"
                                                    ></v-textarea>
                                                    <v-textarea
                                                        label="Beneficios"
                                                        :value="convocatoria.Convocatoria.beneficios"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                        readonly
                                                        no-resize
                                                        rows="4"
                                                    ></v-textarea>
                                                    <v-textarea
                                                        label="Procedimiento de selección"
                                                        :value="convocatoria.Convocatoria.procedimientoSeleccion"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                        readonly
                                                        no-resize
                                                        rows="4"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no se ha registrado en ninguna convocatoria.</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                    <v-container>
                        <v-row v-if="condecoraciones.length > 0">
                            <v-col cols="12" v-for="condecoracion in condecoraciones" :key="condecoracion.id">
                                <v-card color="primary" class="card">
                                    <v-card-text>
                                        <v-container>
                                            <v-row align="center">
                                                <v-col cols="4" align="center">
                                                    <v-img :src="condecoracion.CatCondecoracion.icono" width="80"></v-img>
                                                    <div class="text-subtitle-2 mt-4 font-weight-bold">
                                                        {{ condecoracion.CatCondecoracion.condecoracion }}
                                                    </div>
                                                    <div class="text-caption">
                                                        {{ condecoracion.CatCondecoracion.descripcion }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-row>
                                                        <v-col cols="12" align="center">
                                                            <div class="text-h6 font-weight-bold">{{ condecoracion.CatCondecoracion.title }}</div>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-text-field
                                                                :value="condecoracion.fecha"
                                                                rounded
                                                                readonly
                                                                filled
                                                                dense
                                                                background-color="primary"
                                                                color="secondary"
                                                                label="Fecha de registro"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                :value="condecoracion.fechaCondecoracion"
                                                                rounded
                                                                readonly
                                                                filled
                                                                dense
                                                                background-color="primary"
                                                                color="secondary"
                                                                label="Fecha de condecoración"
                                                            ></v-text-field>
                                                            <v-textarea
                                                                label="Observaciones"
                                                                :value="condecoracion.observaciones"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                readonly
                                                                no-resize
                                                                rows="4"
                                                            ></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text-h6">Este personal no cuenta con condecoraciones.</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="3">
                    <v-container>
                        <v-row v-for="(evaluacion, index) in productividad" :key="index">
                            <v-col cols="3">
                                <evaluacion-productividad-card-component :evaluacion="evaluacion"></evaluacion-productividad-card-component>
                            </v-col>
                            <v-col cols="3">
                                <evaluacion-disciplina-administrativa-card-component
                                    :evaluacion="disciplinaAdministrativa[index]"
                                ></evaluacion-disciplina-administrativa-card-component>
                            </v-col>
                            <v-col cols="3">
                                <evaluacion-disciplina-operativa-card-component
                                    :evaluacion="disciplinaOperativa[index]"
                                ></evaluacion-disciplina-operativa-card-component>
                            </v-col>
                            <v-col cols="3">
                                <evaluacion-respeto-principios-card-component
                                    :evaluacion="respetoPrincipios[index]"
                                ></evaluacion-respeto-principios-card-component>
                            </v-col>
                            <v-col cols="12" align="center">
                                <v-btn @click="exportExcel()" color="secondary">Descargar cadena de resultados</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="4">
                    <v-container>
                        <v-row v-for="(evaluacion, index) in respetoPrincipiosValores" :key="index">
                            <v-col cols="4">
                                <evaluacion-respeto-principios-valores-card-component
                                    :evaluacion="evaluacion"
                                ></evaluacion-respeto-principios-valores-card-component>
                            </v-col>
                            <v-col cols="4">
                                <evaluacion-desempenio-institucional-card-component
                                    :evaluacion="desempeñoInstitucional[index]"
                                ></evaluacion-desempenio-institucional-card-component>
                            </v-col>
                            <v-col cols="4">
                                <evaluacion-rendimiento-profesionalismo-card-component
                                    :evaluacion="rendimientoProfesionalismo[index]"
                                ></evaluacion-rendimiento-profesionalismo-card-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="5">
                    <v-container>
                        <v-row v-for="(evaluacion, index) in evaluacionesAnuales" :key="index">
                            <v-col cols="6">
                                <evaluacion-anual-card-component :evaluacion="evaluacion"></evaluacion-anual-card-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="6">
                    <v-container fill-height>
                        <v-row align="center">
                            <v-col cols="6">
                                <v-img :src="personal.adjuntoCup" width="100%" aspect-ratio="1" contain> </v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Folio CUP"
                                            :value="personal.CUP"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            label="Vigencia de evaluación C3"
                                            :value="personal.vigenciaEvaluacionC3"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            label="Vigencia de curso de formación inicial"
                                            :value="personal.vigenciaFormacionInicial"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            label="Vigencia de curso de competencias básicas"
                                            :value="personal.vigenciaCompetenciasBasicas"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            label="Vigencia de CUP"
                                            :value="personal.vigenciaCup"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'
    import axios from 'axios'
    import { saveAs } from 'file-saver'
    import { publicPath } from '../../../vue.config'
    import EvaluacionProductividadCardComponent from './evaluaciones/productividad/EvaluacionProductividadCardComponent.vue'
    import EvaluacionRespetoPrincipiosCardComponent from './evaluaciones/respeto_principios/EvaluacionRespetoPrincipiosCardComponent.vue'
    import EvaluacionDisciplinaAdministrativaCardComponent from './evaluaciones/disciplina_administrativa/EvaluacionDisciplinaAdministrativaCardComponent.vue'
    import EvaluacionDisciplinaOperativaCardComponent from './evaluaciones/disciplina_operativa/EvaluacionDisciplinaOperativaCardComponent.vue'
    import EvaluacionRespetoPrincipiosValoresCardComponent from './evaluaciones/respeto_principios_valores/EvaluacionRespetoPrincipiosValoresCardComponent.vue'
    import EvaluacionDesempenioInstitucionalCardComponent from './evaluaciones/desempeño_institucional/EvaluacionDesempenioInstitucionalCardComponent.vue'
    import EvaluacionRendimientoProfesionalismoCardComponent from './evaluaciones/rendimiento_profesionalismo/EvaluacionRendimientoProfesionalismoCardComponent.vue'
    import EvaluacionAnualCardComponent from './evaluaciones/evaluacion_anual/EvaluacionAnualCardComponent.vue'

    const ExcelJS = require('exceljs')
    var buffer = null
    var workbook = new ExcelJS.Workbook()
    var worksheet = null
    var cell = null

    export default {
        components: {
            EvaluacionProductividadCardComponent,
            EvaluacionRespetoPrincipiosCardComponent,
            EvaluacionDisciplinaAdministrativaCardComponent,
            EvaluacionDisciplinaOperativaCardComponent,
            EvaluacionRespetoPrincipiosValoresCardComponent,
            EvaluacionDesempenioInstitucionalCardComponent,
            EvaluacionRendimientoProfesionalismoCardComponent,
            EvaluacionAnualCardComponent,
        },

        props: {
            convocatorias: {
                type: Array,
                default: function() {
                    return []
                },
            },

            condecoraciones: {
                type: Array,
                default: function() {
                    return []
                },
            },

            personal: {
                type: Object,
            },

            estatus: {
                type: Number,
            },
        },

        async mounted() {
            if (this.productividad.length > 1) {
                // await axios(publicPath + 'cadena_resultados.xlsx', { responseType: 'arraybuffer' })
                //     .catch(function(err) {
                //         /* error in getting data */
                //         console.log('Error in getting data: ' + err)
                //     })
                //     .then(async function(res) {
                //         /* parse the data when it is received */
                //         return res.data
                //     })
                //     .catch(function(err) {
                //         /* error in parsing */
                //         console.log('Error in parsin: ' + err)
                //     })
                //     .then(async data => {
                //         await workbook.xlsx.load(data)
                //         worksheet = workbook.getWorksheet(1)
                //         cell = worksheet.getCell('E9')
                //         cell.value = this.getFullName(this.personal)
                //         cell = worksheet.getCell('F9')
                //         cell.value = this.personal.curp
                //         cell = worksheet.getCell('G9')
                //         cell.value = this.personal.CUIP
                //         cell = worksheet.getCell('H9')
                //         cell.value = this.personal.CatPuestoDependencia.catPuesto.puesto
                //         cell = worksheet.getCell('I9')
                //         cell.value = this.personal.antiguedad
                //         cell = worksheet.getCell('J9')
                //         cell.value = this.personal.CatPuestoDependencia.CatTipoPuesto.title
                //         cell = worksheet.getCell('K9')
                //         cell.value = this.personal.CatPuestoDependencia.catDependencia.nombre
                //         cell = worksheet.getCell('L9')
                //         cell.value = this.personal.CatArea.nombre
                //         cell = worksheet.getCell('N9')
                //         cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].fechaAplicacion
                //         cell = worksheet.getCell('O9')
                //         cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].puntajeTotal
                //         cell = worksheet.getCell('P9')
                //         cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].calificacion
                //         cell = worksheet.getCell('Q9')
                //         cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].CatResultado.title
                //         cell = worksheet.getCell('R9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].instanciaInforma
                //         cell = worksheet.getCell('S9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].fechaAplicacion
                //         cell = worksheet.getCell('T9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[0].cantidad
                //         cell = worksheet.getCell('U9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[1].cantidad
                //         cell = worksheet.getCell('V9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[2].cantidad
                //         cell = worksheet.getCell('W9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[3].cantidad
                //         cell = worksheet.getCell('X9')
                //         cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[4].cantidad
                //         cell = worksheet.getCell('Y9')
                //         cell.value = ''
                //         this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].actasAdministrativas.forEach((acta, index) => {
                //             cell.value += index + 1 + '.' + 'Descripción: ' + acta.descripcion + ' Fecha: ' + acta.fecha + ' Motivo: ' + acta.motivo + '\r\n'
                //         })
                //         cell = worksheet.getCell('Z9')
                //         cell.value = ''
                //         this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].estimulos.forEach((estimulo, index) => {
                //             cell.value += index + 1 + '.' + 'Motivo: ' + estimulo.motivo + ' Descripción: ' + estimulo.descripcion + '\r\n'
                //         })
                //         cell = worksheet.getCell('AA9')
                //         cell.value = ''
                //         this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].reconocimientos.forEach((reconocimiento, index) => {
                //             cell.value += index + 1 + '.' + 'Motivo: ' + reconocimiento.motivo + ' Descripción: ' + reconocimiento.descripcion + '\r\n'
                //         })
                //         cell = worksheet.getCell('AB9')
                //         cell.value = this.disciplinaOperativa[this.disciplinaOperativa.length - 1].instanciaInforma
                //         cell = worksheet.getCell('AC9')
                //         cell.value = this.disciplinaOperativa[this.disciplinaOperativa.length - 1].fechaAplicacion
                //         cell = worksheet.getCell('AH9')
                //         cell.value = ''
                //         this.disciplinaOperativa[this.disciplinaOperativa.length - 1].procedimientos.forEach((procedimiento, index) => {
                //             cell.value += index + 1 + '.' + procedimiento.numero + '\r\n'
                //         })
                //         cell = worksheet.getCell('AI9')
                //         cell.value = ''
                //         this.disciplinaOperativa[this.disciplinaOperativa.length - 1].procedimientos.forEach((procedimiento, index) => {
                //             cell.value += index + 1 + '.' + procedimiento.fecha + '\r\n'
                //         })
                //         cell = worksheet.getCell('AN9')
                //         cell.value = this.productividad[this.productividad.length - 1].fechaAplicacion
                //         cell = worksheet.getCell('AO9')
                //         cell.value = this.productividad[this.productividad.length - 1].puntajeTotal
                //         cell = worksheet.getCell('AP9')
                //         cell.value = this.productividad[this.productividad.length - 1].calificacion
                //         cell = worksheet.getCell('AQ9')
                //         cell.value = this.productividad[this.productividad.length - 1].CatResultado.title
                //         worksheet.removeConditionalFormatting()
                //         buffer = await workbook.xlsx.writeBuffer()
                //     })
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters('disciplinaAdministrativa', {
                disciplinaAdministrativa: 'getDisciplinaAdministrativa',
            }),

            ...mapGetters('disciplinaOperativa', {
                disciplinaOperativa: 'getDisciplinaOperativa',
            }),

            ...mapGetters('productividad', {
                productividad: 'getProductividad',
            }),

            ...mapGetters('respetoPrincipios', {
                respetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('respetoPrincipiosValores', {
                respetoPrincipiosValores: 'getRespetoPrincipiosValores',
            }),

            ...mapGetters('desempeñoInstitucional', {
                desempeñoInstitucional: 'getDesempeñoInstitucional',
            }),

            ...mapGetters('rendimientoProfesionalismo', {
                rendimientoProfesionalismo: 'getRendimientoProfesionalismo',
            }),

            ...mapGetters('evaluacionAnual', {
                evaluacionesAnuales: 'getEvaluacionAnual',
            }),
        },

        data() {
            return {
                selectedTab: null,
            }
        },

        methods: {
            async exportExcel() {
                await axios(publicPath + 'cadena_resultados.xlsx', { responseType: 'arraybuffer' })
                    .catch(function(err) {
                        /* error in getting data */
                        console.log('Error in getting data: ' + err)
                    })
                    .then(async function(res) {
                        /* parse the data when it is received */
                        return res.data
                    })
                    .catch(function(err) {
                        /* error in parsing */
                        console.log('Error in parsin: ' + err)
                    })
                    .then(async data => {
                        await workbook.xlsx.load(data)
                        worksheet = workbook.getWorksheet(1)
                        cell = worksheet.getCell('E9')
                        cell.value = this.getFullName(this.personal)
                        cell = worksheet.getCell('F9')
                        cell.value = this.personal.curp
                        cell = worksheet.getCell('G9')
                        cell.value = this.personal.CUIP
                        cell = worksheet.getCell('H9')
                        cell.value = this.personal.CatPuestoDependencia ? this.personal.CatPuestoDependencia.catPuesto.puesto : ''
                        cell = worksheet.getCell('I9')
                        cell.value = this.personal.antiguedad
                        cell = worksheet.getCell('J9')
                        cell.value = this.personal.CatPuestoDependencia ? this.personal.CatPuestoDependencia.CatTipoPuesto.title : ''
                        cell = worksheet.getCell('K9')
                        cell.value = this.personal.CatPuestoDependencia ? this.personal.CatPuestoDependencia.catDependencia.nombre : ''
                        cell = worksheet.getCell('L9')
                        cell.value = this.personal.CatArea ? this.personal.CatArea.nombre : ''
                        cell = worksheet.getCell('N9')
                        cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].fechaAplicacion
                        cell = worksheet.getCell('O9')
                        cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].puntajeTotal
                        cell = worksheet.getCell('P9')
                        cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].calificacion
                        cell = worksheet.getCell('Q9')
                        cell.value = this.respetoPrincipios[this.respetoPrincipios.length - 1].CatResultado.title
                        cell = worksheet.getCell('R9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].instanciaInforma
                        cell = worksheet.getCell('S9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].fechaAplicacion
                        cell = worksheet.getCell('T9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[0].cantidad
                        cell = worksheet.getCell('U9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[1].cantidad
                        cell = worksheet.getCell('V9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[2].cantidad
                        cell = worksheet.getCell('W9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[3].cantidad
                        cell = worksheet.getCell('X9')
                        cell.value = this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].incidencias[4].cantidad
                        cell = worksheet.getCell('Y9')
                        cell.value = ''
                        this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].actasAdministrativas.forEach((acta, index) => {
                            cell.value += index + 1 + '.' + 'Descripción: ' + acta.descripcion + ' Fecha: ' + acta.fecha + ' Motivo: ' + acta.motivo + '\r\n'
                        })
                        cell = worksheet.getCell('Z9')
                        cell.value = ''
                        this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].estimulos.forEach((estimulo, index) => {
                            cell.value += index + 1 + '.' + 'Motivo: ' + estimulo.motivo + ' Descripción: ' + estimulo.descripcion + '\r\n'
                        })
                        cell = worksheet.getCell('AA9')
                        cell.value = ''
                        this.disciplinaAdministrativa[this.disciplinaAdministrativa.length - 1].reconocimientos.forEach((reconocimiento, index) => {
                            cell.value += index + 1 + '.' + 'Motivo: ' + reconocimiento.motivo + ' Descripción: ' + reconocimiento.descripcion + '\r\n'
                        })
                        cell = worksheet.getCell('AB9')
                        cell.value = this.disciplinaOperativa[this.disciplinaOperativa.length - 1].instanciaInforma
                        cell = worksheet.getCell('AC9')
                        cell.value = this.disciplinaOperativa[this.disciplinaOperativa.length - 1].fechaAplicacion
                        cell = worksheet.getCell('AH9')
                        cell.value = ''
                        this.disciplinaOperativa[this.disciplinaOperativa.length - 1].procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.numero + '\r\n'
                        })
                        cell = worksheet.getCell('AI9')
                        cell.value = ''
                        this.disciplinaOperativa[this.disciplinaOperativa.length - 1].procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.fecha + '\r\n'
                        })
                        cell = worksheet.getCell('AN9')
                        cell.value = this.productividad[this.productividad.length - 1].fechaAplicacion
                        cell = worksheet.getCell('AO9')
                        cell.value = this.productividad[this.productividad.length - 1].puntajeTotal
                        cell = worksheet.getCell('AP9')
                        cell.value = this.productividad[this.productividad.length - 1].calificacion
                        cell = worksheet.getCell('AQ9')
                        cell.value = this.productividad[this.productividad.length - 1].CatResultado.title

                        worksheet.removeConditionalFormatting()
                        buffer = await workbook.xlsx.writeBuffer()
                    })

                var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, 'cadena_resultados.xlsx')
            },
        },
    }
</script>
