<template>
    <v-dialog persistent width="560" v-model="active">
        <v-card class="primary rounded-sm">
            <v-card-title>
                <div class="text-h6 ">Asignación de condecoración a personal</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-form v-model="form">
                    <v-select
                        v-model="condecoracion.fk_idCatCondecoracion"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        :items="condecoraciones"
                        item-value="id"
                        item-text="condecoracion"
                        item-color="secondary"
                    >
                        <template v-slot:label>Condecoración <span class="red--text">*</span> </template>
                    </v-select>
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="condecoracion.fechaCondecoracion"
                                label="Fecha de condecoración"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="condecoracion.fechaCondecoracion" @input="menu = false"></v-date-picker>
                    </v-menu>
                    <v-textarea
                        label="Observaciones"
                        v-model="condecoracion.observaciones"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        no-resize
                        rows="6"
                    ></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="!form || loading" @click="triggerSaveCondecoracionPersonal()"
                    >Asignar condecoración</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            personal: {
                type: Object,
            },
        },

        data() {
            return {
                form: false,
                menu: false,
                loading: false,

                condecoracion: {
                    fk_idPersonal: null,
                    fk_idCatCondecoracion: null,
                    fechaCondecoracion: null,
                    observaciones: '',
                },
            }
        },

        computed: {
            ...mapGetters('condecoraciones', {
                condecoraciones: 'getCondecoraciones',
            }),
        },

        methods: {
            ...mapActions('condecoracionPersonal', ['saveCondecoracionPersonal']),

            closeDialog() {
                this.$emit('close')
                this.condecoracion = {
                    fk_idPersonal: null,
                    fk_idCatCondecoracion: null,
                    fechaCondecoracion: null,
                    observaciones: '',
                }
                this.loading = false
            },

            async triggerSaveCondecoracionPersonal() {
                this.loading = true
                this.condecoracion.fk_idPersonal = this.personal.idPersonal
                await this.saveCondecoracionPersonal(this.condecoracion)

                this.closeDialog()
            },
        },
    }
</script>
