import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        funciones: [],
    },

    getters: {
        getFunciones: state => {
            return state.funciones
        },
    },

    mutations: {
        SET_FUNCIONES(state, funciones) {
            state.funciones = funciones
        },
    },

    actions: {
        fetchFunciones: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/funciones')
                .then(response => {
                    commit('SET_FUNCIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
