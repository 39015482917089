<template>
    <v-dialog persistent width="720" v-model="active">
        <v-card color="primary" class="rounded-sm" v-if="promocion">
            <v-card-title>
                <div class="text-h6 font-weight-bold">Nueva promocion</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form v-model="form" ref="form">
                        <v-row>
                            <v-col cols="12" align="center">
                                <v-text-field v-model="promocion.puesto" rounded filled dense color="secondary" background-color="primary" :rules="required">
                                    <template v-slot:label>Puesto <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-textarea
                                    v-model="promocion.descripcion"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Descripción <span class="red--text">*</span> </template>
                                </v-textarea>
                                <v-textarea
                                    v-model="promocion.requisitos"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Requisitos <span class="red--text">*</span> </template>
                                </v-textarea>
                                <v-textarea
                                    v-model="promocion.funciones"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Funciones <span class="red--text">*</span> </template>
                                </v-textarea>
                                <v-textarea
                                    label="Responsabilidades"
                                    v-model="promocion.responsabilidades"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                ></v-textarea>
                                <v-text-field
                                    label="Sueldo"
                                    v-model="promocion.sueldo"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Sueldo <span class="red--text">*</span> </template>
                                </v-text-field>
                                <div class="text-caption">Fecha de publicación de promocion <span class="red--text">*</span></div>
                                <date-picker v-model="promocion.fechaPublicacion" type="date" valueType="DD/MM/YYYY"></date-picker>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="!form || loading" @click="triggerSavePromocion()">Registrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'

    export default {
        components: { DatePicker },

        props: {
            active: {
                type: Boolean,
            },

            promocion: {
                type: Object,
            },
        },

        data() {
            return {
                promocionDialog: false,
                form: false,
                loading: false,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        methods: {
            ...mapActions('promociones', ['updatePromocion', 'fetchPromociones']),

            closeDialog() {
                this.$refs.form.resetValidation()
                this.loading = false
                this.$emit('close')
            },

            async triggerSavePromocion() {
                this.loading = true
                await this.updatePromocion(this.promocion)
                await this.fetchPromociones()

                this.closeDialog()
            },
        },
    }
</script>
