import { axios, fileAxios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        documentos: [],
    },

    getters: {
        getDocumentos: state => {
            return state.documentos
        },
    },

    mutations: {
        SET_DOCUMENTOS(state, documentos) {
            state.documentos = documentos
        },
    },

    actions: {
        fetchDocumentoByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/EonCloud/EstadoFuerza/personal/' + idPersonal + '/documento')
                .then(response => {
                    commit('SET_DOCUMENTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveDocumentoPersonal: async function(context, documentoPersonal) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + documentoPersonal.fk_idPersonal + '/documento', documentoPersonal)
                .then(response => {
                    console.log(response.data.data)
                    var formData = new FormData()
                    formData.append('file', documentoPersonal.file)
                    formData.append('iddocumentosPersonal', response.data.data[0].ID)
                    // console.log(formData)
                    context.dispatch('uploadDocumento', formData)
                })
                .catch(error => console.log(error))
        },

        updateDocumentoPersonal: async function({ commit }, documentoPersonal) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + documentoPersonal.fk_idPersonal + '/documento/' + documentoPersonal.id, documentoPersonal)
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatusDocumentoPersonal: async function({ commit }, documentoPersonal) {
            await axios
                .put(
                    '/PoliciaCuadrantes/EstadoFuerza/personal/' +
                        documentoPersonal.fk_idPersonal +
                        '/documento/' +
                        documentoPersonal.iddocumentosPersonal +
                        '/estatus',
                    {
                        fk_idEstatus: 2,
                    }
                )
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        uploadDocumento: async function(context, formData) {
            await fileAxios
                .post('/Documento/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Documento guardado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
