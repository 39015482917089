import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        asuntosInternos: [],
    },

    getters: {
        getAsuntosInternos: state => {
            return state.asuntosInternos
        },
    },

    mutations: {
        SET_ASUNTOS_INTERNOS(state, asuntosInternos) {
            state.asuntosInternos = asuntosInternos
        },
    },

    actions: {
        fetchAsuntosInternos: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/consultaasuntosinternos')
                .then(response => {
                    commit('SET_ASUNTOS_INTERNOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveAsuntosInternos: async function(context, asuntosInternos) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + asuntosInternos.fk_idPersonal + '/consultaasuntosinternos', asuntosInternos)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Consulta guardada correctamente.', { root: true })
                })
                .catch(error => console.log(error))
        },

        updateFamiliar: async function({ commit }, familiar) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/consultaasuntosinternos/' + familiar.idFamiliar, familiar)
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
