import { axios } from '../../plugins/axios'
import axios2 from 'axios'

export default {
    namespaced: true,

    state: {
        candidatos: [],
        candidatoParaValidacion: null,
    },

    getters: {
        getCandidatos: state => {
            return state.candidatos.filter(candidato => {
                return candidato.apaterno != '' && candidato.amaterno != '' && candidato.nombre != ''
            })
        },

        getLoggedCandidato: state => {
            return state.candidatos.filter(candidato => {
                return candidato.idPersonal == localStorage.getItem('idPersonal')
            })
        },

        getCandidatoParaValidacion: state => {
            return state.candidatoParaValidacion
        },
    },

    mutations: {
        SET_CANDIDATOS(state, candidatos) {
            state.candidatos = candidatos
        },

        SET_CANDIDATO_PARA_VALIDACION(state, idPersonal) {
            var candidatoParaValidacion = state.candidatos.filter(candidato => {
                return candidato.idPersonal == idPersonal
            })

            state.candidatoParaValidacion = candidatoParaValidacion[0]
        },
    },

    actions: {
        fetchCandidatos: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/candidato')
                .then(response => {
                    commit('SET_CANDIDATOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCandidato: async function({ commit }, candidato) {
            await axios2
                .post(process.env.VUE_APP_API_URL + '/PoliciaCuadrantes/EstadoFuerza/candidato', {
                    candidato,
                })
                .then(response => {
                    // console.log(response)
                })
                .catch(error => console.log(error))
        },

        updateCandidato: async function(context, candidato) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/candidato/' + candidato.idPersonal, candidato)
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                    if (response.status == 200) {
                        context.commit('SET_SNACKBAR_MESSAGE', 'El candidato ha sido actualizado correctamente.', { root: true })
                    } else {
                        context.commit(
                            'SET_SNACKBAR_MESSAGE',
                            'El candidato no pudo ser actualizado. Por favor, verifique que los campos estén completos..',
                            {
                                root: true,
                            }
                        )
                    }
                })
                .catch(error =>
                    context.commit('SET_SNACKBAR_MESSAGE', 'El candidato no pudo ser actualizado. Por favor, verifique que los campos estén completos..', {
                        root: true,
                    })
                )
        },
    },
}
