import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        preaspirantes: [],
        aspirantes: [],
        preaspiranteParaValidacion: null,
        aspiranteParaValidacion: null,
    },

    getters: {
        getPreaspirantes: state => {
            return state.preaspirantes.filter(preaspirante => {
                return preaspirante
            })
        },

        getAspirantes: state => {
            return state.aspirantes
        },

        getPreaspiranteParaValidacion: state => {
            return state.preaspiranteParaValidacion
        },

        getAspiranteParaValidacion: state => {
            return state.aspiranteParaValidacion
        },
    },
    mutations: {
        SET_PREASPIRANTES(state, preaspirantes) {
            state.preaspirantes = preaspirantes
        },

        SET_ASPIRANTES(state, aspirantes) {
            state.aspirantes = aspirantes
        },

        SET_PREASPIRANTE_PARA_VALIDACION(state, idPersonal) {
            var preaspiranteParaValidacion = state.preaspirantes.filter(preaspirante => {
                return preaspirante.idPersonal == idPersonal
            })

            var preaspirante = _.cloneDeep(preaspiranteParaValidacion[0])

            state.preaspiranteParaValidacion = preaspirante
        },

        SET_ASPIRANTE_PARA_VALIDACION(state, idPersonal) {
            var aspiranteParaValidacion = state.aspirantes.filter(aspirante => {
                return aspirante.idPersonal == idPersonal
            })

            var aspirante = _.cloneDeep(aspiranteParaValidacion[0])
            console.log(aspirante)
            state.aspiranteParaValidacion = aspirante
        },
    },
    actions: {
        fetchPreaspirantes: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/preAspirante')
                .then(response => {
                    commit('SET_PREASPIRANTES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchAspirantes: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/pendientesBiometricos')
                .then(response => {
                    commit('SET_ASPIRANTES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        savePreaspirante: async function(context, preaspirante) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal', preaspirante.data)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Preaspirante registrado correctamente.', {
                        root: true,
                    })
                    preaspirante.estudios.forEach(estudio => {
                        estudio.fk_idPersonal = response.data.data[0].ID
                        context.dispatch('estudio/saveEstudiosPersonal', estudio, { root: true })
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
