import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        principiosConstitucionales: [],
    },

    getters: {
        getPrincipiosConstitucionales: state => {
            return state.principiosConstitucionales
        },
    },

    mutations: {
        SET_PRINCIPIOS_CONSTITUCIONALES(state, principiosConstitucionales) {
            state.principiosConstitucionales = principiosConstitucionales
        },
    },

    actions: {
        fetchPrincipiosConstitucionales: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/principiosConstitucionales')
                .then(response => {
                    commit('SET_PRINCIPIOS_CONSTITUCIONALES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
