<template>
    <v-container>
        <v-row justify="center">
            <v-col sm="12" md="4">
                <v-card class="card" color="secondary">
                    <v-card-text v-if="personal" class="white--text">
                        <v-container>
                            <v-row align="center">
                                <v-col cols="12" align="center">
                                    <v-img v-if="personal.foto != ''" :src="personal.foto" width="120"></v-img>
                                    <v-avatar v-else color="secondary" rounded size="80">
                                        <v-icon size="64" color="white">mdi-account</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Nombre</div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ getFullName(personal) }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-caption">Edad</div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ getAge(personal.fechaNacimiento) }}</div>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-caption">Fecha de nacimiento</div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ personal.fechaNacimiento }}</div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Sexo</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatSexo">{{ personal.CatSexo.title }}</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-else>No registrado</div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Puesto</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatPuestoDependencia">
                                        {{ personal.CatPuestoDependencia.catPuesto.puesto }}
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Dependencia</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatPuestoDependencia">
                                        {{ personal.CatPuestoDependencia.catDependencia.nombre }}
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Adscripción</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatArea">
                                        {{ personal.CatArea.nombre }}
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-caption">Subárea</div>
                                    <div class="text-subtitle-1 font-weight-bold" v-if="personal.CatSubArea">
                                        {{ personal.CatSubArea.nombre }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        <div class="white--text my-4">
                            Obteniendo información del personal...
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex'
    export default {
        async mounted() {
            await this.getPersonal()
        },

        data() {
            return {
                personal: null,
            }
        },

        methods: {
            ...mapActions('personal', ['getPersonalToValidateById']),

            async getPersonal() {
                this.personal = await this.getPersonalToValidateById(this.$route.params.id)
            },
        },
    }
</script>
