<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title>
                        <div class="text-h6">Lista de equipos</div>
                        <v-spacer></v-spacer>
                        <v-text-field
                            label="Buscar"
                            v-model="searchEquipo"
                            append-icon="mdi-magnify"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headers" :items="equipos" class="transparent" :search="searchEquipo">
                            <template v-slot:item.opciones="{ item }">
                                <v-btn icon color="secondary" @click="openUpdateEquipoDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn icon color="secondary" @click="openUpdateEstatusEquipoDialog(item)"><v-icon>mdi-update</v-icon></v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <update-equipo-dialog-component
            :active="updateEquipoDialog"
            @close="updateEquipoDialog = false"
            :equipo="selectedEquipo"
        ></update-equipo-dialog-component>
        <estatus-equipo-dialog-component
            :active="updateEstatusEquipoDialog"
            @close="updateEstatusEquipoDialog = false"
            :equipo="selectedEquipo"
        ></estatus-equipo-dialog-component>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'
    import EstatusEquipoDialogComponent from '../../components/equipamiento/EstatusEquipoDialogComponent.vue'
    import UpdateEquipoDialogComponent from '../../components/equipamiento/UpdateEquipoDialogComponent.vue'

    export default {
        components: {
            UpdateEquipoDialogComponent,
            EstatusEquipoDialogComponent,
        },

        data() {
            return {
                headers: [
                    { text: 'Categoría', value: 'CatItem.CatTipoItem.title' },
                    { text: 'Item', value: 'CatItem.item' },
                    { text: 'Número', value: 'numero' },
                    { text: 'Fecha de fabricación', value: 'fechaFabricacion' },
                    { text: 'Vigencia', value: 'aniosVigencia' },
                    { text: 'Estatus', value: 'CatEstatus.title' },
                    { text: 'Opciones', value: 'opciones' },
                ],

                updateEquipoDialog: false,
                updateEstatusEquipoDialog: false,

                selectedEquipo: null,

                searchEquipo: '',
            }
        },

        computed: {
            ...mapGetters('equipo', {
                equipos: 'getEquipos',
            }),
        },

        methods: {
            openUpdateEquipoDialog(equipo) {
                this.selectedEquipo = equipo
                this.updateEquipoDialog = true
            },

            openUpdateEstatusEquipoDialog(equipo) {
                this.selectedEquipo = equipo
                this.updateEstatusEquipoDialog = true
            },
        },
    }
</script>
