<template>
    <v-container v-if="personal">
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Asignación de turno</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-if="personal.CatTurno"
                            color="secondary"
                            background-color="primary"
                            label="Turno actual"
                            v-model="personal.CatTurno.title"
                            rounded
                            filled
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-else
                            color="secondary"
                            background-color="primary"
                            label="Turno actual"
                            value="No asignado"
                            readonly
                            rounded
                            filled
                            dense
                        ></v-text-field>
                        <v-select
                            v-model="nuevoTurno"
                            solo
                            filled
                            rounded
                            color="secondary"
                            background-color="primary"
                            :items="filteredTurnos"
                            item-value="id"
                            item-text="title"
                            item-color="secondary"
                        >
                            <template v-slot:label>Nuevo turno <span class="red--text">*</span> </template>
                        </v-select>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn
                            color="secondary"
                            class="primary--text mb-4"
                            :loading="loading"
                            :disabled="!nuevoTurno || loading"
                            v-on:click="triggerAsignacionTurno()"
                            >Asignar Turno</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchTurnos()
        },

        data() {
            return {
                loading: false,
                nuevoTurno: null,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('turnos', {
                turnos: 'getTurnos',
            }),

            filteredTurnos: function() {
                return this.turnos.filter(turno => {
                    return turno.id == 1 || turno.id == 2 || turno.id == 3
                })
            },
        },

        methods: {
            ...mapActions('personal', ['updateTurnoPersonal', 'fetchPersonal']),
            ...mapActions('turnos', ['fetchTurnos']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            triggerAsignacionTurno: async function() {
                this.loading = true

                await this.updateTurnoPersonal({
                    idPersonal: this.personal.idPersonal,
                    fk_idCatTurno: this.nuevoTurno,
                })

                this.loading = false
                this.$router.push('/recursos_humanos/personal')
            },
        },
    }
</script>
