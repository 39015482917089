import { axios } from '../../plugins/axios'
import axios2 from 'axios'

export default {
    namespaced: true,

    state: {
        validaCurp: false,
    },

    getters: {
        getValidaCurp: state => {
            return state.validaCurp
        },
    },

    mutations: {
        SET_VALIDA_CURP(state, validaCurp) {
            state.validaCurp = validaCurp
        },
    },

    actions: {
        fetchValidaCurp: async function({ commit }, curp) {
            await axios
                .get('/IPH/EstadoFuerza/validaCurp/' + curp)
                .then(response => {
                    commit('SET_VALIDA_CURP', true)
                })
                .catch(error => commit('SET_VALIDA_CURP', false))
        },

        fetchValidaCurpCandidato: async function({ commit }, curp) {
            await axios2
                .post('https://eonproduccion.net/ws/curp.php', { curp: curp })
                .then(response => {
                    if (response.data.length > 0) {
                        commit('SET_VALIDA_CURP', false)
                    } else commit('SET_VALIDA_CURP', true)
                })
                .catch(error => commit('SET_VALIDA_CURP', false))
        },
    },
}
