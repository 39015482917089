import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        reactivosDesempenioInstitucional: [],
        reactivosRendimientoProfesionalismo: [],
        reactivosPoliciaPreventivo: [],
        reactivosPoliciaInvestigacion: [],
    },

    getters: {
        getReactivosDesempenioInstitucional: state => {
            return state.reactivosDesempenioInstitucional
        },

        getReactivosRendimientoProfesionalismo: state => {
            return state.reactivosRendimientoProfesionalismo
        },

        getReactivosPoliciaPreventivo: state => {
            return state.reactivosPoliciaPreventivo
        },

        getReactivosPoliciaInvestigacion: state => {
            return state.reactivosPoliciaInvestigacion
        },
    },

    mutations: {
        SET_REACTIVOS_POLICIA_PREVENTIVO(state, reactivosPoliciaPreventivo) {
            state.reactivosPoliciaPreventivo = reactivosPoliciaPreventivo
        },

        SET_REACTIVOS_POLICIA_INVESTIGACION(state, reactivosPoliciaInvestigacion) {
            state.reactivosPoliciaInvestigacion = reactivosPoliciaInvestigacion
        },

        SET_REACTIVOS_DESEMPENIO_INSTITUCIONAL(state, reactivosDesempenioInstitucional) {
            state.reactivosDesempenioInstitucional = reactivosDesempenioInstitucional
        },

        SET_REACTIVOS_RENDIMIENTO_PROFESIONALISMO(state, reactivosRendimientoProfesionalismo) {
            state.reactivosRendimientoProfesionalismo = reactivosRendimientoProfesionalismo
        },
    },

    actions: {
        fetchReactivosPoliciaPreventivo: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/1/reactivosEvaluacion')
                .then(response => {
                    commit('SET_REACTIVOS_POLICIA_PREVENTIVO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchReactivosPoliciaInvestigacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/2/reactivosEvaluacion')
                .then(response => {
                    commit('SET_REACTIVOS_POLICIA_INVESTIGACION', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchReactivosDesempenioInstitucional: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/4/reactivosEvaluacion')
                .then(response => {
                    commit('SET_REACTIVOS_DESEMPENIO_INSTITUCIONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchReactivosRendimientoProfesionalismo: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/5/reactivosEvaluacion')
                .then(response => {
                    commit('SET_REACTIVOS_RENDIMIENTO_PROFESIONALISMO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
