import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        armasPersonal: [],
        id_arma_personal: 0,
    },

    getters: {
        getArmasPersonal: state => {
            return state.armasPersonal
        },

        getIdArmaPersonal: state => {
            return state.id_arma_personal
        },
    },

    mutations: {
        SET_ARMAS_PERSONAL: (state, armasPersonal) => {
            state.armasPersonal = armasPersonal
        },

        SET_ID_ARMA_PERSONAL(state, idArmaPersonal) {
            // console.log(idArmaPersonal)
            state.id_arma_personal = idArmaPersonal
        },

        UPDATE_ARMA_PERSONAL_STATUS: (state, armaPersonal) => {
            state.armasPersonal[state.armasPersonal.indexOf(armaPersonal.armaPersonal)].Arma.fk_idEstatus = armaPersonal.nuevoEstatus
        },
    },

    actions: {
        fetchArmasPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/personal/' + idPersonal + '/armas')
                .then(response => {
                    commit('SET_ARMAS_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveArmaPersonal: async function(context, armaPersonal) {
            try {
                await axios
                    .post('/PoliciaCuadrantes/Armeria/personal/' + armaPersonal.fk_idPersonal + '/armas', armaPersonal)
                    .then(response => {
                        context.commit('SET_ID_ARMA_PERSONAL', response.data.data[0].ID)
                    })
                    .catch(error => console.log(error))
            } catch (error) {
                console.log(error)
            }
        },

        updateEstatusArmaPersonal: async function({ commit }, armaPersonal) {
            if (armaPersonal.Arma.fk_idEstatus == 1) {
                var nuevoEstatus = 2
            } else {
                var nuevoEstatus = 1
            }

            try {
                await axios.put('/PoliciaCuadrantes/Armeria/armas/' + armaPersonal.Arma.idArma, {
                    fk_idModelo: armaPersonal.Arma.fk_idModelo,
                    fk_idClase: armaPersonal.Arma.fk_idClase,
                    fk_idCalibre: armaPersonal.Arma.fk_idCalibre,
                    fk_idEstatus: nuevoEstatus,
                    fk_idSede: armaPersonal.Arma.fk_idSede,
                    serie: armaPersonal.Arma.serie,
                    folio: armaPersonal.Arma.folio,
                    folioC: armaPersonal.Arma.folioC,
                    folioD: armaPersonal.Arma.folioD,
                    matricula: armaPersonal.Arma.matricula,
                    loc: armaPersonal.Arma.loc,
                    noEconomico: armaPersonal.Arma.noEconomico,
                    ubicacion: armaPersonal.Arma.ubicacion,
                    fechaAltaLoc: armaPersonal.Arma.fechaAltaLoc,
                })
                commit('UPDATE_ARMA_PERSONAL_STATUS', { armaPersonal: armaPersonal, nuevoEstatus: nuevoEstatus })
            } catch (error) {
                console.log(error)
            }
        },

        cancelaArmaPersonal: async function(context, armaPersonal) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/personal/' + armaPersonal.fk_idPersonal + '/armas/' + armaPersonal.idArmaPersonal + '/cancelacion')
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'El arma ha sido desasignada del personal.', { root: true })
                })
                .catch(error => console.log(error))
        },
    },
}
