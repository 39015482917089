<template>
    <v-dialog persistent width="640" v-model="active">
        <v-card :loading="loading" class="rounded-sm" color="primary">
            <v-card-title>
                <div class="text-h6">Municiones de arma</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" v-for="(municion, index) in municionArma" :key="index">
                            <v-row>
                                <v-col>
                                    <div class="text-subtitle-1">Cantidad: {{ municion.cantidad }}</div>
                                    <div class="text-subtitle-2">Asignadas el {{ municion.fechaAsignado }}</div>
                                </v-col>
                                <v-col cols="7">
                                    <v-select
                                        label="Motivo de uso"
                                        v-model="municion.fk_idCatMotivoUso"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :items="motivoUso"
                                        item-value="id"
                                        item-text="title"
                                        item-color="secondary"
                                        @change="triggerUpdateUso($event, municion)"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('municionArma', {
                municionArma: 'getMunicionArma',
            }),

            ...mapGetters('motivoUso', {
                motivoUso: 'getMotivoUso',
            }),
        },

        methods: {
            ...mapActions('municionArma', ['updateMunicionesArmaUtilizadas']),

            async triggerUpdateUso(event, municion) {
                this.loading = true
                await this.updateMunicionesArmaUtilizadas({
                    fk_idArma: municion.fk_idArmaPersonal,
                    id: municion.idMunicionesP,
                    fk_idCatMotivoUso: event,
                })
                this.loading = false
            },

            closeDialog() {
                this.$emit('close')
            },
        },
    }
</script>
