<template>
    <v-main class="primary">
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading"></loader-component>
            <router-view v-else></router-view>
        </transition>
    </v-main>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import LoaderComponent from '../../components/LoaderComponent.vue'

    export default {
        components: {
            LoaderComponent,
        },

        async created() {
            this.ENABLE_LOADER()
            this.$vuetify.theme.themes.light.primary = '#F2F2F2'
            this.$vuetify.theme.themes.light.secondary = '#3D3D3D'
            this.$vuetify.theme.themes.light.accent = '#F58634'
            this.$vuetify.theme.themes.light.anchor = '#34A871'
            await this.fetchProcedimiento()
            await this.fetchEstatusProcedimiento()

            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
            }),
        },

        methods: {
            ...mapActions('personal', ['fetchPersonal']),
            ...mapActions('procedimiento', ['fetchProcedimiento']),
            ...mapActions('estatusProcedimiento', ['fetchEstatusProcedimiento']),

            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),
        },
    }
</script>
