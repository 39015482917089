import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        asignacionItem: [],
    },

    getters: {
        getAsignacionItem: state => {
            return state.asignacionItem
        },
    },

    mutations: {
        SET_ASIGNACION_ITEM(state, asignacionItem) {
            state.asignacionItem = asignacionItem
        },
    },

    actions: {
        fetchAsignacionItem: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/personal/' + idPersonal + '/items')
                .then(response => {
                    commit('SET_ASIGNACION_ITEM', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveAsignacionItem: async function(context, asignacionItem) {
            await axios
                .post('/PoliciaCuadrantes/armeria/personal/' + asignacionItem.fk_idPersonal + '/items', asignacionItem)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Asignación de items realizada correctamente.', {
                        root: true,
                    })
                    var formData = new FormData()
                    formData.append('file', asignacionItem.file)
                    formData.append('idDetalleAsignacion', response.data.data[0].ID)
                    context.dispatch('uploadAsignacion', formData)
                })
                .catch(error => console.log(error))
        },

        updateAsignacionItem: async function({ commit }, asignacionItem) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/personal/' + asignacionItem.fk_idPersonal + '/items/' + asignacionItem.idAsignacion, asignacionItem)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Asignación de items editada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        uploadAsignacion: async function(context, formData) {
            await fileAxios
                .post('/SetOficioDetalleAsignacionEquipo/uploadFiles', formData)
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
