import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        classroomStatus: [],
    },

    getters: {
        getClassroomStatus: state => {
            return state.classroomStatus
        },
    },

    mutations: {
        SET_CLASSROOM_STATUS(state, classroomStatus) {
            state.classroomStatus = classroomStatus
        },
    },

    actions: {
        fetchClassroomStatus: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/estatusAula')
                .then(response => {
                    commit('SET_CLASSROOM_STATUS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
