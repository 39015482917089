import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        referencias: [],
    },

    getters: {
        getReferencias: state => {
            return state.referencias
        },
    },

    mutations: {
        SET_REFERENCIAS(state, referencias) {
            state.referencias = referencias
        },
    },

    actions: {
        fetchReferenciasByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/referencias')
                .then(response => {
                    commit('SET_REFERENCIAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveReferencia: async function(context, referencia) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + referencia.idPersonal + '/referencias', referencia)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Referencia agregada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateReferencia: async function({ commit }, referencia) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + idPersonal + '/referencias/' + referencia.idReferencia, referencia)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
