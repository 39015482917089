<template>
    <v-dialog v-model="active" width="480" persistent>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Cambiar estatus de equipo</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="equipo">
                <v-select
                    v-model="equipo.fk_idCatEstatus"
                    label="Estatus"
                    rounded
                    filled
                    dense
                    color="secondary"
                    background-color="primary"
                    item-color="secondary"
                    item-value="id"
                    item-text="title"
                    :items="estatusEquipamiento"
                ></v-select>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" :disabled="loading" :loading="loading" @click="triggerUpdateEstatusEquipo()">Guardar cambios</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            equipo: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('estatusEquipamiento', {
                estatusEquipamiento: 'getEstatusEquipamiento',
            }),
        },

        methods: {
            ...mapActions('equipo', ['fetchEquipo', 'updateEstatusEquipo']),

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },

            async triggerUpdateEstatusEquipo() {
                this.loading = true
                await this.updateEstatusEquipo({
                    idEquipamiento: this.equipo.idEquipamiento,
                    fk_idEstatus: this.equipo.fk_idCatEstatus,
                })

                await this.fetchEquipo()
                this.closeDialog()
            },
        },
    }
</script>
