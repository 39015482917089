import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoFaltaAdministrativa: [],
    },

    getters: {
        getTipoFaltaAdministrativa: state => {
            return state.tipoFaltaAdministrativa
        },
    },

    mutations: {
        SET_TIPO_FALTA_ADMINISTRATIVA(state, tipoFaltaAdministrativa) {
            state.tipoFaltaAdministrativa = tipoFaltaAdministrativa
        },
    },

    actions: {
        fetchTipoFaltaAdministrativa: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/tipoFaltaAdmin')
                .then(response => {
                    commit('SET_TIPO_FALTA_ADMINISTRATIVA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
