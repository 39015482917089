<template>
    <v-container v-if="preaspirante && dictamen">
        <v-row>
            <v-col cols="12">
                <v-btn @click="$router.go(-1)" text>
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver al catálogo
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="8">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de resultado en consulta previa</v-card-title>
                    <v-card-text v-if="dictamen.length > 0">
                        <v-form v-model="dictamenForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(preaspirante)"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Aspirante"
                                            :rules="nameRules"
                                            readonly
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="dictamen[dictamen.length - 1].afis"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Afis"
                                            :rules="nameRules"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="dictamen[dictamen.length - 1].suic"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Suic"
                                            :rules="nameRules"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="dictamen[dictamen.length - 1].rnsp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Rnsp"
                                            :rules="nameRules"
                                        ></v-text-field>
                                        <v-select
                                            label="Tipo de resultado"
                                            v-model="dictamen[dictamen.length - 1].fk_idCatAlerta"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            item-color="secondary"
                                            :items="alertas"
                                            item-value="id"
                                            item-text="alerta"
                                        ></v-select>
                                        <v-textarea
                                            v-model="dictamen[dictamen.length - 1].resultadoConsultaPrevia"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Resultado en consulta previa"
                                            no-resize
                                            rows="8"
                                            :rules="nameRules"
                                        ></v-textarea>
                                        <v-text-field
                                            v-model="dictamen[dictamen.length - 1].oficio"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Número de oficio"
                                            :rules="nameRules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-text v-else>
                        <div class="text-h6">No se ha agendado cita de registro.</div>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn color="secondary" class="primary--text px-8" :disabled="!dictamenForm || loading" v-on:click="triggerSaveTrainer()"
                            >Registrar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
            await this.fetchDictamenPersonal(this.id)
        },

        data() {
            return {
                loading: false,
                dictamenForm: false,
                menu: false,
                file: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamen: 'getDictamenPersonal',
            }),

            ...mapGetters('alerta', {
                alertas: 'getAlertas',
            }),
        },

        methods: {
            ...mapActions('dictamenPersonal', ['updateDictamenPersonal', 'fetchDictamenPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),

            triggerSaveTrainer: async function() {
                this.loading = true

                await this.updateDictamenPersonal(this.dictamen[this.dictamen.length - 1])

                this.loading = false

                this.$router.push('/personal/preaspirantes')
            },

            saveBirthdayDate: function(date) {
                this.$refs.menu.save(date)
            },

            getFullName: function(personal) {
                return personal.nombre + ' ' + personal.segundoNombre + ' ' + personal.apaterno + ' ' + personal.amaterno
            },
        },
    }
</script>
