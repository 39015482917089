import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        iconos: [],
    },

    getters: {
        getIconos: state => {
            return state.iconos
        },
    },

    mutations: {
        SET_ICONOS(state, iconos) {
            state.iconos = iconos
        },
    },

    actions: {
        fetchIconos: async function({ commit }) {
            await axios
                .get('/EonCloud/iconos')
                .then(response => {
                    commit('SET_ICONOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
