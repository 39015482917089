import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        movimientoArma: [],
        movimientoArmaDisponibles: 0,
    },

    getters: {
        getMovimientoArma: state => {
            return state.movimientoArma
        },

        getMovimientoArmaDisponibles: state => {
            return state.movimientoArmaDisponibles
        },
    },

    mutations: {
        SET_MOVIMIENTO_ARMA(state, movimientoArma) {
            state.movimientoArma = movimientoArma
        },

        SET_MOVIMIENTO_ARMA_DISPONIBLES(state, movimientoArmaDisponibles) {
            state.movimientoArmaDisponibles = movimientoArmaDisponibles
        },
    },

    actions: {
        fetchMovimientoArma: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/movimientosArma')
                .then(response => {
                    commit('SET_MOVIMIENTO_ARMA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchMovimientoArmaDisponibles: async function({ commit }, idModeloArma) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/Modelo/' + idModeloArma + '/movimientosArma/disponibles')
                .then(response => {
                    commit('SET_MOVIMIENTO_ARMA_DISPONIBLES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMovimientoArma: async function(context, movimientoArma) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/movimientosArma', movimientoArma)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Movimiento de arma realizado correctamente.', { root: true })
                })
                .catch(error => console.log(error))
        },

        updateMovimientoArma: async function({ commit }, movimientoArma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/movimientosArma/' + movimientoArma.id, movimientoArma)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
