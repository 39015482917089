import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposMultimedia: [],
    },

    getters: {
        getTiposMultimedia: state => {
            return state.tiposMultimedia
        },
    },

    mutations: {
        SET_TIPOS_MULTIMEDIA(state, tiposMultimedia) {
            state.tiposMultimedia = tiposMultimedia
        },
    },

    actions: {
        fetchTiposMultimedia: async function({ commit }) {
            await axios
                .get('/EonCloud/tiposmultimedia')
                .then(response => {
                    commit('SET_TIPOS_MULTIMEDIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
