import { axios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        tipoDocumento: [],
    },
    getters: {
        getTipoDocumento: state => {
            return state.tipoDocumento
        },
    },
    mutations: {
        SET_TIPO_DOCUMENTO(state, tipoDocumento) {
            state.tipoDocumento = tipoDocumento
        },
    },
    actions: {
        fetchTipoDocumento: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/tipoDocumento')
                .then(response => {
                    commit('SET_TIPO_DOCUMENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
