import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoEvaluacion: [],
    },

    getters: {
        getTipoEvaluacion: state => {
            return state.tipoEvaluacion
        },
    },
    mutations: {
        SET_TIPO_EVALUACION(state, tipoEvaluacion) {
            state.tipoEvaluacion = tipoEvaluacion
        },
    },
    actions: {
        fetchTipoEvaluacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/tipoevaluacion')
                .then(response => {
                    commit('SET_TIPO_EVALUACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
