<template>
    <v-container v-if="cursoToUpdate">
        <v-row justify="center">
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Programar curso</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 50" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form v-model="courseScheduleForm">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                :value="cursoToUpdate.subcategoria"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                label="Curso"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :items="capacitadores"
                                                item-value="id"
                                                item-text="nombre"
                                                v-model="programacionCurso.fk_idCapacitador"
                                                :rules="required"
                                            >
                                                <template v-slot:label>Capacitador que impartirá el curso <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-select
                                                label="Aula"
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :items="aulas"
                                                item-value="id"
                                                item-text="aula"
                                                v-model="programacionCurso.fk_idAula"
                                                :rules="required"
                                            >
                                                <template v-slot:label>Aula <span class="red--text">*</span> </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-container>
                        <v-container v-else>
                            <v-form v-model="periodoCursoForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-date-picker
                                            v-model="periodoCurso"
                                            locale="es"
                                            color="secondary"
                                            no-title
                                            full-width
                                            range
                                            :rules="required"
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="fechaInicio"
                                            v-bind="textInputProps"
                                            label="Fecha incio"
                                            disabled
                                            readonly
                                            :rules="required"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="fechaFin"
                                            v-bind="textInputProps"
                                            label="Fecha fin"
                                            disabled
                                            readonly
                                            :rules="required"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col xl="12" align="center">
                                    <v-btn
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, textInputProps, circularProgressProps } from '../../components/props/props'
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_CURSO_TO_UPDATE(this.id)
        },

        data() {
            return {
                cardActionsProps,
                cardProps,
                cardTitleProps,
                primaryButtonProps,
                textInputProps,
                circularProgressProps,

                loading: false,
                courseScheduleForm: false,
                periodoCursoForm: false,
                activeStep: 1,
                periodoCurso: [null, null],

                programacionCurso: {
                    fk_idSubcategoria: this.id,
                    fk_idCapacitador: null,
                    fk_idAula: null,
                    fechaInicio: null,
                    fechaFin: null,
                    fk_idEstatusCurso: null,
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('estatusCurso', {
                courseStatus: 'getEstatusCurso',
            }),

            ...mapGetters('curso', {
                cursos: 'getCursos',
            }),

            ...mapGetters('capacitador', {
                capacitadores: 'getCapacitadores',
            }),

            ...mapGetters('aula', {
                aulas: 'getAulas',
            }),

            ...mapGetters('curso', {
                cursoToUpdate: 'getCursoToUpdate',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Información principal'
                    case 2:
                        return 'Duración del curso'
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 2) {
                    return 'Siguiente paso'
                } else {
                    return 'Programar curso'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.courseScheduleForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.periodoCursoForm) {
                            return true
                        } else {
                            return false
                        }
                }
            },

            fechaInicio: function() {
                if (this.periodoCurso[0]) {
                    if (moment(this.periodoCurso[1]).isBefore(this.periodoCurso[0])) {
                        return moment(this.periodoCurso[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoCurso[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.periodoCurso[1]) {
                    if (moment(this.periodoCurso[1]).isAfter(this.periodoCurso[0])) {
                        return moment(this.periodoCurso[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoCurso[0]).format('DD/MM/YYYY')
                }
            },
        },

        methods: {
            ...mapActions('cursoProgramado', ['saveCursoProgramado', 'fetchCursosProgramados']),
            ...mapMutations('curso', ['SET_CURSO_TO_UPDATE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 2) {
                    this.activeStep++
                } else {
                    this.loading = true

                    await this.saveCursoProgramado({
                        fkidSubcategoria: this.id,
                        fkidCapacitador: this.programacionCurso.fk_idCapacitador,
                        fkidAula: this.programacionCurso.fk_idAula,
                        fechaInicio: this.fechaInicio,
                        fechaFin: this.fechaFin,
                        fkidEstatusCurso: 1,
                    })

                    await this.fetchCursosProgramados()
                    this.$router.push('/profesionalizacion/cursos')
                    this.loading = false
                }
            },
        },
    }
</script>
