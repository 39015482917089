<template>
    <v-container v-if="cursoToUpdate">
        <v-row>
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de curso</v-card-title>
                    <v-card-text>
                        <v-form v-model="courseForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="cursoToUpdate.subcategoria"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Subcategoría (nombre del curso) <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-select
                                            v-model="cursoToUpdate.fk_idCategoria"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="categories"
                                            item-value="id"
                                            item-text="categoria"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Categoría <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model.number="cursoToUpdate.puntaje"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Puntaje"
                                            :rules="puntajeRules"
                                        >
                                            <template v-slot:label>Puntaje <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="cursoToUpdate.observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="4"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            v-model="cursoToUpdate.file"
                                            label="Curso .jpg"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" class="mb-8" :disabled="!courseForm" v-on:click="triggerUpdateCourse()">Editar curso</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_CURSO_TO_UPDATE(this.id)
        },

        data() {
            return {
                loading: false,
                courseForm: false,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                puntajeRules: [v => !!v || 'Ingrese un puntaje válido.', v => v > 0 || 'Ingrese un puntaje válido.'],
            }
        },

        computed: {
            ...mapGetters('categoriaCurso', {
                categories: 'getCategories',
            }),

            ...mapGetters('curso', {
                cursoToUpdate: 'getCursoToUpdate',
            }),
        },

        methods: {
            ...mapActions('curso', ['updateCurso']),
            ...mapMutations('curso', ['SET_CURSO_TO_UPDATE']),
            ...mapActions('curso', ['fetchCursos']),

            triggerUpdateCourse: async function() {
                this.loading = true

                this.cursoToUpdate.nombre = this.cursoToUpdate.file.name
                await this.updateCurso(this.cursoToUpdate)

                await this.fetchCursos()
                this.$router.push('/profesionalizacion/cursos')
                this.loading = false
            },
        },
    }
</script>
