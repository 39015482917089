import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        categorias: [],
    },

    getters: {
        getCategorias: state => {
            return state.categorias
        },
    },

    mutations: {
        SET_CATEGORIAS(state, categorias) {
            state.categorias = categorias
        },
    },

    actions: {
        fetchCategorias: async function({ commit }) {
            await axios
                .get('/EonCloud/categorias')
                .then(response => {
                    commit('SET_CATEGORIAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
