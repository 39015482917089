<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Puesto y dependencia</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="8">
                        <div class="text-h6 font-weight-bold">Dependencia</div>
                    </v-col>
                    <v-col cols="4" v-if="personal.CatRamaDependencia">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="personal.CatRamaDependencia.CatDependencia.catInstancia.title"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Instancia"
                                ></v-text-field>
                                <v-text-field
                                    v-model="personal.CatRamaDependencia.CatDependencia.nombre"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Nombre"
                                ></v-text-field>
                                <v-text-field
                                    v-model="personal.CatRamaDependencia.ramaDependencia"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Dirección"
                                ></v-text-field>
                                <template v-if="personal.CatSubArea">
                                    <v-text-field
                                        v-model="personal.CatSubArea.CatArea.nombre"
                                        rounded
                                        readonly
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Área"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="personal.CatSubArea.nombre"
                                        rounded
                                        readonly
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Sub-área"
                                    ></v-text-field>
                                </template>
                                <template v-else>
                                    <v-text-field
                                        value=""
                                        rounded
                                        readonly
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Área"
                                    ></v-text-field>
                                    <v-text-field
                                        value=""
                                        rounded
                                        readonly
                                        filled
                                        dense
                                        background-color="primary"
                                        color="secondary"
                                        label="Sub-área"
                                    ></v-text-field>
                                </template>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="8">
                        <div class="text-h6 font-weight-bold">Puesto</div>
                    </v-col>
                    <v-col cols="4" v-if="personal.CatPuestoDependencia">
                        <v-row>
                            <v-col cols="12">
                                <!-- * Muestra tipo de función (incluyendo operativa con funciones administrativas) -->
                                <!-- <v-text-field
                                    v-if="personal.CatTipoFuncion"
                                    v-model="personal.CatTipoFuncion.title"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Tipo de Puesto"
                                ></v-text-field> -->
                                <v-text-field
                                    v-if="personal.CatPuestoDependencia.CatTipoPuesto"
                                    v-model="personal.CatPuestoDependencia.CatTipoPuesto.title"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Tipo de Puesto"
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    value=""
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Tipo de Puesto"
                                ></v-text-field>
                                <v-text-field
                                    v-model="personal.CatPuestoDependencia.catPuesto.puesto"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Puesto"
                                ></v-text-field>
                                <v-text-field
                                    v-if="perfil == 'Recursos Humanos' || perfil == 'Desarrollo Profesional'"
                                    v-model="personal.plaza"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Plaza"
                                ></v-text-field>
                                <v-text-field
                                    v-if="personal.CatTurno"
                                    v-model="personal.CatTurno.title"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Turno"
                                ></v-text-field>
                                <v-text-field v-else rounded readonly filled dense background-color="primary" color="secondary" label="Turno"></v-text-field>
                                <v-textarea
                                    v-model="personal.CatPuestoDependencia.funciones"
                                    rounded
                                    readonly
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    label="Funciones"
                                    no-resize
                                    rows="4"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            personal: {
                type: Object,
            },
        },

        data() {
            return {
                selectedTab: null,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),
        },
    }
</script>
