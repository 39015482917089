<template>
    <v-dialog v-model="dialog" width="360">
        <template v-slot:activator="{ on, attrs }">
            <v-btn rounded color="secondary" class="mx-2" v-bind="attrs" v-on="on">
                Descargar código QR
            </v-btn>
        </template>
        <v-card class="pt-8">
            <v-card-text class="text-center">
                <qrcode-vue :value="qrValue" :size="200"></qrcode-vue>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" class="px-8" @click="dialog = false">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    export default {
        components: {
            QrcodeVue,
        },

        props: {
            idPersonal: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                dialog: false,
            }
        },

        computed: {
            qrValue() {
                return `https://edofza.eonproduccion.net/valida-personal/${this.idPersonal}`
            },
        },
    }
</script>
