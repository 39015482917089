import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        parentesco: [],
    },

    getters: {
        getParentesco: state => {
            return state.parentesco
        },
    },

    mutations: {
        SET_PARENTESCO(state, parentesco) {
            state.parentesco = parentesco
        },
    },

    actions: {
        fetchParentesco: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/C3/catparentesco')
                .then(response => {
                    commit('SET_PARENTESCO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
