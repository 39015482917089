import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoSeniaParticular: [],
    },

    getters: {
        getTipoSeniaParticular: state => {
            return state.tipoSeniaParticular
        },
    },

    mutations: {
        SET_TIPO_SENIA_PARTICULAR(state, tipoSeniaParticular) {
            state.tipoSeniaParticular = tipoSeniaParticular
        },
    },

    actions: {
        fetchTipoSeniaParticular: async function({ commit }) {
            await axios
                .get('/EonCloud/common/tipoSeniaParticular')
                .then(response => {
                    commit('SET_TIPO_SENIA_PARTICULAR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
