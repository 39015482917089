<template>
    <v-container v-if="personal">
        <v-row justify="center">
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Asignación de puesto, función y adscripción</v-card-title>
                    <v-card-text>
                        <v-container class="mb-4">
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(personal)"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Nombre del personal"
                                            maxlength="20"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="personal.CatRamaDependencia">
                                        <v-text-field
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Dependencia actual"
                                            :value="personal.CatRamaDependencia.CatDependencia.nombre"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="personal.fk_idRamaDependencia"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="ramas"
                                            item-value="id"
                                            item-text="ramaDependencia"
                                            item-color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Dirección <span class="red--text">*</span> </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="personal.fk_idCatArea"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="filteredAreas"
                                            item-value="id"
                                            item-text="nombre"
                                            item-color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Adscripción <span class="red--text">*</span> </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="personal.fk_idCatSubArea"
                                            label="Subárea"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="filteredSubareas"
                                            item-value="id"
                                            item-text="nombre"
                                            item-color="secondary"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="personal.fk_idPuestoDependencia"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="puestos"
                                            item-value="id"
                                            item-text="catPuesto.puesto"
                                            item-color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Puesto <span class="red--text">*</span> </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="text-subtitle-2">Tipo de puesto <span class="red--text">*</span></div>
                                        <v-radio-group v-model="personal.fk_idCatTipoFuncion">
                                            <v-radio
                                                v-for="tipo in tipoFuncion"
                                                :key="tipo.id"
                                                :label="tipo.title"
                                                :value="tipo.id"
                                                color="secondary"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 3">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="personal.plaza"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Plaza"
                                            maxlength="20"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { circularProgressProps } from '../../components/props/props'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchTipoPuesto()
            await this.fetchTipoFuncion()
        },

        data() {
            return {
                circularProgressProps,
                activeStep: 1,
                loading: false,
                asignaSedePersonalForm: false,
                dependencia: null,
                rama: null,
                region: null,
                sede: null,
                subsede: null,

                selectedSubsede: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('dependencia', {
                dependencias: 'getDependencias',
            }),

            ...mapGetters('rama', {
                ramas: 'getRamas',
            }),

            ...mapGetters('puesto', {
                puestos: 'getPuestos',
            }),

            ...mapGetters('tipoPuesto', {
                tipoPuesto: 'getTipoPuesto',
            }),

            ...mapGetters('tipoFuncion', {
                tipoFuncion: 'getTipoFuncion',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('areas', {
                areas: 'getAreas',
            }),

            ...mapGetters('subareas', {
                subareas: 'getSubareas',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Dirección, adscripción y área'
                    case 2:
                        return 'Puesto'
                    case 3:
                        return 'Plaza'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        return true
                    case 2:
                        return true
                    case 3:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Guardar registro'
                }
            },

            filteredSubareas: function() {
                return this.subareas.filter(subarea => {
                    return subarea.fk_idCatArea == this.personal.fk_idCatArea
                })
            },

            filteredAreas: function() {
                return this.areas.filter(area => {
                    return area.fk_idCatRamaDependencia == this.personal.fk_idRamaDependencia
                })
            },
        },

        methods: {
            ...mapActions('personal', [
                'updatePuestoPersonal',
                'updateSubsedePersonal',
                'updateAreaPersonal',
                'fetchPersonal',
                'updatePersonal',
                'updateSubareaPersonal',
            ]),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapActions('tipoPuesto', ['fetchTipoPuesto']),
            ...mapActions('tipoFuncion', ['fetchTipoFuncion']),
            ...mapActions('puesto', ['fetchPuestosByDependencia']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    await this.updatePersonal(this.personal)

                    await this.updatePuestoPersonal({
                        idPersonal: this.id,
                        fk_idPuestoDependencia: this.personal.fk_idPuestoDependencia,
                    })
                    await this.updateAreaPersonal({
                        idPersonal: this.id,
                        fk_idCatArea: this.personal.fk_idCatArea,
                    })

                    if (this.personal.fk_idCatSubArea != 0) {
                        await this.updateSubareaPersonal({
                            idPersonal: this.id,
                            fk_idCatSubArea: this.personal.fk_idCatSubArea,
                        })
                    }
                    this.SET_SNACKBAR_MESSAGE('La información del elemento ha sido actualizada correctamente.')

                    this.$router.go(-1)
                }
            },
        },
    }
</script>
