import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        modelos: [],
    },

    getters: {
        getModelos: state => {
            return state.modelos
        },
    },

    mutations: {
        SET_MODELOS(state, modelos) {
            state.modelos = modelos
        },
    },

    actions: {
        fetchModelos: async function({ commit }) {
            await axios
                .get('/EonCloud/modelos')
                .then(response => {
                    commit('SET_MODELOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
