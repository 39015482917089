<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="rounded-sm" color="primary">
            <v-card-title>
                <div class="text-h6">Lista de cargadores</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-virtual-scroll :items="cargadores" item-height="64" height="400" v-if="cargadores.length > 0">
                    <template v-slot:default="{ item, index }">
                        <v-list-item :key="index">
                            <v-list-item-avatar>
                                <v-avatar color="secondary" class="white--text">{{ index + 1 }}</v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div class="text-caption text--secondary">Número económico</div>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <div class="text-body-2">
                                        {{ item.noEconomico }}
                                    </div>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="openUpdateCargadorDialogComponent(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
                <div v-else class="text-subtitle-1">No hay cargadores registrados de este calibre.</div>
            </v-card-text>
        </v-card>
        <update-cargador-dialog-component
            :active="updateCargadorDialog"
            :cargador="selectedCargador"
            @close="updateCargadorDialog = false"
        ></update-cargador-dialog-component>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex'
    import UpdateCargadorDialogComponent from './UpdateCargadorDialogComponent.vue'

    export default {
        components: {
            UpdateCargadorDialogComponent,
        },

        props: {
            active: {
                type: Boolean,
            },
        },

        data() {
            return {
                updateCargadorDialog: false,
                selectedCargador: null,
            }
        },

        computed: {
            ...mapGetters('cargadores', {
                cargadores: 'getCargadores',
            }),
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },

            openUpdateCargadorDialogComponent(cargador) {
                this.selectedCargador = cargador
                this.updateCargadorDialog = true
            },
        },
    }
</script>
