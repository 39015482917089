<template>
    <v-navigation-drawer fixed color="primary" class="elevation-8" :width="getNavigationDrawerWidth" v-if="showNavigationDrawer" app>
        <v-list-item class="pl-4">
            <v-img src="@/assets/img/policia.png" contain class="mb-1"></v-img>
        </v-list-item>
        <v-divider></v-divider>
        <v-list v-if="muestraMenuProfesionalizacion">
            <v-list-item>
                <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/dashboard"><v-icon>mdi-home</v-icon></v-btn>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_RECLUTAMIENTO' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/candidatos" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-account-edit</v-icon></v-btn
                        >
                    </template>
                    <span>Candidatos</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_RECLUTAMIENTO' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/preaspirantes" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-account-group</v-icon></v-btn
                        >
                    </template>
                    <span>Control de aspirantes</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_PROFESIONALIZACION' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/capacitadores" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-school</v-icon></v-btn
                        >
                    </template>
                    <span>Capacitadores</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_PROFESIONALIZACION' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/aulas" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-domain</v-icon></v-btn
                        >
                    </template>
                    <span>Aulas</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_PROFESIONALIZACION' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/cursos" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-cast-education</v-icon></v-btn
                        >
                    </template>
                    <span>Cursos</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_PROFESIONALIZACION' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/cursos_programados" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-calendar</v-icon></v-btn
                        >
                    </template>
                    <span>Cursos programados</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'IFCSP_PROFESIONALIZACION' || perfil === 'IFCSP_ADMINISTRADOR' || perfil === 'Profesionalización'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/profesionalizacion/solicitud_cursos" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-comment-quote</v-icon></v-btn
                        >
                    </template>
                    <span>Sugerencias de cursos</span>
                </v-tooltip>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuPersonal">
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/personal/dashboard" v-bind="attrs" v-on="on"><v-icon>mdi-home</v-icon></v-btn>
                    </template>
                    <span>Home</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/personal/preaspirantes" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-account-group</v-icon></v-btn
                        >
                    </template>
                    <span>Aspirantes pendientes de consulta previa</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/personal/aspirantes" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-account-multiple-check</v-icon></v-btn
                        >
                    </template>
                    <span>Aspirantes pendientes de registro de CUIP</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/personal/personal" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-shield-account</v-icon></v-btn
                        >
                    </template>
                    <span>Personal</span>
                </v-tooltip>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuEnlace">
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/enlace_c3/dashboard" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-home</v-icon></v-btn
                        >
                    </template>
                    <span>Home</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/enlace_c3/aspirantes" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-account-alert</v-icon></v-btn
                        >
                    </template>
                    <span>Aspirantes</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/enlace_c3/personal" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-shield-account</v-icon></v-btn
                        >
                    </template>
                    <span>Personal</span>
                </v-tooltip>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuRH">
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/recursos_humanos/dashboard" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-home</v-icon></v-btn
                        >
                    </template>
                    <span>Inicio</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/recursos_humanos/personal" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-shield-account</v-icon></v-btn
                        >
                    </template>
                    <span>Personal</span>
                </v-tooltip>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuArmeria">
            <v-list-item>
                <v-menu bottom left offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" v-bind="attrs" v-on="on"><v-icon>mdi-book</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            link
                            to="/armeria/pase_lista_diario"
                            v-on:click="weaponDailyRollCallDialogDialog = true"
                            v-if="perfil === 'ARMERIA_PASE DE LISTA' || perfil === 'Armeria'"
                        >
                            <v-list-item-title>Fatiga de entrada y salida de arma</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/armeria/registro_loc" v-if="perfil === 'ARMERIA_LOC' || perfil === 'Armeria'">
                            <v-list-item-title>Registro de LOC</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/armeria/loc_personal">
                            <v-list-item-title>Registro de personal a LOC</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
            <v-list-item>
                <v-menu bottom left offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" v-bind="attrs" v-on="on"><v-icon>mdi-pistol</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item link to="/armeria/catalogo_armas">
                            <v-list-item-title>Catálogo de armas</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/armeria/consulta_armas">
                            <v-list-item-title>Inventario de armas</v-list-item-title>
                        </v-list-item>
                        <v-list-item link to="/armeria/asignacion_arma">
                            <v-list-item-title>Asignar arma a personal</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
            <v-list-item>
                <v-menu bottom left offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" v-bind="attrs" v-on="on"><v-icon>mdi-ammunition</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item link to="/armeria/asignacion_municion">
                            <v-list-item-title>Asignar municiones a personal</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-list>
                        <v-list-item link to="/armeria/registro_cargador">
                            <v-list-item-title>Registro de cargador</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-list>
                        <v-list-item link to="/armeria/asignacion_municion_curso">
                            <v-list-item-title>Asignar municiones a un curso</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
            <v-list-item>
                <v-menu bottom left offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" v-bind="attrs" v-on="on"><v-icon>mdi-package</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item link to="/armeria/inventario_municiones">
                            <v-list-item-title>Inventario de municiones y cargadores</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            link
                            to="/armeria/inventario_items"
                            v-if="perfil === 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS' || perfil === 'Armeria'"
                        >
                            <v-list-item-title>Inventario de items</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            link
                            to="/armeria/asignacion_items"
                            v-if="perfil === 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS' || perfil === 'Armeria'"
                        >
                            <v-list-item-title>Asignación de items a personal</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuEquipamiento">
            <!-- <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/equipamiento/dashboard" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-home</v-icon></v-btn
                        >
                    </template>
                    <span>Inicio</span>
                </v-tooltip>
            </v-list-item> -->
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/equipamiento/personal" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-shield-account</v-icon></v-btn
                        >
                    </template>
                    <span>Personal</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/equipamiento/items" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-briefcase-variant</v-icon></v-btn
                        >
                    </template>
                    <span>Catálogo de items</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/equipamiento/equipo" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-briefcase-variant</v-icon></v-btn
                        >
                    </template>
                    <span>Control de equipo</span>
                </v-tooltip>
            </v-list-item>
        </v-list>
        <v-list v-if="muestraMenuDesarrolloProfesional">
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/desarrollo_profesional/dashboard" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-home</v-icon></v-btn
                        >
                    </template>
                    <span>Inicio</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/desarrollo_profesional/personal" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-shield-account</v-icon></v-btn
                        >
                    </template>
                    <span>Personal</span>
                </v-tooltip>
            </v-list-item>
            <v-list-item v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONDECORACIONES Y CONVOCATORIAS' || perfil === 'Desarrollo Profesional'">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/desarrollo_profesional/convocatorias" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-file-certificate</v-icon></v-btn
                        >
                    </template>
                    <span>Convocatorias</span>
                </v-tooltip>
            </v-list-item>
            <!-- * Se indicó que las promociones se manejan dentro de convocatorias -->
            <!-- <v-list-item>
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon class="menu-button mr-3 primary--text" to="/desarrollo_profesional/promociones" v-bind="attrs" v-on="on"
                            ><v-icon>mdi-stairs-up</v-icon></v-btn
                        >
                    </template>
                    <span>Promociones</span>
                </v-tooltip>
            </v-list-item> -->
        </v-list>
        <template v-slot:append>
            <v-list>
                <v-list-item class="pl-4">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="menu-button primary--text" icon v-bind="attrs" v-on="on" v-on:click="$vuetify.theme.dark = !$vuetify.theme.dark">
                                <v-icon v-if="$vuetify.theme.dark">mdi-brightness-6</v-icon>
                                <v-icon v-else>mdi-brightness-4</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="$vuetify.theme.dark">Cambiar a modo oscuro</span>
                        <span v-else>Cambiar a modo claro</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item class="pl-4">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="menu-button primary--text" icon v-bind="attrs" v-on="on" @click="openUrl('https://eonproduccion.net/soporte/')"
                                ><v-icon>mdi-face-agent</v-icon></v-btn
                            >
                        </template>
                        <span>Soporte técnico</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item class="pl-4">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="menu-button primary--text" icon v-bind="attrs" v-on="on" v-on:click="versionDialog = true"
                                ><v-icon>mdi-information</v-icon></v-btn
                            >
                        </template>
                        <span>Acerca de</span>
                    </v-tooltip>
                </v-list-item>
                <v-list-item class="pl-4" v-if="perfil == 'ADMINISTRADOR'">
                    <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="menu-button primary--text" icon v-bind="attrs" v-on="on" to="/launcher"><v-icon>mdi-exit-to-app</v-icon></v-btn>
                        </template>
                        <span>Salir de la aplicación</span>
                    </v-tooltip>
                </v-list-item>
            </v-list>
        </template>
        <version-dialog-component :active="versionDialog" @close="versionDialog = false"></version-dialog-component>
    </v-navigation-drawer>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex'
    import VersionDialogComponent from './VersionDialogComponent.vue'

    export default {
        components: {
            VersionDialogComponent,
        },

        data() {
            return {
                versionDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
                token: 'getToken',
                perfil: 'getPerfil',
            }),

            getNavigationDrawerWidth: function() {
                if (this.loading) {
                    return 0
                } else {
                    return 64
                }
            },

            showNavigationDrawer: function() {
                if (
                    this.token.length == 0 ||
                    this.$route.name == 'Portal' ||
                    this.$route.name == 'Registro de preaspirante' ||
                    this.$route.name == 'Login' ||
                    this.$route.name == 'Launcher'
                ) {
                    return false
                } else return true
            },

            muestraMenuPreaspirantes: function() {
                if (this.$route.matched.some(({ path }) => path === '/candidato')) {
                    return true
                } else return false
            },

            muestraMenuProfesionalizacion: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return true
                } else return false
            },

            muestraMenuPersonal: function() {
                if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    return true
                } else return false
            },

            muestraMenuArmeria: function() {
                if (this.$route.matched.some(({ path }) => path === '/armeria')) {
                    return true
                } else return false
            },

            muestraMenuRH: function() {
                if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    return true
                } else return false
            },

            muestraMenuEquipamiento: function() {
                if (this.$route.matched.some(({ path }) => path === '/equipamiento')) {
                    return true
                } else return false
            },

            muestraMenuDesarrolloProfesional: function() {
                if (this.$route.matched.some(({ path }) => path === '/desarrollo_profesional')) {
                    return true
                } else return false
            },

            muestraMenuEnlace: function() {
                if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            triggerLogout: function() {
                this.SET_SNACKBAR_MESSAGE('Cerrando sesión...')
                this.logout()
                window.location.reload()
                // this.$router.replace('/')
            },

            openUrl(url) {
                window.open(url, '_blank')
            },
        },
    }
</script>
