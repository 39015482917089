<template>
    <v-container v-if="personal">
        <v-row justify="center">
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de foto y cuip</v-card-title>
                    <v-card-text>
                        <v-form v-model="dictamenForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(personal)"
                                            readonly
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Nombre del personal"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="cuip"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            maxlength="20"
                                            counter
                                            :rules="nameRules"
                                            validate-on-blur
                                        >
                                            <template v-slot:label>CUIP <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn
                            color="secondary"
                            class="primary--text px-8"
                            :loading="loading"
                            :disabled="!dictamenForm || loading"
                            v-on:click="triggerSaveCuip()"
                            >Guardar cambios en personal</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    import sha1 from 'sha1'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.fetchPersonalById(this.id)
        },

        data() {
            return {
                loading: false,
                dictamenForm: false,
                menu: false,
                file: null,
                cuip: '',

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalSingle',
            }),
        },

        methods: {
            ...mapActions('cuip', ['saveCuip']),
            ...mapActions('personal', ['uploadFoto', 'fetchPersonalById']),
            ...mapActions('preaspirante', ['fetchAspirantes']),
            ...mapActions('usuario', ['saveUsuario']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            triggerSaveCuip: async function() {
                this.loading = true

                await this.saveCuip({
                    idPersonal: this.id,
                    cuip: this.cuip,
                })

                await this.saveUsuario({
                    fk_idPersonal: this.id,
                    loginField: this.cuip,
                    newPassword: sha1(this.cuip).toUpperCase(),
                    withTwoFactor: false,
                })

                await this.fetchAspirantes()

                this.$router.go(-1)
            },
        },
    }
</script>
