import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        sexo: [],
    },

    getters: {
        getSexo: state => {
            return state.sexo
        },
    },

    mutations: {
        SET_SEXO(state, sexo) {
            state.sexo = sexo
        },
    },

    actions: {
        fetchSexo: async function({ commit }) {
            await axios
                .get('/EonCloud/sexo')
                .then(response => {
                    commit('SET_SEXO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
