<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="card" color="primary">
            <v-card-title>
                <div class="text-h6">Registrar sanción</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-form v-model="form" ref="form">
                    <v-text-field v-model="sancion.oficio" rounded filled dense color="secondary" background-color="primary" :rules="required">
                        <template v-slot:label>Número de oficio <span class="red--text">*</span> </template>
                    </v-text-field>
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="sancion.fecha"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                :rules="required"
                            >
                                <template v-slot:label>Fecha <span class="red--text">*</span> </template>
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="sancion.fecha" @input="menu = false"></v-date-picker>
                    </v-menu>
                    <v-textarea
                        v-model="sancion.motivo"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        no-resize
                        rows="4"
                        :rules="required"
                    >
                        <template v-slot:label>Motivo <span class="red--text">*</span> </template>
                    </v-textarea>
                    <v-textarea
                        label="Descripción"
                        v-model="sancion.descripcion"
                        rounded
                        filled
                        dense
                        color="secondary"
                        background-color="primary"
                        no-resize
                        rows="4"
                    ></v-textarea>
                    <v-checkbox label="Sanción vigente" v-model="sancion.vigente" color="secondary"></v-checkbox>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" :loading="loading" :disabled="loading || !form" @click="triggerSaveSancion()">Registrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            personal: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
                menu: false,
                form: false,

                sancion: {
                    fk_idPersonal: null,
                    fk_idPersonalAutoriza: this.idPersonal,
                    oficio: '',
                    motivo: '',
                    descripcion: '',
                    vigente: true,
                    fecha: null,
                },

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters({
                idPersonal: 'getIdPersonal',
            }),
        },

        methods: {
            ...mapActions('sanciones', ['saveSancion']),

            closeDialog() {
                this.$refs.form.resetValidation()
                this.$emit('close')
                this.loading = false
                this.sancion = {
                    oficio: '',
                    motivo: '',
                    descripcion: '',
                    vigente: true,
                    fecha: null,
                }
            },

            async triggerSaveSancion() {
                this.loading = true
                this.sancion.fk_idPersonal = this.personal.idPersonal
                await this.saveSancion(this.sancion)
                this.closeDialog()
            },
        },
    }
</script>
