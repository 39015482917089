import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusProcedimiento: [],
    },

    getters: {
        getEstatusProcedimiento: state => {
            return state.estatusProcedimiento
        },
    },

    mutations: {
        SET_ESTATUS_PROCEDIMIENTO(state, estatusProcedimiento) {
            state.estatusProcedimiento = estatusProcedimiento
        },
    },

    actions: {
        fetchEstatusProcedimiento: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/estatusProcedimiento')
                .then(response => {
                    commit('SET_ESTATUS_PROCEDIMIENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
