import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        desempeñoInstitucional: [],
        desempeñoInstitucionalPersonal: null,
    },

    getters: {
        getDesempeñoInstitucional: state => {
            return state.desempeñoInstitucional
        },

        getDesempeñoInstitucionalPersonal: state => {
            return state.desempeñoInstitucionalPersonal
        },
    },

    mutations: {
        SET_DESEMPEÑO_INSTITUCIONAL(state, desempeñoInstitucional) {
            state.desempeñoInstitucional = desempeñoInstitucional
        },

        SET_DESEMPEÑO_INSTITUCIONAL_PERSONAL(state, desempeñoInstitucionalPersonal) {
            state.desempeñoInstitucionalPersonal = desempeñoInstitucionalPersonal[0]
        },
    },

    actions: {
        fetchDesempeñoInstitucional: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/desempenioInstitucional')
                .then(response => {
                    commit('SET_DESEMPEÑO_INSTITUCIONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchDesempeñoInstitucionalById: async function({ commit }, evaluacion) {
            await axios
                .get(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        evaluacion.fkidPersonal +
                        '/desempenioInstitucional/' +
                        evaluacion.idDesempenioInstitucional
                )
                .then(response => {
                    commit('SET_DESEMPEÑO_INSTITUCIONAL_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveDesempeñoInstitucional: async function({ commit }, desempeñoInstitucional) {
            await axios
                .post(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + desempeñoInstitucional.fk_idPersonal + '/desempenioInstitucional',
                    desempeñoInstitucional
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateDesempeñoInstitucional: async function({ commit }, desempeñoInstitucional) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        desempeñoInstitucional.fk_idPersonal +
                        '/desempenioInstitucional/' +
                        desempeñoInstitucional.id,
                    desempeñoInstitucional
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatus: async function({ commit }, desempeñoInstitucional) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        desempeñoInstitucional.fk_idPersonal +
                        '/desempenioInstitucional/' +
                        desempeñoInstitucional.id +
                        '/estatus',
                    { fk_idCatEstatusEvaluacion: 3 }
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
