<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Documentos</div>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row v-if="documentos.length > 0">
                    <v-col cols="4" v-for="documento in documentos" :key="documento.id">
                        <template v-if="documento.URL !== ''">
                            <v-card color="primary" class="card" v-if="documento.fk_idEstatus == 1" height="100%">
                                <v-card-title class="font-weight-bold justify-center">{{ documento.TipoDocumento.title }}</v-card-title>
                                <v-card-text align="center">
                                    <v-img :src="documento.URL" contain v-if="getDocumentType(documento.nombre) == 'img'">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <embed
                                        v-else-if="getDocumentType(documento.nombre) == 'pdf'"
                                        width="100%"
                                        class="pdf"
                                        name="plugin"
                                        :src="documento.URL + '#toolbar=0&navpanes=0&scrollbar=0'"
                                        type="application/pdf"
                                    />
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="secondary" v-on:click="download(documento.URL)">Descargar</v-btn>
                                </v-card-actions>
                            </v-card>
                            <v-card color="primary" class="card" v-else>
                                <v-card-title class="font-weight-bold justify-center">{{ documento.TipoDocumento.title }}</v-card-title>
                                <v-card-text align="center">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="6">
                                                <div class="text-subtitle-2">
                                                    Fecha de carga
                                                </div>
                                            </v-col>
                                            <v-col cols="6">
                                                <div class="text-subtitle-2">
                                                    04/03/2021
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <v-icon size="80" color="success">mdi-check</v-icon>
                                    <div class="text-subtitle-2 font-weight-bold">Este documento ha sido validado</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="secondary" v-on:click="download(documento.URL)">Descargar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                        <v-card color="primary" class="card" v-else>
                            <v-card-title class="font-weight-bold justify-center">{{ documento.TipoDocumento.title }}</v-card-title>
                            <v-card-text align="center">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="text-subtitle-2">
                                                No se encontró la URL del documento
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" align="center">
                        <v-icon color="primary darken-3" size="256">mdi-folder-alert</v-icon>
                        <div class="text-h6">Este elemento no ha registrado documentos</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center">
                        <v-btn v-if="perfil !== 'ADMINISTRADOR' && perfil !== 'Policia'" color="secondary" :to="{ name: getRouteName, params: { id: id } }"
                            >Agregar documento</v-btn
                        >
                        <agrega-documento-dialog-component :id="id" v-else></agrega-documento-dialog-component>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<style scoped>
    .pdf {
        height: 270px;
        overflow: hidden !important;
    }
</style>
<script>
    import { mapGetters } from 'vuex'
    import AgregaDocumentoDialogComponent from '../../views/policia/AgregaDocumentoDialogComponent.vue'

    export default {
        components: {
            AgregaDocumentoDialogComponent,
        },

        props: {
            documentos: {
                type: Array,
            },

            id: {
                typer: Number,
            },
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            getRouteName: function() {
                if (this.$route.matched.some(({ path }) => path === '/profesionalizacion')) {
                    return 'agregaDocumentoPersonal'
                } else if (this.$route.matched.some(({ path }) => path === '/enlace_c3')) {
                    {
                        return 'agregaDocumentoEnlace'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/personal')) {
                    {
                        return 'agregaDocumentoRegistroPersonal'
                    }
                } else if (this.$route.matched.some(({ path }) => path === '/recursos_humanos')) {
                    {
                        return 'recursos_humanos.agrega_documento_personal'
                    }
                } else {
                    return 'policia.agrega_documento_personal'
                }
            },
        },
    }
</script>
