<template>
    <v-container v-if="personal">
        <v-row>
            <v-col cols="4">
                <v-btn text class="mx-2" v-on:click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon> Volver a catálogo de personal</v-btn>
            </v-col>
            <v-col cols="8" align="right">
                <v-spacer></v-spacer>
                <v-btn
                    rounded
                    readonly
                    color="secondary"
                    class="mx-2"
                    v-on:click="
                        download(
                            'http://ssctlaxcala.eonproduccion.net/tableros/report.php?r=43&u1=' +
                                personal.idPersonal +
                                '&m=1&h=16e71e786d66b4fb52012cf3aaf20c26c0775a4f&export=pdf'
                        )
                    "
                    >Descargar expediente</v-btn
                >
                <v-btn v-on:click="download(personal.foto)" rounded color="secondary" class="mx-2" v-if="personal.foto.length > 0"
                    >Descargar fotografía</v-btn
                >
            </v-col>
            <v-col cols="4" xl="3">
                <v-row>
                    <v-col cols="12">
                        <personal-card-component :personal="personal"></personal-card-component>
                    </v-col>
                    <v-col cols="12">
                        <menu-list-component :estatus="personal.fk_idEstatus"></menu-list-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="9">
                <datos-generales-component v-if="selectedMenu == 1" :personal="personal" :estudios="estudiosPersonal"></datos-generales-component>
                <referencias-component v-if="selectedMenu == 2" :personal="personal" :referencias="referencias"></referencias-component>
                <documentos-component v-if="selectedMenu == 3" :documentos="documentos" :id="personal.idPersonal"></documentos-component>
                <desarrollo-elemento-component
                    v-if="selectedMenu == 4"
                    :evaluaciones="evaluacionesMedicas"
                    :dictamenes="dictamenes"
                    :biometricos="biometricosPersonal"
                    :cursos="cursos"
                    :evaluaciones-productividad="evaluacionesPersonalProductividad"
                    :armas="armasPersonal"
                    :equipamiento="equipamientoPersonal"
                ></desarrollo-elemento-component>
                <puesto-dependencia-component v-if="selectedMenu == 5" :personal="personal"></puesto-dependencia-component>
                <area-adscripcion-component v-if="selectedMenu == 6" :personal="personal"></area-adscripcion-component>
                <!-- 
                <v-card class="card" color="primary" v-if="selectedMenu == 16">
                    <v-card-title class="font-weight-bold justify-center">Evaluaciones de respeto a los principios</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="evaluacionesPersonalRespetoPrincipios.length > 0">
                                <v-col
                                    cols="4"
                                    v-for="evaluacionRespetoPrincipios in evaluacionesPersonalRespetoPrincipios"
                                    :key="evaluacionRespetoPrincipios.id"
                                >
                                    <v-card color="primary" class="card">
                                        <v-card-title class="font-weight-bold justify-center">Evaluación respeto a los principios</v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            :value="evaluacionRespetoPrincipios.fechaAplicacion"
                                                            rounded
                                                            readonly
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Fecha de aplicación"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-subtitle-2 font-weight-bold">Preiodicidad</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-subtitle-2">
                                                            {{ evaluacionRespetoPrincipios.CatPeriodicidad.title }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-subtitle-2 font-weight-bold">Resultado</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-subtitle-2">
                                                            {{ evaluacionRespetoPrincipios.CatResultado.title }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <div class="text-subtitle-2 font-weight-bold">Calificación</div>
                                                    </v-col>
                                                    <v-col cols="6" align="right">
                                                        <div class="text-subtitle-2">
                                                            {{ evaluacionRespetoPrincipios.calificacion }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <v-btn
                                                            color="secondary"
                                                            :to="{
                                                                name: 'detalleRespetoPrincipios',
                                                                params: { evaluacionRespetoPrincipios: evaluacionRespetoPrincipios },
                                                            }"
                                                            >Detalles</v-btn
                                                        >
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col cols="12" algin="center">
                                    <div class="text--secondary text-h6">Este personal no cuenta con evaluaciones de desempeño registradas</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>                -->
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col cols="3">
                <v-row>
                    <v-col cols="12">
                        <v-skeleton-loader type="image"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader type="image"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="9">
                <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .selectable {
        cursor: pointer;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import _ from 'lodash'
    import PersonalCardComponent from '../../components/expediente/PersonalCardComponent.vue'
    import MenuListComponent from '../../components/expediente/MenuListComponent.vue'
    import DatosGeneralesComponent from '../../components/expediente/DatosGeneralesComponent.vue'
    import DocumentosComponent from '../../components/expediente/DocumentosComponent.vue'
    import DesarrolloElementoComponent from '../../components/expediente/DesarrolloElemento.vue'
    import AreaAdscripcionComponent from '../../components/expediente/AreaAdscripcionComponent.vue'
    import ReferenciasComponent from '../../components/expediente/ReferenciasComponent.vue'
    import PuestoDependenciaComponent from '../../components/expediente/PuestoDependenciaComponent'

    export default {
        components: {
            PersonalCardComponent,
            MenuListComponent,
            DatosGeneralesComponent,
            DocumentosComponent,
            DesarrolloElementoComponent,
            AreaAdscripcionComponent,
            ReferenciasComponent,
            PuestoDependenciaComponent,
        },

        async mounted() {
            await this.fetchPersonalById(this.id)
            await this.fetchEstudiosByPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            await this.fetchEvaluacionMedicaByPersonal(this.id)
            await this.fetchDictamenPersonal(this.id)
            await this.fetchArmasPersonal(this.id)
            await this.fetchBiometricosPersonal(this.id)
            await this.fetchAsignacionEquipoByPersonal(this.id)
            await this.fetchCursosAsignados()
            await this.fetchProductividad(this.id)
            await this.fetchRespetoPrincipios(this.id)
            await this.fetchReferenciasByPersonal(this.id)
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                nameRules: [v => !!v || 'Este campo es requerido.'],

                estadoNace: null,
                municipioNace: null,
                estadoRadica: null,
                municipioRadica: null,
                estadoDireccion: null,
                municipioDireccion: null,
                saveChanges: false,

                menuNacimiento: false,
                date: null,
            }
        },

        watch: {
            menuNacimiento(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalSingle',
            }),

            ...mapGetters('estudio', {
                estudios: 'getEstudios',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            ...mapGetters('biometrico', {
                biometricosPersonal: 'getBiometricosPersonal',
            }),

            ...mapGetters('asignacionEquipo', {
                equipamientoPersonal: 'getAsignacionesEquipo',
            }),

            ...mapGetters('cursoAsignado', {
                cursos: 'getCursosAsignados',
            }),

            ...mapGetters('productividad', {
                evaluacionesProductividad: 'getProductividad',
            }),

            ...mapGetters('respetoPrincipios', {
                evaluacionesRespetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('expediente', {
                selectedMenu: 'getSelectedMenu',
            }),

            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicas: 'getEvaluacionMedica',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenes: 'getDictamenPersonal',
            }),

            ...mapGetters('referencias', {
                referencias: 'getReferencias',
            }),

            menu: function() {
                return []
            },

            estudiosPersonal: function() {
                if (this.estudios) {
                    return this.estudios.filter(estudio => {
                        return estudio.fk_idPersonal == this.id
                    })
                } else return []
            },

            cursosPersonal: function() {
                if (this.cursos) {
                    return this.cursos.filter(cursoAsignado => {
                        return cursoAsignado.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalProductividad: function() {
                if (this.evaluacionesProductividad) {
                    return this.evaluacionesProductividad.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalRespetoPrincipios: function() {
                if (this.evaluacionesRespetoPrincipios) {
                    return this.evaluacionesRespetoPrincipios.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },
        },

        methods: {
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal']),
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal']),
            ...mapActions('estatusDocumento', ['updateEstatusDocumento']),
            ...mapActions('personal', ['fetchPersonalById']),
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),
            ...mapActions('biometrico', ['fetchBiometricosPersonal']),
            ...mapActions('asignacionEquipo', ['fetchAsignacionEquipoByPersonal']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignados']),
            ...mapActions('productividad', ['fetchProductividad']),
            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios']),
            ...mapActions('referencias', ['fetchReferenciasByPersonal']),

            selectMenu: function(menu) {
                this.selectedMenu = menu
            },

            triggerUpdateEstatusDocumento: async function(documento) {
                await this.updateEstatusDocumento({
                    idPersonal: documento.fk_idPersonal,
                    idDocumentoPersonal: documento.iddocumentosPersonal,
                })
                await this.fetchDocumentoByPersonal(this.id)
            },

            triggerUpdatePersonal: async function() {
                var x = this.personal

                if (this.municipioNace) {
                    this.personal.fk_idMunicipioNacimiento = this.municipioNace
                }
                if (this.municipioRadica) {
                    this.personal.fk_idMunicipioRadica = this.municipioRadica
                }
                if (this.municipioDireccion) {
                    this.personal.Contacto.Direccion.fk_idMunicipio = this.municipioDireccion
                }
                await this.updatePersonal(x)
            },

            save(date) {
                this.$refs.menu.save(date)
                this.personal.fechaNacimiento = moment(date).format('DD/MM/YYYY')
            },
        },
    }
</script>
