<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="rounded-sm" color="primary">
            <v-card-title>
                <div class="text-h6">Marcar como elemento activo</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <div class="text-subtitle-2">Este elemento comenzará a formar parte de la lista de Personal.</div>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="loading" @click="saveActivo()">Cambiar estatus a activo</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            idPersonal: {
                type: Number,
            },
        },

        data() {
            return {
                menuFecha: false,
                loading: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            fecha() {
                return this.asuntosInternos.fecha ? moment(this.asuntosInternos.fecha).format('DD/MM/YYYY') : ''
            },
        },

        methods: {
            ...mapActions('personal', ['updateEstatusPersonal', 'fetchPersonal']),
            ...mapActions('preaspirante', ['fetchAspirantes']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
            },

            async saveActivo() {
                this.loading = true
                await this.updateEstatusPersonal({
                    idPersonal: this.idPersonal,
                    fk_idEstatus: 1,
                })

                await this.fetchAspirantes()
                await this.fetchPersonal()

                this.closeDialog()
            },
        },
    }
</script>
