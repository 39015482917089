<template>
    <v-container>
        <v-col cols="12">
            <v-row>
                <v-col cols="2">
                    <v-btn text @click="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <personal-info-card-component :personal="personal"></personal-info-card-component>
                </v-col>
            </v-row>
        </v-col>
        <v-row justify="center">
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Credencialización</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-row>
                                <v-col cols="12">
                                    <v-date-picker
                                        v-model="reimpresionCredencial.fechaTramite"
                                        locale="es"
                                        color="secondary"
                                        no-title
                                        full-width
                                        :rules="required"
                                    ></v-date-picker>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-row>
                                <v-col cols="12">
                                    <v-date-picker
                                        v-model="reimpresionCredencial.fechaEntrega"
                                        locale="es"
                                        color="secondary"
                                        no-title
                                        full-width
                                        :rules="required"
                                    ></v-date-picker>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 3">
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Motivo de reposición"
                                        v-model="reimpresionCredencial.motivoReposicion"
                                        rounded
                                        filled
                                        dense
                                        no-resize
                                        color="secondary"
                                        background-color="primary"
                                        rows="4"
                                    ></v-textarea>
                                    <div class="text-subtitle-2">Historial de reimpresiones</div>
                                    <v-virtual-scroll
                                        v-if="reimpresionCredencialPersonal.length > 0"
                                        :items="reimpresionCredencialPersonal"
                                        item-height="88"
                                        height="164"
                                    >
                                        <template v-slot:default="{ item }">
                                            <v-list-item link>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.motivoReposicion }}</v-list-item-title>
                                                    <v-list-item-suibtitle>Tramitada el {{ item.fechaTramite }}</v-list-item-suibtitle>
                                                    <v-list-item-suibtitle>Entregada el {{ item.fechaEntrega }}</v-list-item-suibtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                    <div v-else class="text-subtitle-1">El elemento no cuenta con impresiones de credencial realizadas.</div>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { circularProgressProps } from '../../components/props/props'
    import moment from 'moment'
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchReimpresionCredencialByPersonal(this.id)
        },

        data() {
            return {
                circularProgressProps,
                activeStep: 1,
                loading: false,

                reimpresionCredencial: {
                    idPersonal: this.id,
                    fechaTramite: null,
                    fechaEntrega: null,
                    motivoReposicion: '',
                },

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('reimpresionCredencial', {
                reimpresionCredencialPersonal: 'getReimpresionCredencial',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de trámite'
                    case 2:
                        return 'Fecha de entrega'
                    case 3:
                        return 'Motivo de reposición'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.reimpresionCredencial.fechaTramite) {
                            return true
                        } else return false
                    case 2:
                        if (this.reimpresionCredencial.fechaEntrega) {
                            return true
                        } else return false
                    case 3:
                        if (this.reimpresionCredencial.motivoReposicion) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar'
                }
            },
        },

        methods: {
            ...mapActions('reimpresionCredencial', ['saveReimpresionCredencial', 'fetchReimpresionCredencialByPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.reimpresionCredencial.fechaTramite = moment(this.reimpresionCredencial.fechaTramite).format('DD/MM/YYYY')
                    this.reimpresionCredencial.fechaEntrega = moment(this.reimpresionCredencial.fechaEntrega).format('DD/MM/YYYY')

                    await this.saveReimpresionCredencial(this.reimpresionCredencial)

                    this.$router.push('/personal/personal')
                }
            },
        },
    }
</script>
