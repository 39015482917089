import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        recompensas: [],
    },

    getters: {
        getRecompensas: state => {
            return state.recompensas
        },
    },

    mutations: {
        SET_RECOMPENSAS(state, recompensas) {
            state.recompensas = recompensas
        },
    },

    actions: {
        fetchRecompensas: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/recompensas')
                .then(response => {
                    commit('SET_RECOMPENSAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveRecompensa: async function({ commit }, recompensa) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal' + recompensa.idPersonal + '/recompensas', recompensa)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateRecompensa: async function({ commit }, recompensa) {
            await axios
                .put('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + recompensa.idPersonal + '/recompensas/' + recompensa.id, recompensa)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
