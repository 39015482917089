<template>
    <v-container fill-height>
        <v-row justify="center">
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title> Registro de cargador </v-card-title>
                    <v-card-text>
                        <v-form v-model="cargadorForm">
                            <v-select
                                v-model="cargador.fk_idCatCalibre"
                                :items="calibres"
                                item-value="id"
                                item-text="title"
                                item-color="secondary"
                                color="secondary"
                                background-color="primary"
                                rounded
                                filled
                                dense
                                :rules="required"
                            >
                                <template v-slot:label>Calibre <span class="red--text">*</span> </template>
                            </v-select>
                            <v-text-field
                                label="Número económico"
                                v-model.number="cargador.noEconomico"
                                type="number"
                                rounded
                                filled
                                dense
                                color="secondary"
                                background-color="primary"
                                :rules="required"
                            >
                                <template v-slot:label>Número económico <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn rounded color="secondary" class="px-4" :loading="loading" :disabled="loading || !cargadorForm" @click="triggerSaveCargador()"
                            >Registrar cargador</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data() {
            return {
                loading: false,
                cargadorForm: false,

                cargador: {
                    noEconomico: null,
                    fk_idCatCalibre: null,
                },

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),
        },

        methods: {
            ...mapActions('cargadores', ['saveCargador']),

            async triggerSaveCargador() {
                this.loading = true
                await this.saveCargador(this.cargador)
                this.loading = false
                this.$router.push('/armeria/dashboard')
            },
        },
    }
</script>
