<template>
    <v-main class="primary">
        <v-container>
            <v-row>
                <v-col>
                    <v-btn text color="secondary" to="/">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon>
                        Volver al portal
                    </v-btn>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col v-bind="cardFormColsProps">
                    <v-card v-bind="cardProps">
                        <v-card-title v-bind="cardTitleProps">
                            Registro de candidato
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="4" align="center">
                                        <v-progress-circular :value="activeStep * 20" v-bind="circularProgressProps"></v-progress-circular>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                        <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                        <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                            >Volver al paso anterior</a
                                        >
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container>
                                <v-row>
                                    <transition name="fade" mode="out-in">
                                        <v-col cols="12" v-if="activeStep == 1" key="1">
                                            <v-alert type="info" color="secondary">
                                                Antes de continuar, es necesario verificar que su CURP no esté registrada en nuestro sistema.
                                            </v-alert>
                                            <v-text-field
                                                v-model="candidato.curp"
                                                rounded
                                                filled
                                                dense
                                                label="CURP *"
                                                background-color="primary"
                                                color="secondary"
                                                validate-on-blur
                                                counter="18"
                                                maxlength="18"
                                                :rules="curpRules"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" v-if="activeStep == 2" key="2">
                                            <v-form v-model="nameForm">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field v-model="candidato.nombre" v-bind="textInputProps" :rules="nameRules">
                                                            <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="candidato.segundoNombre"
                                                            rounded
                                                            filled
                                                            dense
                                                            label="Segundo nombre"
                                                            background-color="primary"
                                                            color="secondary"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="candidato.apaterno"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="nameRules"
                                                        >
                                                            <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="candidato.amaterno"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="nameRules"
                                                        >
                                                            <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-col>
                                        <v-col cols="12" v-if="activeStep == 3" key="3">
                                            <v-form v-model="personalInfoForm">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="candidato.fechaNacimiento"
                                                            v-mask="'##/##/####'"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="dateRules"
                                                            hint="Formato de fecha: DD/MM/YYYY"
                                                        >
                                                            <template v-slot:label>Fecha de nacimiento <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-select
                                                            v-model="candidato.sexo"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            label="Sexo *"
                                                            :items="sexo"
                                                            item-value="id"
                                                            item-text="title"
                                                            :rules="nameRules"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-col>
                                        <v-col cols="12" v-if="activeStep == 4" key="4">
                                            <v-form v-model="contactInfoForm">
                                                <v-row>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="candidato.Contacto.telefonoCelular"
                                                            v-mask="'###-###-##-##'"
                                                            type="tel"
                                                            rounded
                                                            filled
                                                            dense
                                                            maxlength="13"
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="phoneRules"
                                                        >
                                                            <template v-slot:label>Teléfono celular <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            v-model="candidato.Contacto.telefonoFijo"
                                                            v-mask="'###-###-##-##'"
                                                            type="tel"
                                                            rounded
                                                            filled
                                                            dense
                                                            label="Teléfono fijo"
                                                            maxlength="13"
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="candidato.Contacto.telefonoFijo.length > 0 ? phoneRules : []"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                            v-model="candidato.Contacto.correoElectronico"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="emailRules"
                                                        >
                                                            <template v-slot:label>Correo electrónico <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-col>
                                        <v-col cols="12" v-if="activeStep == 5" key="5">
                                            <v-form v-model="passwordForm">
                                                <v-text-field
                                                    v-model="candidato.password"
                                                    type="password"
                                                    rounded
                                                    filled
                                                    dense
                                                    label="Contraseña"
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="passwordRules"
                                                    validate-on-blur
                                                    :disabled="loading"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="verifyPassword"
                                                    type="password"
                                                    rounded
                                                    filled
                                                    dense
                                                    label="Reingrese su contraseña"
                                                    background-color="primary"
                                                    color="secondary"
                                                    :rules="secondPasswordRules"
                                                    validate-on-blur
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </v-form>
                                        </v-col>
                                    </transition>
                                </v-row>
                            </v-container>
                            <v-container>
                                <v-row>
                                    <v-col md="12" xl="6" offset-xl="3">
                                        <v-btn
                                            block
                                            color="secondary"
                                            v-on:click="activeStepButtonHandler"
                                            :loading="loading"
                                            :disabled="!validActiveStep || loading"
                                            >{{ activeStepButtonLabel }}</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import sha1 from 'sha1'
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../components/props/props'

    export default {
        async mounted() {
            await this.fetchSexo()
        },

        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,

                activeStep: 1,
                nameForm: false,
                personalInfoForm: false,
                contactInfoForm: false,
                passwordForm: false,
                loading: false,
                snackbar: false,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                phoneRules: [v => !!v || 'Este campo es requerido.', v => v.length == 13 || 'Ingrese su número telefónico a 10 dígitos.'],
                emailRules: [v => !!v || 'Este campo es requerido.', v => /.+@.+\..+/.test(v) || 'Ingrese un correo electrónico válido.'],
                passwordRules: [v => !!v || 'Este campo es requerido.'],
                secondPasswordRules: [
                    v => !!v || 'Por favor, reingrese su contraseña.',
                    v => v == this.candidato.password || 'La contraseña no coincide. Verifique por favor.',
                ],
                curpRules: [v => !!v || 'Este campo es requerido', v => v.length == 0 || v.length == 18 || 'Por favor, verifique este campo.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY', true)
                        return moment(date).isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],

                menu: false,

                verifyPassword: '',

                candidato: {
                    curp: '',
                    password: '',
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    fechaNacimiento: '',
                    fk_idSexo: null,
                    Contacto: {
                        telefonoCelular: '',
                        telefonoFijo: '',
                        correoElectronico: '',
                    },
                    fk_idPerfil: 16,
                },
            }
        },

        computed: {
            ...mapGetters('estadoCivil', {
                estadoCivil: 'getEstadoCivil',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('validaCurp', {
                validaCurp: 'getValidaCurp',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Verificación de CURP'
                    case 2:
                        return 'Dínos tu nombre'
                    case 3:
                        return 'Información personal'
                    case 4:
                        return 'Información de contacto'
                    case 5:
                        return 'Contraseña'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.candidato.curp.length == 18) {
                            return true
                        } else return false
                    case 2:
                        if (this.nameForm) {
                            return true
                        } else return false
                    case 3:
                        if (this.personalInfoForm) {
                            return true
                        } else return false
                    case 4:
                        if (this.contactInfoForm) {
                            return true
                        } else return false
                    case 5:
                        if (this.passwordForm) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep == 1) {
                    return 'Verificar CURP'
                } else if (this.activeStep < 5) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrarse'
                }
            },
        },

        methods: {
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('candidato', ['saveCandidato', 'fetchCandidatos']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapActions('sexo', ['fetchSexo']),
            ...mapActions('validaCurp', ['fetchValidaCurpCandidato']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.loading = true
                    await this.fetchValidaCurpCandidato(this.candidato.curp)
                    if (this.validaCurp == true) {
                        this.activeStep++
                    } else {
                        this.SET_SNACKBAR_MESSAGE('La CURP ingresada ya está registrada.')
                    }
                    this.loading = false
                } else if (this.activeStep < 5) {
                    this.activeStep++
                } else {
                    this.loading = true

                    await this.saveCandidato({
                        password: sha1(this.candidato.password).toUpperCase(),
                        nombre: this.candidato.nombre,
                        segundoNombre: this.candidato.segundoNombre,
                        apaterno: this.candidato.apaterno,
                        amaterno: this.candidato.amaterno,
                        fechaNacimiento: moment(this.candidato.fechaNacimiento).format('DD/MM/YYYY'),
                        fk_idSexo: this.candidato.fk_idSexo,
                        curp: this.candidato.curp,
                        Contacto: {
                            telefonoCelular: this.candidato.Contacto.telefonoCelular,
                            telefonoFijo: '',
                            correoElectronico: this.candidato.Contacto.correoElectronico,
                        },
                        fk_idPerfil: 16,
                    })

                    this.loading = false
                    this.$router.push('login')
                }
            },
        },
    }
</script>
