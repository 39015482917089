<template>
    <v-dialog persistent width="80%" v-model="active">
        <v-card color="primary" class="rounded-sm" v-if="convocatoria">
            <v-card-title>
                <div class="text-h6 font-weight-bold">Nueva convocatoria</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form v-model="form" ref="form">
                        <v-row>
                            <v-col cols="3">
                                <v-select
                                    label="Tipo de convocatoria"
                                    v-model="convocatoria.fk_idTipoConvocatoria"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :items="tipoConvocatoria"
                                    item-color="secondary"
                                    item-value="id"
                                    item-text="title"
                                    :rules="required"
                                >
                                    <template v-slot:label>Tipo de convocatoria <span class="red--text">*</span> </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Nombre de convocatoria"
                                    v-model="convocatoria.convocatoria"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Nombre de convocatoria <span class="red--text">*</span> </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Dirigida a"
                                    v-model="convocatoria.dirigidaA"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Dirigida a <span class="red--text">*</span> </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    label="Lugar de recepción de documentación"
                                    v-model="convocatoria.lugarRecepcionDocumentos"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-textarea
                                    v-model="convocatoria.requisitos"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Requisitos <span class="red--text">*</span> </template>
                                </v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <v-textarea
                                    v-model="convocatoria.beneficios"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Beneficios <span class="red--text">*</span> </template>
                                </v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <v-textarea
                                    v-model="convocatoria.plazasVacantes"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                    :rules="required"
                                >
                                    <template v-slot:label>Plazas vacantes <span class="red--text">*</span> </template>
                                </v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <v-textarea
                                    label="Documentación necesaria"
                                    v-model="convocatoria.documentacion"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="4">
                                <v-textarea
                                    label="Procedimiento de selección"
                                    v-model="convocatoria.procedimientoSeleccion"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    no-resize
                                    rows="4"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <div class="text-caption">Fecha de publicación de convocatoria <span class="red--text">*</span></div>
                                        <date-picker
                                            v-model="convocatoria.fechaHoraPublicacionConvocatoria"
                                            type="datetime"
                                            valueType="YYYY-MM-DD HH:mm:ss"
                                        ></date-picker>
                                    </v-col>
                                    <v-col>
                                        <div class="text-caption">Fecha de recepción de documentación <span class="red--text">*</span></div>
                                        <date-picker
                                            v-model="convocatoria.fechaHoraRecepcionDocumentos"
                                            type="datetime"
                                            valueType="YYYY-MM-DD HH:mm:ss"
                                        ></date-picker>
                                    </v-col>
                                    <v-col>
                                        <div class="text-caption">Fecha de inicio de registro <span class="red--text">*</span></div>
                                        <date-picker
                                            v-model="convocatoria.fechaHoraInicioRegistro"
                                            type="datetime"
                                            valueType="YYYY-MM-DD HH:mm:ss"
                                        ></date-picker>
                                    </v-col>
                                    <v-col>
                                        <div class="text-caption">Fecha de cierre de registro <span class="red--text">*</span></div>
                                        <date-picker
                                            v-model="convocatoria.fechaHoraCierreRegistro"
                                            type="datetime"
                                            valueType="YYYY-MM-DD HH:mm:ss"
                                        ></date-picker>
                                    </v-col>
                                    <v-col>
                                        <div class="text-caption">Fecha de resultados <span class="red--text">*</span></div>
                                        <date-picker v-model="convocatoria.fechaHoraResultados" type="datetime" valueType="YYYY-MM-DD HH:mm:ss"></date-picker>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" :loading="loading" :disabled="!validForm || loading" @click="triggerSaveConvocatoria()">Editar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'

    export default {
        components: { DatePicker },

        props: {
            active: {
                type: Boolean,
            },

            convocatoria: {
                type: Object,
            },
        },

        data() {
            return {
                form: false,
                loading: false,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('tipoConvocatoria', {
                tipoConvocatoria: 'getTipoConvocatoria',
            }),

            validForm() {
                if (
                    this.form &&
                    this.convocatoria.fechaHoraRecepcionDocumentos &&
                    this.convocatoria.fechaHoraPublicacionConvocatoria &&
                    this.convocatoria.fechaHoraInicioRegistro &&
                    this.convocatoria.fechaHoraCierreRegistro &&
                    this.convocatoria.fechaHoraResultados
                ) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapActions('convocatoria', ['updateConvocatoria', 'fetchConvocatoria']),

            closeDialog() {
                this.$refs.form.resetValidation()
                this.$emit('close')
            },

            async triggerSaveConvocatoria() {
                this.loading = true
                await this.updateConvocatoria(this.convocatoria)
                await this.fetchConvocatoria(1)

                this.closeDialog()
            },
        },
    }
</script>
