<template>
    <v-container fill-height>
        <v-row v-if="resultadosPersonal.length == 0" justify="center">
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Buscar personal</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="busquedaPersonal.nombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.segundoNombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Segundo nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.apaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido paterno"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.amaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido materno"
                        ></v-text-field>
                        <v-text-field v-model="busquedaPersonal.curp" color="secondary" background-color="primary" rounded filled label="CURP"></v-text-field>
                        <v-text-field v-model="busquedaPersonal.CUIP" color="secondary" background-color="primary" rounded filled label="CUIP"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-container class="py-0">
                            <v-row justify="center">
                                <v-col cols="8" align="center">
                                    <v-btn
                                        rounded
                                        block
                                        color="secondary"
                                        @click="triggerSearchPersonal()"
                                        :disabled="loading || emptyForm"
                                        :loading="loading"
                                        >Realizar búsqueda</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-row>
                    <v-col cols="4">
                        <v-btn color="secondary" rounded @click="RESET_BUSQUEDA_PERSONAL()">Realizar nueva búsqueda</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                        <v-text-field
                            label="Filtrar elementos"
                            v-model="filtroPersonal"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4" xl="3" v-for="personal in filteredPersonal" :key="personal.id">
                <v-card class="card" color="secondary" elevation="8">
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-avatar rounded color="primary" size="80" v-if="personal.foto.length == 0">
                                        <v-icon x-large color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                    <v-img v-else :src="personal.foto" max-height="80" max-width="80"></v-img>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <div class="text-h6 primary--text font-weight-bold">{{ personal.apaterno }}</div>
                                    <div class="text-subtitle-1 primary--text ">
                                        {{ personal.amaterno + ' ' + personal.nombre + ' ' + personal.segundoNombre }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <div class="text-caption primary--text">
                                        Evaluación C3 vigente hasta
                                    </div>
                                    <div v-if="personal.examenC3Mes.length > 0 && personal.vigenciaAnio != 0" class="text-subtitle-1 primary--text">
                                        {{ personal.examenC3Mes }} del {{ personal.vigenciaAnio }}
                                    </div>
                                    <div v-else class="text-subtitle-1 primary--text">
                                        Información no registrada
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="secondary darken-1">
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'ENLACE C3_ADMINISTRADOR' || perfil === 'ENLACE C3_CAPTURA DE RESULTADOS' || perfil === 'Enlace C3'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{ name: 'adscripcionEnlace', params: { id: personal.idPersonal } }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon>mdi-account-details</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Registro de resultados C3</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="black">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{
                                                    path: 'documentos_personal/' + personal.idPersonal,
                                                    params: { id: personal.idPersonal },
                                                }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon>mdi-file-account</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Ver expediente</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'ENLACE C3_ADMINISTRADOR' || perfil === 'ENLACE C3_CAPTURA DE RESULTADOS' || perfil === 'Enlace C3'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="primary secondary--text mx-2" elevation="8" icon v-on="on" v-bind="attrs"
                                                ><v-icon>mdi-check</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Turnar a C3</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <validacion-asuntos-internos-dialog-component
            :active="asuntosInternosDialog"
            :id-personal="selectedPersonal"
            @close="asuntosInternosDialog = false"
        ></validacion-asuntos-internos-dialog-component>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import _ from 'lodash'
    import ValidacionAsuntosInternosDialogComponent from '../../components/enlace/ValidacionAsuntosInternosDialogComponent.vue'

    export default {
        components: {
            ValidacionAsuntosInternosDialogComponent,
        },

        data() {
            return {
                loading: false,
                filtroPersonal: '',
                asuntosInternosDialog: false,
                selectedPersonal: null,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters('personal', {
                resultadosPersonal: 'getPersonal',
                busquedaPersonal: 'getBusquedaPersonal',
            }),

            ...mapGetters('tipoAmbito', {
                tipoAmbito: 'getTipoAmbito',
            }),

            ...mapGetters('dependencia', {
                dependencias: 'getDependencias',
            }),

            filteredPersonal: function() {
                return this.resultadosPersonal.filter(personal => {
                    return (
                        this.getFullName(personal)
                            .toLowerCase()
                            .indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.CUIP.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.rfc.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.curp.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0
                    )
                })
            },

            emptyForm() {
                if (
                    this.busquedaPersonal.nombre === '' &&
                    this.busquedaPersonal.segundoNombre === '' &&
                    this.busquedaPersonal.apaterno === '' &&
                    this.busquedaPersonal.amaterno === '' &&
                    this.busquedaPersonal.curp === '' &&
                    this.busquedaPersonal.CUIP === ''
                ) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            async triggerSearchPersonal() {
                this.loading = true

                await this.searchPersonal(this.busquedaPersonal)
                if (this.resultadosPersonal.length == 0) {
                    this.SET_SNACKBAR_MESSAGE('Búsqueda sin resultados.')
                }

                this.loading = false
            },

            openAsuntosInternosDialog(personal) {
                this.selectedPersonal = personal.idPersonal
                this.asuntosInternosDialog = true
            },
        },
    }
</script>
