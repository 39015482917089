<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Verificación de sanciones administrativas</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 50" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col cols="12" v-if="activeStep == 1" key="1">
                                        <v-form v-model="consejoHonorForm">
                                            <v-row align="center">
                                                <v-col cols="8">
                                                    <v-text-field
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Responsable"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-switch
                                                        v-model="asuntoInternoSwitch"
                                                        inset
                                                        :label="asuntoInternoLabel"
                                                        color="secondary"
                                                        class="mb-6"
                                                    ></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-date-picker
                                                        locale="es"
                                                        color="secondary"
                                                        :max="getToday()"
                                                        no-title
                                                        full-width
                                                        :rules="nameRules"
                                                    ></v-date-picker>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="consejoHonorForm">
                                            <v-row align="center">
                                                <v-col cols="8">
                                                    <v-text-field
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Responsable"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-switch
                                                        v-model="asuntoInternoSwitch"
                                                        inset
                                                        :label="asuntoInternoLabel"
                                                        color="secondary"
                                                        class="mb-6"
                                                    ></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-date-picker
                                                        locale="es"
                                                        color="secondary"
                                                        :max="getToday()"
                                                        no-title
                                                        full-width
                                                        :rules="nameRules"
                                                    ></v-date-picker>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../../components/props/props'
    export default {
        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,
                activeStep: 1,
                asuntosInternosForm: false,
                consejoHonorForm: false,
                asuntoInternoSwitch: false,
                loading: false,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Asuntos Internos'
                    case 2:
                        return 'Consejo de honor y justicia'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        return true
                    case 2:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 2) {
                    return 'Siguiente paso'
                } else {
                    return 'Enviar datos'
                }
            },

            asuntoInternoLabel: function() {
                if (this.asuntoInternoSwitch == true) {
                    return 'Apto'
                } else return 'No apto'
            },
        },

        methods: {
            activeStepButtonHandler: async function() {
                if (this.activeStep < 2) {
                    this.activeStep++
                } else {
                    this.loading = true
                }
            },
        },
    }
</script>

<style></style>
