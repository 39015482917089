import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        municionArma: [],
    },

    getters: {
        getMunicionArma: state => {
            return state.municionArma
        },
    },

    mutations: {
        SET_MUNICION_ARMA(state, municionArma) {
            state.municionArma = municionArma
        },
    },

    actions: {
        fetchMunicionArma: async function({ commit }, idArma) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/armas/' + idArma + '/municiones')
                .then(response => {
                    commit('SET_MUNICION_ARMA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMunicionArma: async function({ commit }, municionArma) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/armas/' + municionArma.fk_idArma + '/municiones', municionArma)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateMunicionArma: async function({ commit }, municionArma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/armas/' + municionArma.fk_idArma + '/municiones/' + municionArma.id, municionArma)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateEstatusMunicionesArma: async function({ commit }, municionArma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/armas/' + municionArma.fk_idArma + '/municiones/' + municionArma.id + '/estatus', municionArma)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateMunicionesArmaUtilizadas: async function({ commit }, municionArma) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/armas/' + municionArma.fk_idArma + '/municiones/' + municionArma.id + '/utilizadas', {
                    fk_idCatMotivoUso: municionArma.fk_idCatMotivoUso,
                })
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
