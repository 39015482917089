<template>
    <v-container v-if="selectedPersonal">
        <v-row justify="center">
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="selectedPersonal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de trámite administrativo</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 50" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col cols="12" v-if="activeStep == 1" key="1">
                                        <v-row align="center">
                                            <v-col cols="12">
                                                <v-select
                                                    v-model="faltaAdministrativa.fk_idTipoFalta"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :items="tipoFaltaAdministrativa"
                                                    item-value="id"
                                                    item-text="title"
                                                    item-color="secondary"
                                                >
                                                    <template v-slot:label>Elegir tipo de trámite <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="fechaFaltaForm">
                                            <v-row align="center">
                                                <template v-if="faltaAdministrativa.fk_idTipoFalta == 1">
                                                    <v-col cols="12">
                                                        <v-date-picker
                                                            v-model="faltaAdministrativa.fechaDesde"
                                                            locale="es"
                                                            color="secondary"
                                                            no-title
                                                            full-width
                                                            :rules="required"
                                                        ></v-date-picker>
                                                    </v-col>
                                                </template>
                                                <template v-else>
                                                    <v-col cols="12">
                                                        <v-date-picker
                                                            v-model="fechaArresto"
                                                            locale="es"
                                                            color="secondary"
                                                            no-title
                                                            full-width
                                                            :rules="required"
                                                            range
                                                        ></v-date-picker>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            :value="this.fechaInicio"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Desde <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-text-field
                                                            :value="this.fechaFin"
                                                            rounded
                                                            filled
                                                            dense
                                                            background-color="primary"
                                                            color="secondary"
                                                            :rules="required"
                                                        >
                                                            <template v-slot:label>Hasta <span class="red--text">*</span> </template>
                                                        </v-text-field>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 3" key="3">
                                        <v-form v-model="arrestoForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-autocomplete
                                                        v-model="faltaAdministrativa.fk_idPersonalEmisor"
                                                        :label="personalArrestoLabel"
                                                        placeholder="Personal que autoriza"
                                                        :items="filteredPersonal"
                                                        item-value="idPersonal"
                                                        item-text="CUIP"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        clearable
                                                    >
                                                        <template v-slot:item="{ item }">
                                                            <v-list-item-avatar color="accent" class="headline font-weight-light white--text">
                                                                <v-icon color="white">mdi-police-badge</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title class="font-weight-bold">{{
                                                                    item.apaterno + ' ' + item.amaterno + ' ' + item.nombre + ' ' + item.segundoNombre
                                                                }}</v-list-item-title>
                                                                <v-list-item-subtitle v-text="item.CUIP"></v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </template>
                                                        <template v-slot:no-data>
                                                            <v-container v-if="personal.length == 0">
                                                                <v-row>
                                                                    <v-col cols="12" align="center">
                                                                        <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                                                        <div class="text-caption font-weight-bold mt-4">
                                                                            Cargando datos. Por favor, espere...
                                                                        </div>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </template>
                                                    </v-autocomplete>
                                                    <v-textarea
                                                        no-resize
                                                        rows="3"
                                                        color="secondary"
                                                        background-color="primary"
                                                        v-model="faltaAdministrativa.motivo"
                                                        rounded
                                                        filled
                                                        dense
                                                        :rules="required"
                                                    >
                                                        <template v-slot:label>Detalles u observaciones <span class="red--text">*</span> </template>
                                                    </v-textarea>
                                                </v-col>
                                                <v-col cols="8">
                                                    <v-file-input
                                                        v-model="faltaAdministrativa.file"
                                                        label="Oficio adjunto"
                                                        rounded
                                                        filled
                                                        background-color="primary"
                                                        color="secondary"
                                                        dense
                                                    ></v-file-input>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-text-field
                                                        label="No. de oficio"
                                                        v-model="faltaAdministrativa.numeroOficio"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" align="center">
                                    <v-btn color="error" text class="px-8 mx-4" v-on:click="$router.go(-1)">Cancelar</v-btn>
                                    <v-btn
                                        class="px-8 mx-4"
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../../components/props/props'
    import moment from 'moment'
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchTipoFaltaAdministrativa()
            await this.fetchMotivoFaltaAdministrativa()
        },

        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,

                activeStep: 1,
                loading: false,
                fechaArresto: [],
                fechaFaltaForm: false,
                arrestoForm: false,
                cuipArresto: '',
                cuipArrestado: '',

                faltaAdministrativa: {
                    idPersonal: this.id,
                    fk_idTipoFalta: null,
                    fk_idCatMotivo: 1,
                    fk_idPersonalEmisor: null,
                    motivo: '',
                    fechaDesde: null,
                    fechaHasta: null,
                    adjunto: null,
                    file: null,
                    numeroOficio: '',
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
                selectedPersonal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('tipoFaltaAdministrativa', {
                tipoFaltaAdministrativa: 'getTipoFaltaAdministrativa',
            }),

            ...mapGetters('motivoFaltaAdministrativa', {
                motivoFaltaAdministrativa: 'getMotivoFaltaAdministrativa',
            }),

            filteredPersonal: function() {
                if (this.faltaAdministrativa.fk_idTipoFalta == 2) {
                    return this.personal.filter(personal => {
                        return personal.fk_idPuestoDependencia == 2 || personal.fk_idPuestoDependencia == 3
                    })
                } else return this.personal
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Tipo de trámite'
                    case 2:
                        return 'Fecha'
                    case 3:
                        return 'Información principal'
                }
            },

            personalArrestoLabel: function() {
                switch (this.faltaAdministrativa.fk_idTipoFalta) {
                    case 1:
                        return '¿Quién sanciona?'
                    case 2:
                        return '¿Quién arrestó?'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.faltaAdministrativa.fk_idTipoFalta) {
                            return true
                        } else return false
                    case 2:
                        if (this.faltaAdministrativa.fk_idTipoFalta == 1) {
                            if (this.faltaAdministrativa.fechaDesde) {
                                return true
                            } else return false
                        } else {
                            if (this.fechaFaltaForm) {
                                return true
                            } else return false
                        }

                    case 3:
                        if (this.arrestoForm) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar'
                }
            },

            fechaInicio: function() {
                if (this.fechaArresto[0]) {
                    if (moment(this.fechaArresto[1]).isBefore(this.fechaArresto[0])) {
                        return moment(this.fechaArresto[1]).format('DD/MM/YYYY')
                    } else return moment(this.fechaArresto[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.fechaArresto[1]) {
                    if (moment(this.fechaArresto[1]).isAfter(this.fechaArresto[0])) {
                        return moment(this.fechaArresto[1]).format('DD/MM/YYYY')
                    } else return moment(this.fechaArresto[0]).format('DD/MM/YYYY')
                }
            },

            isRangeDate: function() {
                if (this.faltaAdministrativa.fk_idTipoFalta == 1) {
                    return false
                } else return true
            },
        },

        methods: {
            ...mapActions('tipoFaltaAdministrativa', ['fetchTipoFaltaAdministrativa']),
            ...mapActions('motivoFaltaAdministrativa', ['fetchMotivoFaltaAdministrativa']),
            ...mapActions('faltasAdministrativas', ['saveFaltaAdministrativa']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true
                    if (this.faltaAdministrativa.fk_idTipoFalta != 1) {
                        this.faltaAdministrativa.fechaDesde = this.fechaArresto[0]
                        this.faltaAdministrativa.fechaHasta = this.fechaArresto[1]
                    }

                    this.faltaAdministrativa.fechaDesde = moment(this.faltaAdministrativa.fechaDesde).format('DD/MM/YYYY')

                    if (this.faltaAdministrativa.fechaHasta) {
                        this.faltaAdministrativa.fechaHasta = moment(this.faltaAdministrativa.fechaHasta).format('DD/MM/YYYY')
                    }

                    this.faltaAdministrativa.adjunto = this.faltaAdministrativa.file.name

                    await this.saveFaltaAdministrativa(this.faltaAdministrativa)

                    this.$router.go(-1)
                }
            },
        },
    }
</script>
