import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoRecompensa: [],
    },

    getters: {
        getTipoRecompensa: state => {
            return state.tipoRecompensa
        },
    },
    mutations: {
        SET_TIPO_RECOMPENSA(state, tipoRecompensa) {
            state.tipoRecompensa = tipoRecompensa
        },
    },
    actions: {
        fetchTipoRecompensa: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/tiporecompensa')
                .then(response => {
                    commit('SET_TIPO_RECOMPENSA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
