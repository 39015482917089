<template>
    <div id="map"></div>
</template>
<style scoped>
    #map {
        height: 99% !important;
    }
</style>
<script>
    import mapboxgl from 'mapbox-gl'

    export default {
        data() {
            return {
                accessToken: 'pk.eyJ1IjoiZ3VzdG1nIiwiYSI6ImNramtlb2liazRwbDgzM255ajJleWd2cWUifQ.IXSXU4vuLx_zyN5c8lcvYg',
                mapStyle: 'mapbox://styles/mapbox/light-v10',
            }
        },

        mounted() {
            mapboxgl.accessToken = 'pk.eyJ1IjoiZ3VzdG1nIiwiYSI6ImNramtlb2liazRwbDgzM255ajJleWd2cWUifQ.IXSXU4vuLx_zyN5c8lcvYg'
            var map = new mapboxgl.Map({
                style: 'mapbox://styles/mapbox/light-v10',
                center: [-98.2382961, 19.3162688],
                zoom: 16,
                pitch: 45,
                bearing: -17.6,
                container: 'map',
                antialias: true,
            })

            map.on('load', function() {
                // Insert the layer beneath any symbol layer.
                var layers = map.getStyle().layers

                var labelLayerId
                for (var i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                        labelLayerId = layers[i].id
                        break
                    }
                }

                map.addLayer(
                    {
                        id: '3d-buildings',
                        source: 'composite',
                        'source-layer': 'building',
                        filter: ['==', 'extrude', 'true'],
                        type: 'fill-extrusion',
                        minzoom: 15,
                        paint: {
                            'fill-extrusion-color': '#191953',

                            // use an 'interpolate' expression to add a smooth transition effect to the
                            // buildings as the user zooms in
                            'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
                            'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
                            'fill-extrusion-opacity': 0.6,
                        },
                    },
                    labelLayerId
                )

                map.addLayer({
                    id: 'sky',
                    type: 'sky',
                    paint: {
                        // set up the sky layer to use a color gradient
                        'sky-type': 'gradient',
                        // the sky will be lightest in the center and get darker moving radially outward
                        // this simulates the look of the sun just below the horizon
                        'sky-gradient': ['interpolate', ['linear'], ['sky-radial-progress'], 0.8, 'rgba(135, 206, 235, 1.0)', 1, 'rgba(0,0,0,0.1)'],
                        'sky-gradient-center': [0, 0],
                        'sky-gradient-radius': 90,
                        'sky-opacity': ['interpolate', ['exponential', 0.1], ['zoom'], 5, 0, 22, 1],
                    },
                })

                map.resize()
                map.addControl(new mapboxgl.FullscreenControl())

                var marker = new mapboxgl.Marker().setLngLat([-98.2382961, 19.3162688]).addTo(map)
                var marker2 = new mapboxgl.Marker().setLngLat([-98.2414252, 19.3194069]).addTo(map)
                var marker3 = new mapboxgl.Marker().setLngLat([-98.2361315, 19.3219428]).addTo(map)
                var marker4 = new mapboxgl.Marker().setLngLat([-98.2363271, 19.3122262]).addTo(map)
            })
        },
    }
</script>
