<template>
    <v-card class="card" color="primary">
        <v-card-title>Evaluación anual</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" align="center">
                        <div class="text-subtitle-1">{{ evaluacion.fk_idCatTipoEvaluacion == 6 ? 'Funciones Administrativas' : 'Funciones Operativas' }}</div>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 3" color="success">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 1" color="error">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                    </v-col>
                    <v-col align="center">
                        <div class="text-caption">Puntaje total</div>
                        <div class="text-body-2">{{ evaluacion.puntajeTotal }}</div>
                    </v-col>
                    <v-col align="center">
                        <div class="text-caption">Calificación</div>
                        <div class="text-body-2">{{ evaluacion.calificacion }}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-caption">Resultado</div>
                        <div class="text-body-2">{{ evaluacion.CatResultado.title }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn rounded color="secondary" @click="triggerDescargaEvaluacion()" :loading="loading" :disabled="loading">Descargar evaluacion</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { saveAs } from 'file-saver'
    import axios from 'axios'
    import { publicPath } from '../../../../../vue.config'

    const ExcelJS = require('exceljs')
    let buffer = null
    let workbook = new ExcelJS.Workbook()
    let worksheet = null
    let cell = null

    export default {
        props: {
            evaluacion: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            tipoEvaluacionAnual() {
                if (this.evaluacion) {
                    return this.evaluacion.fk_idCatTipoEvaluacion == 6 ? 'evaluacion_anual_administrativa.xlsx' : 'evaluacion_anual_operativa.xlsx'
                } else return ''
            },
        },

        methods: {
            async triggerDescargaEvaluacion() {
                this.loading = true

                await this.loadEvaluacion()
                this.loading = false
            },

            async loadEvaluacion() {
                await axios(publicPath + this.tipoEvaluacionAnual, { responseType: 'arraybuffer' })
                    .catch(function(err) {
                        /* error in getting data */
                        console.log('Error in getting data: ' + err)
                    })
                    .then(async function(res) {
                        /* parse the data when it is received */
                        return res.data
                    })
                    .catch(function(err) {
                        /* error in parsing */
                        console.log('Error in parsin: ' + err)
                    })
                    .then(async data => {
                        await workbook.xlsx.load(data)

                        worksheet = workbook.getWorksheet(1)

                        cell = worksheet.getCell('Q6')
                        cell.value = 'FECHA: ' + this.evaluacion.fecha

                        // * Información del evaluador
                        // ! No manejamos teléfono ni email de la dependencia
                        cell = worksheet.getCell('B8')
                        cell.value = this.getFullName(this.evaluacion.PersonalEvaluador)
                        cell = worksheet.getCell('B9')
                        cell.value = this.evaluacion.PersonalEvaluador.CatPuestoDependencia.catPuesto.puesto
                        cell = worksheet.getCell('B10')
                        cell.value = this.evaluacion.PersonalEvaluador.CatPuestoDependencia.catDependencia.nombre
                        cell = worksheet.getCell('D10')
                        cell.value = this.evaluacion.PersonalEvaluador.CatArea ? this.evaluacion.PersonalEvaluador.CatArea.nombre : 'No tiene área asignada'

                        // * Información del evaluado
                        cell = worksheet.getCell('J8')
                        cell.value = this.getFullName(this.evaluacion.Personal)
                        cell = worksheet.getCell('Q8')
                        cell.value = this.evaluacion.Personal.CatPuestoDependencia.catPuesto.puesto
                        cell = worksheet.getCell('P10')
                        cell.value = this.evaluacion.Personal.CatArea ? this.evaluacion.PersonalEvaluador.CatArea.nombre : 'No tiene área asignada'
                        cell = worksheet.getCell('P13')
                        cell.value = this.evaluacion.periodoEvaluacion
                        cell = worksheet.getCell('J12')
                        cell.value = this.evaluacion.Personal.CUIP
                        cell = worksheet.getCell('I14')
                        cell.value = this.evaluacion.Personal.Contacto.telefonoCelular
                        cell = worksheet.getCell('O14')
                        cell.value = this.evaluacion.Personal.Contacto.correoElectronico

                        // * Cumplimiento de sus funciones
                        let index_reactivos_cumplimiento_funciones = 23
                        this.evaluacion.cumplimientoFunciones.forEach(reactivo => {
                            cell = worksheet.getCell('Q' + index_reactivos_cumplimiento_funciones)
                            cell.value = isNaN(reactivo.calificacion) ? reactivo.calificacion : parseInt(reactivo.calificacion)

                            index_reactivos_cumplimiento_funciones += 1
                        })

                        // * Valores
                        let index_reactivos_valores = 48
                        this.evaluacion.evaluacionValores.forEach(reactivo => {
                            cell = worksheet.getCell('C' + index_reactivos_valores)
                            cell.value = isNaN(reactivo.calificacion) ? reactivo.calificacion : parseInt(reactivo.calificacion)

                            index_reactivos_valores += 1
                        })

                        // * Principios constitucionales
                        let index_reactivos_constitucionales = 48
                        this.evaluacion.evaluacionPrincipios.forEach(reactivo => {
                            cell = worksheet.getCell('K' + index_reactivos_constitucionales)
                            cell.value = isNaN(reactivo.calificacion) ? reactivo.calificacion : parseInt(reactivo.calificacion)

                            index_reactivos_constitucionales += 1
                        })

                        // * Habilidades
                        let index_reactivos_habilidades = 48
                        this.evaluacion.evaluacionHabilidades.forEach(reactivo => {
                            cell = worksheet.getCell('R' + index_reactivos_habilidades)
                            cell.value = isNaN(reactivo.calificacion) ? reactivo.calificacion : parseInt(reactivo.calificacion)

                            index_reactivos_habilidades += 1
                        })

                        cell = worksheet.getCell('C57')
                        cell.value = this.evaluacion.observaciones
                        cell = worksheet.getCell('A61')
                        cell.value = this.getFullName(this.evaluacion.PersonalEvaluador)

                        worksheet.removeConditionalFormatting()
                        buffer = await workbook.xlsx.writeBuffer()

                        this.downloadEvaluacion()
                    })
            },

            downloadEvaluacion() {
                var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, 'evaluacion_anual_operativa.xlsx')
            },
        },
    }
</script>
