<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset-md="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Línea de tiempo de elemento</v-card-title>
                    <v-card-text>
                        <v-timeline>
                            <v-timeline-item icon="mdi-account-circle" icon-color="primary" fill-dot color="secondary">
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">02 de febrero de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold">Registro de candidato</v-card-title>
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            El elemento se registró a la lista de candidatos.
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            this.fetchEvaluacionMedicaByPersonal(this.id)
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            listaFechas: function() {
                var listaFechas = []

                return listaFechas
            },
        },

        methods: {
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
        },
    }
</script>
