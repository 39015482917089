import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        categoriasCursos: [],
    },

    getters: {
        getCategories: state => {
            return state.categoriasCursos
        },
    },

    mutations: {
        SET_CATEGORIAS_CURSOS(state, categoriasCursos) {
            categoriasCursos.forEach(category => {
                state.categoriasCursos.push(category)
            })
        },
    },

    actions: {
        fetchCategoriasCursos: async function({ commit }, tipoCurso) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/tipoCursos/' + tipoCurso.id + '/categorias')
                .then(response => {
                    commit('SET_CATEGORIAS_CURSOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
