import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estudios: [],
        tipoEstudios: [],
    },

    getters: {
        getEstudios: state => {
            return state.estudios
        },

        getTipoEstudios: state => {
            return state.tipoEstudios
        },
    },

    mutations: {
        SET_ESTUDIOS(state, estudios) {
            state.estudios = estudios
        },

        SET_TIPO_ESTUDIOS(state, tipoEstudios) {
            state.tipoEstudios = tipoEstudios
        },
    },

    actions: {
        fetchTipoEstudios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/estudios')
                .then(response => {
                    commit('SET_TIPO_ESTUDIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchEstudiosByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/estudios')
                .then(response => {
                    commit('SET_ESTUDIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveEstudiosPersonal: async function(context, estudiosPersonal) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + estudiosPersonal.fk_idPersonal + '/estudios', estudiosPersonal)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Registro de estudios agregado correctamente', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstudiosPersonal: async function({ commit }, estudiosPersonal) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + estudiosPersonal.fk_idPersonal + '/estudios/' + estudiosPersonal.id, estudiosPersonal)
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        uploadEstudio: async function({ commit }, formData) {
            var instance = axios.create()
            delete instance.defaults.headers.common['Authorization']

            await instance
                .post('http://eonproduccion.net/CICG_SEG_SCM_WORK/api/Documento/uploadFiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                    console.log(response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
