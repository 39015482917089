<template>
    <v-container v-if="personal">
        <v-col cols="12">
            <v-row>
                <v-col cols="2">
                    <v-btn text @click="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <personal-info-card-component :personal="personal"></personal-info-card-component>
                </v-col>
            </v-row>
        </v-col>
        <v-row justify="center">
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de área de adscripción</v-card-title>
                    <v-card-text>
                        <v-container class="mb-4">
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 11.11" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form>
                                <v-row>
                                    <v-col cols="12" align="center">
                                        <!-- <v-text-field
                                            v-model="capturo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Capturó"
                                        ></v-text-field> -->
                                        <div class="text-subtitle-2">Fecha de captura</div>
                                        <v-date-picker v-model="fechaCaptura" locale="es" color="secondary" no-title full-width></v-date-picker>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="ncp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="CIB"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="smn"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="SMN"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 3">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="rangoCategoria"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Rango o categoría"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="categorizacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categorización"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="jerarquiaPuesto"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Jerarquía de puesto"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="categoriaPuestoCnca"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Categoría de puesto CNCA"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 4">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="text-caption">Fecha ingreso edo fuerza</div>
                                        <v-date-picker v-model="fechaIngresoEdoFza" locale="es" color="secondary" no-title full-width></v-date-picker>
                                        <v-text-field
                                            v-model="sueldoBase"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Sueldo base"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="criticidadPuestoOperativo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Criticidad de puesto operativo"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="funcional"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funcional"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="estatusSnsp"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Estatus SNSP"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="estarLn"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Debe estar en LN"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 5">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="funciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Funciones"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 6">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="unidadInvestigacion"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad de investigación"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="unidadOperaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad operaciones"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="unidadAnalisisTactico"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Unidad de análisis táctico"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 7">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="examenC3Mes"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Exámen C3 mes"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="vigenciaAnio"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Vigencia año"
                                        ></v-text-field>
                                        <v-textarea
                                            v-model="observacionesC3"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones C3"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-container>
                        <v-container v-if="activeStep == 8">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-date-picker v-model="fechaBaja" locale="es" color="secondary" no-title full-width></v-date-picker>
                                        <v-text-field
                                            v-model="motivoBaja"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Motivo de baja"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 9">
                            <v-form>
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import { circularProgressProps } from '../../components/props/props'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        data() {
            return {
                circularProgressProps,

                loading: false,
                activeStep: 1,

                required: [v => !!v || 'Este campo es requerido.'],

                capturo: '',
                fechaCaptura: null,
                ncp: '',
                smn: '',
                escolaridad: '',
                rangoCategoria: '',
                categorizacion: '',
                jerarquiaPuesto: '',
                categoriaPuestoCnca: '',
                fechaIngresoEdoFza: null,
                sueldoBase: '',
                criticidadPuestoOperativo: '',
                funcional: '',
                estatusSnsp: '',
                estarLn: '',
                vigenciaAnio: '',
                funciones: '',
                unidadInvestigacion: '',
                unidadOperaciones: '',
                unidadAnalisisTactico: '',
                examenC3Mes: '',
                observacionesC3: '',
                fechaBaja: null,
                motivoBaja: '',
                observaciones: '',
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Acerca de la captura'
                    case 2:
                        return 'Información de documentación'
                    case 3:
                        return 'Rangos y categorías'
                    case 4:
                        return 'Acerca del puesto'
                    case 5:
                        return 'Funciones'
                    case 6:
                        return 'Unidades'
                    case 7:
                        return 'Información de C3'
                    case 8:
                        return 'Información de baja'
                    case 9:
                        return 'Observaciones'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    default:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 9) {
                    return 'Siguiente paso'
                } else {
                    return 'Guardar registro'
                }
            },
        },

        methods: {
            ...mapActions('personal', ['updatePersonal', 'fetchPersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 9) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.personal.capturo = this.capturo
                    if (this.fechaCaptura) {
                        this.personal.fechaCaptura = moment(this.fechaCaptura).format('DD/MM/YYYY')
                    }
                    this.personal.ncp = this.ncp
                    this.personal.smn = this.smn
                    this.personal.escolaridad = this.escolaridad
                    this.personal.rangoCategoria = this.rangoCategoria
                    this.personal.funciones = this.funciones
                    this.personal.categorizacion = this.categorizacion
                    this.personal.sueldoBase = this.sueldoBase
                    this.personal.jerarquiaPuesto = this.jerarquiaPuesto
                    this.personal.criticidadPuestoOperativo = this.criticidadPuestoOperativo
                    this.personal.categoriaPuestoCnca = this.categoriaPuestoCnca
                    this.personal.funcional = this.funcional
                    this.personal.unidadInvestigacion = this.unidadInvestigacion
                    this.personal.unidadOperaciones = this.unidadOperaciones
                    this.personal.unidadAnalisisTactico = this.unidadAnalisisTactico
                    if (this.fechaIngresoEdoFza) {
                        this.personal.fechaIngresoEdoFza = moment(this.fechaIngresoEdoFza).format('DD/MM/YYYY')
                    }
                    this.personal.estatusSnsp = this.estatusSnsp
                    this.personal.estarLn = this.estarLn
                    if (this.fechaBaja) {
                        this.personal.fechaBaja = moment(this.fechaBaja).format('DD/MM/YYYY')
                    }
                    this.personal.motivoBaja = this.motivoBaja
                    this.personal.observaciones = this.observaciones
                    this.personal.examenC3Mes = this.examenC3Mes
                    this.personal.vigenciaAnio = this.vigenciaAnio
                    this.personal.observacionesC3 = this.observacionesC3

                    await this.updatePersonal(this.personal)
                    this.SET_SNACKBAR_MESSAGE('Los campos de registro personal han sido actualizados.')
                    this.$router.push('/personal/personal')
                }
            },
        },
    }
</script>
