<template>
    <v-dialog v-model="active" persistent width="560">
        <v-card class="primary">
            <v-card-title class="font-weight-bold">Acerca de</v-card-title>
            <v-card-text>
                <div class="text-subtitle-1 my-4" align="center">
                    Licenciamiento a favor de
                </div>
                <div class="text-h6 font-weight-bold accent--text my-4" align="center">
                    Secretaría de Seguridad Ciudadana del Estado de Tlaxcala
                </div>
                <div class="text-subtitle-1 my-4" align="center">
                    a los siguientes productos a perpetuidad:
                </div>
                <div class="text-h6 font-weight-black accent--text my-4" align="center">
                    SISTEMA ESTATAL DE SEGURIDAD INTEGRAL, EON
                </div>
                <div class="text-subtitle-1 mt-4" align="center">con el número de registro ante INDAUTOR: <b>2944/05082020</b></div>
                <div class="text-subtitle-1" align="center">referentes al contrato: <b>GET-ADX-039-2020</b></div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="secondary" block @click="closeDialog()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: {
            active: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },
        },
    }
</script>
