import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoArmas: [],
    },

    getters: {
        getTipoArmas: state => {
            return state.tipoArmas
        },
    },

    mutations: {
        SET_TIPO_ARMAS: (state, tipoArmas) => {
            state.tipoArmas = tipoArmas
        },
    },

    actions: {
        fetchTiposArma: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/tipoArma/')
                .then(response => {
                    commit('SET_TIPO_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
