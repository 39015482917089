import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        ocupaciones: [],
    },

    getters: {
        getOcupaciones: state => {
            return state.ocupaciones
        },
    },

    mutations: {
        SET_OCUPACIONES(state, ocupaciones) {
            state.ocupaciones = ocupaciones
        },
    },

    actions: {
        fetchOcupaciones: async function({ commit }) {
            await axios
                .get('/EonCloud/EstadoFuerza/ocupaciones')
                .then(response => {
                    commit('SET_OCUPACIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
