import { axios, fileAxios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {
        dictamenPersonal: null,
    },

    getters: {
        getDictamenPersonal: state => {
            return state.dictamenPersonal
        },
    },

    mutations: {
        SET_DICTAMEN_PERSONAL(state, dictamenPersonal) {
            state.dictamenPersonal = dictamenPersonal
        },
    },

    actions: {
        fetchDictamenPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/dictamen')
                .then(response => {
                    commit('SET_DICTAMEN_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveDictamenPersonal: async function(context, dictamen) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + dictamen.fk_idPersonal + '/dictamen', dictamen)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Cita para resultado en consulta previa guardada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateDictamenPersonal: async function(context, dictamen) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + dictamen.fk_idPersonal + '/dictamen/' + dictamen.idDictamen, dictamen)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Resultado en consulta previa guardado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        uploadResultadoConsultaPreviaFile: async function(context, formData) {
            await fileAxios
                .post('/RESULTADO_CONSULTA_PREVIA_PATH/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Resultado en consulta previa guardado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
