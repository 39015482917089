import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        pendientesConsultaPrevia: [],
    },

    getters: {
        getPendientesConsultaPrevia: state => {
            return state.pendientesConsultaPrevia
        },
    },

    mutations: {
        SET_PENDIENTES_CONSULTA_PREVIA(state, pendientesConsultaPrevia) {
            state.pendientesConsultaPrevia = pendientesConsultaPrevia
        },
    },

    actions: {
        fetchPendientesConsultaPrevia: async function({ commit }) {
            await axios
                .get('/EonCloud/EstadoFuerza/pendientesConsultaPrevia')
                .then(response => {
                    commit('SET_PENDIENTES_CONSULTA_PREVIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
