import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        enrolamientos: [],
    },

    getters: {
        getEnrolamientos: state => {
            return state.enrolamientos
        },
    },

    mutations: {
        SET_ENROLAMIENTOS(state, enrolamientos) {
            state.enrolamientos = enrolamientos
        },
    },

    actions: {
        fetchEnrolamientoByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/enrolamientos')
                .then(response => {
                    commit('SET_ENROLAMIENTOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveEnrolamiento: async function(context, enrolamiento) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal/' + enrolamiento.idPersonal + '/enrolamientos', {
                    fechaCaptura: enrolamiento.fechaCaptura,
                })
                .then(response => {
                    var formData = new FormData()
                    formData.append('File_frontal', enrolamiento.fotoFrontal)
                    formData.append('File_lateral1', enrolamiento.fotoLateral1)
                    formData.append('File_lateral2', enrolamiento.fotoLateral2)
                    formData.append('File_firmaElectronica', enrolamiento.firmaDigital)
                    formData.append('idEnrolamiento', response.data.data[0].ID)
                    formData.append('CUIP', enrolamiento.cuip)
                    context.dispatch('uploadEnrolamiento', formData)
                })
                .catch(error => console.log(error))
        },

        updateEnrolamiento: async function({ commit }, enrolamiento) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + enrolamiento.idPersonal + '/enrolamientos/' + enrolamiento.id, enrolamiento)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        uploadEnrolamiento: async function({ commit }, formData) {
            await fileAxios
                .post('/SetAdjuntosEnrolamiento/uploadFiles', formData)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
