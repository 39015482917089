import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoMovimiento: [],
    },

    getters: {
        getTipoMovimiento: state => {
            return state.tipoMovimiento
        },
    },

    mutations: {
        SET_TIPOS_MOVIMIENTO(state, tipoMovimiento) {
            state.tipoMovimiento = tipoMovimiento
        },
    },

    actions: {
        fetchTipoMovimiento: async function(context) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/tipoMovimientoEquipamiento')
                .then(async response => {
                    context.commit('SET_TIPOS_MOVIMIENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
