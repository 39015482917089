<template>
    <v-container v-if="activeStep == 1">
        <v-row>
            <v-col cols="12">
                <v-btn @click="$router.go(-1)" text>Volver al catálogo</v-btn>
            </v-col>
            <v-col cols="4" offset="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de evaluaciones</v-card-title>
                    <v-card-text>
                        <v-select
                            v-model="evaluacionElegida"
                            label="Fecha de evaluación agendada"
                            background-color="primary"
                            color="secondary"
                            rounded
                            filled
                            dense
                            :items="evaluacionesMedicasProgramadas"
                            item-value="idEvaluacionMedica"
                            item-text="fechaAplicacion"
                            :rules="required"
                        >
                        </v-select>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" v-on:click="activeStep++" :disabled="!evaluacionElegida">Aceptar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col cols="12">
                <v-btn @click="$router.go(-1)" text>Volver al catálogo</v-btn>
            </v-col>
            <v-col cols="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de evaluaciones</v-card-title>
                    <v-card-text>
                        <v-form v-model="dictamenForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(preaspirante)"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Aspirante"
                                            :rules="required"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            v-model="evaluacionMedica.file"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Documento adjunto de evaluación <span class="red--text">*</span> </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="evaluacionMedica.dictamen"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="4"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-list color="primary">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Acondicionamiento físico</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-switch v-model="evaluacionMedica.acondicionamientoFisico" inset color="secondary"></v-switch>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Médica - clínica</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-switch v-model="evaluacionMedica.clinica" inset color="secondary"></v-switch>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Psicológica</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-switch v-model="evaluacionMedica.psicologica" inset color="secondary"></v-switch>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn color="secondary" class="primary--text px-8" :disabled="!dictamenForm || loading" v-on:click="triggerSaveTrainer()">
                            Registrar evaluación
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            await this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
            await this.fetchEvaluacionMedicaByPersonal(this.id)
        },

        data() {
            return {
                loading: false,
                dictamenForm: false,
                menu: false,
                menu2: false,
                activeStep: 1,

                evaluacionElegida: 0,

                evaluacionMedica: {
                    fk_idPersonal: this.id,
                    acondicionamientoFisico: false,
                    medica: false,
                    clinica: false,
                    psicologica: false,
                    resultado: false,
                    fechaAplicacion: null,
                    observaciones: '',
                    nombre: null,
                    fechaRegistro: null,
                    file: null,
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicasProgramadas: 'getEvaluacionMedica',
            }),

            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),
        },

        methods: {
            ...mapActions('evaluacionMedica', ['updateEvaluacionMedica', 'fetchEvaluacionMedicaByPersonal']),
            ...mapActions('personal', ['updatePersonal', 'fetchPersonalById']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),

            triggerSaveTrainer: async function() {
                this.loading = true

                var evaluacion = this.evaluacionesMedicasProgramadas.filter(evaluacion => {
                    return evaluacion.idEvaluacionMedica == this.evaluacionElegida
                })

                evaluacion[0].acondicionamientoFisico = this.evaluacionMedica.acondicionamientoFisico
                evaluacion[0].clinica = this.evaluacionMedica.clinica
                evaluacion[0].psicologica = this.evaluacionMedica.psicologica
                if (
                    this.evaluacionMedica.acondicionamientoFisico == true &&
                    this.evaluacionMedica.clinica == true &&
                    this.evaluacionMedica.psicologica == true
                ) {
                    evaluacion[0].resultado = 1
                }
                evaluacion[0].observaciones = this.evaluacionMedica.observaciones
                evaluacion[0].nombre = this.evaluacionMedica.file.name
                evaluacion[0].file = this.evaluacionMedica.file

                await this.updateEvaluacionMedica(evaluacion[0])

                this.$router.push('/profesionalizacion/preaspirantes')
                this.loading = false
            },

            saveFechaAplicacion: function(date) {
                this.$refs.menu.save(date)
            },

            saveFechaRegistro: function(date) {
                this.$refs.menu2.save(date)
            },
        },
    }
</script>
