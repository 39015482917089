<template>
    <v-container>
        <v-row>
            <v-col cols="5">
                <v-card class="card" color="primary" height="100%">
                    <v-card-title class="font-weight-bold justify-center">Personal inscrito a cada curso</v-card-title>
                    <v-card-text style="height:80%">
                        <apexchart height="100%" type="bar" :options="personalInscritoOptions" :series="personalInscritoSeries"></apexchart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center" align="center"
                        >Candidatos con<br />
                        documentación lista</v-card-title
                    >
                    <v-card-text>
                        <v-list color="primary">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Jaime Rodríguez Cerón</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Eduardo Parra Juárez</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Manuel Torres Valencia</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Federico López Martínez</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center" align="center">Evaluaciones médicas programadas</v-card-title>
                    <v-card-text>
                        <v-date-picker locale="es" color="secondary" no-title full-width range readonly></v-date-picker>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Cursos por tipo</v-card-title>
                    <v-card-text>
                        <apexchart width="100%" type="donut" :options="cursosTipoOptions" :series="cursosTipoSeries"></apexchart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card color="primary" class="card" height="100%">
                    <v-card-title class="font-weight-bold justify-center">Últimos movimientos</v-card-title>
                    <v-card-text>
                        <v-list color="primary" class="inset-card">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-school</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 45 min</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>El curso de Capacitación continua A ha concluido.</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-book</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 2 hrs</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>El candidato Marco Arellano se ha registrado desde el portal</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-book</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 10 hrs</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>El preaspirante Juan Cuevas Galindo aprobó su evaluación médica</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar color="secondary">
                                        <v-icon color="primary">mdi-comment</v-icon>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        <div class="text-caption">Hace 1 día</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-title>El personal Armando Fernández Herrera envió una sugerencia de curso.</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text> Ver detalles</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Preaspirantes listos para turnar a C3</v-card-title>
                    <v-card-text>
                        <v-list color="primary">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Jaime Rodríguez Cerón</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Eduardo Parra Juárez</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Manuel Torres Valencia</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Federico López Martínez</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn text>Ver</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                personalInscritoOptions: {
                    chart: {
                        id: 'vuechart-example',
                    },

                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: ['Curso A', 'Curso B', 'Curso C', 'Curso D', 'Curso E', 'Curso F'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#FFAB00',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },
                personalInscritoSeries: [
                    {
                        type: 'column',
                        name: 'Total de inscritos',
                        data: [39, 74, 64, 16, 57, 60],
                    },
                ],

                cursosTipoOptions: {
                    chart: {
                        type: 'donut',
                    },
                    labels: ['Capacitación continua', 'Competencias básicas', 'Cursos de especialización'],
                    stroke: {
                        lineCap: 'round',
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#FFAB00',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                cursosTipoSeries: [60, 15],

                preaspirantesListosOptions: {
                    chart: {
                        type: 'donut',
                    },
                    labels: ['Capacitación continua', 'Competencias básicas', 'Cursos de especialización'],
                    stroke: {
                        lineCap: 'round',
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#00003c',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },

                preaspirantesListosSeries: [60, 15],
            }
        },
    }
</script>
