<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Cambio de contraseña</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <!-- <v-text-field label="Contraseña actual" v-model="personal.password" rounded filled dense></v-text-field> -->
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {}
</script>
