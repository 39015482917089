<template>
    <v-container v-if="activeStep == 1" fill-height>
        <v-row align="center" justify="center">
            <v-col cols="4">
                <v-card v-bind:class="{ card: !loading, 'card-flat': loading }" color="primary">
                    <v-card-title>
                        Ingrese CUIP
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <v-text-field
                                        v-model="busquedaPersonal.CUIP"
                                        placeholder="Buscar personal"
                                        rounded
                                        filled
                                        background-color="primary"
                                        color="secondary"
                                        :disabled="loadingBusqueda"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn
                                        rounded
                                        block
                                        large
                                        color="secondary"
                                        @click="triggerSearchPersonal()"
                                        :disabled="loadingBusqueda || loading"
                                        :loading="loadingBusqueda"
                                    >
                                        Buscar <v-icon class="ml-2">mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-virtual-scroll :items="personal" item-height="64" height="300">
                                        <template v-slot:default="{ item }">
                                            <v-list-item :key="item.idPersonal" link @click="triggerFetchPersonalWeapons(item)">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-avatar size="80">
                                                        <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                                        <v-icon size="48" v-else>mdi-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <div v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                                            <div v-else>CUIP no asignado</div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle> Estatus: {{ item.CatEstatus.title }} </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Vigencia C3:
                                                            {{ item.vigenciaEvaluacionC3 === '' ? 'No registrado' : item.vigenciaEvaluacionC3 }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else fluid>
        <v-row>
            <v-col cols="1">
                <v-btn text v-on:click="goBack()">
                    <v-icon class="pr-2">mdi-arrow-left</v-icon>
                    Buscar CUIP
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Información del personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="12" align="center">
                                    <v-img contain :src="selectedPersonal.foto" max-width="80"></v-img>
                                </v-col>
                                <v-col cols="12" align="center" class="pb-0">
                                    <div class="text-subtitle-1 text--primary">
                                        {{
                                            selectedPersonal.nombre +
                                                ' ' +
                                                selectedPersonal.segundoNombre +
                                                ' ' +
                                                selectedPersonal.apaterno +
                                                ' ' +
                                                selectedPersonal.amaterno
                                        }}
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <div class="text-subtitle-2 secondary--text">Datos generales</div>
                                </v-col>
                                <v-col cols="12" align="left" class="pb-0">
                                    <div class="text-caption mb-1">CUIP</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.CUIP }}</div>
                                </v-col>
                                <v-col cols="12" align="left" class="pb-0">
                                    <div class="text-caption mb-1">CURP</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.curp }}</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <div class="text-subtitle-2 secondary--text">Contacto</div>
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                    <div class="text-caption mb-1">Teléfono celular</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.Contacto.telefonoCelular }}</div>
                                </v-col>
                                <v-col cols="6" class="pb-0">
                                    <div class="text-caption mb-1">Teléfono fijo</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.Contacto.telefonoFijo }}</div>
                                </v-col>
                                <v-col cols="12" align="left" class="pb-0">
                                    <div class="text-caption mb-1">E-mail</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.Contacto.correoElectronico }}</div>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" class="py-0">
                                    <div class="text-subtitle-2 secondary--text pb-2">Puesto y área del elemento</div>
                                </v-col>
                                <v-col cols="12" align="center" v-if="selectedPersonal.CatPuestoDependencia">
                                    <div class="text-caption mb-1">Puesto</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.CatPuestoDependencia.catPuesto.puesto }}</div>
                                </v-col>
                                <v-col cols="12" align="center" v-if="selectedPersonal.CatArea">
                                    <div class="text-caption mb-1">Área</div>
                                    <div class="text-body-1 text--primary">{{ selectedPersonal.CatArea.title }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-row>
                    <v-col cols="12">
                        <v-card v-bind:class="{ card: !loading, 'card-flat': loading }" color="primary">
                            <v-card-title class="font-weight-bold">Lista de armas</v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-data-table
                                                class="primary"
                                                dense
                                                :headers="personalWeaponsTableHeaders"
                                                :items="armasPersonal"
                                                :search="searchPersonalWeapon"
                                                hide-default-footer
                                                calculate-widths
                                            >
                                                <template v-slot:item.cancelacion="{ item }">
                                                    <v-btn icon color="error" @click="triggerCancelaArmaPersonal(item)"><v-icon>mdi-minus</v-icon></v-btn>
                                                </template>
                                                <template v-slot:item.personalWeaponStatus="{ item }">
                                                    <v-switch
                                                        :input-value="getArmaPersonalEstatus(item)"
                                                        color="secondary"
                                                        @change="triggerSaveEntregaRecepcion(item, $event)"
                                                        inset
                                                    >
                                                    </v-switch>
                                                </template>
                                                <template v-slot:item.municiones="{ item }">
                                                    <v-btn icon color="secondary" @click="openMunicionesArmaDialog(item)"
                                                        ><v-icon>mdi-ammunition</v-icon></v-btn
                                                    >
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="downloadWeaponDialog" width="360">
            <v-card>
                <v-card-title class="justify-center">Descarga de arma</v-card-title>
                <v-card-text class="text-body-1">
                    Para ubicar el arma en armería, es necesario realizar previamente una descarga. Si la descarga de arma ya ha sido realizada, marque la
                    siguiente casilla y a continuación de click en Aceptar.
                    <v-checkbox v-model="downloadWeaponStatus" label="Se ha realizado la descarga de arma" color="secondary"></v-checkbox>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn text v-on:click="closeDownloadWeaponDialog()">Cancelar</v-btn>
                    <v-btn color="secondary" v-on:click="triggerUpdateWeaponStatus()" :disabled="!downloadWeaponStatus">Guardar arma</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <municiones-arma-dialog-component :active="municionesArmaDialog" @close="municionesArmaDialog = false"></municiones-arma-dialog-component>
    </v-container>
</template>
<style scoped>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import MapComponent from '../../components/MapComponent'
    import MunicionesArmaDialogComponent from '../../components/armeria/MunicionesArmaDialogComponent.vue'

    export default {
        components: {
            // LoaderComponent,
            MapComponent,
            MunicionesArmaDialogComponent,
        },

        beforeRouteLeave(to, from, next) {
            this.RESET_BUSQUEDA_PERSONAL()
            next()
        },

        data() {
            return {
                loading: false,
                card: 'card',
                flatCard: 'card-flat',
                weaponDailyRollCallDialog: false,
                activeStep: 1,
                searchItemValue: '',
                selectedPersonal: null,
                cuipForm: false,
                cuip: null,
                successfulCuipSearch: null,
                fetchingPersonalWeapons: false,
                required: [v => !!v || 'Este campo es requerido. '],

                personalWeaponsTableHeaders: [
                    { text: '', value: 'cancelacion', width: '5%' },
                    { text: 'Clase', value: 'Arma.CatClase.clase', width: '10%' },
                    { text: 'Tipo', value: 'Arma.CatClase.CatTipo.title', width: '10%' },
                    { text: 'Marca', value: 'Arma.CatModelo.CatMarca.title', width: '10%' },
                    { text: 'Modelo', value: 'Arma.CatModelo.modelo', width: '10%' },
                    { text: 'Calibre', value: 'Arma.CatCalibre.title', width: '10%' },
                    { text: 'No. de serie', value: 'Arma.serie', width: '10%' },
                    { text: 'Armeros', value: 'Arma.armeros', width: '20%' },
                    { text: 'En armería | En campo', value: 'personalWeaponStatus', width: '20%', filterable: false },
                    { text: '', value: 'municiones', width: '15%', filterable: false },
                ],

                searchPersonalWeapon: '',

                downloadWeaponDialog: false,
                downloadWeaponStatus: false,
                weaponToUpdate: null,

                x: null,

                municionesArmaDialog: false,

                busquedaPersonal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: '',
                },

                loadingBusqueda: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            ...mapGetters('tipoArma', {
                weaponTypes: 'getTipoArmas',
            }),

            ...mapGetters('marcaArma', {
                weaponBrands: 'getMarcaArma',
            }),

            ...mapGetters('tipoEntregaArma', {
                tipoEntrega: 'getTiposEntrega',
            }),

            ...mapGetters('bitacoraEntregaRecepcion', {
                bitacora: 'getBitacoraEntregaRecepcion',
            }),
        },

        methods: {
            ...mapActions('armaPersonal', ['fetchArmasPersonal', 'updateEstatusArmaPersonal', 'cancelaArmaPersonal']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapActions('entregaRecepcionArma', ['saveEntregaRecepcionArma']),
            ...mapActions('municionArma', ['fetchMunicionArma']),
            ...mapActions('bitacoraEntregaRecepcion', ['fetchBitacoraEntregaRecepcion']),
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            searchCuip: function() {
                this.loading = true
                this.selectedPersonal = this.personal.find(element => element.CUIP == 'GAAJ751110H29199421')
                if (this.selectedPersonal || !this.selectedPersonal === undefined) {
                    this.successfulCuipSearch = true
                } else {
                    this.successfulCuipSearch = false
                }
                console.log(this.selectedPersonal)

                this.loading = false
            },

            triggerFetchPersonalWeapons: async function(personal) {
                this.selectedPersonal = personal
                this.fetchingPersonalWeapons = true
                await this.fetchArmasPersonal(this.selectedPersonal.idPersonal)
                this.fetchingPersonalWeapons = false
                if (this.armasPersonal.length > 0) {
                    this.activeStep++
                } else {
                    this.SET_SNACKBAR_MESSAGE('El personal seleccionado no tiene armas asignadas.')
                }
            },

            getWeaponStatusLabel: function(armaPersonal) {
                var bitacora = []

                bitacora = this.bitacora.filter(entregaRecepcion => {
                    return entregaRecepcion.fk_idArmaPersonal == armaPersonal.idArmaPersonal
                })

                if (bitacora.length > 0) {
                    this.x = bitacora[bitacora.length - 1].fk_idTipoEntrega

                    if (bitacora[bitacora.length - 1].fk_idTipoEntrega == 2) {
                        return 'En campo'
                    } else return 'En armería'
                } else return 'En armería'
            },

            weaponStatusToggleHandler: function(weapon) {
                this.weaponToUpdate = weapon
                if (this.weaponToUpdate.Arma.fk_idEstatus == 1) {
                    this.downloadWeaponDialog = true
                } else {
                    this.triggerUpdateWeaponStatus()
                }
            },

            triggerUpdateWeaponStatus: async function() {
                if (this.weaponToUpdate.Arma.fk_idEstatus == 1) {
                    var receiptType = 2
                } else {
                    var receiptType = 1
                }

                await this.saveEntregaRecepcionArma({
                    idPersonal: this.weaponToUpdate.fk_idPersonal,
                    fk_idTipoEntrega: receiptType,
                    fk_idArmaPersonal: this.weaponToUpdate.idArmaPersonal,
                    fecha: moment().format('DD/MM/YYYY'),
                    armaDescargada: 1,
                })
                this.closeDownloadWeaponDialog()
            },

            closeDownloadWeaponDialog: function() {
                this.downloadWeaponDialog = false
                this.downloadWeaponStatus = false
                this.weaponToUpdate = null
            },

            getWeaponStatusToggleValue: function(status_id) {
                if (status_id == 1) {
                    return 1
                } else {
                    return 0
                }
            },

            goBack: function() {
                // this.selectedPersonal = null
                this.activeStep--
            },

            async triggerSaveEntregaRecepcion(armaPersonal, event) {
                if (event) {
                    await this.saveEntregaRecepcionArma({
                        idPersonal: armaPersonal.fk_idPersonal,
                        fk_idTipoEntrega: 2,
                        fk_idArmaPersonal: armaPersonal.idArmaPersonal,
                        armaDescargada: false,
                    })
                } else {
                    await this.saveEntregaRecepcionArma({
                        idPersonal: armaPersonal.fk_idPersonal,
                        fk_idTipoEntrega: 1,
                        fk_idArmaPersonal: armaPersonal.idArmaPersonal,
                        armaDescargada: true,
                    })
                }
                await this.fetchBitacoraEntregaRecepcion()
            },

            getArmaPersonalEstatus: function(armaPersonal) {
                var bitacora = []

                bitacora = this.bitacora.filter(entregaRecepcion => {
                    return entregaRecepcion.fk_idArmaPersonal == armaPersonal.idArmaPersonal
                })

                if (bitacora.length > 0) {
                    this.x = bitacora[bitacora.length - 1].fk_idTipoEntrega

                    if (bitacora[bitacora.length - 1].fk_idTipoEntrega == 2) {
                        return true
                    } else return false
                } else return false
            },

            async triggerCancelaArmaPersonal(armaPersonal) {
                await this.cancelaArmaPersonal(armaPersonal)
                await this.fetchArmasPersonal(this.selectedPersonal.idPersonal)
            },

            async openMunicionesArmaDialog(armaPersonal) {
                await this.fetchMunicionArma(armaPersonal.idArmaPersonal)
                this.municionesArmaDialog = true
            },

            buscaPersonal() {
                return new Promise(async () => {
                    setTimeout(await this.searchPersonal(this.busquedaPersonal), 200)
                    this.loadingBusqueda = false
                })
            },

            triggerSearchPersonal() {
                this.loadingBusqueda = true
                if (this.busquedaPersonal.CUIP !== '') {
                    this.buscaPersonal()
                } else {
                    this.loadingBusqueda = false
                    this.RESET_BUSQUEDA_PERSONAL()
                }
            },

            setPersonalArma(id_personal) {
                this.armaPersonal.fk_idPersonal = id_personal
                this.activeStepButtonHandler()
            },
        },
    }
</script>
