<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card>
            <v-card-title>Perfil de acceso</v-card-title>
            <v-card-text>
                <div class="text-body-2 mb-4">
                    El usuario ingresado cuenta con varios perfiles en el sistema. ¿Con qué perfil desea entrar?
                </div>
                <v-list-item v-for="(perfil, index) in perfiles" :key="index" link @click="enterApp(perfil)">
                    <v-list-item-content>
                        <v-list-item-title class="text-subtitle-1 font-weight-bold">{{ perfil }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },
        },

        computed: {
            ...mapGetters({
                perfiles: 'getPerfil',
                idPersonal: 'getIdPersonal',
            }),
        },

        methods: {
            ...mapMutations(['SET_PERFIL']),

            enterApp(perfil) {
                this.$emit('close')
                localStorage.setItem('perfil', perfil)
                this.SET_PERFIL(perfil)
                switch (perfil) {
                    case 'Armeria':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/armeria/dashboard')
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'Equipamiento':
                        // this.DISABLE_LOADER()
                        window.location.replace('equipamiento/personal')
                        break
                    case 'Profesionalización':
                        // this.DISABLE_LOADER()
                        // this.$router.replace('/profesionalizacion/dashboard')
                        window.location.replace('profesionalizacion/dashboard')
                        break
                    case 'Desarrollo Profesional':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/desarrollo_profesional/dashboard')
                        window.location.replace('desarrollo_profesional/personal')
                        break
                    case 'Personal/Site':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/personal/dashboard')
                        window.location.replace('personal/dashboard')
                        break
                    case 'Recursos Humanos':
                        // this.DISABLE_LOADER()
                        // this.$router.push('/recursos_humanos/dashboard')
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'Enlace C3':
                        window.location.replace('enlace_c3/dashboard')
                        break
                    case 'ADMINISTRADOR':
                        window.location.replace('launcher')
                        break
                    case 'Administrador IPH+':
                        window.location.replace('launcher')
                        break
                    case 'Asuntos Internos':
                        window.location.replace('asuntos_internos/personal')
                        break
                    case 'Consejo de Honor y Justicia':
                        window.location.replace('asuntos_internos/personal')
                        break
                    case 'Policia':
                        window.location.replace('policia/' + this.idPersonal)
                        break

                    // * Nuevos perfiles
                    case 'IFCSP_PROFESIONALIZACION':
                        window.location.replace('profesionalizacion/dashboard')
                        break
                    case 'IFCSP_RECLUTAMIENTO':
                        window.location.replace('profesionalizacion/dashboard')
                        break
                    case 'IFCSP_ADMINISTRADOR':
                        window.location.replace('profesionalizacion/dashboard')
                        break
                    case 'REP_ADMINISTRADOR':
                        window.location.replace('personal/dashboard')
                        break
                    case 'REP_REGISTRO':
                        window.location.replace('personal/dashboard')
                        break
                    case 'REP_CREDENCIALIZACION':
                        window.location.replace('personal/dashboard')
                        break
                    case 'REP_BIOMETRIA':
                        window.location.replace('personal/dashboard')
                        break
                    case 'ENLACE C3_CAPTURA DE RESULTADOS':
                        window.location.replace('enlace_c3/dashboard')
                        break
                    case 'ENLACE C3_CAPTURA DE EXPEDIENTE':
                        window.location.replace('enlace_c3/dashboard')
                        break
                    case 'ENLACE C3_ADMINISTRADOR':
                        window.location.replace('enlace_c3/dashboard')
                        break
                    case 'RH_ADMINISTRADOR':
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'RH_TRAMITES ADMINISTRATIVOS':
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'RH_CAMBIO DE ESTATUS/ADSCRIPCION':
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'RH_REGISTRO DE PERSONAL':
                        window.location.replace('recursos_humanos/dashboard')
                        break
                    case 'ARMERIA_PASE DE LISTA':
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'ARMERIA_LOC':
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'ARMERIA_PERSONAL LOC':
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'ARMERIA_CATALOGOS E INVENTARIO ARMAS':
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS':
                        window.location.replace('armeria/inventario_armas')
                        break
                    case 'EQUIPAMIENTO_ADMINISTRADOR':
                        window.location.replace('equipamiento/personal')
                        break
                    case 'DP_ADMINISTRADOR':
                        window.location.replace('desarrollo_profesional/personal')
                        break
                    case 'DP_CONTROL DE EVALUACIONES':
                        window.location.replace('desarrollo_profesional/personal')
                        break
                    case 'DP_CONDECORACIONES Y CONVOCATORIAS':
                        window.location.replace('desarrollo_profesional/personal')
                        break
                }
            },
        },
    }
</script>
