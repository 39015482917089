<template>
    <v-card class="menu-button primary-shadow">
        <v-card-text>
            <v-container>
                <v-row align="center" v-if="enrolamiento">
                    <v-col cols="4" align="center" v-if="enrolamiento.URL_Lateral1 == ''">
                        <v-avatar color="primary" size="96" rounded readonly>
                            <v-icon color="secondary" size="64">mdi-account</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align="center" v-else>
                        <v-img :src="enrolamiento.URL_Lateral1" max-width="96" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="4" align="center" v-if="personal.foto.length == 0">
                        <v-avatar color="primary" size="96" rounded readonly>
                            <v-icon color="secondary" size="64">mdi-account</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align="center" v-else>
                        <v-img :src="personal.foto" max-width="96" aspect-ratio="1"></v-img>
                    </v-col>
                    <v-col cols="4" align="center" v-if="enrolamiento.URL_Lateral2 == ''">
                        <v-avatar color="primary" size="96" rounded readonly>
                            <v-icon color="secondary" size="64">mdi-account</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align="center" v-else>
                        <v-img :src="enrolamiento.URL_Lateral2" max-width="96" aspect-ratio="1"></v-img>
                    </v-col>
                </v-row>
                <v-row align="center" v-else justify="center">
                    <v-col cols="4" v-if="personal.foto.length == 0">
                        <v-avatar color="primary" size="96" rounded readonly>
                            <v-icon color="secondary" size="64">mdi-account</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col cols="4" align="center" v-else>
                        <v-img :src="personal.foto" max-width="96" aspect-ratio="1"></v-img>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" align="center">
                        <div class="text-subtitle-1 white--text font-weight-bold">{{ personal.apaterno }}</div>
                        <div class="text-subtitle-2 white--text ">
                            {{ personal.amaterno + ' ' + personal.nombre + ' ' + personal.segundoNombre }}
                        </div>
                        <div v-if="personal.fechaNacimiento != ''" class="text-subtitle-2 white--text">{{ getAge(personal.fechaNacimiento) }}</div>
                        <div v-else class="text-subtitle-2 white--text">Fecha de nacimiento no registrada</div>
                        <v-chip class="black--text my-2 font-weight-bold" color="primary" small>
                            {{ personal.CatEstatus.title }}
                        </v-chip>
                    </v-col>
                    <v-col cols="2" align="center" v-for="(condecoracion, index) in condecoraciones" :key="index">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-img :src="condecoracion.CatCondecoracion.icono" width="48" v-on="on" v-bind="attrs"></v-img>
                            </template>
                            <span>{{ condecoracion.CatCondecoracion.condecoracion }}</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                        <v-divider class="primary darken-1"></v-divider>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-subtitle-2 primary--text">CUIP:</div>
                    </v-col>
                    <v-col cols="6" align="right">
                        <div class="text-subtitle-2 font-weight-bold primary--text">{{ personal.CUIP }}</div>
                    </v-col>
                    <v-col cols="6">
                        <div class="text-subtitle-2 primary--text">Número de credencial:</div>
                    </v-col>
                    <v-col cols="6" align="right">
                        <div class="text-subtitle-2 font-weight-bold primary--text">{{ personal.numeroCredencial }}</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            personal: {
                type: Object,
            },

            enrolamiento: {
                type: Object,
                default() {
                    return null
                },
            },
        },

        computed: {
            ...mapGetters('condecoracionPersonal', {
                condecoraciones: 'getCondecoracionPersonal',
            }),
        },
    }
</script>
