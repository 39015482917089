import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        calibres: [],
    },
    getters: {
        getCalibres: state => {
            return state.calibres
        },
    },
    mutations: {
        SET_CALIBRES: (state, calibres) => {
            state.calibres = calibres
        },
    },
    actions: {
        fetchCalibres: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/calibres/')
                .then(response => {
                    commit('SET_CALIBRES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
