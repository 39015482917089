import moment from 'moment'
import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        disciplinaAdministrativa: [],
    },

    getters: {
        getDisciplinaAdministrativa: state => {
            return state.disciplinaAdministrativa
        },
    },

    mutations: {
        SET_DISCIPLINA_ADMINISTRATIVA(state, disciplinaAdministrativa) {
            state.disciplinaAdministrativa = disciplinaAdministrativa
        },
    },

    actions: {
        fetchDisciplinaAdministrativa: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/disciplinaAdministrativa')
                .then(response => {
                    commit('SET_DISCIPLINA_ADMINISTRATIVA', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveDisciplinaAdministrativa: async function({ commit }, disciplinaAdministrativa) {
            await axios
                .post(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + disciplinaAdministrativa.fk_idPersonal + '/disciplinaAdministrativa',
                    disciplinaAdministrativa
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateDisciplinaAdministrativa: async function(context, disciplinaAdministrativa) {
            disciplinaAdministrativa.estimulos.forEach(estimulo => {
                estimulo.fecha = moment(estimulo.fecha).format('DD/MM/YYYY')
            })

            disciplinaAdministrativa.reconocimientos.forEach(reconocimiento => {
                reconocimiento.fecha = moment(reconocimiento.fecha).format('DD/MM/YYYY')
            })

            disciplinaAdministrativa.actasAdministrativas.forEach(acta => {
                acta.fecha = moment(acta.fecha).format('DD/MM/YYYY')
            })

            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        disciplinaAdministrativa.fk_idPersonal +
                        '/disciplinaAdministrativa/' +
                        disciplinaAdministrativa.id,
                    disciplinaAdministrativa
                )
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Evaluación guardada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatus: async function(context, disciplinaAdministrativa) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        disciplinaAdministrativa.fk_idPersonal +
                        '/disciplinaAdministrativa/' +
                        disciplinaAdministrativa.id +
                        '/estatus',
                    { fk_idCatEstatusEvaluacion: 3 }
                )
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
