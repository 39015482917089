<template>
    <v-dialog v-model="resetPasswordDialog" width="480" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title>Cambiar contraseña</v-list-item-title>
            </v-list-item>
        </template>
        <v-card tile color="primary">
            <v-card-title class="font-weight-bold justify-center">Cambio de contraseña</v-card-title>
            <v-card-text>
                <v-form v-model="passwordForm" ref="form">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Contraseña anterior"
                                    v-model="oldPassword"
                                    rounded
                                    filled
                                    background-color="primary"
                                    color="secondary"
                                    :rules="required"
                                    :type="visibleOldPassword"
                                    validate-on-blur
                                >
                                    <template v-slot:append>
                                        <v-btn icon @mousedown="seeOldPassword = true" @mouseup="seeOldPassword = false"><v-icon>mdi-eye</v-icon></v-btn>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                    label="Contraseña anterior"
                                    v-model="newPassword"
                                    rounded
                                    filled
                                    background-color="primary"
                                    color="secondary"
                                    :rules="required"
                                    :type="visibleNewPassword"
                                    validate-on-blur
                                >
                                    <template v-slot:append>
                                        <v-btn icon @mousedown="seeNewPassword = true" @mouseup="seeNewPassword = false"><v-icon>mdi-eye</v-icon></v-btn>
                                    </template></v-text-field
                                >
                                <v-text-field
                                    label="Contraseña anterior"
                                    v-model="verifyPassword"
                                    rounded
                                    filled
                                    background-color="primary"
                                    color="secondary"
                                    :rules="verifyPasswordRules"
                                    :type="visibleVerifyPassword"
                                    validate-on-blur
                                >
                                    <template v-slot:append>
                                        <v-btn icon @mousedown="seeVerifyPassword = true" @mouseup="seeVerifyPassword = false"
                                            ><v-icon>mdi-eye</v-icon></v-btn
                                        >
                                    </template></v-text-field
                                >
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn class="px-8" text color="error" v-on:click="closeDialog()">Cancelar</v-btn>
                <v-btn class="px-8" color="secondary" :disabled="!passwordForm || loading" :loading="loading" v-on:click="triggerUpdatePassword"
                    >Cambiar contraseña</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import sha1 from 'sha1'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                resetPasswordDialog: false,
                passwordForm: false,
                loading: false,

                seeOldPassword: false,
                seeNewPassword: false,
                seeVerifyPassword: false,

                oldPassword: '',
                newPassword: '',
                verifyPassword: '',

                required: [v => !!v || 'Este campo es requerido.'],
                verifyPasswordRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => v == this.newPassword || 'Por favor verifique que este campo coincida con su nueva contraseña ingresada.',
                ],
            }
        },

        computed: {
            ...mapGetters({
                idPersonal: 'getIdPersonal',
                id: 'getId',
            }),

            visibleOldPassword: function() {
                if (this.seeOldPassword) {
                    return 'text'
                } else return 'password'
            },

            visibleNewPassword: function() {
                if (this.seeNewPassword) {
                    return 'text'
                } else return 'password'
            },

            visibleVerifyPassword: function() {
                if (this.seeVerifyPassword) {
                    return 'text'
                } else return 'password'
            },
        },

        methods: {
            ...mapActions(['passwordReset']),

            closeDialog: function() {
                this.oldPassword = ''
                this.newPassword = ''
                this.verifyPassword = ''
                this.$refs.form.resetValidation()
                this.resetPasswordDialog = false
            },

            triggerUpdatePassword: async function() {
                this.loading = true
                await this.passwordReset({
                    userId: parseInt(this.id),
                    oldPassword: sha1(this.oldPassword).toUpperCase(),
                    newPassword: sha1(this.newPassword).toUpperCase(),
                    withTwoFactor: false,
                })
                this.loading = false

                this.closeDialog()
            },
        },
    }
</script>
