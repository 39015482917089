<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Asignación de cargador</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">
                                        PASO {{ activeStep }}
                                        <div v-if="activeStep == 3" class="d-inline">(Opcional)</div>
                                    </div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <v-form v-model="cuipForm">
                                            <v-text-field
                                                background-color="primary"
                                                color="secondary"
                                                v-model="cuip"
                                                :rules="cuipRules"
                                                validate-on-blur
                                                rounded
                                                filled
                                                dense
                                                v-on:input="searchCuip()"
                                                autocomplete="off"
                                            >
                                                <template v-slot:label>Buscar por CUIP <span class="red--text">*</span> </template>
                                            </v-text-field>
                                        </v-form>
                                        <v-expand-transition>
                                            <v-row v-if="successfulCuipSearch">
                                                <v-col cols="12">
                                                    <v-card flat color="secondary" rounded>
                                                        <v-container>
                                                            <v-row align="center">
                                                                <v-col cols="4" align="center">
                                                                    <v-avatar size="48" color="primary" rounded>
                                                                        <v-icon color="secondary">mdi-account</v-icon>
                                                                    </v-avatar>
                                                                </v-col>
                                                                <v-col cols="8">
                                                                    <div class="text-subtitle-1 primary--text">
                                                                        {{
                                                                            selectedPersonal.nombre +
                                                                                ' ' +
                                                                                selectedPersonal.segundoNombre +
                                                                                ' ' +
                                                                                selectedPersonal.amaterno +
                                                                                ' ' +
                                                                                selectedPersonal.apaterno
                                                                        }}
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else-if="!successfulCuipSearch && cuip != ''">
                                                <v-col cols="12" align="center">
                                                    <div class="text-subtitle-2">Búsqueda sin resultados.</div>
                                                </v-col>
                                            </v-row>
                                        </v-expand-transition>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-date-picker
                                            v-model="ammunitionAssignmentDate"
                                            locale="es"
                                            color="secondary"
                                            :max="getMaxDay"
                                            no-title
                                            full-width
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="12" class="pb-0">
                                                <v-select
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    :items="personalWeapons"
                                                    item-value="id"
                                                    item-text="title"
                                                    v-model="selectedWeapon"
                                                    v-on:change="selectedammunition = null"
                                                >
                                                    <template v-slot:label>Seleccione un arma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="8" class="py-0">
                                                <v-text-field
                                                    label="Calibre del arma"
                                                    background-color="primary"
                                                    color="secondary"
                                                    :value="weaponCaliber"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="4" class="py-0">
                                                <v-text-field
                                                    background-color="primary"
                                                    color="secondary"
                                                    type="number"
                                                    v-model.number="ammunitionAmount"
                                                    rounded
                                                    filled
                                                    dense
                                                    v-on:input="searchCuip()"
                                                    autocomplete="off"
                                                    :disabled="!selectedWeapon"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                activeStep: 1,
                loading: false,
                cuipForm: false,
                cuip: '',
                successfulCuipSearch: null,
                selectedPersonal: null,
                ammunitionAssignmentDate: '',
                selectedWeapon: null,
                ammunitionAmount: 0,
                personalWeapons: [
                    { id: 1, title: 'Arma larga de prueba' },
                    { id: 2, title: 'Arma corta de prueba' },
                ],

                cuipRules: [v => !!v || 'Por favor, ingrese el CUIP.', v => this.existsCuip() || 'El CUIP ingresado no ha sido encontrado.'],
                required: [v => v > 0 || 'Ingrese un número mayor a cero. '],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('calibre', {
                calibers: 'getCalibres',
            }),

            // ...mapGetters('armaPersonal', {
            //     personalWeapons: 'getArmasPersonal',
            // }),

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Elegir personal'
                    case 2:
                        return 'Fecha de asignación'
                    case 3:
                        return 'Selección del arma'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        return true
                    case 2:
                        return true
                    case 3:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Asignar municiones'
                }
            },

            weaponCaliber: function() {
                if (this.selectedWeapon) {
                    var weapon = this.personalWeapons.find(weapon => weapon.fk_idArma == this.selectedWeapon)
                    return weapon.Arma.CatCalibre.title
                } else {
                    return ''
                }
            },
        },

        methods: {
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),

            ...mapActions('municionPersonal', ['saveAmmunitionAssignment']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.fetchArmasPersonal(this.selectedPersonal.idPersonal)
                    this.activeStep++
                } else if (this.activeStep > 1 && this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    var weapon = this.personalWeapons.find(weapon => weapon.fk_idArma == this.selectedWeapon)

                    await this.saveAmmunitionAssignment({
                        idArma: this.selectedWeapon,
                        fk_idCalibre: weapon.Arma.fk_idCalibre,
                        cantidad: this.ammunitionAmount,
                        fechaAsignado: moment(this.ammunitionAssignmentDate).format('DD/MM/YYYY'),
                    })

                    this.$router.push('/armeria/dashboard')
                }
            },

            searchCuip: async function() {
                this.loading = true
                this.selectedPersonal = this.personal.find(element => element.idPersonal == this.cuip)
                if (this.selectedPersonal || !this.selectedPersonal === undefined) {
                    this.successfulCuipSearch = true
                } else {
                    this.successfulCuipSearch = false
                }

                this.loading = false
            },

            existsCuip: function() {
                this.selectedPersonal = this.personal.find(element => element.idPersonal == this.cuip)
                if (this.selectedPersonal || !this.selectedPersonal === undefined) {
                    return true
                } else return false
            },
        },
    }
</script>
