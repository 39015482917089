<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="text-h5">Evaluación de desempeño anual</div>
                <v-stepper vertical non-linear v-model="activeStep">
                    <v-stepper-step step="1">Información general</v-stepper-step>
                    <v-stepper-content step="1">
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-card class="rounded-sm" elevation="4">
                                        <v-card-title>
                                            <div class="text-subtitle-1 font-weight-bold">
                                                Información general
                                            </div>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="3">
                                                        <v-select
                                                            :items="filteredTipoEvaluacion"
                                                            item-text="title"
                                                            item-value="id"
                                                            item-color="secondary"
                                                            v-model="evaluacionAnual.fk_idCatTipoEvaluacion"
                                                            rounded
                                                            filled
                                                            dense
                                                            color="secondary"
                                                            background-color="primary"
                                                        >
                                                            <template v-slot:label>Tipo de evaluación <span class="red--text">*</span> </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-form v-model="informacionGeneralForm">
                                                    <v-row>
                                                        <v-col>
                                                            <v-autocomplete
                                                                v-model="evaluacionAnual.fk_idPersonalEvaluador"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="evaluadores"
                                                                item-value="idPersonal"
                                                                item-text="CUIP"
                                                                item-color="secondary"
                                                                :rules="required"
                                                                hint="Presione la tecla Enter para realizar la búsqueda"
                                                                persistent-hint
                                                                @keyup.enter="fetchEvaluadores($event.target.value)"
                                                            >
                                                                <template v-slot:label>Personal evaluador <span class="red--text">*</span> </template>
                                                                <template v-slot:item="data">
                                                                    <v-list-item-avatar>
                                                                        <v-img v-if="data.item.foto !== ''" :src="data.item.foto"></v-img>
                                                                        <v-icon v-else>mdi-account</v-icon>
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ data.item.CUIP }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ getFullName(data.item) }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col>
                                                            <v-autocomplete
                                                                v-model="evaluacionAnual.fk_idSupervisor"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :items="supervisores"
                                                                item-value="idPersonal"
                                                                item-text="CUIP"
                                                                item-color="secondary"
                                                                :rules="required"
                                                                hint="Presione la tecla Enter para realizar la búsqueda"
                                                                persistent-hint
                                                                @keyup.enter="fetchSupervisores($event.target.value)"
                                                            >
                                                                <template v-slot:label>Supervisor <span class="red--text">*</span> </template>
                                                                <template v-slot:item="data">
                                                                    <v-list-item-avatar>
                                                                        <v-img v-if="data.item.foto !== ''" :src="data.item.foto"></v-img>
                                                                        <v-icon v-else>mdi-account</v-icon>
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>{{ data.item.CUIP }}</v-list-item-title>
                                                                        <v-list-item-subtitle>{{ getFullName(data.item) }}</v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                v-model="evaluacionAnual.periodoEvaluacion"
                                                                rounded
                                                                filled
                                                                dense
                                                                color="secondary"
                                                                background-color="primary"
                                                                :rules="required"
                                                            >
                                                                <template v-slot:label>Período de evaluación <span class="red--text">*</span> </template>
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-menu
                                                                v-model="menuFecha"
                                                                :close-on-content-click="false"
                                                                :nudge-right="40"
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                        :value="fecha"
                                                                        label="Fecha"
                                                                        append-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        rounded
                                                                        filled
                                                                        dense
                                                                        color="secondary"
                                                                        background-color="primary"
                                                                        :rules="required"
                                                                    >
                                                                        <template v-slot:label>Fecha <span class="red--text">*</span> </template>
                                                                    </v-text-field>
                                                                </template>
                                                                <v-date-picker v-model="evaluacionAnual.fecha" @input="menuFecha = false"></v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" align="center">
                                                            <v-btn
                                                                color="secondary"
                                                                @click="generaEvaluaciones()"
                                                                :disabled="loading || !informacionGeneralForm"
                                                                >Guardar información</v-btn
                                                            >
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-step step="2">Llenado de evaluaciones</v-stepper-step>
                    <v-stepper-content step="2">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-card color="secondary" v-if="evaluacionesAnuales.length > 0">
                                        <v-card-title>Evaluación anual</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ evaluacionesAnuales[evaluacionesAnuales.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <evaluacion-anual-dialog-component
                                                :active="evaluacionAnualDialog"
                                                :evaluacion-anual="evaluacionesAnuales[evaluacionesAnuales.length - 1]"
                                            ></evaluacion-anual-dialog-component>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <!-- <v-col>
                                    <v-card color="secondary" v-if="disciplinaAdministrativa.length > 0">
                                        <v-card-title>Disciplina administrativa</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ disciplinaAdministrativa[disciplinaAdministrativa.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <evaluacion-disciplina-administrativa-dialog-component
                                                :id-personal="id"
                                                :disciplina-administrativa="disciplinaAdministrativa[disciplinaAdministrativa.length - 1]"
                                            ></evaluacion-disciplina-administrativa-dialog-component>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col>
                                    <v-card color="secondary" v-if="disciplinaOperativa.length > 0">
                                        <v-card-title>Disciplina operativa</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ disciplinaOperativa[disciplinaOperativa.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <evaluacion-disciplina-operativa-dialog-component
                                                :id-personal="id"
                                            ></evaluacion-disciplina-operativa-dialog-component>
                                        </v-card-actions>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .v-data-table {
        overflow-x: auto !important;
    }
</style>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import EvaluacionDisciplinaAdministrativaDialogComponent from '../../components/desarrollo_policial/EvaluacionDisciplinaAdministrativaDialogComponent.vue'
    import EvaluacionDisciplinaOperativaDialogComponent from '../../components/desarrollo_policial/EvaluacionDisciplinaOperativaDialogComponent.vue'
    // import EvaluacionProductividadDialogComponent from '../../components/desarrollo_policial/EvaluacionProductividadDialogComponent.vue'
    // import EvaluacionRespetoPrincipiosDialogComponent from '../../components/desarrollo_policial/EvaluacionRespetoPrincipiosDialogComponent.vue'
    // import EvaluacionRespetoPrincipiosValoresDialogComponent from '../../components/desarrollo_policial/EvaluacionRespetoPrincipiosValoresDialogComponent.vue'
    // import EvaluacionDesempenioInstitucionalDialogComponent from '../../components/desarrollo_policial/EvaluacionDesempenioInstitucionalDialogComponent.vue'
    // import EvaluacionRendimientoProfesionalismoDialogComponent from '../../components/desarrollo_policial/EvaluacionRendimientoProfesionalismoDialogComponent.vue'
    import EvaluacionAnualDialogComponent from '../../components/desarrollo_policial/EvaluacionAnualDialogComponent.vue'

    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        components: {
            EvaluacionDisciplinaAdministrativaDialogComponent,
            EvaluacionDisciplinaOperativaDialogComponent,
            // EvaluacionRespetoPrincipiosDialogComponent,
            // EvaluacionProductividadDialogComponent,
            // EvaluacionRespetoPrincipiosValoresDialogComponent,
            // EvaluacionDesempenioInstitucionalDialogComponent,
            // EvaluacionRendimientoProfesionalismoDialogComponent,
            EvaluacionAnualDialogComponent,
        },

        async mounted() {
            await this.fetchTipoEvaluacion()
            await this.fetchHabilidades()
            await this.fetchPrincipiosConstitucionales()
            await this.fetchValoresCapacidades()
            await this.fetchResultados()

            this.fetchIncidencia()
            this.SET_PERSONAL_FOTO_CUIP(this.id)

            await this.fetchDisciplinaAdministrativa(this.id)
            await this.fetchDisciplinaOperativa(this.id)
        },

        data() {
            return {
                selectedTipoEvaluacion: null,

                activeStep: 1,
                loading: false,
                informacionGeneralForm: false,
                tipoEvaluacionAnual: null,

                menuFecha: false,

                evaluacionAnual: {
                    fk_idPersonalEvaluador: null,
                    periodoEvaluacion: '',
                    fecha: null,
                    promedio: '',
                    observaciones: '',
                    puntajeTotal: '',
                    calificacion: '',
                    fk_idResultado: 1,
                    fk_idSupervisor: null,
                    fk_idCatTipoEvaluacion: 0,
                    promedioValores: '',
                    promedioPrincipiosConstitucionales: '',
                    promedioHabilidades: '',
                    fk_idCatEstatusEvaluacion: 1,
                    cumplimientoFunciones: [],
                    evaluacionHabilidades: [],
                    evaluacionPrincipios: [],
                    evaluacionValores: [],
                },

                required: [v => !!v || 'Este campo es requerido'],

                evaluacionAnualDialog: false,
            }
        },

        computed: {
            ...mapGetters('tipoEvaluacion', {
                tipoEvaluacion: 'getTipoEvaluacion',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
                listaPersonal: 'getPersonal',
                evaluadores: 'getEvaluadores',
                supervisores: 'getSupervisores',
            }),

            ...mapGetters('disciplinaAdministrativa', {
                disciplinaAdministrativa: 'getDisciplinaAdministrativa',
            }),

            ...mapGetters('disciplinaOperativa', {
                disciplinaOperativa: 'getDisciplinaOperativa',
            }),

            ...mapGetters('evaluacionAnual', {
                evaluacionesAnuales: 'getEvaluacionAnual',
            }),

            filteredTipoEvaluacion() {
                if (this.tipoEvaluacion) {
                    return this.tipoEvaluacion.filter(tipo => {
                        return tipo.id == 6 || tipo.id == 7
                    })
                }
            },

            fecha: function() {
                return this.evaluacionAnual.fecha ? moment(this.evaluacionAnual.fecha).format('DD/MM/YYYY') : ''
            },
        },

        methods: {
            ...mapActions('estatusProcedimiento', ['fetchEstatusProcedimiento']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),
            ...mapActions('procedimiento', ['fetchProcedimiento']),
            ...mapActions('reactivoPrincipio', ['fetchReactivoPrincipio']),
            ...mapActions('reactivoValor', ['fetchReactivoValor']),
            ...mapActions('tipoEvaluacion', ['fetchTipoEvaluacion']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),
            ...mapActions('reactivosEvaluacion', ['fetchReactivosEvaluacion']),
            ...mapActions('actosRelevantesEvaluacion', ['fetchActosRelevantesEvaluacion']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapActions('disciplinaAdministrativa', ['fetchDisciplinaAdministrativa', 'saveDisciplinaAdministrativa']),
            ...mapActions('disciplinaOperativa', ['fetchDisciplinaOperativa', 'saveDisciplinaOperativa']),

            ...mapActions('incidencia', ['fetchIncidencia']),

            ...mapActions('evaluacionAnual', ['saveEvaluacionAnual', 'fetchEvaluacionAnual', 'fetchEvaluacionAnualByTipo', 'fetchEvaluacionAnualById']),

            ...mapActions('personal', ['fetchEvaluadores', 'fetchSupervisores']),
            ...mapActions('habilidades', ['fetchHabilidades']),
            ...mapActions('principiosConstitucionales', ['fetchPrincipiosConstitucionales']),
            ...mapActions('valoresCapacidades', ['fetchValoresCapacidades']),
            ...mapActions('descripcionCriterio', ['fetchDescripcionCriterio']),
            ...mapActions('resultado', ['fetchResultados']),

            async generaEvaluaciones() {
                this.loading = true

                await this.saveEvaluacionAnual({
                    fk_idPersonal: this.id,
                    fk_idPersonalEvaluador: this.evaluacionAnual.fk_idPersonalEvaluador,
                    periodoEvaluacion: this.evaluacionAnual.periodoEvaluacion,
                    fecha: this.fecha,
                    promedio: '',
                    observaciones: '',
                    puntajeTotal: '',
                    calificacion: '',
                    fk_idResultado: 0,
                    fk_idSupervisor: this.evaluacionAnual.fk_idSupervisor,
                    fk_idCatTipoEvaluacion: this.evaluacionAnual.fk_idCatTipoEvaluacion,
                    promedioValores: '',
                    promedioPrincipiosConstitucionales: '',
                    promedioHabilidades: '',
                    fk_idCatEstatusEvaluacion: 1,
                    cumplimientoFunciones: [],
                    evaluacionHabilidades: [],
                    evaluacionPrincipios: [],
                    evaluacionValores: [],
                })

                // await this.saveDisciplinaOperativa({
                //     fk_idPersonal: this.id,
                //     fk_idPersonalEvaluador: this.evaluacionAnual.fk_idPersonalEvaluador,
                //     fk_idPeriodicidad: this.evaluacionAnual.fk_idPeriodicidad,
                //     instanciaInforma: '',
                //     fechaInicial: this.fecha,
                //     fechaFin: moment(this.fecha).add(90, 'days'),
                //     fechaAplicacion: moment(this.informacionGeneral.fechaAplicacion).format('DD/MM/YYYY'),
                //     procedimientos: [],
                //     fk_idCatEstatusEvaluacion: 1,
                // })

                // await this.saveDisciplinaAdministrativa({
                //     fk_idPersonal: this.id,
                //     fk_idPersonalEvaluador: this.informacionGeneral.fk_idPersonalEvaluador,
                //     fk_idPeriodicidad: this.informacionGeneral.fk_idPeriodicidad,
                //     instanciaInforma: 'RECURSOS HUMANOS',
                //     fechaInicial: this.fechaInicio,
                //     fechaFin: this.fechaFin,
                //     fechaAplicacion: this.fechaAplicacion,
                //     fk_idCatEstatusEvaluacion: 1,
                //     incidencias: [
                //         {
                //             cantidad: 0,
                //             fk_idCatIncidencia: 1,
                //         },
                //         {
                //             cantidad: 0,
                //             fk_idCatIncidencia: 2,
                //         },
                //         {
                //             cantidad: 0,
                //             fk_idCatIncidencia: 3,
                //         },
                //         {
                //             cantidad: 0,
                //             fk_idCatIncidencia: 4,
                //         },
                //         {
                //             cantidad: 0,
                //             fk_idCatIncidencia: 5,
                //         },
                //     ],
                //     reconocimientos: [],
                //     estimulos: [],
                //     actasAdministrativas: [],
                // })

                // await this.fetchDisciplinaAdministrativa(this.id)
                // await this.fetchDisciplinaOperativa(this.id)

                this.activeStep++

                this.loading = false
                this.$router.go(-1)
            },

            async openEvaluacionAnual(evaluacionAnual) {
                await this.fetchEvaluacionAnualById(evaluacionAnual)
                await this.fetchDescripcionCriterio(this.evaluacionAnual.fk_idCatTipoEvaluacion)
                this.descripcionCriterio.forEach(descripcion => {
                    this.evaluacionAnual.cumplimientoFunciones.push({
                        calificacion: '',
                        fk_idDescripcionCriterio: descripcion.id,
                        estatus: true,
                    })
                })
                this.valoresCapacidades.forEach(valor => {
                    this.evaluacionAnual.evaluacionValores.push({
                        calificacion: '',
                        fk_idCatValoresCapacidad: valor.id,
                        estatus: true,
                    })
                })
                this.principiosConstitucionales.forEach(principio => {
                    this.evaluacionAnual.evaluacionPrincipios.push({
                        calificacion: '',
                        fk_idCatPrincipiosConstitucionales: principio.id,
                        estatus: true,
                    })
                })
                this.principiosConstitucionales.forEach(habilidad => {
                    this.evaluacionAnual.evaluacionHabilidades.push({
                        calificacion: '',
                        fk_idCatHabilidades: habilidad.id,
                        estatus: true,
                    })
                })
                this.evaluacionAnualDialog = true
            },
        },
    }
</script>
