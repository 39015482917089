<template>
    <v-container>
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Asignación de funciones</v-card-title>
                    <v-card-text>
                        <v-textarea
                            no-resize
                            rows="4"
                            color="secondary"
                            background-color="primary"
                            label="Funciones"
                            v-model="funciones"
                            rounded
                            filled
                            dense
                        ></v-textarea>
                        <v-autocomplete
                            solo
                            filled
                            rounded
                            multiple
                            color="secondary"
                            background-color="primary"
                            label="Lista de funciones"
                            :items="listaFunciones"
                            item-value="id"
                            item-text="title"
                            item-color="secondary"
                        ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn color="secondary" class="primary--text mb-4" :loading="loading">Asignar funciones</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                loading: false,
                funciones: '',
                listaFunciones: [
                    { id: 1, title: 'Función A' },
                    { id: 2, title: 'Función B' },
                    { id: 3, title: 'Función C' },
                    { id: 4, title: 'Función D' },
                ],
            }
        },
    }
</script>
