import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        rasgos: [],
    },

    getters: {
        getRasgos: state => {
            return state.rasgos
        },
    },

    mutations: {
        SET_RASGOS(state, rasgos) {
            state.rasgos = rasgos
        },
    },

    actions: {
        fetchRasgos: async function({ commit }) {
            await axios
                .get('/EonCloud/rasgos')
                .then(response => {
                    commit('SET_RASGOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
