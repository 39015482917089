import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        areas: [],
    },

    getters: {
        getAreas: state => {
            return state.areas
        },
    },

    mutations: {
        SET_AREAS(state, areas) {
            state.areas = areas
        },
    },

    actions: {
        fetchAreas: async function({ commit }) {
            await axios
                .get('/EonCloud/common/areas')
                .then(response => {
                    commit('SET_AREAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
