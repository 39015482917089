import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        principios: [],
    },

    getters: {
        getPrincipios: state => {
            return state.principios
        },
    },

    mutations: {
        SET_PRINCIPIOS(state, principios) {
            state.principios = principios
        },
    },

    actions: {
        fetchPrincipios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/principios')
                .then(response => {
                    commit('SET_PRINCIPIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
