<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col v-bind="cardFormColsProps">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">
                        Registro de personal
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 10" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col cols="12" v-if="activeStep == 1" key="1">
                                        <v-alert type="info" color="secondary">
                                            Antes de continuar, es necesario verificar que su CURP no esté registrada en nuestro sistema.
                                        </v-alert>
                                        <v-text-field
                                            :value="personal.curp"
                                            @input="personal.curp = $event.toUpperCase()"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            validate-on-blur
                                            counter="18"
                                            maxlength="18"
                                            :rules="curpRules"
                                        >
                                            <template v-slot:label>CURP <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="dependenciaForm">
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="selectedDependencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="dependencias"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        :rules="nameRules"
                                                        @change="triggerFetchPuestos($event)"
                                                    >
                                                        <template v-slot:label>Dependencia <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="personal.fk_idRamaDependencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="filteredRamas"
                                                        item-value="id"
                                                        item-text="ramaDependencia"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Dirección <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="personal.fk_idCatArea"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="filteredAreas"
                                                        item-value="id"
                                                        item-text="nombre"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Adscripción <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="12">
                                                    <div class="text-subtitle-2">Tipo de puesto <span class="red--text">*</span></div>
                                                    <v-radio-group v-model="personal.fk_idCatTipoFuncion">
                                                        <v-radio
                                                            v-for="tipo in tipoPuesto"
                                                            :key="tipo.id"
                                                            :label="tipo.title"
                                                            :value="tipo.id"
                                                            color="secondary"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="personal.fk_idPuestoDependencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="puestos"
                                                        item-value="id"
                                                        item-text="catPuesto.puesto"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Puesto <span class="red--text">*</span> </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 3" key="3">
                                        <v-form v-model="nameForm">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        :value="personal.nombre"
                                                        @input="personal.nombre = $event.toUpperCase()"
                                                        v-bind="textInputProps"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        :value="personal.segundoNombre"
                                                        @input="personal.segundoNombre = $event.toUpperCase()"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Segundo nombre"
                                                        background-color="primary"
                                                        color="secondary"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="personal.apaterno"
                                                        @input="personal.apaterno = $event.toUpperCase()"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="personal.amaterno"
                                                        @input="personal.amaterno = $event.toUpperCase()"
                                                        rounded
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        :rules="nameRules"
                                                    >
                                                        <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="personal.Contacto.correoElectronico"
                                                        rounded
                                                        filled
                                                        dense
                                                        label="Correo electrónico"
                                                        background-color="primary"
                                                        color="secondary"
                                                    >
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../../components/props/props'

    export default {
        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,

                activeStep: 1,
                nameForm: false,
                personalInfoForm: false,
                contactInfoForm: false,
                nacionalidadForm: false,
                documentacionOficialForm: false,
                ubicacionForm: false,
                direccionForm: false,
                estudiosForm: false,
                dependenciaForm: false,
                puestoForm: false,
                loading: false,
                snackbar: false,

                estadoNace: null,
                estadoRadica: null,
                estadoVive: null,

                selectedDependencia: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                phoneRules: [v => !!v || 'Este campo es requerido.', v => v.length == 10 || 'Ingrese su número telefónico a 10 dígitos.'],
                smnRules: [v => !!v || 'Este campo es requerido.'],
                emailRules: [v => !!v || 'Este campo es requerido.', v => /.+@.+\..+/.test(v) || 'Ingrese un correo electrónico válido.'],
                curpRules: [v => !!v || 'Este campo es requerido', v => v.length == 0 || v.length == 18 || 'Por favor, verifique este campo.'],
                nssRules: [v => v.length == 0 || v.length == 11 || 'Por favor, complete los 11 caracteres.'],
                licenciaRules: [v => v.length == 0 || v.length == 9 || 'Por favor, complete los 9 caracteres.'],
                vigenciaRules: [v => v.length == 0 || v.length == 4 || 'Por favor, ingrese el año a 4 digitos.'],
                yearRules: [v => /^[0-9]*$/.test(v) || 'Por favor, sólo ingrese números.', v => v.length == 4 || 'Por favor, ingrese el año a 4 dígitos.'],

                menu: false,

                personal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    fechaNacimiento: '',
                    nacionalidad: '',
                    curp: '',
                    nss: '',
                    nsmi: '',
                    pasaporte: '',
                    rfc: '',
                    claveIne: '',
                    licenciaConducir: '',
                    vigenciaLicencia: null,
                    foto: '',
                    fk_idTipoNacionalidad: 0,
                    fk_idEstadoCivil: 0,
                    fk_idEstatus: 1,
                    fk_idMunicipioNacimiento: 0,
                    fk_idMunicipioRadica: 0,
                    fk_idPuestoDependencia: 0,
                    fk_idRamaDependencia: 0,
                    fk_idSubsede: 0,
                    fk_idCatTipoFuncion: 0,
                    fk_idCatArea: 0,
                    fk_idCatGrupoSanguineo: 0,
                    Contacto: {
                        telefonoCelular: '',
                        telefonoFijo: '',
                        correoElectronico: '',
                        Direccion: {
                            fk_idMunicipio: 0,
                            calle: '',
                            colonia: '',
                            ext: '',
                            int: '',
                            entrecalles: '',
                            entrecalles2: '',
                            referencias: '',
                            lat: '',
                            lgt: '',
                            cp: '',
                        },
                    },
                    cartillaMilitar: '',
                    aptoSNPSP: true,
                    capturo: '',
                    fechaCaptura: '',
                    ncp: '',
                    smn: '',
                    escolaridad: '',
                    rangoCategoria: '',
                    funciones: '',
                    categorizacion: '',
                    sueldoBase: '',
                    jerarquiaPuesto: '',
                    criticidadPuestoOperativo: '',
                    categoriaPuestoCnca: '',
                    funcional: '',
                    unidadInvestigacion: '',
                    unidadOperaciones: '',
                    unidadAnalisisTactico: '',
                    fechaIngresoEdoFza: '',
                    estatusSnsp: '',
                    estarLn: '',
                    fechaBaja: '',
                    motivoBaja: '',
                    observaciones: '',
                    examenC3Mes: '',
                    vigenciaAnio: 0,
                    observacionesC3: '',
                },

                estudio: {
                    institucion: '',
                    generacionInicio: '',
                    generacionFin: '',
                    generacion: '',
                    fk_idCatEstatusEstudio: null,
                    observaciones: '',
                    fk_idCatEstudio: null,
                    fk_idComprobanteEstudio: null,
                    fk_idPersonal: null,
                },

                estudios: [],
            }
        },

        computed: {
            ...mapGetters('dependencia', {
                dependencias: 'getDependencias',
            }),

            ...mapGetters('estadoCivil', {
                estadoCivil: 'getEstadoCivil',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('comprobanteEstudios', {
                comprobanteEstudios: 'getComprobantesEstudios',
            }),

            ...mapGetters('estatusEstudios', {
                estatusEstudios: 'getEstatusEstudios',
            }),

            ...mapGetters('estudio', {
                tipoEstudios: 'getTipoEstudios',
            }),

            ...mapGetters('tipoDocumento', {
                tipoDocumento: 'getTipoDocumento',
            }),

            ...mapGetters('estatusEstudios', {
                estatusEstudios: 'getEstatusEstudios',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('validaCurp', {
                validaCurp: 'getValidaCurp',
            }),

            ...mapGetters('areas', {
                areas: 'getAreas',
            }),

            ...mapGetters('rama', {
                ramas: 'getRamas',
            }),

            ...mapGetters('puesto', {
                puestos: 'getPuestos',
            }),

            ...mapGetters('tipoPuesto', {
                tipoPuesto: 'getTipoPuesto',
            }),

            ...mapGetters('tipoFuncion', {
                tipoFuncion: 'getTipoFuncion',
            }),

            ...mapGetters('grupoSanguineo', {
                grupoSanguineo: 'getGrupoSanguineo',
            }),

            ...mapGetters('personal', {
                savedIdPersonal: 'getSavedIdPersonal',
            }),

            filteredRamas: function() {
                return this.ramas.filter(rama => {
                    return rama.fk_idDependencia == this.selectedDependencia
                })
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Verificación de CURP'
                    case 2:
                        return 'Dirección, adscripción y puesto'
                    case 3:
                        return 'Información personal'
                    case 4:
                        return 'Información de contacto'
                    case 5:
                        return 'Nacionalidad'
                    case 6:
                        return 'Documentación oficial'
                    case 7:
                        return 'Ubicación'
                    case 8:
                        return 'Dirección'
                    case 9:
                        return 'Estudios'
                    case 10:
                        return 'Lista de estudios'
                    case 11:
                        return 'Dependencia'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.personal.curp.length == 18) {
                            return true
                        } else return false
                    case 2:
                        if (this.dependenciaForm) {
                            return true
                        } else return false
                    case 3:
                        if (this.nameForm) {
                            return true
                        } else return false
                    case 4:
                        return true
                    case 5:
                        return true
                    case 6:
                        return true
                    case 7:
                        return true
                    case 8:
                        return true
                    case 9:
                        return true
                    case 11:
                        return true
                    case 12:
                        return true
                    default:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep == 1) {
                    return 'Verificar CURP'
                } else if (this.activeStep != 1 && this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar personal'
                }
            },

            filteredMunicipiosEstadoNacimiento: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoNace
                })
            },

            filteredMunicipiosEstadoRadica: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoRadica
                })
            },

            filteredMunicipiosEstadoVive: function() {
                return this.municipios.filter(municipio => {
                    return municipio.fk_idEstado == this.estadoVive
                })
            },

            filteredAreas: function() {
                return this.areas.filter(area => {
                    return area.fk_idCatRamaDependencia == this.personal.fk_idRamaDependencia
                })
            },
        },

        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },

        methods: {
            ...mapActions('personal', ['savePersonal', 'fetchPersonal', 'updatePuestoPersonal', 'updateSubsedePersonal', 'updateAreaPersonal']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('estudio', ['saveEstudiosPersonal', 'fetchTipoEstudios']),
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('tipoDocumento', ['fetchTipoDocumento']),
            ...mapActions('documento', ['saveDocumentoPersonal', 'fetchDocumentoByPersonal']),
            ...mapActions('estatusEstudios', ['fetchEstatusEstudios']),
            ...mapActions('puesto', ['fetchPuestosByDependencia']),

            ...mapActions('validaCurp', ['fetchValidaCurp']),
            ...mapActions('sexo', ['fetchSexo']),

            ...mapMutations(['SET_SNACKBAR_MESSAGE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.loading = true
                    await this.fetchValidaCurp(this.personal.curp)
                    if (this.validaCurp == true) {
                        this.activeStep++
                    } else {
                        this.SET_SNACKBAR_MESSAGE('La CURP ingresada ya está registrada.')
                    }
                    this.loading = false
                } else if (this.activeStep != 1 && this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    await this.savePersonal({
                        data: this.personal,
                        estudios: [],
                    })

                    if (this.personal.fk_idPuestoDependencia != 0) {
                        await this.updatePuestoPersonal({
                            idPersonal: this.savedIdPersonal,
                            fk_idPuestoDependencia: this.personal.fk_idPuestoDependencia,
                        })
                    }
                    if (this.personal.fk_idCatArea != 0) {
                        await this.updateAreaPersonal({
                            idPersonal: this.savedIdPersonal,
                            fk_idCatArea: this.personal.fk_idCatArea,
                        })
                    }

                    this.$router.replace('/personal/personal')

                    this.loading = false
                }
            },

            saveBirthdayDate: function(date) {
                this.$refs.menu.save(date)
            },

            getNombreTipoEstudios: function(id) {
                var tipoEstudio = this.tipoEstudios.filter(tipoEstudio => {
                    return tipoEstudio.id == id
                })

                return tipoEstudio[0].title
            },

            removeEstudio: function(index) {
                this.estudios.splice(index, 1)

                if (this.estudios.length == 0) {
                    this.activeStep = 9
                }
            },

            async triggerFetchPuestos(event) {
                this.personal.fk_idPuestoDependencia = 0
                await this.fetchPuestosByDependencia(event)
            },
        },
    }
</script>
