import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        modelosArmas: [],
    },
    getters: {
        getModelosArmas: state => {
            return state.modelosArmas
        },
    },
    mutations: {
        SET_MODELOS_ARMAS: (state, modelosArmas) => {
            state.modelosArmas = modelosArmas
        },
    },
    actions: {
        fetchModeloArmas: async function({ commit }, idMarca) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/marcas/' + idMarca + '/modelos')
                .then(response => {
                    commit('SET_MODELOS_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
