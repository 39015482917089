<template>
    <v-container v-if="personal" fluid>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="8" align="right">
                <v-btn v-on:click="download(personal.foto)" rounded color="secondary" class="mx-2" v-if="personal.foto.length > 0"
                    >Descargar fotografía</v-btn
                >
                <v-btn v-on:click="triggerLogout()" rounded color="secondary" class="mx-2">Cerrar sesión</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-row>
                    <v-col cols="12">
                        <personal-card-component :personal="personal"></personal-card-component>
                    </v-col>
                    <v-col cols="12">
                        <menu-list-component :loading="loadingPersonalData" :estatus="personal.fk_idEstatus"></menu-list-component>
                    </v-col>
                </v-row>
            </v-col>
            <template v-if="loadingPersonalData == false">
                <v-col>
                    <datos-generales-component v-if="selectedMenu == 1" :personal="personal" :estudios="estudiosPersonal"></datos-generales-component>
                    <referencias-component
                        v-if="selectedMenu == 2"
                        :personal="personal"
                        :referencias="referencias"
                        :familiares="familiares"
                    ></referencias-component>
                    <documentos-component v-if="selectedMenu == 3" :documentos="documentos" :id="personal.idPersonal"></documentos-component>
                    <desarrollo-elemento-component
                        v-if="selectedMenu == 4"
                        :evaluaciones="evaluacionesMedicas"
                        :dictamenes="dictamenes"
                        :biometricos="biometricosPersonal"
                        :cursos="cursos"
                        :evaluaciones-productividad="evaluacionesPersonalProductividad"
                        :armas="armasPersonal"
                        :equipamiento="equipamientoPersonal"
                        :estatus="personal.fk_idEstatus"
                    ></desarrollo-elemento-component>
                    <puesto-dependencia-component v-if="selectedMenu == 5" :personal="personal"></puesto-dependencia-component>
                    <area-adscripcion-component v-if="selectedMenu == 6" :personal="personal"></area-adscripcion-component>
                    <faltas-administrativas-component v-if="selectedMenu == 8" :faltas="faltasAdministrativas"></faltas-administrativas-component>
                    <linea-tiempo-component v-if="selectedMenu == 7" :permutaciones="permutaciones"></linea-tiempo-component>
                    <media-filiacion-component
                        v-if="selectedMenu == 9"
                        :media-filiacion="personal.mediafiliacion"
                        :personal="personal"
                        :senias-particulares="personal.seniasParticulares"
                    ></media-filiacion-component>
                    <evaluaciones-component v-if="selectedMenu == 10" :personal="personal" :evaluaciones="evaluaciones"></evaluaciones-component>
                </v-col>
            </template>
            <template v-else>
                <v-col>
                    <v-skeleton-loader type="image" height="100%"></v-skeleton-loader>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<style scoped>
    .selectable {
        cursor: pointer;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'
    import _ from 'lodash'
    import PersonalCardComponent from '../../components/expediente/PersonalCardComponent.vue'
    import MenuListComponent from '../../components/expediente/MenuListComponent.vue'
    import DatosGeneralesComponent from '../../components/expediente/DatosGeneralesComponent.vue'
    import DocumentosComponent from '../../components/expediente/DocumentosComponent.vue'
    import DesarrolloElementoComponent from '../../components/expediente/DesarrolloElemento.vue'
    import AreaAdscripcionComponent from '../../components/expediente/AreaAdscripcionComponent.vue'
    import ReferenciasComponent from '../../components/expediente/ReferenciasComponent.vue'
    import PuestoDependenciaComponent from '../../components/expediente/PuestoDependenciaComponent'
    import FaltasAdministrativasComponent from '../../components/expediente/FaltasAdministrativasComponent.vue'
    import FamiliaresComponent from '../../components/expediente/FamiliaresComponent.vue'
    import LineaTiempoComponent from '../../components/expediente/LineaTiempoComponent.vue'
    import MediaFiliacionComponent from '../../components/expediente/MediaFiliacionComponent.vue'
    import EvaluacionesComponent from '../../components/expediente/EvaluacionesComponent.vue'

    export default {
        components: {
            PersonalCardComponent,
            MenuListComponent,
            DatosGeneralesComponent,
            DocumentosComponent,
            DesarrolloElementoComponent,
            AreaAdscripcionComponent,
            ReferenciasComponent,
            PuestoDependenciaComponent,
            FaltasAdministrativasComponent,
            FamiliaresComponent,
            LineaTiempoComponent,
            MediaFiliacionComponent,
            EvaluacionesComponent,
        },

        async mounted() {
            await this.SET_SELECTED_MENU(1)
            this.loadingPersonalData = true
            await this.fetchPersonalById(this.id)
            await this.fetchEstudiosByPersonal(this.id)
            await this.fetchDocumentoByPersonal(this.id)
            await this.fetchEvaluacionMedicaByPersonal(this.id)
            await this.fetchDictamenPersonal(this.id)
            await this.fetchArmasPersonal(this.id)
            await this.fetchBiometricosPersonal(this.id)
            await this.fetchAsignacionEquipoByPersonal(this.id)
            await this.fetchCursosAsignadosByPersonal(this.id)
            await this.fetchProductividad(this.id)
            await this.fetchRespetoPrincipios(this.id)
            await this.fetchReferenciasByPersonal(this.id)
            await this.fetchFaltasAdministrativasByPersonal(this.id)
            await this.fetchFamiliaresByPersonal(this.id)
            await this.fetchPermutacionesByPersonal(this.id)
            await this.fetchEvaluacionesById(this.id)
            await this.fetchReactivoPrincipio()
            await this.fetchFrecuenciaRespetoPrincipio()
            await this.fetchFrecuenciaProductividad()
            await this.fetchCondecoracionPersonal(this.id)

            await this.fetchTipoEvaluacion()
            await this.fetchHabilidades()
            await this.fetchPrincipiosConstitucionales()
            await this.fetchValoresCapacidades()
            await this.fetchResultados()
            await this.fetchEstatusEvaluacion()
            await this.fetchReactivoValor()
            await this.fetchReactivosRendimientoProfesionalismo()
            await this.fetchReactivosDesempenioInstitucional()

            await this.fetchMunicipios()
            await this.fetchEstadoCivil()
            await this.fetchTipoNacionalidad()
            await this.fetchSexo()
            await this.fetchGrupoSanguineo()
            this.loadingPersonalData = false
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                nameRules: [v => !!v || 'Este campo es requerido.'],
                loadingPersonalData: false,

                estadoNace: null,
                municipioNace: null,
                estadoRadica: null,
                municipioRadica: null,
                estadoDireccion: null,
                municipioDireccion: null,
                saveChanges: false,

                menuNacimiento: false,
                date: null,
            }
        },

        watch: {
            menuNacimiento(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalSingle',
            }),

            ...mapGetters('estudio', {
                estudios: 'getEstudios',
            }),

            ...mapGetters('documento', {
                documentos: 'getDocumentos',
            }),

            ...mapGetters('tipoNacionalidad', {
                tipoNacionalidad: 'getTipoNacionalidad',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('estado', {
                estados: 'getEstados',
            }),

            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            ...mapGetters('biometrico', {
                biometricosPersonal: 'getBiometricosPersonal',
            }),

            ...mapGetters('asignacionEquipo', {
                equipamientoPersonal: 'getAsignacionesEquipo',
            }),

            ...mapGetters('cursoAsignado', {
                cursos: 'getCursosAsignadosPersonal',
            }),

            ...mapGetters('productividad', {
                evaluacionesProductividad: 'getProductividad',
            }),

            ...mapGetters('respetoPrincipios', {
                evaluacionesRespetoPrincipios: 'getRespetoPrincipios',
            }),

            ...mapGetters('expediente', {
                selectedMenu: 'getSelectedMenu',
            }),

            ...mapGetters('evaluacionMedica', {
                evaluacionesMedicas: 'getEvaluacionMedica',
            }),

            ...mapGetters('dictamenPersonal', {
                dictamenes: 'getDictamenPersonal',
            }),

            ...mapGetters('referencias', {
                referencias: 'getReferencias',
            }),

            ...mapGetters('permutacion', {
                permutaciones: 'getPermutaciones',
            }),

            ...mapGetters('faltasAdministrativas', {
                faltasAdministrativas: 'getFaltasAdministrativas',
            }),

            ...mapGetters('familiares', {
                familiares: 'getFamiliares',
            }),

            ...mapGetters('evaluacionesPersonal', {
                evaluaciones: 'getEvaluacionesPersonal',
            }),

            menu: function() {
                return []
            },

            estudiosPersonal: function() {
                if (this.estudios) {
                    return this.estudios.filter(estudio => {
                        return estudio.fk_idPersonal == this.id
                    })
                } else return []
            },

            cursosPersonal: function() {
                if (this.cursos) {
                    return this.cursos.filter(cursoAsignado => {
                        return cursoAsignado.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalProductividad: function() {
                if (this.evaluacionesProductividad) {
                    return this.evaluacionesProductividad.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },

            evaluacionesPersonalRespetoPrincipios: function() {
                if (this.evaluacionesRespetoPrincipios) {
                    return this.evaluacionesRespetoPrincipios.filter(evaluacion => {
                        return evaluacion.fk_idPersonal == this.id
                    })
                } else return []
            },
        },

        methods: {
            ...mapActions('comprobanteEstudios', ['fetchComprobantesEstudios']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal']),
            ...mapActions('evaluacionMedica', ['fetchEvaluacionMedicaByPersonal']),
            ...mapActions('dictamenPersonal', ['fetchDictamenPersonal']),
            ...mapActions('estatusDocumento', ['updateEstatusDocumento']),
            ...mapActions('personal', ['fetchPersonalById']),
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),
            ...mapActions('biometrico', ['fetchBiometricosPersonal']),
            ...mapActions('asignacionEquipo', ['fetchAsignacionEquipoByPersonal']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignadosByPersonal']),
            ...mapActions('productividad', ['fetchProductividad']),
            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipios']),
            ...mapActions('referencias', ['fetchReferenciasByPersonal']),
            ...mapActions('faltasAdministrativas', ['fetchFaltasAdministrativasByPersonal']),
            ...mapActions('familiares', ['fetchFamiliaresByPersonal']),
            ...mapActions('permutacion', ['fetchPermutacionesByPersonal']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),
            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipiosById']),
            ...mapActions('productividad', ['fetchProductividadById']),
            ...mapActions('reactivoPrincipio', ['fetchReactivoPrincipio']),
            ...mapActions('frecuenciaRespetoPrincipio', ['fetchFrecuenciaRespetoPrincipio']),
            ...mapActions('indicadorEvaluacion', ['fetchIndicadorEvaluacion']),
            ...mapActions('actoRelevante', ['fetchActosRelevantes']),
            ...mapActions('tipoEvaluacion', ['fetchTipoEvaluacion']),
            ...mapActions('frecuenciaProductividad', ['fetchFrecuenciaProductividad']),
            ...mapActions('evaluacionAnual', ['fetchEvaluacionAnualById']),
            ...mapActions('habilidades', ['fetchHabilidades']),
            ...mapActions('principiosConstitucionales', ['fetchPrincipiosConstitucionales']),
            ...mapActions('valoresCapacidades', ['fetchValoresCapacidades']),
            ...mapActions('descripcionCriterio', ['fetchDescripcionCriterio']),
            ...mapActions('resultado', ['fetchResultados']),
            ...mapActions('estatusEvaluacion', ['fetchEstatusEvaluacion']),
            ...mapActions('reactivoValor', ['fetchReactivoValor']),
            ...mapActions('reactivosEvaluacion', ['fetchReactivosDesempenioInstitucional', 'fetchReactivosRendimientoProfesionalismo']),
            ...mapActions('respetoPrincipiosValores', ['saveRespetoPrincipiosValores', 'fetchRespetoPrincipiosValores']),
            ...mapActions('rendimientoProfesionalismo', ['saveRendimientoProfesionalismo', 'fetchRendimientoProfesionalismo']),
            ...mapActions('desempeñoInstitucional', ['saveDesempeñoInstitucional', 'fetchDesempeñoInstitucional']),
            ...mapActions('condecoracionPersonal', ['fetchCondecoracionPersonal']),
            ...mapMutations('expediente', ['SET_SELECTED_MENU']),

            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('sexo', ['fetchSexo']),

            selectMenu: function(menu) {
                this.selectedMenu = menu
            },

            triggerUpdateEstatusDocumento: async function(documento) {
                await this.updateEstatusDocumento({
                    idPersonal: documento.fk_idPersonal,
                    idDocumentoPersonal: documento.iddocumentosPersonal,
                })
                await this.fetchDocumentoByPersonal(this.id)
            },

            download: function(url) {
                window.open(url, '_blank')
            },

            downloadQr: function(cuip) {
                window.open('http://ssctlaxcala.eonproduccion.net/qr/?cuip=' + cuip, '_blank')
            },

            triggerUpdatePersonal: async function() {
                var x = this.personal

                if (this.municipioNace) {
                    this.personal.fk_idMunicipioNacimiento = this.municipioNace
                }
                if (this.municipioRadica) {
                    this.personal.fk_idMunicipioRadica = this.municipioRadica
                }
                if (this.municipioDireccion) {
                    this.personal.Contacto.Direccion.fk_idMunicipio = this.municipioDireccion
                }
                await this.updatePersonal(x)
            },

            save(date) {
                this.$refs.menu.save(date)
                this.personal.fechaNacimiento = moment(date).format('DD/MM/YYYY')
            },

            triggerLogout: function() {
                this.logout()
                window.location.reload()
                // this.$router.replace('/')
            },
        },
    }
</script>
