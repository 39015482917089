import Axios from 'axios'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Authorization: localStorage.getItem('token'),
    },
})

const fileAxios = Axios.create({
    baseURL: process.env.VUE_APP_FILE_API_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})

export { axios, fileAxios }
