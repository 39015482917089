import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        marcaArmas: [],
    },

    getters: {
        getMarcaArma: state => {
            return state.marcaArmas
        },
    },

    mutations: {
        SET_MARCA_ARMAS: (state, marcaArmas) => {
            state.marcaArmas = marcaArmas
        },
    },

    actions: {
        fetchMarcaArma: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/marcas/')
                .then(response => {
                    commit('SET_MARCA_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
