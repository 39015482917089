<template>
    <v-card class="card" color="primary">
        <v-card-title>Disciplina operativa</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" align="center">
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 3" color="success">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                        <v-chip v-if="evaluacion.fk_idCatEstatusEvaluacion == 1" color="error">Estatus: {{ evaluacion.CatEstatusEvaluacion.title }}</v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="text-caption">Instancia que informa</div>
                        <div class="text-body-2 black--text">{{ evaluacion.instanciaInforma }}</div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="text-caption">Procedimientos</div>
                        <v-row v-for="(procedimiento, index) in evaluacion.procedimientos" :key="index">
                            <v-col cols="12">
                                <div class="text-caption black--text">{{ procedimiento.CatProcedimiento.title }}</div>
                                <div class="text-body-2 black--text">Número: {{ procedimiento.numero }}</div>
                                <div class="text-body-2 black--text">Motivo: {{ procedimiento.motivo }}</div>
                                <div class="text-body-2 black--text">Descripción: {{ procedimiento.descripcion }}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn rounded color="secondary" @click="triggerDescargaEvaluacion()" :loading="loading" :disabled="loading">Descargar evaluacion</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { saveAs } from 'file-saver'
    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import { publicPath } from '../../../../../vue.config'

    const ExcelJS = require('exceljs')
    let buffer = null
    let workbook = new ExcelJS.Workbook()
    let worksheet = null
    let cell = null

    export default {
        props: {
            evaluacion: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('procedimiento', {
                procedimientos: 'getProcedimiento',
            }),
        },

        methods: {
            async triggerDescargaEvaluacion() {
                this.loading = true

                await this.loadEvaluacion()
                this.loading = false
            },

            async loadEvaluacion() {
                await axios(publicPath + 'evaluacion_disciplina_operativa.xlsx', { responseType: 'arraybuffer' })
                    // await axios('/evaluacion_disciplina_operativa.xlsx', { responseType: 'arraybuffer' })
                    .catch(function(err) {
                        /* error in getting data */
                        console.log('Error in getting data: ' + err)
                    })
                    .then(async function(res) {
                        /* parse the data when it is received */
                        return res.data
                    })
                    .catch(function(err) {
                        /* error in parsing */
                        console.log('Error in parsin: ' + err)
                    })
                    .then(async data => {
                        await workbook.xlsx.load(data)

                        worksheet = workbook.getWorksheet(1)

                        cell = worksheet.getCell('C7')
                        cell.value = this.getFullName(this.evaluacion.Personal)
                        cell = worksheet.getCell('C9')
                        cell.value = this.evaluacion.Personal.CatPuestoDependencia.catDependencia.nombre
                        cell = worksheet.getCell('C11')
                        cell.value = this.evaluacion.instanciaInforma
                        cell = worksheet.getCell('J7')
                        cell.value = this.evaluacion.fechaAplicacion
                        cell = worksheet.getCell('J9')
                        cell.value = this.evaluacion.Personal.CatPuestoDependencia.catPuesto.puesto
                        cell = worksheet.getCell('J11')
                        cell.value = this.evaluacion.fechaInicial + ' - ' + this.evaluacion.fechaFin
                        cell = worksheet.getCell('M9')
                        cell.value = this.evaluacion.Personal.antiguedad
                        cell = worksheet.getCell('M11')
                        cell.value = this.evaluacion.CatPeriodicidad.title

                        let index_procedimientos = 18
                        this.procedimientos.forEach(procedimiento => {
                            cell = worksheet.getCell('B' + index_procedimientos)
                            cell.value = this.evaluacion.procedimientos.filter(x => {
                                return x.fk_idCatProcedimiento == procedimiento.id
                            }).length
                            index_procedimientos += 2
                        })

                        cell = worksheet.getCell('F15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.numero + '\r\n'
                        })

                        cell = worksheet.getCell('G15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.fecha + '\r\n'
                        })

                        cell = worksheet.getCell('H15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.motivo + '\r\n'
                        })

                        cell = worksheet.getCell('H15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.CatEstatusProcedimiento.title + '\r\n'
                        })

                        cell = worksheet.getCell('J15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.CatEstatusProcedimiento.title + '\r\n'
                        })

                        cell = worksheet.getCell('K15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.descripcion + '\r\n'
                        })

                        cell = worksheet.getCell('N15')
                        cell.value = ''
                        this.evaluacion.procedimientos.forEach((procedimiento, index) => {
                            cell.value += index + 1 + '.' + procedimiento.resolucion + '\r\n'
                        })

                        cell = worksheet.getCell('H36')
                        cell.value = this.getFullName(this.evaluacion.PersonalEvaluador)
                        cell = worksheet.getCell('H41')
                        cell.value = this.evaluacion.PersonalEvaluador.CatPuestoDependencia.catPuesto.puesto

                        worksheet.removeConditionalFormatting()
                        buffer = await workbook.xlsx.writeBuffer()

                        this.downloadEvaluacion()
                    })
            },

            downloadEvaluacion() {
                var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                saveAs(blob, 'evaluacion_disciplina_operativa.xlsx')
            },
        },
    }
</script>
