<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <div class="text-h5 font-weight-bold">Desarrollo del elemento</div>
            <v-tabs v-model="selectedTab" color="secondary" fixed-tabs>
                <v-tab class="font-weight-bold">Evaluaciones <br />médicas</v-tab>
                <v-tab class="font-weight-bold">Resultados en<br />consulta previa</v-tab>
                <v-tab class="font-weight-bold" v-if="perfil != 'Enlace C3'">Biométricos</v-tab>
                <v-tab v-if="estatus == 1" class="font-weight-bold">Cursos</v-tab>
                <v-tab v-if="estatus == 1" class="font-weight-bold">Armas<br />asignadas</v-tab>
                <v-tab v-if="estatus == 1" class="font-weight-bold">Equipamiento</v-tab>
            </v-tabs>
        </v-card-title>
        <v-card-text>
            <v-tabs-items v-model="selectedTab" class="transparent">
                <v-tab-item key="1">
                    <v-container>
                        <v-row v-if="evaluaciones.length > 0">
                            <v-col cols="4" v-for="evaluacionMedica in evaluaciones" :key="evaluacionMedica.id">
                                <v-card color="primary" class="card">
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="evaluacionMedica.fechaAplicacion"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de aplicación"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <div class="text-caption">Resultados</div>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="text-subtitle-2 font-weight-bold">Clínica</div>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-icon v-if="evaluacionMedica.clinica == 1" color="success">mdi-check</v-icon>
                                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="text-subtitle-2 font-weight-bold">Acondicionamiento físico</div>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-icon v-if="evaluacionMedica.acondicionamientoFisico == 1" color="success">mdi-check</v-icon>
                                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="text-subtitle-2 font-weight-bold">Psicológica</div>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-icon v-if="evaluacionMedica.psicologica == 1" color="success">mdi-check</v-icon>
                                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-divider></v-divider>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="text-subtitle-2 font-weight-bold">Resultado</div>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-icon v-if="evaluacionMedica.resultado == 1" color="success">mdi-check</v-icon>
                                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no cuenta con evaluaciones médicas registradas</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="2">
                    <v-container>
                        <v-row v-if="dictamenes.length > 0">
                            <v-col cols="4" v-for="dictamen in dictamenes" :key="dictamen.id">
                                <v-card color="primary" class="card">
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="dictamen.fecha"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de realizacion"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        :value="dictamen.afis"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="AFIS"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        :value="dictamen.suic"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="SUIC"
                                                    ></v-text-field>
                                                    <v-text-field
                                                        :value="dictamen.rnsp"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="RNSP"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" align="center" v-if="perfil === 'Personal/Site'">
                                                    <div class="text-caption font-weight-bold">
                                                        Resultado en consulta previa
                                                    </div>
                                                    <div class="text-subtitle-2">
                                                        {{ dictamen.resultadoConsultaPrevia }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-alert type="success" v-if="dictamen.fk_idCatAlerta == 1">No se encontró incidente alguno</v-alert>
                                                    <v-alert type="warning" class="black--text" v-if="dictamen.fk_idCatAlerta == 2"
                                                        >Se encontró incidente menor</v-alert
                                                    >
                                                    <v-alert type="error" v-if="dictamen.fk_idCatAlerta == 3">Se encontró incidente mayor</v-alert>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text-h6">No se ha hecho la consulta previa de personal</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="3">
                    <v-container>
                        <v-row v-if="biometricos.length > 0">
                            <v-col cols="4" v-for="biometrico in biometricos" :key="biometrico.id">
                                <v-card color="primary" class="card">
                                    <v-card-title class="justify-center d-block" align="center">
                                        <v-icon size="60" color="secondary" v-if="biometrico.TipoBiometrico.id == 1">mdi-fingerprint</v-icon>
                                        <v-icon size="60" color="secondary" v-else-if="biometrico.TipoBiometrico.id == 2">mdi-microphone</v-icon>
                                        <v-icon size="60" color="secondary" v-else>mdi-camera</v-icon>
                                        <div class="font-weight-bold text-h6 d-block">{{ biometrico.TipoBiometrico.title }}</div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="biometrico.fechaRealizacion"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de captura"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-img v-if="getDocumentType(biometrico.nombre) == 'img'" :src="biometrico.URL" contain></v-img>
                                                    <embed
                                                        v-if="getDocumentType(biometrico.nombre) == 'pdf'"
                                                        width="100%"
                                                        class="pdf"
                                                        name="plugin"
                                                        :src="biometrico.URL + '#toolbar=0&navpanes=0&scrollbar=0'"
                                                        type="application/pdf"
                                                    />
                                                </v-col>
                                                <v-col cols="12" align="center">
                                                    <div class="text-caption font-weight-bold">Observaciones</div>
                                                    <div v-if="biometrico.observaciones.length > 0" class="text-subtitle-2">
                                                        {{ biometrico.observaciones }}
                                                    </div>
                                                    <div v-else class="text-subtitle-2">
                                                        Ninguna
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" align="center">
                                                    <v-btn color="secondary" @click="download(biometrico.URL)">Descargar</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no cuenta con captura de biométricos registrados</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="4">
                    <v-container>
                        <v-row v-if="cursos.length > 0">
                            <v-col cols="4" v-for="cursoPersonal in cursos" :key="cursoPersonal.id">
                                <v-card color="primary" class="card">
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="cursoPersonal.ProgramacionCurso.Subcategoria.subcategoria"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Curso realizado"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Categoría</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ cursoPersonal.ProgramacionCurso.Subcategoria.CatCategoria.categoria }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Tipo</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ cursoPersonal.ProgramacionCurso.Subcategoria.CatCategoria.CatTipoCurso.title }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="10">
                                                    <div class="text-subtitle-2 font-weight-bold">Asistió</div>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-icon v-if="cursoPersonal.asistencia == 1" color="success">mdi-check</v-icon>
                                                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Calificación</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2" v-if="cursoPersonal.calificacion.length > 0">
                                                        {{ cursoPersonal.calificacion }}
                                                    </div>
                                                    <div class="text-subtitle-2" v-else>No asignada</div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Comentarios</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2" v-if="cursoPersonal.comentario.length > 0">
                                                        {{ cursoPersonal.comentario }}
                                                    </div>
                                                    <div class="text-subtitle-2" v-else>
                                                        Ninguno
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no cuenta con cursos registrados</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="6">
                    <v-container>
                        <v-row v-if="armas.length > 0">
                            <v-col cols="4" v-for="armaPersonal in armas" :key="armaPersonal.id">
                                <v-card color="primary" class="card">
                                    <v-card-title class="font-weight-bold justify-center">{{ armaPersonal.Arma.CatClase.clase }}</v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Número de inventario</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.noEconomico }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Matrícula</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.matricula }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Modelo</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.CatModelo.modelo }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Calibre</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.CatCalibre.title }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Estatus</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.CatEstatus.title }}
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div class="text-subtitle-2 font-weight-bold">Armero</div>
                                                </v-col>
                                                <v-col cols="6" align="right">
                                                    <div class="text-subtitle-2">
                                                        {{ armaPersonal.Arma.armeros }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no cuenta con armas asignadas</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item key="7">
                    <v-container>
                        <v-row v-if="equipamiento.length > 0">
                            <v-col cols="4" v-for="equipamiento in equipamiento" :key="equipamiento.id">
                                <v-card color="primary" class="card">
                                    <v-card-title class="font-weight-bold justify-center">Asignación de equipamiento</v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        :value="equipamiento.fechaAsignacion"
                                                        rounded
                                                        readonly
                                                        filled
                                                        dense
                                                        background-color="primary"
                                                        color="secondary"
                                                        label="Fecha de asignación"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-list color="primary">
                                                        <v-subheader class="font-weight-black">Equipo asignado:</v-subheader>
                                                        <v-list-item v-for="equipo in equipamiento.equipoAsignado" :key="equipo.id">
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle>{{ equipo.Equipo.CatItem.CatTipoItem.title }}</v-list-item-subtitle>
                                                                <v-list-item-title class="font-weight-bold">{{
                                                                    equipo.Equipo.CatItem.item
                                                                }}</v-list-item-title>
                                                                <v-list-item-subtitle>{{ equipo.Equipo.numero }}</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-col>
                                                <v-col cols="12" align="center">
                                                    <v-btn color="secondary" v-on:click="download(equipamiento.URL)">Descargar evidencia</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" align="center">
                                <div class="text--secondary text-h6">Este personal no cuenta con asignaciones de equipamiento registradas</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            evaluaciones: {
                type: Array,
                default: function() {
                    return []
                },
            },

            dictamenes: {
                type: Array,
                default: function() {
                    return []
                },
            },

            biometricos: {
                type: Array,
                default: function() {
                    return []
                },
            },

            cursos: {
                type: Array,
                default: function() {
                    return []
                },
            },

            evaluacionesProductividad: {
                type: Array,
                default: function() {
                    return []
                },
            },

            respetoPrincipios: {
                type: Array,
                default: function() {
                    return []
                },
            },

            armas: {
                type: Array,
                default: function() {
                    return []
                },
            },

            equipamiento: {
                type: Array,
                default: function() {
                    return []
                },
            },

            personal: {
                type: Object,
            },

            estatus: {
                type: Number,
            },
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),
        },

        data() {
            return {
                selectedTab: null,
            }
        },
    }
</script>
