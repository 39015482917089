import { axios } from '../../plugins/axios'

export default {
    namespaced: true,
    state: {},

    getters: {},

    mutations: {},

    actions: {
        saveCuip: async function(context, personalCuip) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personalCuip.idPersonal + '/cuip', {
                    CUIP: personalCuip.cuip,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Registro de CUIP y foto realizado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
