import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        inventario: [],
        inventarioArmas: [],
        inventarioAccesorios: [],
        inventarioMuniciones: [],
        inventarioCargadores: [],
    },

    getters: {
        getInventario: state => {
            return state.inventario
        },

        getInventarioArmas: state => {
            return state.inventarioArmas
        },

        getInventarioAccesorios: state => {
            return state.inventarioAccesorios
        },

        getInventarioMuniciones: state => {
            return state.inventarioMuniciones
        },

        getInventarioCargadores: state => {
            return state.inventarioCargadores
        },
    },

    mutations: {
        SET_INVENTARIO: (state, inventario) => {
            state.inventario = inventario
        },

        SET_INVENTARIO_ARMAS: (state, inventarioArmas) => {
            state.inventarioArmas = inventarioArmas
        },

        SET_INVENTARIO_ACCESORIOS: (state, inventarioAccesorios) => {
            state.inventarioAccesorios = inventarioAccesorios
        },

        SET_INVENTARIO_MUNICIONES: (state, inventarioMuniciones) => {
            state.inventarioMuniciones = inventarioMuniciones
        },

        SET_INVENTARIO_CARGADORES: (state, inventarioCargadores) => {
            state.inventarioCargadores = inventarioCargadores
        },
    },

    actions: {
        fetchInventario: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/inventario')
                .then(response => {
                    commit('SET_INVENTARIO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchInventarioArmas: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/inventario/armas')
                .then(response => {
                    commit('SET_INVENTARIO_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchInventarioAccesorios: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/inventario/accesorios')
                .then(response => {
                    commit('SET_INVENTARIO_ACCESORIOS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchInventarioMuniciones: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/inventario/municiones')
                .then(response => {
                    commit('SET_INVENTARIO_MUNICIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchInventarioCargadores: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/inventario/cargadores')
                .then(response => {
                    commit('SET_INVENTARIO_CARGADORES', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveInventario: async function({ commit }, inventario) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/inventario', inventario)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateInventario: async function({ commit }, inventario) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/inventario/' + inventario.id, inventario)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
