<template>
    <v-container fill-height>
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="card" color="primary" :loading="loading">
                    <v-card-title>Tabla de personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        label="Buscar personal por nombre o CUIP"
                                        v-model="searchPersonal"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headers" :items="filteredPersonal" class="transparent" :loading="loading">
                                        <template v-slot:item.nombre="{ item }">
                                            <div class="d-inline-flex align-center pt-4">
                                                <v-avatar size="48" class="mr-4">
                                                    <v-img :src="item.foto"></v-img>
                                                </v-avatar>
                                                <div>
                                                    <div class="text-caption font-weight-bold">
                                                        {{ getFullName(item) }}
                                                    </div>
                                                    <div class="text-caption">
                                                        {{ item.CUIP }}
                                                    </div>
                                                    <div class="text-caption" v-if="item.CatPuestoDependencia">
                                                        {{ item.CatPuestoDependencia.catPuesto.puesto }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:item.susceptible="{ item }">
                                            <v-icon v-if="susceptibleCup(item)" color="success">mdi-check-circle</v-icon>
                                            <v-icon v-else color="error">mdi-alert-circle</v-icon>
                                        </template>
                                        <template v-slot:item.vigenciaEvaluacionC3="{ item }">
                                            <div
                                                :class="
                                                    tieneC3Vigente(item.vigenciaEvaluacionC3)
                                                        ? 'success--text font-weight-bold'
                                                        : 'error--text font-weight-bold'
                                                "
                                            >
                                                {{ item.vigenciaEvaluacionC3 }}
                                            </div>
                                        </template>
                                        <template v-slot:item.vigenciaFormacionInicial="{ item }">
                                            <div
                                                :class="
                                                    tieneC3Vigente(item.vigenciaFormacionInicial)
                                                        ? 'success--text font-weight-bold'
                                                        : 'error--text font-weight-bold'
                                                "
                                            >
                                                {{ item.vigenciaFormacionInicial }}
                                            </div>
                                        </template>
                                        <template v-slot:item.vigenciaCompetenciasBasicas="{ item }">
                                            <div
                                                :class="
                                                    tieneC3Vigente(item.vigenciaCompetenciasBasicas)
                                                        ? 'success--text font-weight-bold'
                                                        : 'error--text font-weight-bold'
                                                "
                                            >
                                                {{ item.vigenciaCompetenciasBasicas }}
                                            </div>
                                        </template>
                                        <template v-slot:item.vigenciaCup="{ item }">
                                            <div
                                                :class="tieneC3Vigente(item.vigenciaCup) ? 'success--text font-weight-bold' : 'error--text font-weight-bold'"
                                            >
                                                {{ item.vigenciaCup }}
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        async mounted() {
            this.loading = true
            if (this.personal.length == 0) {
                // await this.fetchPersonalPagination({
                //     page: this.options.page,
                //     rows: this.options.itemsPerPage,
                // })
                await this.fetchPersonalByDependencia(2)
            }
            this.loading = false
        },

        data() {
            return {
                loading: false,
                searchPersonal: '',

                headers: [
                    { text: 'Nombre', value: 'nombre', sortable: false },
                    { text: 'Susceptible a CUP', value: 'susceptible', align: 'center', sortable: false },
                    { text: 'Vigencia C3', value: 'vigenciaEvaluacionC3', align: 'center', sortable: false },
                    { text: 'Vigencia Formación Inicial', value: 'vigenciaFormacionInicial', align: 'center', sortable: false },
                    { text: 'Vigencia Comp. Básicas', value: 'vigenciaCompetenciasBasicas', align: 'center', sortable: false },
                    { text: 'Vigencia CUP', value: 'vigenciaCup', align: 'center', sortable: false },
                ],
                options: {
                    page: 1,
                    itemsPerPage: 5,
                },
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getFullPersonal',
            }),

            filteredPersonal() {
                if (this.personal.length > 0) {
                    return this.personal.filter(elemento => {
                        return (
                            this.getFullName(elemento)
                                .toLowerCase()
                                .indexOf(this.searchPersonal.toLowerCase()) >= 0 ||
                            elemento.CUIP.toLowerCase().indexOf(this.searchPersonal.toLowerCase()) >= 0
                        )
                    })
                } else return []
            },
        },

        methods: {
            ...mapActions('personal', ['fetchPersonalPagination', 'fetchPersonalByDependencia']),

            susceptibleCup(personal) {
                if (
                    this.tieneC3Vigente(personal.vigenciaEvaluacionC3) &&
                    this.tieneC3Vigente(personal.vigenciaFormacionInicial) &&
                    this.tieneC3Vigente(personal.vigenciaCompetenciasBasicas) &&
                    this.tieneC3Vigente(personal.vigenciaCup)
                ) {
                    return true
                } else return false
            },
        },
    }
</script>
