<template>
    <v-container align="center" fill-height>
        <v-row justify="center">
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title>Personal registrado en LOC</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-spacer></v-spacer>
                                <v-col>
                                    <v-autocomplete
                                        v-model="filtroPuesto"
                                        label="Filtrar por puesto"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :items="puestos"
                                        item-value="id"
                                        item-text="catPuesto.puesto"
                                        item-color="secondary"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col>
                                    <v-select
                                        v-model="filtroFuncion"
                                        label="Filtrar por tipo de funcion"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :items="tipoPuesto"
                                        item-value="id"
                                        item-text="title"
                                        item-color="secondary"
                                        clearable
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="buscaPersonal"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar personal"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headers" :items="filteredPersonal" class="primary" :loading="loading">
                                        <template v-slot:item.nombre="{ item }">
                                            <div class="d-inline-flex align-center pt-4">
                                                <v-avatar size="48" class="mr-4">
                                                    <v-img :src="item.foto"></v-img>
                                                </v-avatar>
                                                <div>
                                                    <div class="text-caption font-weight-bold">
                                                        {{ getFullName(item) }}
                                                    </div>
                                                    <div class="text-caption">
                                                        {{ item.CUIP }}
                                                    </div>
                                                    <div class="text-caption" v-if="item.CatPuestoDependencia">
                                                        {{ item.CatPuestoDependencia.catPuesto.puesto }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-slot:item.vigenciaEvaluacionC3="{ item }">
                                            <div
                                                :class="
                                                    tieneC3Vigente(item.vigenciaEvaluacionC3)
                                                        ? 'success--text font-weight-bold'
                                                        : 'error--text font-weight-bold'
                                                "
                                            >
                                                {{ item.vigenciaEvaluacionC3 }}
                                            </div>
                                        </template>
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn color="secondary" rounded small @click="triggerSavePersonalLoc(item.idPersonal)">Registrar en LOC</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay :value="overlay" :z-index="999" align="center" color="transparent" opacity="1">
            <!-- <v-progress-circular indeterminate color="primary"></v-progress-circular> -->
            <!-- <div class="text-overline mt-4">Cargando información. Por favor, espere...</div> -->
        </v-overlay>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        async mounted() {
            this.loading = true
            if (this.personal.length == 0) {
                // await this.fetchPersonalPagination({
                //     page: this.options.page,
                //     rows: this.options.itemsPerPage,
                // })
                await this.fetchPersonalByDependencia(2)
            }
            this.loading = false
        },

        data() {
            return {
                loading: false,
                buscaPersonal: '',
                headers: [
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Tipo de funciones', value: 'CatPuestoDependencia.CatTipoPuesto.title' },
                    { text: 'Vigencia C3', value: 'vigenciaEvaluacionC3', align: 'center', sortable: false },
                    { text: 'Opciones', value: 'opciones' },
                ],

                overlay: false,

                filtroPuesto: null,
                filtroFuncion: null,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getFullPersonal',
            }),

            ...mapGetters('puesto', {
                puestos: 'getPuestos',
            }),

            ...mapGetters('tipoPuesto', {
                tipoPuesto: 'getTipoPuesto',
            }),

            ...mapGetters('tipoFuncion', {
                tipoFuncion: 'getTipoFuncion',
            }),

            filteredPersonal: function() {
                var filteredPersonal = this.personal.filter(elemento => {
                    return (
                        this.getFullName(elemento)
                            .toLowerCase()
                            .indexOf(this.buscaPersonal.toLowerCase()) >= 0 || elemento.CUIP.toLowerCase().indexOf(this.buscaPersonal.toLowerCase()) >= 0
                    )
                })

                if (this.filtroPuesto) {
                    filteredPersonal = filteredPersonal.filter(personal => {
                        if (personal.CatPuestoDependencia) {
                            return personal.CatPuestoDependencia.fk_idPuesto == this.filtroPuesto
                        }
                    })
                }

                if (this.filtroFuncion) {
                    filteredPersonal = filteredPersonal.filter(personal => {
                        if (personal.CatPuestoDependencia) {
                            return personal.CatPuestoDependencia.fk_idCatTipoPuesto == this.filtroFuncion
                        }
                    })
                }

                return filteredPersonal
            },
        },

        methods: {
            ...mapActions('personalLoc', ['savePersonalLoc']),
            ...mapActions('personal', ['fetchPersonalPagination', 'fetchPersonalByDependencia']),

            async triggerSavePersonalLoc(idPersonal) {
                this.overlay = true

                await this.savePersonalLoc({
                    idPersonal: idPersonal,
                    fk_idLoc: 1,
                })
                this.overlay = false
            },
        },
    }
</script>
