<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col cols="2">
                        <v-btn text @click="$router.go(-1)">
                            <v-icon>mdi-arrow-left</v-icon>
                            Volver
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <personal-info-card-component :personal="personal"></personal-info-card-component>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4" offset="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Agrega familiar a personal</v-card-title>
                    <v-card-text>
                        <v-form v-model="familiarForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="familiar.nombre"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Segundo nombre"
                                            v-model="familiar.segundoNombre"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="familiar.apaterno"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="familiar.amaterno"
                                            color="secondary"
                                            background-color="primary"
                                            rounded
                                            filled
                                            dense
                                            :rules="required"
                                        >
                                            <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="familiar.fk_idCatParentesco"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :items="parentesco"
                                            item-value="id"
                                            item-text="title"
                                        >
                                            <template v-slot:label>Parentezco <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="familiar.fechaNacimiento"
                                                    rounded
                                                    filled
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    v-on="on"
                                                    v-bind="attrs"
                                                    readonly
                                                    append-icon="mdi-calendar"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Fecha de nacimiento <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                ref="picker"
                                                v-model="familiar.fechaNacimiento"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                min="1950-01-01"
                                                @change="saveBirthdayDate(familiar.fechaNacimiento)"
                                                color="secondary"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="familiar.fk_idCatSexo"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :items="sexo"
                                            item-value="id"
                                            item-text="title"
                                            :rules="required"
                                        >
                                            <template v-slot:label>Sexo <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn color="secondary" class="primary--text px-8 mb-4" :disabled="!familiarForm || loading" v-on:click="triggerSaveFamiliar()">
                            Registrar familiar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchParentesco()
            await this.fetchSexo()
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                menu: false,
                familiarForm: false,
                loading: false,

                familiar: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    fechaNacimiento: null,
                    fk_idCatSexo: null,
                    fk_idCatParentesco: null,
                },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('sexo', {
                sexo: 'getSexo',
            }),

            ...mapGetters('parentesco', {
                parentesco: 'getParentesco',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },

        methods: {
            ...mapActions('sexo', ['fetchSexo']),
            ...mapActions('parentesco', ['fetchParentesco']),
            ...mapActions('familiares', ['saveFamiliar']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            saveBirthdayDate: function(date) {
                this.$refs.menu.save(date)
            },

            triggerSaveFamiliar: async function() {
                this.loading = true

                await this.saveFamiliar({
                    idPersonal: this.id,
                    fk_idCatParentesco: this.familiar.fk_idCatParentesco,
                    fk_idCatSexo: this.familiar.fk_idCatSexo,
                    nombre: this.familiar.nombre,
                    segundoNombre: this.familiar.segundoNombre,
                    apaterno: this.familiar.apaterno,
                    amaterno: this.familiar.amaterno,
                    fechaNacimiento: moment(this.familiar.fechaNacimiento).format('DD/MM/YYYY'),
                })

                this.$router.go(-1)
            },
        },
    }
</script>
