<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Asignación de arma a personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 25" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">
                                        PASO {{ activeStep }}
                                        <div v-if="activeStep == 4" class="d-inline">(Opcional)</div>
                                    </div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <!-- <v-form v-model="cuipForm">
                                            <v-autocomplete
                                                v-model="armaPersonal.fk_idPersonal"
                                                :items="personal"
                                                item-text="CUIP"
                                                background-color="primary"
                                                color="secondary"
                                                :rules="cuipRules"
                                                rounded
                                                filled
                                                dense
                                                clearable
                                                item-value="idPersonal"
                                            >
                                                <template v-slot:label>Ingrese CUIP <span class="red--text">*</span> </template>
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="accent" class="headline font-weight-light white--text">
                                                        <v-icon color="white">mdi-police-badge</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold">{{
                                                            item.apaterno + ' ' + item.amaterno + ' ' + item.nombre + ' ' + item.segundoNombre
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle v-text="item.CUIP"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-form> -->
                                        <v-row>
                                            <v-col cols="8">
                                                <v-text-field
                                                    v-model="busquedaPersonal.CUIP"
                                                    placeholder="Buscar personal"
                                                    rounded
                                                    filled
                                                    background-color="primary"
                                                    color="secondary"
                                                    :disabled="loadingBusqueda"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn
                                                    rounded
                                                    block
                                                    large
                                                    color="secondary"
                                                    @click="triggerSearchPersonal()"
                                                    :disabled="loadingBusqueda || loading"
                                                    :loading="loadingBusqueda"
                                                >
                                                    Buscar <v-icon class="ml-2">mdi-magnify</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-virtual-scroll :items="personal" item-height="64" height="300">
                                                    <template v-slot:default="{ item }">
                                                        <v-list-item :key="item.idPersonal" link @click="setPersonalArma(item.idPersonal)">
                                                            <template v-slot:default="{ active }">
                                                                <v-list-item-avatar size="80">
                                                                    <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                                                    <v-icon size="48" v-else>mdi-account</v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <div v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                                                        <div v-else>CUIP no asignado</div>
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle> Estatus: {{ item.CatEstatus.title }} </v-list-item-subtitle>
                                                                    <v-list-item-subtitle>
                                                                        Vigencia C3:
                                                                        {{ item.vigenciaEvaluacionC3 === '' ? 'No registrado' : item.vigenciaEvaluacionC3 }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </v-list-item>
                                                    </template>
                                                </v-virtual-scroll>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-date-picker
                                            v-model="armaPersonal.fechaAsignado"
                                            locale="es"
                                            color="secondary"
                                            no-title
                                            full-width
                                            :max="getMaxDay"
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="12">
                                                <div class="text-subtitle-1">Armas asignadas actualmente</div>
                                                <v-list v-if="armasPersonal.length > 0">
                                                    <v-list-item v-for="(arma, index) in armasPersonal" :key="index">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ arma.Arma.matricula }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                {{ arma.Arma.CatClase.CatTipo.title }}
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select
                                                    v-model="tipoArma"
                                                    v-on:change="selectedWeapon = null"
                                                    filled
                                                    rounded
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :items="filteredTipoArma"
                                                    item-value="id"
                                                    item-text="title"
                                                >
                                                    <template v-slot:label>Tipo de arma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-select
                                                    filled
                                                    rounded
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :items="tipoAsignacion"
                                                    item-value="id"
                                                    item-text="title"
                                                    v-model="armaPersonal.fk_idTipoAsignacion"
                                                >
                                                    <template v-slot:label>Tipo de asignación <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-select
                                                    v-model="armaPersonal.fk_idArma"
                                                    v-on:change="triggerFetchCargadoresByCalibre()"
                                                    filled
                                                    rounded
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    :items="filteredWeaponsByType"
                                                    item-value="idArma"
                                                    item-text="CatModelo.modelo"
                                                    :disabled="filteredWeaponsByType.length == 0"
                                                >
                                                    <template v-slot:label>Arma <span class="red--text">*</span> </template>
                                                    <template v-slot:item="{ item, on, attrs }">
                                                        <v-list-item v-on="on" v-bind="attrs">
                                                            <v-list-item-content>
                                                                <v-list-item-subtitle class="text-caption"
                                                                    >Matrícula: {{ item.matricula }}</v-list-item-subtitle
                                                                >
                                                                <v-list-item-title class="text-subtitle-2 font-weight-black"
                                                                    >Modelo: {{ item.CatModelo.modelo }}</v-list-item-title
                                                                >
                                                                <v-list-item-subtitle class="text-caption">Serie: {{ item.serie }}</v-list-item-subtitle>
                                                                <v-list-item-subtitle class="text-caption"
                                                                    >Clase: {{ item.CatClase.title }}</v-list-item-subtitle
                                                                >
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Cantidad de municiones"
                                                    type="number"
                                                    v-model.number="municionesArma.cantidad"
                                                    rounded
                                                    filled
                                                    dense
                                                    autocomplete="off"
                                                    min="0"
                                                ></v-text-field>
                                                <v-textarea
                                                    label="Comentarios (Opcional)"
                                                    v-model="armaPersonal.comentario"
                                                    rounded
                                                    filled
                                                    background-color="primary"
                                                    color="secondary"
                                                    rows="4"
                                                    dense
                                                    no-resize
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 4" key="4">
                                        <v-text-field
                                            label="Número de oficio de resguardo"
                                            v-model="cargadorPersonal.oficio"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                        ></v-text-field>
                                        <v-textarea
                                            label="Comentarios"
                                            v-model="cargadorPersonal.comentario"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            rows="4"
                                            dense
                                            no-resize
                                        ></v-textarea>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3" v-if="activeStep > 1">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        async mounted() {
            await this.fetchTipoAsignacion()
        },

        beforeRouteLeave(to, from, next) {
            this.RESET_BUSQUEDA_PERSONAL()
            next()
        },

        data() {
            return {
                activeStep: 1,
                loading: false,
                cuipForm: false,
                cuip: null,
                successfulCuipSearch: null,
                selectedPersonal: null,
                tipoArma: null,

                armaPersonal: {
                    fk_idPersonal: null,
                    fk_idArma: null,
                    fk_idTipoAsignacion: null,
                    fechaAsignado: null,
                    comentario: '',
                },

                municionesArma: {
                    fk_idArma: 0,
                    fk_idCalibre: 0,
                    cantidad: 0,
                    fechaAsignado: null,
                },

                cargadorPersonal: {
                    fk_idPersonal: null,
                    fk_idCargador: 0,
                    fechaAsignado: null,
                    oficio: '',
                    comentario: '',
                },

                cuipRules: [v => !!v || 'Por favor, ingrese el CUIP.'],

                busquedaPersonal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: '',
                },

                loadingBusqueda: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('arma', {
                weapons: 'getArmas',
            }),

            ...mapGetters('tipoArma', {
                weaponTypes: 'getTipoArmas',
            }),

            ...mapGetters('tipoAsignacionArma', {
                tipoAsignacion: 'getTipoAsignacion',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
                idArmaPersonal: 'getIdArmaPersonal',
            }),

            ...mapGetters('cargadores', {
                cargadores: 'getCargadores',
            }),

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },

            filteredArmas: function() {
                return this.weapons.filter(arma => {
                    return arma.fk_idEstatus == 1
                })
            },

            filteredTipoArma: function() {
                if (this.armasPersonal.some(arma => arma.Arma.CatClase.fk_idTipo == 1)) {
                    return this.weaponTypes.filter(tipoArma => {
                        return tipoArma.id == 2
                    })
                } else if (this.armasPersonal.some(arma => arma.Arma.CatClase.fk_idTipo == 2)) {
                    return this.weaponTypes.filter(tipoArma => {
                        return tipoArma.id == 1
                    })
                } else {
                    return this.weaponTypes
                }
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Elegir personal'
                    case 2:
                        return 'Fecha de asignación'
                    case 3:
                        return 'Arma, municiones y cargadores'
                    case 4:
                        return 'Oficio'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        // if (this.cuipForm) {
                        //     return true
                        // } else {
                        //     return false
                        // }
                        return true
                    case 2:
                        // if (this.weaponAssignmentEntryDate != '') {
                        //     return true
                        // } else {
                        //     return false
                        // }
                        return true
                    case 3:
                        // if (this.selectedWeapon) {
                        //     return true
                        // } else return false
                        return true
                    case 4:
                        // if (this.weaponAssignmentOfficeFile) {
                        //     return true
                        // } else {
                        //     return false
                        // }
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 4) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            filteredWeaponsByType: function() {
                return this.filteredArmas.filter(weapon => {
                    return weapon.CatClase.fk_idTipo == this.tipoArma
                })
            },
        },

        methods: {
            ...mapActions('armaPersonal', ['saveArmaPersonal', 'fetchArmasPersonal']),
            ...mapActions('municionArma', ['saveMunicionArma']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapActions('tipoAsignacionArma', ['fetchTipoAsignacion']),
            ...mapActions('cargadores', ['fetchCargadores']),
            ...mapActions('cargadorPersonal', ['saveCargadorPersonal']),
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.loading = true
                    await this.fetchArmasPersonal(this.armaPersonal.fk_idPersonal)
                    if (this.armasPersonal.length < 2) {
                        this.activeStep++
                    } else {
                        this.SET_SNACKBAR_MESSAGE('El elemento seleccionado ya cuenta con dos armas asignadas.')
                    }
                    this.loading = false
                } else if (this.activeStep > 1 && this.activeStep < 4) {
                    this.activeStep++
                } else {
                    this.loading = true

                    // console.log(this.armaPersonal)
                    // console.log(this.municionesArma)
                    this.armaPersonal.fechaAsignado = moment(this.armaPersonal.fechaAsignado).format('DD/MM/YYYY')
                    this.cargadorPersonal.fechaAsignado = this.armaPersonal.fechaAsignado
                    this.municionesArma.fechaAsignado = this.armaPersonal.fechaAsignado
                    this.municionesArma.fk_idCalibre = this.filteredArmas.find(arma => arma.idArma == this.armaPersonal.fk_idArma).fk_idCalibre
                    this.cargadorPersonal.fk_idPersonal = this.armaPersonal.fk_idPersonal

                    await this.saveArmaPersonal(this.armaPersonal)
                    this.municionesArma.fk_idArma = this.idArmaPersonal
                    await this.saveMunicionArma(this.municionesArma)
                    this.SET_SNACKBAR_MESSAGE('Arma asignada a personal correctamente.')
                    this.RESET_BUSQUEDA_PERSONAL()
                    this.$router.push('/armeria/catalogo_armas')
                }
            },

            async triggerFetchCargadoresByCalibre() {
                var arma = this.filteredArmas.filter(arma => {
                    return arma.idArma == this.armaPersonal.fk_idArma
                })
                await this.fetchCargadores(arma[0].fk_idCalibre)
            },

            buscaPersonal() {
                return new Promise(async () => {
                    setTimeout(await this.searchPersonal(this.busquedaPersonal), 200)
                    this.loadingBusqueda = false
                })
            },

            triggerSearchPersonal() {
                this.loadingBusqueda = true
                if (this.busquedaPersonal.CUIP !== '') {
                    this.buscaPersonal()
                } else {
                    this.loadingBusqueda = false
                    this.RESET_BUSQUEDA_PERSONAL()
                }
            },

            setPersonalArma(id_personal) {
                this.armaPersonal.fk_idPersonal = id_personal
                this.activeStepButtonHandler()
            },
        },
    }
</script>
