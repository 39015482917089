import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        aulas: [],
        aulaToUpdate: null,
    },

    getters: {
        getAulas: state => {
            return state.aulas
        },

        getAulaToUpdate: state => {
            return state.aulaToUpdate
        },
    },

    mutations: {
        SET_AULAS(state, aulas) {
            state.aulas = aulas
        },

        SET_AULA_TO_UPDATE(state, aulaId) {
            var aulaToUpdate = state.aulas.filter(aula => {
                return aula.id == aulaId
            })

            var aula = _.cloneDeep(aulaToUpdate[0])

            state.aulaToUpdate = aula
        },
    },

    actions: {
        fetchAulas: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Profesionalizacion/aulas')
                .then(response => {
                    commit('SET_AULAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveAula: async function({ commit }, aula) {
            await axios
                .post('/PoliciaCuadrantes/Profesionalizacion/aulas', aula)
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateAula: async function({ commit }, aula) {
            await axios
                .put('/PoliciaCuadrantes/Profesionalizacion/aulas/' + aula.id, aula)
                .then(response => {
                    // commit('SET_EQUIPMENT_ASSIGNMENTS', response.data.data)
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
