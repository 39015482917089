<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card class="card" color="primary">
            <v-card-title>
                <div class="text-h6">Lista de procedimientos</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-virtual-scroll height="480" item-height="132" :items="procedimientos">
                    <template v-slot:default="{ item }">
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ item.fecha }}</v-list-item-subtitle>
                                <v-list-item-title>{{ item.motivo }}</v-list-item-title>
                                <v-list-item-subtitle class="font-weight-bold">Tipo: {{ item.CatProcedimiento.title }}</v-list-item-subtitle>
                                <v-list-item-subtitle>No. oficio: {{ item.numero }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Descripción: {{ item.descripcion }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Resolución: {{ item.resolucion }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            active: {
                type: Boolean,
            },

            personal: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        computed: {
            ...mapGetters('procedimientos', {
                procedimientos: 'getProcedimientos',
            }),
        },

        methods: {
            closeDialog() {
                this.$emit('close')
            },
        },
    }
</script>
