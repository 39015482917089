<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Sancion administrativa</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 50" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col cols="12" v-if="activeStep == 1" key="1">
                                        <v-row align="center">
                                            <v-col cols="12">
                                                <v-date-picker
                                                    v-model="faltaAdministrativa.fechaDesde"
                                                    locale="es"
                                                    color="secondary"
                                                    :max="getToday()"
                                                    no-title
                                                    full-width
                                                    :rules="required"
                                                ></v-date-picker>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="sancionForm">
                                            <v-autocomplete
                                                v-model="cuipSanciona"
                                                label="¿Quién sanciona?"
                                                placeholder="Buscar CUIP"
                                                :items="personal"
                                                item-value="idPersonal"
                                                item-text="CUIP"
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                rounded
                                                filled
                                                dense
                                                clearable
                                            >
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="accent" class="headline font-weight-light white--text">
                                                        <v-icon color="white">mdi-police-badge</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold">{{
                                                            item.apaterno + ' ' + item.amaterno + ' ' + item.nombre + ' ' + item.segundoNombre
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle v-text="item.CUIP"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-container v-if="personal.length == 0">
                                                        <v-row>
                                                            <v-col cols="12" align="center">
                                                                <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                                                <div class="text-caption font-weight-bold mt-4">Cargando datos. Por favor, espere...</div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </template>
                                            </v-autocomplete>
                                            <v-autocomplete
                                                v-model="cuipSancionado"
                                                label="¿A quién sanciona?"
                                                placeholder="Buscar CUIP"
                                                :items="personal"
                                                item-value="idPersonal"
                                                item-text="CUIP"
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                                rounded
                                                filled
                                                dense
                                                clearable
                                            >
                                                <template v-slot:item="{ item }">
                                                    <v-list-item-avatar color="accent" class="headline font-weight-light white--text">
                                                        <v-icon color="white">mdi-police-badge</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="font-weight-bold">{{
                                                            item.apaterno + ' ' + item.amaterno + ' ' + item.nombre + ' ' + item.segundoNombre
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle v-text="item.CUIP"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-container v-if="personal.length == 0">
                                                        <v-row>
                                                            <v-col cols="12" align="center">
                                                                <v-progress-circular indeterminate color="accent"></v-progress-circular>
                                                                <div class="text-caption font-weight-bold mt-4">Cargando datos. Por favor, espere...</div>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </template>
                                            </v-autocomplete>
                                            <v-textarea
                                                no-resize
                                                rows="3"
                                                color="secondary"
                                                background-color="primary"
                                                label="Motivo de sanción"
                                                v-model="faltaAdministrativa.motivo"
                                                rounded
                                                filled
                                                dense
                                                :rules="required"
                                            ></v-textarea>
                                            <v-file-input
                                                v-model="file"
                                                label="Oficio"
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                dense
                                                :rules="required"
                                            ></v-file-input>
                                        </v-form>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" align="center">
                                    <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
                                    <v-btn
                                        class="mb-4 mx-8"
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import { cardFormColsProps, cardProps, cardTitleProps, circularProgressProps, textInputProps } from '../../components/props/props'
    import moment from 'moment'

    export default {
        data() {
            return {
                cardFormColsProps,
                cardProps,
                cardTitleProps,
                circularProgressProps,
                textInputProps,

                activeStep: 1,
                loading: false,

                sancionForm: false,
                cuipSanciona: '',
                cuipSancionado: '',

                faltaAdministrativa: {
                    fk_idPersonalArresto: null,
                    fk_idCatMotivo: 0,
                    fk_idTipoFalta: 1,
                    motivo: '',
                    fechaDesde: null,
                    fechaHasta: null,
                    adjunto: '',
                },

                file: null,

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de sanción'
                    case 2:
                        return 'Información de sanción'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.faltaAdministrativa.fechaDesde) {
                            return true
                        } else return false
                    case 2:
                        if (this.sancionForm) {
                            return true
                        } else return false
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 2) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar sanción'
                }
            },

            asuntoInternoLabel: function() {
                if (this.asuntoInternoSwitch == true) {
                    return 'Apto'
                } else return 'No apto'
            },
        },

        methods: {
            ...mapActions('faltasAdministrativas', ['saveFaltaAdministrativa']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 2) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.faltaAdministrativa.fechaDesde = moment(this.faltaAdministrativa.fechaDesde).format('DD/MM/YYYY')
                    this.faltaAdministrativa.fechaHasta = moment(this.faltaAdministrativa.fechaDesde).format('DD/MM/YYYY')
                    // await this.saveFaltaAdministrativa(this.faltaAdministrativa)
                }
            },
        },
    }
</script>
