<template>
    <v-dialog persistent width="90%" v-model="active">
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Evaluación de renidmiento y profesionalismo</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="rendimientoProfesionalismo">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card" color="primary">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluado"
                                                    :value="getFullName(rendimientoProfesionalismo.Personal)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Evaluador"
                                                    :value="getFullName(rendimientoProfesionalismo.PersonalEvaluador)"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="rendimientoProfesionalismo.CatMunicipio.nombre"
                                                    label="Municipio"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="rendimientoProfesionalismo.fechaInicial"
                                                    label="Fecha Inicial"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="rendimientoProfesionalismo.fechaFin"
                                                    label="Fecha fin"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="rendimientoProfesionalismo.fechaAplicacion"
                                                    label="Fecha de aplicación"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Institución de seguridad pública a la que aspira"
                                                    v-model="rendimientoProfesionalismo.institucionSeguridadAspira"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Aspirante al puesto de"
                                                    v-model="rendimientoProfesionalismo.aspiranteAlPuesto"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Reactivos</div>
                                </v-card-title>
                                <v-card-text v-if="rendimientoProfesionalismo.frecuenciasMedicion.length > 0">
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(reactivo, index) in reactivos" :key="index">
                                                <v-row align="center">
                                                    <v-col cols="1">
                                                        {{ index + 1 }}
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <div class="text-subtitle-2">{{ reactivo.CatReactivo.title }}</div>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            label="Frecuencia de medición"
                                                            v-model="rendimientoProfesionalismo.frecuenciasMedicion[index].fk_idCatFrecuencia"
                                                            rounded
                                                            filled
                                                            dense
                                                            :items="frecuencia"
                                                            item-value="id"
                                                            item-text="title"
                                                            item-color="secondary"
                                                            color="secondary"
                                                            background-color="primary"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-divider class="my-2"></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Observaciones</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-textarea
                                                    label="Observaciones"
                                                    v-model="rendimientoProfesionalismo.observaciones"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    no-resize
                                                    rows="3"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="5">
                            <v-card color="primary" class="card">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Resultados</div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Resultado</div>
                                                        <div class="text-subtitle-1">{{ getResultado }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Puntaje total</div>
                                                        <div class="text-subtitle-1">{{ getPuntajeTotal }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-card class="card" color="primary">
                                                    <v-card-title class="d-block">
                                                        <div class="text-caption">Calificación</div>
                                                        <div class="text-subtitle-1">{{ getCalificacion }}</div>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveRendimientoProfesionalismo()" :loading="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        props: {
            idPersonal: {
                type: Number,
            },

            active: {
                type: Boolean,
            },

            rendimientoProfesionalismo: {
                type: Object,
            },
        },

        data() {
            return {
                rendimientoProfesionalismoDialog: false,
                loading: false,

                menuFecha: [],
            }
        },

        computed: {
            ...mapGetters('periodicidad', {
                periodicidad: 'getPeriodicidad',
            }),

            ...mapGetters('reactivosEvaluacion', {
                reactivos: 'getReactivosRendimientoProfesionalismo',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuencia: 'getFrecuenciaRespetoPrincipio',
            }),

            ...mapGetters('resultado', {
                resultado: 'getResultado',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            getResultado: function() {
                // TODO: Ligar resultado con objeto a actualizar, ya que siempre lo deja con resultado Excelente
                if (this.getPuntajeTotal <= 35) {
                    this.rendimientoProfesionalismo.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal > 35 && this.getPuntajeTotal < 42) {
                    this.rendimientoProfesionalismo.fk_idResultado = 4
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal > 41 && this.getPuntajeTotal < 48) {
                    this.rendimientoProfesionalismo.fk_idResultado = 3
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal > 47 && this.getPuntajeTotal < 54) {
                    this.rendimientoProfesionalismo.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal > 53) {
                    this.rendimientoProfesionalismo.fk_idResultado = 1
                    return 'Excelente'
                }
            },

            getPuntajeTotal: function() {
                if (this.rendimientoProfesionalismo) {
                    var puntajeTotal = 0
                    this.rendimientoProfesionalismo.frecuenciasMedicion.forEach(reactivo => {
                        switch (reactivo.fk_idCatFrecuencia) {
                            case 1:
                                puntajeTotal = puntajeTotal + 4
                                break
                            case 2:
                                puntajeTotal = puntajeTotal + 3
                                break
                            case 3:
                                puntajeTotal = puntajeTotal + 2
                                break
                            case 4:
                                puntajeTotal = puntajeTotal + 1

                                break
                            case 5:
                                puntajeTotal = puntajeTotal + 0
                                break
                            default:
                                break
                        }
                    })

                    return puntajeTotal
                }
            },

            getCalificacion: function() {
                if (this.getPuntajeTotal == 0) {
                    return '0'
                } else if (this.getPuntajeTotal > 1 && this.getPuntajeTotal < 36) {
                    return '5'
                } else if (this.getPuntajeTotal > 35 && this.getPuntajeTotal < 42) {
                    return '6'
                } else if (this.getPuntajeTotal > 41 && this.getPuntajeTotal < 48) {
                    return '7'
                } else if (this.getPuntajeTotal > 47 && this.getPuntajeTotal < 54) {
                    return '8'
                } else if (this.getPuntajeTotal > 53 && this.getPuntajeTotal < 57) {
                    return '9'
                } else if (this.getPuntajeTotal > 56) {
                    return '10'
                }
            },
        },

        methods: {
            ...mapActions('rendimientoProfesionalismo', ['updateRendimientoProfesionalismo', 'updateEstatusRendimientoProfesionalismo']),
            ...mapActions('reactivosEvaluacion', ['fetchReactivosEvaluacion']),
            ...mapActions('evaluacionesPersonal', ['fetchEvaluacionesById']),

            triggerSaveRendimientoProfesionalismo: async function() {
                this.loading = true
                this.rendimientoProfesionalismo.puntajeTotal = this.getPuntajeTotal
                this.rendimientoProfesionalismo.calificacion = this.getCalificacion
                await this.updateRendimientoProfesionalismo(this.rendimientoProfesionalismo)
                await this.updateEstatusRendimientoProfesionalismo(this.rendimientoProfesionalismo)
                await this.fetchEvaluacionesById(this.rendimientoProfesionalismo.fk_idPersonalEvaluador)
                this.closeDialog()
            },

            closeDialog() {
                this.loading = false
                this.$emit('close')
            },
        },
    }
</script>
