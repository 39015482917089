<template>
    <v-main class="primary mt-4">
        <router-view></router-view>
    </v-main>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        async mounted() {
            this.fetchTipoEstudios()
            this.fetchEstados()
            this.fetchMunicipios()
            this.fetchTipoNacionalidad()
            this.fetchDocumentoByPersonal(localStorage.getItem('idPersonal'))
            this.fetchEstudiosByPersonal(localStorage.getItem('idPersonal'))
        },

        methods: {
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('documento', ['fetchDocumentoByPersonal']),
            ...mapActions('estudio', ['fetchEstudiosByPersonal', 'fetchTipoEstudios']),
        },
    }
</script>
