<template>
    <v-card class="card" color="primary">
        <v-card-title class="font-weight-bold justify-center">Registro de capacitador</v-card-title>
        <v-card-text>
            <v-form v-model="trainerForm">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="capacitador.nombre" rounded filled dense background-color="primary" color="secondary" :rules="nameRules">
                                <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="capacitador.segundoNombre"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                label="Segundo nombre"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="capacitador.apaterno"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                label="Apellido paterno"
                                :rules="nameRules"
                            >
                                <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="capacitador.amaterno"
                                rounded
                                filled
                                dense
                                background-color="primary"
                                color="secondary"
                                label="Apellido materno"
                                :rules="nameRules"
                            >
                                <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                v-model="capacitador.file"
                                persistent-hint
                                rounded
                                filled
                                background-color="primary"
                                color="secondary"
                                dense
                                :rules="nameRules"
                            >
                                <template v-slot:label>Adjuntar certificado o título <span class="red--text">*</span> </template>
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="justify-center pb-8">
            <v-btn text color="error" class="primary--text px-8" v-on:click="$router.go(-1)">Cancelar</v-btn>
            <v-btn color="secondary" class="primary--text px-8" :disabled="!trainerForm || loading" v-on:click="triggerSaveTrainer()">Registrar</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        data() {
            return {
                loading: false,
                trainerForm: false,
                capacitador: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    certificado: '',
                    file: null,
                },

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        methods: {
            ...mapActions('capacitador', ['saveCapacitador', 'fetchCapacitadores']),

            triggerSaveTrainer: async function() {
                this.loading = true

                this.capacitador.certificado = this.capacitador.file.name
                await this.saveCapacitador(this.capacitador)
                await this.fetchCapacitadores()

                this.$router.push('/profesionalizacion/capacitadores')

                this.loading = false
            },
        },
    }
</script>
