<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Registro de LOC</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 20" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <v-text-field
                                            label="Buscar CUIP"
                                            v-model="cuip"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                            v-on:input="searchCuip()"
                                            autocomplete="off"
                                        ></v-text-field>
                                        <v-expand-transition>
                                            <v-row v-if="successfulCuipSearch">
                                                <v-col cols="12">
                                                    <v-card flat color="secondary" rounded>
                                                        <v-container>
                                                            <v-row align="center">
                                                                <v-col cols="4" align="center">
                                                                    <v-avatar size="48" color="primary" rounded>
                                                                        <v-icon color="secondary">mdi-account</v-icon>
                                                                    </v-avatar>
                                                                </v-col>
                                                                <v-col cols="8">
                                                                    <div class="text-subtitle-1 primary--text">
                                                                        {{
                                                                            selectedPersonal.nombre +
                                                                                ' ' +
                                                                                selectedPersonal.segundoNombre +
                                                                                ' ' +
                                                                                selectedPersonal.amaterno +
                                                                                ' ' +
                                                                                selectedPersonal.apaterno
                                                                        }}
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-expand-transition>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-form v-model="validSecondStepForm">
                                            <v-row>
                                                <v-col cols="7">
                                                    <v-text-field
                                                        label="Número de licencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        v-model="loc.noLicencia"
                                                        :rules="required"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="5">
                                                    <v-text-field
                                                        label="Año de vigencia"
                                                        background-color="primary"
                                                        color="secondary"
                                                        maxlength="4"
                                                        rounded
                                                        filled
                                                        dense
                                                        v-model="loc.vigencia"
                                                        :rules="required"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-date-picker v-model="fechaAlta" locale="es" color="secondary" no-title full-width></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 4" key="4">
                                        <v-date-picker v-model="fechaTramite" locale="es" color="secondary" no-title full-width></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 5" key="5">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="R.M."
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.rm"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="Z.M."
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.zm"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Instancia"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.nombreInstancia"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Titular de la licencia"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.titular"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Representante legal"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.representanteLegal"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="Dirección"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                    disabled
                                                    v-model="loc.direccion"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 6" key="6">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field
                                                    rounded
                                                    filled
                                                    color="secondary"
                                                    append-icon="mdi-magnify"
                                                    v-model="searchWeapon"
                                                    label="Buscar arma por serie o matrícula"
                                                ></v-text-field>
                                                <v-list color="primary">
                                                    <v-list-item-group multiple active-class="">
                                                        <v-list-item v-for="weapon in filteredWeapons" v-bind:key="weapon.idArma">
                                                            <template v-slot:default="{ active }">
                                                                <v-list-item-action>
                                                                    <v-checkbox v-model="armasElegidas" :value="weapon.idArma" color="secondary"></v-checkbox>
                                                                </v-list-item-action>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        {{ weapon.CatModelo.modelo }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>Matricula: {{ weapon.matricula }}</v-list-item-subtitle>
                                                                    <v-list-item-subtitle>Serie: {{ weapon.serie }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    /* .card {
        box-shadow: 8px 8px 16px var(--v-primary-darken1), -8px -8px 16px var(--v-primary-lighten1) !important;
        transition: 0.3s;
    } */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_LOC_TO_UPDATE(this.id)
        },

        data() {
            return {
                activeStep: 2,
                loading: false,
                cuip: '',
                successfulCuipSearch: null,
                selectedPersonal: null,
                locRegistrationDate: '',
                validSecondStepForm: false,
                searchWeapon: '',
                armasElegidas: [],

                fechaAlta: null,
                fechaTramite: null,

                // loc: {
                //     idPersonal: null,
                //     fechaAlta: null,
                //     fechaTramite: null,
                //     noLicencia: '',
                //     vigencia: '',
                //     rm: 'VI REG',
                //     zm: '23/a Z.M.',
                //     nombreInstancia: 'Secretaría de seguridad ciudadana',
                //     titular: 'Maestro Eduardo Zárate Valiente Hernández Secretario de Seguridad Ciudadana',
                //     representanteLegal: 'Rafael Rodríguez (Encargado del depósito de armamento y municiones)',
                //     direccion: 'Calle Xicoténcatl #13 Col Centro Tlax',
                // },

                required: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('arma', {
                weapons: 'getArmas',
            }),

            ...mapGetters('licenciaOficialColectiva', {
                loc: 'getLocToUpdate',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Elegir personal'
                    case 2:
                        return 'Número de licencia'
                    case 3:
                        return 'Fecha de alta'
                    case 4:
                        return 'Fecha de trámite'
                    case 5:
                        return 'Información de instancia'
                    case 6:
                        return 'Armas'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.cuip.length > 0 && this.successfulCuipSearch) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.validSecondStepForm) {
                            return true
                        } else {
                            return false
                        }
                    case 3:
                        if (this.loc.fechaAlta != '' && this.loc.fechaAlta) {
                            return true
                        } else {
                            return false
                        }
                    case 4:
                        if (this.loc.fechaTramite != '' && this.loc.fechaTramite) {
                            return true
                        } else {
                            return false
                        }
                    case 5:
                        return true
                    case 6:
                        if (this.armasElegidas.length > 0) {
                            return true
                        } else {
                            return false
                        }
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 6) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar loc'
                }
            },

            filteredWeapons: function() {
                return this.weapons.filter(weapon => {
                    return (
                        weapon.matricula.toLowerCase().indexOf(this.searchWeapon.toLowerCase()) >= 0 ||
                        weapon.serie.toLowerCase().indexOf(this.searchWeapon.toLowerCase()) >= 0
                    )
                })
            },
        },

        methods: {
            ...mapActions('licenciaOficialColectiva', ['saveLoc']),
            ...mapMutations('licenciaOficialColectiva', ['SET_LOC_TO_UPDATE']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 6) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.loc.idPersonal = 1
                    this.loc.fechaAlta = moment(this.loc.fechaAlta).format('DD/MM/YYYY')
                    this.loc.fechaTramite = moment(this.loc.fechaTramite).format('DD/MM/YYYY')

                    await this.saveLoc(this.loc)

                    this.$router.push('/armeria/dashboard')
                }
            },

            searchCuip: async function() {
                this.loading = true
                this.selectedPersonal = this.personal.find(element => element.idPersonal == this.cuip)
                if (this.selectedPersonal || !this.selectedPersonal === undefined) {
                    this.successfulCuipSearch = true
                } else {
                    this.successfulCuipSearch = false
                }

                this.loading = false
            },
        },
    }
</script>
