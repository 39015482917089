import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cantidadSeniaParticular: [],
    },

    getters: {
        getCantidadSeniaParticular: state => {
            return state.cantidadSeniaParticular
        },
    },

    mutations: {
        SET_CANTIDAD_SENIA_PARTICULAR(state, cantidadSeniaParticular) {
            state.cantidadSeniaParticular = cantidadSeniaParticular
        },
    },

    actions: {
        fetchCantidadSeniaParticular: async function({ commit }) {
            await axios
                .get('/EonCloud/common/cantidadSeniaParticular')
                .then(response => {
                    commit('SET_CANTIDAD_SENIA_PARTICULAR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
