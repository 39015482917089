<template>
    <v-container v-if="personal">
        <v-col cols="12">
            <v-row>
                <v-col cols="2">
                    <v-btn text @click="$router.go(-1)">
                        <v-icon>mdi-arrow-left</v-icon>
                        Volver
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <personal-info-card-component :personal="personal"></personal-info-card-component>
                </v-col>
            </v-row>
        </v-col>
        <v-row justify="center">
            <v-col cols="5" v-if="activeStep == 1">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Credencialización</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-date-picker
                                                v-model="enrolamiento.fechaCaptura"
                                                locale="es"
                                                color="secondary"
                                                no-title
                                                full-width
                                                :rules="required"
                                            ></v-date-picker>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-form v-model="dictamenForm">
                                <v-row>
                                    <v-col cols="8" offset="2">
                                        <v-card color="white" height="480" elevation="8" class="rounded-xl">
                                            <v-card-title class="justify-center">
                                                <v-img src="@/assets/img/eon.png" contain max-height="48"></v-img>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row align="center">
                                                        <v-col cols="3">
                                                            <v-avatar rounded color="grey" size="56">
                                                                <v-icon size="32">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="6" align="center">
                                                            <v-avatar rounded color="grey" size="96">
                                                                <v-icon size="48">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-avatar rounded color="grey" size="56">
                                                                <v-icon size="32">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="activeStep > 1">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Credencialización</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="6" align="right">
                                    <v-progress-circular :value="activeStep * 33" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                        >Volver al paso anterior</a
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 2">
                            <v-form>
                                <v-row align="center">
                                    <v-col cols="4" offset-md="1">
                                        <v-form v-model="fotosForm">
                                            <v-file-input
                                                v-model="enrolamiento.fotoFrontal"
                                                label="Adjuntar foto frontal"
                                                persistent-hint
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                dense
                                                prepend-icon="mdi-camera"
                                            >
                                            </v-file-input>
                                            <v-file-input
                                                v-model="enrolamiento.fotoLateral1"
                                                label="Adjuntar foto lateral izquierda"
                                                persistent-hint
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                dense
                                                prepend-icon="mdi-camera"
                                            >
                                            </v-file-input>
                                            <v-file-input
                                                v-model="enrolamiento.fotoLateral2"
                                                label="Adjuntar foto lateral derecha"
                                                persistent-hint
                                                rounded
                                                filled
                                                background-color="primary"
                                                color="secondary"
                                                dense
                                                prepend-icon="mdi-camera"
                                            >
                                            </v-file-input>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="4" offset="2">
                                        <v-card color="white" height="480" elevation="8" class="rounded-xl">
                                            <v-card-title class="justify-center">
                                                <v-img src="@/assets/img/eon.png" contain max-height="48"></v-img>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row align="center">
                                                        <v-col cols="3">
                                                            <v-avatar rounded color="grey" size="56">
                                                                <v-icon size="32">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="6" align="center">
                                                            <v-avatar rounded color="grey" size="96">
                                                                <v-icon size="48">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-avatar rounded color="grey" size="56">
                                                                <v-icon size="32">mdi-account</v-icon>
                                                            </v-avatar>
                                                        </v-col>
                                                        <v-col cols="8" offset="2" align="center" class="mt-16">
                                                            <div class="text-overline font-weight-bold text--primary">{{ getFullName(personal) }}</div>
                                                            <div class="text-overline">{{ personal.CatPuestoDependencia.catPuesto.puesto }}</div>
                                                            <div class="text-overline">{{ personal.CUIP }}</div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container v-if="activeStep == 3">
                            <v-form>
                                <v-row align="center">
                                    <v-col cols="4" offset-md="1">
                                        <v-form>
                                            <v-text-field
                                                v-model.number="personal.numeroCredencial"
                                                type="number"
                                                rounded
                                                filled
                                                dense
                                                background-color="primary"
                                                color="secondary"
                                                :rules="required"
                                            >
                                                <template v-slot:label>Folio <span class="red--text">*</span> </template>
                                            </v-text-field>
                                            <v-checkbox label="Portación de arma" v-model="portacionArma" color="secondary"></v-checkbox>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="4" offset="2">
                                        <v-card color="white" height="560" elevation="8" class="rounded-xl">
                                            <v-card-title class="justify-center">
                                                <v-img src="@/assets/img/eon.png" contain max-height="48"></v-img>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-container>
                                                    <v-row align="center">
                                                        <v-col cols="6">
                                                            <div class="text-subtitle-2 font- weight-bold">Folio</div>
                                                        </v-col>
                                                        <v-col cols="6" align="right">
                                                            <div class="text-body-1">{{ personal.numeroCredencial }}</div>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <div class="text-subtitle-2 font- weight-bold">Vigencia</div>
                                                        </v-col>
                                                        <v-col cols="6" align="right">
                                                            <div class="text-body-1">{{ getVigencia }}</div>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <div class="text-subtitle-2 ">Tipo de sangre</div>
                                                        </v-col>
                                                        <v-col cols="6" align="right">
                                                            <div v-if="personal.CatGrupoSanguineo" class="text-overline font-weight-bold">
                                                                {{ personal.CatGrupoSanguineo.title }}
                                                            </div>
                                                            <div v-else class="text-overline font-weight-bold">No registrado</div>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <div class="text-subtitle-2 ">Tipo de puesto</div>
                                                        </v-col>
                                                        <v-col cols="6" align="right">
                                                            <div v-if="personal.CatPuestoDependencia" class="text-overline font-weight-bold">
                                                                {{ personal.CatPuestoDependencia.CatTipoPuesto.title }}
                                                            </div>
                                                            <div v-else class="text-overline font-weight-bold">No registrado</div>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <div class="text-caption font-weight-bold">Información de armas</div>
                                                            <v-row>
                                                                <v-col cols="12" v-if="armasPersonal.length == 0">
                                                                    <div class="text-subtitle-2">El elemento no cuenta con armas registradas</div>
                                                                </v-col>
                                                                <v-col cols="12" v-else>
                                                                    <v-list>
                                                                        <v-list-item v-for="(arma, index) in armasPersonal" :key="index">
                                                                            <v-list-item-content>
                                                                                <v-list-item-subtitle>
                                                                                    {{ arma.Arma.CatClase.CatTipo.title }}
                                                                                </v-list-item-subtitle>
                                                                                <v-list-item-title>
                                                                                    {{ arma.Arma.CatClase.clase }} {{ arma.Arma.CatModelo.modelo }}
                                                                                </v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" align="center">
                                    <v-btn
                                        color="secondary"
                                        class="px-8"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapMutations, mapGetters, mapActions } from 'vuex'
    import { circularProgressProps } from '../../components/props/props'
    import moment from 'moment'
    import PersonalInfoCardComponent from '../../components/PersonalInfoCardComponent.vue'

    export default {
        components: {
            PersonalInfoCardComponent,
        },

        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.fetchArmasPersonal(this.id)
        },

        data() {
            return {
                circularProgressProps,
                loading: false,
                activeStep: 1,

                required: [v => !!v || 'Este campo es requerido.'],

                enrolamiento: {
                    idPersonal: this.id,
                    fechaCaptura: null,
                    fotoFrontal: null,
                    fotoLateral1: null,
                    fotoLateral2: null,
                    firmaDigital: null,
                    cuip: null,
                },

                portacionArma: false,
                fotosForm: false,
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de registro'
                    case 2:
                        return 'Parte frontal'
                    case 3:
                        return 'Parte trasera'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.enrolamiento.fechaCaptura) {
                            return true
                        } else return false
                    case 2:
                        if (this.fotosForm) {
                            return true
                        } else return false
                    default:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Guardar registro de credencial'
                }
            },

            getVigencia() {
                if (this.portacionArma) {
                    return '30 de Junio'
                } else {
                    return '31 de Diciembre'
                }
            },
        },

        methods: {
            ...mapActions('personal', ['uploadFoto', 'fetchPersonal', 'updatePersonal']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            ...mapActions('enrolamiento', ['saveEnrolamiento']),
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    this.enrolamiento.fechaCaptura = moment(this.enrolamiento.fechaCaptura).format('DD/MM/YYYY')
                    this.enrolamiento.cuip = this.personal.CUIP
                    this.enrolamiento.firmaDigital = this.enrolamiento.fotoFrontal

                    var formData = new FormData()
                    formData.append('file', this.enrolamiento.fotoFrontal)
                    formData.append('idPersonal', this.personal.idPersonal)

                    await this.updatePersonal(this.personal)
                    await this.uploadFoto(formData)
                    await this.saveEnrolamiento(this.enrolamiento)

                    this.$router.push('/personal/personal')
                }
            },
        },
    }
</script>
