<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Registro de curso</v-card-title>
                    <v-card-text>
                        <v-form v-model="courseForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="curso.subcategoria"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Subcategoría (nombre del curso) <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-select
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            :items="categories"
                                            item-value="id"
                                            item-text="categoria"
                                            v-model="curso.fk_idCategoria"
                                            :rules="nameRules"
                                            item-color="secondary"
                                        >
                                            <template v-slot:label>Categoría <span class="red--text">*</span> </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            v-model.number="curso.puntaje"
                                            type="number"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Puntaje"
                                            :rules="puntajeRules"
                                        >
                                            <template v-slot:label>Puntaje <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="curso.observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="4"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            v-model="curso.file"
                                            label="Adjuntar documento del curso .jpg"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                            ref="file"
                                            type="file"
                                            id="file"
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" class="mb-8" v-on:click="triggerSaveCourse()" :loading="loading" :disabled="loading || !courseForm"
                            >Registrar curso</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        data() {
            return {
                loading: false,
                courseForm: false,

                curso: {
                    nombre: null,
                    subcategoria: '',
                    fk_idCategoria: null,
                    observaciones: '',
                    puntaje: 0,
                    file: null,
                },

                nameRules: [v => !!v || 'Este campo es requerido.'],
                puntajeRules: [v => !!v || 'Ingrese un puntaje válido.', v => v > 0 || 'Ingrese un puntaje válido.'],
            }
        },

        computed: {
            ...mapGetters('categoriaCurso', {
                categories: 'getCategories',
            }),

            ...mapGetters('tipoCurso', {
                tipoCurso: 'getCourseTypes',
            }),
        },

        methods: {
            ...mapActions('curso', ['saveCurso']),
            ...mapActions('curso', ['fetchCursos']),

            triggerSaveCourse: async function() {
                this.loading = true

                this.curso.nombre = this.curso.file.name
                await this.saveCurso(this.curso)

                await this.fetchCursos()
                this.$router.push('/profesionalizacion/cursos')
                this.loading = false
            },

            handleFileUpload() {
                this.file = this.$refs.file.files[0]
                console.log(this.$refs.file.files[0])
            },
        },
    }
</script>
