import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tiposRegion: [],
    },

    getters: {
        getTiposRegion: state => {
            return state.tiposRegion
        },
    },

    mutations: {
        SET_TIPOS_REGION(state, tiposRegion) {
            state.tiposRegion = tiposRegion
        },
    },

    actions: {
        fetchTiposRegion: async function({ commit }) {
            await axios
                .get('/EonCloud/common/tiporegiones')
                .then(response => {
                    commit('SET_TIPOS_REGION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
