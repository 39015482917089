import { axios, fileAxios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        asignacionesEquipo: [],
    },

    getters: {
        getAsignacionesEquipo: state => {
            return state.asignacionesEquipo
        },
    },

    mutations: {
        SET_ASIGNACIONES_EQUIPO(state, asignacionesEquipo) {
            state.asignacionesEquipo = asignacionesEquipo
        },
    },

    actions: {
        fetchAsignacionEquipoByPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/personal/' + idPersonal + '/equipamiento')
                .then(response => {
                    commit('SET_ASIGNACIONES_EQUIPO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveAsignacionEquipo: async function(context, asignacionEquipo) {
            await axios
                .post('/PoliciaCuadrantes/Equipamiento/personal/' + asignacionEquipo.fk_idPersonal + '/equipamiento', asignacionEquipo)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', asignacionEquipo.file)
                    formData.append('idDetalleAsignacion', response.data.data[0].ID)
                    context.dispatch('cargaArchivoAsignacionEquipo', formData)
                })
                .catch(error => console.log(error))
        },

        updateAsignacionEquipo: async function(context, asignacionEquipo) {
            await axios
                .put('/PoliciaCuadrantes/Equipamiento/personal/' + asignacionEquipo.idPersonal + '/equipamiento/' + asignacionEquipo.itemId, asignacionEquipo)
                .then(response => {})
                .catch(error => console.log(error))
        },

        cargaArchivoAsignacionEquipo: async function({ commit }, formData) {
            await fileAxios
                .post('/SetOficioDetalleAsignacionEquipo/uploadFiles', formData)
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
