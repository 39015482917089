<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Catálogo de armas</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row justify="end" align="center">
                                <v-col cols="2">
                                    <registro-arma-dialog-component></registro-arma-dialog-component>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field
                                        v-model="buscaArma"
                                        rounded
                                        filled
                                        dense
                                        hide-details
                                        background-color="primary"
                                        color="secondary"
                                        label="Buscar arma"
                                        append-icon="mdi-magnify"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table class="primary" :headers="headers" :items="armas" :search="buscaArma" calculate-widths>
                                        <template v-slot:item.estatus="{ item }">
                                            <span class="text-body-2">{{ item.CatEstatus.title }}</span>
                                            <v-btn icon small color="secondary" @click="openEstatusArmaDialog(item)"><v-icon>mdi-update</v-icon> </v-btn>
                                        </template>
                                        <template v-slot:item.opciones="{ item }">
                                            <v-btn icon color="secondary" @click="openEditarArmaDialog(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                            <v-btn icon color="secondary" @click="openConsultaArmaLocDialog(item)"><v-icon>mdi-book-search</v-icon></v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <estatus-arma-dialog-component
            :active="estatusArmaDialog"
            :arma="selectedArma"
            v-on:close="estatusArmaDialog = false"
        ></estatus-arma-dialog-component>
        <editar-arma-dialog-component :active="editarArmaDialog" :arma="selectedArma" v-on:close="editarArmaDialog = false"></editar-arma-dialog-component>
        <consulta-arma-loc-dialog-component :active="locDialog" :arma="selectedArma" @close="locDialog = false"></consulta-arma-loc-dialog-component>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import ConsultaArmaLocDialogComponent from '../../components/armeria/ConsultaArmaLocDialogComponent.vue'
    import EditarArmaDialogComponent from '../../components/armeria/EditarArmaDialogComponent.vue'
    import EstatusArmaDialogComponent from '../../components/armeria/EstatusArmaDialogComponent.vue'
    import RegistroArmaDialogComponent from '../../components/armeria/RegistroArmaDialogComponent.vue'

    export default {
        components: {
            EstatusArmaDialogComponent,
            EditarArmaDialogComponent,
            RegistroArmaDialogComponent,
            ConsultaArmaLocDialogComponent,
        },

        data() {
            return {
                headers: [
                    { text: 'Tipo', value: 'CatClase.CatTipo.title', width: '10%' },
                    { text: 'Marca', value: 'CatModelo.CatMarca.title', width: '10%' },
                    { text: 'Modelo', value: 'CatModelo.modelo', width: '5%' },
                    { text: 'Folio', value: 'folio', width: '10%' },
                    { text: 'Matrícula', value: 'matricula', width: '10%' },
                    { text: 'No. económico', value: 'noEconomico', width: '10%' },
                    { text: 'Armero', value: 'armeros', width: '10%' },
                    { text: 'Fecha alta LOC', value: 'fechaAltaLoc', width: '10%' },
                    { text: 'Estatus', value: 'estatus', width: '15%', sortable: false },
                    { text: 'Opciones', value: 'opciones', align: 'center', width: '10%', sortable: false },
                ],

                estatusArmaDialog: false,
                editarArmaDialog: false,
                locDialog: false,
                selectedArma: null,
                buscaArma: '',
            }
        },

        computed: {
            ...mapGetters('arma', {
                armas: 'getArmas',
            }),
        },

        methods: {
            ...mapActions('claseArma', ['fetchClasesArma']),
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('armaLoc', ['fetchArmaLoc']),

            openEstatusArmaDialog(arma) {
                this.selectedArma = arma
                this.estatusArmaDialog = true
            },

            async openEditarArmaDialog(arma) {
                this.selectedArma = arma
                await this.fetchModeloArmas(arma.CatModelo.fk_idMarca)
                this.editarArmaDialog = true
            },

            async openConsultaArmaLocDialog(arma) {
                this.selectedArma = arma
                await this.fetchArmaLoc(arma.idArma)
                this.locDialog = true
            },
        },
    }
</script>
