import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        mediasFiliaciones: [],
    },

    getters: {
        getMediasFiliaciones: state => {
            return state.mediasFiliaciones
        },
    },

    mutations: {
        SET_MEDIAS_FILIACIONES(state, mediasFiliaciones) {
            state.mediasFiliaciones = mediasFiliaciones
        },
    },

    actions: {
        fetchMediasFiliaciones: async function({ commit }) {
            await axios
                .get('/EonCloud/mediasfiliaciones')
                .then(response => {
                    commit('SET_MEDIAS_FILIACIONES', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
