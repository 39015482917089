<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Registro de arma nueva</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 25" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">
                                        PASO {{ activeStep }}
                                        <div v-if="activeStep == 4" class="d-inline">(Opcional)</div>
                                    </div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <v-select
                                            v-model="selectedWeaponType"
                                            filled
                                            rounded
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :items="weaponTypes"
                                            label="Tipo de arma"
                                            item-value="id"
                                            item-text="title"
                                            v-on:change="weaponClassesHandler()"
                                        ></v-select>
                                        <v-select
                                            v-model="arma.fk_idClase"
                                            filled
                                            rounded
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Clase de arma"
                                            :items="weaponClasses"
                                            item-value="id"
                                            item-text="clase"
                                            :disabled="loadingWeaponClasses || !selectedWeaponType"
                                        ></v-select>
                                        <v-select
                                            v-on:change="arma.fk_idModelo = null"
                                            filled
                                            rounded
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Marca"
                                            :items="weaponBrands"
                                            item-value="id"
                                            item-text="title"
                                            v-model="selectedWeaponBrand"
                                        ></v-select>
                                        <v-select
                                            v-model="arma.fk_idModelo"
                                            filled
                                            rounded
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Modelo"
                                            :items="filteredWeaponModelsByBrand"
                                            item-value="id"
                                            item-text="modelo"
                                            :disabled="!selectedWeaponBrand"
                                        ></v-select>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-select
                                                    filled
                                                    rounded
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Calibre"
                                                    :items="calibers"
                                                    item-value="id"
                                                    item-text="title"
                                                    v-model="arma.fk_idCalibre"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="arma.serie"
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Número de inventario"
                                                    filled
                                                    rounded
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="arma.matricula"
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Matrícula"
                                                    filled
                                                    rounded
                                                    dense
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="arma.observaciones"
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Observaciones"
                                                    filled
                                                    rounded
                                                    dense
                                                ></v-text-field>
                                                <v-select
                                                    v-model="arma.fk_idCatProcedenciaArma"
                                                    filled
                                                    rounded
                                                    dense
                                                    background-color="primary"
                                                    color="secondary"
                                                    label="Procedencia del arma"
                                                    :items="procedencia"
                                                    item-value="id"
                                                    item-text="title"
                                                    item-color="secondary"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="7" class="pb-0">
                                                <v-text-field
                                                    background-color="primary"
                                                    color="secondary"
                                                    filled
                                                    rounded
                                                    dense
                                                    v-model="arma.noEconomico"
                                                    label="Número económico"
                                                    type="number"
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="5" class="pb-0">
                                                <v-select
                                                    v-model="arma.fk_idEstatus"
                                                    filled
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    dense
                                                    :items="weaponStatus"
                                                    item-value="id"
                                                    item-text="title"
                                                    label="Estatus"
                                                    :disabled="loading"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                                <v-text-field
                                                    v-model="arma.folioC"
                                                    label="Folio C"
                                                    background-color="primary"
                                                    color="secondary"
                                                    filled
                                                    rounded
                                                    dense
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="6" class="py-0">
                                                <v-text-field
                                                    v-model="arma.folioD"
                                                    label="Folio D"
                                                    background-color="primary"
                                                    color="secondary"
                                                    filled
                                                    rounded
                                                    dense
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="py-0">
                                                <v-text-field
                                                    v-model="arma.armeros"
                                                    label="Armeros"
                                                    background-color="primary"
                                                    color="secondary"
                                                    filled
                                                    rounded
                                                    dense
                                                    :disabled="loading"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                loading: false,
                loadingWeaponClasses: false,
                activeStep: 1,
                selectedWeaponType: null,
                selectedWeaponBrand: null,

                arma: {
                    serie: '',
                    folio: '',
                    matricula: '',
                    noEconomico: 0,
                    folioC: '',
                    folioD: '',
                    loc: '',
                    fechaAltaLoc: '01/01/1999',
                    fk_idSubsede: null,
                    armeros: '',
                    fk_idModelo: null,
                    fk_idClase: null,
                    fk_idCalibre: null,
                    fk_idEstatus: null,
                    fk_idCatProcedenciaArma: null,
                },
            }
        },

        computed: {
            ...mapGetters('tipoArma', {
                weaponTypes: 'getTipoArmas',
            }),

            ...mapGetters('marcaArma', {
                weaponBrands: 'getMarcaArma',
            }),

            ...mapGetters('claseArma', {
                weaponClasses: 'getClasesArma',
            }),

            ...mapGetters('modeloArma', {
                modeloArma: 'getModelosArmas',
            }),

            ...mapGetters('calibre', {
                calibers: 'getCalibres',
            }),

            ...mapGetters('estatusArma', {
                weaponStatus: 'getEstatusArma',
            }),

            ...mapGetters('procedencia', {
                procedencia: 'getProcedencia',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Tipo de arma'
                    case 2:
                        return 'Información del arma'
                    case 3:
                        return 'Administración del arma'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.selectedWeaponType && this.selectedWeaponBrand && this.arma.fk_idModelo) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.arma.fk_idCalibre && this.arma.serie != '' && this.arma.fk_idCatProcedenciaArma) {
                            return true
                        } else {
                            return false
                        }
                    // case 3:
                    //     if (this.weaponLocEntryDate) {
                    //         return true
                    //     } else return false
                    case 3:
                        return true
                }
            },

            filteredWeaponModelsByBrand: function() {
                return this.modeloArma.filter(modelo => {
                    return modelo.CatMarca.id == this.selectedWeaponBrand
                })
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },
        },

        methods: {
            ...mapActions('claseArma', ['fetchClasesArma']),

            ...mapActions('arma', ['saveArma']),

            activeStepButtonHandler: async function() {
                if (this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true
                    await this.saveArma(this.arma)
                    this.loading = false
                    this.$router.push('/armeria/dashboard')
                }
            },

            weaponClassesHandler: async function() {
                this.selectedWeaponClass = null
                this.loadingWeaponClasses = true
                await this.fetchClasesArma(this.selectedWeaponType)
                this.loadingWeaponClasses = false
            },
        },
    }
</script>
