import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        ladoSeniaParticular: [],
    },

    getters: {
        getLadoSeniaParticular: state => {
            return state.ladoSeniaParticular
        },
    },

    mutations: {
        SET_LADO_SENIA_PARTICULAR(state, ladoSeniaParticular) {
            state.ladoSeniaParticular = ladoSeniaParticular
        },
    },

    actions: {
        fetchLadoSeniaParticular: async function({ commit }) {
            await axios
                .get('/EonCloud/common/ladoSeniaParticular')
                .then(response => {
                    commit('SET_LADO_SENIA_PARTICULAR', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
