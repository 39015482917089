import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        rendimientoProfesionalismo: [],
        rendimientoProfesionalismoPersonal: null,
    },

    getters: {
        getRendimientoProfesionalismo: state => {
            return state.rendimientoProfesionalismo
        },

        getRendimientoProfesionalismoPersonal: state => {
            return state.rendimientoProfesionalismoPersonal
        },
    },

    mutations: {
        SET_RENDIMIENTO_PROFESIONALISMO(state, rendimientoProfesionalismo) {
            state.rendimientoProfesionalismo = rendimientoProfesionalismo
        },

        SET_RENDIMIENTO_PROFESIONALISMO_PERSONAL(state, rendimientoProfesionalismoPersonal) {
            state.rendimientoProfesionalismoPersonal = rendimientoProfesionalismoPersonal[0]
        },
    },

    actions: {
        fetchRendimientoProfesionalismo: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/rendimientoProfesionalismo')
                .then(response => {
                    commit('SET_RENDIMIENTO_PROFESIONALISMO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchRendimientoProfesionalismoById: async function({ commit }, evaluacion) {
            await axios
                .get(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        evaluacion.fkidPersonal +
                        '/rendimientoProfesionalismo/' +
                        evaluacion.idRendimientoProfesionalismo
                )
                .then(response => {
                    commit('SET_RENDIMIENTO_PROFESIONALISMO_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveRendimientoProfesionalismo: async function({ commit }, rendimientoProfesionalismo) {
            await axios
                .post(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + rendimientoProfesionalismo.fk_idPersonal + '/rendimientoProfesionalismo',
                    rendimientoProfesionalismo
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateRendimientoProfesionalismo: async function({ commit }, rendimientoProfesionalismo) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        rendimientoProfesionalismo.fk_idPersonal +
                        '/rendimientoProfesionalismo/' +
                        rendimientoProfesionalismo.id,
                    rendimientoProfesionalismo
                )
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatusRendimientoProfesionalismo: async function(context, rendimientoProfesionalismo) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' +
                        rendimientoProfesionalismo.fk_idPersonal +
                        '/rendimientoProfesionalismo/' +
                        rendimientoProfesionalismo.id +
                        '/estatus',
                    {
                        fk_idCatEstatusEvaluacion: 3,
                    }
                )
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
