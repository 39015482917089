import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        evaluacionesPersonal: [],
    },

    getters: {
        getEvaluacionesPersonal: state => {
            return state.evaluacionesPersonal
        },
    },

    mutations: {
        SET_EVALUACIONES_PERSONAL(state, evaluacionesPersonal) {
            state.evaluacionesPersonal = evaluacionesPersonal
        },
    },

    actions: {
        fetchEvaluacionesById: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluador/' + idPersonal + '/evaluaciones')
                .then(response => {
                    commit('SET_EVALUACIONES_PERSONAL', response.data.data[0])
                })
                .catch(error => console.log(error))
        },
    },
}
