<template>
    <v-container v-if="preaspirante">
        <v-row>
            <v-col cols="12">
                <v-btn @click="$router.go(-1)" text>
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver al catálogo
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="5">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Captura de voz</v-card-title>
                    <v-card-text>
                        <v-form v-model="capturaVozForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            :value="getFullName(preaspirante)"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Aspirante"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="fecha"
                                            v-mask="'##/##/####'"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="dateRules"
                                            hint="Formato de fecha: DD/MM/YYYY"
                                        >
                                            <template v-slot:label>Fecha de captura <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            v-model="file"
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                            :rules="nameRules"
                                            prepend-icon="mdi-microphone"
                                        >
                                            <template v-slot:label>Adjuntar archivo de voz <span class="red--text">*</span> </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea
                                            v-model="observaciones"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            label="Observaciones"
                                            no-resize
                                            rows="4"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn color="secondary" class="primary--text px-8" :disabled="!capturaVozForm || loading" v-on:click="triggerSaveBiometrico()"
                            >Registrar</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PREASPIRANTE_PARA_VALIDACION(this.id)
        },

        data() {
            return {
                loading: false,
                capturaVozForm: false,
                menu: false,

                observaciones: '',
                fecha: null,
                file: null,
                nombre: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('preaspirante', {
                preaspirante: 'getPreaspiranteParaValidacion',
            }),
        },

        methods: {
            ...mapActions('biometrico', ['saveBiometricoPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes']),
            ...mapMutations('preaspirante', ['SET_PREASPIRANTE_PARA_VALIDACION']),

            triggerSaveBiometrico: async function() {
                this.loading = true

                await this.saveBiometricoPersonal({
                    nombre: this.file.name,
                    fk_idPersonal: this.id,
                    fk_idTipoBiometrico: 2,
                    observaciones: this.observaciones,
                    fechaRealizacion: this.fecha,
                    file: this.file,
                })

                await this.fetchPreaspirantes()
                this.loading = false
                this.$router.push('/personal/preaspirantes')
            },

            saveBirthdayDate: function(date) {
                this.$refs.menu.save(date)
            },
        },
    }
</script>
