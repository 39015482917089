var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showNavigationDrawer)?_c('v-navigation-drawer',{staticClass:"elevation-8",attrs:{"fixed":"","color":"primary","width":_vm.getNavigationDrawerWidth,"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',[_c('v-list-item',{staticClass:"pl-4"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button primary--text",attrs:{"icon":""},on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},'v-btn',attrs,false),on),[(_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v("mdi-brightness-6")]):_c('v-icon',[_vm._v("mdi-brightness-4")])],1)]}}],null,false,509953601)},[(_vm.$vuetify.theme.dark)?_c('span',[_vm._v("Cambiar a modo oscuro")]):_c('span',[_vm._v("Cambiar a modo claro")])])],1),_c('v-list-item',{staticClass:"pl-4"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button primary--text",attrs:{"icon":""},on:{"click":function($event){return _vm.openUrl('https://eonproduccion.net/soporte/')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-face-agent")])],1)]}}],null,false,1187747269)},[_c('span',[_vm._v("Soporte técnico")])])],1),_c('v-list-item',{staticClass:"pl-4"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button primary--text",attrs:{"icon":""},on:{"click":function($event){_vm.versionDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information")])],1)]}}],null,false,3240864737)},[_c('span',[_vm._v("Acerca de")])])],1),(_vm.perfil == 'ADMINISTRADOR')?_c('v-list-item',{staticClass:"pl-4"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button primary--text",attrs:{"icon":"","to":"/launcher"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}],null,false,408035758)},[_c('span',[_vm._v("Salir de la aplicación")])])],1):_vm._e()],1)]},proxy:true}],null,false,4094681668)},[_c('v-list-item',{staticClass:"pl-4"},[_c('v-img',{staticClass:"mb-1",attrs:{"src":require("@/assets/img/policia.png"),"contain":""}})],1),_c('v-divider'),(_vm.muestraMenuProfesionalizacion)?_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/dashboard"}},[_c('v-icon',[_vm._v("mdi-home")])],1)],1),(_vm.perfil === 'IFCSP_RECLUTAMIENTO' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/candidatos"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit")])],1)]}}],null,false,3588877901)},[_c('span',[_vm._v("Candidatos")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_RECLUTAMIENTO' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/preaspirantes"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}],null,false,3979833809)},[_c('span',[_vm._v("Control de aspirantes")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_PROFESIONALIZACION' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/capacitadores"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-school")])],1)]}}],null,false,489637286)},[_c('span',[_vm._v("Capacitadores")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_PROFESIONALIZACION' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/aulas"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-domain")])],1)]}}],null,false,1333905339)},[_c('span',[_vm._v("Aulas")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_PROFESIONALIZACION' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/cursos"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cast-education")])],1)]}}],null,false,3026973368)},[_c('span',[_vm._v("Cursos")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_PROFESIONALIZACION' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/cursos_programados"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)]}}],null,false,374728410)},[_c('span',[_vm._v("Cursos programados")])])],1):_vm._e(),(_vm.perfil === 'IFCSP_PROFESIONALIZACION' || _vm.perfil === 'IFCSP_ADMINISTRADOR' || _vm.perfil === 'Profesionalización')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/profesionalizacion/solicitud_cursos"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-comment-quote")])],1)]}}],null,false,1910864567)},[_c('span',[_vm._v("Sugerencias de cursos")])])],1):_vm._e()],1):_vm._e(),(_vm.muestraMenuPersonal)?_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/personal/dashboard"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,1412087491)},[_c('span',[_vm._v("Home")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/personal/preaspirantes"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}],null,false,1128989288)},[_c('span',[_vm._v("Aspirantes pendientes de consulta previa")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/personal/aspirantes"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-multiple-check")])],1)]}}],null,false,2858916459)},[_c('span',[_vm._v("Aspirantes pendientes de registro de CUIP")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/personal/personal"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-account")])],1)]}}],null,false,4223672291)},[_c('span',[_vm._v("Personal")])])],1)],1):_vm._e(),(_vm.muestraMenuEnlace)?_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/enlace_c3/dashboard"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,1331176564)},[_c('span',[_vm._v("Home")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/enlace_c3/aspirantes"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-alert")])],1)]}}],null,false,2134745097)},[_c('span',[_vm._v("Aspirantes")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/enlace_c3/personal"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-account")])],1)]}}],null,false,1287621652)},[_c('span',[_vm._v("Personal")])])],1)],1):_vm._e(),(_vm.muestraMenuRH)?_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/recursos_humanos/dashboard"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,1318368187)},[_c('span',[_vm._v("Inicio")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/recursos_humanos/personal"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-account")])],1)]}}],null,false,2917268507)},[_c('span',[_vm._v("Personal")])])],1)],1):_vm._e(),(_vm.muestraMenuArmeria)?_c('v-list',[_c('v-list-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book")])],1)]}}],null,false,1660768404)},[_c('v-list',[(_vm.perfil === 'ARMERIA_PASE DE LISTA' || _vm.perfil === 'Armeria')?_c('v-list-item',{attrs:{"link":"","to":"/armeria/pase_lista_diario"},on:{"click":function($event){_vm.weaponDailyRollCallDialogDialog = true}}},[_c('v-list-item-title',[_vm._v("Fatiga de entrada y salida de arma")])],1):_vm._e(),(_vm.perfil === 'ARMERIA_LOC' || _vm.perfil === 'Armeria')?_c('v-list-item',{attrs:{"link":"","to":"/armeria/registro_loc"}},[_c('v-list-item-title',[_vm._v("Registro de LOC")])],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","to":"/armeria/loc_personal"}},[_c('v-list-item-title',[_vm._v("Registro de personal a LOC")])],1)],1)],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pistol")])],1)]}}],null,false,717290144)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/armeria/catalogo_armas"}},[_c('v-list-item-title',[_vm._v("Catálogo de armas")])],1),_c('v-list-item',{attrs:{"link":"","to":"/armeria/consulta_armas"}},[_c('v-list-item-title',[_vm._v("Inventario de armas")])],1),_c('v-list-item',{attrs:{"link":"","to":"/armeria/asignacion_arma"}},[_c('v-list-item-title',[_vm._v("Asignar arma a personal")])],1)],1)],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-ammunition")])],1)]}}],null,false,3936990098)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/armeria/asignacion_municion"}},[_c('v-list-item-title',[_vm._v("Asignar municiones a personal")])],1)],1),_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/armeria/registro_cargador"}},[_c('v-list-item-title',[_vm._v("Registro de cargador")])],1)],1),_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/armeria/asignacion_municion_curso"}},[_c('v-list-item-title',[_vm._v("Asignar municiones a un curso")])],1)],1)],1)],1),_c('v-list-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-package")])],1)]}}],null,false,763824935)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":"/armeria/inventario_municiones"}},[_c('v-list-item-title',[_vm._v("Inventario de municiones y cargadores")])],1),(_vm.perfil === 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS' || _vm.perfil === 'Armeria')?_c('v-list-item',{attrs:{"link":"","to":"/armeria/inventario_items"}},[_c('v-list-item-title',[_vm._v("Inventario de items")])],1):_vm._e(),(_vm.perfil === 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS' || _vm.perfil === 'Armeria')?_c('v-list-item',{attrs:{"link":"","to":"/armeria/asignacion_items"}},[_c('v-list-item-title',[_vm._v("Asignación de items a personal")])],1):_vm._e()],1)],1)],1)],1):_vm._e(),(_vm.muestraMenuEquipamiento)?_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/equipamiento/personal"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-account")])],1)]}}],null,false,898628598)},[_c('span',[_vm._v("Personal")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/equipamiento/items"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-briefcase-variant")])],1)]}}],null,false,4144150863)},[_c('span',[_vm._v("Catálogo de items")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/equipamiento/equipo"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-briefcase-variant")])],1)]}}],null,false,789736030)},[_c('span',[_vm._v("Control de equipo")])])],1)],1):_vm._e(),(_vm.muestraMenuDesarrolloProfesional)?_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/desarrollo_profesional/dashboard"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}],null,false,2433599151)},[_c('span',[_vm._v("Inicio")])])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/desarrollo_profesional/personal"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-account")])],1)]}}],null,false,1888084111)},[_c('span',[_vm._v("Personal")])])],1),(_vm.perfil === 'DP_ADMINISTRADOR' || _vm.perfil === 'DP_CONDECORACIONES Y CONVOCATORIAS' || _vm.perfil === 'Desarrollo Profesional')?_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"menu-button mr-3 primary--text",attrs:{"icon":"","to":"/desarrollo_profesional/convocatorias"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-certificate")])],1)]}}],null,false,3746045553)},[_c('span',[_vm._v("Convocatorias")])])],1):_vm._e()],1):_vm._e(),_c('version-dialog-component',{attrs:{"active":_vm.versionDialog},on:{"close":function($event){_vm.versionDialog = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }