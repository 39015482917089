<template>
    <v-alert :type="getAlertType(vigenciaFormacion)" dense :color="vigenciaFormacion === '' ? 'blue-grey' : ''">
        {{ getAlertText(vigenciaFormacion) }}
    </v-alert>
</template>
<script>
    import moment from 'moment'

    export default {
        props: {
            vigenciaFormacion: {
                type: String,
            },
        },

        methods: {
            getAlertType(fecha_vigencia) {
                if (fecha_vigencia !== '') {
                    return moment(fecha_vigencia, 'DD/MM/YYYY').diff(moment(), 'months', true) > 7 ? 'success' : 'error'
                } else return 'info'
            },

            getAlertText(fecha_vigencia) {
                if (fecha_vigencia !== '') {
                    return 'Formación inicial: ' + this.getDateText(fecha_vigencia)
                } else return 'Formación inicial: Fecha no registrada'
            },
        },
    }
</script>
