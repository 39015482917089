<template>
    <v-container>
        <v-row>
            <v-col cols="7" align="right">
                <!-- <v-btn class="secondary card py-y mt-2 px-8" rounded elevation="8" to="/profesionalizacion/registro_loc">
                    Registrar loc <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn> -->
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="buscaLoc"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar loc por nombre, edad, municipio o estado..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaLoc.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredLocs.length }} resultados encontrados con la búsqueda "{{ buscaLoc }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoLocs, index) in groupedLocs" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoLocs.fecha }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoLocs.locs.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="loc in grupoLocs.locs" :key="loc.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-book</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ loc.noLicencia }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-caption primary--text">Fecha de alta</div>
                                                        <div class="text-subtitle-2 primary--text">
                                                            {{ loc.fechaAlta }}
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-caption primary--text">Fecha de trámite</div>
                                                        <div class="text-subtitle-2 primary--text">{{ loc.fechaTramite }}</div>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-caption primary--text">Cantidad de armas</div>
                                                        <div class="text-subtitle-2 primary--text">
                                                            18
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="right">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'editaLoc' }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Agendar cita de evaluación</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import _ from 'lodash'

    export default {
        data() {
            return {
                buscaLoc: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('licenciaOficialColectiva', {
                locs: 'getLocs',
            }),

            filteredLocs: function() {
                return this.locs.filter(loc => {
                    return (
                        loc.noLicencia.toLowerCase().indexOf(this.buscaLoc.toLowerCase()) >= 0

                        // (loc.CatMunicipioRadica && loc.CatMunicipioRadica.nombre.toLowerCase().indexOf(this.buscaLoc.toLowerCase()) >= 0) ||
                        // (loc.CatMunicipioRadica &&
                        //     loc.CatMunicipioRadica.CatEstado.title.toLowerCase().indexOf(this.buscaLoc.toLowerCase()) >= 0)
                        // (loc.CatMunicipioNacimiento &&
                        //     loc.CatMunicipioNacimiento.nombre.toLowerCase().indexOf(this.buscaLoc.toLowerCase()) >= 0) ||
                        // (loc.CatMunicipioNacimiento &&
                        //     loc.CatMunicipioNacimiento.CatEstado.title.toLowerCase().indexOf(this.buscaLoc.toLowerCase()) >= 0)
                    )
                })
            },

            groupedLocs: function() {
                var result = _(this.filteredLocs)
                    .groupBy(loc => loc.fechaTramite.toUpperCase())
                    .map((locs, fecha) => ({ fecha, locs }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.fecha < b.fecha) {
                            return -1
                        }
                        if (a.fecha > b.fecha) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.fecha > b.fecha) {
                            return -1
                        }
                        if (a.fecha < b.fecha) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },

        methods: {
            getFullName: function(loc) {
                return loc.nombre + ' ' + loc.segundoNombre + ' ' + loc.apaterno + ' ' + loc.amaterno
            },
        },
    }
</script>
