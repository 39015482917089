import { axios } from '../../../plugins/axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        equipos: [],
    },

    getters: {
        getEquipos: state => {
            return state.equipos
        },
    },

    mutations: {
        SET_EQUIPO(state, equipos) {
            state.equipos = equipos
        },
    },

    actions: {
        fetchEquipo: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/equipo')
                .then(response => {
                    commit('SET_EQUIPO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveEquipo: async function(context, equipo) {
            await axios
                .post('/PoliciaCuadrantes/Equipamiento/equipo', equipo)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEquipo: async function(context, equipo) {
            await axios
                .put('/PoliciaCuadrantes/Equipamiento/equipo/' + equipo.idEquipamiento, equipo)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Equipo editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateEstatusEquipo: async function(context, equipo) {
            await axios
                .put('/PoliciaCuadrantes/Equipamiento/equipo/' + equipo.idEquipamiento + '/estatus', equipo)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Estatus de equipo editado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
