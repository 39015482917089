<template>
    <v-card class="card" color="primary">
        <v-card-title>
            <v-container>
                <v-row align="center">
                    <v-col cols="3">
                        <div class="text-h5 font-weight-bold">Evaluaciones</div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                        <v-select
                            label="Filtrar evaluaciones"
                            v-model="filtroEstatus"
                            :items="filteredEstatusEvaluacion"
                            item-value="title"
                            item-text="title"
                            item-color="secondary"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-title>
        <v-card-text>
            <v-container v-if="evaluaciones.Productividad">
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Productividad</div>
                    </v-col>
                </v-row>
                <v-row v-if="filteredProductividad.length > 0">
                    <v-col cols="4" v-for="(productividad, index) in filteredProductividad" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(productividad.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ productividad.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="productividad.estatusEvaluacion === 'Evaluación Pendiente'" color="error">Evaluación pendiente</v-chip>
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="evaluacionProductividad(productividad)">Realizar evaluación</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones de productividad {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container v-if="evaluaciones.RespetoPrincipios">
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Respeto a los principios</div>
                    </v-col>
                </v-row>
                <v-row v-if="filteredRespetoPrincipios.length > 0">
                    <v-col cols="4" v-for="(respetoPrincipios, index) in filteredRespetoPrincipios" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(respetoPrincipios.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ respetoPrincipios.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="respetoPrincipios.estatusEvaluacion === 'Evaluación Pendiente'" color="error">Evaluación pendiente</v-chip>
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="evaluacionRespetoPrincipios(respetoPrincipios)">Realizar evaluación</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones de respeto a los principios {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container v-if="evaluaciones.EvaluacionAnual">
                <v-row v-if="evaluaciones.EvaluacionAnual.length > 0">
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Evaluaciones anuales</div>
                    </v-col>
                    <v-col cols="4" v-for="(evaluacionAnual, index) in evaluaciones.EvaluacionAnual" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(evaluacionAnual.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ evaluacionAnual.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="evaluacionAnual.estatusEvaluacion === 'Evaluación Pendiente'" color="error">Evaluación pendiente</v-chip>
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="openEvaluacionAnual(evaluacionAnual)">Realizar evaluación</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones anuales {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container v-if="evaluaciones.DesempeñoInstitucional">
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Evaluaciones de Desempeño Institucional</div>
                    </v-col>
                </v-row>
                <v-row v-if="filteredDesmpeñoInstitucional.length > 0">
                    <v-col cols="4" v-for="(desempeñoInstitucional, index) in filteredDesmpeñoInstitucional" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(desempeñoInstitucional.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ desempeñoInstitucional.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="desempeñoInstitucional.estatusEvaluacion === 'Evaluación Pendiente'" color="error">Evaluación pendiente</v-chip>
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="openDesempeñoInstitucionalDialog(desempeñoInstitucional)"
                                                >Realizar evaluación</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones de desempeño institucional {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container v-if="evaluaciones.RendimientoProfesionalismo">
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Evaluaciones de Rendimiento y Profesionalismo</div>
                    </v-col>
                </v-row>
                <v-row v-if="filteredRendimientoProfesionalismo.length > 0">
                    <v-col cols="4" v-for="(rendimientoProfesionalismo, index) in filteredRendimientoProfesionalismo" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(rendimientoProfesionalismo.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ rendimientoProfesionalismo.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="rendimientoProfesionalismo.estatusEvaluacion === 'Evaluación Pendiente'" color="error"
                                    >Evaluación pendiente</v-chip
                                >
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="openRendimientoProfesionalismoDialog(rendimientoProfesionalismo)"
                                                >Realizar evaluación</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones de rendimiento y profesionalismo {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-container v-if="evaluaciones.RespetoPrincipiosValores">
                <v-row>
                    <v-col cols="12">
                        <div class="text-h6 font-weight-bold">Evaluaciones de Respeto a los Principios y Valores</div>
                    </v-col>
                </v-row>
                <v-row v-if="filteredRespetoPrincipiosValores.length > 0">
                    <v-col cols="4" v-for="(respetoPrincipiosValores, index) in filteredRespetoPrincipiosValores" :key="index">
                        <v-card color="primary" class="card">
                            <v-card-title class="justify-center d-block" align="center">
                                <div class="text-subtitle-1 font-weight-bold">{{ getFullName(respetoPrincipiosValores.Evaluado[0]) }}</div>
                                <div class="text-subtitle-2 font-weight-bold">CUIP: {{ respetoPrincipiosValores.Evaluado[0].CUIP }}</div>
                                <v-chip v-if="respetoPrincipiosValores.estatusEvaluacion === 'Evaluación Pendiente'" color="error"
                                    >Evaluación pendiente</v-chip
                                >
                                <v-chip v-else color="success">Evaluación realizada</v-chip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" align="center">
                                            <v-btn color="secondary" @click="openRespetoPrincipiosValoresDialog(respetoPrincipiosValores)"
                                                >Realizar evaluación</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <div class="text-h6">No cuenta con evaluaciones de respeto a los principios y valores {{ getEstatusText }}.</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <evaluacion-respeto-principios-dialog-component
            :active="respetoPrincipiosDialog"
            @close="respetoPrincipiosDialog = false"
            :respeto-principios="respetoPrincipios"
        ></evaluacion-respeto-principios-dialog-component>
        <evaluacion-productividad-dialog-component
            :active="productividadDialog"
            @close="productividadDialog = false"
            :productividad="productividad"
        ></evaluacion-productividad-dialog-component>
        <evaluacion-anual-dialog-component
            :active="evaluacionAnualDialog"
            @close="evaluacionAnualDialog = false"
            :evaluacion-anual="evaluacionAnual"
        ></evaluacion-anual-dialog-component>
        <evaluacion-desempenio-institucional-dialog-component
            :id-personal="personal.idPersonal"
            :active="desempeñoInstitucionalDialog"
            :desempenio-institucional="selectedDesempeñoInstitucional"
            @close="desempeñoInstitucionalDialog = false"
        ></evaluacion-desempenio-institucional-dialog-component>
        <evaluacion-rendimiento-profesionalismo-dialog-component
            :id-personal="personal.idPersonal"
            :active="rendimientoProfesionalismoDialog"
            :rendimiento-profesionalismo="selectedRendimientoProfesionalismo"
            @close="rendimientoProfesionalismoDialog = false"
        ></evaluacion-rendimiento-profesionalismo-dialog-component>
        <evaluacion-respeto-principios-valores-dialog-component
            :id-personal="personal.idPersonal"
            :active="respetoPrincipiosValoresDialog"
            :respeto-principios-valores="selectedRespetoPrincipiosValores"
            @close="respetoPrincipiosValoresDialog = false"
        ></evaluacion-respeto-principios-valores-dialog-component>
    </v-card>
</template>
<script>
    import EvaluacionRespetoPrincipiosDialogComponent from '../../components/desarrollo_policial/EvaluacionRespetoPrincipiosDialogComponent.vue'
    import EvaluacionProductividadDialogComponent from '../../components/desarrollo_policial/EvaluacionProductividadDialogComponent.vue'
    import EvaluacionAnualDialogComponent from '../desarrollo_policial/EvaluacionAnualDialogComponent.vue'
    import EvaluacionRespetoPrincipiosValoresDialogComponent from '../../components/desarrollo_policial/EvaluacionRespetoPrincipiosValoresDialogComponent.vue'
    import EvaluacionDesempenioInstitucionalDialogComponent from '../../components/desarrollo_policial/EvaluacionDesempenioInstitucionalDialogComponent.vue'
    import EvaluacionRendimientoProfesionalismoDialogComponent from '../../components/desarrollo_policial/EvaluacionRendimientoProfesionalismoDialogComponent.vue'

    import { mapActions, mapGetters } from 'vuex'

    export default {
        components: {
            EvaluacionRespetoPrincipiosDialogComponent,
            EvaluacionProductividadDialogComponent,
            EvaluacionAnualDialogComponent,
            EvaluacionRespetoPrincipiosValoresDialogComponent,
            EvaluacionDesempenioInstitucionalDialogComponent,
            EvaluacionRendimientoProfesionalismoDialogComponent,
        },

        async mounted() {},

        props: {
            personal: {
                type: Object,
            },

            evaluaciones: {
                type: Object,
            },
        },

        data() {
            return {
                overlay: false,

                respetoPrincipiosDialog: false,
                selectedRespetoPrincipios: null,

                productividadDialog: false,
                selectedProductividad: null,

                evaluacionAnualDialog: false,

                selectedDesempeñoInstitucional: null,
                desempeñoInstitucionalDialog: false,

                selectedRendimientoProfesionalismo: null,
                rendimientoProfesionalismoDialog: false,

                selectedRespetoPrincipiosValores: null,
                respetoPrincipiosValoresDialog: false,

                filtroEstatus: 'Evaluación Pendiente',
            }
        },

        computed: {
            ...mapGetters('respetoPrincipios', {
                respetoPrincipios: 'getRespetoPrincipiosSingle',
            }),

            ...mapGetters('productividad', {
                productividad: 'getProductividadSingle',
            }),

            ...mapGetters('evaluacionAnual', {
                evaluacionAnual: 'getEvaluacionAnualSingle',
            }),

            ...mapGetters('reactivoPrincipio', {
                reactivos: 'getReactivoPrincipio',
            }),

            ...mapGetters('indicadorEvaluacion', {
                indicadores: 'getIndicadorEvaluacion',
            }),

            ...mapGetters('actosRelevantesEvaluacion', {
                actosRelevantes: 'getActosRelevantesEvaluacion',
            }),

            ...mapGetters('descripcionCriterio', {
                descripcionCriterio: 'getDescripcionCriterio',
            }),

            ...mapGetters('valoresCapacidades', {
                valoresCapacidades: 'getValoresCapacidades',
            }),

            ...mapGetters('principiosConstitucionales', {
                principiosConstitucionales: 'getPrincipiosConstitucionales',
            }),

            ...mapGetters('estatusEvaluacion', {
                estatusEvaluacion: 'getEstatusEvaluacion',
            }),

            ...mapGetters('reactivosEvaluacion', {
                reactivosDesempenioInstitucional: 'getReactivosDesempenioInstitucional',
                reactivosRendimientoProfesionalismo: 'getReactivosRendimientoProfesionalismo',
            }),

            ...mapGetters('desempeñoInstitucional', {
                desempeñoInstitucionalPersonal: 'getDesempeñoInstitucionalPersonal',
            }),

            ...mapGetters('rendimientoProfesionalismo', {
                rendimientoProfesionalismoPersonal: 'getRendimientoProfesionalismoPersonal',
            }),

            ...mapGetters('respetoPrincipiosValores', {
                respetoPrincipiosValoresPersonal: 'getRespetoPrincipiosValoresPersonal',
            }),

            getEstatusText() {
                if (this.filtroEstatus == 1) {
                    return 'pendientes'
                } else return 'finalizadas'
            },

            filteredEstatusEvaluacion() {
                return this.estatusEvaluacion.filter(estatus => {
                    return estatus.id != 2
                })
            },

            filteredProductividad() {
                if (this.evaluaciones.Productividad) {
                    return this.evaluaciones.Productividad.filter(evaluacion => {
                        return evaluacion.estatusEvaluacion === this.filtroEstatus
                    })
                }
            },

            filteredRespetoPrincipios() {
                if (this.evaluaciones.RespetoPrincipios) {
                    return this.evaluaciones.RespetoPrincipios.filter(evaluacion => {
                        return evaluacion.estatusEvaluacion === this.filtroEstatus
                    })
                }
            },

            filteredDesmpeñoInstitucional() {
                if (this.evaluaciones.DesempeñoInstitucional) {
                    return this.evaluaciones.DesempeñoInstitucional.filter(evaluacion => {
                        return evaluacion.estatusEvaluacion === this.filtroEstatus
                    })
                }
            },

            filteredRendimientoProfesionalismo() {
                if (this.evaluaciones.RendimientoProfesionalismo) {
                    return this.evaluaciones.RendimientoProfesionalismo.filter(evaluacion => {
                        return evaluacion.estatusEvaluacion === this.filtroEstatus
                    })
                }
            },

            filteredRespetoPrincipiosValores() {
                if (this.evaluaciones.RespetoPrincipiosValores) {
                    return this.evaluaciones.RespetoPrincipiosValores.filter(evaluacion => {
                        return evaluacion.estatusEvaluacion === this.filtroEstatus
                    })
                }
            },
        },

        methods: {
            ...mapActions('productividad', ['fetchProductividadById']),
            ...mapActions('respetoPrincipios', ['fetchRespetoPrincipiosById']),

            ...mapActions('desempeñoInstitucional', ['fetchDesempeñoInstitucionalById']),
            ...mapActions('rendimientoProfesionalismo', ['fetchRendimientoProfesionalismoById']),
            ...mapActions('respetoPrincipiosValores', ['fetchRespetoPrincipiosValoresById']),
            ...mapActions('evaluacionAnual', ['fetchEvaluacionAnualById']),
            ...mapActions('descripcionCriterio', ['fetchDescripcionCriterio']),

            async evaluacionRespetoPrincipios(respetoPrincipios) {
                await this.fetchRespetoPrincipiosById(respetoPrincipios)

                this.reactivos.forEach(reactivo => {
                    this.respetoPrincipios.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idReactivoPrincipio: reactivo.id,
                        estatus: true,
                    })
                })
                this.selectedRespetoPrincipios = this.respetoPrincipios
                this.respetoPrincipiosDialog = true
            },

            async evaluacionProductividad(productividad) {
                await this.fetchProductividadById(productividad)

                this.productividadDialog = true
            },

            async openEvaluacionAnual(evaluacionAnual) {
                await this.fetchEvaluacionAnualById(evaluacionAnual)
                await this.fetchDescripcionCriterio(this.evaluacionAnual.fk_idCatTipoEvaluacion)
                this.descripcionCriterio.forEach(descripcion => {
                    this.evaluacionAnual.cumplimientoFunciones.push({
                        calificacion: '',
                        fk_idDescripcionCriterio: descripcion.id,
                        estatus: true,
                    })
                })
                this.valoresCapacidades.forEach(valor => {
                    this.evaluacionAnual.evaluacionValores.push({
                        calificacion: '',
                        fk_idCatValoresCapacidad: valor.id,
                        estatus: true,
                    })
                })
                this.principiosConstitucionales.forEach(principio => {
                    this.evaluacionAnual.evaluacionPrincipios.push({
                        calificacion: '',
                        fk_idCatPrincipiosConstitucionales: principio.id,
                        estatus: true,
                    })
                })
                this.principiosConstitucionales.forEach(habilidad => {
                    this.evaluacionAnual.evaluacionHabilidades.push({
                        calificacion: '',
                        fk_idCatHabilidades: habilidad.id,
                        estatus: true,
                    })
                })
                this.evaluacionAnualDialog = true
            },

            async openDesempeñoInstitucionalDialog(evaluacion) {
                await this.fetchDesempeñoInstitucionalById(evaluacion)
                this.reactivosDesempenioInstitucional.forEach(reactivo => {
                    this.desempeñoInstitucionalPersonal.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idReactivoEvaluacion: reactivo.id,
                        estatus: true,
                    })
                })
                this.selectedDesempeñoInstitucional = this.desempeñoInstitucionalPersonal
                this.desempeñoInstitucionalDialog = true
            },

            async openRendimientoProfesionalismoDialog(evaluacion) {
                await this.fetchRendimientoProfesionalismoById(evaluacion)

                this.reactivosRendimientoProfesionalismo.forEach(reactivo => {
                    this.rendimientoProfesionalismoPersonal.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idReactivoEvaluacion: reactivo.id,
                        estatus: true,
                    })
                })

                this.selectedRendimientoProfesionalismo = this.rendimientoProfesionalismoPersonal
                this.rendimientoProfesionalismoDialog = true
            },

            async openRespetoPrincipiosValoresDialog(evaluacion) {
                await this.fetchRespetoPrincipiosValoresById(evaluacion)

                this.reactivos.forEach(reactivo => {
                    this.respetoPrincipiosValoresPersonal.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idReactivoValor: reactivo.id,
                        estatus: true,
                    })
                })

                this.selectedRespetoPrincipiosValores = this.respetoPrincipiosValoresPersonal
                this.respetoPrincipiosValoresDialog = true
            },

            getFullName(person) {
                if (person.segundoNombre == '') {
                    return person.apaterno + ' ' + person.amaterno + ' ' + person.Nombre
                } else {
                    return person.apaterno + ' ' + person.amaterno + ' ' + person.Nombre + ' ' + person.segundoNombre
                }
            },
        },
    }
</script>
