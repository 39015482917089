import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        cargadorPersonal: [],
    },

    getters: {
        getCargadorPersonal: state => {
            return state.cargadorPersonal
        },
    },

    mutations: {
        SET_CARGADOR_PERSONAL(state, cargadorPersonal) {
            state.cargadorPersonal = cargadorPersonal
        },
    },

    actions: {
        fetchCargadorPersonal: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/personal/' + idPersonal + '/cargadores')
                .then(response => {
                    commit('SET_CARGADOR_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCargadorPersonal: async function({ commit }, cargadorPersonal) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/personal/' + cargadorPersonal.fk_idPersonal + '/cargadores', cargadorPersonal)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateCargadorPersonal: async function({ commit }, cargadorPersonal) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/personal/' + cargadorPersonal.idPersonal + '/cargadores/' + cargadorPersonal.id, cargadorPersonal)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        cancelCargadorPersonal: async function({ commit }, cargadorPersonal) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/personal/' + cargadorPersonal.idPersonal + '/cargadores/' + cargadorPersonal.id + '/cancelacion')
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
