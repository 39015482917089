import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        armaLoc: [],
    },

    getters: {
        getArmaLoc: state => {
            return state.armaLoc
        },
    },

    mutations: {
        SET_ARMAS: (state, armaLoc) => {
            state.armaLoc = armaLoc
        },
    },

    actions: {
        fetchArmaLoc: async function({ commit }, idArma) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/armas/' + idArma + '/armasLoc')
                .then(response => {
                    commit('SET_ARMAS', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveArmaLoc: async function({ commit }, armaLoc) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/armas/' + armaLoc.idArma + '/armasLoc', armaLoc)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateArmaLoc: async function({ commit }, armaLoc) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/armas/' + armaLoc.idArma + '/armasLoc/' + armaLoc.fk_idLoc, armaLoc)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
