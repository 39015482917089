<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-stepper v-model="activeStep">
                    <v-stepper-step step="1">Información de evaluación de desempeño académico</v-stepper-step>
                    <v-stepper-content step="1">
                        <v-container>
                            <v-form v-model="informacionGeneralAcademicoForm">
                                <v-row>
                                    <v-col>
                                        <v-autocomplete
                                            v-model="informacionGeneralAcademico.fk_idPersonalEvaluador"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :items="evaluadores"
                                            item-value="idPersonal"
                                            item-text="CUIP"
                                            item-color="secondary"
                                            :rules="required"
                                            hint="Presione la tecla Enter para realizar la búsqueda"
                                            persistent-hint
                                            @keyup.enter="fetchEvaluadores($event.target.value)"
                                        >
                                            <template v-slot:label>Personal evaluador <span class="red--text">*</span> </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar>
                                                    <v-img v-if="data.item.foto !== ''" :src="data.item.foto"></v-img>
                                                    <v-icon v-else>mdi-account</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.CUIP }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ getFullName(data.item) }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete
                                            v-model="informacionGeneralAcademico.fk_idCatMunicipio"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :items="municipios"
                                            item-value="id"
                                            item-text="nombre"
                                            item-color="secondary"
                                            :rules="required"
                                        >
                                            <template v-slot:label>Municipio <span class="red--text">*</span> </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item.catEstado.title }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="menuFechaInicioAcademico"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="fechaInicioAcademico"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Fecha inicio <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="informacionGeneralAcademico.fechaInicial"
                                                @input="menuFechaInicioAcademico = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="menuFechaFinAcademico"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="fechaFinAcademico"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Fecha fin <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="informacionGeneralAcademico.fechaFin"
                                                @input="menuFechaFinAcademico = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col>
                                        <v-menu
                                            v-model="menuFechaAplicacionAcademico"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="fechaAplicacionAcademico"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                    :rules="required"
                                                >
                                                    <template v-slot:label>Fecha de aplicación <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="informacionGeneralAcademico.fechaAplicacion"
                                                :min="fechaInicioPermitida"
                                                :max="fechaFinalPermitida"
                                                @input="menuFechaAplicacionAcademico = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="informacionGeneralAcademico.institucionSeguridadAspira"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :rules="required"
                                        >
                                            <template v-slot:label
                                                >Institución de seguridad pública a la que aspira <span class="red--text">*</span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Aspirante al puesto de"
                                            v-model="informacionGeneralAcademico.aspiranteAlPuesto"
                                            rounded
                                            filled
                                            dense
                                            color="secondary"
                                            background-color="primary"
                                            :rules="required"
                                        >
                                            <template v-slot:label>Aspirante al puesto de <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" align="center">
                                        <v-btn
                                            color="secondary"
                                            @click="generaEvaluacionesAcademico()"
                                            :disabled="loading || !informacionGeneralAcademicoForm"
                                            >Guardar información</v-btn
                                        >
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-stepper-content>
                    <v-stepper-step step="2">Información de evaluación de desempeño académico</v-stepper-step>
                    <v-stepper-content step="2">
                        <v-container>
                            <v-row>
                                <v-col v-if="respetoPrincipiosValores.length > 0">
                                    <v-card color="secondary">
                                        <v-card-title>Respeto a los principios y valores</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ respetoPrincipiosValores[respetoPrincipiosValores.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="secondary" class="mx-2" @click="openRespetoPrincipiosValoresDialog()"
                                                >Respeto a los principios y valores</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col v-if="rendimientoProfesionalismo.length > 0">
                                    <v-card color="secondary">
                                        <v-card-title>Rendimiento y profesionalismo</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ rendimientoProfesionalismo[rendimientoProfesionalismo.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="secondary" class="mx-2" @click="openRendimientoProfesionalismoDialog()"
                                                >Rendimiento profesionalismo</v-btn
                                            >
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                                <v-col v-if="desempeñoInstitucional.length > 0">
                                    <v-card color="secondary">
                                        <v-card-title>Desempeño institucional</v-card-title>
                                        <v-card-text>
                                            <div class="text-caption">Estatus de evaluación</div>
                                            <div class="text-body-1">
                                                {{ desempeñoInstitucional[desempeñoInstitucional.length - 1].CatEstatusEvaluacion.title }}
                                            </div>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn color="secondary" class="mx-2" @click="openDesempeñoInstitucionalDialog()">Desempeño institucional</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-stepper-content>
                    <evaluacion-desempenio-institucional-dialog-component
                        :id-personal="id"
                        :active="desempeñoInstitucionalDialog"
                        :desempenio-institucional="desempeñoInstitucional[desempeñoInstitucional.length - 1]"
                        @close="desempeñoInstitucionalDialog = false"
                    ></evaluacion-desempenio-institucional-dialog-component>
                    <evaluacion-rendimiento-profesionalismo-dialog-component
                        :id-personal="id"
                        :active="rendimientoProfesionalismoDialog"
                        :rendimiento-profesionalismo="rendimientoProfesionalismo[rendimientoProfesionalismo.length - 1]"
                        @close="rendimientoProfesionalismoDialog = false"
                    ></evaluacion-rendimiento-profesionalismo-dialog-component>
                    <evaluacion-respeto-principios-valores-dialog-component
                        :id-personal="id"
                        :active="respetoPrincipiosValoresDialog"
                        :respeto-principios-valores="respetoPrincipiosValores[respetoPrincipiosValores.length - 1]"
                        @close="respetoPrincipiosValoresDialog = false"
                    ></evaluacion-respeto-principios-valores-dialog-component>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'

    import EvaluacionRespetoPrincipiosValoresDialogComponent from '../../components/desarrollo_policial/EvaluacionRespetoPrincipiosValoresDialogComponent.vue'
    import EvaluacionDesempenioInstitucionalDialogComponent from '../../components/desarrollo_policial/EvaluacionDesempenioInstitucionalDialogComponent.vue'
    import EvaluacionRendimientoProfesionalismoDialogComponent from '../../components/desarrollo_policial/EvaluacionRendimientoProfesionalismoDialogComponent.vue'
    import moment from 'moment'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        components: {
            EvaluacionRespetoPrincipiosValoresDialogComponent,
            EvaluacionDesempenioInstitucionalDialogComponent,
            EvaluacionRendimientoProfesionalismoDialogComponent,
        },

        async mounted() {
            await this.fetchReactivoValor()
            await this.fetchReactivosRendimientoProfesionalismo()
            await this.fetchReactivosDesempenioInstitucional()
        },

        data() {
            return {
                activeStep: 1,
                loading: false,

                menuFechaInicioAcademico: false,
                menuFechaFinAcademico: false,
                menuFechaAplicacionAcademico: false,

                informacionGeneralAcademicoForm: false,

                informacionGeneralAcademico: {
                    fk_idPersonalEvaluador: null,
                    fk_idCatMunicipio: null,
                    fechaInicial: null,
                    fechaFin: null,
                    fechaAplicacion: null,
                    institucionSeguridadAspira: '',
                    aspiranteAlPuesto: 'POLICIA',
                },

                desempeñoInstitucionalDialog: false,
                rendimientoProfesionalismoDialog: false,
                respetoPrincipiosValoresDialog: false,

                required: [v => !!v || 'Este campo es requerido'],
            }
        },

        computed: {
            ...mapGetters('reactivosEvaluacion', {
                reactivosDesempenioInstitucional: 'getReactivosDesempenioInstitucional',
                reactivosRendimientoProfesionalismo: 'getReactivosRendimientoProfesionalismo',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonal',
                personalFotoCuip: 'getPersonalFotoCuip',
                evaluadores: 'getEvaluadores',
            }),

            ...mapGetters('municipio', {
                municipios: 'getMunicipios',
            }),

            ...mapGetters('respetoPrincipiosValores', {
                respetoPrincipiosValores: 'getRespetoPrincipiosValores',
            }),

            ...mapGetters('rendimientoProfesionalismo', {
                rendimientoProfesionalismo: 'getRendimientoProfesionalismo',
            }),

            ...mapGetters('desempeñoInstitucional', {
                desempeñoInstitucional: 'getDesempeñoInstitucional',
            }),

            ...mapGetters('reactivoValor', {
                reactivos: 'getReactivoValor',
            }),

            fechaInicioAcademico: function() {
                return this.informacionGeneralAcademico.fechaInicial ? moment(this.informacionGeneralAcademico.fechaInicial).format('DD/MM/YYYY') : ''
            },

            fechaFinAcademico: function() {
                return this.informacionGeneralAcademico.fechaInicial
                    ? moment(this.informacionGeneralAcademico.fechaInicial)
                          .add(3, 'months')
                          .format('DD/MM/YYYY')
                    : ''
            },

            fechaAplicacionAcademico: function() {
                return this.informacionGeneralAcademico.fechaAplicacion ? moment(this.informacionGeneralAcademico.fechaAplicacion).format('DD/MM/YYYY') : ''
            },

            fechaInicioPermitida: function() {
                return this.fechaInicioAcademico != '' ? moment(this.fechaInicioAcademico, 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
            },

            fechaFinalPermitida: function() {
                return this.fechaInicioPermitida
                    ? moment(this.fechaInicioPermitida)
                          .add(3, 'months')
                          .subtract(1, 'day')
                          .format('YYYY-MM-DD')
                    : ''
            },
        },

        methods: {
            ...mapActions('reactivoValor', ['fetchReactivoValor']),
            ...mapActions('reactivosEvaluacion', ['fetchReactivosDesempenioInstitucional', 'fetchReactivosRendimientoProfesionalismo']),
            ...mapActions('respetoPrincipiosValores', ['saveRespetoPrincipiosValores', 'fetchRespetoPrincipiosValores']),
            ...mapActions('rendimientoProfesionalismo', ['saveRendimientoProfesionalismo', 'fetchRendimientoProfesionalismo']),
            ...mapActions('desempeñoInstitucional', ['saveDesempeñoInstitucional', 'fetchDesempeñoInstitucional']),

            ...mapActions('personal', ['fetchEvaluadores']),

            async generaEvaluacionesAcademico() {
                this.loading = true

                await this.saveRespetoPrincipiosValores({
                    fk_idPersonal: this.id,
                    puntajeTotal: 0,
                    calificacion: '0.0',
                    observaciones: '',
                    fechaAplicacion: this.fechaAplicacionAcademico,
                    fechaInicial: this.fechaInicioAcademico,
                    fechaFin: this.fechaFinAcademico,
                    fk_idResultado: 1,
                    fk_idPersonalEvaluador: this.informacionGeneralAcademico.fk_idPersonalEvaluador,
                    fk_idCatMunicipio: this.informacionGeneralAcademico.fk_idCatMunicipio,
                    institucionSeguridadAspira: this.informacionGeneralAcademico.institucionSeguridadAspira,
                    aspiranteAlPuesto: this.informacionGeneralAcademico.aspiranteAlPuesto,
                    frecuenciasMedicion: [],
                    fk_idCatEstatusEvaluacion: 1,
                })

                await this.saveDesempeñoInstitucional({
                    fk_idPersonal: this.id,
                    puntajeTotal: 0,
                    calificacion: '0.0',
                    observaciones: '',
                    fechaAplicacion: this.fechaAplicacionAcademico,
                    fechaInicial: this.fechaInicioAcademico,
                    fechaFin: this.fechaFinAcademico,
                    fk_idResultado: 1,
                    fk_idPersonalEvaluador: this.informacionGeneralAcademico.fk_idPersonalEvaluador,
                    fk_idCatMunicipio: this.informacionGeneralAcademico.fk_idCatMunicipio,
                    institucionSeguridadAspira: this.informacionGeneralAcademico.institucionSeguridadAspira,
                    aspiranteAlPuesto: this.informacionGeneralAcademico.aspiranteAlPuesto,
                    frecuenciasMedicion: [],
                    fk_idCatEstatusEvaluacion: 1,
                })

                await this.saveRendimientoProfesionalismo({
                    fk_idPersonal: this.id,
                    puntajeTotal: 0,
                    calificacion: '0.0',
                    observaciones: '',
                    fechaAplicacion: this.fechaAplicacionAcademico,
                    fechaInicial: this.fechaInicioAcademico,
                    fechaFin: this.fechaFinAcademico,
                    fk_idResultado: 1,
                    fk_idPersonalEvaluador: this.informacionGeneralAcademico.fk_idPersonalEvaluador,
                    fk_idCatMunicipio: this.informacionGeneralAcademico.fk_idCatMunicipio,
                    institucionSeguridadAspira: this.informacionGeneralAcademico.institucionSeguridadAspira,
                    aspiranteAlPuesto: this.informacionGeneralAcademico.aspiranteAlPuesto,
                    frecuenciasMedicion: [],
                    fk_idCatEstatusEvaluacion: 1,
                })

                await this.fetchRespetoPrincipiosValores(this.id)
                await this.fetchRendimientoProfesionalismo(this.id)
                await this.fetchDesempeñoInstitucional(this.id)

                this.activeStep++
                this.loading = false
            },

            async openDesempeñoInstitucionalDialog() {
                if (this.desempeñoInstitucional[this.desempeñoInstitucional.length - 1].frecuenciasMedicion.length == 0) {
                    this.reactivosDesempenioInstitucional.forEach(reactivo => {
                        this.desempeñoInstitucional[this.desempeñoInstitucional.length - 1].frecuenciasMedicion.push({
                            fk_idCatFrecuencia: null,
                            fk_idReactivoEvaluacion: reactivo.id,
                            estatus: true,
                        })
                    })
                }
                this.desempeñoInstitucionalDialog = true
            },

            async openRendimientoProfesionalismoDialog() {
                if (this.rendimientoProfesionalismo[this.rendimientoProfesionalismo.length - 1].frecuenciasMedicion.length == 0) {
                    this.reactivosRendimientoProfesionalismo.forEach(reactivo => {
                        this.rendimientoProfesionalismo[this.rendimientoProfesionalismo.length - 1].frecuenciasMedicion.push({
                            fk_idCatFrecuencia: null,
                            fk_idReactivoEvaluacion: reactivo.id,
                            estatus: true,
                        })
                    })
                }
                this.rendimientoProfesionalismoDialog = true
            },

            async openRespetoPrincipiosValoresDialog() {
                if (this.respetoPrincipiosValores[this.respetoPrincipiosValores.length - 1].frecuenciasMedicion.length == 0) {
                    this.reactivos.forEach(reactivo => {
                        this.respetoPrincipiosValores[this.respetoPrincipiosValores.length - 1].frecuenciasMedicion.push({
                            fk_idCatFrecuencia: null,
                            fk_idReactivoValor: reactivo.id,
                            estatus: true,
                        })
                    })
                }
                this.respetoPrincipiosValoresDialog = true
            },
        },
    }
</script>
