import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        frecuenciaRespetoPrincipio: [],
    },

    getters: {
        getFrecuenciaRespetoPrincipio: state => {
            return state.frecuenciaRespetoPrincipio
        },
    },

    mutations: {
        SET_FRECUENCIA_RESPETO_PRINCIPIO(state, frecuenciaRespetoPrincipio) {
            state.frecuenciaRespetoPrincipio = frecuenciaRespetoPrincipio
        },
    },

    actions: {
        fetchFrecuenciaRespetoPrincipio: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/frecuenciasrespetoprincipios')
                .then(response => {
                    commit('SET_FRECUENCIA_RESPETO_PRINCIPIO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
