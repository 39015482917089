import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        estatusArma: [],
    },

    getters: {
        getEstatusArma: state => {
            return state.estatusArma
        },
    },

    mutations: {
        SET_ESTATUS_ARMA: (state, estatusArma) => {
            state.estatusArma = estatusArma
        },
    },

    actions: {
        fetchEstatusArma: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/estatus/')
                .then(response => {
                    commit('SET_ESTATUS_ARMA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
