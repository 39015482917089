<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Ingreso a inventario</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        solo
                                        filled
                                        rounded
                                        color="secondary"
                                        background-color="primary"
                                        label="Tipo de entrada"
                                        :items="tipoIngreso"
                                        item-value="id"
                                        item-text="title"
                                        item-color="secondary"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        solo
                                        filled
                                        rounded
                                        color="secondary"
                                        background-color="primary"
                                        label="Seleccione calibre"
                                        :items="calibres"
                                        item-value="id"
                                        item-text="title"
                                        item-color="secondary"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        color="secondary"
                                        background-color="primary"
                                        label="Cantidad a ingresar"
                                        rounded
                                        filled
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text color="error" v-on:click="$router.go(-1)">Cancelar</v-btn>
                        <v-btn color="secondary" v-on:click="triggerEntrada()">Registrar entrada</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                tipoIngreso: [
                    { id: 1, title: 'Ingreso de munición' },
                    { id: 2, title: 'Ingreso de cargador' },
                ],
            }
        },

        computed: {
            ...mapGetters('calibre', {
                calibres: 'getCalibres',
            }),
        },

        methods: {
            triggerEntrada: function() {
                this.SET_SNACKBAR_MESSAGE('Entrada de inventario realizada correctamente')
                this.$router.go(-1)
            },
        },
    }
</script>
