<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="4" v-if="activeStep == 1">
                <v-card color="primary" class="card">
                    <v-card-title class="justify-center">Asignación de accesorios a personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="8">
                                    <v-text-field
                                        v-model="busquedaPersonal.CUIP"
                                        placeholder="Buscar personal"
                                        rounded
                                        filled
                                        background-color="primary"
                                        color="secondary"
                                        :disabled="loadingBusqueda"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn
                                        rounded
                                        block
                                        large
                                        color="secondary"
                                        @click="triggerSearchPersonal()"
                                        :disabled="loadingBusqueda || loading"
                                        :loading="loadingBusqueda"
                                    >
                                        Buscar <v-icon class="ml-2">mdi-magnify</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <v-virtual-scroll :items="personal" item-height="64" height="300">
                                        <template v-slot:default="{ item }">
                                            <v-list-item :key="item.idPersonal" link @click="setPersonal(item)">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-avatar size="80">
                                                        <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                                        <v-icon size="48" v-else>mdi-account</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <div v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                                            <div v-else>CUIP no asignado</div>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle> Estatus: {{ item.CatEstatus.title }} </v-list-item-subtitle>
                                                        <v-list-item-subtitle>
                                                            Vigencia C3:
                                                            {{ item.vigenciaEvaluacionC3 === '' ? 'No registrado' : item.vigenciaEvaluacionC3 }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" v-if="activeStep == 2">
                <v-card color="primary" class="card">
                    <v-card-title>Asignación de items a personal</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col>
                                    <v-text-field
                                        v-model="asignacionItems.fechaSolicitud"
                                        v-mask="'##/##/####'"
                                        label="Fecha de solicitud"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :rules="dateRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        v-model="asignacionItems.fechaAsignacion"
                                        v-mask="'##/##/####'"
                                        label="Fecha de asignación"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                        :rules="dateRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                        label="Folio de resguardo"
                                        v-model="asignacionItems.folioResguardo"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-file-input
                                        label="Evidencia"
                                        v-model="asignacionItems.file"
                                        rounded
                                        filled
                                        dense
                                        color="secondary"
                                        background-color="primary"
                                    ></v-file-input>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select
                                                label="Tipo de item"
                                                v-model="selectedTipoItem"
                                                :items="tipoItem"
                                                item-value="id"
                                                item-text="title"
                                                rounded
                                                filled
                                                dense
                                                color="secondary"
                                                background-color="primary"
                                            >
                                            </v-select>
                                            <v-autocomplete
                                                v-model="selectedItems"
                                                label="Items"
                                                :items="filteredAccesorios"
                                                background-color="primary"
                                                color="secondary"
                                                rounded
                                                filled
                                                dense
                                                :clearable="false"
                                                multiple
                                                hide-selected
                                                return-object
                                                item-text="CatItem.item"
                                                item-value="idAccesorio"
                                            >
                                                <template v-slot:item="{ item, on, attrs }">
                                                    <v-list-item link v-on="on" v-bind="attrs">
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ item.CatItem.item }}</v-list-item-title>
                                                            <v-list-item-subtitle>{{ item.numero }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-textarea
                                                label="Observaciones"
                                                v-model="asignacionItems.observaciones"
                                                rounded
                                                filled
                                                dense
                                                color="secondary"
                                                background-color="primary"
                                                no-resize
                                                rows="4"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="headers" :items="selectedItems" class="transparent" hide-default-footer disable-pagination>
                                        <template v-slot:item.numero="{ item, index }">
                                            <v-text-field
                                                rounded
                                                filled
                                                dense
                                                readonly
                                                label="Numero"
                                                v-model="selectedItems[index].numero"
                                                color="secondary"
                                                background-color="primary"
                                            ></v-text-field>
                                        </template>
                                        <template v-slot:item.fechaFabricacion="{ item, index }">
                                            <v-menu
                                                v-model="menuFechaFabricacion[index]"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                disabled
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="selectedItems[index].fechaFabricacion"
                                                        label="Fecha de fabricación"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="selectedItems[index].fechaFabricacion"
                                                    @input="menuFechaFabricacion[index] = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </template>
                                        <template v-slot:item.aniosVigencia="{ item, index }">
                                            <v-menu
                                                v-model="menuAniosVigencia[index]"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                disabled
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="selectedItems[index].aniosVigencia"
                                                        label="Fecha de vigencia"
                                                        append-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        rounded
                                                        filled
                                                        dense
                                                        color="secondary"
                                                        background-color="primary"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="selectedItems[index].aniosVigencia"
                                                    @input="menuAniosVigencia[index] = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="secondary" @click="triggerSaveAsignacion()" :disabled="loading" :loading="loading">
                            Registrar asignación de items</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        beforeRouteLeave(to, from, next) {
            this.RESET_BUSQUEDA_PERSONAL()
            next()
        },

        data() {
            return {
                activeStep: 1,
                menuFechaSolicitud: false,
                menuFechaAsignacion: false,
                menuFechaFabricacion: [],
                menuAniosVigencia: [],
                loading: false,

                selectedTipoItem: null,

                asignacionItems: {
                    fk_idPersonal: null,
                    fechaSolicitud: '',
                    fechaAsignacion: '',
                    observaciones: '',
                    evidencia: '',
                    folioResguardo: '',
                    itemAsignado: [],
                    file: null,
                },

                headers: [
                    { text: 'Tipo de item', value: 'CatItem.item' },
                    { text: 'Número', value: 'numero' },
                    { text: 'Fecha de fabricación', value: 'fechaFabricacion' },
                    { text: 'Vigencia', value: 'aniosVigencia' },
                ],

                selectedItems: [],

                busquedaPersonal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: '',
                },

                loadingBusqueda: false,

                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('tipoItemArmeria', {
                tipoItem: 'getTipoItem',
            }),

            ...mapGetters('accesorio', {
                accesorios: 'getAccesorios',
            }),

            filteredAccesorios() {
                if (this.accesorios.length > 0) {
                    var filtered_accesorios_activos = this.accesorios.filter(accesorio => {
                        return accesorio.fk_idCatEstatus == 2
                    })

                    return filtered_accesorios_activos.filter(accesorio => {
                        return accesorio.CatItem.fk_idCatTipoItem == this.selectedTipoItem
                    })
                } else return []
            },
        },

        methods: {
            ...mapActions('asignacionItem', ['saveAsignacionItem']),
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            async triggerSaveAsignacion() {
                this.loading = true

                this.selectedItems.forEach(item => {
                    this.asignacionItems.itemAsignado.push({
                        fk_idAccesorio: item.idAccesorio,
                    })
                })

                await this.saveAsignacionItem({
                    fk_idPersonal: this.asignacionItems.fk_idPersonal,
                    fechaSolicitud: this.asignacionItems.fechaSolicitud,
                    fechaAsignacion: this.asignacionItems.fechaAsignacion,
                    observaciones: this.asignacionItems.observaciones,
                    evidencia: this.asignacionItems.file.name,
                    folioResguardo: this.asignacionItems.folioResguardo,
                    itemAsignado: this.asignacionItems.itemAsignado,
                    file: this.asignacionItems.file,
                })

                this.loading = false
                this.RESET_BUSQUEDA_PERSONAL()
                this.$router.push('/armeria/inventario_items')
            },

            buscaPersonal() {
                return new Promise(async () => {
                    setTimeout(await this.searchPersonal(this.busquedaPersonal), 200)
                    this.loadingBusqueda = false
                })
            },

            triggerSearchPersonal() {
                this.loadingBusqueda = true
                if (this.busquedaPersonal.CUIP !== '') {
                    this.buscaPersonal()
                } else {
                    this.loadingBusqueda = false
                    this.RESET_BUSQUEDA_PERSONAL()
                }
            },

            setPersonal(personal) {
                this.asignacionItems.fk_idPersonal = personal.idPersonal

                this.activeStep++
            },
        },
    }
</script>
