import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        personalLoc: [],
    },

    getters: {
        getPersonalLoc: state => {
            return state.personalLoc
        },
    },

    mutations: {
        SET_PERSONAL_LOC: (state, personalLoc) => {
            state.personalLoc = personalLoc
        },
    },

    actions: {
        fetchPersonalLoc: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/personal/' + idPersonal + '/personalLoc')
                .then(response => {
                    commit('SET_PERSONAL_LOC', response.data.data)
                })
                .catch(error => console.log(error))
        },

        savePersonalLoc: async function(context, personalLoc) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/personal/' + personalLoc.idPersonal + '/personalLoc', personalLoc)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Personal asignado a LOC correctamente.', { root: true })
                })
                .catch(error => console.log(error))
        },

        updatePersonalLoc: async function({ commit }, personalLoc) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/personal/' + personalLoc.idPersonal + '/personalLoc/' + personalLoc.fk_idLoc, personalLoc)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
