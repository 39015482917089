import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        resultado: [],
    },

    getters: {
        getResultado: state => {
            return state.resultado
        },
    },

    mutations: {
        SET_RESULTADOS(state, resultado) {
            state.resultado = resultado
        },
    },

    actions: {
        fetchResultados: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/resultados')
                .then(response => {
                    commit('SET_RESULTADOS', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
