<template>
    <v-container>
        <v-row>
            <v-col cols="4" offset="4">
                <v-card color="primary" class="card">
                    <v-card-title class="justify-center font-weight-bold">Movimientos realizados</v-card-title>
                    <v-card-text>
                        <v-list color="primary">
                            <v-list-item v-for="movimiento in movimientos" :key="movimiento.idMovimiento">
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ movimiento.CatTipoMovimiento.title }}</v-list-item-subtitle>
                                    <v-list-item-title>{{ movimiento.fecha }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ movimiento.observaciones }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-avatar color="secondary">
                                        <div class="text-overline white--text">{{ movimiento.cantidad }}</div>
                                    </v-avatar>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <div class="text-h6">Cantidad total en inventario: {{ getStock() }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text class="error--text mb-4 mx-8" v-on:click="$router.go(-1)">Salir</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        async mounted() {
            this.fetchMovimientoEquipamientoByItem(this.id)
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {}
        },

        computed: {
            ...mapGetters('movimientoEquipamiento', {
                movimientos: 'getMovimientoEquipamiento',
            }),
        },

        methods: {
            ...mapActions('movimientoEquipamiento', ['fetchMovimientoEquipamientoByItem']),

            getStock: function() {
                var stock = 0

                this.movimientos.forEach(movimiento => {
                    stock = stock + movimiento.cantidad
                })

                return stock
            },
        },
    }
</script>
