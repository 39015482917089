import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        actosRelevantesEvaluacion: [],
    },

    getters: {
        getActosRelevantesEvaluacion: state => {
            return state.actosRelevantesEvaluacion
        },
    },

    mutations: {
        SET_ACTOS_RELEVANTES_EVALUACION(state, actosRelevantesEvaluacion) {
            state.actosRelevantesEvaluacion = actosRelevantesEvaluacion
        },
    },

    actions: {
        fetchActosRelevantesEvaluacion: async function({ commit }, idEvaluacion) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/evaluacion/' + idEvaluacion + '/actosrelevantesEvaluacion')
                .then(response => {
                    commit('SET_ACTOS_RELEVANTES_EVALUACION', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
