import { axios, fileAxios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        biometricosPersonal: [],
        biometricoToUpdate: null,
    },

    getters: {
        getBiometricosPersonal: state => {
            return state.biometricosPersonal
        },
    },

    mutations: {
        SET_BIOMETRICOS_PERSONAL(state, biometricosPersonal) {
            state.biometricosPersonal = biometricosPersonal
        },

        // SET_AULA_TO_UPDATE(state, biometricoId) {
        //     var biometricoToUpdate = state.biometricos.filter(biometrico => {
        //         return biometrico.id == biometricoId
        //     })

        //     var biometrico = _.cloneDeep(biometricoToUpdate[0])

        //     state.biometricoToUpdate = biometrico
        // },
    },

    actions: {
        fetchBiometricosPersonal: async function({ commit }, idPersonal) {
            await axios
                .get('/PoliciaCuadrantes/EstadoFuerza/personal/' + idPersonal + '/biometricos')
                .then(response => {
                    commit('SET_BIOMETRICOS_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveBiometricoPersonal: async function(context, biometrico) {
            await axios
                .post('/EonCloud/EstadoFuerza/personal/' + biometrico.fk_idPersonal + '/biometricos', biometrico)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', biometrico.file)
                    formData.append('idBiometricoPersonal', response.data.data[0].ID)
                    // console.log(formData)
                    context.dispatch('uploadBiometrico', formData)
                })
                .catch(error => console.log(error))
        },

        updateBiometrico: async function(context, biometrico) {
            await axios
                .put('/EonCloud/EstadoFuerza/personal/' + biometrico.fk_idPersonal + '/biometricos', biometrico)
                .then(response => {
                    var formData = new FormData()
                    formData.append('file', biometrico.file)
                    formData.append('idBiometricoPersonal', biometrico.id)
                    // console.log(formData)
                    context.dispatch('uploadBiometrico', formData)
                })
                .catch(error => console.log(error))
        },

        uploadBiometrico: async function(context, formData) {
            await fileAxios
                .post('/Biometrico/uploadFiles', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Captura de biométrico guardada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },
    },
}
