<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset-md="3">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Línea de tiempo de elemento</v-card-title>
                    <v-card-text>
                        <v-timeline>
                            <v-timeline-item>
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">02 de febrero de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold">Registro de candidato</v-card-title>
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            El elemento se registró a la lista de candidatos.
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                            <v-timeline-item>
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">12 de febrero de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold"
                                        >Validación de documentos</v-card-title
                                    >
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            Se finalizó la carga y validación de documentos para su paso al siguiente filtro.
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                            <v-timeline-item>
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">10 de marzo de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold">Agenda de cita</v-card-title>
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            Se realizó una agenda de cita con fecha de realización
                                            <span class="text-body-2 font-weight-bold primary--text">12 de marzo de 2020 </span> para evaluación médica.
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-timeline-item>
                            <v-timeline-item>
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">12 de marzo de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold"
                                        >Realización de evaluación médica</v-card-title
                                    >
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            Se realizó el registro de evaluación médica
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn>Ver detalles</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-timeline-item>
                            <v-timeline-item>
                                <template v-slot:opposite>
                                    <span class="font-weight-black secondary--text">18 de marzo de 2020</span>
                                </template>
                                <v-card class="secondary" color="primary">
                                    <v-card-title class="justify-center text-subtitle-2 primary--text font-weight-bold"
                                        >Registro en consulta previa realizado</v-card-title
                                    >
                                    <v-card-text>
                                        <div class="text-body-2 primary--text">
                                            Se realizó el registro en consulta previa del candidato.
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="justify-center">
                                        <v-btn>Ver detalles</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-timeline-item>
                        </v-timeline>
                        <v-card-actions class="justify-center mt-4">
                            <v-btn text class="secondary--text">Ver más...</v-btn>
                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),
        },

        methods: {
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
        },
    }
</script>
