<template>
    <v-container>
        <v-row>
            <v-col cols="7" align="right">
                <v-btn class="secondary card py-y mt-2 px-8" rounded elevation="8" to="/enlace_c3/registrar_sancion"
                    >Registrar sanción <v-icon small class="ml-2">mdi-plus</v-icon></v-btn
                >
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="buscaSancion"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar sanción administrativa..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaSancion.length > 0">
                <div class="text-h6 font-weight-bold">{{ sanciones.length }} resultados encontrados con la búsqueda "{{ buscaSancion }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoSanciones, index) in groupedSanciones" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoSanciones.fecha }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoSanciones.sanciones.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="sancion in grupoSanciones.sanciones" :key="sancion.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-book</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">Castro</div>
                                                        <div class="text-subtitle-1 primary--text ">
                                                            Fajardo Jesús Jesusito
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-sheet height="240" color="transparent"></v-sheet>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                buscaSancion: '',
                ordenAlfabetico: 0,

                sanciones: [
                    { id: 1, fecha: '10/02/2021', sanciona: 'Administrativo A', sancionado: 'Personal B', motivo: 'Uniforme incompleto' },
                    { id: 2, fecha: '14/02/2021', sanciona: 'Administrativo A', sancionado: 'Personal C', motivo: 'Llegó tarde a pase de lista' },
                    { id: 3, fecha: '14/02/2021', sanciona: 'Administrativo D', sancionado: 'Personal A', motivo: 'Llegó tarde a pase de lista' },
                ],
            }
        },

        computed: {
            groupedSanciones: function() {
                var result = _(this.sanciones)
                    .groupBy(sancion => sancion.fecha.toUpperCase())
                    .map((sanciones, fecha) => ({ fecha, sanciones }))
                    .value()

                // if (this.ordenAlfabetico == 0) {
                //     result.sort(function(a, b) {
                //         if (a.inicial < b.inicial) {
                //             return -1
                //         }
                //         if (a.inicial > b.inicial) {
                //             return 1
                //         }
                //         return 0
                //     })2
                // } else {
                //     result.sort(function(a, b) {
                //         if (a.inicial > b.inicial) {
                //             return -1
                //         }
                //         if (a.inicial < b.inicial) {
                //             return 1
                //         }
                //         return 0
                //     })
                // }
                return result
            },
        },
    }
</script>
