import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        tipoConvocatoria: [],
    },

    getters: {
        getTipoConvocatoria: state => {
            return state.tipoConvocatoria
        },
    },
    mutations: {
        SET_TIPO_CONVOCATORIA(state, tipoConvocatoria) {
            state.tipoConvocatoria = tipoConvocatoria
        },
    },
    actions: {
        fetchTipoConvocatoria: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/tipoConvocatoria')
                .then(response => {
                    commit('SET_TIPO_CONVOCATORIA', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
