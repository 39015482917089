import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        capacitacion: [],
    },

    getters: {
        getCapacitacion: state => {
            return state.capacitacion
        },
    },

    mutations: {
        SET_CAPACITACION(state, capacitacion) {
            state.capacitacion = capacitacion
        },
    },

    actions: {
        fetchCapacitacion: async function({ commit }) {
            await axios
                .get('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + idPersonal + '/capacitacion')
                .then(response => {
                    commit('SET_CAPACITACION', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveCapacitacion: async function(context, capacitacion) {
            await axios
                .post('/PoliciaCuadrantes/DesarrolloPolicial/personal/' + capacitacion.fk_idPersonal + '/capacitacion', capacitacion)
                .then(response => {})
                .catch(error => console.log(error))
        },

        updateCapacitacion: async function(context, capacitacion) {
            await axios
                .put(
                    '/PoliciaCuadrantes/DesarrolloPolicial/personal/' + capacitacion.fk_idPersonal + '/capacitacion/' + capacitacion.idCondecoracion,
                    capacitacion
                )
                .then(response => {})
                .catch(error => console.log(error))
        },
    },
}
