import { axios } from '../../plugins/axios'

export default {
    namespaced: true,

    state: {
        factoresMerito: [],
    },

    getters: {
        getFactoresMerito: state => {
            return state.factoresMerito
        },
    },

    mutations: {
        SET_FACTORES_MERTIO(state, factoresMerito) {
            state.factoresMerito = factoresMerito
        },
    },

    actions: {
        fetchFactoresMerito: async function({ commit }) {
            await axios
                .get('')
                .then(response => {
                    commit('SET_FACTORES_MERTIO', response.data.data)
                })
                .catch(error => console.log(error))
        },
    },
}
