<template>
    <v-container>
        <v-row align="center">
            <v-col md="4" offset-md="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Asignación de munición</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 33" size="96" width="12" color="secondary" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption">
                                        PASO {{ activeStep }}
                                        <div v-if="activeStep == 3" class="d-inline">(Opcional)</div>
                                    </div>
                                    <div class="text-subtitle-1 font-weight-bold">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container>
                            <v-row>
                                <transition name="fade" mode="out-in">
                                    <v-col md="12" v-if="activeStep == 1" key="1">
                                        <!-- <v-autocomplete
                                            v-model="selectedPersonal"
                                            :items="personal"
                                            item-text="CUIP"
                                            background-color="primary"
                                            color="secondary"
                                            rounded
                                            filled
                                            dense
                                            clearable
                                            item-value="idPersonal"
                                        >
                                            <template v-slot:label>Buscar por CUIP <span class="red--text">*</span> </template>
                                            <template v-slot:item="{ item }">
                                                <v-list-item-avatar color="accent" class="headline font-weight-light white--text">
                                                    <v-icon color="white">mdi-police-badge</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold">{{
                                                        item.apaterno + ' ' + item.amaterno + ' ' + item.nombre + ' ' + item.segundoNombre
                                                    }}</v-list-item-title>
                                                    <v-list-item-subtitle v-text="item.CUIP"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete> -->
                                        <v-row>
                                            <v-col cols="8">
                                                <v-text-field
                                                    v-model="busquedaPersonal.CUIP"
                                                    placeholder="Buscar personal"
                                                    rounded
                                                    filled
                                                    background-color="primary"
                                                    color="secondary"
                                                    :disabled="loadingBusqueda"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-btn
                                                    rounded
                                                    block
                                                    large
                                                    color="secondary"
                                                    @click="triggerSearchPersonal()"
                                                    :disabled="loadingBusqueda || loading"
                                                    :loading="loadingBusqueda"
                                                >
                                                    Buscar <v-icon class="ml-2">mdi-magnify</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-virtual-scroll :items="personal" item-height="64" height="300">
                                                    <template v-slot:default="{ item }">
                                                        <v-list-item :key="item.idPersonal" link @click="setPersonal(item)">
                                                            <template v-slot:default="{ active }">
                                                                <v-list-item-avatar size="80">
                                                                    <v-img :src="item.foto" v-if="item.foto !== ''"></v-img>
                                                                    <v-icon size="48" v-else>mdi-account</v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <div class="text-subtitle-2 font-weight-bold">{{ getFullName(item) }}</div>
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                        <div v-if="item.CUIP.length > 0">{{ item.CUIP }}</div>
                                                                        <div v-else>CUIP no asignado</div>
                                                                    </v-list-item-subtitle>
                                                                    <v-list-item-subtitle> Estatus: {{ item.CatEstatus.title }} </v-list-item-subtitle>
                                                                    <v-list-item-subtitle>
                                                                        Vigencia C3:
                                                                        {{ item.vigenciaEvaluacionC3 === '' ? 'No registrado' : item.vigenciaEvaluacionC3 }}
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </template>
                                                        </v-list-item>
                                                    </template>
                                                </v-virtual-scroll>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 2" key="2">
                                        <v-date-picker
                                            v-model="municionArma.fechaAsignado"
                                            locale="es"
                                            color="secondary"
                                            :max="getMaxDay"
                                            no-title
                                            full-width
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col md="12" v-if="activeStep == 3" key="3">
                                        <v-row>
                                            <v-col cols="12" class="pb-0">
                                                <v-select
                                                    label="Seleccione un Arma"
                                                    v-model="armaPersonal"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    :items="armasPersonal"
                                                    item-value="fk_idArma"
                                                    item-text="Arma.CatModelo.modelo"
                                                >
                                                    <template v-slot:label>Seleccione un arma <span class="red--text">*</span> </template>
                                                </v-select>
                                            </v-col>
                                            <v-col cols="8" class="py-0">
                                                <v-text-field
                                                    label="Calibre del arma"
                                                    background-color="primary"
                                                    color="secondary"
                                                    :value="calibreArma"
                                                    rounded
                                                    filled
                                                    dense
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="4" class="py-0">
                                                <v-text-field
                                                    background-color="primary"
                                                    color="secondary"
                                                    type="number"
                                                    v-model.number="municionArma.cantidad"
                                                    rounded
                                                    filled
                                                    dense
                                                    autocomplete="off"
                                                    :disabled="!armaPersonal"
                                                >
                                                    <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </transition>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep > 1">
                            <v-row>
                                <v-col md="12" xl="6" offset-xl="3">
                                    <v-btn
                                        block
                                        color="secondary"
                                        v-on:click="activeStepButtonHandler"
                                        :loading="loading"
                                        :disabled="!validActiveStep || loading"
                                        >{{ activeStepButtonLabel }}</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<style>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .card-flat {
        box-shadow: none !important;
        transition: 0.3s;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                activeStep: 1,
                loading: false,
                cuipForm: false,

                selectedPersonal: null,

                armaPersonal: null,

                municionArma: {
                    fk_idArma: 0,
                    fk_idCalibre: null,
                    cantidad: 0,
                    fechaAsignado: '',
                },

                busquedaPersonal: {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: '',
                },

                loadingBusqueda: false,
            }
        },

        beforeRouteLeave(to, from, next) {
            this.RESET_BUSQUEDA_PERSONAL()
            next()
        },

        computed: {
            ...mapGetters('personal', {
                personal: 'getPersonal',
            }),

            ...mapGetters('calibre', {
                calibers: 'getCalibres',
            }),

            ...mapGetters('armaPersonal', {
                armasPersonal: 'getArmasPersonal',
            }),

            getMaxDay: function() {
                return moment().format('YYYY-MM-DD')
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Elegir personal'
                    case 2:
                        return 'Fecha de asignación'
                    case 3:
                        return 'Selección del arma'
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        return true
                    case 2:
                        return true
                    case 3:
                        return true
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 3) {
                    return 'Siguiente paso'
                } else {
                    return 'Asignar municiones'
                }
            },

            calibreArma: function() {
                if (this.armaPersonal) {
                    var arma = this.armasPersonal.find(arma => arma.fk_idArma == this.armaPersonal)
                    return arma.Arma.CatCalibre.title
                } else {
                    return ''
                }
            },
        },

        methods: {
            ...mapActions('armaPersonal', ['fetchArmasPersonal']),
            ...mapActions('municionArma', ['saveMunicionArma']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            activeStepButtonHandler: async function() {
                if (this.activeStep == 1) {
                    this.loading = true
                    await this.fetchArmasPersonal(this.selectedPersonal)
                    if (this.armasPersonal.length > 0) {
                        this.activeStep++
                    } else {
                        this.SET_SNACKBAR_MESSAGE('El elemento seleccionado no cuenta con armas registardas.')
                    }
                    this.loading = false
                } else if (this.activeStep > 1 && this.activeStep < 3) {
                    this.activeStep++
                } else {
                    this.loading = true

                    var weapon = this.armasPersonal.find(weapon => weapon.fk_idArma == this.armaPersonal)

                    await this.saveMunicionArma({
                        fk_idArma: weapon.idArmaPersonal,
                        fk_idCalibre: weapon.Arma.fk_idCalibre,
                        cantidad: this.municionArma.cantidad,
                        fechaAsignado: moment(this.municionArma.fechaAsignado).format('DD/MM/YYYY'),
                    })

                    this.RESET_BUSQUEDA_PERSONAL()
                    this.$router.push('/armeria/inventario_municiones')
                }
            },

            buscaPersonal() {
                return new Promise(async () => {
                    setTimeout(await this.searchPersonal(this.busquedaPersonal), 200)
                    this.loadingBusqueda = false
                })
            },

            triggerSearchPersonal() {
                this.loadingBusqueda = true
                if (this.busquedaPersonal.CUIP !== '') {
                    this.buscaPersonal()
                } else {
                    this.loadingBusqueda = false
                    this.RESET_BUSQUEDA_PERSONAL()
                }
            },

            setPersonal(personal) {
                this.selectedPersonal = personal.idPersonal
                this.activeStepButtonHandler()
            },
        },
    }
</script>
