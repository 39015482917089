<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="7">
                <v-text-field
                    v-model="buscaCandidato"
                    color="secondary"
                    background-color="#f2fff8"
                    rounded
                    filled
                    placeholder="Buscar candidato por nombre, edad, municipio o estado..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row v-if="candidatos.length > 0">
            <v-col cols="12" v-if="buscaCandidato.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredCandidatos.length }} resultados encontrados con la búsqueda "{{ buscaCandidato }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoCandidatos, index) in groupedCandidatos" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoCandidatos.inicial }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoCandidatos.candidatos.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="candidato in grupoCandidatos.candidatos" :key="candidato.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-icon x-large color="secondary">mdi-account</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="12" align="center">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ candidato.apaterno }}</div>
                                                        <div class="text-subtitle-1 primary--text ">
                                                            {{ candidato.amaterno + ' ' + candidato.nombre + ' ' + candidato.segundoNombre }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-sheet height="240" color="transparent"></v-sheet>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="1">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="error--text mx-2 primary"
                                                                    elevation="8"
                                                                    :to="{ name: 'archivarCandidato', params: { id: candidato.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                >
                                                                    <v-icon>mdi-archive-arrow-down</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Archivar candidato</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                    <v-col cols="11" align="right">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="secondary--text mx-2 primary"
                                                                    elevation="8"
                                                                    :to="{
                                                                        name: 'documentosCandidato',
                                                                        params: { id: candidato.idPersonal },
                                                                    }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                >
                                                                    <v-icon>mdi-file-account</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Ver expediente</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="secondary--text mx-2 primary"
                                                                    elevation="8"
                                                                    :to="{ name: 'validaCandidato', params: { id: candidato.idPersonal } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                >
                                                                    <v-icon>mdi-check</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Validar candidato</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row v-else>
            <v-col cols="12" align="center">
                <div class="text-h6">No hay candidatos registrados.</div>
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import _ from 'lodash'

    export default {
        data() {
            return {
                buscaCandidato: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('candidato', {
                candidatos: 'getCandidatos',
            }),

            filteredCandidatos: function() {
                return this.candidatos.filter(candidato => {
                    return (
                        candidato.nombre.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0 ||
                        candidato.segundoNombre.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0 ||
                        candidato.apaterno.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0 ||
                        candidato.amaterno.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0 ||
                        this.getAge(candidato.fechaNacimiento) == this.buscaCandidato
                        // (candidato.CatMunicipioRadica && candidato.CatMunicipioRadica.nombre.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0) ||
                        // (candidato.CatMunicipioRadica &&
                        //     candidato.CatMunicipioRadica.CatEstado.title.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0)
                        // (candidato.CatMunicipioNacimiento &&
                        //     candidato.CatMunicipioNacimiento.nombre.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0) ||
                        // (candidato.CatMunicipioNacimiento &&
                        //     candidato.CatMunicipioNacimiento.CatEstado.title.toLowerCase().indexOf(this.buscaCandidato.toLowerCase()) >= 0)
                    )
                })
            },

            groupedCandidatos: function() {
                var result = _(this.filteredCandidatos)
                    .groupBy(candidato => candidato.apaterno[0].toUpperCase())
                    .map((candidatos, inicial) => ({ inicial, candidatos }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.inicial < b.inicial) {
                            return -1
                        }
                        if (a.inicial > b.inicial) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.inicial > b.inicial) {
                            return -1
                        }
                        if (a.inicial < b.inicial) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },

        methods: {
            getFullName: function(candidato) {
                return candidato.nombre + ' ' + candidato.segundoNombre + ' ' + candidato.apaterno + ' ' + candidato.amaterno
            },
        },
    }
</script>
