import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Portal from '../views/Portal.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Launcher from '../views/Launcher.vue'

import Professionalization from '../views/professionalization/Professionalization.vue'
import DashboardProfesionalizacion from '../views/professionalization/Dashboard.vue'
import Trainers from '../views/professionalization/Trainers.vue'
import Classrooms from '../views/professionalization/Classrooms.vue'
import NewClassroom from '../views/professionalization/NewClassroom.vue'
import UpdateClassroom from '../views/professionalization/UpdateClassroom.vue'
import Courses from '../views/professionalization/Courses.vue'
import Preaspirantes from '../views/professionalization/Preaspirantes.vue'
import DocumentosPreaspirante from '../views/professionalization/DocumentosPreaspirante.vue'
import DocumentosCandidato from '../views/professionalization/DocumentosCandidato.vue'
import EvaluacionMedica from '../views/professionalization/EvaluacionMedica.vue'
import NewTrainer from '../views/professionalization/NewTrainer.vue'
import UpdateTrainer from '../views/professionalization/UpdateTrainer.vue'
import NewCourse from '../views/professionalization/NewCourse.vue'
import UpdateCourse from '../views/professionalization/UpdateCourse.vue'
import PersonalProfesionalizacion from '../views/professionalization/PersonalProfesionalizacion.vue'
import AsignacionCurso from '../views/professionalization/AsignacionCurso.vue'
import AgendaCita from '../views/professionalization/AgendaCita.vue'
import RegistroPreaspirante from '../views/professionalization/RegistroPreaspirante.vue'
import CandidatosProf from '../views/professionalization/Candidatos.vue'
import ValidaCandidato from '../views/professionalization/ValidaCandidato.vue'
import ValidaPreaspirante from '../views/professionalization/ValidaPreaspirante.vue'
import ProgramarCurso from '../views/professionalization/ProgramarCurso.vue'
import CursosProgramados from '../views/professionalization/CursosProgramados.vue'
import EvaluaCurso from '../views/professionalization/EvaluaCurso.vue'
import AgregaDocumentoPersonal from '../views/professionalization/AgregaDocumentoPersonal.vue'
import AgregaEstudioPersonal from '../views/professionalization/AgregaEstudioPersonal.vue'
import SolicitudCursos from '../views/professionalization/SolicitudCursos.vue'
import ArchivarCandidato from '../views/professionalization/ArchivarCandidato.vue'
import ArchivarPreaspirante from '../views/professionalization/ArchivarPreaspirante.vue'
import AgregaReferencia from '../views/professionalization/AgregaReferencia.vue'
import AgregaFamiliarProfesionalizacion from '../views/professionalization/AgregaFamiliar.vue'

import Candidate from '../views/preaspirante/Preaspirante.vue'
import PersonalInfo from '../views/preaspirante/PersonalInfo.vue'

import IndexPersonal from '../views/personal/Index.vue'
import PreaspirantesPersonal from '../views/personal/Preaspirantes.vue'
import AspirantesCuip from '../views/personal/Aspirantes.vue'
import RegistroFotoCuip from '../views/personal/RegistroFotoCuip.vue'
import GeneraDictamen from '../views/personal/GeneraDictamen.vue'
import DashboardPersonal from '../views/personal/DashboardPersonal.vue'
import PersonalPersonal from '../views/personal/Personal.vue'
import CapturaVoz from '../views/personal/CapturaVoz.vue'
import CapturaHuella from '../views/personal/CapturaHuella.vue'
import CapturaFoto from '../views/personal/CapturaFoto.vue'
import DocumentosPersonal from '../views/personal/DocumentosPersonal.vue'
import DocumentosPreaspirantePersonal from '../views/personal/DocumentosPreaspirante.vue'
import AgregaCredencial from '../views/personal/AgregaCredencial.vue'
import LineaTiempo from '../views/personal/LineaTiempo.vue'
import RegistroAreaAdscripcionPersonal from '../views/personal/RegistroAreaAdscripcion.vue'
import ReimpresionCredencial from '../views/personal/ReimpresionCredencial.vue'
import AgregaDocumentoPersonalSite from '../views/personal/AgregaDocumentoPersonal.vue'
import RegistroMediaFiliacion from '../views/personal/RegistroMediaFiliacion.vue'
import RegistroPersonalDependencia from '../views/personal/RegistroPersonal.vue'
import PasswordReset from '../views/personal/ResetPassword.vue'
import AgregaFamiliarPersonal from '../views/personal/AgregaFamiliar.vue'

import Equipamiento from '../views/equipamiento/Equipamiento.vue'
import DashboardEquipamiento from '../views/equipamiento/DashboardEquipamiento.vue'
import EquipmentAssignment from '../views/equipamiento/EquipmentAssignment.vue'
import PersonalEquipamiento from '../views/equipamiento/PersonalEquipamiento.vue'
import EquipoAsignado from '../views/equipamiento/EquipoAsignado.vue'
import Items from '../views/equipamiento/Items.vue'
import EditarItem from '../views/equipamiento/EditarItem.vue'
import RegistroItem from '../views/equipamiento/RegistroItem.vue'
import MovimientoEquipamiento from '../views/equipamiento/MovimientoItem.vue'
import MovimientosRealizados from '../views/equipamiento/Movimientos.vue'
import Equipo from '../views/equipamiento/Equipo.vue'

import DesarrolloProfesional from '../views/desarrollo_profesional/DesarrolloProfesional.vue'
import DashboardDesarrolloProfesional from '../views/desarrollo_profesional/Dashboard.vue'
import PersonalDesarrolloProfesional from '../views/desarrollo_profesional/PersonalDesarrolloProfesional.vue'
import EvaluacionProductividad from '../views/desarrollo_profesional/EvaluacionProductividad.vue'
import EvaluacionRespetoPrincipios from '../views/desarrollo_profesional/EvaluacionRespetoPrincipios.vue'
import SugerenciaCurso from '../views/desarrollo_profesional/SugerenciaCurso.vue'
import AsignaCup from '../views/desarrollo_profesional/AsignaCup.vue'
import VerificacionSancionesAdministrativas from '../views/desarrollo_profesional/VerificacionSancionesAdministrativas.vue'
import DocumentosPersonalDesarrolloPolicial from '../views/desarrollo_profesional/ExpedientePersonal.vue'
import DetalleProductividad from '../views/desarrollo_profesional/DetalleEvaluacionProductividad.vue'
import DetalleRespetoPrincipios from '../views/desarrollo_profesional/DetalleEvaluacionRespetoPrincipios.vue'
import EvaluacionDesempeño from '../views/desarrollo_profesional/EvaluacionDesempeño.vue'
import EvaluacionDesempeñoAnual from '../views/desarrollo_profesional/EvaluacionDesempeñoAnual.vue'
import EvaluacionDesempeñoAcademico from '../views/desarrollo_profesional/EvaluacionDesempeñoAcademico.vue'
import Convocatorias from '../views/desarrollo_profesional/Convocatorias.vue'
import Promociones from '../views/desarrollo_profesional/Promociones.vue'

import PageNotFound from '../views/PageNotFound.vue'

import RecursosHumanos from '../views/recursos_humanos/RecursosHumanos.vue'
import DashboardRH from '../views/recursos_humanos/Dashboard.vue'
import PersonalRH from '../views/recursos_humanos/Personal.vue'
import ActualizaEstatus from '../views/recursos_humanos/ActualizaEstatus.vue'
import AsignaSedePersonal from '../views/recursos_humanos/AsignaSedePersonal.vue'
import RegistroPersonal from '../views/recursos_humanos/RegistroPersonal.vue'
import AsignacionFunciones from '../views/recursos_humanos/AsignacionFunciones.vue'
import AsignacionTurno from '../views/recursos_humanos/AsignacionTurno.vue'
import ExpedientePersonal from '../views/recursos_humanos/ExpedientePersonal.vue'
import AgregaEstudioPersonalRH from '../views/recursos_humanos/AgregaEstudioPersonal.vue'
import RegistroAreaAdscripcion from '../views/recursos_humanos/RegistroAreaAdscripcion.vue'
import LineaTiempoPersonal from '../views/recursos_humanos/LineaTiempo.vue'
import RegistrarBoleta from '../views/recursos_humanos/RegistrarBoleta.vue'
import AgregaDocumentoRH from '../views/recursos_humanos/AgregaDocumentoPersonal.vue'
import RegistroFaltaAdministrativa from '../views/recursos_humanos/RegistrarBoleta.vue'
import AgregaFamiliar from '../views/recursos_humanos/AgregaFamiliar.vue'
import AgregaReferenciaRH from '../views/recursos_humanos/AgregaReferencia.vue'

import Armeria from '../views/armeria/Armeria.vue'
import Dashboard from '../views/armeria/Dashboard.vue'
import RegistroArma from '../views/armeria/RegistroArma.vue'
import AsignacionArma from '../views/armeria/AsignacionArma.vue'
import AsignacionMunicion from '../views/armeria/AsignacionMunicion.vue'
import AsignacionCargador from '../views/armeria/AsignacionCargador.vue'
import AsignacionMunicionCurso from '../views/armeria/AsignacionMunicionCurso.vue'
import PaseListaDiario from '../views/armeria/PaseListaDiario.vue'
import RegistroLoc from '../views/armeria/RegistroLoc.vue'
import Locs from '../views/armeria/Locs.vue'
import EditarLoc from '../views/armeria/EditaLoc.vue'
import InventarioMuniciones from '../views/armeria/InventarioMuniciones.vue'
import EntradaInventario from '../views/armeria/IngresoMunicion.vue'
import RegistroCargador from '../views/armeria/RegistroCargador.vue'
import CatalogoArmas from '../views/armeria/CatalogoArmas.vue'
import InventarioArmas from '../views/armeria/InventarioArmas.vue'
import InventarioItems from '../views/armeria/InventarioItems.vue'
import ConsultaArmas from '../views/armeria/ConsultaStockMovimientosArma.vue'
import AsignacionItems from '../views/armeria/AsignacionItems.vue'
import LocPersonal from '../views/armeria/LocPersonal.vue'

import DashboardEnlace from '../views/enlace/DashboardEnlace.vue'
import TurnaC3 from '../views/enlace/TurnaC3.vue'
import PreaspirantesEnlace from '../views/enlace/Preaspirantes.vue'
import PersonalEnlace from '../views/enlace/Personal.vue'
import c3 from '../views/enlace/Enlace.vue'
import SancionesAdministrativas from '../views/enlace/SancionesAdministrativas.vue'
import RegistrarSancion from '../views/enlace/RegistrarSancion.vue'
import DocumentosPreaspiranteEnlace from '../views/enlace/DocumentosPreaspirante.vue'
import AdscripcionEnlace from '../views/enlace/AdscripcionEnlace.vue'
import AgregaDocumentoEnlace from '../views/enlace/AgregaDocumentoPersonal.vue'
import Aspirantes from '../views/enlace/Aspirantes.vue'
import AgregaReferenciaC3 from '../views/enlace/AgregaReferencia.vue'
import AgregaFamiliarC3 from '../views/enlace/AgregaFamiliar.vue'
import AgregaEstudioPersonalC3 from '../views/enlace/AgregaEstudioPersonal.vue'
import DocumentosPersonalEnlace from '../views/enlace/DocumentosPersonal.vue'

import AgregaEstudiosPersonal from '../views/enlace/AgregaEstudioPersonal.vue'

import Policia from '../views/policia/Policia.vue'

import IndexAsuntosInternos from '../views/asuntos_internos/Index.vue'
import PersonalAsuntosInternos from '../views/asuntos_internos/Personal.vue'

import IndexConsultaPersonal from '../views/consulta_personal/Index.vue'
import BuscadorPersonal from '../views/consulta_personal/Personal.vue'
import ExpedienteBuscadorPersonal from '../views/consulta_personal/ExpedientePersonal.vue'

import { publicPath } from '../../vue.config'
import ValidaPersonalVue from '../views/ValidaPersonal.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Portal',
        component: Portal,
    },
    {
        path: '/register',
        name: 'Registro de preaspirante',
        component: Register,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/valida-personal/:id',
        name: 'valida-personal',
        component: ValidaPersonalVue,
    },
    {
        path: '/policia/:id',
        name: 'policia',
        component: Policia,
        props: castRouteParams,
    },
    {
        path: '/consulta_personal',
        name: ' consulta_personal',
        component: IndexConsultaPersonal,
        children: [
            {
                path: 'personal',
                component: BuscadorPersonal,
                name: 'consulta_personal.buscador_personal',
            },
            {
                path: 'expediente_personal/:id',
                component: ExpedienteBuscadorPersonal,
                name: 'consulta_personal.expediente_personal',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/candidato',
        name: ' Candidato',
        component: Candidate,
        children: [
            {
                path: 'informacion_personal/:id',
                component: PersonalInfo,
                name: 'candidatoInfo',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/personal',
        name: 'Registro de personal',
        component: IndexPersonal,
        children: [
            {
                path: 'preaspirantes',
                component: PreaspirantesPersonal,
                name: 'Preaspirantes',
            },
            {
                path: 'aspirantes',
                component: AspirantesCuip,
                name: 'Aspirantes',
            },
            {
                path: 'personal',
                component: PersonalPersonal,
                name: 'personalPersonal',
            },
            {
                path: 'dashboard',
                component: DashboardPersonal,
                name: 'dashboardPersonal',
            },
            {
                path: 'registro_foto_cuip/:id',
                component: RegistroFotoCuip,
                name: 'registroFotoCuip',
                props: castRouteParams,
            },
            {
                path: 'genera_dictamen/:id',
                component: GeneraDictamen,
                name: 'generaDictamen',
                props: castRouteParams,
            },
            {
                path: 'captura_voz/:id',
                component: CapturaVoz,
                name: 'capturaVoz',
                props: castRouteParams,
            },
            {
                path: 'captura_huella/:id',
                component: CapturaHuella,
                name: 'capturaHuella',
                props: castRouteParams,
            },
            {
                path: 'captura_foto/:id',
                component: CapturaFoto,
                name: 'capturaFoto',
                props: castRouteParams,
            },
            {
                path: 'documentos_personal/:id',
                component: DocumentosPersonal,
                name: 'Expediente de personal',
                props: castRouteParams,
            },
            {
                path: 'documentos_preaspirante/:id',
                component: DocumentosPreaspirantePersonal,
                name: 'documentosPreaspiranteSite',
                props: castRouteParams,
            },
            {
                path: 'agrega_credencial/:id',
                component: AgregaCredencial,
                name: 'Registro de credencial',
                props: castRouteParams,
            },
            {
                path: 'reimpresion_credencial/:id',
                component: ReimpresionCredencial,
                name: 'reimpresionCredencial',
                props: castRouteParams,
            },
            {
                path: 'linea_tiempo/:id',
                component: LineaTiempo,
                name: 'Linea de tiempo',
                props: castRouteParams,
            },
            {
                path: 'registro_area_adscripcion/:id',
                component: RegistroAreaAdscripcionPersonal,
                name: 'registroAreaAdscripcionPersonal',
                props: castRouteParams,
            },
            {
                path: 'agrega_documento_personal/:id',
                component: AgregaDocumentoPersonalSite,
                name: 'agregaDocumentoRegistroPersonal',
                props: castRouteParams,
            },
            {
                path: 'agrega_referencia/:id',
                component: AgregaReferencia,
                name: 'agregaReferenciaSite',
                props: castRouteParams,
            },
            {
                path: 'registro_media_filiacion/:id',
                component: RegistroMediaFiliacion,
                name: 'registro_personal.registro_media_filiacion',
                props: castRouteParams,
            },
            {
                path: 'registro_personal',
                component: RegistroPersonalDependencia,
                name: 'registro_personal.registro_personal',
            },
            {
                path: 'password_reset',
                component: PasswordReset,
                name: 'password_reset',
            },
            {
                path: 'agrega_familiar/:id',
                component: AgregaFamiliarPersonal,
                name: 'registro_personal.agrega_familiar',
                props: castRouteParams,
            },
            {
                path: 'agrega_estudios/:id',
                component: AgregaEstudiosPersonal,
                name: 'registro_personal.agrega_estudios',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/launcher',
        name: 'Launcher',
        component: Launcher,
    },
    {
        path: '/equipamiento',
        name: 'Equipamiento',
        component: Equipamiento,
        children: [
            {
                path: 'dashboard',
                name: 'dashboardEquipamiento',
                component: DashboardEquipamiento,
            },
            {
                path: 'personal',
                name: 'personalEquipamiento',
                component: PersonalEquipamiento,
            },
            {
                path: 'asignacion_equipamiento/:id',
                name: 'asignaEquipamiento',
                component: EquipmentAssignment,
                props: castRouteParams,
            },
            {
                path: 'equipamiento_asignado/:id',
                name: 'verEquipamiento',
                component: EquipoAsignado,
                props: castRouteParams,
            },
            {
                path: 'items',
                name: 'items',
                component: Items,
            },
            {
                path: 'equipo',
                name: 'equipo',
                component: Equipo,
            },
            {
                path: 'registro_item',
                name: 'registroItem',
                component: RegistroItem,
            },
            {
                path: 'editar_item/:id',
                name: 'editarItem',
                component: EditarItem,
                props: castRouteParams,
            },
            {
                path: 'movimiento_equipamiento/:id',
                name: 'movimientoEquipamiento',
                component: MovimientoEquipamiento,
                props: castRouteParams,
            },
            {
                path: 'movimientos_realizados/:id',
                name: 'listaMovimientos',
                component: MovimientosRealizados,
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/desarrollo_profesional',
        name: 'Desarrollo policial',
        component: DesarrolloProfesional,
        children: [
            {
                path: 'dashboard',
                name: 'dashboardDesarrolloProfesional',
                component: DashboardDesarrolloProfesional,
            },
            {
                path: 'personal',
                name: 'personalDesarrolloProfesional',
                component: PersonalDesarrolloProfesional,
            },
            {
                path: 'convocatorias',
                name: 'desarrollo_policial.convocatorias',
                component: Convocatorias,
            },
            {
                path: 'promociones',
                name: 'desarrollo_policial.promociones',
                component: Promociones,
            },
            {
                path: 'evaluacion_productividad/:id',
                component: EvaluacionProductividad,
                name: 'evaluacionProductividad',
                props: castRouteParams,
            },
            {
                path: 'evaluacion_respeto_principios/:id',
                component: EvaluacionRespetoPrincipios,
                name: 'evaluacionRespetoPrincipios',
                props: castRouteParams,
            },
            {
                path: 'sugerencia_curso',
                component: SugerenciaCurso,
                name: 'sugerirCurso',
                props: true,
            },
            {
                path: 'asigna_cup',
                component: AsignaCup,
                name: 'asignaCup',
                props: true,
            },
            {
                path: 'verificacion_sanciones_administrativas',
                component: VerificacionSancionesAdministrativas,
                name: 'verificacionSancionesAdministrativas',
                props: castRouteParams,
            },
            {
                path: 'documentos_personal/:id',
                component: DocumentosPersonalDesarrolloPolicial,
                name: 'documentosPersonalDesarrollo',
                props: castRouteParams,
            },
            {
                path: 'detalle_productividad',
                component: DetalleProductividad,
                name: 'detalleProductividad',
                props: true,
            },
            {
                path: 'detalle_respeto_principios',
                component: DetalleRespetoPrincipios,
                name: 'detalleRespetoPrincipios',
                props: true,
            },
            {
                path: 'evaluacion_desempenio/:id',
                component: EvaluacionDesempeño,
                name: 'evaluacion_desempenio',
                props: castRouteParams,
            },
            {
                path: 'evaluacion_desempenio_anual/:id',
                component: EvaluacionDesempeñoAnual,
                name: 'evaluacion_desempenio_anual',
                props: castRouteParams,
            },
            {
                path: 'evaluacion_desempenio_academico/:id',
                component: EvaluacionDesempeñoAcademico,
                name: 'evaluacion_desempenio_academico',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/profesionalizacion',
        name: 'Profesionalización',
        component: Professionalization,
        children: [
            {
                path: 'dashboard',
                component: DashboardProfesionalizacion,
                name: 'profesionalizacion.dashboard',
            },
            {
                path: 'capacitadores',
                component: Trainers,
                name: 'Capacitadores',
            },
            {
                path: 'solicitud_cursos',
                component: SolicitudCursos,
                name: 'Solicitudes de cursos',
            },
            {
                path: 'registro_capacitador',
                component: NewTrainer,
                name: 'newTrainer',
            },
            {
                path: 'edita_capacitador/:id',
                component: UpdateTrainer,
                name: 'updateTrainer',
                props: castRouteParams,
            },
            {
                path: 'aulas',
                component: Classrooms,
                name: 'Aulas',
            },
            {
                path: 'registro_aula',
                component: NewClassroom,
                name: 'newClassroom',
            },
            {
                path: 'edita_aula/:id',
                component: UpdateClassroom,
                name: 'updateClassroom',
                props: castRouteParams,
            },
            {
                path: 'cursos',
                component: Courses,
                name: 'Cursos',
            },
            {
                path: 'registro_curso',
                component: NewCourse,
                name: 'newCourse',
            },
            {
                path: 'edita_curso/:id',
                component: UpdateCourse,
                name: 'updateCourse',
                props: castRouteParams,
            },
            {
                path: 'asignacion_curso/:id',
                component: AsignacionCurso,
                name: 'asignacionCurso',
                props: castRouteParams,
            },
            {
                path: 'programar_curso/:id',
                component: ProgramarCurso,
                name: 'programarCurso',
                props: castRouteParams,
            },
            {
                path: 'cursos_programados',
                component: CursosProgramados,
                name: 'cursosProgramados',
            },
            {
                path: 'evalua_curso',
                component: EvaluaCurso,
                name: 'evaluaCurso',
            },
            {
                path: 'preaspirantes',
                component: Preaspirantes,
                name: 'Preaspirantes',
            },
            {
                path: 'agenda_cita/:id',
                component: AgendaCita,
                name: 'agendaCita',
                props: castRouteParams,
            },
            {
                path: 'documentos_preaspirante/:id',
                component: DocumentosPreaspirante,
                name: 'documentosPreaspirante',
                props: castRouteParams,
            },
            {
                path: 'documentos_candidato/:id',
                component: DocumentosCandidato,
                name: 'documentosCandidato',
                props: castRouteParams,
            },
            {
                path: 'evaluacion_medica/:id',
                component: EvaluacionMedica,
                name: 'evaluacionMedica',
                props: castRouteParams,
            },
            {
                path: 'personal',
                component: PersonalProfesionalizacion,
                name: 'personalProfesionalizacion',
            },
            {
                path: 'registro_preaspirante',
                component: RegistroPreaspirante,
                name: 'registroPreaspirante',
            },
            {
                path: 'candidatos',
                component: CandidatosProf,
                name: 'candidatosProfesionalizacion',
            },
            {
                path: 'valida_candidato/:id',
                component: ValidaCandidato,
                name: 'validaCandidato',
                props: castRouteParams,
            },
            {
                path: 'valida_preaspirante/:id',
                component: ValidaPreaspirante,
                name: 'validaPreaspirante',
                props: castRouteParams,
            },
            {
                path: 'evaluacion_curso/:id',
                component: EvaluaCurso,
                name: 'evaluarCurso',
                props: castRouteParams,
            },
            {
                path: 'agrega_documento_personal/:id',
                component: AgregaDocumentoPersonal,
                name: 'agregaDocumentoPersonal',
                props: castRouteParams,
            },
            {
                path: 'agrega_estudio_personal/:id',
                component: AgregaEstudioPersonal,
                name: 'agregaEstudioPersonal',
                props: castRouteParams,
            },
            {
                path: 'archivar_candidato/:id',
                component: ArchivarCandidato,
                name: 'archivarCandidato',
                props: castRouteParams,
            },
            {
                path: 'archivar_preaspirante/:id',
                component: ArchivarPreaspirante,
                name: 'archivarPreaspirante',
                props: castRouteParams,
            },
            {
                path: 'agrega_referencia/:id',
                component: AgregaReferencia,
                name: 'agregaReferencia',
                props: castRouteParams,
            },
            {
                path: 'agrega_familiar/:id',
                component: AgregaFamiliarProfesionalizacion,
                name: 'profesionalizacion.agrega_familiar',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/enlace_c3',
        name: 'C3',
        component: c3,
        children: [
            {
                path: 'dashboard',
                component: DashboardEnlace,
                name: 'dashboardEnlace',
            },
            {
                path: 'preaspirantes',
                component: PreaspirantesEnlace,
                name: 'preaspirantesEnlace',
            },
            {
                path: 'aspirantes',
                component: Aspirantes,
                name: 'aspirantes',
            },
            {
                path: 'personal',
                component: PersonalEnlace,
                name: 'personalEnlace',
            },
            {
                path: 'documentos_preaspirante/:id',
                component: DocumentosPreaspiranteEnlace,
                name: 'documentosPreaspiranteEnlace',
                props: castRouteParams,
            },
            {
                path: 'turna_c3/:id',
                component: TurnaC3,
                name: 'turnaC3',
                props: castRouteParams,
            },
            {
                path: 'documentos_personal/:id',
                component: DocumentosPersonalEnlace,
                name: 'documentosPersonal',
                props: castRouteParams,
            },
            {
                path: 'sanciones_administrativas',
                component: SancionesAdministrativas,
                name: 'sancionesAdministrativas',
            },
            {
                path: 'registrar_sancion',
                component: RegistrarSancion,
                name: 'registrarSancion',
            },
            {
                path: 'adscripcion_enlace/:id',
                component: AdscripcionEnlace,
                name: 'adscripcionEnlace',
                props: castRouteParams,
            },
            {
                path: 'agrega_documento_enlace/:id',
                component: AgregaDocumentoEnlace,
                name: 'agregaDocumentoEnlace',
                props: castRouteParams,
            },
            {
                path: 'agrega_familiar/:id',
                component: AgregaFamiliarC3,
                name: 'enlace_c3.agrega_familiar',
                props: castRouteParams,
            },
            {
                path: 'agrega_referencia/:id',
                component: AgregaReferenciaC3,
                name: 'enlace_c3.agrega_referencia',
                props: castRouteParams,
            },
            {
                path: 'agrega_estudio_personal/:id',
                component: AgregaEstudioPersonalC3,
                name: 'agregaEstudioPersonalC3',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/recursos_humanos',
        name: 'Recursos humanos',
        component: RecursosHumanos,
        children: [
            {
                path: 'dashboard',
                component: DashboardRH,
                name: 'dashboardRH',
            },
            {
                path: 'actualiza_estatus/:id',
                component: ActualizaEstatus,
                name: 'actualizaEstatus',
                props: castRouteParams,
            },
            {
                path: 'personal',
                component: PersonalRH,
                name: 'Personal',
            },
            {
                path: 'asigna_sede_personal/:id',
                component: AsignaSedePersonal,
                name: 'asignaSedePersonal',
                props: castRouteParams,
            },
            {
                path: 'expediente_personal/:id',
                component: ExpedientePersonal,
                name: 'expedientePersonalRH',
                props: castRouteParams,
            },
            {
                path: 'registro_personal',
                component: RegistroPersonal,
                name: 'registroPersonal',
            },
            {
                path: 'asignacion_funciones/:id',
                component: AsignacionFunciones,
                name: 'asignacionFunciones',
                props: castRouteParams,
            },
            {
                path: 'asignacion_turno/:id',
                component: AsignacionTurno,
                name: 'asignacionTurno',
                props: castRouteParams,
            },
            {
                path: 'agrega_estudio_personal/:id',
                component: AgregaEstudioPersonalRH,
                name: 'agregaEstudioPersonalRH',
                props: castRouteParams,
            },
            {
                path: 'registro_area_adscripcion/:id',
                component: RegistroAreaAdscripcion,
                name: 'registroAreaAdscripcion',
                props: castRouteParams,
            },
            {
                path: 'linea_tiempo_personal/:id',
                component: LineaTiempoPersonal,
                name: 'lineaTiempoPersonal',
                props: castRouteParams,
            },
            {
                path: 'registrar_boleta',
                component: RegistrarBoleta,
                name: 'registrarBoleta',
            },
            {
                path: 'agrega_documento_personal/:id',
                component: AgregaDocumentoRH,
                name: 'recursos_humanos.agrega_documento_personal',
                props: castRouteParams,
            },
            {
                path: 'agrega_referencia_personal/:id',
                component: AgregaReferenciaRH,
                name: 'recursos_humanos.agrega_referencia_personal',
                props: castRouteParams,
            },
            {
                path: 'registro_falta_administrativa/:id',
                component: RegistroFaltaAdministrativa,
                name: 'recursos_humanos.registro_falta_administrativa',
                props: castRouteParams,
            },
            {
                path: 'agrega_familiar/:id',
                component: AgregaFamiliar,
                name: 'recursos_humanos.agrega_familiar',
                props: castRouteParams,
            },
        ],
    },
    {
        path: '/armeria',
        name: 'Armeria',
        component: Armeria,
        children: [
            {
                path: 'dashboard',
                component: Dashboard,
                name: 'dashboardArmeria',
            },
            {
                path: 'registro_arma',
                component: RegistroArma,
                name: 'registroArma',
            },
            {
                path: 'asignacion_arma',
                component: AsignacionArma,
                name: 'asignacionArma',
            },
            {
                path: 'asignacion_municion',
                component: AsignacionMunicion,
                name: 'asignacionMunicion',
            },
            {
                path: 'asignacion_items',
                component: AsignacionItems,
                name: 'asignacion_items',
            },
            {
                path: 'asignacion_cargador',
                component: AsignacionCargador,
                name: 'asignacionCargador',
            },
            {
                path: 'asignacion_municion_curso',
                component: AsignacionMunicionCurso,
                name: 'asignacionMunicionCurso',
            },
            {
                path: 'pase_lista_diario',
                component: PaseListaDiario,
                name: 'paseListaDiario',
            },
            {
                path: 'registro_loc',
                component: RegistroLoc,
                name: 'registroLoc',
            },
            {
                path: 'catalogo_loc',
                component: Locs,
                name: 'locs',
            },
            {
                path: 'editar_loc',
                component: EditarLoc,
                name: 'editaLoc',
            },
            {
                path: 'inventario_municiones',
                component: InventarioMuniciones,
                name: 'inventarioMuniciones',
            },
            {
                path: 'entrada',
                component: EntradaInventario,
                name: 'entradaInventario',
            },
            {
                path: 'registro_cargador',
                component: RegistroCargador,
                name: 'registro_cargador',
            },
            {
                path: 'catalogo_armas',
                component: CatalogoArmas,
                name: 'catalogo_armas',
            },
            {
                path: 'inventario_armas',
                component: InventarioArmas,
                name: 'inventario_armas',
            },
            {
                path: 'inventario_items',
                component: InventarioItems,
                name: 'inventario_items',
            },
            {
                path: 'consulta_armas',
                component: ConsultaArmas,
                name: 'consulta_armas',
            },
            {
                path: 'loc_personal',
                component: LocPersonal,
                name: 'armeria.loc_personal',
            },
        ],
    },
    {
        path: '/asuntos_internos',
        name: 'asuntos_internos',
        component: IndexAsuntosInternos,
        children: [
            {
                path: 'personal',
                component: PersonalAsuntosInternos,
                name: 'asuntos_internos.personal',
            },
        ],
    },
    {
        path: '*',
        component: PageNotFound,
        name: '404',
    },
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: publicPath,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && to.name !== 'Registro de preaspirante' && to.name !== 'Portal' && store.state.token.length == 0) {
        next({ name: 'Login' })
    } else if (
        (localStorage.getItem('perfil') === 'Profesionalización' ||
            localStorage.getItem('perfil') === 'IFCSP_PROFESIONALIZACION' ||
            localStorage.getItem('perfil') === 'IFCSP_RECLUTAMIENTO' ||
            localStorage.getItem('perfil') === 'IFCSP_ADMINISTRADOR') &&
        to.matched[0].path !== '/profesionalizacion' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Personal/Site' ||
            localStorage.getItem('perfil') === 'REP_ADMINISTRADOR' ||
            localStorage.getItem('perfil') === 'REP_REGISTRO' ||
            localStorage.getItem('perfil') === 'REP_CREDENCIALIZACION' ||
            localStorage.getItem('perfil') === 'REP_BIOMETRIA') &&
        to.matched[0].path !== '/personal' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Enlace C3' ||
            localStorage.getItem('perfil') === 'ENLACE C3_CAPTURA DE RESULTADOS' ||
            localStorage.getItem('perfil') === 'ENLACE C3_CAPTURA DE EXPEDIENTE' ||
            localStorage.getItem('perfil') === 'ENLACE C3_ADMINISTRADOR') &&
        to.matched[0].path !== '/enlace_c3' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Recursos Humanos' ||
            localStorage.getItem('perfil') === 'RH_ADMINISTRADOR' ||
            localStorage.getItem('perfil') === 'RH_TRAMITES ADMINISTRATIVOS' ||
            localStorage.getItem('perfil') === 'RH_CAMBIO DE ESTATUS/ADSCRIPCION' ||
            localStorage.getItem('perfil') === 'RH_REGISTRO DE PERSONAL') &&
        to.matched[0].path !== '/recursos_humanos' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Equipamiento' || localStorage.getItem('perfil') === 'EQUIPAMIENTO_ADMINISTRADOR') &&
        to.matched[0].path !== '/equipamiento' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Armeria' ||
            localStorage.getItem('perfil') === 'ARMERIA_PASE DE LISTA' ||
            localStorage.getItem('perfil') === 'ARMERIA_LOC' ||
            localStorage.getItem('perfil') === 'ARMERIA_PERSONAL LOC' ||
            localStorage.getItem('perfil') === 'ARMERIA_CATALOGOS E INVENTARIO ARMAS' ||
            localStorage.getItem('perfil') === 'ARMERIA_CATALOGOS E INVENTARIO ACCESORIOS') &&
        to.matched[0].path !== '/armeria' &&
        to.path !== '/'
    ) {
        next('')
    } else if (
        (localStorage.getItem('perfil') === 'Desarrollo Profesional' ||
            localStorage.getItem('perfil') === 'DP_ADMINISTRADOR' ||
            localStorage.getItem('perfil') === 'DP_CONTROL DE EVALUACIONES' ||
            localStorage.getItem('perfil') === 'DP_CONDECORACIONES Y CONVOCATORIAS') &&
        to.matched[0].path !== '/desarrollo_profesional' &&
        to.path !== '/'
    ) {
        next('')
    } else if (localStorage.getItem('perfil') === 'Asuntos Internos' && to.matched[0].path !== '/asuntos_internos' && to.path !== '/') {
        next('')
    } else if (localStorage.getItem('perfil') === 'Consejo de Honor y Justicia' && to.matched[0].path !== '/asuntos_internos' && to.path !== '/') {
        next('')
    } else if (localStorage.getItem('perfil') === 'Policia' && to.matched[0].path !== '/policia/:id' && to.path !== '/') {
        next('')
    } else next()
})

router.afterEach((to, from) => {
    // store.state.loading = false
})

function castRouteParams(route) {
    return {
        id: Number(route.params.id),
    }
}

export default router
