import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        municionCurso: [],
    },

    getters: {
        getMunicionCurso: state => {
            return state.municionCurso
        },
    },

    mutations: {
        SET_MUNICION_CURSO(state, municionCurso) {
            state.municionCurso = municionCurso
        },
    },

    actions: {
        fetchMunicionCurso: async function({ commit }, idCurso) {
            await axios
                .get('/PoliciaCuadrantes/Armeria/cursos/' + idCurso + '/municiones')
                .then(response => {
                    commit('SET_MUNICION_CURSO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMunicionCurso: async function({ commit }, municionCurso) {
            await axios
                .post('/PoliciaCuadrantes/Armeria/cursos/' + municionCurso.fk_idCurso + '/municiones', municionCurso)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateMunicionCurso: async function({ commit }, municionCurso) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/cursos/' + municionCurso.fk_idCurso + '/municiones/' + municionCurso.id, municionCurso)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatusMunicionCurso: async function({ commit }, municionCurso) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/cursos/' + municionCurso.fk_idCurso + '/municiones/' + municionCurso.id + '/estatus', {
                    fk_idCatEstatusMunicion: municionCurso.fk_idCatEstatusMunicion,
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateMunicionCursoUtilizada: async function({ commit }, municionCurso) {
            await axios
                .put('/PoliciaCuadrantes/Armeria/cursos/' + municionCurso.fk_idCurso + '/municiones/' + municionCurso.id + '/utilizadas', {
                    fk_idCatMotivoUso: municionCurso.fk_idCatMotivoUso,
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
