<template>
    <v-card class="secondary primary-shadow" :loading="loading">
        <v-card-text>
            <v-list color="transparent">
                <v-list-item-group mandatory>
                    <v-list-item v-on:click="SET_SELECTED_MENU(1)" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-account</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Datos generales
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(2)" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-account-multiple</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Referencias y familiares
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(3)" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-folder</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Documentos
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(4)" v-if="estatus < 8 && perfil !== 'Enlace C3'" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-shield-account</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Desarrollo del elemento
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(8)" v-if="estatus < 7" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-account-alert</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Trámites administrativos
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(5)" v-if="estatus < 7" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-domain</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Puesto y dependencia
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(6)" v-if="estatus < 7" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-information</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Campos registro personal
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(7)" v-if="estatus < 7" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-chart-timeline-variant</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Línea del tiempo
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-on:click="SET_SELECTED_MENU(9)"
                        v-if="
                            estatus < 7 &&
                                perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE' &&
                                perfil !== 'Enlace C3'
                        "
                        :disabled="loading"
                    >
                        <v-list-item-icon><v-icon color="primary">mdi-head-alert</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text"> Media filiación</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="SET_SELECTED_MENU(10)" v-if="perfil == 'Policia'" :disabled="loading">
                        <v-list-item-icon><v-icon color="primary">mdi-file-document-edit</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Evaluaciones
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-on:click="SET_SELECTED_MENU(11)"
                        v-if="
                            estatus < 7 &&
                                perfil !== 'ENLACE C3_ADMINISTRADOR' &&
                                perfil !== 'ENLACE C3_CAPTURA DE RESULTADOS' &&
                                perfil !== 'ENLACE C3_CAPTURA DE EXPEDIENTE' &&
                                perfil !== 'Enlace C3' &&
                                perfil !== 'Policia'
                        "
                        :disabled="loading"
                    >
                        <v-list-item-icon><v-icon color="primary">mdi-account-star</v-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="primary--text">
                                Carrera policial
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapMutations, mapGetters } from 'vuex'

    export default {
        props: {
            estatus: {
                type: Number,
            },

            loading: {
                type: Boolean,
            },
        },

        data() {
            return {
                expedienteMenuTab: null,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),
        },

        methods: {
            ...mapMutations('expediente', ['SET_SELECTED_MENU']),
        },
    }
</script>
