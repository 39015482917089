<template>
    <v-dialog width="480" v-model="active" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn rounded color="secondary" v-on="on" v-bind="attrs">Registrar movimiento</v-btn>
        </template>
        <v-card color="primary" class="rounded-sm">
            <v-card-title>
                <div class="text-h6">Nuevo movimiento de arma</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form v-model="movimientoForm" ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="movimientoArma.fk_idcatTipoMovimiento"
                                    rounded
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    :items="tipoMovimiento"
                                    item-value="id"
                                    item-text="title"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Tipo de movimiento <span class="red--text">*</span> </template>
                                </v-select>
                                <v-select
                                    v-model="marca"
                                    @change="triggerFetchModeloArmas($event)"
                                    rounded
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    :items="marcaArma"
                                    item-value="id"
                                    item-text="title"
                                    item-color="secondary"
                                    :rules="required"
                                >
                                    <template v-slot:label>Marca <span class="red--text">*</span> </template>
                                </v-select>
                                <v-select
                                    v-model="movimientoArma.fk_idCatModelo"
                                    rounded
                                    filled
                                    dense
                                    background-color="primary"
                                    color="secondary"
                                    :items="modeloArma"
                                    item-value="id"
                                    item-text="modelo"
                                    item-color="secondary"
                                    :disabled="loadingModelos"
                                    :rules="required"
                                >
                                    <template v-slot:label>Modelo <span class="red--text">*</span> </template>
                                </v-select>
                                <v-text-field
                                    v-model="movimientoArma.fechaCompra"
                                    v-mask="'##/##/####'"
                                    hint="Formato de fecha: DD/MM/YYYY"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="dateRules"
                                >
                                    <template v-slot:label>Fecha de compra <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-text-field
                                    v-model="movimientoArma.puestaDisposición"
                                    v-mask="'##/##/####'"
                                    hint="Formato de fecha: DD/MM/YYYY"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                    :rules="dateRules"
                                >
                                    <template v-slot:label>Fecha de puesta a disposición <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-text-field
                                    v-model.number="movimientoArma.cantidad"
                                    type="number"
                                    color="secondary"
                                    background-color="primary"
                                    rounded
                                    filled
                                    dense
                                    min="0"
                                    :rules="cantidadRules"
                                >
                                    <template v-slot:label>Cantidad <span class="red--text">*</span> </template>
                                </v-text-field>
                                <v-textarea
                                    label="Observaciones"
                                    v-model="movimientoArma.observaciones"
                                    no-resize
                                    rows="4"
                                    rounded
                                    filled
                                    dense
                                    color="secondary"
                                    background-color="primary"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn rounded color="secondary" :disabled="loading || !movimientoForm" @click="triggerSaveMovimientoArma()" :loading="loading"
                    >Registrar movimiento</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapGetters, mapActions } from 'vuex'

    export default {
        data() {
            return {
                active: false,
                movimientoForm: false,
                loading: false,
                loadingModelos: false,
                marca: null,

                movimientoArma: {
                    fk_idCatModelo: null,
                    fk_idcatTipoMovimiento: null,
                    cantidad: 0,
                    observaciones: '',
                    fechaCompra: null,
                    puestaDisposición: null,
                },

                cantidadRules: [v => v > 0 || 'La cantidad no puede ser cero.'],
                required: [v => !!v || 'Este campo es requerido.'],
                dateRules: [
                    v => !!v || 'Este campo es requerido.',
                    v => {
                        var date = moment(v, 'DD/MM/YYYY')
                        return moment(date, 'DD/MM/YYYY').isValid() || 'El formato de fecha debe ser DD/MM/YYYY'
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('marcaArma', {
                marcaArma: 'getMarcaArma',
            }),

            ...mapGetters('modeloArma', {
                modeloArma: 'getModelosArmas',
            }),

            ...mapGetters('tipoMovimientoEquipamiento', {
                tipoMovimiento: 'getTipoMovimiento',
            }),
        },

        methods: {
            ...mapActions('modeloArma', ['fetchModeloArmas']),
            ...mapActions('movimientoArma', ['saveMovimientoArma', 'fetchMovimientoArma']),
            ...mapActions('arma', ['fetchArmas']),

            closeDialog() {
                this.marca = null
                this.movimientoArma = {
                    fk_idCatModelo: null,
                    fk_idcatTipoMovimiento: null,
                    cantidad: 0,
                    observaciones: '',
                }
                this.$refs.form.resetValidation()
                this.active = false
                this.loading = false
            },

            async triggerFetchModeloArmas(event) {
                this.loadingModelos = true
                await this.fetchModeloArmas(event)
                this.loadingModelos = false
            },

            async triggerSaveMovimientoArma() {
                this.loading = true
                await this.saveMovimientoArma(this.movimientoArma)
                await this.fetchMovimientoArma()
                await this.fetchArmas()
                this.closeDialog()
            },
        },
    }
</script>
