<template>
    <v-container fill-height>
        <v-row v-if="resultadosPersonal.length == 0" justify="center">
            <v-col cols="4">
                <v-card class="card" color="primary">
                    <v-card-title class="justify-center font-weight-bold">Buscar personal</v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="busquedaPersonal.nombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.segundoNombre"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Segundo nombre"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.apaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido paterno"
                        ></v-text-field>
                        <v-text-field
                            v-model="busquedaPersonal.amaterno"
                            color="secondary"
                            background-color="primary"
                            rounded
                            filled
                            label="Apellido materno"
                        ></v-text-field>
                        <v-text-field v-model="busquedaPersonal.curp" color="secondary" background-color="primary" rounded filled label="CURP"></v-text-field>
                        <v-text-field v-model="busquedaPersonal.CUIP" color="secondary" background-color="primary" rounded filled label="CUIP"></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-container class="py-0">
                            <v-row justify="center">
                                <v-col cols="8" align="center">
                                    <v-btn
                                        rounded
                                        block
                                        color="secondary"
                                        @click="triggerSearchPersonal()"
                                        :disabled="loading || emptyForm"
                                        :loading="loading"
                                        >Realizar búsqueda</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-row>
                    <v-col cols="4">
                        <v-btn color="secondary" rounded @click="RESET_BUSQUEDA_PERSONAL()">Realizar nueva búsqueda</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4">
                        <v-text-field
                            label="Filtrar elementos"
                            v-model="filtroPersonal"
                            rounded
                            filled
                            dense
                            color="secondary"
                            background-color="primary"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" lg="4" xl="3" v-for="personal in filteredPersonal" :key="personal.id">
                <v-card class="card" color="secondary" elevation="8">
                    <v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-avatar rounded color="primary" size="80" v-if="personal.foto.length == 0">
                                        <v-icon x-large color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                    <v-img v-else :src="personal.foto" max-height="80" max-width="80"></v-img>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <div class="text-h6 primary--text font-weight-bold">{{ personal.apaterno }}</div>
                                    <div class="text-subtitle-1 primary--text ">
                                        {{ personal.amaterno + ' ' + personal.nombre + ' ' + personal.segundoNombre }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <div class="text-subtitle-1 font-weight-bold mb-2" align="center">Vigencias</div>
                        <alerta-vigencia-cup-personal-component :vigencia-cup="personal.vigenciaCup"></alerta-vigencia-cup-personal-component>
                        <alerta-vigencia-c3-personal-component :vigencia-c3="personal.vigenciaEvaluacionC3"></alerta-vigencia-c3-personal-component>
                        <alerta-vigencia-competencias-basicas-personal-component
                            :vigencia-competencias="personal.vigenciaCompetenciasBasicas"
                        ></alerta-vigencia-competencias-basicas-personal-component>
                        <alerta-vigencia-formacion-inicial-personal-component
                            :vigencia-formacion="personal.vigenciaFormacionInicial"
                        ></alerta-vigencia-formacion-inicial-personal-component>
                    </v-card-text>
                    <v-card-actions class="secondary darken-1">
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="
                                            perfil === 'DP_ADMINISTRADOR' ||
                                                perfil === 'DP_CONDECORACIONES Y CONVOCATORIAS' ||
                                                perfil === 'Desarrollo Profesional'
                                        "
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                @click="openCondecoracionPersonalDialog(personal)"
                                                ><v-icon color="black">mdi-medal</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Condecorar a este elemento</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONTROL DE EVALUACIONES' || perfil === 'Desarrollo Profesional'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{
                                                    path: 'evaluacion_desempenio_academico/' + personal.idPersonal,
                                                    params: { id: personal.idPersonal },
                                                }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-school</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Realizar evaluación de desempeño académico</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONTROL DE EVALUACIONES' || perfil === 'Desarrollo Profesional'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{
                                                    path: 'evaluacion_desempenio_anual/' + personal.idPersonal,
                                                    params: { id: personal.idPersonal },
                                                }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-numeric-1-circle</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Realizar evaluación anual</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONTROL DE EVALUACIONES' || perfil === 'Desarrollo Profesional'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{
                                                    path: 'evaluacion_desempenio/' + personal.idPersonal,
                                                    params: { id: personal.idPersonal },
                                                }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-numeric-3-circle</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Realizar evaluación de desempeño</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONTROL DE EVALUACIONES' || perfil === 'Desarrollo Profesional'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{ name: 'sugerirCurso', params: { personal: personal } }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-comment-quote</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Sugerir curso</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        bottom
                                        color="black"
                                        v-if="perfil === 'DP_ADMINISTRADOR' || perfil === 'DP_CONTROL DE EVALUACIONES' || perfil === 'Desarrollo Profesional'"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{ name: 'asignaCup', params: { personal: personal } }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-card-bulleted</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Asignación de CUP</span>
                                    </v-tooltip>
                                    <v-tooltip bottom color="black">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                class="primary secondary--text mx-2"
                                                elevation="8"
                                                :to="{
                                                    name: 'documentosPersonalDesarrollo',
                                                    params: { id: personal.idPersonal },
                                                }"
                                                icon
                                                v-on="on"
                                                v-bind="attrs"
                                                ><v-icon color="black">mdi-file-account</v-icon></v-btn
                                            >
                                        </template>
                                        <span>Ver expediente del personal</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <asigna-condecoracion-personal-dialog-component
            :active="condecoracionPersonalDialog"
            :personal="selectedPersonal"
            @close="condecoracionPersonalDialog = false"
        ></asigna-condecoracion-personal-dialog-component>
    </v-container>
</template>
<style scoped>
    .fab {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
</style>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import _ from 'lodash'

    import AsignaCondecoracionPersonalDialogComponent from '../../components/desarrollo_policial/condecoraciones/AsignaCondecoracionPersonalDialogComponent.vue'
    import AlertaVigenciaCupPersonalComponent from '../../components/personal/AlertaVigenciaCupPersonalComponent.vue'
    import AlertaVigenciaC3PersonalComponent from '../../components/personal/AlertaVigenciaC3PersonalComponent.vue'
    import AlertaVigenciaCompetenciasBasicasPersonalComponent from '../../components/personal/AlertaVigenciaCompetenciasBasicasPersonalComponent.vue'
    import AlertaVigenciaFormacionInicialPersonalComponent from '../../components/personal/AlertaVigenciaFormacionInicialPersonalComponent.vue'

    export default {
        components: {
            AsignaCondecoracionPersonalDialogComponent,
            AlertaVigenciaCupPersonalComponent,
            AlertaVigenciaC3PersonalComponent,
            AlertaVigenciaCompetenciasBasicasPersonalComponent,
            AlertaVigenciaFormacionInicialPersonalComponent,
        },

        data() {
            return {
                loading: false,
                filtroPersonal: '',
                selectedPersonal: null,
                condecoracionPersonalDialog: false,
            }
        },

        computed: {
            ...mapGetters({
                perfil: 'getPerfil',
            }),

            ...mapGetters('personal', {
                resultadosPersonal: 'getPersonal',
                busquedaPersonal: 'getBusquedaPersonal',
            }),

            ...mapGetters('tipoAmbito', {
                tipoAmbito: 'getTipoAmbito',
            }),

            ...mapGetters('dependencia', {
                dependencias: 'getDependencias',
            }),

            filteredPersonal: function() {
                return this.resultadosPersonal.filter(personal => {
                    return (
                        this.getFullName(personal)
                            .toLowerCase()
                            .indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.CUIP.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.rfc.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0 ||
                        personal.curp.toLowerCase().indexOf(this.filtroPersonal.toLowerCase()) >= 0
                    )
                })
            },

            emptyForm() {
                if (
                    this.busquedaPersonal.nombre === '' &&
                    this.busquedaPersonal.segundoNombre === '' &&
                    this.busquedaPersonal.apaterno === '' &&
                    this.busquedaPersonal.amaterno === '' &&
                    this.busquedaPersonal.curp === '' &&
                    this.busquedaPersonal.CUIP === ''
                ) {
                    return true
                } else return false
            },
        },

        methods: {
            ...mapActions('personal', ['searchPersonal']),
            ...mapMutations(['SET_SNACKBAR_MESSAGE']),
            ...mapMutations('personal', ['RESET_BUSQUEDA_PERSONAL']),

            async triggerSearchPersonal() {
                this.loading = true

                await this.searchPersonal(this.busquedaPersonal)
                if (this.resultadosPersonal.length == 0) {
                    this.SET_SNACKBAR_MESSAGE('Búsqueda sin resultados.')
                }

                this.loading = false
            },

            openCondecoracionPersonalDialog(personal) {
                this.selectedPersonal = personal
                this.condecoracionPersonalDialog = true
                this.loading = false
            },
        },
    }
</script>
