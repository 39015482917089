import { axios, fileAxios } from '../../plugins/axios'
import _ from 'lodash'

export default {
    namespaced: true,

    state: {
        fullPersonal: [],
        personal: [],
        personalFotoCuip: null,
        personalSingle: null,
        savedIdPersonal: 0,

        busquedaPersonal: {
            nombre: '',
            segundoNombre: '',
            apaterno: '',
            amaterno: '',
            curp: '',
            CUIP: '',
        },

        evaluadores: [],
        supervisores: [],
    },

    getters: {
        getFullPersonal: state => {
            let personal = state.fullPersonal.filter(elemento => {
                return elemento.CatPuestoDependencia && elemento.CatPuestoDependencia.fk_idDependencia == 2
            })

            return _.sortBy(personal, elemento => elemento.apaterno + ' ' + elemento.amaterno + ' ' + elemento.nombre)
        },

        getPersonal: state => {
            let personal = state.personal.filter(elemento => {
                return elemento.CatPuestoDependencia && elemento.CatPuestoDependencia.fk_idDependencia == 2
            })

            return _.sortBy(personal, elemento => elemento.apaterno + ' ' + elemento.amaterno + ' ' + elemento.nombre)
        },

        getPersonalFotoCuip: state => {
            return state.personalFotoCuip
        },

        getPersonalSingle: state => {
            return state.personalSingle
        },

        getSavedIdPersonal: state => {
            return state.savedIdPersonal
        },

        getBusquedaPersonal: state => {
            return state.busquedaPersonal
        },

        getEvaluadores: state => {
            return state.evaluadores
        },

        getSupervisores: state => {
            return state.supervisores
        },
    },

    mutations: {
        SET_PERSONAL(state, personal) {
            state.personal = personal.filter(elemento => {
                return elemento.fk_idEstatus != 7
            })
        },

        SET_FULL_PERSONAL(state, personal) {
            state.fullPersonal = personal
        },

        PUSH_NEW_PERSONAL(state, personal) {
            state.personal.push(personal)
        },

        SET_PERSONAL_FOTO_CUIP(state, idPersonal) {
            var personalFotoCuip = state.personal.filter(personal => {
                return personal.idPersonal == idPersonal
            })

            var personal = _.cloneDeep(personalFotoCuip[0])

            state.personalFotoCuip = personal
        },

        SET_PERSONAL_SINGLE(state, personalSingle) {
            state.personalSingle = personalSingle
        },

        SET_SAVED_ID_PERSONAL(state, savedIdPersonal) {
            state.savedIdPersonal = savedIdPersonal
        },

        SET_BUSQUEDA_PERSONAL(state, busquedaPersonal) {
            state.busquedaPersonal = busquedaPersonal
        },

        RESET_BUSQUEDA_PERSONAL(state) {
            state.busquedaPersonal = {
                nombre: '',
                segundoNombre: '',
                apaterno: '',
                amaterno: '',
                curp: '',
                CUIP: '',
            }

            state.personal = []
        },

        SET_EVALUADORES(state, evaluadores) {
            state.evaluadores = evaluadores
        },

        SET_SUPERVISORES(state, supervisores) {
            state.supervisores = supervisores
        },
    },

    actions: {
        fetchPersonal: async function({ commit }) {
            await axios
                .get('/IPH/EstadoFuerza/personal')
                .then(response => {
                    commit('SET_FULL_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchPersonalByDependencia: async function({ commit }, id_dependencia) {
            await axios
                .get('/IPH/EstadoFuerza/dependencias/' + id_dependencia + '/personal')
                .then(response => {
                    commit('SET_FULL_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchPersonalPagination: async function({ commit }, pagination) {
            await axios
                .get('/IPH/EstadoFuerza/personal/page/' + pagination.page + '/rowsOfPage/' + pagination.rows)
                .then(response => {
                    commit('SET_FULL_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchPersonalByAmbito: async function({ commit }, ambito) {
            await axios
                .get('/IPH/EstadoFuerza/ambito/' + ambito + '/personal')
                .then(response => {
                    commit('SET_PERSONAL', response.data.data)
                })
                .catch(error => console.log(error))
        },

        fetchPersonalById: async function({ commit }, idPersonal) {
            await axios
                .get('/IPH/EstadoFuerza/personal/' + idPersonal)
                .then(response => {
                    commit('SET_PERSONAL_SINGLE', response.data.data[0])
                })
                .catch(error => console.log(error))
        },

        searchPersonal: async function(context, searchPersonal) {
            await axios
                .post('/EonCloud/EstadoFuerza/search/personal', searchPersonal)
                .then(response => {
                    if (response.data.data.length == 0) {
                        context.commit('SET_PERSONAL', [])
                        context.commit('SET_SNACKBAR_MESSAGE', 'Búsqueda sin resultados. Intente nuevamente', {
                            root: true,
                        })
                    } else context.commit('SET_PERSONAL', response.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        fetchEvaluadores: async function(context, cuip) {
            await axios
                .post('/EonCloud/EstadoFuerza/search/personal', {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: cuip,
                })
                .then(response => {
                    if (response.data.data.length == 0) {
                        context.commit('SET_EVALUADORES', [])
                    } else context.commit('SET_EVALUADORES', response.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        fetchSupervisores: async function(context, cuip) {
            await axios
                .post('/EonCloud/EstadoFuerza/search/personal', {
                    nombre: '',
                    segundoNombre: '',
                    apaterno: '',
                    amaterno: '',
                    curp: '',
                    CUIP: cuip,
                })
                .then(response => {
                    if (response.data.data.length == 0) {
                        context.commit('SET_SUPERVISORES', [])
                    } else context.commit('SET_SUPERVISORES', response.data.data)
                })
                .catch(error => {
                    console.log(error)
                })
        },

        pushNewPersonal: async function(context, idPersonal) {
            await axios
                .get('/IPH/EstadoFuerza/personal/' + idPersonal)
                .then(response => {
                    context.commit('PUSH_NEW_PERSONAL', response.data.data[0])
                    context.commit('SET_SNACKBAR_MESSAGE', 'Elemento registrado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        savePersonal: async function(context, personal) {
            await axios
                .post('/PoliciaCuadrantes/EstadoFuerza/personal', personal.data)
                .then(response => {
                    context.dispatch('personal/pushNewPersonal', response.data.data[0].ID, { root: true })
                    context.commit('SET_SAVED_ID_PERSONAL', response.data.data[0].ID)
                    personal.estudios.forEach(estudio => {
                        estudio.fk_idPersonal = response.data.data[0].ID
                        context.dispatch('estudio/saveEstudiosPersonal', estudio, { root: true })
                    })
                })
                .catch(error => console.log(error))
        },

        updatePersonal: async function(context, personal) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personal.idPersonal, personal)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Información de elemento actualizada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'La información del elemento no pudo ser actualziada. Por favor verifique los campos.', {
                        root: true,
                    })
                    console.log(error)
                })
        },

        updateCuipPersonal: async function({ commit }, personal) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personal.idPersonal + '/cuip', {
                    CUIP: personal.cuip,
                })
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateEstatusPersonal: async function(context, personal) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personal.idPersonal + '/estatus', {
                    fk_idEstatus: personal.fk_idEstatus,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Actualizando estatus de elemento. Por favor, espere...', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        updateLicenciaOficialColectivaPersonal: async function({ commit }, personal) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personal.idPersonal + '/licenciaColectiva', {
                    licenciaColectivaPortacionArma: personal.cup,
                })
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updatePuestoPersonal: async function({ commit }, puesto) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + puesto.idPersonal + '/puesto', {
                    fk_idPuestoDependencia: puesto.fk_idPuestoDependencia,
                })
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateAreaPersonal: async function({ commit }, area) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + area.idPersonal + '/areas', {
                    fk_idCatArea: area.fk_idCatArea,
                })
                .then(response => {
                    // //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateSubareaPersonal: async function({ commit }, subarea) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + subarea.idPersonal + '/subareas', {
                    fk_idCatSubArea: subarea.fk_idCatSubArea,
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateTurnoPersonal: async function({ commit }, turno) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + turno.idPersonal + '/turno', {
                    fk_idCatTurno: turno.fk_idCatTurno,
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateSubsedePersonal: async function(context, subsede) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + subsede.idPersonal + '/subsede', {
                    fk_idSubsede: subsede.idSubsede,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Asignación de subsede y puesto realizada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        uploadFoto: async function({ commit }, formData) {
            await fileAxios
                .post('/Fotografia/uploadPhotograph', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateCupPersonal: async function(context, personal) {
            await axios
                .put('/PoliciaCuadrantes/EstadoFuerza/personal/' + personal.idPersonal + '/cup', {
                    CUP: personal.cup,
                })
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Información de CUP guardada correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        uploadCup: async function(context, formData) {
            await fileAxios
                .post('/SetAdjuntoCup/uploadFiles', formData)
                .then(response => {
                    context.commit('SET_SNACKBAR_MESSAGE', 'Documento CUP cargado correctamente.', {
                        root: true,
                    })
                })
                .catch(error => console.log(error))
        },

        getPersonalToValidateById: async function({ commit }, idPersonal) {
            return await axios
                .get('/IPH/EstadoFuerza/personal/' + idPersonal)
                .then(response => {
                    return response.data.data[0]
                })
                .catch(error => console.log(error))
        },
    },
}
