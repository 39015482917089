<template>
    <v-dialog persistent width="90%" v-model="disciplinaOperativaDialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" class="mx-2" v-on="on" v-bind="attrs">Disciplina Operativa</v-btn>
        </template>
        <v-card class="rounded-sm" elevation="4">
            <v-card-title>
                <div class="text-h6">Evaluación de disciplina operativa</div>
                <v-spacer></v-spacer>
                <v-btn icon color="error" @click="closeDialog()"><v-icon>mdi-window-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="rounded-sm" elevation="4">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        Información general
                                    </div>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Instancia que informa"
                                                    v-model="disciplinaOperativa.instanciaInforma"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="Periodicidad"
                                                    v-model="disciplinaOperativa.CatPeriodicidad.title"
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaOperativa.fechaInicial"
                                                    label="Fecha inicio"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaOperativa.fechaFin"
                                                    label="Fecha fin"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :value="disciplinaOperativa.fechaAplicacion"
                                                    label="Fecha de aplicación"
                                                    append-icon="mdi-calendar"
                                                    readonly
                                                    rounded
                                                    filled
                                                    dense
                                                    color="secondary"
                                                    background-color="primary"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="3">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-h6">{{ getCantidadProcedimientos(1) }} Queja(s)</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="3">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-h6">{{ getCantidadProcedimientos(2) }} Administrativo(s)</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="3">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-h6">{{ getCantidadProcedimientos(3) }} Averigüacion(es) previa(s)</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col cols="3">
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-h6">{{ getCantidadProcedimientos(4) }} Carpeta de investigación</div>
                                </v-card-title>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card elevation="4" class="rounded-sm">
                                <v-card-title>
                                    <div class="text-subtitle-1 font-weight-bold">Procedimientos</div>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        fab
                                        color="secondary"
                                        icon
                                        @click="
                                            disciplinaOperativa.procedimientos.push({
                                                fk_idCatProcedimiento: null,
                                                fk_idCatEstatusProcedimiento: null,
                                                numero: '',
                                                fecha: '',
                                                motivo: '',
                                                descripcion: '',
                                                resolucion: '',
                                                estatus: true,
                                            })
                                        "
                                        ><v-icon>mdi-plus</v-icon></v-btn
                                    >
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" v-for="(procedimiento, index) in disciplinaOperativa.procedimientos" :key="index">
                                                <v-row>
                                                    <v-col>
                                                        <div class="text-h6">{{ index + 1 }}</div>
                                                    </v-col>
                                                    <v-col cols="10">
                                                        <v-row>
                                                            <v-col cols="2">
                                                                <v-select
                                                                    label="Tipo de procedimiento"
                                                                    v-model="procedimiento.fk_idCatProcedimiento"
                                                                    :items="procedimientos"
                                                                    item-value="id"
                                                                    item-text="title"
                                                                    item-color="secondary"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-select>
                                                            </v-col>
                                                            <v-col>
                                                                <v-text-field
                                                                    label="Número"
                                                                    v-model="procedimiento.numero"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col>
                                                                <!-- <v-menu
                                                                    v-model="menuFecha[index]"
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    min-width="auto"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            :value="fecha(index)"
                                                                            label="Fecha"
                                                                            append-icon="mdi-calendar"
                                                                            readonly
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            rounded
                                                                            filled
                                                                            dense
                                                                            color="secondary"
                                                                            background-color="primary"
                                                                        ></v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="procedimiento.fecha"
                                                                        @input="menuFecha[index] = false"
                                                                    ></v-date-picker>
                                                                </v-menu> -->
                                                                <date-picker v-model="procedimiento.fecha" type="date" valueType="DD/MM/YYYY"></date-picker>
                                                            </v-col>
                                                            <v-col>
                                                                <v-text-field
                                                                    label="Motivo"
                                                                    v-model="procedimiento.motivo"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col>
                                                                <v-select
                                                                    label="Estado actual"
                                                                    v-model="procedimiento.fk_idCatEstatusProcedimiento"
                                                                    :items="estatusProcedimiento"
                                                                    item-value="id"
                                                                    item-text="title"
                                                                    item-color="secondary"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <v-textarea
                                                                    label="Descripción"
                                                                    v-model="procedimiento.descripcion"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                    rows="2"
                                                                    no-resize
                                                                ></v-textarea>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-textarea
                                                                    label="Resolución"
                                                                    v-model="procedimiento.resolucion"
                                                                    rounded
                                                                    filled
                                                                    dense
                                                                    color="secondary"
                                                                    background-color="primary"
                                                                    rows="2"
                                                                    no-resize
                                                                ></v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn
                                                            small
                                                            color="error"
                                                            class="mt-2"
                                                            text
                                                            @click="disciplinaOperativa.procedimientos.splice(index, 1)"
                                                        >
                                                            Eliminar
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-divider></v-divider>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="secondary" @click="triggerSaveDisciplinaOperativa()" :loading="loading" :disabled="loading">Guardar evaluación</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import moment from 'moment'
    import { mapActions, mapGetters } from 'vuex'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'

    export default {
        components: { DatePicker },

        props: {
            idPersonal: {
                type: Number,
            },

            disciplinaOperativa: {
                type: Object,
            },
        },

        data() {
            return {
                disciplinaOperativaDialog: false,
                loading: false,

                menuFecha: [],
            }
        },

        computed: {
            ...mapGetters('periodicidad', {
                periodicidad: 'getPeriodicidad',
            }),

            ...mapGetters('procedimiento', {
                procedimientos: 'getProcedimiento',
            }),

            ...mapGetters('estatusProcedimiento', {
                estatusProcedimiento: 'getEstatusProcedimiento',
            }),
        },

        methods: {
            ...mapActions('disciplinaOperativa', ['updateDisciplinaOperativa', 'fetchDisciplinaOperativa', 'updateEstatus']),

            getIncidenciaTitle(id_incidencia) {
                switch (id_incidencia) {
                    case 1:
                        return 'Faltas'
                    case 2:
                        return 'Amonestaciones'
                    case 3:
                        return 'Incapacidades'
                    case 4:
                        return 'Permisos'
                    case 5:
                        return 'Arresto'

                    default:
                        break
                }
            },

            closeDialog() {
                this.loading = false
                this.disciplinaOperativaDialog = false
            },

            async triggerSaveDisciplinaOperativa() {
                this.loading = true

                this.disciplinaOperativa.procedimientos.forEach(procedimiento => {
                    delete procedimiento.id
                    console.log(procedimiento.fecha)
                })

                await this.updateDisciplinaOperativa(this.disciplinaOperativa)
                await this.updateEstatus(this.disciplinaOperativa)

                await this.fetchDisciplinaOperativa(this.idPersonal)
                this.closeDialog()
            },

            getCantidadProcedimientos(id_procedimiento) {
                return this.disciplinaOperativa.procedimientos.filter(procedimiento => {
                    return procedimiento.fk_idCatProcedimiento == id_procedimiento
                }).length
            },

            fecha: function(index) {
                return this.disciplinaOperativa.procedimientos[index].fecha
                    ? moment(this.disciplinaOperativa.procedimientos[index].fecha).format('DD/MM/YYYY')
                    : ''
            },
        },
    }
</script>
