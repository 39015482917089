<template>
    <v-container v-if="activeStep < 3">
        <v-row>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">Evaluación de respeto a los principios</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="activeStep * 25" v-bind="circularProgressProps"></v-progress-circular>
                                </v-col>
                                <v-col cols="8">
                                    <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                    <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                    <a class="text-decoration-none black--text" v-show="activeStep != 1" v-on:click="activeStep--">Volver al paso anterior</a>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-if="activeStep == 1">
                            <v-row>
                                <v-form v-model="fechaAplicacionForm">
                                    <v-col cols="12">
                                        <v-date-picker
                                            v-model="evaluacionRespetoPrincipios.fechaAplicacion"
                                            locale="es"
                                            color="secondary"
                                            :max="getToday()"
                                            no-title
                                            full-width
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col cols="6" offset="3">
                                        <v-text-field
                                            :value="formatDate(evaluacionRespetoPrincipios.fechaAplicacion)"
                                            v-bind="textInputProps"
                                            label="Fecha de aplicación"
                                            readonly
                                            :rules="required"
                                        ></v-text-field>
                                    </v-col>
                                </v-form>
                            </v-row>
                        </v-container>
                        <v-container v-else>
                            <v-form v-model="periodoEvaluacionForm">
                                <v-row>
                                    <v-col cols="12">
                                        <v-date-picker
                                            v-model="periodoEvaluacion"
                                            locale="es"
                                            color="secondary"
                                            no-title
                                            full-width
                                            range
                                            :rules="required"
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="fechaInicio"
                                            v-bind="textInputProps"
                                            label="Fecha incio"
                                            disabled
                                            readonly
                                            :rules="required"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            :value="fechaFin"
                                            v-bind="textInputProps"
                                            label="Fecha fin"
                                            disabled
                                            readonly
                                            :rules="required"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                        <v-container>
                            <v-row>
                                <v-col xl="12" align="center">
                                    <v-btn color="secondary" v-on:click="activeStep++" :loading="loading" :disabled="!validActiveStep">{{
                                        activeStepButtonLabel
                                    }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else-if="activeStep == 3">
        <v-row>
            <v-col lg="4">
                <v-card class="card" color="secondary">
                    <v-card-title v-bind="cardTitleProps" class="black--text d-block" align="center">
                        <div class="black--text mb-4">Evaluación de respeto a los principios</div>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4">
                                    <v-avatar rounded size="80" color="primary">
                                        <v-icon size="48" color="secondary">mdi-account</v-icon>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="8" align="left">
                                    <div class="black--text text-subtitle-1">{{ getFullName(personal) }}</div>
                                    <div class="black--text text-caption text-secondary">{{ personal.CUIP }}</div>
                                    <div class="black--text text-caption text-secondary">
                                        {{ personal.CatPuestoDependencia.catPuesto.puesto }}
                                    </div>
                                    <div class="black--text text-caption text-secondary">Operativo</div>
                                </v-col>
                                <v-col cols="12" align="center">
                                    <v-icon color="primary" class="mr-4">mdi-calendar</v-icon>
                                    <div class="text-overline black--text d-inline">{{ fechaInicio }}</div>
                                    <div class="text-body-2 d-inline mx-4">-</div>
                                    <div class="text-overline black--text d-inline ">{{ fechaFin }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <v-progress-circular :value="getPuntajeTotal" color="primary" size="96" width="12" rotate="-90"></v-progress-circular>
                                </v-col>
                                <v-col cols="6">
                                    <div class="text-h6 font-weight-black black--text">Puntaje total: {{ getPuntajeTotal }}</div>
                                    <div class="text-subtitle-2 font-weight-bold black--text">Resultado: {{ getResultado }}</div>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-list color="secondary">
                            <v-list-item-group v-model="principioElegido">
                                <v-list-item :key="principio.id" v-for="principio in principios">
                                    <v-list-item-content>
                                        <v-list-item-title class="black--text">{{ principio.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                    <v-card-actions v-bind="cardActionsProps">
                        <v-btn
                            color="primary"
                            class="px-8 black--text"
                            elevation="8"
                            :loading="loading"
                            :disabled="!validForm || loading"
                            v-on:click="activeStep++"
                            >Siguiente paso</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col lg="8" v-if="evaluacionRespetoPrincipios.frecuenciasMedicion.length > 0">
                <v-card v-bind="cardProps">
                    <v-card-title v-bind="cardTitleProps">Indicadores de respeto a los principios</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-form v-model="reactivosForm">
                                <v-row>
                                    <v-col cols="12" v-for="reactivo in filterReactivosByPrincipio" :key="reactivo.id">
                                        <v-row>
                                            <v-col cols="8">
                                                <div class="text subtitle-1 black--text">{{ reactivo.reactivo }}</div>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select
                                                    label="Frecuencia"
                                                    background-color="primary"
                                                    color="secondary"
                                                    rounded
                                                    filled
                                                    dense
                                                    :items="frecuenciaRespetoPrincipios"
                                                    item-value="id"
                                                    item-text="title"
                                                    item-color="secondary"
                                                    :value="getFrecuencia(reactivo.id)"
                                                    @input="setFrecuencia(reactivo.id, $event)"
                                                    :rules="required"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else-if="activeStep == 4">
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Observaciones</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        no-resize
                                        rows="4"
                                        color="secondary"
                                        background-color="primary"
                                        label="Observaciones"
                                        v-model="evaluacionRespetoPrincipios.observaciones"
                                        rounded
                                        filled
                                        dense
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions v-bind="cardActionsProps">
                        <v-btn v-bind="primaryButtonProps" :loading="loading" :disabled="loading" v-on:click="triggerSaveEvaluacionRespetoPrincipios()"
                            >Registrar evaluación</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row>
            <v-col lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Evaluación registrada</v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-icon size="120" color="accent">mdi-star</v-icon>
                                    <div class="text-subtitle-1 font-weight-bold">
                                        El elemento {{ getFullName(personal) }} obtuvo el resultado {{ getResultado }}.
                                    </div>
                                    <div class="text-subtitle-2"></div>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-bind="textInputProps" label="Puntaje total" :value="getPuntajeTotal"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        v-bind="textInputProps"
                                        label="Calificación total de respeto a los principios"
                                        :value="getPuntajeTotal / 10"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-list v-for="frecuencia in frecuenciaRespetoPrincipios" :key="frecuencia.id" color="transparent">
                                        <v-list-item>
                                            <v-list-item-title>{{ frecuencia.title }}</v-list-item-title>
                                            <v-list-item-action>{{ getCantidadIndicadores(frecuencia.id) }}</v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-card class="card" color="primary">
                                        <apexchart height="100%" type="bar" :options="personalInscritoOptions" :series="personalInscritoSeries"></apexchart>
                                    </v-card>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions v-bind="cardActionsProps">
                        <v-btn v-bind="primaryButtonProps" to="/desarrollo_profesional/personal">Volver a lista de personal</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, textInputProps, circularProgressProps } from '../../components/props/props'
    import moment from 'moment'

    export default {
        async mounted() {
            this.setFrecuenciasMedicion()
            this.SET_PERSONAL_FOTO_CUIP(this.id)
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                cardProps,
                cardTitleProps,
                textInputProps,
                circularProgressProps,
                cardActionsProps,
                primaryButtonProps,

                activeStep: 1,
                loading: false,
                principioElegido: 0,
                reactivosForm: false,

                fechaAplicacionForm: false,
                periodoEvaluacionForm: false,

                periodoEvaluacion: [null, null],

                evaluacionRespetoPrincipios: {
                    puntajeTotal: 100,
                    calificacion: '100',
                    observaciones: '',
                    fechaAplicacion: null,
                    fechaInicial: null,
                    fechaFin: null,
                    fk_idPersonal: this.id,
                    fk_idResultado: 2,
                    fk_idPersonalEvaluador: 2,
                    fk_idPeriodicidad: 1,
                    frecuenciasMedicion: [],
                },

                required: [v => !!v || 'Este campo es requerido.'],

                personalInscritoOptions: {
                    chart: {
                        id: 'vuechart-example',
                    },

                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: ['Excelente', 'Sobresaliente', 'Satisfactorio', 'Insuficiente', 'No satisfactorio', 'No aplica'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#F5B940',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },
                personalInscritoSeries: [
                    {
                        type: 'column',
                        name: 'Total de inscritos',
                        data: [19, 5, 7, 3, 0, 2],
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('principio', {
                principios: 'getPrincipios',
            }),

            ...mapGetters('reactivo', {
                reactivos: 'getReactivos',
            }),

            ...mapGetters('frecuenciaRespetoPrincipio', {
                frecuenciaRespetoPrincipios: 'getFrecuenciaRespetoPrincipio',
            }),
            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de aplicación'
                    case 2:
                        return 'Período de evaluación'
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 4) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            fechaInicio: function() {
                if (this.periodoEvaluacion[0]) {
                    if (moment(this.periodoEvaluacion[1]).isBefore(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.periodoEvaluacion[1]) {
                    if (moment(this.periodoEvaluacion[1]).isAfter(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            filterReactivosByPrincipio: function() {
                return this.reactivos.filter(reactivo => {
                    return reactivo.fk_idCatPrincipio == this.principioElegido + 1
                })
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.fechaAplicacionForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.periodoEvaluacionForm) {
                            return true
                        } else {
                            return false
                        }
                }
            },

            validForm: function() {
                if (this.evaluacionRespetoPrincipios.frecuenciasMedicion.some(frecuencia => !frecuencia.fk_idCatFrecuencia)) {
                    return false
                } else return true
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0

                this.evaluacionRespetoPrincipios.frecuenciasMedicion.forEach(reactivo => {
                    switch (reactivo.fk_idCatFrecuencia) {
                        case 1:
                            puntajeTotal = puntajeTotal + 4
                            break
                        case 2:
                            puntajeTotal = puntajeTotal + 3
                            break
                        case 3:
                            puntajeTotal = puntajeTotal + 2
                            break
                        case 4:
                            puntajeTotal = puntajeTotal + 1
                            break
                        case 5:
                            puntajeTotal = puntajeTotal + 0
                            break
                    }
                })
                return puntajeTotal
            },

            getResultado: function() {
                if (this.getPuntajeTotal < 31) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal < 63) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal < 76) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal < 88) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal <= 100) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 1
                    return 'Excelente'
                }
            },
        },

        methods: {
            ...mapActions('respetoPrincipios', ['saveRespetoPrincipios']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),
            getToday: function() {
                return moment().format('YYYY-MM-DD')
            },

            getFrecuencia: function(id_reactivo) {
                const index = this.evaluacionRespetoPrincipios.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idCatReactivo == id_reactivo)
                return this.evaluacionRespetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia
            },

            setFrecuencia: function(id_reactivo, e) {
                const index = this.evaluacionRespetoPrincipios.frecuenciasMedicion.findIndex(reactivo => reactivo.fk_idCatReactivo == id_reactivo)
                this.evaluacionRespetoPrincipios.frecuenciasMedicion[index].fk_idCatFrecuencia = e
            },

            setFrecuenciasMedicion: function() {
                this.reactivos.forEach(reactivo => {
                    this.evaluacionRespetoPrincipios.frecuenciasMedicion.push({
                        fk_idCatFrecuencia: null,
                        fk_idCatReactivo: reactivo.id,
                    })
                })
            },

            triggerSaveEvaluacionRespetoPrincipios: async function() {
                this.loading = true
                setTimeout(() => this.activeStep++, 2000)

                await this.saveRespetoPrincipios({
                    puntajeTotal: this.getPuntajeTotal,
                    calificacion: (this.getPuntajeTotal / 10).toString(),
                    observaciones: this.evaluacionRespetoPrincipios.observaciones,
                    fechaAplicacion: moment(this.evaluacionRespetoPrincipios.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fk_idPersonal: this.id,
                    fk_idResultado: 1,
                    fk_idPersonalEvaluador: 510,
                    fk_idPeriodicidad: 1,
                    frecuenciasMedicion: this.evaluacionRespetoPrincipios.frecuenciasMedicion,
                })
            },

            getCantidadIndicadores: function(id_frecuencia) {
                return this.evaluacionRespetoPrincipios.frecuenciasMedicion.filter(reactivo => {
                    return reactivo.fk_idCatFrecuencia == id_frecuencia
                }).length
            },
        },
    }
</script>
