<template>
    <v-dialog persistent width="480" v-model="active">
        <v-card color="primary" class="card">
            <v-card-title>
                <div class="text-h6">¿Turnar aspirante a proceso de evaluación?</div>
            </v-card-title>
            <v-card-actions>
                <v-btn text color="error" @click="closeDialog()">Cancelar</v-btn>
                <v-btn color="secondary" @click="triggerTurnaAspirante()">Turnar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        props: {
            active: {
                type: Boolean,
            },

            aspirante: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
            }
        },

        methods: {
            ...mapActions('preaspirante', ['fetchAspirantes']),
            ...mapActions('personal', ['updatePersonal']),

            closeDialog() {
                this.$emit('close')
                this.loading = false
            },

            async triggerTurnaAspirante() {
                this.loading = true

                this.aspirante.observacionesC3 = 'NO REGISTRADO'
                await this.updatePersonal(this.aspirante)

                await this.fetchAspirantes()
                this.closeDialog()
            },
        },
    }
</script>
