<template>
    <v-container v-if="capacitadorToUpdate">
        <v-row>
            <v-col cols="12">
                <v-btn text @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                    Volver
                </v-btn>
            </v-col>
            <v-col cols="12" lg="4" offset-lg="4">
                <v-card class="card" color="primary">
                    <v-card-title class="font-weight-bold justify-center">Editar capacitador</v-card-title>
                    <v-card-text>
                        <v-form v-model="trainerForm">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="capacitadorToUpdate.nombre"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Nombre <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="capacitadorToUpdate.segundoNombre"
                                            label="Segundo nombre"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="capacitadorToUpdate.apaterno"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Apellido paterno <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="capacitadorToUpdate.amaterno"
                                            rounded
                                            filled
                                            dense
                                            background-color="primary"
                                            color="secondary"
                                            :rules="nameRules"
                                        >
                                            <template v-slot:label>Apellido materno <span class="red--text">*</span> </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-file-input
                                            v-model="file"
                                            label="Adjuntar certificado o título"
                                            persistent-hint
                                            rounded
                                            filled
                                            background-color="primary"
                                            color="secondary"
                                            dense
                                        >
                                        </v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-8">
                        <v-btn color="secondary" class="primary--text px-8" :disabled="!trainerForm || loading" v-on:click="triggerUpdateTrainer()">
                            Editar capacitador
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'

    export default {
        props: {
            id: {
                type: Number,
            },
        },

        async mounted() {
            this.SET_CAPACITADOR_TO_UPDATE(this.id)
        },

        data() {
            return {
                loading: false,
                trainerForm: false,
                file: null,

                nameRules: [v => !!v || 'Este campo es requerido.'],
            }
        },

        computed: {
            ...mapGetters('capacitador', {
                capacitadorToUpdate: 'getCapacitadorToUpdate',
            }),
        },

        methods: {
            ...mapActions('capacitador', ['updateCapacitador', 'fetchCapacitadores']),
            ...mapActions('cursoProgramado', ['fetchCursosProgramados']),
            ...mapActions('cursoAsignado', ['fetchCursosAsignados']),
            ...mapMutations('capacitador', ['SET_CAPACITADOR_TO_UPDATE']),

            triggerUpdateTrainer: async function() {
                this.loading = true

                if (this.file) {
                    this.capacitadorToUpdate.certificado = this.file.name
                }

                await this.updateCapacitador({
                    capacitador: this.capacitadorToUpdate,
                    file: this.file,
                })

                await this.fetchCapacitadores()
                await this.fetchCursosProgramados()
                await this.fetchCursosAsignados()
                this.$router.replace('/profesionalizacion/capacitadores')

                this.loading = false
            },
        },
    }
</script>
