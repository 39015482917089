import { axios } from '../../../plugins/axios'

export default {
    namespaced: true,

    state: {
        movimientoEquipamiento: [],
    },

    getters: {
        getMovimientoEquipamiento: state => {
            return state.movimientoEquipamiento
        },
    },

    mutations: {
        SET_MOVIMIENTO_EQUIPAMIENTO(state, movimientoEquipamiento) {
            state.movimientoEquipamiento = movimientoEquipamiento
        },
    },

    actions: {
        fetchMovimientoEquipamientoByItem: async function({ commit }, idItem) {
            await axios
                .get('/PoliciaCuadrantes/Equipamiento/Item/' + idItem + '/movimientos')
                .then(response => {
                    commit('SET_MOVIMIENTO_EQUIPAMIENTO', response.data.data)
                })
                .catch(error => console.log(error))
        },

        saveMovimientoEquipamiento: async function({ commit }, movimientoEquipamiento) {
            await axios
                .post('/PoliciaCuadrantes/Equipamiento/movimientos', movimientoEquipamiento)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },

        updateMovimientoEquipamiento: async function({ commit }, movimientoEquipamiento) {
            await axios
                .put('/PoliciaCuadrantes/Equipamiento/movimientos/' + movimientoEquipamiento.id, movimientoEquipamiento)
                .then(response => {
                    //console.log('OK')
                })
                .catch(error => console.log(error))
        },
    },
}
