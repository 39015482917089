<template>
    <v-container>
        <v-row>
            <v-col cols="7" align="right">
                <v-btn class="secondary card py-y mt-2 px-8" rounded elevation="8" to="/equipamiento/registro_item"
                    >Nuevo item <v-icon small class="ml-2">mdi-plus</v-icon></v-btn
                >
            </v-col>
            <v-col cols="12" lg="4">
                <v-text-field
                    v-model="buscaItem"
                    color="secondary"
                    background-color="primary"
                    rounded
                    filled
                    placeholder="Buscar item por nombre..."
                    append-icon="mdi-magnify"
                ></v-text-field>
            </v-col>
            <v-col lg="1">
                <v-btn-toggle v-model="ordenAlfabetico" class="card" rounded mandatory>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-ascending</v-icon>
                    </v-btn>
                    <v-btn icon>
                        <v-icon color="secondary darken-2">mdi-order-alphabetical-descending</v-icon>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" v-if="buscaItem.length > 0">
                <div class="text-h6 font-weight-bold">{{ filteredItems.length }} resultados encontrados con la búsqueda "{{ buscaItem }}"</div>
            </v-col>
            <v-expansion-panels flat class="card" focusable hover mandatory>
                <v-expansion-panel v-for="(grupoItems, index) in groupedItems" :key="index">
                    <v-expansion-panel-header>
                        <div>
                            <div class="text-h6 secondary--text font-weight-bold d-inline">
                                {{ grupoItems.tipo }}
                            </div>
                            <div class="text-overline d-inline secondary--text">({{ grupoItems.items.length }})</div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container>
                            <v-row>
                                <v-col cols="12" lg="4" xl="3" v-for="item in grupoItems.items" :key="item.id">
                                    <v-card class="card" color="secondary" elevation="8">
                                        <v-card-title>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="4" align="center">
                                                        <v-avatar rounded color="primary" size="80">
                                                            <v-img
                                                                v-if="item.id == 10 || item.id == 2"
                                                                src="@/assets/img/items/casco.png"
                                                                width="48"
                                                                contain
                                                            ></v-img>
                                                            <v-img
                                                                v-else-if="item.id == 5 || item.id == 1"
                                                                src="@/assets/img/items/chaleco.png"
                                                                width="48"
                                                                contain
                                                            ></v-img>
                                                            <v-img v-else-if="item.id == 7" src="@/assets/img/items/coderas.png" width="48" contain></v-img>
                                                            <v-img v-else-if="item.id == 13" src="@/assets/img/items/guantes.png" width="48" contain></v-img>
                                                            <v-img v-else-if="item.id == 4" src="@/assets/img/items/maleta.png" width="48" contain></v-img>
                                                            <v-img
                                                                v-else-if="item.id == 12"
                                                                src="@/assets/img/items/mascara_anti.png"
                                                                width="48"
                                                                contain
                                                            ></v-img>
                                                            <v-img
                                                                v-else-if="item.id == 9"
                                                                src="@/assets/img/items/rodilleras_espinilleras.png"
                                                                width="48"
                                                                contain
                                                            ></v-img>
                                                            <v-img v-else-if="item.id == 6" src="@/assets/img/items/hombreras.png" width="48" contain></v-img>
                                                            <v-img v-else-if="item.id == 11" src="@/assets/img/items/pr24.png" width="48" contain></v-img>
                                                            <v-img v-else-if="item.id == 8" src="@/assets/img/items/muslera.png" width="48" contain></v-img>
                                                            <v-img v-else-if="item.id == 3" src="@/assets/img/items/placas.png" width="48" contain></v-img>
                                                            <v-icon v-else x-large color="secondary">mdi-briefcase</v-icon>
                                                        </v-avatar>
                                                    </v-col>
                                                    <v-col cols="8">
                                                        <div class="text-h6 primary--text font-weight-bold">{{ item.item }}</div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container></v-container>
                                        </v-card-text>
                                        <v-card-actions class="secondary darken-1">
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" align="right">
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'listaMovimientos', params: { id: item.id } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-clipboard</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Ver movimientos e inventario</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'movimientoEquipamiento', params: { id: item.id } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-clipboard-flow-outline</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Realizar movimiento de inventario</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom color="black">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    class="primary secondary--text mx-2"
                                                                    elevation="8"
                                                                    :to="{ name: 'editarItem', params: { id: item.id } }"
                                                                    icon
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                                                >
                                                            </template>
                                                            <span>Editar item</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                buscaItem: '',
                ordenAlfabetico: 0,
            }
        },

        computed: {
            ...mapGetters('item', {
                items: 'getItems',
            }),

            filteredItems: function() {
                return this.items.filter(item => {
                    return item.item.toLowerCase().indexOf(this.buscaItem.toLowerCase()) >= 0
                })
            },

            groupedItems: function() {
                var result = _(this.filteredItems)
                    .groupBy(item => item.CatTipoItem.title)
                    .map((items, tipo) => ({ tipo, items }))
                    .value()

                if (this.ordenAlfabetico == 0) {
                    result.sort(function(a, b) {
                        if (a.tipo < b.tipo) {
                            return -1
                        }
                        if (a.tipo > b.tipo) {
                            return 1
                        }
                        return 0
                    })
                } else {
                    result.sort(function(a, b) {
                        if (a.tipo > b.tipo) {
                            return -1
                        }
                        if (a.tipo < b.tipo) {
                            return 1
                        }
                        return 0
                    })
                }
                return result
            },
        },
    }
</script>
