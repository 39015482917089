<template>
    <div v-if="personal">
        <v-container v-if="activeStep < 3">
            <v-row>
                <v-col cols="12" lg="4" offset-lg="4">
                    <v-card v-bind="cardProps">
                        <v-card-title v-bind="cardTitleProps">Evaluación de productividad</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="4" align="center">
                                        <v-progress-circular :value="activeStep * 25" v-bind="circularProgressProps"></v-progress-circular>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="text-caption text--primary">PASO {{ activeStep }}</div>
                                        <div class="text-subtitle-1 font-weight-bold text--primary">{{ activeStepTitle }}</div>
                                        <a class="text-decoration-none secondary--text" v-show="activeStep != 1" v-on:click="activeStep--"
                                            >Volver al paso anterior</a
                                        >
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container v-if="activeStep == 1">
                                <v-row>
                                    <v-form v-model="fechaAplicacionForm">
                                        <v-col cols="12">
                                            <v-date-picker
                                                v-model="productividadTest.fechaAplicacion"
                                                locale="es"
                                                color="secondary"
                                                :max="getToday()"
                                                no-title
                                                full-width
                                            ></v-date-picker>
                                        </v-col>
                                        <v-col cols="6" offset="3">
                                            <v-text-field
                                                :value="formatDate(productividadTest.fechaAplicacion)"
                                                v-bind="textInputProps"
                                                label="Fecha de aplicación"
                                                readonly
                                                :rules="required"
                                            ></v-text-field>
                                        </v-col>
                                    </v-form>
                                </v-row>
                            </v-container>
                            <v-container v-else>
                                <v-form v-model="periodoEvaluacionForm">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-date-picker
                                                v-model="periodoEvaluacion"
                                                locale="es"
                                                color="secondary"
                                                no-title
                                                full-width
                                                range
                                                :rules="required"
                                            ></v-date-picker>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                :value="fechaInicio"
                                                v-bind="textInputProps"
                                                label="Fecha incio"
                                                disabled
                                                readonly
                                                :rules="required"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                :value="fechaFin"
                                                v-bind="textInputProps"
                                                label="Fecha fin"
                                                disabled
                                                readonly
                                                :rules="required"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                            <v-container>
                                <v-row>
                                    <v-col xl="12" align="center">
                                        <v-btn color="secondary" v-on:click="activeStep++" :loading="loading" :disabled="!validActiveStep">{{
                                            activeStepButtonLabel
                                        }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else-if="activeStep == 3">
            <v-row>
                <v-col lg="4">
                    <v-card class="card" color="secondary">
                        <v-card-title v-bind="cardTitleProps" class="black--text d-block" align="center">
                            <div class="black--text mb-4">Evaluación de productividad</div>
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="4">
                                        <v-avatar rounded size="80" color="primary">
                                            <v-icon size="48" color="secondary">mdi-account</v-icon>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="8" align="left">
                                        <div class="black--text text-subtitle-1">{{ getFullName(personal) }}</div>
                                        <div class="black--text text-caption text-secondary">{{ personal.CUIP }}</div>
                                        <div class="black--text text-caption text-secondary">{{ personal.CatPuestoDependencia.catPuesto.puesto }}</div>
                                        <div class="black--text text-caption text-secondary">Operativo</div>
                                    </v-col>
                                    <v-col cols="12" align="center">
                                        <v-icon color="primary" class="mr-4">mdi-calendar</v-icon>
                                        <div class="text-overline black--text d-inline">{{ fechaInicio }}</div>
                                        <div class="text-body-2 d-inline mx-4">-</div>
                                        <div class="text-overline black--text d-inline ">{{ fechaFin }}</div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row align="center">
                                    <v-col cols="4" align="center">
                                        <v-progress-circular :value="getPuntajeTotal" color="primary" size="96" width="12" rotate="-90"></v-progress-circular>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="text-h6 font-weight-black black--text">Puntaje total: {{ getPuntajeTotal }}</div>
                                        <div class="text-subtitle-2 font-weight-bold black--text">Resultado: {{ getResultado }}</div>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-list color="secondary">
                                <v-list-item-group v-model="funcionElegida">
                                    <v-list-item :key="funcion.id" v-for="funcion in funciones">
                                        <v-list-item-content>
                                            <v-list-item-title class="black--text">{{ funcion.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-list-item v-on:click="funcionElegida = 4">
                                <v-list-item-content>
                                    <v-list-item-title class="black--text">Factores de mérito</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                        <v-card-actions v-bind="cardActionsProps">
                            <v-btn
                                color="primary"
                                class="px-8 secondary--text"
                                elevation="8"
                                :loading="loading"
                                :disabled="!indicadoresForm || !factoresMeritoForm || loading"
                                v-on:click="triggerSaveEvaluacionProductividad()"
                                >Registrar evaluación</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col lg="8" v-if="productividadTest.niveles.length > 0 && productividadTest.factores.length > 0">
                    <v-card v-bind="cardProps" v-if="funcionElegida < 4">
                        <v-card-title v-bind="cardTitleProps">Indicadores de productividad</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-form v-model="indicadoresForm">
                                    <v-row>
                                        <v-col cols="12" v-for="indicador in filterIndicadoresByFuncion" :key="indicador.id">
                                            <v-row>
                                                <v-col cols="5">
                                                    <div class="text subtitle-1 ">{{ indicador.indicador }}</div>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        :value="getRealizados(indicador.id)"
                                                        @input="setRealizados(indicador.id, $event)"
                                                        v-bind="textInputProps"
                                                        label="Realizados"
                                                        :rules="required"
                                                        validate-on-blur
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-select
                                                        label="Frecuencia productividad"
                                                        background-color="primary"
                                                        color="secondary"
                                                        rounded
                                                        filled
                                                        dense
                                                        :items="frecuenciaProductividad"
                                                        item-value="id"
                                                        item-text="title"
                                                        item-color="secondary"
                                                        :value="getFrecuencia(indicador.id)"
                                                        @input="setFrecuencia(indicador.id, $event)"
                                                        :rules="required"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    <v-card v-bind="cardProps" v-else>
                        <v-card-title v-bind="cardTitleProps">Factores de mérito</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-form v-model="factoresMeritoForm">
                                    <v-row>
                                        <v-col cols="12" v-for="actoRelevante in actosRelevantes" :key="actoRelevante.id">
                                            <v-row>
                                                <v-col cols="3">
                                                    <div class="text subtitle-1">{{ actoRelevante.title }}</div>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        :value="getCantidadActos(actoRelevante.id)"
                                                        @input="setCantidadActos(actoRelevante.id, $event)"
                                                        v-bind="textInputProps"
                                                        label="Cantidad"
                                                        :rules="required"
                                                        validate-on-blur
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field
                                                        :value="getDescripcionActos(actoRelevante.id)"
                                                        @input="setDescripcionActos(actoRelevante.id, $event)"
                                                        v-bind="textInputProps"
                                                        label="Descripción"
                                                        :rules="required"
                                                        validate-on-blur
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <v-row>
                <v-col lg="4" offset-lg="4">
                    <v-card class="card" color="primary">
                        <v-card-title class="font-weight-bold justify-center">Evaluación registrada</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" align="center">
                                        <v-icon size="120" color="accent">mdi-star</v-icon>
                                        <div class="text-subtitle-1 font-weight-bold">
                                            El elemento {{ getFullName(personal) }} obtuvo el resultado {{ getResultado }}.
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-bind="textInputProps" label="Puntaje total" :value="getPuntajeTotal"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-bind="textInputProps"
                                            label="Calificación total de productividad"
                                            :value="getPuntajeTotal / 100"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-list v-for="frecuencia in frecuenciaProductividad" :key="frecuencia.id" color="transparent">
                                            <v-list-item>
                                                <v-list-item-title>{{ frecuencia.title }}</v-list-item-title>
                                                <v-list-item-action>{{ getCantidadIndicadores(frecuencia.id) }}</v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <v-card class="card" color="primary">
                                            <apexchart
                                                height="100%"
                                                type="bar"
                                                :options="personalInscritoOptions"
                                                :series="personalInscritoSeries"
                                            ></apexchart>
                                        </v-card>
                                    </v-col> -->
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions v-bind="cardActionsProps">
                            <v-btn v-bind="primaryButtonProps" to="/desarrollo_profesional/personal">Volver a lista de personal</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { cardActionsProps, cardProps, cardTitleProps, primaryButtonProps, textInputProps, circularProgressProps } from '../../components/props/props'
    import moment from 'moment'

    export default {
        async mounted() {
            this.SET_PERSONAL_FOTO_CUIP(this.id)
            await this.setNiveles()
            await this.setFactoresMerito()
        },

        props: {
            id: {
                type: Number,
            },
        },

        data() {
            return {
                cardTitleProps,
                cardActionsProps,
                primaryButtonProps,
                cardProps,
                textInputProps,
                circularProgressProps,

                loading: false,
                funcionElegida: 0,
                activeStep: 1,
                evaluacionProductividadForm: false,
                fechaAplicacionForm: false,
                periodoEvaluacionForm: false,
                periodoEvaluacion: [null, null],
                indicadoresForm: false,
                factoresMeritoForm: false,

                productividadTest: {
                    idPersonal: this.id,
                    puntajeTotal: 60,
                    calificacion: 8.5,
                    fechaAplicacion: null,
                    fechaInicial: null,
                    fechaFin: null,
                    fkidPersonalEvaluador: 1,
                    fkidResultado: 1,
                    niveles: [],
                    factores: [],
                },

                required: [v => !!v || 'Este campo es requerido'],

                personalInscritoOptions: {
                    chart: {
                        id: 'vuechart-example',
                    },

                    markers: {
                        size: 0,
                    },
                    xaxis: {
                        categories: ['Excelente', 'Sobresaliente', 'Satisfactorio', 'Insuficiente', 'No satisfactorio', 'No aplica'],
                    },

                    theme: {
                        mode: 'light',

                        monochrome: {
                            enabled: true,
                            color: '#F5B940',
                            shadeTo: 'light',
                            shadeIntensity: 0.65,
                        },
                    },
                },
                personalInscritoSeries: [
                    {
                        type: 'column',
                        name: 'Total de inscritos',
                        data: [19, 5, 7, 3, 0, 2],
                    },
                ],
            }
        },

        computed: {
            ...mapGetters('productividad', {
                productividad: 'getProductividad',
            }),
            ...mapGetters('funcion', {
                funciones: 'getFunciones',
            }),
            ...mapGetters('indicador', {
                indicadores: 'getIndicadores',
            }),
            ...mapGetters('frecuenciaProductividad', {
                frecuenciaProductividad: 'getFrecuenciaProductividad',
            }),
            ...mapGetters('actoRelevante', {
                actosRelevantes: 'getActosRelevantes',
            }),
            ...mapGetters('factorMerito', {
                factoresMerito: 'getFactoresMerito',
            }),
            ...mapGetters('resultado', {
                resultados: 'getResultados',
            }),

            ...mapGetters('personal', {
                personal: 'getPersonalFotoCuip',
            }),

            filterIndicadoresByFuncion: function() {
                return this.indicadores.filter(indicador => {
                    return indicador.fk_idCatFuncion == this.funcionElegida + 1
                })
            },

            activeStepTitle: function() {
                switch (this.activeStep) {
                    case 1:
                        return 'Fecha de aplicación'
                    case 2:
                        return 'Período de evaluación'
                }
            },

            activeStepButtonLabel: function() {
                if (this.activeStep < 4) {
                    return 'Siguiente paso'
                } else {
                    return 'Registrar arma'
                }
            },

            fechaInicio: function() {
                if (this.periodoEvaluacion[0]) {
                    if (moment(this.periodoEvaluacion[1]).isBefore(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            fechaFin: function() {
                if (this.periodoEvaluacion[1]) {
                    if (moment(this.periodoEvaluacion[1]).isAfter(this.periodoEvaluacion[0])) {
                        return moment(this.periodoEvaluacion[1]).format('DD/MM/YYYY')
                    } else return moment(this.periodoEvaluacion[0]).format('DD/MM/YYYY')
                }
            },

            validActiveStep: function() {
                switch (this.activeStep) {
                    case 1:
                        if (this.fechaAplicacionForm) {
                            return true
                        } else {
                            return false
                        }
                    case 2:
                        if (this.periodoEvaluacionForm) {
                            return true
                        } else {
                            return false
                        }
                }
            },

            getPuntajeTotal: function() {
                var puntajeTotal = 0

                this.productividadTest.niveles.forEach(indicador => {
                    switch (indicador.fk_idCatFrecuencia) {
                        case 1:
                            puntajeTotal = puntajeTotal + 4
                            break
                        case 2:
                            puntajeTotal = puntajeTotal + 3
                            break
                        case 3:
                            puntajeTotal = puntajeTotal + 2
                            break
                        case 4:
                            puntajeTotal = puntajeTotal + 1
                            break
                        case 5:
                            puntajeTotal = puntajeTotal + 0
                            break
                        default:
                            puntajeTotal = puntajeTotal + 0
                    }
                })
                return puntajeTotal
            },

            getResultado: function() {
                if (this.getPuntajeTotal < 31) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 5
                    return 'No satisfactorio'
                } else if (this.getPuntajeTotal < 63) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Insuficiente'
                } else if (this.getPuntajeTotal < 76) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Satisfactorio'
                } else if (this.getPuntajeTotal < 88) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 2
                    return 'Sobresaliente'
                } else if (this.getPuntajeTotal <= 100) {
                    // this.evaluacionRespetoPrincipios.fk_idResultado = 1
                    return 'Excelente'
                }
            },
        },

        methods: {
            ...mapActions('productividad', ['saveProductividad']),
            ...mapMutations('personal', ['SET_PERSONAL_FOTO_CUIP']),

            getRealizados: function(id_indicador) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                return this.productividadTest.niveles[index].realizados
            },

            setRealizados: function(id_indicador, e) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                // if (isNaN(e)) {
                //     this.productividadTest.niveles[index].realizados = null
                // } else {
                //     this.productividadTest.niveles[index].realizados = parseFloat(e)
                // }
                this.productividadTest.niveles[index].realizados = e
            },

            getFrecuencia: function(id_indicador) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                return this.productividadTest.niveles[index].fk_idCatFrecuencia
            },

            setFrecuencia: function(id_indicador, e) {
                const index = this.productividadTest.niveles.findIndex(indicador => indicador.fk_idCatIndicador == id_indicador)
                this.productividadTest.niveles[index].fk_idCatFrecuencia = e
            },

            getCantidadActos: function(id_acto_relevante) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                return this.productividadTest.factores[index].cantidad
            },

            setCantidadActos: function(id_acto_relevante, e) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                this.productividadTest.factores[index].cantidad = parseFloat(e)
            },

            getDescripcionActos: function(id_acto_relevante) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                return this.productividadTest.factores[index].descripcion
            },

            setDescripcionActos: function(id_acto_relevante, e) {
                const index = this.productividadTest.factores.findIndex(factor => factor.fk_idActorRelevante == id_acto_relevante)
                this.productividadTest.factores[index].descripcion = e
            },

            setNiveles: function() {
                this.indicadores.forEach(indicador => {
                    this.productividadTest.niveles.push({
                        realizados: null,
                        fk_idCatFrecuencia: null,
                        fk_idCatIndicador: indicador.id,
                    })
                })
            },

            setFactoresMerito: function() {
                this.actosRelevantes.forEach(factor => {
                    this.productividadTest.factores.push({
                        fk_idActorRelevante: factor.id,
                        cantidad: null,
                        descripcion: '',
                    })
                })
            },

            triggerSaveEvaluacionProductividad: async function() {
                this.loading = true
                setTimeout(() => this.activeStep++, 2000)

                await this.saveProductividad({
                    idPersonal: this.personal.idPersonal,
                    puntajeTotal: 60,
                    calificacion: '8',
                    fechaAplicacion: moment(this.productividadTest.fechaAplicacion).format('DD/MM/YYYY'),
                    fechaInicial: this.fechaInicio,
                    fechaFin: this.fechaFin,
                    fkidPersonalEvaluador: 1,
                    fkidResultado: 1,
                    niveles: this.productividadTest.niveles,
                    factores: this.productividadTest.factores,
                })

                // this.$router.replace('/desarrollo_profesional/personal')
            },

            getCantidadIndicadores: function(id_frecuencia) {
                return this.productividadTest.niveles.filter(indicador => {
                    return indicador.fk_idCatFrecuencia == id_frecuencia
                }).length
            },
        },
    }
</script>
