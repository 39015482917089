<template>
    <v-main class="primary">
        <transition name="fade" mode="out-in">
            <loader-component v-if="loading"></loader-component>
            <router-view v-else></router-view>
        </transition>
    </v-main>
</template>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
</style>
<script>
    import { mapActions, mapMutations, mapGetters } from 'vuex'
    import LoaderComponent from '../../components/LoaderComponent.vue'

    export default {
        components: {
            LoaderComponent,
        },

        async created() {
            this.ENABLE_LOADER()
            this.$vuetify.theme.themes.light.primary = '#fffbf2'
            this.$vuetify.theme.themes.light.secondary = '#F5B940'
            this.$vuetify.theme.themes.light.accent = '#F58634'
            this.$vuetify.theme.themes.light.anchor = '#F5B940'
            await this.fetchAspirantes()
            await this.fetchMunicipios()
            await this.fetchEstados()
            await this.fetchEstadoCivil()
            await this.fetchTipoNacionalidad()
            await this.fetchSexo()
            await this.fetchGrupoSanguineo()

            this.DISABLE_LOADER()
        },

        computed: {
            ...mapGetters({
                loading: 'getLoader',
            }),
        },

        methods: {
            ...mapActions('personal', ['fetchPersonal']),
            ...mapActions('preaspirante', ['fetchPreaspirantes', 'fetchAspirantes']),
            ...mapMutations(['DISABLE_LOADER', 'ENABLE_LOADER']),
            ...mapActions('estadoCivil', ['fetchEstadoCivil']),
            ...mapActions('tipoNacionalidad', ['fetchTipoNacionalidad']),
            ...mapActions('sexo', ['fetchSexo']),
            ...mapActions('grupoSanguineo', ['fetchGrupoSanguineo']),
            ...mapActions('estado', ['fetchEstados']),
            ...mapActions('municipio', ['fetchMunicipios']),
        },
    }
</script>
